import { StoreAction } from 'constants/store';
import { DEFAULT_PAGINATION } from 'defaults';
import {
  ActionType,
  StoreSearch,
  StoreSearchBrand,
  StoreSearchConsultation,
  StoreSearchDiagnosis,
  StoreSearchGeneric,
  StoreTreatmentPlans
} from 'models/store';
import { extractConsultation, extractTreatmentPlans } from 'services/patient.service';
const { DIAGNOSIS, PATIENT, PRESCRIPTION } = StoreAction;
const { RECORDS, TREATMENT_PLANS } = PATIENT;
const { SEARCH_GENERIC, SEARCH_BRAND } = PRESCRIPTION;

const INITIAL_STATE: StoreSearch = {
  consultationList: {
    inProgress: false,
    pagination: DEFAULT_PAGINATION
  },
  diagnosisList: {
    inProgress: false
  },
  genericList: {
    inProgress: false
  },
  brandList: {
    inProgress: false
  },
  treatmentPlans: {
    inProgress: false,
    data: []
  }
};

function searchConsultationsReducer(
  state = INITIAL_STATE.consultationList,
  action: ActionType
): StoreSearchConsultation {
  switch (action.type) {
    case RECORDS.REQUEST:
      return {
        ...state,
        data: undefined,
        inProgress: true
      };
    case RECORDS.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload
      };
    case RECORDS.SUCCESS:
      return {
        ...state,
        data: action.payload.map(extractConsultation),
        pagination: action.others?.pagination ?? state.pagination,
        inProgress: false
      };
    default:
      return state;
  }
}

function searchTreatmentPlansReducer(state = INITIAL_STATE.treatmentPlans, action: ActionType): StoreTreatmentPlans {
  switch (action.type) {
    case TREATMENT_PLANS.SUCCESS:
      return {
        ...state,
        data: extractTreatmentPlans(action.payload),
        inProgress: false
      };
    default:
      return state;
  }
}

function searchDiagnosesReducer(state = INITIAL_STATE.diagnosisList, action: ActionType): StoreSearchDiagnosis {
  switch (action.type) {
    case DIAGNOSIS.REQUEST:
      return {
        ...state,
        data: undefined,
        inProgress: true
      };
    case DIAGNOSIS.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload
      };
    case DIAGNOSIS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        inProgress: false
      };
    default:
      return state;
  }
}

function searchGenericReducer(state = INITIAL_STATE.genericList, action: ActionType): StoreSearchGeneric {
  switch (action.type) {
    case SEARCH_GENERIC.REQUEST:
      return {
        ...state,
        data: undefined,
        inProgress: true
      };
    case SEARCH_GENERIC.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload
      };
    case SEARCH_GENERIC.SUCCESS:
      return {
        ...state,
        data: action.payload,
        inProgress: false
      };
    default:
      return state;
  }
}

function searchBrandReducer(state = INITIAL_STATE.brandList, action: ActionType): StoreSearchBrand {
  switch (action.type) {
    case SEARCH_BRAND.REQUEST:
      return {
        ...state,
        data: undefined,
        inProgress: true
      };
    case SEARCH_BRAND.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload
      };
    case SEARCH_BRAND.SUCCESS:
      return {
        ...state,
        data: action.payload,
        inProgress: false
      };
    default:
      return state;
  }
}

function rootReducer(state = INITIAL_STATE, action: ActionType) {
  return {
    consultationList: searchConsultationsReducer(state.consultationList, action),
    diagnosisList: searchDiagnosesReducer(state.diagnosisList, action),
    genericList: searchGenericReducer(state.genericList, action),
    brandList: searchBrandReducer(state.brandList, action),
    treatmentPlans: searchTreatmentPlansReducer(state.treatmentPlans, action)
  };
}

export default rootReducer;
