import { http } from 'common/services';
import { ENDPOINTS } from 'defaults';

const { DIAGNOSIS, CASE_RATE_CODE } = ENDPOINTS.REFERENCE.SEARCH;

export const searchICD10 = (term: string, byWord?: boolean) => {
  const payload = {
    query: {
      multi_match: { query: term, fields: [`diagnosis_name${byWord ? '' : '.autocomplete'}`, 'diagnosis_code'] }
    }
  };
  return http.post(DIAGNOSIS.name, DIAGNOSIS.url, payload);
};

export const searchCaseRate = (term: string, field: string) => {
  const payload = {
    query: { multi_match: { query: term, fields: ['description.autocomplete', field] } }
  };

  return http.post(CASE_RATE_CODE.name, CASE_RATE_CODE.url, payload);
};
