import { CircularProgress } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { styled } from 'components/Theme';
import React from 'react';
import { StyledMatButton } from './Button';

interface SplitButtonProps {
  options: string[];
  color?: 'default' | 'primary' | 'secondary';
  onClick?: (index: number) => void;
  className?: string;
  fullWidth?: boolean;
  loading?: boolean;
  disabled?: boolean;
  disabledPrimary?: boolean;
  disabledIndex?: { [index: string]: boolean }; // disable option
}

export const SplitButton: React.FC<SplitButtonProps> = ({
  options,
  color = 'default',
  onClick,
  className,
  fullWidth,
  disabled,
  disabledPrimary = false,
  disabledIndex = {},
  loading
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  let buttonClasses = `c-button c-button--split ${className}`.trim();
  if (fullWidth) {
    buttonClasses += ' c-button--full';
  }
  if (loading) {
    buttonClasses += ' c-button--loading';
  }

  const handleClick = (index = 0) => {
    onClick && onClick(index);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    handleClick(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <SplitButtonWrapper className={buttonClasses}>
      <ButtonGroup variant="contained" ref={anchorRef} disabled={disabled || loading}>
        <StyledMatButton
          color={color}
          className="mat-button mat-button--main"
          onClick={() => handleClick()}
          disabled={disabledPrimary || !!disabledIndex['0']}
        >
          {options[0]}
        </StyledMatButton>
        <StyledMatButton color={color} className="mat-button--options" size="small" onClick={handleToggle}>
          <ArrowDropDownIcon />
        </StyledMatButton>
      </ButtonGroup>
      {loading && <CircularProgress color="primary" size={24} className="loader" />}
      <Popper
        className="c-button__options"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'right bottom'
            }}
          >
            <Paper className="paper">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => {
                    if (index === 0) {
                      return null;
                    }
                    return (
                      <MenuItem
                        key={option}
                        onClick={event => handleMenuItemClick(event, index)}
                        disabled={!!disabledIndex[`${index}`]}
                      >
                        {option}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </SplitButtonWrapper>
  );
};

export const SplitButtonWrapper = styled.div`
  position: relative;
  &.c-button--full {
    width: 100%;
  }
  .MuiButtonGroup-root {
    width: 100%;
    box-shadow: none;
    .mat-button--main {
      flex: 1;
      padding: 8px 24px;
      border-radius: 3px 0 0 3px;
    }
    .mat-button--options {
      padding: 0 8px;
      border-radius: 0 3px 3px 0;
    }
  }
  .c-button__options {
    width: 100%;
    position: absolute !important;
    left: 0 !important;
    top: calc(100% + 1px) !important;
    transform: none !important;
    z-index: 10;
    .paper {
      border-radius: 0 0 3px 3px;
    }
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
    transform: translate(0, 0);
  }
`;
