export const computeScore = (term: string, diagnosis: string, total: number) => {
  const words = (term || '')
    .toLowerCase()
    .split(' ')
    .filter(d => !!d.trim());
  let score = 0;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const index = diagnosis.toLowerCase().indexOf(word);
    if (index < 0) {
      score += total;
    } else {
      score += index;
    }
  }
  return score;
};

export const mapElasticHits = (results: any, term: string, total: number): any[] => {
  let diags = results.hits.hits.map((hit: any) => ({
    id: hit._id,
    name: hit._source.diagnosis_name,
    code: hit._source.diagnosis_code,
    score1: hit._score
  }));
  diags = diags.map((item: any) => ({
    ...item,
    name: item.name,
    score2: computeScore(term, item.name, total)
  }));
  return diags;
};
