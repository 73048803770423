import { Check } from '@material-ui/icons';
import { formatDate, getKey, getLocalStorageData } from 'common/services';
import { Avatar, Button, Chip, Input, Loading, Tab, Autocomplete, Toast } from 'components';
import { styled } from 'components/Theme';
import { EncounterType } from 'constants/form';
import { Consultation } from 'models';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncFn } from 'react-use';
import {
  createPatientHistoricalHealthRecord,
  extractConsultation,
  getPatientHistoricalHealthRecord,
  getPatientRecords
} from 'services/patient.service';
import { searchAllPatients } from 'services/search.service';
import { addRecordAttachment } from 'services/uploader.service';
import { AttachFile } from 'shared/components/AttachFile';
import { Dropzone } from 'shared/components/Dropzone';
import { ModalFooter } from 'shared/components/Modal';

interface Props {
  close: () => void;
}

interface GeneralFilesProps {
  generalFiles: File[];
  setGeneralFiles: (files: File[]) => void;
}

interface EncounterFilesProps {
  patientId: number;
  encounterFiles: File[];
  setEncounterFiles: (files: File[]) => void;
  encounter: Consultation | null;
  setEncounter: (enc: Consultation) => void;
}

const QuickAttachFile: React.FC<Props> = ({ close }) => {
  const activeClinicId = getLocalStorageData().clinicId;
  const [t] = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [hideSearching, setHideSearching] = useState(false);

  const [generalFiles, setGeneralFiles] = useState<File[]>([]);
  const [encounterFiles, setEncounterFiles] = useState<File[]>([]);
  const [encounter, setEncounter] = useState<Consultation | null>(null);

  const [{ loading: fetchingPatient, value: patients }, fetchPatient] = useAsyncFn(async (...args: any[]) => {
    const [term] = args;
    const { data: patients } = await searchAllPatients(term);
    return patients;
  });

  const [{ loading: attachingFiles }, attachFiles] = useAsyncFn(async (...args: any[]) => {
    const [patient, encounter, global, local, clinicId] = args;
    try {
      if (global.length > 0) {
        let historicalId = -1;
        const { data: historical } = await getPatientHistoricalHealthRecord(patient.id);
        if (historical && historical.length > 0) {
          historicalId = historical[0].id;
        } else {
          const { data } = await createPatientHistoricalHealthRecord(patient.id, clinicId);
          if (data) historicalId = data.id;
        }
        for (let f of global) {
          await addRecordAttachment(historicalId, f, 'general');
        }
      }
      if (local.length > 0 && encounter) {
        for (let f of local) {
          await addRecordAttachment(encounter.phrId, f, 'general');
        }
      }
      Toast.success('Files successfully attached.');
      close();
    } catch (err) {
      console.log(err);
    }
  });

  const renderPatientList = useMemo(() => {
    if (hideSearching) return null;
    if (!patients) return null;
    if (patients.length === 0) return null;
    return (
      <PatientListWrapper hasSelected={!!selectedPatient}>
        {(patients || [])
          .filter((item: any) => !selectedPatient || item.id !== selectedPatient.id)
          .map((item: any, idx: number) => {
            return (
              <PatientCard
                selected={false}
                key={idx}
                onClick={() => {
                  setSelectedPatient(item);
                  setHideSearching(true);
                }}
              >
                <Avatar src={item.avatar} className="attach_file-confirm--avatar" />
                <div className="attach_file-confirm--px-birthdate">
                  <span className="attach_file-confirm--px-fullname">{item.name}</span>
                  <span className="attach_file-confirm--birth-date">
                    {item.birth_date ? formatDate(new Date(item.birth_date), 'MMM dd, yyyy') : ''}
                  </span>
                </div>
                <Check className="attach_file-confirm--item-selected" />
              </PatientCard>
            );
          })}
      </PatientListWrapper>
    );
  }, [patients, setSelectedPatient, hideSearching, selectedPatient]);

  const renderSelected = useMemo(() => {
    if (!selectedPatient) return null;
    return (
      <PatientCard selected={true}>
        <Avatar src={selectedPatient.avatar} className="attach_file-confirm--avatar" />
        <div className="attach_file-confirm--px-birthdate">
          <span className="attach_file-confirm--px-fullname">{selectedPatient.name}</span>
          <span className="attach_file-confirm--birth-date">
            {selectedPatient.birth_date ? formatDate(new Date(selectedPatient.birth_date), 'MMM dd, yyyy') : ''}
          </span>
        </div>
        <Check className="attach_file-confirm--item-selected" />
      </PatientCard>
    );
  }, [selectedPatient]);

  const onAttachFile = () => {
    if (generalFiles.length === 0 && encounterFiles.length === 0) return;
    if (!selectedPatient) {
      Toast.error('Patient is required when attaching files.');
      return;
    }
    if (encounterFiles.length > 0 && !encounter) {
      Toast.error('Encounter is required when attaching files to an encounter.');
      return;
    }
    attachFiles(selectedPatient, encounter, generalFiles, encounterFiles, activeClinicId);
  };

  return (
    <React.Fragment>
      <QuickAttachFileWrapper>
        <div className="modal_section left">
          <span className="modal_header">{t('upload_files_for_your_patient', { ns: 'encounter' })}</span>
          <Input
            name="searchTerm"
            label={t('search_patient')}
            type="search"
            fullWidth
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
            onSearch={() => {
              setHideSearching(false);
              fetchPatient(searchTerm);
              setEncounter(null);
            }}
            autoComplete="off"
          />
          {renderSelected}
          <Loading loading={fetchingPatient}>{renderPatientList}</Loading>
        </div>
        <div className="modal_section right">
          <Tab
            variant="fullWidth"
            pages={[
              {
                pageComponent: <GeneralFilesTab generalFiles={generalFiles} setGeneralFiles={setGeneralFiles} />,
                pageTitle: t('general_files', { ns: 'encounter' })
              },
              {
                pageComponent: (
                  <EncounterFilesTab
                    encounterFiles={encounterFiles}
                    setEncounterFiles={setEncounterFiles}
                    patientId={selectedPatient ? selectedPatient.id : -1}
                    encounter={encounter}
                    setEncounter={setEncounter}
                  />
                ),
                pageTitle: t('attach_to_an_encounter', { ns: 'encounter' }),
                disabled: !selectedPatient
              }
            ]}
          />
        </div>
      </QuickAttachFileWrapper>
      <ModalFooter>
        <Button color="primary" label={t('cancel')} variant="text" onClick={close} disabled={attachingFiles} />
        <Button
          color="primary"
          label={t('attach_file', { ns: 'encounter' })}
          onClick={onAttachFile}
          loading={attachingFiles}
        />
      </ModalFooter>
    </React.Fragment>
  );
};

export default React.memo(QuickAttachFile);

const GeneralFilesTab: React.FC<GeneralFilesProps> = ({ generalFiles, setGeneralFiles }) => {
  const [t] = useTranslation();
  return (
    <TabContentWrapper>
      {generalFiles.map((item: File, idx: number) => {
        return (
          <Chip
            className="item_chip"
            color="primary"
            key={idx}
            label={item.name}
            onClick={() => window.open(URL.createObjectURL(item), '_blank')}
            onDelete={() => setGeneralFiles(generalFiles.filter((_, itemIdx: number) => itemIdx !== idx))}
          />
        );
      })}
      <Dropzone
        onUpload={(files: File[]) => setGeneralFiles([...generalFiles, ...files])}
        label={t('drag_and_drop_here_to_attach_files')}
      />
      <div className="file_add_button">
        <AttachFile
          color="secondary"
          label={t('upload_file')}
          multiple
          onUpload={(files: File[]) => setGeneralFiles([...generalFiles, ...files])}
        />
        <span className="option_text">{t('or')}</span>
      </div>
    </TabContentWrapper>
  );
};

const EncounterFilesTab: React.FC<EncounterFilesProps> = ({
  patientId,
  encounterFiles,
  setEncounterFiles,
  encounter,
  setEncounter
}) => {
  const [t] = useTranslation();
  const [{ loading, value: encounters }, fetchEncounters] = useAsyncFn(async (...args: any[]) => {
    const [id] = args;
    if (!id) return [];
    try {
      const { data } = await getPatientRecords(id);
      if (data && data.length > 0) {
        return data.map(extractConsultation);
      }
      return [];
    } catch (err) {
      console.log(err);
      return [];
    }
  });

  useEffect(() => {
    if (patientId) {
      fetchEncounters(patientId);
    }
  }, [patientId, fetchEncounters]);
  return (
    <TabContentWrapper>
      {encounterFiles.map((item: File, idx: number) => {
        return (
          <Chip
            className="item_chip"
            color="primary"
            key={idx}
            label={item.name}
            onClick={() => window.open(URL.createObjectURL(item), '_blank')}
            onDelete={() => setEncounterFiles(encounterFiles.filter((_, itemIdx: number) => itemIdx !== idx))}
          />
        );
      })}
      <Loading loading={loading}>
        <Autocomplete
          label="Encounter"
          options={encounters || []}
          name="encounterSelect"
          defaultValue={encounter || ''}
          onChange={(_, v) => {
            if (!v) return '';
            setEncounter(v);
            return v;
          }}
          fullWidth
          getOptionLabel={(option: Consultation) => {
            if (typeof option === 'object') {
              return `${formatDate(option.visitDate, 'yyyy-MM-dd')} ${getKey(option.encounterType, EncounterType)} (${
                option.chiefComplaint
              })`;
            }
            return '';
          }}
          renderOption={(option: Consultation) => {
            return (
              <SelectOptionWrapper>
                <div className="option_top">
                  <div className="option_top_content">
                    <span className="option_label">{t('encounter_type', { ns: 'encounter' })}</span>
                    <span className="option_value">{getKey(option.encounterType, EncounterType)}</span>
                  </div>
                  <div className="option_top_content">
                    <span className="option_label">Visit Date </span>
                    <span className="option_value">{formatDate(option.visitDate, 'MMM dd, yyyy')}</span>
                  </div>
                </div>
                <span className="option_label">{t('chief_complain_present_illness', { ns: 'appointment' })}</span>
                <span className="option_value">{option.chiefComplaint}</span>
              </SelectOptionWrapper>
            );
          }}
        />
      </Loading>
      <Dropzone
        onUpload={(files: File[]) => setEncounterFiles([...encounterFiles, ...files])}
        label={t('drag_and_drop_here_to_attach_files')}
      />
      <div className="file_add_button">
        <AttachFile
          color="secondary"
          label={t('upload_file')}
          multiple
          onUpload={(files: File[]) => setEncounterFiles([...encounterFiles, ...files])}
        />
        <span className="option_text">{t('or')}</span>
      </div>
    </TabContentWrapper>
  );
};

const QuickAttachFileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  .modal_section {
    width: 50%;
    height: 100%;
    &.left {
      padding-right: 8px;
      min-height: 310px;
    }
    &.right {
      padding-left: 8px;
    }
  }
  .modal_header {
    font-size: 14px;
    color: #979797;
  }
  @media screen and (max-width: ${props => props.theme.variables.bpLarge}) {
    flex-wrap: wrap;
    .modal_section {
      width: 100%;
      &.left {
        padding: 0;
        min-height: auto;
        margin-bottom: 16px;
      }
      &.right {
        padding: 0;
      }
    }
  }
`;

const PatientListWrapper = styled.div<{ hasSelected: boolean }>`
  width: 100%;
  max-height: ${props => (props.hasSelected ? '344px' : '430px')};
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 0px 8px 0px 8px;
  margin-top: 16px;
`;

const PatientCard = styled.div<{ selected: boolean }>`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: solid 1px ${props => (props.selected ? props.theme.color.primary : '#eaeaea')};
  border-radius: 3px;
  height: 80px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  .attach_file-confirm--avatar {
    width: 50px !important;
    height: 50px !important;
    margin-right: 16px;
  }
  .attach_file-confirm--px-birthdate {
    display: flex;
    flex-direction: column;
    .attach_file-confirm--px-fullname {
      font-weight: bold;
    }
    .attach_file-confirm--birth-date {
      margin-top: 8px;
      color: #989898;
    }
  }
  .attach_file-confirm--item-selected {
    display: ${props => (props.selected ? 'inline' : 'none')};
    margin-left: auto;
    margin-right: 8px;
    justify-self: flex-end;
    fill: ${props => props.theme.color.primary};
  }
  &:hover {
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
`;

const TabContentWrapper = styled.div`
  width: 100%;
  padding: 8px;
  .item_chip {
    margin: 0px 8px 8px 0px;
  }
  .file_dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    text-align: center;
    color: #979797;
    border: 1px solid #eaeaea;
    border-radius: 3px;
    margin-top: 16px;
  }
  .file_add_button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 16px;
    .option_text {
      color: #979797;
      margin-right: 16px;
    }
  }
`;

const SelectOptionWrapper = styled.div`
  width: 100%;
  padding: 8px;
  .option_top {
    display: flex;
    flex-direction: row;
    .option_top_content {
      width: 50%;
    }
  }
  .option_label {
    display: block;
    font-weight: 500;
    font-size: 14px;
  }
  .option_value {
    display: block;
    font-size: 12px;
    font-weight: 400;
  }
`;
