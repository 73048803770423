import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enCommon from 'locales/en/common.json';
import enAppointment from 'locales/en/appointment.json';
import enBilling from 'locales/en/billing.json';
import enDoctorProfile from 'locales/en/doctor_profile.json';
import enEncounter from 'locales/en/encounter.json';
import enPatientProfile from 'locales/en/patient_profile.json';
import enPatientRecord from 'locales/en/patient_record.json';
import enSecretaryDashboard from 'locales/en/secretary_dashboard.json';
import enTermsAndConditionsPolicy from 'locales/en/terms_and_conditions_policy.json';
import enSuccessErrorMessages from 'locales/en/success_error_messages.json';
import enAuth from 'locales/en/auth.json';

import idCommon from 'locales/id/common.json';
import idAppointment from 'locales/id/appointment.json';
import idBilling from 'locales/id/billing.json';
import idDoctorProfile from 'locales/id/doctor_profile.json';
import idEncounter from 'locales/id/encounter.json';
import idPatientProfile from 'locales/id/patient_profile.json';
import idPatientRecord from 'locales/id/patient_record.json';
import idSecretaryDashboard from 'locales/id/secretary_dashboard.json';
import idTermsAndConditionsPolicy from 'locales/id/terms_and_conditions_policy.json';
import idSuccessErrorMessages from 'locales/id/success_error_messages.json';
import idAuth from 'locales/id/auth.json';

export const en = {
  common: enCommon,
  appointment: enAppointment,
  billing: enBilling,
  doctor_profile: enDoctorProfile,
  encounter: enEncounter,
  patient_profile: enPatientProfile,
  patient_record: enPatientRecord,
  secretary_dashboard: enSecretaryDashboard,
  terms_and_conditions_policy: enTermsAndConditionsPolicy,
  success_error_messages: enSuccessErrorMessages,
  auth: enAuth
};
export const id = {
  common: idCommon,
  appointment: idAppointment,
  billing: idBilling,
  doctor_profile: idDoctorProfile,
  encounter: idEncounter,
  patient_profile: idPatientProfile,
  patient_record: idPatientRecord,
  secretary_dashboard: idSecretaryDashboard,
  terms_and_conditions_policy: idTermsAndConditionsPolicy,
  success_error_messages: idSuccessErrorMessages,
  auth: idAuth
};

export const defaultNS = 'common';
export const resources = {
  en,
  id
} as const;

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: (import.meta.env.VITE_NODE_ENV || 'local') === 'development',
    fallbackLng: window.location.hostname.includes('.id') ? 'id' : 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    ns: [
      'common',
      'appointment',
      'patient_record',
      'patient_profile',
      'doctor_profile',
      'billing',
      'encounter',
      'secretary_dashboard'
    ],
    defaultNS,
    fallbackNS: defaultNS,
    resources,
    detection: {
      order: ['localStorage'],
      caches: ['localStorage']
    }
  });

export default i18n;
