import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import classNames from 'classnames';
import { styled } from 'components/Theme';
import { FormHelperProps } from 'hooks/form';
import { KeyValue } from 'models';
import React, { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

export interface ToggleProps {
  name: string;
  items: ToggleItem[];
  label?: string | React.ReactNode;
  helpers?: FormHelperProps;
  multiple?: boolean;
  disabled?: boolean;
  className?: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  value?: any;
  allowDeselect?: boolean;
  icon?: any;
  onChange?: (value: any) => void;
}

export interface ToggleItem extends KeyValue {
  disabled?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
  label,
  name,
  helpers,
  multiple,
  items,
  disabled,
  className,
  error,
  helperText,
  fullWidth,
  allowDeselect,
  icon,
  ...props
}) => {
  const classes = classNames('c-toggle', className, {
    'no-label': !label,
    full: fullWidth
  });

  const [value, setValue] = useState(props.value ?? '');
  const handleChange = (_: any, newValue: any) => {
    if (newValue === null && !allowDeselect) return;
    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  const itemsButton = useMemo(
    () =>
      items.map(({ id, value, ...props }, idx) => (
        <ToggleButton key={idx} value={id} disabled={disabled || props.disabled}>
          {value}
        </ToggleButton>
      )),
    [items, disabled]
  );

  return (
    <StyledFormControl className={classes} error={error}>
      <div className="labelWrapper">
        {label && <FormLabel component="legend">{label}</FormLabel>}
        {icon && icon}
      </div>
      {helpers ? (
        <Controller
          name={name}
          control={helpers?.control}
          onChange={([_, val]) => {
            if (!val) return '';
            if (typeof val === 'object') return val.id ?? '';
            return val;
          }}
          as={
            <ToggleButtonGroup exclusive={!multiple} size="small">
              {itemsButton}
            </ToggleButtonGroup>
          }
        />
      ) : (
        <ToggleButtonGroup value={value} exclusive={!multiple} size="small" onChange={handleChange}>
          {itemsButton}
        </ToggleButtonGroup>
      )}

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)`
  margin-top: -3px !important;
  margin-bottom: 12px !important;
  button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
    background-color: rgba(0, 0, 0, 0.1);
    span {
      color: #999;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .labelWrapper {
    display: flex;
  }
  .MuiFormLabel-root {
    margin-left: 2px;
  }
  .MuiToggleButtonGroup-root {
    margin-top: 4px;
    background-color: transparent;
  }
  &.no-label {
    margin-top: 15px !important;
    margin-bottom: 12px !important;
    .MuiToggleButtonGroup-root {
      margin-top: 0;
    }
  }
  .MuiFormHelperText-root {
    position: absolute;
    top: calc(100% + 2px);
    left: 5px;
    margin: 0;
  }
  .MuiToggleButton-root {
    flex: 1;
    &.Mui-selected {
      span {
        color: #fff;
      }
      background: ${props => props.theme.color.primary};
      &:hover {
        span {
          color: #484848;
        }
        background: ${props => props.theme.color.primaryLight};
      }
    }
  }
  .MuiToggleButton-sizeSmall {
    height: 39px;
  }
  &.full {
    width: 100%;
    .MuiToggleButtonGroup-root {
      width: 100%;
    }
  }
`;
