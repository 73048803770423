import { AxiosResponse } from 'axios';
import { getAccountSpecializations, getDoctorProfile } from 'common/services';
import {
  extractDiagnosesAndPrescriptions,
  sortByKey,
  transformInRecord,
  transformInTreatmentRecords
} from 'common/transformer';
import { Toast } from 'components';
import { StoreAction } from 'constants/store';
import { AccountType } from 'models';
import { _Account, _AllDiagnosisType, _HealthRecord } from 'models/endpoint';
import { ActionType } from 'models/store';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  extractPatientInfo,
  getPatientInformation,
  getPatientLatestHealthRecord,
  getPatientRecords,
  getPatientVaccinations,
  sharePatientRecords,
  sharePatientRecordsSolo,
  updatePatientAvatar
} from 'services/patient.service';
import {
  getDiagnosisByPatient,
  getEncounters,
  getSharedTreatmentRecords,
  getTreatmentRecords
} from 'services/phr.service';
import { getBorrowersByPatientId } from 'services/search.service';
import { flattenTreatmentDetails } from 'services/treatment.service';
import { getPagination } from 'services/utils.service';

const { AVATAR, PROFILE, RECORDS, GET, INFO, TREATMENT_PLANS, SHARE_PATIENT, SHARE_PATIENT_SOLO } = StoreAction.PATIENT;
const { GET_PROFILE } = StoreAction.DOCTOR;

function* getPatientProfileSaga(action: ActionType) {
  try {
    const { payload } = action;
    const { data } = yield call(getPatientInformation, payload);
    const { data: vaccinations } = yield call(getPatientVaccinations, payload);
    const { data: treatmentRecords } = yield call(getTreatmentRecords, payload);
    const patientInfo = extractPatientInfo(payload, data);
    const profile = {
      ...patientInfo,
      vaccinations: sortByKey(vaccinations, 'created_at'),
      treatment: treatmentRecords,
      treatmentRecords: transformInTreatmentRecords(flattenTreatmentDetails(treatmentRecords))
    };
    yield put({ type: GET.INFO.SUCCESS, payload: patientInfo });
    yield put({ type: PROFILE.GET.SUCCESS, payload: profile });
  } catch (err) {
    yield put({ type: PROFILE.GET.FAILED, payload: err });
    Toast.error(err.message);
  }
}

function* updatePatientAvatarSaga(action: ActionType) {
  try {
    const { data } = yield call(updatePatientAvatar, action.payload.patientId, action.payload.file);
    yield put({ type: AVATAR.UPDATE.SUCCESS, payload: { file_name: data.file_name, file_url: data.file_url } });
  } catch (err) {
    yield put({ type: AVATAR.UPDATE.FAILED, payload: err });
    Toast.error(err.message);
  }
}

function* getPatientRecordsSaga(action: ActionType) {
  try {
    const { pagination } = action.others || {};
    const { data, headers }: AxiosResponse = yield call(getPatientRecords, action.payload, pagination);
    yield put({ type: RECORDS.SUCCESS, payload: data, others: { pagination: getPagination(headers) } });
    yield put({ type: TREATMENT_PLANS.SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: RECORDS.FAILED, payload: err });
  }
}

function* getPatientInformationSaga(action: ActionType) {
  try {
    yield put({ type: PROFILE.GET.REQUEST, payload: action.payload });
  } catch (err) {
    yield put({ type: GET.INFO.FAILED, payload: err });
  }
}

function* getLatestHealthRecordSaga(action: ActionType) {
  try {
    const { data } = yield call(getPatientLatestHealthRecord, action.payload);
    const activeRecords = data.filter((item: _HealthRecord) => item.active);
    const findRecordWithPrescription = activeRecords.find((item: any) => item.phr_prescriptions.length > 0);
    if (findRecordWithPrescription) activeRecords[0].phr_prescriptions = findRecordWithPrescription.phr_prescriptions;
    const latestRecord = activeRecords.length > 0 ? extractDiagnosesAndPrescriptions(activeRecords[0]) : undefined;
    yield put({ type: GET.LATEST_HEALTH_RECORD.SUCCESS, payload: latestRecord });
  } catch (err) {
    yield put({ type: GET.LATEST_HEALTH_RECORD.FAILED, payload: err });
  }
}

function* updatePatientInformationAvatarSaga(action: ActionType) {
  try {
    const { data } = yield call(updatePatientAvatar, action.payload.patientId, action.payload.file);
    yield put({ type: INFO.AVATAR.UPDATE.SUCCESS, payload: { file_name: data.file_name, file_url: data.file_url } });
  } catch (err) {
    yield put({ type: INFO.AVATAR.UPDATE.FAILED, payload: err });
    Toast.error(err.message);
  }
}

function* getPatientTreatmentPlansSaga(action: ActionType) {
  try {
    const { data: diagnoses } = yield call(getDiagnosisByPatient, action.payload.patientId);
    const withTreatments = diagnoses.filter((diagnosis: _AllDiagnosisType) => diagnosis.treatment !== null);
    const phrIds = withTreatments.map((item: _AllDiagnosisType) => item.patient_health_record_id);
    const { data: healthRecords } = phrIds && phrIds.length > 0 ? yield call(getEncounters, phrIds) : { data: [] };
    const phrIndexById = healthRecords.reduce((acc: any, item: _HealthRecord) => {
      return {
        ...acc,
        [item.id]: item
      };
    }, {});
    const records = withTreatments.map((diagnosis: _AllDiagnosisType) => {
      const transformed = transformInRecord.diagnosisWithTreatment(diagnosis);
      const phr = phrIndexById[diagnosis.patient_health_record_id] as _HealthRecord | undefined;
      return {
        ...transformed,
        visitDate: phr?.visit_date ? new Date(phr.visit_date) : null,
        laboratoryAndDiagnosticData: phr?.laboratory_and_diagnostic_data
      };
    });
    yield put({ type: GET.TREATMENT_PLANS.SUCCESS, payload: sortByKey(records, 'visitDate').reverse() });
  } catch (err) {
    yield put({ type: GET.TREATMENT_PLANS.FAILED, payload: err });
    Toast.error(err.message);
  }
}

function* sharePatientRecordsSaga(action: ActionType) {
  const { POST } = SHARE_PATIENT;
  try {
    const payload = action.payload;
    yield call(sharePatientRecords, payload.patientId, payload.accountId);
    const { data } = yield call(getDoctorProfile);
    let specializations = [];
    const privateAccount = (data.accounts || []).find((acc: _Account) => acc.type === AccountType.Doctor);
    if (privateAccount) {
      const accountID = privateAccount.id;
      const { data: specs } = yield call(getAccountSpecializations, accountID);
      specializations = specs;
    }
    yield put({ type: GET_PROFILE.SUCCESS, payload: { ...data, specializations } });
    Toast.success('Patient Records was successfully shared.');
    yield put({ type: POST.SUCCESS, payload });
  } catch (err) {
    console.log(err);
    yield put({ type: POST.FAILED, payload: err });
    Toast.error('Patient Sharing Failed! Please contact the administrator.');
  }
}

function* sharePatientRecordsSoloSaga(action: ActionType) {
  const { POST } = SHARE_PATIENT_SOLO;
  try {
    const payload = action.payload;
    yield call(sharePatientRecordsSolo, payload.patientId, payload.accountId);
    const { data } = yield call(getDoctorProfile);
    const { data: borrowers } = yield call(getBorrowersByPatientId, payload.patientId);
    let specializations = [];
    const privateAccount = (data.accounts || []).find((acc: _Account) => acc.type === AccountType.Doctor);
    if (privateAccount) {
      const accountID = privateAccount.id;
      const { data: specs } = yield call(getAccountSpecializations, accountID);
      specializations = specs;
    }
    yield put({ type: GET_PROFILE.SUCCESS, payload: { ...data, specializations } });
    Toast.success('Patient Records was successfully shared.');
    yield put({ type: POST.SUCCESS, payload: { borrowers } });
  } catch (err) {
    console.log(err);
    yield put({ type: POST.FAILED, payload: err });
    Toast.error('Patient Sharing Failed! Please contact the administrator.');
  }
}

function* resetPatientProfileSaga(action: ActionType) {
  yield put({ type: PROFILE.GET.RESET, payload: {} });
  yield put({ type: GET.INFO.RESET, payload: {} });
  yield put({ type: GET.LATEST_HEALTH_RECORD.RESET, payload: {} });
}

export function* rootSaga() {
  yield takeEvery(AVATAR.UPDATE.REQUEST, updatePatientAvatarSaga);
  yield takeEvery(INFO.AVATAR.UPDATE.REQUEST, updatePatientInformationAvatarSaga);
  yield takeLatest(PROFILE.GET.REQUEST, getPatientProfileSaga);
  yield takeLatest(PROFILE.RESET.REQUEST, resetPatientProfileSaga);
  yield takeLatest(RECORDS.REQUEST, getPatientRecordsSaga);
  yield takeLatest(GET.INFO.REQUEST, getPatientInformationSaga);
  yield takeLatest(GET.LATEST_HEALTH_RECORD.REQUEST, getLatestHealthRecordSaga);
  yield takeLatest(GET.TREATMENT_PLANS.REQUEST, getPatientTreatmentPlansSaga);
  yield takeLatest(SHARE_PATIENT.POST.REQUEST, sharePatientRecordsSaga);
  yield takeLatest(SHARE_PATIENT_SOLO.POST.REQUEST, sharePatientRecordsSoloSaga);
}
