import grey from '@material-ui/core/colors/blueGrey';
import teal from '@material-ui/core/colors/teal';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { getLocalStorageData } from 'common/services';
import { Loading } from 'components';
import { FeatureFlag } from 'components/feature-flag';
import ModalContainer from 'components/modal/ModalContainer';
import PeekContainer from 'components/peek/PeekContainer';
import { theme } from 'components/Theme';
import 'config/sentry';
import { AssetLoaderProvider } from 'context/AssetLoaderContext';
import { SessionProvider } from 'context/SessionContext';
import { useEnv } from 'hooks';
import { UserTheme } from 'models';
import React, { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Confirm } from 'shared/utils/Prompt';
import { Toaster } from 'sonner';
import store from 'state/store';
import { ThemeProvider } from 'styled-components';
import Webfont from 'webfontloader';
import App from './App';

Webfont.load({
  custom: {
    families: ['Roboto:300,400,500,700', 'sans-serif'],
    urls: ['https://fonts.googleapis.com/css?family=Roboto:300,400,500,700']
  }
});
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const userTheme = getLocalStorageData().userTheme;

const matTheme = createMuiTheme({
  palette: {
    primary:
      userTheme === UserTheme.BCMAP ? grey : userTheme === UserTheme.TMC ? { main: '#004987', light: '#0A599B' } : teal
  },
  typography: {
    fontFamily: `"Hind", "Roboto", "sans-serif"`
  }
});

const Wrapper: React.FC = () => {
  const { isTMC, project } = useEnv();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [component, setComponent] = useState<any>(null);

  useEffect(() => {
    import('./DefaultStyles').then(c => {
      setComponent(c.default);
      setIsLoaded(true);
    });
  }, []);

  if (!isLoaded) return null;
  return (
    <AssetLoaderProvider>
      <Suspense fallback={<Loading type={isTMC ? 'tmc' : 'heart'} project={project} loading={true} className="full" />}>
        {component ? component : null}
        <SessionProvider>
          <App />
        </SessionProvider>

        <PeekContainer />
        <ModalContainer />
        <Toaster position="top-right" style={{ top: 80, right: 16 }} richColors />
      </Suspense>
    </AssetLoaderProvider>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <FeatureFlag appName="Doctor Portal">
        <BrowserRouter
          basename="app"
          getUserConfirmation={(message, callback) => Confirm(JSON.parse(message), callback)}
        >
          <MuiThemeProvider theme={matTheme}>
            <ThemeProvider theme={theme}>
              <Wrapper />
            </ThemeProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </FeatureFlag>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('app')
);
