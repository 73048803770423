import axios from 'axios';
import { errorHandler, getLocalStorageData } from 'common/services';
import { Toast } from 'components';
import { ERROR_MAP } from 'defaults';

export const assumeMember = async (payload: any) => {
  return new Promise((res, rej) => {
    axios
      .post(`/v1/auth/assume`, payload, {
        headers: {
          Authorization: getLocalStorageData().accessToken,
          'X-ENV': import.meta.env.VITE_NODE_ENV || ''
        }
      })
      .then(data => res(data))
      .catch(err => {
        const tmp = errorHandler(err);
        const error = ERROR_MAP[tmp.error];
        error && Toast.error(error || 'An error occurred.');
        rej(tmp);
      });
  });
};
