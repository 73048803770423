import { AxiosResponse } from 'axios';
import { http } from 'common/services';
import { Toast } from 'components';
import { ROUTES } from 'constants/routes';
import { StoreAction } from 'constants/store';
import { History } from 'history';
import i18n from 'i18n';
import { NewEncounterInput } from 'models';
import { _EncounterVaccination } from 'models/endpoint';
import { ActionType } from 'models/store';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  addProcedure,
  createEncounter,
  getEncounterStagingForm,
  getProcedures,
  updateProcedure
} from 'services/phr.service';
const { NEW_ENCOUNTER, GET_ENCOUNTER, SAVE_ENCOUNTER, GET_PROCEDURE, SAVE_PROCEDURE, GET_STAGING_FORM } =
  StoreAction.PHR;

function* createNewEncounterSaga(actions: ActionType) {
  try {
    const { payload, history }: { payload: NewEncounterInput; history?: History } = actions.payload;
    const { data }: AxiosResponse = yield call(createEncounter, payload);
    history && history.push(ROUTES.PHR.parse(data.id, payload.patientId));
    yield put({ type: NEW_ENCOUNTER.SUCCESS, payload: data.id });
  } catch (err) {
    yield put({ type: NEW_ENCOUNTER.FAILED, payload: err });
  }
}

function getEncounter(phrId: number) {
  return http.newGet(`/v1/encounter/${phrId}`);
}

function saveEncounter(payload: any) {
  return http.newPost('/v1/encounter', payload);
}

function* getEncounterSaga(actions: ActionType) {
  try {
    const phrId = typeof actions.payload === 'number' ? actions.payload : actions.payload.id;
    const { data: result } = yield call(getEncounter, phrId);
    const { encounter, procedure, staging_forms } = result;
    yield put({ type: GET_ENCOUNTER.SUCCESS, payload: encounter });
    yield put({ type: GET_PROCEDURE.SUCCESS, payload: procedure });
    yield put({ type: GET_STAGING_FORM.BY_ENCOUNTER.SUCCESS, payload: staging_forms });
  } catch (err) {
    yield put({ type: GET_ENCOUNTER.FAILED, payload: err });
    yield put({ type: GET_PROCEDURE.FAILED, payload: err });
  }
}

function* saveDiagnosticProcedureSaga(actions: ActionType) {
  try {
    const { id, phrID, procedures, instruction, remarks } = actions.payload;
    if (!id) {
      const { data: procedure } = yield call(addProcedure, phrID, procedures, instruction, remarks);
      yield put({ type: SAVE_PROCEDURE.SUCCESS, payload: [procedure] });
    } else {
      const { data: procedure } = yield call(updateProcedure, id, phrID, procedures, instruction, remarks);
      yield put({ type: SAVE_PROCEDURE.SUCCESS, payload: [procedure] });
    }
  } catch (err) {
    yield put({ type: SAVE_PROCEDURE.FAILED, payload: err });
  }
}

function* saveEncounterSaga(actions: ActionType) {
  try {
    const payload = actions.payload;
    yield call(saveEncounter, payload);
    Toast.success(i18n.t('msg_success_encounter_save', { ns: 'success_error_messages' }));
    yield put({ type: GET_ENCOUNTER.REQUEST, payload: payload.encounter.id });
    yield put({ type: SAVE_ENCOUNTER.SUCCESS, payload: true });
  } catch (err) {
    yield put({ type: SAVE_ENCOUNTER.FAILED, payload: err });
  }
}

export function* rootSaga() {
  yield takeLatest(NEW_ENCOUNTER.REQUEST, createNewEncounterSaga);
  yield takeLatest(GET_ENCOUNTER.REQUEST, getEncounterSaga);
  yield takeLatest(SAVE_ENCOUNTER.REQUEST, saveEncounterSaga);
  yield takeLatest(SAVE_PROCEDURE.REQUEST, saveDiagnosticProcedureSaga);
}
