import { CircularProgress } from '@material-ui/core';
import { Autocomplete as MatAutocomplete, UseAutocompleteCommonProps } from '@material-ui/lab';
import classNames from 'classnames';
import { styled } from 'components/Theme';
import { FormHelperProps } from 'hooks/form';
import debounce from 'lodash/debounce';
import React from 'react';
import { Controller } from 'react-hook-form';
import { StyledTextField } from '../input/Input';

type Props = UseAutocompleteCommonProps<any> & {
  name: string;
  label?: string;
  className?: string;
  multiple?: boolean;
  error?: boolean;
  helperText?: string;
  loading?: boolean;
  helpers?: FormHelperProps;
  idField?: {
    name: string;
    helpers: FormHelperProps;
  };
  submitOnEnter?: boolean;
  onSearch?: (term: string) => void;
  onChange?: (e: any, v: any | null) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  unit?: any;
  defaultValue?: any;
  value?: any;
  enableBuiltInFilter?: boolean;
  renderOption?: any;
};

export const Autocomplete: React.FC<Props> = ({
  label,
  name,
  className,
  helpers,
  multiple,
  error,
  helperText,
  loading,
  submitOnEnter,
  onSearch,
  onChange,
  idField,
  fullWidth,
  disabled,
  unit,
  defaultValue,
  enableBuiltInFilter = false,
  renderOption,
  ...props
}) => {
  const classes = classNames('c-autocomplete', className, {
    'w-100': fullWidth
  });

  const onKeyDown = (ev: any) => {
    // On Enter
    if (ev.keyCode === 13) {
      if (!submitOnEnter) {
        ev.preventDefault();
      }
    }
  };

  const syncChanges = debounce(e => {
    onSearch && onSearch(e.target.value);
  }, 400);

  const commonProps = (params: any) => ({
    ...params,
    variant: 'outlined',
    size: 'small',
    margin: 'normal',
    name,
    label,
    error,
    helperText,
    onKeyDown,
    disabled,
    InputProps: {
      ...params.InputProps,
      endAdornment: (
        <React.Fragment>
          {loading ? <CircularProgress color="inherit" size={20} /> : null}
          {unit}
          {params.InputProps.endAdornment}
        </React.Fragment>
      )
    }
  });

  const renderInput = (params: any) => {
    return (
      <StyledTextField
        {...commonProps(params)}
        onChange={e => {
          e.persist && e.persist();
          syncChanges(e);
        }}
      />
    );
  };

  const renderAutocomplete = () => {
    if (multiple) {
      return (
        <AutocompleteWrapper
          {...props}
          onChange={!helpers ? onChange : undefined}
          multiple
          size="small"
          disabled={disabled}
          autoComplete
          autoSelect={props.autoSelect ?? props.freeSolo}
          disableClearable={disabled ?? props.disableClearable ?? props.freeSolo}
          className={classes}
          filterOptions={!enableBuiltInFilter ? (option: any) => option : undefined}
          getOptionLabel={
            props.getOptionLabel
              ? props.getOptionLabel
              : (option: any) => (typeof option !== 'object' ? option : option.value)
          }
          renderInput={renderInput}
          renderOption={renderOption}
        />
      );
    }

    return (
      <AutocompleteWrapper
        {...props}
        onChange={!helpers ? onChange : undefined}
        disabled={disabled}
        autoComplete
        disableClearable={disabled ?? props.disableClearable ?? !props.freeSolo}
        autoSelect={props.autoSelect ?? props.freeSolo}
        // onInputChange={props.freeSolo ? (_: any, v: any) => helpers && helpers.setValue(name, v) : undefined}
        className={classes}
        filterOptions={!enableBuiltInFilter ? (option: any) => option : undefined}
        getOptionLabel={
          props.getOptionLabel
            ? props.getOptionLabel
            : (option: any) => (typeof option !== 'object' ? option : option.value)
        }
        renderInput={renderInput}
        renderOption={renderOption}
      />
    );
  };

  return (
    <React.Fragment>
      {helpers ? (
        <Controller
          name={name}
          control={helpers?.control}
          defaultValue={defaultValue}
          onChange={([e, v]) => {
            if (!!onChange) return onChange(e, v);
            if (!v) return defaultValue;
            return v;
          }}
          as={renderAutocomplete()}
        />
      ) : (
        renderAutocomplete()
      )}
      {idField && <input hidden name={idField.name} ref={idField?.helpers.register} />}
    </React.Fragment>
  );
};

export const AutocompleteWrapper = styled(MatAutocomplete)<any>`
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiAutocomplete-clearIndicator {
    margin-top: 1px;
  }
`;
