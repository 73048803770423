import { AccountType, Gender } from '../user';

export interface _User {
  id: number;
  email: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  nickname: string;
  gender: Gender;
  suffix: string;
  birth_date: Date;
  onboard: boolean;
  deceased: boolean;
  deceased_cause: string | null;
  terms_and_conditions: boolean;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  type: string;
  role: string;
  file_url: string;
  file_name: string;
  contact_numbers: _ContactNumber[];
  addresses: _Address[];
  claimed: boolean;
  test_account: boolean;
  access: _Access;
  last_sign_in_at: Date;
  wallets: _Wallet[];
  summary: _PatientSummary;
  disclaimer: boolean | null;
  enabled_2fa?: boolean;
  next_feedback_date?: string | Date;
  details?: _UserDetails;
  code?: string;
}

export interface _UserDetails extends _UserSubscriptionDetails {
  doctor_created?: boolean;
  next_reminder_date?: string | Date;
  project__core__first_sign_in?: string | null;
  project__tmc__first_sign_in?: string | null;
  project__keralty__first_sign_in?: string | null;
  prescription__watermark?: boolean;
}

export enum SUBSCRIPTION_STATUS {
  EMPTY = '',
  NEAR_END = 'near_end',
  ENDED = 'ended'
}

export interface _UserSubscriptionDetails {
  project__tmc_subscription_trial: boolean;
  project__tmc_subscription_status: SUBSCRIPTION_STATUS;
  project__core_subscription_trial: boolean;
  project__core_subscription_status: SUBSCRIPTION_STATUS;
  project__keralty_subscription_trial: boolean;
  project__keralty_subscription_status: SUBSCRIPTION_STATUS;
}

export interface _Access {
  submission_form__bcmap: boolean;
  encounter_form__bci: boolean;
  encounter_form__hpb: boolean;
  encounter_form__oncology: boolean;
  encounter_form__standard: boolean;
  encounter_form__cardiology: boolean;
  encounter_form__diabetology: boolean;
  encounter_form__rheumatology: boolean;
  encounter_form__endocrinology: boolean;
  psmo: boolean;
  psmo__referral_data_analytics: boolean;
  nalagen: boolean;
  aace: boolean;
  tavr: boolean;
  registry_liver: boolean;
  registry_pgh: boolean;
  registry_jrmmc: boolean;
  registry_evrmc: boolean;
  ncd: boolean;
}

export interface _SecretaryDoctor {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  file_url: string;
  specializations: {
    id: number;
    name: string;
  }[];
}

export interface _SecretaryDashboard {
  accounts: {
    id: number;
    name: string;
    type: AccountType;
    doctors: number[];
  }[];
  doctors: _SecretaryDoctor[];
}

export interface _NavigatorAccount {
  id: number;
  type: string;
  name: string;
  doctors: number[];
}

export interface _NavigatorDashboard {
  accounts: _NavigatorAccount[];
  doctors: _SecretaryDoctor[];
}

export interface _ContactNumber {
  id: number;
  contact_type: string;
  number: string;
  created_at: Date;
  updated_at: Date;
  owner_id: number;
  owner_type: string;
}

export interface _Wallet {
  id: number;
  user_id: number;
  institution: string;
  balance: number;
  enabled: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface _Address {
  id: number;
  owner_id: number;
  owner_type: string;
  address1: string;
  address2: string;
  barangay: string;
  region: string;
  city: string;
  state: string;
  municipality: string;
  province: string;
  country: string;
  postcode: string;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  address_type: string;
}

export interface _Profile {
  user: _User;
  identifications: _Identification[];
  specializations: _ProfileSpecialization[];
  // to be defined
  accounts: _Account[];
  templates: any;
}

export interface _AccountHMO {
  id: number;
  hmo_id: number;
  hmo_code: string;
  clinic_id: number | null;
  clinic_name: string | null;
  number: string;
}

export interface _JoinDates {
  id: number;
  date: Date;
}

export interface _Account {
  id: number;
  type: string;
  role: string;
  code: string;
  file_name: string | null;
  file_url: string | null;
  classification: 'clinic' | 'opd';
  publish_schedule: boolean;
  name: string;
  limit: number;
  clinic_ids: number[];
  affiliation_id: number;
  affiliation_type: string;
  auto_share: boolean;
  member_join_dates: _JoinDates[];
  active: boolean;
  created_at: string;
  updated_at: string;
}

export interface _Identification {
  id: number;
  doctor_id: number;
  id_type: string;
  identifier: string;
  expiration: Date | null;
  created_at: Date;
  updated_at: Date;
}

export interface _ProfileSpecialization {
  id: number;
  doctor_id: number;
  specialization_id: number;
  active: Boolean;
  created_at: Date;
  updated_at: Date;
  specialization: _Specialization;
}

interface _Specialization {
  id: number;
  major_id?: number | null;
  sub_id?: number | null;
  name: string;
  active: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface _PatientListItem extends _User {
  name: string;
  avatar: string;
  visit_date: Date;
  diagnosis: string;
  hmo: string;
  philhealth: string | null;
  for_extraction: boolean;
  phr_id?: number | null;
  eligible_trial_count?: number;
  eligible_support_program_count?: number;
  default_wallet_id: number;
  default_wallet_balance: number;
  on_watch_list: boolean;
  submission_form_types: string[];
}

export interface _PatientsOnWatchlist extends _PatientListItem {
  status: {
    snooze: string;
    last_updated_at: string;
    turn_off_notifications: false;
  };
}

export type _DoctorPatient = {
  patient: _User[];
  summary: _PatientSummary[];
};

export interface _NalagenDetails {
  lifestyle__fruit_vegetable: string;
  lifestyle__hormone_replacement_theraphy: string;
  lifestyle__oral_contraceptive: string;
  lifestyle__patient_exercise: string;
  pdmh__history_of_dcis: string;
  pdmh__history_of_lcis: string;
  pdmh__prior_thoracic_radiotheraphy: string;
  preference__risk_reducing_theraphy: string;
  preference__risk_reducing_surgery: string;
  preference__risk_reducing_counseling: string;
  preference__lifestyle_modification: string;
  sm__breast_cancer: string;
  sm__ovarian_cancer: string;
  sm__normal_result_breast_cancer: string;
  sm__normal_result_gynecologic_assessment: string;
  bdh_previous_breast_cancer_diagnoses: boolean | string;
  bdh_previous_breast_cancer_diagnoses_age: string;
  bdh_receptors__estrogen_positive: string;
  bdh_receptors__progestorene_positive: string;
  bdh_receptors__her2_positive: string;
  bdh_second_diagnosis: string;
  bdh_triple_negative: string;
  fh_known_predisposition_to_breast_cancer_in_family: string;
}

export interface _PatientSummary {
  id: number;
  doctor_id: number;
  patient_id: number;
  email: string;
  gender: string;
  current_address: Partial<_Address> | null;
  permanent_address: Partial<_Address> | null;
  contact_number: string | null;
  blood_type: string | null;
  philhealth: string | null;
  hmo: string | null;
  religion: string | null;
  marital_status: string | null;
  birth_date: Date;
  allergies: string | null;
  medications: string | null;
  present_medical_history: string | null;
  past_medical_history: string | null;
  complications: _Complications[];
  past_medical_history_details: _PastMedicalHistoryDetails;
  obgyn_history?: _ObGyneHistoryDetails;
  diabetes_history: _DiabetesHistoryDetails;
  family_history: string | null;
  latest_consultation_date: Date | null;
  family_history_details: _FamilyHistoryDetails[];
  social_history: string | null;
  additional_info: string | null;
  occupation: string | null;
  ethnicity: string | null;
  specific_ethnicity: string | null;
  payment_preference: string | null;
  landline_number: string | null;
  guardian_name: string | null;
  guardian_relation: string | null;
  guardian_contact_information: string | null;
  emergency_contact_name: string | null;
  emergency_contact_relation: string | null;
  emergency_contact_information: string | null;
  emergency_contact_address: string | null;
  cigarette_lifestyle: string | null;
  cigarette_lifestyle_onset: Date | null;
  cigarette_lifestyle_remark: string | null;
  cigarette_lifestyle_quit_date: Date | null;
  cigarette_lifestyle_months: string | null;
  alcohol_lifestyle: string | null;
  alcohol_lifestyle_onset: Date | null;
  alcohol_lifestyle_remark: string | null;
  alcohol_lifestyle_quit_date: Date | null;
  alcohol_lifestyle_months: string | null;
  diet_and_nutrition: Array<string>;
  diet_and_nutrition_onset: Date | null;
  diet_and_nutrition_remark: string | null;
  diet_and_nutrition_quit_date: Date | null;
  diet_and_nutrition_months: string | null;
  exercise_habits: string | null;
  exercise_habits_onset: Date | null;
  exercise_habits_remark: string | null;
  exercise_habits_quit_date: Date | null;
  exercise_habits_months: string | null;
  drug_lifestyle: string | null;
  drug_lifestyle_onset: Date | null;
  drug_lifestyle_remark: string | null;
  drug_lifestyle_quit_date: Date | null;
  drug_lifestyle_months: string | null;
  menarche: string | null;
  menopause: string | null;
  menopause_status: string | null;
  cycle_length: string | null;
  last_menstrual_period: Date | null;
  estimate_date_of_delivery: Date | null;
  age_of_gestation: string | null;
  menstrual_cycle: string | null;
  length_of_bleeding: string | null;
  pads_or_tampons: string | null;
  frequency: string | null;
  bleeding_between_periods: boolean;
  bleeding_after_intercourse: boolean;
  post_menopausal_bleeding: boolean;
  gravida: string | null;
  term_birth: string | null;
  preterm: string | null;
  abortion: string | null;
  living_children: string | null;
  mode_of_delivery: string | null;
  breastfeeding: string | null;
  contraception: string | null;
  complication_during_pregnancy: string | null;
  history_of_sti: string | null;
  pap_smear_history: string | null;
  gynecological_surgery: string | null;
  disease_history: string | null;
  breast_disease_history_details: _BreastDiseaseHistoryDetails;
  chronic_kidney_disease_history_details: _ChronicKidneyDiseaseHistoryDetails;
  liver_disease_history_details: _LiverDiseaseHistoryDetails;
  for_extraction: boolean;
  deceased: boolean;
  deceased_cause: string | null;
  place_of_birth: string | null;
  educational_attainment: string | null;
  environmental_exposure: string | null;
  chief_complaint: string | null;
  on_watch_list: boolean;
  status: _PatientStatus;
  risk_factors: _PatientRiskFactors;
  co_morbid_conditions: _CoMorbidConditions[];
  co_morbid_condition_remarks: string;
  identification_numbers: _IdentificationNumber[];
  employments: _Employment[];
  educations: _Education[];
  home: string;
  activities: string;
  handedness: string;
  suicidality: string | null;
  sex: string | null;
  development: string | null;
  neonatal_history: string | null;
  growth_charts: _GrowthCharts;
  created_at: Date;
  updated_at: Date;
  // to be defined
  summary_allergies: Array<any>;
  general_medical_history_remarks: string | null;
  birth_weight: _BirthWeight;
  general_medical_histories: _GeneralMedicalHistories[];
  weights: _Weights[];
  nationality: string;
  id_number: string;
  nalagen_details: _NalagenDetails;
  optha_details: _OphthaDetails;
  code?: string;
}

export type _OphthaDetails = {
  eye_records: _RecordDetails[];
  spectacles: _SpectacleDetails[];
  refractions: Array<string>;
  eye_records_last_refracted: Date;
};

export type _SpectacleDetails = {
  name: string;
  value?: string | null;
};

export type _RecordDetails = {
  name: string;
  value?: string | null;
};

export type _RefractionDetails = {
  od?: string | null;
  os?: string | null;
  pd?: string | null;
};

export type _ObLeopoldDetails = {
  i?: string | null;
  ii?: string | null;
  iii?: string | null;
  iv?: string | null;
};

// general_medical_history_remarks
// weight_remarks
// birth_weight: bw__g1, bw__g2, bw__g3, bw__g1_unit, bw__g2_unit, bw__g3_unit
// general_medical_histories = [{name:, value:, maintenance:,}]
// weights = [{weight:, weight_unit:, weight_gained:, weight_gained_unit:, weight_date, remarks}]

export type _IdentificationNumber = {
  id?: number;
  id_type: string;
  identifier: string;
};

export type _Employment = {
  id?: number;
  occupation: string;
  company_name: string;
  address: string;
  remarks: string;
};

export type _Education = {
  id?: number;
  educational_attainment: string;
  institution_name: string;
  remarks: string;
};

export type _GrowthCharts = {
  gc__height_value: string;
  gc__height_unit: string;
  gc__weight_value: string;
  gc__weight_unit: string;
  gc__remarks: string;
  gc__head_circumference_value: string;
  gc__head_circumference_unit: string;
};

export interface _PatientRiskFactors {
  rf__asthma_related_death_remarks: string | null;
  rf__asthma_emergency_room_consultation: boolean;
  rf__asthma_emergency_room_consultation_date: string | null;
  rf__asthma_hospitalized: boolean;
  rf__asthma_hospitalized_date: string | null;
  rf__asthma_intubated: boolean;
  rf__asthma_intubated_date: string | null;
  rf__asthma_lack_of_ics: boolean;
  rf__asthma_frequent_use_oral_steroid: boolean;
  rf__asthma_frequent_use_oral_steroid_frequency: string | null;
}

export interface _CoMorbidConditions {
  id?: number;
  name: string;
  value: any;
  maintenance: string;
}

export interface _Complications {
  id?: number;
  name: string;
  date_of_onset: any;
  value: any;
  remarks: string;
}
export interface _Weights {
  [key: string]: any;
  weight: {
    highest: number;
    lowest: number;
    [key: string]: number;
  };
  weight_unit: {
    highest: string;
    lowest: string;
  };
  weight_gained: number;
  weight_gained_unit: string;
  weight_date: Date | null;
  remarks: string;
}

export interface _GeneralMedicalHistories {
  name: string;
  value: any;
  maintenance: string;
}

export interface _BirthWeight {
  bw__g1: number;
  bw__g2: number;
  bw__g3: number;
  bw__g1_unit: string;
  bw__g2_unit: string;
  bw__g3_unit: string;
}

export interface _PatientStatus {
  disease__no_evidence: boolean;
  disease__deceased_from_disease: boolean;
  disease__deceased_from_other: boolean;
  disease__deceased_from_other_unrelated: boolean;
  disease__stable: boolean;
  disease__progressive: boolean;
  disease__status_unknown: boolean;

  disease__recurrence_general: boolean;
  disease__recurrence_local: boolean;
  disease__recurrence_regional: boolean;
  disease__recurrence_distant: boolean;
  disease__recurrence_progressive: boolean;

  treatment__undergoing: boolean;
  treatment__completed: boolean;
  treatment__incomplete: boolean;
  treatment__none: boolean;
  treatment__status_unknown: boolean;
  treatment__lost_to_followup: boolean;

  response__adverse: boolean;
  response__partial: boolean;
  response__complete: boolean;
  response__no_objective_clinical_benefit: boolean;

  turn_off_notifications: boolean;
  snooze: string;
  last_updated_at: string;
}

export type _PastMedicalHistoryDetails = {
  pmh_ecog: string;
  pmh_dm_bool: boolean;
  pmh_dm_value: string;
  pmh_hiv_bool: boolean;
  pmh_hiv_value: string;
  pmh_hpn_bool: boolean;
  pmh_hpn_value: string;
  pmh_hepatitis_bool: boolean;
  pmh_hepatitis_value: string;
  pmh_stroke_cva_bool: boolean;
  pmh_stroke_cva_value: string;
  pmh_thyroid_dse_bool: boolean;
  pmh_thyroid_dse_value: string;
  pmh_previous_surgeries: string;
  pmh_bronchial_asthma_bool: boolean;
  pmh_bronchial_asthma_value: string;
  pmh_previous_transfusion_bool: boolean;
  pmh_previous_transfusion_value: string;
};

export type _FamilyHistoryDetails = {
  id?: number;
  name: string;
  value: any;
  remarks: string;
};

export type _BreastDiseaseHistoryDetails = {
  bdh_g: string;
  bdh_p: string;
  bdh_t?: string;
  bdh_pt?: string;
  bdh_a?: string;
  bdh_l?: string;
  babies_count?: string;
  babies_anomalies_count?: string;
  bdh_pmp: string;
  bdh_data: string;
  bdh_lnmp: string;
  bdh_menarche_age: string;
  bdh_ocp_use_bool: boolean | string;
  bdh_menopause_age: string;
  bdh_menopause_date: Date | null;
  bdh_pcos_date: Date | null;
  bdh_ocp_use_value: string;
  bdh_breastfeeding_bool: boolean | string;
  bdh_breastfeeding_value: string;
  bdh_menstrual_intervals: string;
  bdh_family_hx_other_ca_bool: boolean | string;
  bdh_family_hx_breast_ca_bool: boolean | string;
  bdh_family_hx_other_ca_value: string;
  bdh_family_hx_breast_ca_value: string;
  bdh_previous_breast_surgery_bool: boolean | string;
  bdh_age_first_completed_pregnancy: string;
  bdh_previous_breast_surgery_value: string;
};

export type _ObGyneHistoryDetails = {
  obgyn_remarks?: string | null;
  obgyn_history__gravida?: string;
  obgyn_history__parity?: string;
  obgyn_history__term?: string;
  obgyn_history__preterm?: string;
  obgyn_history__abortion?: string;
  obgyn_history__living_children?: string;
  obgyn_history__babies_with_greater_than_8lbs?: string;
  obgyn_history__babies_with_anomalies?: string;
  obgyn_history__length_of_cycle?: string;
  last_menstrual_period?: Date | null;
  menstrual_cycle?: string | null;
  frequency?: string | null;
  obgyn_history__characteristics?: boolean | string;
  pads_or_tampons?: string | null;
  length_of_bleeding?: string | null;
  menarche?: string | null;
  menopause?: string | null;
  obgyn_history__date_of_menopause?: Date | null;
  obgyn_history__pcos?: Date | null;
};

export type _DiabetesHistoryDetails = {
  diabetes_history__newly_diagnosed: boolean;
  diabetes_history__previously_diagnosed: boolean;
  diabetes_history__date: string | null;
  diabetes_history__duration: string | null;
  diabetes_history__diagnosis_age: string | null;
  diabetes_history__suspect_diabetes: string | null;
  diabetes_history__type_1: boolean;
  diabetes_history__type_2: boolean;
  diabetes_history__gdm: boolean;
  diabetes_history__secondary: boolean;
  diabetes_history__igt_ift: string | null;
  diabetes_history__attended_diabetes_session: string | null;
  diabetes_history_treatment__sulfonylurea: boolean;
  diabetes_history_treatment__metformin: boolean;
  diabetes_history_treatment__agi: boolean;
  diabetes_history_treatment__tzd: boolean;
  diabetes_history_treatment__dpp: boolean;
  diabetes_history_treatment__sglt: boolean;
  diabetes_history_treatment__others: boolean;
  diabetes_history_treatment__others_value: string | null;
  diabetes_history_treatment__insulin: boolean;
  diabetes_history_treatment__insulin_type: string | null;
  diabetes_history_treatment__insulin_units: string | null;
  diabetes_history_treatment__incretin: boolean;
  diabetes_history_treatment__incretin_type: string | null;
  diabetes_history_treatment__incretin_mcg: string | null;
  diabetes_history_remarks: string | null;
};

export type _ChronicKidneyDiseaseHistoryDetails = {
  ckdh_previous_kidney_surgery: string;
  ckdh_previous_kidney_surgery_value: string;
  ckdh_family_hx_ckd: string;
  ckdh_family_hx_ckd_value: string;
  ckdh_family_hx_other_ckd: string;
  ckdh_family_hx_other_ckd_value: string;
};

export type _LiverDiseaseHistoryDetails = {
  ldh_previous_liver_resection: string;
  ldh_previous_liver_resection_value: string;
  ldh_previous_abdominal_surgery: string;
  ldh_previous_abdominal_surgery_value: string;
  ldh_tumor_type: string;
  ldh_tumor_type_value: string;
  ldh_family_hx_liver_disease: string;
  ldh_family_hx_liver_disease_value: string;
  ldh_family_hx_other_liver_disease: string;
  ldh_family_hx_other_liver_disease_value: string;
};

export type _PatientSummaryFlat = _PatientSummary &
  _PastMedicalHistoryDetails &
  _ObGyneHistoryDetails &
  _BreastDiseaseHistoryDetails & {
    family_history_details?: _FamilyHistoryDetails[];
    past_medical_history_details?: _PastMedicalHistoryDetails;
    breast_disease_history_details?: _BreastDiseaseHistoryDetails;
    chronic_kidney_disease_history_details?: _ChronicKidneyDiseaseHistoryDetails;
    liver_disease_history_details?: _LiverDiseaseHistoryDetails;
  };

export interface _ChangeHistory {
  profile: _ChangeHistoryLog[];
  history: _ChangeHistoryLog[];
  treatments: _ChangeHistoryLog[];
  vaccinations: _ChangeHistoryLog[];
}

export interface _ChangeHistoryLog {
  version: _ChangeHistoryVersion;
  whodunnit: string | null;
}

interface _ChangeHistoryVersion {
  id: number;
  item_id: number;
  item_type: string;
  event: string;
  whodunnit: string;
  object: string;
  object_changes: string;
  created_at: Date;
}

export interface _MedicalRepresentative {
  id?: number;
  profile_id?: number;
  first_name: string;
  last_name: string;
  contact_number: string;
  email: string;
  company: string;
  brand: string;
  notes: string;
  last_visit_date?: string | null;
}

export interface _MedRepProfile {
  id?: number;
  medical_representative_id?: number;
  contact_number: string;
  email: string;
  company: string;
  brand: string;
  notes: string;
}
