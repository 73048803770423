import { AxiosResponse } from 'axios';
import { login, setLocalStorageData } from 'common/services';
import { StoreAction } from 'constants/store';
import { ActionType } from 'models/store';
import { call, put, takeEvery } from 'redux-saga/effects';
import { handleLogout, signUp } from 'services/auth.service';
import { Toast } from 'components';

const { LOGIN, LOGOUT, SIGNUP } = StoreAction;

function* loginSaga(actions: ActionType) {
  try {
    const { data }: AxiosResponse = yield call(login, actions.payload);
    const { access_token } = data;
    yield call(setLocalStorageData, {
      authenticated: true,
      accessToken: access_token
    });
    yield put({ type: LOGIN.SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: LOGIN.FAILED, payload: err });
  }
}

function* signupSaga(actions: ActionType) {
  try {
    const { data }: AxiosResponse = yield call(signUp, actions.payload);
    yield put({ type: SIGNUP.SUCCESS, payload: data });
  } catch (err) {
    if (err.status === 422 || err.status === 500) {
      Toast.error('The email already exists. Please try again.');
    } else {
      Toast.error('There is an error encountered while signing up.');
    }
    yield put({ type: SIGNUP.FAILED, payload: err });
  }
}

function* logoutSaga() {
  try {
    yield call(handleLogout);
    yield put({ type: LOGOUT.SUCCESS });
  } catch (err) {
    yield put({ type: LOGOUT.FAILED, payload: err });
  }
}

export function* rootSaga() {
  yield takeEvery(LOGIN.REQUEST, loginSaga);
  yield takeEvery(LOGOUT.REQUEST, logoutSaga);
  yield takeEvery(SIGNUP.REQUEST, signupSaga);
}
