import { transformIncomingAppointmentsData } from 'common/transformer';
import { StoreAction } from 'constants/store';
import { PatientNameAndAvatar, MedicalRepresentative } from 'models';
import { ActionType, StoreAppointment } from 'models/store';

const { SET_SELECTED_DATE, PATIENT, CREATE, CANCEL, GET, MED_REP } = StoreAction.APPOINTMENT;
const { DATE_AND_CLINIC } = GET.VIA;
const { ADD_REFERENCE } = PATIENT;
const { ADD_MEDREP_REFERENCE } = MED_REP;

const INITIAL_STATE: StoreAppointment = {
  selectedDate: new Date(),
  appointmentsForSelectedDate: [],
  patientReferenceIndexById: {},
  medRepReferenceIndexById: {},
  transaction: {
    getAppointmentsForSelectedDate: {
      inProgress: false
    },
    createNewAppointment: {
      inProgress: false
    }
  }
};

function appointmentReducer(state = INITIAL_STATE, action: ActionType): StoreAppointment {
  switch (action.type) {
    case SET_SELECTED_DATE.SUCCESS:
      return {
        ...state,
        selectedDate: action.payload
      };
    case DATE_AND_CLINIC.REQUEST:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          getAppointmentsForSelectedDate: {
            inProgress: true
          }
        }
      };
    case DATE_AND_CLINIC.FAILED:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          getAppointmentsForSelectedDate: {
            inProgress: false,
            error: action.payload
          }
        }
      };
    case DATE_AND_CLINIC.SUCCESS:
      return {
        ...state,
        appointmentsForSelectedDate: transformIncomingAppointmentsData(action.payload),
        transaction: {
          ...state.transaction,
          getAppointmentsForSelectedDate: {
            inProgress: false
          }
        }
      };
    case ADD_REFERENCE.SUCCESS:
      return {
        ...state,
        patientReferenceIndexById: action.payload.reduce(
          (indexById: { [key: number]: PatientNameAndAvatar }, patient: PatientNameAndAvatar) => {
            return {
              ...indexById,
              [patient.id]: patient
            };
          },
          {}
        )
      };
    case ADD_MEDREP_REFERENCE.SUCCESS:
      return {
        ...state,
        medRepReferenceIndexById: action.payload.reduce(
          (indexById: { [key: number]: MedicalRepresentative }, medRep: MedicalRepresentative) => {
            return {
              ...indexById,
              [`${medRep.id}`]: medRep
            };
          },
          {}
        )
      };
    case CREATE.REQUEST:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          createNewAppointment: {
            inProgress: true
          }
        }
      };
    case CREATE.FAILED:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          createNewAppointment: {
            inProgress: false,
            error: action.payload
          }
        }
      };
    case CREATE.SUCCESS:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          createNewAppointment: {
            inProgress: false
          }
        }
      };
    case CANCEL.SUCCESS:
      const updatedList = state.appointmentsForSelectedDate.filter(appointment => appointment.id !== action.payload);
      return {
        ...state,
        appointmentsForSelectedDate: updatedList
      };
    default:
      return state;
  }
}

export default appointmentReducer;
