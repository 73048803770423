import { AxiosResponse, AxiosError } from 'axios';
import { format, isValid } from 'date-fns';
import { User, SecretaryDoctor, UserTheme } from 'models';
import { LocalStorage } from 'defaults';
import { EncounterType } from 'constants/form';
import i18n from 'i18n';
import { useEnv } from 'hooks';

interface LocalStorageData {
  authenticated?: boolean;
  accessToken?: string;
  assumeToken?: string;
  activeAccount?: number;
  userTheme?: string;
}

export const getLocalStorageData = () => {
  const authenticated = localStorage.getItem(LocalStorage.isAuthenticated) || '';
  const activeAccount = localStorage.getItem(LocalStorage.activeAccount) || '0';
  const activeClinic = localStorage.getItem(LocalStorage.clinicId) || '0';
  const assumeToken = localStorage.getItem(LocalStorage.assumeToken) || '';
  const accessToken = localStorage.getItem(LocalStorage.accessToken) || '';
  const theme = localStorage.getItem(LocalStorage.theme) || '';
  const notifyLater = localStorage.getItem(LocalStorage.notifyLater) || '';
  const feedbackClosed = localStorage.getItem(LocalStorage.feedbackClosed) || false;
  return {
    isAuthenticated: authenticated === 'true' ? true : false,
    accessToken: accessToken && accessToken !== 'undefined' ? accessToken : '',
    assumeToken: assumeToken && assumeToken !== 'undefined' ? assumeToken : '',
    activeAccount: Number(activeAccount),
    clinicId: Number(activeClinic),
    userTheme: theme,
    notifyLater,
    feedbackClosed
  };
};

export const setLocalStorageData = (data: LocalStorageData) => {
  const { authenticated, accessToken, assumeToken, activeAccount, userTheme } = data;
  !!authenticated && localStorage.setItem(LocalStorage.isAuthenticated, 'true');
  accessToken && localStorage.setItem(LocalStorage.accessToken, accessToken);
  assumeToken && localStorage.setItem(LocalStorage.assumeToken, assumeToken);
  activeAccount && localStorage.setItem(LocalStorage.activeAccount, `${activeAccount}`);
  userTheme && localStorage.setItem(LocalStorage.theme, userTheme);
};

export function successHandler({ data }: AxiosResponse) {
  return {
    data,
    error: null
  };
}

export function errorHandler(err: AxiosError) {
  let error = {
    error: '',
    message: '',
    status: -1
  };
  const res: AxiosResponse | undefined = err.response;
  if (res) {
    const tmp: string | undefined = res.data.error;
    if (tmp) {
      error.error = tmp.toLowerCase().replace(new RegExp(' ', 'g'), '_').replace(/\./g, '');
      error.status = res.status || -1;
      error.message = err?.response?.data?.error || res.data.message || '';
    } else {
      error.message = err?.response?.data?.error || res.data.message || '';
      error.status = res.status || -1;
    }
  }
  return error;
}

export function parseDate(date: Date | null, extended: boolean = false) {
  if (date === null) return null;
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dateStr = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  let timeStr = '';
  if (extended) {
    const hours = date.getHours().toString().padStart(2, '0');
    const min = date.getMinutes().toString().padStart(2, '0');
    const sec = date.getSeconds().toString().padStart(2, '0');
    timeStr = `${hours}:${min}:${sec}`;
  }
  return `${dateStr} ${timeStr}`.trim();
}

export const formatDate = (date: Date, _format: string) => {
  if (!date || !isValid(date)) return '';
  return format(date, _format);
};

export const isILD = (patientName: string) => {
  const userTheme = getLocalStorageData().userTheme;
  if (patientName?.length > 0) {
    return patientName.startsWith('ILD-') && (userTheme === UserTheme.MedCheck);
  } else {
    return false;
  }
}

export const getFullName = (user: User | SecretaryDoctor | any) => {
  const { firstName, lastName, middleName, suffix } = user;
  const middleInitial = middleName ? ` ${middleName[0]}.` : '';
  return `${firstName}${middleInitial} ${lastName} ${suffix ? suffix : ''}`;
};

export const printFullName = (user: User | SecretaryDoctor | any) => {
  const { firstName, lastName, middleName, suffix, code } = user;
  const middleInitial = middleName ? ` ${middleName[0]}.` : '';
  return isILD(firstName) ? `${code}` : `${firstName}${middleInitial} ${lastName} ${suffix ? suffix : ''}`;
}

export function titleCase(str: string) {
  const strArray = str.toLowerCase().split(' ');
  let final = [];
  for (let word of strArray) {
    final.push(word.charAt(0).toUpperCase() + word.slice(1));
  }
  return final.join(' ');
}

export function getAge(birthDate: Date) {
  const today = new Date();
  const diff_ms = today.getTime() - birthDate.getTime();
  const age_dt = new Date(diff_ms);
  const year = Math.abs(age_dt.getUTCFullYear() - 1970);

  const nowMonth = today.getMonth();
  const dobMonth = birthDate.getMonth();
  const adjustment = nowMonth < dobMonth ? 12 : 0;
  const month = nowMonth + adjustment - dobMonth;

  return {
    year,
    month,
    toString: () => {
      let str = '';
      if (year > 0) str = `${year} ${i18n.t('year', { count: year })}`;
      if (month > 0) str += ` ${month} ${i18n.t('month', { count: year })}`;
      return `${str} ${i18n.t('old')}`;
    }
  };
}

export function getSearchParams(search: any, key: string) {
  const params = new URLSearchParams(search);
  return params.get(key);
}

export function encounterTypeToString(type?: EncounterType) {
  switch (type) {
    case EncounterType.Endocrinology:
      return i18n.t('endocrinology_encounter_form', { ns: 'encounter' });
    case EncounterType.Diabetology:
      return i18n.t('diabetology_encounter_form', { ns: 'encounter' });
    case EncounterType.Cardiology:
      return i18n.t('cardiology_encounter_form', { ns: 'encounter' });
    case EncounterType.Oncology:
      return i18n.t('oncology_encounter_form', { ns: 'encounter' });
    case EncounterType.HPB:
      return 'Hepato Pancreato Biliary Encounter Form';
    case EncounterType.Rheumatology:
      return i18n.t('rheumatology_encounter_form', { ns: 'encounter' });
    case EncounterType.BCI:
      return i18n.t('bci_oncology_encounter_form', { ns: 'encounter' });
    case EncounterType.Pulmonology:
      return i18n.t('pulmonology_encounter_form', { ns: 'encounter' });
    case EncounterType.Pediatrics:
      return i18n.t('pediatrics_encounter_form', { ns: 'encounter' });
    case EncounterType.Service:
      return 'Service Encounter Form';
    case EncounterType.ActNow:
      return 'Act Now Encounter Form';
    case EncounterType.ENT:
      return i18n.t('ent_encounter_form', { ns: 'encounter' });
    case EncounterType.Ophthalmology:
      return 'Ophthalmology Encounter Form';
    case EncounterType.Ob:
      return 'OB Encounter Form';
    case EncounterType.Gyne:
      return 'Gyne Encounter Form';
    default:
      return i18n.t('standard_encounter_form', { ns: 'encounter' });
  }
}

export const getBrCaStage = (t: string, n: string, m: string) => {
  if (!t || !n || !m) return '';
  const value = [t, n, m];

  if (t === 'T4' && m === 'M0') return 'IIIB';
  if (n === 'N3' && m === 'M0' && t !== 'Tis') return 'IIIC';
  if (m === 'M1' && t !== 'Tis') return 'IV';

  switch (JSON.stringify(value)) {
    case JSON.stringify(['Tis', 'N0', 'M0']):
      return '0';
    case JSON.stringify(['T1', 'N0', 'M0']):
      return 'I';
    case JSON.stringify(['T0', 'N1', 'M0']):
      return 'IIA';
    case JSON.stringify(['T1', 'N1', 'M0']):
      return 'IIA';
    case JSON.stringify(['T2', 'N0', 'M0']):
      return 'IIA';
    case JSON.stringify(['T2', 'N1', 'M0']):
      return 'IIB';
    case JSON.stringify(['T3', 'N0', 'M0']):
      return 'IIB';
    case JSON.stringify(['T0', 'N2', 'M0']):
      return 'IIIA';
    case JSON.stringify(['T1', 'N2', 'M0']):
      return 'IIIA';
    case JSON.stringify(['T2', 'N2', 'M0']):
      return 'IIIA';
    case JSON.stringify(['T3', 'N1', 'M0']):
      return 'IIIA';
    case JSON.stringify(['T3', 'N2', 'M0']):
      return 'IIIA';
    // case JSON.stringify(['T4', 'Any N', 'M0']): return 'IIIB';
    // case JSON.stringify(['Any T', 'N3', 'M0']): return 'IIIC';
    // case JSON.stringify(['Any T', 'Any N', 'M1']): return 'IV';
    default:
      return '';
  }
};

export const getKey = (value: string, object: any) => {
  for (let key of Object.keys(object)) {
    if (object[key] === value) return key.replace(/([a-z](?=[A-Z]))/g, '$1 ');
  }
  return null;
};

export const iSJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
