import { Gender, PatientOnReminder, PatientSubmission } from 'models';
import { _MedCert } from 'models/endpoint/medcert';
import { _PatientOnReminder, _PatientSubmission } from 'models/endpoint/patient';
import { _Survey } from 'models/endpoint/survey';
import { MedCert } from 'models/medcert';
import { Survey } from 'models/survey';

export function transformInPatientSubmissionSummary(data: _PatientSubmission): PatientSubmission {
  const [patient] = data.patient;
  const [summary] = data.summary;
  return {
    id: patient.id,
    fileUrl: patient.file_url || '',
    firstName: patient.first_name || '',
    middleName: patient.middle_name || '',
    lastName: patient.last_name || '',
    code: patient.code || '',
    gender: summary.gender ? (summary.gender as Gender) : '',
    birthDate: summary.birth_date ? new Date(summary.birth_date) : null,
    address: summary.current_address || '',
    permanentAddress: summary.permanent_address || '',
    heightValue: patient.latest_vitals ? patient.latest_vitals.height_value || 0 : 0,
    heightUnit: patient.latest_vitals ? patient.latest_vitals.height_unit || '' : '',
    weightValue: patient.latest_vitals ? patient.latest_vitals.weight_value || 0 : 0,
    weightUnit: patient.latest_vitals ? patient.latest_vitals.weight_unit || '' : '',
    bcmapEnrolled: patient.bcmap_enrolled
      ? {
          bcmap: patient.bcmap_enrolled.bcmap
        }
      : {
          bcmap: false
        }
  };
}

export const transformInPatientOnReminder = (data: _PatientOnReminder[]): PatientOnReminder[] => {
  return (data || []).map(item => {
    return {
      id: item.id,
      name: item.name,
      code: item.code,
      status: {
        lastUpdatedAt: item.status.last_updated_at ? new Date(item.status.last_updated_at) : null
      },
      visitDate: item.visit_date ? new Date(item.visit_date) : null,
      createdAt: new Date(item.created_at)
    };
  });
};

export const transformInSurvey = (data: _Survey): Survey => {
  return {
    id: data.id,
    type: data.type,
    patientId: data.patient_id,
    doctorId: data.doctor_id,
    createdAt: data.created_at ? new Date(data.created_at) : null,
    updatedAt: data.updated_at ? new Date(data.updated_at) : null,
    questions: data.questions
  };
};

export const transformInMedCert = (data: _MedCert): MedCert => {
  return {
    id: data.id,
    patientId: data.patient_id,
    patientHealthRecordId: data.patient_health_record_id,
    medicalCertificateTemplateId: data.medical_certificate_template_id,
    controlNumber: data.control_number,
    name: data.name,
    createdAt: data.created_at ? new Date(data.created_at) : null,
    updatedAt: data.updated_at ? new Date(data.updated_at) : null,
    details: {
      medCertBody: data.details.med_cert_body,
      sentFrom: data.details.sent_from,
      sentTo: data.details.sent_to,
      sentToRecipientEmail: data.details.sent_to_recipient_email
    }
  };
};
