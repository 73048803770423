import { StoreAction } from 'constants/store';
import { ActionType } from 'models/store';
import { createAction } from 'services/utils.service';
import {
  User,
  DoctorProfileForm,
  Identification,
  Clinic,
  SecretaryProfileForm,
  NavigatorProfileForm,
  AccountHMO
} from 'models';

const { SECRETARY, NAVIGATOR } = StoreAction;
const { GET_CURRENT, UPDATE_AVATAR, CHANGE_PASSWORD } = StoreAction.USER;
const { GET_PROFILE, UPDATE_PROFILE, GET_CLINIC, GET_CLINIC_HEADER } = StoreAction.DOCTOR;

interface Current {
  user?: User;
  identifications?: Identification[];
  hmos?: AccountHMO[];
}

export type GetCurrentUserAction = () => ActionType;
export type UpdateCurrentUserAvatarAction = (file: File) => ActionType;
export type GetDoctorProfileAction = () => ActionType;
export type GetDoctorClinicAction = () => ActionType;
export type GetDoctorClinicHeaderAction = () => ActionType;
export type UpdateDoctorProfileAction = (payload: DoctorProfileForm, current: Current) => ActionType;
export type UpdateSecretaryProfileAction = (
  payload: SecretaryProfileForm,
  current: Current & { clinics: Clinic[] }
) => ActionType;
export type UpdateNavigatorProfileAction = (payload: NavigatorProfileForm, currentUser: User) => ActionType;
export type ChangePasswordAction = (currentPassword: string, newPassword: string) => ActionType;

export const getCurrentUser: GetCurrentUserAction = () => createAction(GET_CURRENT.REQUEST, null);

export const updateCurrentUserAvatar: UpdateCurrentUserAvatarAction = (file: File) =>
  createAction(UPDATE_AVATAR.REQUEST, { file });

export const getDoctorProfile: GetDoctorProfileAction = () => createAction(GET_PROFILE.REQUEST, null);

export const getDoctorClinic: GetDoctorClinicAction = () => createAction(GET_CLINIC.REQUEST, null);

export const getDoctorClinicHeader: GetDoctorClinicHeaderAction = () => createAction(GET_CLINIC_HEADER.REQUEST, null);

export const updateDoctorProfile: UpdateDoctorProfileAction = (payload: DoctorProfileForm, current: Current) =>
  createAction(UPDATE_PROFILE.REQUEST, { payload, current });

export const updateSecretaryProfile: UpdateSecretaryProfileAction = (
  payload: SecretaryProfileForm,
  current: Current & { clinics: Clinic[] }
) => createAction(SECRETARY.UPDATE_PROFILE.REQUEST, { payload, current });

export const updateNavigatorProfile: UpdateNavigatorProfileAction = (
  payload: NavigatorProfileForm,
  currentUser: User
) => createAction(NAVIGATOR.UPDATE_PROFILE.REQUEST, { payload, currentUser });

export const changePassword: ChangePasswordAction = (currentPassword: string, newPassword: string) =>
  createAction(CHANGE_PASSWORD.REQUEST, { currentPassword, newPassword });
