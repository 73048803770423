import React, { useCallback, useContext, useRef, useState } from 'react';

type AutosaveContextT = {
  isAutosave: boolean;
  disable: false;
  notifyWhenAllChangesAreSaved: () => Promise<void>;
  // there are hidden private fields here that are secret to implementation
};

const AutosaveContext = React.createContext<AutosaveContextT>({
  isAutosave: false,
  disable: false,
  notifyWhenAllChangesAreSaved: () => Promise.resolve(),
  // @ts-ignore secret fields
  enqueue: () => {
    return () => {};
  }
});

export function AutosaveContextProvider({ children, disable }: { children: React.ReactNode; disable?: boolean }) {
  const saveQueue = useRef<Set<number>>(new Set());
  const listeners = useRef<(() => void)[]>([]);
  const enqueue = useCallback((fn: Promise<void>) => {
    const syntheticId = Math.random() * 1000000;
    saveQueue.current.add(syntheticId);
    const clean = () => {
      saveQueue.current.delete(syntheticId);
      if (saveQueue.current.size == 0) {
        listeners.current.forEach(l => l());
        listeners.current = [];
      }
    };
    fn.catch(e => {
      return;
    }).finally(clean);

    return clean;
  }, []);

  const notifyWhenAllChangesAreSaved = useCallback(() => {
    return new Promise<void>((res, rej) => {
      if (saveQueue.current.size == 0) {
        res();
      } else {
        listeners.current.push(res);
      }
    });
  }, []);

  return (
    <AutosaveContext.Provider
      value={{
        isAutosave: true,
        notifyWhenAllChangesAreSaved,
        disable,
        // @ts-ignore private method used only by autosave tooling
        enqueue
      }}
    >
      {children}
    </AutosaveContext.Provider>
  );
}

export function useAutosaveContext() {
  return useContext(AutosaveContext);
}
