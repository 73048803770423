import React from 'react';
import isEqual from 'lodash/isEqual';
import { Button } from 'components/button/Button';
import { useTranslation } from 'react-i18next';

export interface ModalProps {
  title?: string;
  hideHeader?: boolean;
  component?: any;
  toggle?: () => void;
  width?: string;
  index?: number;
}

const ModalComponent: React.FC<ModalProps> = ({ title, component = null, width, toggle, hideHeader, index }) => {
  const [t] = useTranslation();
  let style = {};
  if (width) {
    style = {
      maxWidth: width
    };
  }

  return (
    <div className="modal" style={{ zIndex: 1202 + (index || 0) }}>
      <div className="modal__container" style={style}>
        {!hideHeader && (
          <div className="modal__header">
            <p className="modal__title">{title}</p>
            <span className="spacer"></span>
            <Button variant="text" label={t('close')} onClick={toggle} />
          </div>
        )}
        <div className="modal__body">{component}</div>
      </div>
    </div>
  );
};

export const Modal = React.memo(ModalComponent, (prev, next) => isEqual(prev, next));
