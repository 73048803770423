import { call, put, takeLatest } from 'redux-saga/effects';
import { StoreAction } from 'constants/store';
import { ActionType } from 'models/store';
import { searchSpecialization } from 'services/specialization.service';

const { SEARCH } = StoreAction.SPECIALIZATION;

function* searchSpecializationSaga(actions: ActionType) {
  try {
    const { data } = yield call(searchSpecialization, actions.payload);
    yield put({ type: SEARCH.SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: SEARCH.FAILED, payload: err });
  }
}

export function* rootSaga() {
  yield takeLatest(SEARCH.REQUEST, searchSpecializationSaga);
}
