import {
  BCI_DIAGNOSIS_DEFAULT,
  ONCO_DIAGNOSIS_DEFAULT,
  PULMO_DIAGNOSIS_DEFAULT,
  RHEUMA_DIAGNOSIS_DEFAULT,
  STANDARD_DIAGNOSIS_DEFAULT,
  DEFAULT_PAGINATION
} from 'defaults';
import {
  BCIEncounter,
  DiabEncounter,
  Encounter,
  EndocrinologyEncounter,
  KeyValue,
  OncologyEncounter,
  PediatricsEncounter,
  PulmonologyEncounter,
  RheumatologyEncounter,
  StandardEncounter,
  ActNowEncounter,
  Vitals,
  ENTEncounter,
  OphthalmologyEncounter,
  ObEncounter,
  GyneEncounter
} from 'models';
import { _HealthRecord } from 'models/endpoint';
import { ActionType, OtherActions, Pagination, ReducerType } from 'models/store';

export function createAction(type: string, payload: any, others?: OtherActions): ActionType {
  return {
    type,
    payload,
    others
  };
}

export function createActionTypes(id: number, type: string): ReducerType {
  return {
    REQUEST: `[${id}.1] ${type} request`,
    SUCCESS: `[${id}.2] ${type} success`,
    FAILED: `[${id}.3] ${type} failed`,
    RESET: `[${id}.4] ${type} reset`
  };
}

export const updatedBMI = (encounter: Encounter): Encounter => {
  return {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      bmi: computeBMI(encounter.vitals)
    }
  };
};

export const computeBMI = ({ heightValue, weightValue, heightUnit, weightUnit }: Partial<Vitals>) => {
  if (!heightUnit || !weightUnit || !heightValue || !weightValue) return 0;
  const _height = heightUnit === 'in' ? (heightValue * 2.54) / 100 : heightValue / 100;
  const _weight = weightUnit === 'lb' ? weightValue / 2.20462 : weightValue;
  return parseFloat((_weight / (_height * _height)).toFixed(1));
};

export const computeBSA = ({ heightValue, weightValue, heightUnit, weightUnit }: any) => {
  if (!heightUnit || !weightUnit || !heightValue || !weightValue) return '0';
  const _height = heightUnit === 'cm' ? heightValue : heightValue * 2.54;
  const _weight = weightUnit === 'kg' ? weightValue : weightValue / 2.20462;
  const BSA = (_weight * _height) / 3600;
  return Math.sqrt(BSA).toFixed(2);
};

export const compareObject = (newData: any[], oldData: any[], key: string = 'id') => {
  const old_ids = oldData.map(n => n[key]);
  const current_ids = newData.filter(n => n[key] > 0).map(n => n[key]);
  const toAdd = newData.filter(n => n[key] <= 0);
  const toUpdate = newData.filter(d => old_ids.includes(d[key]));
  const toDelete = oldData.filter(d => !current_ids.includes(d[key]));
  return {
    insert: toAdd,
    update: toUpdate,
    remove: toDelete
  };
};

export const isEncounterElapsed = (createdAt: Date, visitDate: Date) => {
  const latestDate = createdAt.getTime() > visitDate.getTime() ? createdAt : visitDate;
  if (latestDate.getTime() > Date.now()) return false;
  const diffTime = Math.abs(Date.now() - latestDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays >= 21;
};

export const keyToLabel = (text: string) => {
  let label = '';
  for (let i = 0; i < text.length; i++) {
    const ch = text[i];
    if (i === 0) {
      label += ch.toUpperCase();
    } else if (ch === ch.toUpperCase()) {
      label += ` ${ch}`;
    } else {
      label += ch;
    }
  }
  return label;
};

export const applyStandardFormDefaults = (encounter: StandardEncounter) => {
  let newEncounter: StandardEncounter = {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg'
    }
  };
  return newEncounter;
};

export const applyENTFormDefaults = (encounter: ENTEncounter) => {
  let newEncounter: ENTEncounter = {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg'
    }
  };
  return newEncounter;
};

export const applyEndocrinologyDefaults = (encounter: EndocrinologyEncounter) => {
  let newEncounter: EndocrinologyEncounter = {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg',
      bloodSugarUnit: encounter.vitals.bloodSugarUnit || 'mmol/L'
    }
  };

  return newEncounter;
};

export const applyDiabDefaults = (encounter: DiabEncounter) => {
  let newEncounter: DiabEncounter = {
    ...encounter,
    followUpSchedule: encounter.followUpSchedule ?? {
      notify_email: false,
      notify_sms: false,
      patient_id: encounter.patientId,
      reminder_date: null
    },
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg',
      bloodSugarUnit: encounter.vitals.bloodSugarUnit || 'mmol/L'
    }
  };

  return newEncounter;
};

export const applyActNowFormDefaults = (encounter: ActNowEncounter) => {
  let newEncounter: ActNowEncounter = {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg'
    }
  };
  return newEncounter;
};

export const applyOphthalmologyDefaults = (encounter: OphthalmologyEncounter) => {
  let newEncounter: OphthalmologyEncounter = {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg'
    }
  };

  return newEncounter;
};

export const applyObDefaults = (encounter: ObEncounter) => {
  let newEncounter: ObEncounter = {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg'
    }
  };

  return newEncounter;
};

export const applyGyneDefaults = (encounter: GyneEncounter) => {
  let newEncounter: GyneEncounter = {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg'
    }
  };

  return newEncounter;
};

export const applyBCIFormDefaults = (encounter: BCIEncounter) => {
  let newEncounter: BCIEncounter = {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg'
    }
  };

  return newEncounter;
};

export const applyOncoFormDefaults = (encounter: OncologyEncounter) => {
  let newEncounter: OncologyEncounter = {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg'
    }
  };

  return newEncounter;
};

export const applyRheumaFormDefaults = (encounter: RheumatologyEncounter) => {
  let newEncounter: RheumatologyEncounter = {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg'
    }
  };

  return newEncounter;
};

export const applyPediaFormDefaults = (encounter: PediatricsEncounter, doctorFullName: string) => {
  const visitDate = encounter.visitDate;
  let newEncounter: PediatricsEncounter = {
    ...encounter,
    vitals: {
      ...encounter.vitals,
      headCircumferenceValue: encounter.vitals.headCircumferenceValue || 0,
      headCircumferenceUnit: encounter.vitals.headCircumferenceUnit || 'cm',
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg'
    },
    administeredVaccines: encounter.administeredVaccines.length > 0 ? encounter.administeredVaccines : [],
    scheduledVaccines:
      encounter.scheduledVaccines.length > 0
        ? encounter.scheduledVaccines.map(item => ({ ...item, administeredBy: doctorFullName, givenAt: visitDate }))
        : [],
    notifyEmail: encounter.notifyEmail || false,
    notifySms: encounter.notifySms || false
  };

  return newEncounter;
};

const supplementDefaultForPulmoFields = (fieldAsObject: any, defaultFields: any) => {
  if (!fieldAsObject) return defaultFields;
  if (Object.keys(fieldAsObject).length === 0) return defaultFields;
  let returnObject = {};
  for (let key of Object.keys(fieldAsObject)) {
    returnObject = {
      ...returnObject,
      [key]: fieldAsObject[key] !== undefined ? fieldAsObject[key] : defaultFields[key]
    };
  }
  return returnObject;
};
export const applyPulmoFormDefaults = (encounter: PulmonologyEncounter) => {
  let newEncounter: PulmonologyEncounter = {
    ...encounter,
    subjective: {
      ...encounter.subjective,
      cough: encounter.subjective.cough || false,
      sputumProduction: encounter.subjective.sputumProduction || false,
      wheezing: encounter.subjective.wheezing || false,
      chestTightness: encounter.subjective.chestTightness || false,
      shortnessOfBreath: encounter.subjective.shortnessOfBreath || false,
      duration: encounter.subjective.duration || '',
      unintentionalWeightLoss: encounter.subjective.unintentionalWeightLoss || false,
      chestBackPain: encounter.subjective.chestBackPain || false,
      malaise: encounter.subjective.malaise || false,
      fever: encounter.subjective.fever || false,
      feverTemperature: encounter.subjective.feverTemperature || '',
      nightSweats: encounter.subjective.nightSweats || false
    },
    vitals: {
      ...encounter.vitals,
      heightUnit: encounter.vitals.heightUnit || 'cm',
      weightUnit: encounter.vitals.weightUnit || 'kg',
      neckCircumferenceUnit: encounter.vitals.neckCircumferenceUnit || 'cm'
    },
    riskFactors: supplementDefaultForPulmoFields(encounter.riskFactors, {
      withRiskFactors: '',
      hostFactors: false,
      tobaccoSmoke: false,
      smokeFromHomeCookingAndHeatingFuels: false,
      others: ''
    }),
    spirometrySpirometricDiagnosis: supplementDefaultForPulmoFields(encounter.spirometrySpirometricDiagnosis, {
      dateTaken: null,
      fev1Fevc: '',
      preBdFev1: '',
      postBdFev: '',
      frequency: '',
      daytimeSymptoms: '',
      relieverNeeded: '',
      anyNightWalking: '',
      anyActivityLimitation: ''
    }),
    triggers: supplementDefaultForPulmoFields(encounter.triggers, {
      withTriggers: '',
      pollens: false,
      coldAir: false,
      strongOdors: false,
      smoke: false,
      dustMites: false,
      humidAir: false,
      occupation: false,
      exerciseOrPhysicalActivities: false,
      molds: false,
      emotions: false,
      animalDander: false,
      medications: '',
      food: ''
    }),
    smokingStatus: supplementDefaultForPulmoFields(encounter.smokingStatus, {
      smoker: '',
      smokesPerDay: '',
      willingToQuit: '',
      environmentalTobaccoExposure: ''
    }),
    psychologicalIssues: supplementDefaultForPulmoFields(encounter.psychologicalIssues, {
      withPsychologicalIssues: '',
      worried: '',
      limitation: '',
      workplaceIssue: ''
    }),
    assessmentOfSymptoms: supplementDefaultForPulmoFields(encounter.assessmentOfSymptoms, {
      mMRCScore: '',
      coughing: '',
      phlegm: '',
      chestTightness: '',
      breathless: '',
      activitiesAtHome: '',
      lungConditionConfidence: '',
      soundlySleep: '',
      energyLevels: '',
      tonsillarGrade: '',
      mallampatiScore: '',
      essInCarStopped: '',
      essSittingQuietly: '',
      essSittingInactive: '',
      essLyingDownToRest: '',
      essPassengerInACar: '',
      essSittingAndResting: '',
      essSittingAndTalking: '',
      essSittingAndWatchingTv: ''
    }),
    notesAndFindingsAdditions: supplementDefaultForPulmoFields(encounter.notesAndFindingsAdditions, {
      wheezes: false,
      rales: false,
      localize: ''
    }),
    medications: supplementDefaultForPulmoFields(encounter.medications, {
      medicationsCompliant: '',
      saba: '',
      sabaDate: null,
      laba: '',
      labaDate: null,
      lama: '',
      lamaDate: null,
      lamaLaba: '',
      lamaLabaDate: null,
      lamaIcs: '',
      lamaIcsDate: null,
      others: '',
      othersDate: null
    }),
    medicationalIssues: supplementDefaultForPulmoFields(encounter.medicationalIssues, {
      shakiness: false,
      cough: false,
      soreThroat: false,
      fastHeartbeat: false,
      badTaste: false,
      nervousness: false,
      upsetStomach: false,
      other: ''
    }),
    citGoodTechnique: encounter.citGoodTechnique || '',
    citErrorsSpecify: encounter.citErrorsSpecify || '',
    selfManagementSupport: supplementDefaultForPulmoFields(encounter.selfManagementSupport, {
      asthmaActionPlan: false,
      peakFlowAndSymptomDiary: false,
      adviceOnTriggerAvoidance: false,
      inhalerDeviceTraining: false,
      inhalerDeviceTrainingDoneBy: '',
      asthmaEducation: false,
      asthmaEducationDoneBy: '',
      referredToAsthmaClub: false,
      referredToAsthmaClubDate: null,
      copdEducation: false,
      copdEducationDoneBy: '',
      referredToPulmonaryRehab: false,
      referredToPulmonaryRehabDate: null
    }),
    intensifiedCaseFindings: supplementDefaultForPulmoFields(encounter.intensifiedCaseFindings, {
      unexplainedCough: '',
      closeContactKnownTb: '',
      hrgHivAids: false,
      hrgDiabetes: false,
      hrgEsrd: false,
      hrgCancer: false,
      hrgConnectiveTissueDisorder: false,
      hrgAutoimmuneDisease: false,
      hrgSilicosis: false,
      hrgPatientUnderwentGastrectomy: false,
      hrgPatientProlongedSteroids: false,
      hrpElderly: false,
      hrpUrbanPoor: false,
      hrpInmates: false,
      hrpOtherSettings: ''
    }),
    bacteriologicalStatus: supplementDefaultForPulmoFields(encounter.bacteriologicalStatus, {
      afpSputum: false,
      afpSputum1stDate: null,
      afpSputum2ndDate: null,
      genexpert: false,
      genexpertDate: null,
      tbLamp: false,
      tbLampDate: null
    }),
    ppdSkinTest: supplementDefaultForPulmoFields(encounter.ppdSkinTest, {
      ppd: '',
      ppdSkinReaction: '',
      ppdDate: null
    }),
    ptbDiagnosticXray: supplementDefaultForPulmoFields(encounter.ptbDiagnosticXray, {
      upperLobeInfiltrates: false,
      hilar: false,
      miliaryPattern: false,
      cavitaryLesion: false,
      cavitaryLesionRemarks: '',
      nodulePoorlyDefined: false,
      nodulePoorlyDefinedRemarks: '',
      pleuralEffusion: false,
      pleuralEffusionRemarks: '',
      otherDiagnosticFindings: ''
    }),
    genexpertMtbRif: encounter.genexpertMtbRif ? encounter.genexpertMtbRif : '',
    sleepEvaluation: supplementDefaultForPulmoFields(encounter.sleepEvaluation, {
      confusion: false,
      dryMouth: false,
      irritability: false,
      legsJerking: false,
      snoringRate: '',
      forgetfulness: false,
      hallucination: false,
      howBreathing: '',
      sexualProblems: false,
      morningHeadaches: false,
      wakingUpChoking: false,
      abnormalBreathing: false,
      anxietyDepression: false,
      excessivelySleepy: false,
      unrefreshingSleep: false,
      sleepTalkingWalking: false,
      excessiveLoudSnoring: false,
      howBreathingDuration: '',
      wakingUpDuringNight: false,
      decreasedConcentration: false,
      sexualProblemsRemarks: '',
      difficultyFallingAsleep: false,
      howBreathingDurationUnit: ''
    }),
    associatedSymptoms: supplementDefaultForPulmoFields(encounter.associatedSymptoms, {
      vividDreams: '',
      problemLength: '',
      legsFeelRestless: '',
      partnerComplained: '',
      awakenKickingLegs: '',
      unableToMoveBody: '',
      vividDreamsRemarks: '',
      suddenMuscleWeakness: '',
      legsFeelRestlessRemarks: '',
      partnerComplainedRemarks: '',
      sleepingProblemDiagnosed: '',
      awakenKickingLegsRemarks: '',
      unableToMoveBodyRemarks: '',
      legsRestlessnessDelaySleep: '',
      suddenMuscleWeaknessRemarks: '',
      sleepingProblemDiagnosedRemarks: '',
      legsRestlessnessDelaySleepRemarks: ''
    }),
    sleepSchedule: supplementDefaultForPulmoFields(encounter.sleepSchedule, {
      dayNap: '',
      shiftWork: '',
      dayNapRemarks: '',
      dayNapDuration: '',
      shiftWorkTimeTo: null,
      timeGetUpWeekday: null,
      timeGetUpWeekend: null,
      timeToFallAsleep: '',
      shiftWorkTimeFrom: null,
      timeWakeUpWeekday: null,
      timeWakeUpWeekend: null,
      timeLieDownWeekday: null,
      timeLieDownWeekend: null,
      timeFallAsleepWeekday: null,
      timeFallAsleepWeekend: null,
      medicationsToFallAsleep: '',
      medicationsToFallAsleepRemarks: ''
    }),
    evaluatingSleepiness: supplementDefaultForPulmoFields(encounter.evaluatingSleepiness, {
      sleepyInDaytime: '',
      refreshedOnWakingUp: '',
      suddenMuscleWeakness: '',
      sleepyInDaytimeRemarks: '',
      involvedInRoadAccidents: '',
      refreshedOnWakingUpRemarks: '',
      suddenMuscleWeaknessRemarks: '',
      fallenAsleepAtRedLightDriving: '',
      involvedInRoadAccidentsRemarks: '',
      fallenAsleepAtRedLightDrivingRemarks: ''
    }),
    goiter: encounter.goiter || ''
  };

  return newEncounter;
};

export const nextId = (items: any[]) => {
  return 0 - items.filter(d => d.id <= 0).length;
};

export const mapKeyValue = (items: any[], key1: string, key2: string): KeyValue[] => {
  return items.map(itm => ({
    id: itm[key1],
    value: itm[key2]
  }));
};

export const mapKeyValueGroup = (items: any[], key1: string, key2: string, key3: string): KeyValue[] => {
  return items.map(itm => ({
    id: itm[key1],
    value: itm[key2],
    group: itm[key3]
  }));
};

export const flattenObject = (ob: any) => {
  let toReturn: any = {};
  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;
    if (typeof ob[i] == 'object' && ob[i] !== null) {
      let flatObject = flattenObject(ob[i]);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const getPagination = (headers: any): Pagination => {
  if (!headers) return DEFAULT_PAGINATION;
  return {
    nextPage: headers['x-next-page'] ? Number(headers['x-next-page']) : null,
    offset: Number(headers['x-offset']),
    page: Number(headers['x-page']),
    perPage: Number(headers['x-per-page']),
    prevpage: headers['x-prev-page'] ? Number(headers['x-prev-page']) : null,
    total: Number(headers['x-total']),
    totalPage: Number(headers['x-total-pages'])
  };
};

export const getPaginationPararms = (pagination: Pagination) => {
  const { page, perPage } = pagination;
  return `page=${page}&per_page=${perPage}`;
};

/**
 * Check if the blacklist strings exists in string item
 * @param item string - item to check for blackList
 * @param blackList string[] - blackList
 */
export const isInStringBlackList = (item: string, blackList: string[]): boolean => {
  let exists = false;
  for (let i of blackList) {
    if (item.includes(i)) exists = true;
  }
  return exists;
};

export const getProject = () => {
  const isTMC = (function () {
    const env = import.meta.env.VITE_NODE_ENV || 'local';
    const host = window.location.hostname;
    switch (env) {
      case 'production':
        return ['online.themedicalcity.com', 'tmc.medcheck.com.ph'].includes(host);
      case 'staging':
        return ['testing.themedicalcity.com', 'tmc.staging.medcheck.io'].includes(host);
      default:
        return host === 'localhost' && ['5002', '5006', '5007', '5008'].includes(window.location.port);
    }
  })();

  const isKeralty = (function () {
    const env = import.meta.env.VITE_NODE_ENV || 'local';
    const host = window.location.hostname;
    switch (env) {
      case 'production':
        return ['demo.medcheck.com.ph', 'demo.medcheck.io'].includes(host);
      case 'staging':
        return ['demo.staging.medcheck.io'].includes(host);
      default:
        return host === 'localhost' && ['5004'].includes(window.location.port);
    }
  })();

  const isCMS = (function () {
    const env = import.meta.env.VITE_NODE_ENV || 'local';
    const host = window.location.hostname;
    switch (env) {
      case 'production':
        return ['cms.medcheck.com.ph', 'cms.medcheck.io'].includes(host);
      case 'staging':
        return ['cms.staging.medcheck.io'].includes(host);
      default:
        return host === 'localhost' && ['5003'].includes(window.location.port);
    }
  })();

  return {
    isTMC,
    isKeralty,
    isCMS,
    isCore: !isTMC && !isKeralty,
    project: (isTMC ? 'tmc' : isKeralty || isCMS ? 'keralty' : 'core') as 'keralty' | 'core' | 'tmc'
  };
};

export function cleanObject(obj: any, removeEmptyString?: boolean) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || (obj[propName] === '' && removeEmptyString)) {
      delete obj[propName];
    }
  }
  return obj;
}
