import MatStep from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import MatStepLabel from '@material-ui/core/StepLabel';
import MatStepper from '@material-ui/core/Stepper';
import { Button } from 'components';
import { styled } from 'components/Theme';
import React, { useState, useEffect } from 'react';

interface Props {
  steps: string[];
  onStepChange: (stepIndex: number) => void;
  onComplete?: () => void; // provide for non-submit/non-form stepper get save trigger
  footerButtonsProps?: FooterButtonProps[]; // for multiple self props button
  backLabel?: string;
  nextLabel?: string;
  resetLabel?: string;
  completeLabel?: string;
  currentStep?: number;
}

interface FooterButtonProps {
  color: 'primary' | 'secondary' | 'default';
  type: 'submit' | 'button';
  label: string;
  loading: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const Stepper: React.FC<Props> = ({
  steps,
  onStepChange,
  onComplete,
  footerButtonsProps,
  backLabel = 'Back',
  nextLabel = 'Next',
  resetLabel = 'Back to Start',
  completeLabel = 'Save',
  currentStep,
  children
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [complete, setComplete] = useState(false);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      window.scrollTo({ top: 0 });
      onStepChange(activeStep + 1);
      // This if for validation per step. Change stepper step by changing currentStep props
      currentStep === undefined && setActiveStep(activeStep + 1);
    } else {
      setComplete(true);
    }
  };

  const handleBack = () => {
    window.scrollTo({ top: 0 });
    onStepChange(activeStep - 1);
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    window.scrollTo({ top: 0 });
    onStepChange(0);
    setActiveStep(0);
    setComplete(false);
  };

  const handleChangeStep = (stepIndex: number) => {
    onStepChange(stepIndex);
    // This if for validation per step. Change stepper step by changing currentStep props
    currentStep === undefined && setActiveStep(stepIndex);
    setComplete(false);
  };

  useEffect(() => {
    if (currentStep !== undefined) {
      setActiveStep(currentStep);
      setComplete(false);
    }
  }, [currentStep]);

  if (steps.length === 0) return null;

  return (
    <StepperWrapper>
      <MatStepper activeStep={activeStep} alternativeLabel connector={<StyledStepConnector />}>
        {steps.map((label: string, idx: number) => (
          <MatStep
            key={label}
            completed={activeStep > idx || complete}
            className="step-item"
            onClick={() => handleChangeStep(idx)}
          >
            <MatStepLabel className="step-label">{label}</MatStepLabel>
          </MatStep>
        ))}
      </MatStepper>
      <div>
        <div className="stepper-content">{children}</div>
        <div className="stepper-footer">
          {(!!onComplete && activeStep < steps.length) || (!onComplete && activeStep < steps.length - 1) ? (
            <Button
              type="button"
              label={activeStep === steps.length - 1 ? completeLabel : nextLabel}
              onClick={handleNext}
              color="primary"
            />
          ) : null}
          {footerButtonsProps &&
            footerButtonsProps.map((btnProps: FooterButtonProps, idx: number) => {
              const { onClick, ...rest } = btnProps;
              const visible = activeStep >= steps.length - 1 && !onComplete;
              return (
                visible && (
                  <Button
                    key={idx}
                    {...rest}
                    onClick={() => {
                      setComplete(true);
                      onClick && onClick();
                    }}
                  />
                )
              );
            })}
          <Button label={backLabel} onClick={handleBack} disabled={activeStep === 0} color="secondary" />
          {activeStep >= steps.length - 1 && <Button label={resetLabel} onClick={handleReset} color="secondary" />}
        </div>
      </div>
    </StepperWrapper>
  );
};

const StepperWrapper = styled.div`
  .stepper-content {
    height: 100%;
    overflow: hidden;
    padding-top: 0px !important;
    border-top: 2px;
    border-style: solid;
    border-color: ${props => props.theme.color.primary};
  }
  .stepper-footer {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 24px;
    margin-top: 32px;
    border-top: 2px;
    border-style: solid;
    border-color: ${props => props.theme.color.primary};
    .c-button {
      margin-right: 16px;
      min-width: 150px;
    }
  }
  .step-item {
    svg {
      cursor: pointer;
    }
  }
  .step-label {
    user-select: none;
    cursor: pointer;
    &.Mui-disabled {
      cursor: pointer;
    }
  }
`;

const StyledStepConnector = styled(StepConnector)`
  &.MuiStepConnector-active,
  &.MuiStepConnector-completed {
    .MuiStepConnector-line {
      background-color: ${props => props.theme.color.primary};
      height: 4px;
      margin-top: -4px;
    }
  }
  .MuiStepConnector-line {
    background-color: #9e9e9e;
    height: 4px;
    margin-top: -4px;
  }
`;
