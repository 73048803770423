import { getLocalStorageData } from 'common/services';
import { StoreAction } from 'constants/store';
import { parse } from 'date-fns';
import { AccountRole, AccountType, UserType } from 'models';
import {
  StoreCurrentDoctor,
  StoreCurrentUser,
  StoreNavigatorDashboard,
  StoreSecretaryDashboard,
  StoreSecretaryPermission,
  StoreSession,
  StoreState
} from 'models/store';
import { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export const useCurrentUser = () => {
  const props = useSelector<StoreState, StoreCurrentUser>(props => props.user.currentUser, shallowEqual);
  const { data: currentUser } = props;
  const type = currentUser?.type ?? '';
  return useMemo(
    () => ({
      currentUser: props,
      inProgress: props.inProgress,
      isSecretary: type === UserType.Secretary || type === UserType.ProserAdmin,
      isDoctor: type === UserType.Doctor,
      isNavigator: type === UserType.Navigator
    }),
    [props, type]
  );
};

export const useSecretaryPermissions = () => {
  const props = useSelector<StoreState, StoreSecretaryPermission>(props => props.user.permissions, shallowEqual);
  const { data: permissions } = props;
  const { isSecretary } = useCurrentUser();
  const clinicIds = permissions?.clinicIds;
  const parsedClinicIds = clinicIds ? (typeof clinicIds === 'string' ? JSON.parse(clinicIds) : clinicIds) : [];

  return useMemo(
    () => ({
      accessManageClinics: isSecretary ? permissions?.accessManageClinics ?? false : true,
      accessCreatePatient: isSecretary ? permissions?.accessCreatePatient ?? false : true,
      accessEditPatient: isSecretary ? permissions?.accessEditPatient ?? false : true,
      accessCrudAppointment: isSecretary ? permissions?.accessCrudAppointment ?? false : true,
      accessCrudBilling: isSecretary ? permissions?.accessCrudBilling ?? false : true,
      accessCrudEncounter: isSecretary ? permissions?.accessCrudEncounter ?? false : true,
      accessClinicIds: isSecretary ? parsedClinicIds : []
    }),
    [isSecretary, permissions, parsedClinicIds]
  );
};

export const useCurrentDoctor = () => {
  const props = useSelector<StoreState, StoreCurrentDoctor>(props => props.user.currentDoctor, shallowEqual);
  const accounts = props.accounts;
  const accountId = getLocalStorageData().activeAccount;
  const { accountRole, isAccountPrivate } = useMemo(() => {
    if (accounts && accounts.length > 0 && accountId) {
      const currentAccountId = Number(accountId);
      const account = accounts.find(item => item.id === currentAccountId);
      return {
        accountRole: account ? (account.role as AccountRole) : AccountRole.Admin,
        isAccountPrivate: account ? account.type === AccountType.Doctor : true
      };
    }
    return {
      accountRole: AccountRole.Admin,
      isAccountPrivate: true
    };
  }, [accounts, accountId]);
  return useMemo(
    () => ({
      currentDoctor: props,
      isBcmapDoctorOnly: props.data?.isBcmapDoctorOnly,
      inProgress: props.inProgress,
      accountRole,
      isAccountPrivate
    }),
    [props, accountRole, isAccountPrivate]
  );
};

export const useActiveAccount = () => {
  const activeAccount = useSelector<StoreState, number>(store => store.session.activeAccount ?? 0, shallowEqual);
  const { data: secretaryDashboard, inProgress: secFetching } = useSelector<StoreState, StoreSecretaryDashboard>(
    store => store.user.secretaryDashboard,
    shallowEqual
  );

  const {
    data: currentDoctor,
    inProgress: docFetching,
    accounts: docAccounts
  } = useSelector<StoreState, StoreCurrentDoctor>(store => store.user.currentDoctor, shallowEqual);
  const secAccounts = secretaryDashboard?.accounts ?? [];

  const { data: navigatorDashboard, inProgress: navFetching } = useSelector<StoreState, StoreNavigatorDashboard>(
    props => props.user.navigatorDashboard,
    shallowEqual
  );
  const navAccounts = navigatorDashboard?.accounts ?? [];

  const fetching =
    secFetching ||
    (!secFetching && !secretaryDashboard) ||
    docFetching ||
    (!docFetching && !currentDoctor) ||
    navFetching ||
    (!navFetching && !navigatorDashboard);

  const isValid = [
    ...docAccounts.map(acc => acc.id),
    secAccounts.map(acc => acc.id),
    ...navAccounts.map(acc => acc.id)
  ].includes(activeAccount);

  return useMemo(
    () => ({
      activeAccountId: activeAccount,
      activeAccount: !isValid ? undefined : docAccounts.find(acc => acc.id === activeAccount),
      doctorAccounts: docAccounts,
      secretaryAccounts: secAccounts,
      navigatorAccounts: navAccounts,
      isFetching: fetching,
      isValid
    }),
    [activeAccount, docAccounts, secAccounts, navAccounts, fetching, isValid]
  );
};

export const useSession = () => {
  const { assumeToken } = useSelector<StoreState, StoreSession>(store => store.session, shallowEqual);
  return useMemo(
    () => ({
      assumeToken
    }),
    [assumeToken]
  );
};

export const useResetAttempts = (type: 'login' | 'signup') => {
  const dispatch = useDispatch();
  const resetAttempts = () => {
    const actionType = type === 'login' ? StoreAction.LOGIN.RESET : StoreAction.SIGNUP.RESET;
    dispatch({ type: actionType, payload: null });
  };
  return { resetAttempts };
};
