import { StoreAction } from 'constants/store';
import { ActionType } from 'models/store';
import { createAction } from 'services/utils.service';

const { GET_DASHBOARD, GET_PERMISSIONS } = StoreAction.SECRETARY;

export type GetSecretaryDashboardAction = () => ActionType;

export const getSecretaryDashboard: GetSecretaryDashboardAction = () => createAction(GET_DASHBOARD.REQUEST, null);

export const getSecretaryPermissions = (userId: number): ActionType =>
  createAction(GET_PERMISSIONS.REQUEST, { userId });
