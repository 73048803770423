import { transformIncomingHistoricalFileReferenceData, transformIncomingAttachmentsData } from 'common/transformer';
import { Toast } from 'components';
import React, { useCallback, useMemo, useState } from 'react';
import { useAsyncFn } from 'react-use';
import {
  getPatientHistoricalHealthRecord,
  getPatientHistoricalHealthRecordAttachments
} from 'services/patient.service';
import { getRecordAttachments } from 'services/uploader.service';

export const useFetchHistoricalPHR = () => {
  const [{ loading: fetchingHistoricalRecord, value: historicalPHR }, fetchHistoricalRecord] = useAsyncFn(
    async (...args: any[]) => {
      const [pxId] = args;
      const { data } = await getPatientHistoricalHealthRecord(pxId);
      if (data.length > 0) {
        return transformIncomingHistoricalFileReferenceData(data[0]);
      }
      return undefined;
    },
    undefined,
    { loading: true }
  );
  return React.useMemo(
    () => ({
      fetchingHistoricalRecord,
      historicalPHR,
      fetchHistoricalRecord
    }),
    [fetchingHistoricalRecord, historicalPHR, fetchHistoricalRecord]
  );
};

export const useFetchGlobalHistoricalFiles = () => {
  const [{ loading: fetchingFiles, value: files }, getAttachedFiles] = useAsyncFn(
    async (...args: any[]) => {
      const [pxId] = args;
      const { data } = await getPatientHistoricalHealthRecordAttachments(pxId);
      return transformIncomingAttachmentsData(data);
    },
    undefined,
    { loading: true }
  );
  return React.useMemo(
    () => ({
      fetchingGlobalFiles: fetchingFiles,
      globalFiles: files,
      fetchGlobalHistoricalFiles: getAttachedFiles
    }),
    [fetchingFiles, files, getAttachedFiles]
  );
};

export const useFetchHistoricalFiles = () => {
  const [{ loading: fetchingFiles, value: files }, getAttachedFiles] = useAsyncFn(
    async (...args: any[]) => {
      const [phrId] = args;
      const { data } = await getRecordAttachments(phrId, 'general');
      return transformIncomingAttachmentsData(data);
    },
    undefined,
    { loading: true }
  );
  return React.useMemo(
    () => ({
      fetchingHistoricalFiles: fetchingFiles,
      historicalFiles: files,
      fetchHistoricalFiles: getAttachedFiles
    }),
    [fetchingFiles, files, getAttachedFiles]
  );
};

export const useDragAndDrop = (dropFunction: (files: File[]) => void) => {
  const WHITE_LIST = ['image/png', 'application/pdf', 'image/jpeg'];
  const [dragging, setDragging] = useState(false);

  const onDragIn = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer?.types && e.dataTransfer?.types.includes('Files')) {
        if (!dragging) {
          setDragging(true);
        }
      }
    },
    [dragging, setDragging]
  );

  const onDragOut = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer?.types && e.dataTransfer?.types.includes('Files')) {
        if (dragging) {
          setDragging(false);
        }
      }
    },
    [dragging, setDragging]
  );

  const onDrop = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (dragging) {
        setDragging(false);
      }
      if (e.dataTransfer?.files && e.dataTransfer?.files.length > 0) {
        const rawFiles = e.dataTransfer.files;
        if (typeof rawFiles === 'object') {
          const files = Object.keys(rawFiles).map(key => rawFiles[key]);
          const validatedFiles = files.filter((file: File) => WHITE_LIST.includes(file.type));
          if (files.length > validatedFiles.length) {
            Toast.error(validatedFiles.length > 0 ? 'Some files are not uploaded' : 'Invalid file.');
          }
          validatedFiles.length > 0 && dropFunction(validatedFiles);
        }
        e.dataTransfer.clearData();
      }
    },
    [dragging, setDragging, WHITE_LIST, dropFunction]
  );

  const onDragOver = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return useMemo(() => {
    return {
      onDragIn,
      onDragOut,
      onDrop,
      onDragOver,
      dragging
    };
  }, [dragging, onDragIn, onDragOut, onDragOver, onDrop]);
};
