import { http } from 'common/services';
import { EncounterType } from 'constants/form';
import { format } from 'date-fns';
import { ENDPOINTS } from 'defaults';
import {
  Consultation,
  ConsultingSpecialization,
  DiagnosisArray,
  LabResultArray,
  PatientInput,
  PatientInstructionArray,
  SummaryInput,
  VaccinationInput
} from 'models';
import { _DoctorPatient, _PatientSummary, _User, _PatientStatus } from 'models/endpoint';
import { _PatientSubmission } from 'models/endpoint/patient';
import { Pagination } from 'models/store';
import { MODEL_MAP } from 'partials/labs/helpers';
import { transformInPatientSubmissionSummary } from 'transformer/patient';
import { getPaginationPararms } from './utils.service';

const {
  GET,
  UPDATE,
  UPDATE_AVATAR,
  SUMMARY,
  DISEASE_TREATMENT_STATUS,
  VACCINATION,
  SUBMISSION,
  NAVIGATOR_SUBMISSION,
  CHANGE_HISTORY,
  PRESCRIPTIONS
} = ENDPOINTS.DOCTOR.PATIENT;
const { GET: GET_RECORD, POST } = ENDPOINTS.DOCTOR.RECORD;
const { CREATE_PATIENT } = ENDPOINTS.SESSION;
const { WATCHLIST, SHARE_RECORDS, SHARE_RECORDS_SOLO } = ENDPOINTS.DOCTOR.PATIENT;
const { POST: SHARE_PATIENT } = SHARE_RECORDS;
const { POST: SHARE_PATIENT_SOLO } = SHARE_RECORDS_SOLO;

export const getPatientInformation = async (patientId: number) => http.get(GET.name, `${GET.url}?id=${patientId}`);

export const extractPatientInfo = (patientId: number, data: _DoctorPatient) => {
  const patient = data.patient.find(patient => patient.id === Number(patientId));
  const summary = data.summary.find(summary => summary.patient_id === Number(patientId));

  if (!patient || !summary) {
    return undefined;
  }
  return {
    ...patient,
    gender: summary.gender,
    birth_date: summary.birth_date,
    summary
  };
};

export function getPatientChangeHistory(patientId: number) {
  const endpoint = CHANGE_HISTORY;
  return http.get(endpoint.name, `${endpoint.url}?id=${patientId}`);
}

export function updatePatientAvatar(patientId: number, file: File) {
  let formData = new FormData();
  formData.append('id', `${patientId}`);
  formData.append('file', file);

  return http.put(UPDATE_AVATAR.name, UPDATE_AVATAR.url, formData);
}

export function getLastRecord(patientId: number, type: EncounterType, limit = 1, clinicId?: number) {
  const endpoint = GET_RECORD.parse({
    patient_id: patientId,
    patient_limit: limit,
    active: true,
    type,
    clinic_id: clinicId ?? undefined
  });
  return http.get(endpoint.name, endpoint.url);
}

export function getPatientPrescriptions(patientId: number) {
  const endpoint = PRESCRIPTIONS.FILTER.parse({ patient_id: patientId });
  return http.get(endpoint.name, endpoint.url);
}

export function getPatientRecords(patientId: number, pagination?: Pagination) {
  const endpoint = GET_RECORD.parse({
    patient_id: patientId,
    active: true
  });
  const paginationParams = pagination ? `&${getPaginationPararms(pagination)}` : '';
  return http.get(endpoint.name, `${endpoint.url}&${paginationParams}`);
}

export function getPatientLatestHealthRecord(patientId: number) {
  const endpoint = GET_RECORD.DEFAULT;
  return http.get(endpoint.name, `${endpoint.url}?data_type=true&active=true&patient_id=${patientId}`);
}

export function getPatientHistoricalHealthRecord(patientId: number) {
  const endpoint = GET_RECORD.parse({
    patient_id: patientId,
    type: 'Phrs::TypeHistorical',
    patient_limit: 1,
    active: true
  });
  return http.get(endpoint.name, endpoint.url);
}

export function getPatientHistoricalHealthRecordAttachments(patientId: number) {
  return http.get(
    GET_RECORD.HISTORICAL.ATTACHMENTS.name,
    `${GET_RECORD.HISTORICAL.ATTACHMENTS.url}?patient_id=${patientId}`
  );
}

export function createPatientHistoricalHealthRecord(patientId: number, clinicId: number) {
  const payload = {
    clinic_id: clinicId,
    patient_id: patientId,
    type: 'Phrs::TypeHistorical',
    active: true
  };

  return http.post(POST.name, POST.url, payload);
}

export const createPatient = (patient: PatientInput) => {
  return http.post(CREATE_PATIENT.name, CREATE_PATIENT.url, patient);
};

export const updatePatient = (patient: Partial<_User>) => {
  return http.put(UPDATE.name, UPDATE.url, patient);
};

export const getPatientSummary = (patientId: number) => {
  return http.get(SUMMARY.GET.name, `${SUMMARY.GET.url}?patient_id=${patientId}`);
};

export const updatePatientSummary = (summary: SummaryInput) => {
  return http.put(SUMMARY.PUT.name, SUMMARY.PUT.url, summary);
};

export const upadtePatientDiseaseTreatmentStatus = (summary: _PatientStatus) => {
  return http.put(DISEASE_TREATMENT_STATUS.PUT.name, DISEASE_TREATMENT_STATUS.PUT.url, summary);
};

export const createPatientVaccinations = (vaccinations: Array<VaccinationInput>, patientId: number) => {
  const promises = vaccinations.map((vaccination: VaccinationInput) => {
    return http.post(
      VACCINATION.POST.name,
      VACCINATION.POST.url,
      Object.assign(vaccination, { patient_id: patientId })
    );
  });
  return Promise.all(promises);
};

export const updatePatientVaccinations = (vaccinations: Array<VaccinationInput>, patientId: number) => {
  const promises = vaccinations.map((vaccination: VaccinationInput) => {
    const isUpdate = typeof vaccination.id !== 'undefined';
    const endpoint = isUpdate ? VACCINATION.PUT : VACCINATION.POST;
    const method = isUpdate ? http.put : http.post;
    return method(endpoint.name, endpoint.url, Object.assign(vaccination, { patient_id: patientId }));
  });
  return Promise.all(promises);
};

export async function deletePatientVaccinations(vaccinations: Array<VaccinationInput>, patientId: number) {
  const promises = vaccinations.map(vaccination =>
    http.delete(VACCINATION.DELETE.name, VACCINATION.DELETE.url, { id: vaccination.id, patient_id: patientId })
  );
  return Promise.all(promises);
}

export const getPatientVaccinations = (patientId: number) => {
  return http.get(VACCINATION.GET.name, `${VACCINATION.GET.url}?patient_id=${patientId}`);
};

export function extractConsultation(data: any): Consultation {
  return {
    phrId: data.id,
    patientId: data.patient_id,
    chiefComplaint: data.phr_subjective ? data.phr_subjective.chief_complaint : '',
    patientInstructions: ((data.phr_patient_instructions as PatientInstructionArray[]) || []).map(
      (instruction: PatientInstructionArray) => ({
        detail: instruction.detail,
        patient_health_record_id: instruction.patient_health_record_id
      })
    ),
    encounterType: data.type,
    subType: data.sub_type || 'created',
    notesAndFindings: data.laboratory_and_diagnostic_data || '',
    creatorName: data.creator_name || '',
    lastUpdatedByName: data.last_updated_by_name,
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
    currentDiagnosis: ((data.phr_clinical_diagnoses as DiagnosisArray[]) || []).map((diag: DiagnosisArray) => ({
      name: diag.name,
      additional: diag.additional
    })),
    prescribedMedication: (data.phr_prescriptions || []).map((pres: any) => {
      let val = '';
      if (pres.generic_name) val = pres.generic_name;
      if (pres.brand_name) val += ` (${pres.brand_name})`;
      if (pres.preparation) val += ` ${pres.preparation}`;
      if (pres.quantity) val += ` #${pres.quantity}`;
      if (pres.sig || pres.instruction) val += ` Sig. ${pres.sig ? `${pres.sig} ` : ''}${pres.instruction}`;
      return val.trim();
    }),
    visitDate: new Date(data?.visit_date),
    fileCount: data.file_count,
    medicalCertificates: data.medical_certificates,
    historyOfPresentIllness: data.history_of_present_illness || '',
    labResultTypes: ((data.phr_test_groups as LabResultArray[]) || []).map((test_group: any) => ({
      name: Object.keys(MODEL_MAP).find(key => MODEL_MAP[key] === test_group.type) || ''
    })),
    doctor: {
      name: data?.doctor?.name || '',
      specializations: (data?.doctor?.specializations || []).map((specialization: ConsultingSpecialization) => ({
        id: specialization?.id || '',
        name: specialization?.name || ''
      }))
    }
  };
}

export function extractTreatmentPlans(data: any[]) {
  const treatmentPlans = (data || [])
    .filter(hasTreatmentPlan)
    .sort((a: any, b: any) => new Date(b.visit_date).getTime() - new Date(a.visit_date).getTime())
    .map(filterDiagnoses)
    .reduce((acc: any, cur: any) => {
      if (!cur) {
        return acc;
      }
      const oneAssessment = cur.assessment.map((assess: any) => {
        return {
          visit_date: cur.visit_date,
          laboratory_and_diagnostic_data: cur.laboratory_and_diagnostic_data,
          id: cur.id,
          clinic_id: cur.clinic_id,
          patient_id: cur.patient_id,
          assessments: assess
        };
      });
      return [...acc, ...oneAssessment];
    }, []);
  return treatmentPlans;
}

export function hasTreatmentPlan(encounter: any) {
  if (!encounter) {
    return false;
  }

  let hasTreatmentPlan = false;
  (encounter.phr_clinical_diagnoses || []).forEach((diagnosis: any) => {
    if (diagnosis.treatment) {
      hasTreatmentPlan = true;
    }
  });
  return hasTreatmentPlan;
}

export function filterDiagnoses(encounter: any) {
  if (!encounter) {
    return;
  }
  return {
    ...encounter,
    assessment: (encounter.phr_clinical_diagnoses || []).filter((assessment: any) => !!assessment.treatment)
  };
}

export async function getPatientSubmissionSummary(patientId: number) {
  try {
    const endpoint = SUBMISSION.parse({ id: patientId });
    const { data } = await http.get<_PatientSubmission>(endpoint.name, endpoint.url);
    if (!data || data.patient.length === 0 || data.summary.length === 0) return null;
    return transformInPatientSubmissionSummary(data);
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getNavigatorPatientSubmissionSummary(patientId: number) {
  try {
    const endpoint = NAVIGATOR_SUBMISSION.parse({ id: patientId });
    const { data } = await http.get<_PatientSubmission>(endpoint.name, endpoint.url);
    if (!data || data.patient.length === 0 || data.summary.length === 0) return null;
    return transformInPatientSubmissionSummary(data);
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function sharePatientRecords(patientId: number, accountId: number) {
  try {
    const endpoint = SHARE_PATIENT;
    const { data } = await http.post(endpoint.name, endpoint.url, { account_id: accountId, member_id: patientId });
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function sharePatientRecordsSolo(patientId: number, accountId: number) {
  try {
    const endpoint = SHARE_PATIENT_SOLO;
    const { data } = await http.post(endpoint.name, endpoint.url, { borrower_id: accountId, patient_id: patientId });
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const addToWatchlist = (patientId: number) => {
  const endpoint = WATCHLIST.GET;
  return http.post(endpoint.name, endpoint.url, { patient_id: patientId });
};

export const removeFromWatchlist = (patientId: number) => {
  const endpoint = WATCHLIST.DELETE;
  return http.delete(endpoint.name, endpoint.url, { patient_id: patientId });
};

export const getWatchlist = async () => {
  return http.getAll(WATCHLIST.GET.name, `${WATCHLIST.GET.url}?`);
};

export const turnOffNotificationForPatient = async (summaryId: number) => {
  return http.put(SUMMARY.PUT.name, SUMMARY.PUT.url, { id: summaryId, turn_off_notifications: true });
};

export const turnOffNotificationForMultiplePatient = async (patientIds: number[]) => {
  const { data } = await http.get(SUMMARY.GET.name, `${SUMMARY.GET.url}?patient_id=${patientIds.join(',')}`);
  const promises = patientIds.map(patientId => {
    const summary = data.find((summary: _PatientSummary) => summary.patient_id === patientId);
    return http.put(SUMMARY.PUT.name, SUMMARY.PUT.url, { id: summary.id, turn_off_notifications: true });
  });

  return Promise.all(promises);
};

export const getPatientsOnClinic = async (patientsIds: number[], clinicId: number) => {
  const { GET: endpoint } = ENDPOINTS.DOCTOR.PATIENT.LIST;
  return http.getAll(endpoint.name, `${endpoint.url}?id=${patientsIds.join(',')}&clinic_id=${clinicId}`);
};

export const getPatientAttachments = async (
  payload: { patientId: number; global?: boolean; fromDate?: Date | null; toDate?: Date | null },
  pagination: Pagination
) => {
  const { parse } = ENDPOINTS.V3.ATTACHMENT_REPOSITORY;
  const { patientId, global, fromDate, toDate } = payload;
  const from = fromDate ? encodeURIComponent(format(fromDate, 'yyyy-MM-dd 00:00:00 xx')) : undefined;
  const to = toDate ? encodeURIComponent(format(toDate, 'yyyy-MM-dd 23:59:59 xx')) : undefined;
  const endpoint = parse({ patient_id: patientId, global, from_date: from, to_date: to });
  const paginationParams = pagination ? `&${getPaginationPararms(pagination)}` : '';
  return http.get(endpoint.name, `${endpoint.url}${paginationParams}`);
};
