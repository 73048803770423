import { formatDate, parseDate } from 'common/services';
import { EncounterType, PulmonologyFormSubType } from 'constants/form';
import {
  BCIPhysicalExamDefault,
  commonPhysicalExamDefault,
  isBreastCancer,
  isBronchialAsthma,
  isColonCancer,
  isColorectalCancer,
  isLungCancer,
  isTuberculosis,
  reviewOfSystemDefault,
  TREATMENT_DEFAULT
} from 'defaults';
import {
  ActNowEncounter,
  Agent,
  BCIEncounter,
  BCIPhysicalExam,
  BciRadiationTreatment,
  BCIVitals,
  ChemotherapyRecord,
  CombinedEncounter,
  CombinedVitals,
  CommonPhysicalExam,
  DiabEncounter,
  DiagnosisWithTreatment,
  DoseConstraints,
  Encounter,
  EncounterVaccination,
  EndocrinologyEncounter,
  EndoDiagnosis,
  EndoVitals,
  ENTEncounter,
  GyneEncounter,
  HistoryTrail,
  HormonalTherapyRecord,
  NewEncounterInput,
  ObEncounter,
  OncoDiagnosis,
  OncologyEncounter,
  OncoVitals,
  OphthalmologyEncounter,
  PatientReferralData,
  PediatricsEncounter,
  PediaVitals,
  Prescription,
  PreviousRadiationTherapy,
  Procedure,
  PulmoDiagnosis,
  PulmonologyEncounter,
  PulmoVitals,
  Radiation,
  RadiationRecord,
  Receptors,
  ReviewOfSystem,
  RheumaDiagnosis,
  RheumatologyEncounter,
  Segments,
  StandardDiagnosis,
  StandardEncounter,
  Subjective,
  Surgery,
  SurgeryRecord,
  TargetedTherapyRecord,
  Therapy,
  Treatment1,
  Treatment2,
  TreatmentPlan,
  TreatmentRecords,
  Vitals
} from 'models';
import {
  _AllDiagnosisType,
  _AllPulmonologyDiagnosisOtherType,
  _BCIPhysicalExam,
  _BciRadiationTreatment,
  _BronchialAsthmaDiagnosisOthers,
  _ChemotherapyRecord,
  _CommonPhysicalExam,
  _DiabEncounterFields,
  _DiabHealthRecord,
  _DiagnosisAdditionalFields,
  _DosageConstraints,
  _EndocrinologyEncounterFields,
  _EndocrinologyHealthRecord,
  _HealthRecord,
  _HealthRecordDiagnosesAndPrescriptionOnly,
  _HormonalTherapyRecord,
  _OncologyDiagnosis,
  _PatientReferralData,
  _PhysicalExam,
  _Prescription,
  _PreviousRadiationTherapy,
  _PulmonologyDiagnosis,
  _PulmonologyEncounterFields,
  _RadiationRecord,
  _RadiationTreatment,
  _ReviewOfSystem,
  _ReviewOfSystemFlat,
  _RheumatologyDiagnosis,
  _SaveBciRadiationTreatment,
  _SurgeryRecord,
  _TargetedTherapyRecord,
  _TherapyType,
  _Treatment1,
  _Treatment2,
  _TreatmentAgent,
  _TreatmentAgentFlat,
  _TreatmentAntiCancer,
  _TreatmentFlat,
  _TreatmentProcedure,
  _TreatmentProcedureFlat,
  _TreatmentRadiationFlat,
  _TreatmentRecords,
  _TreatmentSurgery,
  _TreatmentSurgeryFlat,
  _TreatmentTherapy,
  _TreatmentTherapyFlat,
  _TuberculosisDiagnosisOthers,
  _Vitals
} from 'models/endpoint';
import { applyPulmoFormDefaults, isEncounterElapsed } from 'services/utils.service';

export const extractDiagnosesAndPrescriptions = (record: _HealthRecord): _HealthRecordDiagnosesAndPrescriptionOnly => {
  return {
    id: record.id,
    type: record.type,
    diagnoses: record.phr_clinical_diagnoses,
    prescriptions: record.phr_prescriptions,
    procedures: record.phr_clinical_procedures,
    history_of_present_illness: record.history_of_present_illness,
    instructions: record.phr_patient_instructions
  };
};

export const isGranular = (encounterType: EncounterType) => {
  return encounterType === EncounterType.BCI || encounterType === EncounterType.Oncology;
};

export const transformInEncounter = (data: _HealthRecord): CombinedEncounter => {
  const { phr_patient_instructions: instructions, created_at } = data;
  const createdAt = new Date(created_at || Date.now());
  const visitDate = new Date(data?.visit_date || Date.now());
  const hasInstruction = instructions && instructions.length > 0;
  const common:
    | StandardEncounter
    | BCIEncounter
    | OncologyEncounter
    | RheumatologyEncounter
    | PulmonologyEncounter
    | EndocrinologyEncounter
    | ENTEncounter = {
    id: data.id,
    appointmentId: data.appointment_id || null,
    patientId: data.patient_id,
    clinicId: data.clinic_id,
    type: data.type as EncounterType,
    complianceLevel: data.compliance_level,
    complianceRemarks: data.compliance_remarks,
    subjective: transformInRecord.subjective(data),
    vitals: transformInRecord.vitals(data),
    diagnoses: [],
    prescriptionValidityDate: data.prescription_validity_date,
    patientFeedback: data.pr__patient_feedback,
    laboratoryAndDiagnosticData: data.laboratory_and_diagnostic_data || '',
    patientInstruction: hasInstruction
      ? { id: instructions[0].id, detail: instructions[0].detail }
      : { id: 0, detail: '' },
    prescriptions: transformInRecord.prescriptions(data.phr_prescriptions),
    physical: transformInRecord.physical(data.phr_physical_exam, isGranular(data.type as EncounterType)),
    visitDate: new Date(data?.visit_date || Date.now()),
    visitStartTime: new Date(data.visit_start_time || Date.now()),
    visitEndTime: data?.visit_end_time,
    createdAt: createdAt,
    updatedAt: new Date(data.updated_at || Date.now()),
    active: data.active,
    editable: !isEncounterElapsed(createdAt, visitDate),
    isTelemedEncounter: data.is_telemed_encounter,
    subType: data.sub_type || 'created',
    fileCount: data.file_count,
    diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : {},
    formSubType: data.form_sub_type ? (data.form_sub_type as PulmonologyFormSubType) : PulmonologyFormSubType.Basic,
    medicalCertificate: data.medical_certificates
  } as
    | StandardEncounter
    | BCIEncounter
    | OncologyEncounter
    | RheumatologyEncounter
    | PulmonologyEncounter
    | EndocrinologyEncounter
    | ENTEncounter;
  switch (data.type as EncounterType) {
    case EncounterType.Oncology:
      return transformOncoEncounter(common as OncologyEncounter, data);
    case EncounterType.BCI:
      return transformBciEncounter(common as BCIEncounter, data);
    case EncounterType.Rheumatology:
      return transformRheumatologyEncounter(common as RheumatologyEncounter, data);
    case EncounterType.Pulmonology:
      return transformInPulmonologyEncounter(
        common as PulmonologyEncounter,
        data as _HealthRecord & _PulmonologyEncounterFields
      );
    case EncounterType.Pediatrics:
      return transformInPediatricsEncounter(common as PediatricsEncounter, data);
    case EncounterType.Endocrinology:
      return transformEndocrinologyEncounter(common as EndocrinologyEncounter, data as _EndocrinologyHealthRecord);
    case EncounterType.Diabetology:
      return transformDiabEncounter(common as DiabEncounter, data as _DiabHealthRecord);
    case EncounterType.ActNow:
      return transformActNowEncounter(common as ActNowEncounter, data as any);
    case EncounterType.ENT:
      return transformENTEncounter(common as ENTEncounter, data as any);
    case EncounterType.Ophthalmology:
      return transformOphthalmologyEncounter(common as OphthalmologyEncounter, data as any);
    case EncounterType.Ob:
      return transformObEncounter(common as ObEncounter, data as any);
    case EncounterType.Gyne:
      return transformGyneEncounter(common as GyneEncounter, data as any);
    default:
      return transformStandardEncounter(common as Encounter, data);
  }
};

export const transformStandardEncounter = (common: Encounter, data: _HealthRecord): StandardEncounter => {
  return {
    ...common,
    diagnoses: (data.phr_clinical_diagnoses || []).map(transformInRecord.standardDiagnosis)
  };
};

export const transformENTEncounter = (common: Encounter, data: _HealthRecord): ENTEncounter => {
  return {
    ...common,
    diagnoses: (data.phr_clinical_diagnoses || []).map(transformInRecord.standardDiagnosis)
  };
};

export const transformDiabEncounter = (common: Encounter, data: _DiabHealthRecord): DiabEncounter => {
  return {
    ...common,
    vitals: transformInRecord.vitals(data),
    diagnoses: (data.phr_clinical_diagnoses || []).map(transformInRecord.endoDiagnosis),
    typeSpecificId: data.type_specific_id,
    phrId: common.id,
    symptoms: {
      non_healing_wounds: data?.symptoms?.symptoms__non_healing_wounds,
      others: data?.symptoms?.symptoms__others,
      polydipsia: data?.symptoms?.symptoms__polydipsia,
      polyphagia: data?.symptoms?.symptoms__polyphagia,
      polyuria: data?.symptoms?.symptoms__polyuria,
      remarks: data?.symptoms?.symptoms__remarks,
      tingling_sensation: data?.symptoms?.symptoms__tingling_sensation,
      weight_loss: data?.symptoms?.symptoms__weight_loss
    },
    followUpSchedule: {
      patient_health_record_id: common.id,
      ...(data?.follow_up_schedule ?? {})
    },
    diabetesEducatorsNotes: data?.diabetes_educators_notes ?? '',
    medicalNutritionalTherapy: {
      formalConsultation: data?.medical_nutrional_therapy?.mnt__formal_consultation ?? '',
      noFormalConsultation: data?.medical_nutrional_therapy?.mnt__no_formal_consultation ?? '',
      compliance: data?.medical_nutrional_therapy?.mnt__compliance ?? '',
      currentRegimenTcr: data?.medical_nutrional_therapy?.mnt__current_regimen_tcr ?? '',
      changeToTcr: data?.medical_nutrional_therapy?.mnt__change_to_tcr ?? '',
      currentRegimenCho: data?.medical_nutrional_therapy?.mnt__current_regimen_cho ?? '',
      changeToCho: data?.medical_nutrional_therapy?.mnt__change_to_cho ?? '',
      currentRegimenChon: data?.medical_nutrional_therapy?.mnt__current_regimen_chon ?? '',
      changeToChon: data?.medical_nutrional_therapy?.mnt__change_to_chon ?? '',
      currentRegimenFats: data?.medical_nutrional_therapy?.mnt__current_regimen_fats ?? '',
      changeToFats: data?.medical_nutrional_therapy?.mnt__change_to_fats ?? '',
      meals: data?.medical_nutrional_therapy?.mnt__meals ?? '',
      snacks: data?.medical_nutrional_therapy?.mnt__snacks ?? '',
      remarks: data?.medical_nutrional_therapy?.mnt__remarks ?? ''
    },
    waistCircumference: data.waist_circumference_value || 0,
    waistCircumferenceUnit: data.waist_circumference_unit || 'cm'
  };
};

export const transformEndocrinologyEncounter = (
  common: Encounter,
  data: _EndocrinologyHealthRecord
): EndocrinologyEncounter => {
  return {
    ...common,
    vitals: transformInRecord.vitals(data),
    diagnoses: (data.phr_clinical_diagnoses || []).map(transformInRecord.endoDiagnosis),
    typeSpecificId: data.type_specific_id,
    phrId: common.id,
    waistCircumference: data.waist_circumference_value || 0,
    waistCircumferenceUnit: data.waist_circumference_unit || 'cm'
  };
};

export const transformOncoEncounter = (common: OncologyEncounter, data: _HealthRecord): OncologyEncounter => {
  return {
    ...common,
    vitals: transformInRecord.vitals(data),
    diagnoses: (data.phr_clinical_diagnoses || []).map(d => transformInRecord.oncoDiagnosis(d as any))
  };
};

export const transformBciEncounter = (common: BCIEncounter, data: _HealthRecord): BCIEncounter => {
  return {
    ...common,
    vitals: transformInRecord.vitals(data),
    diagnoses: (data.phr_clinical_diagnoses || []).map(d => transformInRecord.oncoDiagnosis(d as any))
  };
};

export const transformOphthalmologyEncounter = (common: OphthalmologyEncounter, data: any) => {
  let ophthalmologyDetail: any = {};
  if (data.ophthalmologyDetail) {
    const pupils = data?.ophthalmologyDetail?.pupils ?? {};
    const visual_acuity = data?.ophthalmologyDetail?.visual_acuity ?? {};
    const tanometry = data?.ophthalmologyDetail?.tanometry ?? {};
    const other_tests = data?.ophthalmologyDetail?.other_tests ?? '';
    ophthalmologyDetail = {
      id: data.ophthalmologyDetail.id,
      ...pupils,
      ...visual_acuity,
      ...tanometry,
      other_tests
    };
  }
  return {
    ...common,
    vitals: transformInRecord.vitals(data),
    diagnoses: (data.phr_clinical_diagnoses || []).map((d: any) => transformInRecord.standardDiagnosis(d as any)),
    ophthalmologyDetail: ophthalmologyDetail
  };
};

export const transformObEncounter = (common: ObEncounter, data: any) => {
  let obDetail: any = {};
  if (data.obDetail) {
    const abdominal = data?.obDetail?.abdominal ?? {};
    const admitting_ctg = data?.obDetail?.admitting_ctg ?? {};
    const genitourinary = data?.obDetail?.genitourinary ?? {};
    const pelvic_exam = data?.obDetail?.pelvic_exam ?? {};
    obDetail = {
      id: data.obDetail.id,
      ...abdominal,
      ...admitting_ctg,
      ...genitourinary,
      ...pelvic_exam
    };
  }
  return {
    ...common,
    vitals: transformInRecord.vitals(data),
    diagnoses: (data.phr_clinical_diagnoses || []).map((d: any) => transformInRecord.standardDiagnosis(d as any)),
    obDetail: obDetail
  };
};

export const transformGyneEncounter = (common: GyneEncounter, data: any) => {
  let gyneDetail: any = {};
  if (data.gyneDetail) {
    const genitourinary = data?.gyneDetail?.genitourinary ?? {};
    const pelvic_exam = data?.gyneDetail?.pelvic_exam ?? {};
    gyneDetail = {
      id: data.gyneDetail.id,
      ...genitourinary,
      ...pelvic_exam
    };
  }
  return {
    ...common,
    vitals: transformInRecord.vitals(data),
    diagnoses: (data.phr_clinical_diagnoses || []).map((d: any) => transformInRecord.standardDiagnosis(d as any)),
    gyneDetail: gyneDetail
  };
};

export const transformActNowEncounter = (common: ActNowEncounter, data: any) => {
  let actNowDetail: any = {};
  if (data.actNowDetail) {
    const clinical_breast_examination = data?.actNowDetail?.clinical_breast_examination ?? {};
    const receptors = data?.actNowDetail?.receptors ?? {};
    actNowDetail = {
      id: data.actNowDetail.id,
      ...clinical_breast_examination,
      ...receptors
    };
  }
  return {
    ...common,
    vitals: transformInRecord.vitals(data),
    diagnoses: (data.phr_clinical_diagnoses || []).map((d: any) => transformInRecord.oncoDiagnosis(d as any)),
    actNowDetail: actNowDetail
  };
};

export const transformRheumatologyEncounter = (
  common: RheumatologyEncounter,
  data: _HealthRecord
): RheumatologyEncounter => {
  return {
    ...common,
    diagnoses: (data.phr_clinical_diagnoses || []).map(d => transformInRecord.rheumaDiagnosis(d as any))
  };
};

export const transformInPulmonologyEncounter = (
  common: PulmonologyEncounter,
  data: _HealthRecord & _PulmonologyEncounterFields
): PulmonologyEncounter => {
  return {
    ...common,
    typeSpecificId: data.type_specific_id ? data.type_specific_id : undefined,
    diagnoses: (data.phr_clinical_diagnoses || []).map(d => transformInRecord.pulmonologyDiagnosis(d as any)),
    riskFactors: {
      withRiskFactors: data.risk_factors?.rf || '',
      hostFactors: data.risk_factors?.rf__host_factors || false,
      tobaccoSmoke: data.risk_factors?.rf__tabacco_smoke || false,
      smokeFromHomeCookingAndHeatingFuels: data.risk_factors?.rf__smoke_from_home_cooking || false,
      others: data.risk_factors?.rf__others || ''
    },
    spirometrySpirometricDiagnosis: {
      dateTaken: data.spirometry_spirometric_diagnosis?.ssd__date_taken
        ? new Date(data.spirometry_spirometric_diagnosis?.ssd__date_taken)
        : null,
      fev1Fevc: data.spirometry_spirometric_diagnosis?.ssd__fev1_fvc || '',
      preBdFev1: data.spirometry_spirometric_diagnosis?.ssd__pre_bd_fev1 || '',
      postBdFev: data.spirometry_spirometric_diagnosis?.ssd__post_bd_fev || '',
      frequency: data.spirometry_spirometric_diagnosis?.ssd__frequency || '',
      daytimeSymptoms: data.spirometry_spirometric_diagnosis?.ssd__daytime_symptoms || '',
      relieverNeeded: data.spirometry_spirometric_diagnosis?.ssd__reliever_needed || '',
      anyNightWalking: data.spirometry_spirometric_diagnosis?.ssd__night_waking_asthma || '',
      anyActivityLimitation: data.spirometry_spirometric_diagnosis?.ssd__activity_limitation_asthma || ''
    },
    levelOfControl: data.level_of_control || '',
    triggers: {
      withTriggers: data.triggers?.t || '',
      pollens: data.triggers?.t__pollens || false,
      coldAir: data.triggers?.t__cold_air || false,
      strongOdors: data.triggers?.t__strong_odors || false,
      smoke: data.triggers?.t__smoke || false,
      dustMites: data.triggers?.t__dust_mites || false,
      humidAir: data.triggers?.t__humid_air || false,
      occupation: data.triggers?.t__occupation || false,
      exerciseOrPhysicalActivities: data.triggers?.t__exercise || false,
      molds: data.triggers?.t__molds || false,
      emotions: data.triggers?.t__emotions || false,
      animalDander: data.triggers?.t__animal_dander || false,
      medications: data.triggers?.t__medications || '',
      food: data.triggers?.t__food || ''
    },
    smokingStatus: {
      smoker: data.smoking_status?.ss || '',
      smokesPerDay: data.smoking_status?.ss__smokes_per_day || '',
      willingToQuit: data.smoking_status?.ss__willing_to_quit || '',
      environmentalTobaccoExposure: data.smoking_status?.ss__environmental_tabacco_exposure || ''
    },
    psychologicalIssues: {
      withPsychologicalIssues: data.psychological_issues?.pi || '',
      worried: data.psychological_issues?.pi__worried || '',
      limitation: data.psychological_issues?.pi__limitation || '',
      workplaceIssue: data.psychological_issues?.pi__workplace_issue || ''
    },
    assessmentOfSymptoms: {
      coughing: data.assessment_of_symptoms?.aos__cat_coughing || '',
      phlegm: data.assessment_of_symptoms?.aos__cat_phelgm || '',
      chestTightness: data.assessment_of_symptoms?.aos__cat_chest_feel || '',
      breathless: data.assessment_of_symptoms?.aos__cat_walking_up_hill || '',
      activitiesAtHome: data.assessment_of_symptoms?.aos__cat_home_activities || '',
      lungConditionConfidence: data.assessment_of_symptoms?.aos__cat_leaving_home || '',
      soundlySleep: data.assessment_of_symptoms?.aos__cat_sleep || '',
      energyLevels: data.assessment_of_symptoms?.aos__cat_energy_level || '',
      mMRCScore: data.assessment_of_symptoms?.aos__mmrc_dyspnea_scale || '',
      tonsillarGrade: data.assessment_of_symptoms?.aos__tonsillar_grade || '',
      mallampatiScore: data.assessment_of_symptoms?.aos__mallampati_score || '',
      essInCarStopped: data.assessment_of_symptoms?.aos__ess_in_car_stopped || '',
      essSittingQuietly: data.assessment_of_symptoms?.aos__ess_sitting_quietly || '',
      essSittingInactive: data.assessment_of_symptoms?.aos__ess_sitting_inactive || '',
      essLyingDownToRest: data.assessment_of_symptoms?.aos__ess_lying_down_to_rest || '',
      essPassengerInACar: data.assessment_of_symptoms?.aos__ess_passenger_in_a_car || '',
      essSittingAndResting: data.assessment_of_symptoms?.aos__ess_sitting_and_resting || '',
      essSittingAndTalking: data.assessment_of_symptoms?.aos__ess_sitting_and_talking || '',
      essSittingAndWatchingTv: data.assessment_of_symptoms?.aos__ess_sitting_and_watching_tv || ''
    },
    notesAndFindingsAdditions: {
      wheezes: data.notes_and_findings_details?.nafd__wheezes || false,
      rales: data.notes_and_findings_details?.nafd__rales || false,
      localize: data.notes_and_findings_details?.nafd__localize || ''
    },
    medications: {
      medicationsCompliant: data.medications?.m__medications_compliant || '',
      saba: data.medications?.m__saba_dose_value || '',
      sabaDate: data.medications?.m__saba_dose_date_started
        ? new Date(data.medications?.m__saba_dose_date_started)
        : null,
      laba: data.medications?.m__laba_dose_value || '',
      labaDate: data.medications?.m__laba_dose_date_started
        ? new Date(data.medications?.m__laba_dose_date_started)
        : null,
      lama: data.medications?.m__lama_dose_value || '',
      lamaDate: data.medications?.m__lama_dose_date_started
        ? new Date(data.medications?.m__lama_dose_date_started)
        : null,
      lamaLaba: data.medications?.m__lama_laba_dose_value || '',
      lamaLabaDate: data.medications?.m__lama_laba_dose_date_started
        ? new Date(data.medications?.m__lama_laba_dose_date_started)
        : null,
      lamaIcs: data.medications?.m__lama_ics_value || '',
      lamaIcsDate: data.medications?.m__lama_ics_date_started
        ? new Date(data.medications?.m__lama_ics_date_started)
        : null,
      others: data.medications?.m__others_value || '',
      othersDate: data.medications?.m__others_date_started ? new Date(data.medications?.m__others_date_started) : null
    },
    medicationalIssues: {
      badTaste: data.medicational_issues?.mi__bad_taste || false,
      cough: data.medicational_issues?.mi__cough || false,
      fastHeartbeat: data.medicational_issues?.mi__fast_heartbeat || false,
      nervousness: data.medicational_issues?.mi__nervousness || false,
      shakiness: data.medicational_issues?.mi__shakiness || false,
      soreThroat: data.medicational_issues?.mi__sore_throat || false,
      upsetStomach: data.medicational_issues?.mi__upset_stomach || false,
      other: data.medicational_issues?.mi__other || ''
    },
    citGoodTechnique: data.check_inhaler_technique || '',
    citErrorsSpecify: data.check_inhaler_technique_specify || '',
    selfManagementSupport: {
      asthmaActionPlan: data.self_management_support_and_patient_education?.smsape__asthma_action_plan || false,
      peakFlowAndSymptomDiary: data.self_management_support_and_patient_education?.smsape__peak_flow || false,
      adviceOnTriggerAvoidance: data.self_management_support_and_patient_education?.smsape__trigger_avoidance || false,
      inhalerDeviceTraining:
        data.self_management_support_and_patient_education?.smsape__inhaler_device_training || false,
      inhalerDeviceTrainingDoneBy:
        data.self_management_support_and_patient_education?.smsape__inhaler_device_training_done_by || '',
      asthmaEducation: data.self_management_support_and_patient_education?.smsape__asthma_education || false,
      asthmaEducationDoneBy: data.self_management_support_and_patient_education?.smsape__asthma_education_done_by || '',
      referredToAsthmaClub: data.self_management_support_and_patient_education?.smsape__asthma_club || false,
      referredToAsthmaClubDate: data.self_management_support_and_patient_education?.smsape__asthma_club_date
        ? new Date(data.self_management_support_and_patient_education?.smsape__asthma_club_date)
        : null,
      copdEducation: data.self_management_support_and_patient_education?.smsape__copd_education || false,
      copdEducationDoneBy: data.self_management_support_and_patient_education?.smsape__copd_education_done_by || '',
      referredToPulmonaryRehab: data.self_management_support_and_patient_education?.smsape__pulmonary_rehab || false,
      referredToPulmonaryRehabDate: data.self_management_support_and_patient_education?.smsape__pulmonary_rehab_date
        ? new Date(data.self_management_support_and_patient_education?.smsape__pulmonary_rehab_date)
        : null
    },
    intensifiedCaseFindings: {
      unexplainedCough: data.intensified_case_findings?.icf__unexplained_cough
        ? data.intensified_case_findings?.icf__unexplained_cough
        : '',
      closeContactKnownTb: data.intensified_case_findings?.icf__close_contact_known_tb
        ? data.intensified_case_findings?.icf__close_contact_known_tb
        : '',
      hrgHivAids: data.intensified_case_findings?.icf__hrg__hiv_aids || false,
      hrgDiabetes: data.intensified_case_findings?.icf__hrg__diabetes || false,
      hrgEsrd: data.intensified_case_findings?.icf__hrg__esrd || false,
      hrgCancer: data.intensified_case_findings?.icf__hrg__cancer || false,
      hrgConnectiveTissueDisorder: data.intensified_case_findings?.icf__hrg__connective_tissue_disorder || false,
      hrgAutoimmuneDisease: data.intensified_case_findings?.icf__hrg__autoimmune_disease || false,
      hrgSilicosis: data.intensified_case_findings?.icf__hrg__silicosis || false,
      hrgPatientUnderwentGastrectomy: data.intensified_case_findings?.icf__hrg__patient_underwent_gastrectomy || false,
      hrgPatientProlongedSteroids: data.intensified_case_findings?.icf__hrg__patient_prolonged_steroids || false,
      hrpElderly: data.intensified_case_findings?.icf__hrp__elderly || false,
      hrpUrbanPoor: data.intensified_case_findings?.icf__hrp__urban_poor || false,
      hrpInmates: data.intensified_case_findings?.icf__hrp__inmates || false,
      hrpOtherSettings: data.intensified_case_findings?.icf__hrp__other_settings
        ? data.intensified_case_findings?.icf__hrp__other_settings
        : ''
    },
    bacteriologicalStatus: {
      afpSputum: data.bacteriological_status?.bs__afp_sputum || false,
      afpSputum1stDate: data.bacteriological_status?.bs__afp_sputum_1st_date
        ? new Date(data.bacteriological_status?.bs__afp_sputum_1st_date)
        : null,
      afpSputum2ndDate: data.bacteriological_status?.bs__afp_sputum_2nd_date
        ? new Date(data.bacteriological_status?.bs__afp_sputum_2nd_date)
        : null,
      genexpert: data.bacteriological_status?.bs__genexpert || false,
      genexpertDate: data.bacteriological_status?.bs__genexpert_date
        ? new Date(data.bacteriological_status?.bs__genexpert_date)
        : null,
      tbLamp: data.bacteriological_status?.bs__tb_lamp || false,
      tbLampDate: data.bacteriological_status?.bs__tb_lamp_date
        ? new Date(data.bacteriological_status?.bs__tb_lamp_date)
        : null
    },
    ppdSkinTest: {
      ppd: data.ppd_skin_test?.ppd ? data.ppd_skin_test?.ppd : '',
      ppdSkinReaction: data.ppd_skin_test?.ppd_skin_reaction ? data.ppd_skin_test?.ppd_skin_reaction : '',
      ppdDate: data.ppd_skin_test?.ppd_date ? new Date(data.ppd_skin_test?.ppd_date) : null
    },
    ptbDiagnosticXray: {
      upperLobeInfiltrates: data.ptb_diagnostic_xray?.ptb__upper_lobe_infiltrates || false,
      hilar: data.ptb_diagnostic_xray?.ptb__hilar || false,
      miliaryPattern: data.ptb_diagnostic_xray?.ptb__miliary_pattern || false,
      cavitaryLesion: data.ptb_diagnostic_xray?.ptb__cavitary_lesion || false,
      cavitaryLesionRemarks: data.ptb_diagnostic_xray?.ptb__cavitary_lesion_remarks
        ? data.ptb_diagnostic_xray?.ptb__cavitary_lesion_remarks
        : '',
      nodulePoorlyDefined: data.ptb_diagnostic_xray?.ptb__nodule_poorly_defined || false,
      nodulePoorlyDefinedRemarks: data.ptb_diagnostic_xray?.ptb__nodule_poorly_defined_remarks
        ? data.ptb_diagnostic_xray?.ptb__nodule_poorly_defined_remarks
        : '',
      pleuralEffusion: data.ptb_diagnostic_xray?.ptb__pleural_effusion || false,
      pleuralEffusionRemarks: data.ptb_diagnostic_xray?.ptb__pleural_effusion_remarks
        ? data.ptb_diagnostic_xray?.ptb__pleural_effusion_remarks
        : '',
      otherDiagnosticFindings: data.ptb_diagnostic_xray?.ptb__other_diagnostic_findings
        ? data.ptb_diagnostic_xray?.ptb__other_diagnostic_findings
        : ''
    },
    genexpertMtbRif: data.genexpert_mtb_rif_result ? data.genexpert_mtb_rif_result : '',
    sleepEvaluation: {
      confusion: data.sleep_evaluation?.se__confusion || false,
      dryMouth: data.sleep_evaluation?.se__dry_mouth || false,
      irritability: data.sleep_evaluation?.se__irritability || false,
      legsJerking: data.sleep_evaluation?.se__legs_jerking || false,
      snoringRate: data.sleep_evaluation?.se__snoring_rate || '',
      forgetfulness: data.sleep_evaluation?.se__forgetfulness || false,
      hallucination: data.sleep_evaluation?.se__hallucination || false,
      howBreathing: data.sleep_evaluation?.se__how_breathing || '',
      sexualProblems: data.sleep_evaluation?.se__sexual_problems || false,
      morningHeadaches: data.sleep_evaluation?.se__morning_headaches || false,
      wakingUpChoking: data.sleep_evaluation?.se__waking_up_choking || false,
      abnormalBreathing: data.sleep_evaluation?.se__abnormal_breathing || false,
      anxietyDepression: data.sleep_evaluation?.se__anxiety_depression || false,
      excessivelySleepy: data.sleep_evaluation?.se__excessively_sleepy || false,
      unrefreshingSleep: data.sleep_evaluation?.se__unrefreshing_sleep || false,
      sleepTalkingWalking: data.sleep_evaluation?.se__sleep_talking_walking || false,
      excessiveLoudSnoring: data.sleep_evaluation?.se__excessive_loud_snoring || false,
      howBreathingDuration: data.sleep_evaluation?.se__how_breathing_duration || '',
      wakingUpDuringNight: data.sleep_evaluation?.se__waking_up_during_night || false,
      decreasedConcentration: data.sleep_evaluation?.se__decreased_concentration || false,
      sexualProblemsRemarks: data.sleep_evaluation?.se__sexual_problems_remarks || '',
      difficultyFallingAsleep: data.sleep_evaluation?.se__difficulty_falling_asleep || false,
      howBreathingDurationUnit: data.sleep_evaluation?.se__how_breathing_duration_unit || ''
    },
    associatedSymptoms: {
      vividDreams: data.associated_symptoms?.as__vivid_dreams || '',
      problemLength: data.associated_symptoms?.as__problem_length || '',
      legsFeelRestless: data.associated_symptoms?.as__legs_feel_restless || '',
      partnerComplained: data.associated_symptoms?.as__partner_complained || '',
      awakenKickingLegs: data.associated_symptoms?.as__awaken_kicking_legs || '',
      unableToMoveBody: data.associated_symptoms?.as__unable_to_move_body || '',
      vividDreamsRemarks: data.associated_symptoms?.as__vivid_dreams_remarks || '',
      suddenMuscleWeakness: data.associated_symptoms?.as__sudden_muscle_weakness || '',
      legsFeelRestlessRemarks: data.associated_symptoms?.as__legs_feel_restless_remarks || '',
      partnerComplainedRemarks: data.associated_symptoms?.as__partner_complained_remarks || '',
      sleepingProblemDiagnosed: data.associated_symptoms?.as__sleeping_problem_diagnosed || '',
      awakenKickingLegsRemarks: data.associated_symptoms?.as__awaken_kicking_legs_remarks || '',
      unableToMoveBodyRemarks: data.associated_symptoms?.as__unable_to_move_body_remarks || '',
      legsRestlessnessDelaySleep: data.associated_symptoms?.as__legs_restlessness_delay_sleep || '',
      suddenMuscleWeaknessRemarks: data.associated_symptoms?.as__sudden_muscle_weakness_remarks || '',
      sleepingProblemDiagnosedRemarks: data.associated_symptoms?.as__sleeping_problem_diagnosed_remarks || '',
      legsRestlessnessDelaySleepRemarks: data.associated_symptoms?.as__legs_restlessness_delay_sleep_remarks || ''
    },
    sleepSchedule: {
      dayNap: data.sleep_schedule?.ss__day_nap || '',
      shiftWork: data.sleep_schedule?.ss__shift_work || '',
      dayNapRemarks: data.sleep_schedule?.ss__day_nap_remarks || '',
      dayNapDuration: data.sleep_schedule?.ss__day_nap_duration || '',
      shiftWorkTimeTo: data.sleep_schedule?.ss__shift_work_time_to
        ? new Date(data.sleep_schedule?.ss__shift_work_time_to)
        : null,
      timeGetUpWeekday: data.sleep_schedule?.ss__time_get_up_weekday
        ? new Date(data.sleep_schedule?.ss__time_get_up_weekday)
        : null,
      timeGetUpWeekend: data.sleep_schedule?.ss__time_get_up_weekend
        ? new Date(data.sleep_schedule?.ss__time_get_up_weekend)
        : null,
      timeToFallAsleep: data.sleep_schedule?.ss__time_to_fall_asleep || '',
      shiftWorkTimeFrom: data.sleep_schedule?.ss__shift_work_time_from
        ? new Date(data.sleep_schedule?.ss__shift_work_time_from)
        : null,
      timeWakeUpWeekday: data.sleep_schedule?.ss__time_wake_up_weekday
        ? new Date(data.sleep_schedule?.ss__time_wake_up_weekday)
        : null,
      timeWakeUpWeekend: data.sleep_schedule?.ss__time_wake_up_weekend
        ? new Date(data.sleep_schedule?.ss__time_wake_up_weekend)
        : null,
      timeLieDownWeekday: data.sleep_schedule?.ss__time_lie_down_weekday
        ? new Date(data.sleep_schedule?.ss__time_lie_down_weekday)
        : null,
      timeLieDownWeekend: data.sleep_schedule?.ss__time_lie_down_weekend
        ? new Date(data.sleep_schedule?.ss__time_lie_down_weekend)
        : null,
      timeFallAsleepWeekday: data.sleep_schedule?.ss__time_fall_asleep_weekday
        ? new Date(data.sleep_schedule?.ss__time_fall_asleep_weekday)
        : null,
      timeFallAsleepWeekend: data.sleep_schedule?.ss__time_fall_asleep_weekend
        ? new Date(data.sleep_schedule?.ss__time_fall_asleep_weekend)
        : null,
      medicationsToFallAsleep: data.sleep_schedule?.ss__medications_to_fall_asleep || '',
      medicationsToFallAsleepRemarks: data.sleep_schedule?.ss__medications_to_fall_asleep_remarks || ''
    },
    evaluatingSleepiness: {
      sleepyInDaytime: data.evaluating_sleepiness?.es__sleepy_in_daytime || '',
      refreshedOnWakingUp: data.evaluating_sleepiness?.es__refreshed_on_waking_up || '',
      suddenMuscleWeakness: data.evaluating_sleepiness?.es__sudden_muscle_weakness || '',
      sleepyInDaytimeRemarks: data.evaluating_sleepiness?.es__sleepy_in_daytime_remarks || '',
      involvedInRoadAccidents: data.evaluating_sleepiness?.es__involved_in_road_accidents || '',
      refreshedOnWakingUpRemarks: data.evaluating_sleepiness?.es__refreshed_on_waking_up_remarks || '',
      suddenMuscleWeaknessRemarks: data.evaluating_sleepiness?.es__sudden_muscle_weakness_remarks || '',
      fallenAsleepAtRedLightDriving: data.evaluating_sleepiness?.es__fallen_asleep_at_red_light_driving || '',
      involvedInRoadAccidentsRemarks: data.evaluating_sleepiness?.es__involved_in_road_accidents_remarks || '',
      fallenAsleepAtRedLightDrivingRemarks:
        data.evaluating_sleepiness?.es__fallen_asleep_at_red_light_driving_remarks || ''
    },
    goiter: data.goiter || ''
  };
};

export const transformInPediatricsEncounter = (
  common: PediatricsEncounter,
  data: _HealthRecord
): PediatricsEncounter => {
  const vaccinations = transformInRecord.vaccinations(data);
  const administered = vaccinations.filter(item => item.phrId && item.phrId === data.id && item.givenAt != null);
  const scheduled = vaccinations.filter(item => item.givenAt === null);
  return {
    ...common,
    diagnoses: (data.phr_clinical_diagnoses || []).map(transformInRecord.standardDiagnosis),
    administeredVaccines: administered,
    scheduledVaccines: scheduled,
    notifyEmail: scheduled.length > 0 ? scheduled[0].notifyEmail || false : false,
    notifySms: scheduled.length > 0 ? scheduled[0].notifySms || false : false
  };
};

export const transformOutNewEncounter = (data: NewEncounterInput) => {
  return {
    patient_id: data.patientId,
    clinic_id: data.clinicId,
    visit_date: formatDate(new Date(data.encounterDate), 'yyyy-MM-dd'),
    visit_start_time: formatDate(new Date(data.encounterDate), 'yyyy-MM-dd HH:mm:ss'),
    type: data.encounterType || 'Phrs::TypeStandard',
    active: false
  };
};

export const transformInRecord = {
  subjective: (data: _HealthRecord): Subjective => ({
    chiefComplaint: data.phr_subjective ? data.phr_subjective.chief_complaint || '' : '',
    historyOfPresentIllness: data.history_of_present_illness || '',
    reviewOfSystem: transformInRecord.reviewOfSystem(data.review_of_system),
    ...(data.phr_subjective?.chief_complaint_details
      ? {
          cough: data.phr_subjective.chief_complaint_details.chief_complaint__cough || false,
          basicCough: data.phr_subjective.chief_complaint_details.chief_complaint__cough || false,
          sputumProduction: data.phr_subjective.chief_complaint_details.chief_complaint__sputum_production || false,
          basicSputumProduction:
            data.phr_subjective.chief_complaint_details.chief_complaint__sputum_production || false,
          wheezing: data.phr_subjective.chief_complaint_details.chief_complaint__wheezing || false,
          basicWheezing: data.phr_subjective.chief_complaint_details.chief_complaint__wheezing || false,
          chestTightness: data.phr_subjective.chief_complaint_details.chief_complaint__chest_tightness || false,
          basicChestTightness: data.phr_subjective.chief_complaint_details.chief_complaint__chest_tightness || false,
          shortnessOfBreath: data.phr_subjective.chief_complaint_details.chief_complaint__shortness_of_breath || false,
          basicShortnessOfBreath:
            data.phr_subjective.chief_complaint_details.chief_complaint__shortness_of_breath || false,
          duration: data.phr_subjective.chief_complaint_details.chief_complaint__duration || '',
          basicDuration: data.phr_subjective.chief_complaint_details.chief_complaint__duration || '',
          unintentionalWeightLoss:
            data.phr_subjective.chief_complaint_details.chief_complaint__unintentional_weight_loss || false,
          chestBackPain: data.phr_subjective.chief_complaint_details.chief_complaint__chest_back_pain || false,
          malaise: data.phr_subjective.chief_complaint_details.chief_complaint__malaise || false,
          fever: data.phr_subjective.chief_complaint_details.chief_complaint__fever || false,
          feverTemperature: data.phr_subjective.chief_complaint_details.chief_complaint__fever_temperature || '',
          nightSweats: data.phr_subjective.chief_complaint_details.chief_complaint__night_sweats || false
        }
      : {
          cough: false,
          basicCough: false,
          sputumProduction: false,
          basicSputumProduction: false,
          wheezing: false,
          basicWheezing: false,
          chestTightness: false,
          basicChestTightness: false,
          shortnessOfBreath: false,
          basicShortnessOfBreath: false,
          duration: '',
          basicDuration: '',
          unintentionalWeightLoss: false,
          chestBackPain: false,
          malaise: false,
          fever: false,
          feverTemperature: '',
          nightSweats: false
        })
  }),
  vitals: (data: _HealthRecord): CombinedVitals => ({
    bmi: data.bmi,
    bpDiastolic: data.bp_diastolic,
    bpSystolic: data.bp_systolic,
    heartRate: data.heart_rate,
    hba1c: data.hba1c,
    waistCircumference: data.waist_circumference_value,
    waistCircumferenceUnit: data.waist_circumference_unit,
    rgtUnit: data.rgt_unit || '',
    fgtUnit: data.fgt_unit || '',
    oggtUnit: data.oggt_unit || '',
    rgt: data.rgt,
    fgt: data.fgt,
    oggt: data.oggt,
    respiratoryRate: data.respiratory_rate,
    temperature: data.temperature,
    heightUnit: data.height_unit || '',
    heightValue: data.height_value ? Number(data.height_value) : 0,
    weightUnit: data.weight_unit || '',
    weightValue: data.weight_value ? Number(data.weight_value) : 0,
    bloodSugarUnit: data.blood_sugar_unit || '',
    bloodSugarValue: data.blood_sugar_value ? Number(data.blood_sugar_value) : 0,
    oxySaturationUnit: data.oxy_saturation_unit || '',
    oxySaturationValue: data.oxy_saturation_value ? Number(data.oxy_saturation_value) : 0,
    ecog: data.ecog || '',
    bsam2: data.bsam2 || '',
    pain: data.pain ? Number(data.pain) : 0,
    gsc: data.gsc ? Number(data.gsc) : 0,
    headCircumferenceValue: data.head_circumference_value ? Number(data.head_circumference_value) : 0,
    headCircumferenceUnit: data.head_circumference_unit || '',
    neckCircumferenceValue: data.neck_circumference_value ? Number(data.neck_circumference_value) : 0,
    neckCircumferenceUnit: data.neck_circumference_unit || ''
  }),
  physical: (physical: _PhysicalExam, isGranural: boolean) => {
    if (isGranural) {
      return transformInRecord.bciPhysicalExam(physical);
    }
    return transformInRecord.commonPhysicalExam(physical);
  },
  reviewOfSystem: (ros: _ReviewOfSystem | null): ReviewOfSystem => {
    if (!ros) return reviewOfSystemDefault;
    return {
      id: ros.id,
      encounterID: ros.patient_health_record_id,
      skin: ros.assessment.skin_others,
      heent: ros.assessment.heent_others,
      respiratory: ros.assessment.respiratory_others,
      cardiovascular: ros.assessment.cardiovascular_others,
      gastrointestinal: ros.assessment.gastrointestinal_others,
      genitourinary: ros.assessment.genitourinary_others,
      musculoskeletal: ros.assessment.musculoskeletal_others,
      neurological: ros.assessment.neurological_others,
      metabolicEndocrine: ros.assessment.metabolic_endocrine_others
    };
  },
  commonPhysicalExam: (pe: _PhysicalExam | null): CommonPhysicalExam => {
    if (!pe) return commonPhysicalExamDefault;
    const physicalExam = pe.assessment;
    return {
      id: pe.id,
      encounterID: pe.patient_health_record_id,
      general: physicalExam.general_comments,
      skin: physicalExam.skin_comments,
      head: physicalExam.head_comments,
      eyes: physicalExam.eyes_comments,
      ears: physicalExam.ears_comments,
      nose: physicalExam.nose_comments,
      mouthAndThroat: physicalExam.mouth_and_throat_comments,
      neck: physicalExam.neck_comments,
      breast: physicalExam.breast_comments,
      pelvic: physicalExam.pelvic_comments,
      chestAndLungs: physicalExam.chest_and_lungs_comments,
      heart: physicalExam.heart_comments,
      abdomen: physicalExam.abdomen_comments,
      backAndSpine: physicalExam.back_and_spine_comments,
      extremities: physicalExam.extremities_comments,
      neurologicExams: physicalExam.neurologic_exams_comments,
      allNormal: physicalExam.all_comments__normal
    };
  },
  bciPhysicalExam: (pe: _PhysicalExam | null): BCIPhysicalExam => {
    if (!pe) return BCIPhysicalExamDefault;
    const physicalExam = pe.granular_assessment;
    return {
      id: pe.id,
      encounterID: pe.patient_health_record_id,
      general: {
        generalNormal: physicalExam.general__normal,
        generalConsciousness: physicalExam.general__consciousness,
        generalBodyBuild: physicalExam.general__body_build,
        generalAmbulation: physicalExam.general__ambulation,
        generalCardiorespiratory: physicalExam.general__cardiorespiratory,
        generalRemarks: physicalExam.general__remarks
      },
      skin: {
        skinNormal: physicalExam.skin__normal,
        skinColor: physicalExam.skin__color,
        skinPigmentation: physicalExam.skin__pigmentation,
        skinLesions: physicalExam.skin__lesions,
        skinPigmentationAreas: physicalExam.skin__pigmentation_areas,
        skinLesionsDescribe: physicalExam.skin__lesions_describe,
        skinRemarks: physicalExam.skin__remarks
      },
      heent: {
        heentNormal: physicalExam.heent__normal,
        heentFace: physicalExam.heent__face,
        heentLeftEye: physicalExam.heent__left_eye,
        heentRightEye: physicalExam.heent__right_eye,
        heentLeftEar: physicalExam.heent__left_ear,
        heentRightEar: physicalExam.heent__right_ear,
        heentNoseSeptum: physicalExam.heent__nose__septum,
        heentNoseTurbiinates: physicalExam.heent__nose__turbiinates,
        heentNoseMass: physicalExam.heent__nose__mass,
        heentNoseDischarge: physicalExam.heent__nose__discharge,
        heentThroatMucosa: physicalExam.heent__throat__mucosa,
        heentThroatUlsers: physicalExam.heent__throat__ulsers,
        heentThroatTonsils: physicalExam.heent__throat__tonsils,
        heentThroatUvula: physicalExam.heent__throat__uvula,
        heentThroatTongue: physicalExam.heent__throat__tongue,
        heentThroatPharynx: physicalExam.heent__throat__pharynx,
        heentFaceLocationSize: physicalExam.heent__face_location_size,
        heentLeftEyeRemarks: physicalExam.heent__left_eye_remarks,
        heentRightEyeRemarks: physicalExam.heent__right_eye_remarks,
        heentLeftEarRemarks: physicalExam.heent__left_ear_remarks,
        heentRightEarRemarks: physicalExam.heent__right_ear_remarks,
        heentNoseDischargeSpecify: physicalExam.heent__nose__discharge_specify,
        heentThroatUlsersDescribe: physicalExam.heent__throat__ulsers_describe,
        heentThroatTongueRemarks: physicalExam.heent__throat__tongue_remarks,
        heentRemarks: physicalExam.heent__remarks
      },
      neck: {
        neckNormal: physicalExam.neck__normal,
        neckPulsations: physicalExam.neck__pulsations,
        neckMasses: physicalExam.neck__masses,
        neckLymphNodes: physicalExam.neck__lymph_nodes,
        neckPulsationsSpecify: physicalExam.neck__pulsations_specify,
        neckMassesSpecify: physicalExam.neck__masses_specify,
        neckLymphNumLocCharSize: physicalExam.neck__lymph_num_loc_char_size,
        neckRemarks: physicalExam.neck__remarks
      },
      breast: {
        breastNormal: physicalExam.breast__normal,
        breastRightNippleExcoriation: physicalExam.breast__right__nipple_excoriation,
        breastRightNippleDischarge: physicalExam.breast__right__nipple_discharge,
        breastRightNippleRetraction: physicalExam.breast__right__nipple_retraction,
        breastRightSkin: physicalExam.breast__right__skin,
        breastRightMass: physicalExam.breast__right__mass,
        breastRightAxillaMass: physicalExam.breast__right__axilla_mass,
        breastLeftNippleExcoriation: physicalExam.breast__left__nipple_excoriation,
        breastLeftNippleDischarge: physicalExam.breast__left__nipple_discharge,
        breastLeftNippleRetraction: physicalExam.breast__left__nipple_retraction,
        breastLeftSkin: physicalExam.breast__left__skin,
        breastLeftMass: physicalExam.breast__left__mass,
        breastLeftAxillaMass: physicalExam.breast__left__axilla_mass,
        breastLeftNippleDischargeRemarks: physicalExam.breast__left__nipple_discharge_remarks,
        breastLeftNippleRetractionRemarks: physicalExam.breast__left__nipple_retraction_remarks,
        breastLeftMassRemarks: physicalExam.breast__left__mass_remarks,
        breastLeftAxillaMassRemarks: physicalExam.breast__left__axilla_mass_remarks,
        breastRightNippleDischargeRemarks: physicalExam.breast__right__nipple_discharge_remarks,
        breastRightNippleRetractionRemarks: physicalExam.breast__right__nipple_retraction_remarks,
        breastRightMassRemarks: physicalExam.breast__right__mass_remarks,
        breastRightAxillaMassRemarks: physicalExam.breast__right__axilla_mass_remarks,
        breastRemarks: physicalExam.breast__remarks
      },
      thoraxLungs: {
        thoraxLungsNormal: physicalExam.thorax_lungs__normal,
        thoraxLungsRightMass: physicalExam.thorax_lungs__right__mass,
        thoraxLungsRightTenderness: physicalExam.thorax_lungs__right__tenderness,
        thoraxLungsRightAbnormality: physicalExam.thorax_lungs__right__abnormality,
        thoraxLungsRightBreathSounds: physicalExam.thorax_lungs__right__breath_sounds,
        thoraxLungsRightAdventitiousSounds: physicalExam.thorax_lungs__right__adventitious_sounds,
        thoraxLungsLeftMass: physicalExam.thorax_lungs__left__mass,
        thoraxLungsLeftTenderness: physicalExam.thorax_lungs__left__tenderness,
        thoraxLungsLeftAbnormality: physicalExam.thorax_lungs__left__abnormality,
        thoraxLungsLeftBreathSounds: physicalExam.thorax_lungs__left__breath_sounds,
        thoraxLungsLeftAdventitiousSounds: physicalExam.thorax_lungs__left__adventitious_sounds,
        thoraxLungsLeftMassLocation: physicalExam.thorax_lungs__left__mass_location,
        thoraxLungsLeftTendernessLocation: physicalExam.thorax_lungs__left__tenderness_location,
        thoraxLungsLeftAbnormalityLocation: physicalExam.thorax_lungs__left__abnormality_location,
        thoraxLungsRightMassLocation: physicalExam.thorax_lungs__right__mass_location,
        thoraxLungsRightTendernessLocation: physicalExam.thorax_lungs__right__tenderness_location,
        thoraxLungsRightAbnormalityLocation: physicalExam.thorax_lungs__right__abnormality_location,
        thoraxLungsRemarks: physicalExam.thorax_lungs__remarks
      },
      cardiovascular: {
        cardiovascularNormal: physicalExam.cardiovascular__normal,
        cardiovascularPrecordialPulsations: physicalExam.cardiovascular__precordial_pulsations,
        cardiovascularApexBeat: physicalExam.cardiovascular__apex_beat,
        cardiovascularHeartSounds: physicalExam.cardiovascular__heart_sounds,
        cardiovascularMurmurs: physicalExam.cardiovascular__murmurs,
        cardiovascularPrecordialPulsationsLocation: physicalExam.cardiovascular__precordial_pulsations_location,
        cardiovascularApexBeatLocation: physicalExam.cardiovascular__apex_beat_location,
        cardiovascularHeartSoundsSpecify: physicalExam.cardiovascular__heart_sounds_specify,
        cardiovascularMurmursCharacterize: physicalExam.cardiovascular__murmurs_characterize,
        cardiovascularRemarks: physicalExam.cardiovascular__remarks
      },
      pulses: {
        pulsesNormal: physicalExam.pulses__normal,
        pulsesRight: physicalExam.pulses__right,
        pulsesLeft: physicalExam.pulses__left,
        pulseRemarks: physicalExam.pulse__remarks
      },
      abdomen: {
        abdomenNormal: physicalExam.abdomen__normal,
        abdomenPulsations: physicalExam.abdomen__pulsations,
        abdomenScars: physicalExam.abdomen__scars,
        abdomenDistension: physicalExam.abdomen__distension,
        abdomenAbdominalWallMass: physicalExam.abdomen__abdominal_wall_mass,
        abdomenBowelSounds: physicalExam.abdomen__bowel_sounds,
        abdomenTenderness: physicalExam.abdomen__tenderness,
        abdomenHepatomegaly: physicalExam.abdomen__hepatomegaly,
        abdomenSplenomegaly: physicalExam.abdomen__splenomegaly,
        abdomenMass: physicalExam.abdomen__mass,
        abdomenPulsationsLocation: physicalExam.abdomen__pulsations_location,
        abdomenScarsLocation: physicalExam.abdomen__scars_location,
        abdomenAbdominalWallMassLocation: physicalExam.abdomen__abdominal_wall_mass_location,
        abdomenTendernessLocation: physicalExam.abdomen__tenderness_location,
        abdomenHepatomegalyLiverspanSizeChar: physicalExam.abdomen__hepatomegaly_liverspan_size_char,
        abdomenSplenomegalySize: physicalExam.abdomen__splenomegaly_size,
        abdomenMassCharacterize: physicalExam.abdomen__mass_characterize,
        abdomenRemarks: physicalExam.abdomen__remarks
      },
      muskuloskeletal: {
        muskuloskeletalNormal: physicalExam.muskuloskeletal__normal,
        muskuloskeletalRemarks: physicalExam.muskuloskeletal__remarks
      },
      rectal: {
        rectalNormal: physicalExam.rectal__normal,
        rectalInspectionExcoriation: physicalExam.rectal__inspection__excoriation,
        rectalInspectionErythema: physicalExam.rectal__inspection__erythema,
        rectalInspectionAnalTag: physicalExam.rectal__inspection__anal_tag,
        rectalInspectionHemorrhoids: physicalExam.rectal__inspection__hemorrhoids,
        rectalInspectionMass: physicalExam.rectal__inspection__mass,
        rectalInspectionExcoriationSite: physicalExam.rectal__inspection__excoriation_site,
        rectalInspectionErythemaSite: physicalExam.rectal__inspection__erythema_site,
        rectalInspectionAnalTagSite: physicalExam.rectal__inspection__anal_tag_site,
        rectalInspectionHemorrhoidsSite: physicalExam.rectal__inspection__hemorrhoids_site,
        rectalInspectionMassSite: physicalExam.rectal__inspection__mass_site,
        rectalDigitalMass: physicalExam.rectal__digital__mass,
        rectalDigitalProstate: physicalExam.rectal__digital__prostate,
        rectalDigitalTenderness: physicalExam.rectal__digital__tenderness,
        rectalDigitalColorOfStools: physicalExam.rectal__digital__color_of_stools,
        rectalDigitalMassSite: physicalExam.rectal__digital__mass_site,
        rectalDigitalProstateCharacterize: physicalExam.rectal__digital__prostate_characterize,
        rectalDigitalTendernessSite: physicalExam.rectal__digital__tenderness_site,
        rectalDigitalColorOfStoolsSpecify: physicalExam.rectal__digital__color_of_stools_specify,
        rectalRemarks: physicalExam.rectal__remarks
      },
      gynecologic: {
        gynecologicNormal: physicalExam.gynecologic__normal,
        gynecologicPerineumExcoriation: physicalExam.gynecologic__perineum__excoriation,
        gynecologicPerineumErythema: physicalExam.gynecologic__perineum__erythema,
        gynecologicPerineumMass: physicalExam.gynecologic__perineum__mass,
        gynecologicPerineumExcoriationSite: physicalExam.gynecologic__perineum__excoriation_site,
        gynecologicPerineumErythemaSite: physicalExam.gynecologic__perineum__erythema_site,
        gynecologicPerineumMassSite: physicalExam.gynecologic__perineum__mass_site,
        gynecologicSpeculumCervixDischarge: physicalExam.gynecologic__speculum__cervix_discharge,
        gynecologicSpeculumCervixExcoriation: physicalExam.gynecologic__speculum__cervix_excoriation,
        gynecologicSpeculumCervixMass: physicalExam.gynecologic__speculum__cervix_mass,
        gynecologicSpeculumCervixOthers: physicalExam.gynecologic__speculum__cervix_others,
        gynecologicSpeculumVaginaExcoriation: physicalExam.gynecologic__speculum__vagina_excoriation,
        gynecologicSpeculumVaginaErythema: physicalExam.gynecologic__speculum__vagina_erythema,
        gynecologicSpeculumVaginaMass: physicalExam.gynecologic__speculum__vagina_mass,
        gynecologicSpeculumVaginaOthers: physicalExam.gynecologic__speculum__vagina_others,
        gynecologicSpeculumCervixDischargeType: physicalExam.gynecologic__speculum__cervix_discharge_type,
        gynecologicSpeculumCervixExcoriationSite: physicalExam.gynecologic__speculum__cervix_excoriation_site,
        gynecologicSpeculumCervixMassSite: physicalExam.gynecologic__speculum__cervix_mass_site,
        gynecologicSpeculumCervixOthersSite: physicalExam.gynecologic__speculum__cervix_others_site,
        gynecologicSpeculumVaginaExcoriationSite: physicalExam.gynecologic__speculum__vagina_excoriation_site,
        gynecologicSpeculumVaginaErythemaSite: physicalExam.gynecologic__speculum__vagina_erythema_site,
        gynecologicSpeculumVaginaMassSite: physicalExam.gynecologic__speculum__vagina_mass_site,
        gynecologicSpeculumVaginaOthersSite: physicalExam.gynecologic__speculum__vagina_others_site,
        gynecologicInternalExaminationCervixTenderness:
          physicalExam.gynecologic__internal_examination__cervix_tenderness,
        gynecologicInternalExaminationMass: physicalExam.gynecologic__internal_examination__mass,
        gynecologicInternalExaminationUterusSize: physicalExam.gynecologic__internal_examination__uterus_size,
        gynecologicInternalExaminationUterusTenderness:
          physicalExam.gynecologic__internal_examination__uterus_tenderness,
        gynecologicInternalExaminationAdnexaeMass: physicalExam.gynecologic__internal_examination__adnexae_mass,
        gynecologicInternalExaminationAdnexaeTenderness:
          physicalExam.gynecologic__internal_examination__adnexae_tenderness,
        gynecologicInternalExaminationCervixTendernessSite:
          physicalExam.gynecologic__internal_examination__cervix_tenderness_site,
        gynecologicInternalExaminationMassSite: physicalExam.gynecologic__internal_examination__mass_site,
        gynecologicInternalExaminationUterusSizeRemarks:
          physicalExam.gynecologic__internal_examination__uterus_size_remarks,
        gynecologicInternalExaminationUterusTendernessSite:
          physicalExam.gynecologic__internal_examination__uterus_tenderness_site,
        gynecologicInternalExaminationAdnexaeMassSite:
          physicalExam.gynecologic__internal_examination__adnexae_mass_site,
        gynecologicInternalExaminationAdnexaeTendernessSite:
          physicalExam.gynecologic__internal_examination__adnexae_tenderness_site,
        gynecologicRemarks: physicalExam.gynecologic__remarks
      },
      neurological: {
        neurologicalNormal: physicalExam.neurological__normal,
        neurologicalMentalStatusConsciousness: physicalExam.neurological__mental_status__consciousness,
        neurologicalMentalStatusOrientation: physicalExam.neurological__mental_status__orientation,
        neurologicalCranialNerves: physicalExam.neurological__cranial_nerves,
        neurologicalMotorUpperExtremitiesLeft: physicalExam.neurological__motor__upper_extremities_left,
        neurologicalMotorUpperExtremitiesRight: physicalExam.neurological__motor__upper_extremities_right,
        neurologicalMotorLowerExtremitiesLeft: physicalExam.neurological__motor__lower_extremities_left,
        neurologicalMotorLowerExtremitiesRight: physicalExam.neurological__motor__lower_extremities_right,
        neurologicalCoordinationInvoluntaryMovements: physicalExam.neurological__coordination_involuntary_movements,
        neurologicalSensory: physicalExam.neurological__sensory,
        neurologicalReflexesSuperficial: physicalExam.neurological__reflexes__superficial,
        neurologicalReflexesDeepTendon: physicalExam.neurological__reflexes__deep_tendon,
        neurologicalMentalStatusOrientationSpecify: physicalExam.neurological__mental_status__orientation_specify,
        neurologicalMotorUpperExtremitiesLeftSpecify: physicalExam.neurological__motor__upper_extremities_left_specify,
        neurologicalMotorUpperExtremitiesRightSpecify:
          physicalExam.neurological__motor__upper_extremities_right_specify,
        neurologicalMotorLowerExtremitiesLeftSpecify: physicalExam.neurological__motor__lower_extremities_left_specify,
        neurologicalMotorLowerExtremitiesRightSpecify:
          physicalExam.neurological__motor__lower_extremities_right_specify,
        neurologicalCoordinationInvoluntaryMovementsSpecify:
          physicalExam.neurological__coordination_involuntary_movements_specify,
        neurologicalSensorySpecify: physicalExam.neurological__sensory_specify,
        neurologicalReflexesSuperficialSpecify: physicalExam.neurological__reflexes__superficial_specify,
        neurologicalReflexesDeepTendonSpecify: physicalExam.neurological__reflexes__deep_tendon_specify,
        neurologicalRemarks: physicalExam.neurological__remarks
      }
    };
  },
  prescriptions: (prescriptions?: _Prescription[] | null): Prescription[] => {
    if (!prescriptions) return [];
    return prescriptions
      .map(prescription => {
        const diagnoses = prescription.diagnosis_list.map(transformInRecord.standardDiagnosis);
        const diagnosisPrescription = prescription.phr_diagnosis_prescriptions[0]?.id;

        const { details: biologics } = prescription;
        return {
          id: prescription.id,
          encounterId: prescription.patient_health_record_id,
          brand: prescription.brand_name || '',
          generic: prescription.generic_name || '',
          preparation: prescription.preparation || '',
          quantity: prescription.quantity,
          instruction: prescription.instruction || '',
          diagnosis: diagnoses.length > 0 ? diagnoses[0] : null,
          diagnosisPrescriptionId: diagnosisPrescription,
          order: prescription.order,
          responseLevel: prescription.response_level,
          responseRemarks: prescription.response_remarks,
          createdAt: new Date(prescription.created_at),
          updatedAt: new Date(prescription.updated_at),
          biologicsBiologic: biologics.biologics__biologic,
          biologicsCancer: biologics.biologics__cancer,
          biologicsHepatitis: biologics.biologics__hepatitis,
          biologicsHerpesZoster: biologics.biologics__herpes_zoster,
          biologicsInfusionNumber: biologics.biologics__infusion_number
            ? parseInt(biologics.biologics__infusion_number)
            : 0,
          biologicsMalignancy: biologics.biologics__malignancy,
          biologicsNotes: biologics.biologics__notes,
          biologicsPreparation: biologics.biologics__preparation,
          biologicsTuberculosis: biologics.biologics__tuberculosis
        };
      })
      .sort((a, b) => a.order - b.order);
  },
  ref_prescriptions: (data: any): any[] => {
    const hits: any[] = data.hits ? data.hits.hits : [];
    return hits.map(hit => {
      return {
        id: hit._source.id,
        brand: hit._source.brand_name || '',
        generic: hit._source.generic_name || '',
        preparation: hit._source.preparation || ''
      };
    });
  },
  vaccinations: (data: _HealthRecord): EncounterVaccination[] => {
    const vaccinations = data.vaccinations || [];
    return vaccinations.map(item => ({
      id: item.id ? item.id : 0,
      patientId: item.patient_id,
      phrId: item.patient_health_record_id || -1,
      vaccine: item.vaccine || '',
      vaccineType: item.vaccine_type || '',
      dosage: item.dosage || '',
      givenAt: item.given_at ? new Date(item.given_at) : null,
      dueAt: item.due_at ? new Date(item.due_at) : null,
      administeredBy: item.administered_by,
      administered: false,
      notifyEmail: item.notify_email,
      notifySms: item.notify_sms
    }));
  },
  standardDiagnosis: (diag: _AllDiagnosisType): StandardDiagnosis => ({
    id: diag.id,
    code: diag.code || '',
    name: diag.name,
    additional: diag.additional || ''
  }),
  endoDiagnosis: (diag: _AllDiagnosisType): EndoDiagnosis => ({
    id: diag.id,
    code: diag.code || '',
    name: diag.name,
    additional: diag.additional || '',
    dateOfOnset: diag.date_of_onset ? parseDate(new Date(diag.date_of_onset)) ?? '' : ''
  }),
  oncoDiagnosis: (diag: _OncologyDiagnosis & { others: any; staging_form_ids?: string }): OncoDiagnosis => ({
    ...transformInRecord.standardDiagnosis(diag),
    dateOfDiagnosis: diag.date_of_diagnosis ? parseDate(new Date(diag.date_of_diagnosis)) ?? '' : '',
    tumorLocation: diag.others?.tumor_location || '',
    stage: diag.others?.stage || '',
    remarks: diag.others?.remarks || '',
    segments: {
      sigmoidColon: diag.others?.segment_sigmoid_colon || false,
      ascendingColon: diag.others?.segment_ascending_colon || false,
      descendingColon: diag.others?.segment_descending_colon || false,
      transverseColon: diag.others?.segment_transverse_colon || false
    },
    receptors: {
      notTested: !diag.others?.tested,
      estrogenPositive: diag.others?.receptors__estrogen_positive || '',
      progestorenePositive: diag.others?.receptors__progestorene_positive || '',
      her2Positive: diag.others?.receptors__her2_positive || '',
      ros1Positive: diag.others?.receptors__ros1_positive || '',
      pdl1Positive: diag.others?.receptors__pdl1_positive || '',
      ntrk: diag.others?.receptors__ntrk || '',
      krasNras: diag.others?.receptors__kras_nras || '',
      braf: diag.others?.receptors__braf || '',
      msi: diag.others?.receptors__msi || '',
      egfr: diag.others?.receptors__egfr || '',
      alk: diag.others?.receptors__alk || '',
      ros1: diag.others?.receptors__ros1 || '',
      pdl1: diag.others?.receptors__pdl1 || '',
      egfrMutation: diag.others?.receptors__egfr_mutation || '',
      alkGeneFusion: diag.others?.receptors__alk_gene_fusion || '',
      krasMutation: diag.others?.receptors__kras_mutation || '',
      her2neuIhc: diag.others?.receptors__her2neu_ihc || '',
      her2neuFish: diag.others?.receptors__her2neu_fish || '',
      others: diag.others?.receptors__others || ''
    },
    diagramData: diag.diagram_data || '',
    treatment: transformInTreatment(diag),
    stagingFormIds: (diag.staging_form_ids || '')
      .split(',')
      .filter(val => val)
      .map((item: string) => parseInt(item))
  }),
  rheumaDiagnosis: (diag: _RheumatologyDiagnosis): RheumaDiagnosis => ({
    ...transformInRecord.standardDiagnosis(diag),
    raEvaluation: {
      age_of_diagnosis: diag.others?.age_of_diagnosis,
      age_of_onset: diag.others?.age_of_onset,
      rheumatoid_arthritis_rf: diag.others?.rheumatoid_arthritis_rf || '',
      rheumatoid_arthritis_acpa: diag.others?.rheumatoid_arthritis_acpa || '',
      rheumatoid_arthritis_duration: diag.others?.rheumatoid_arthritis_duration || '',
      rheumatoid_arthritis_esr: diag.others?.rheumatoid_arthritis_esr || '',
      rheumatoid_arthritis_crp: diag.others?.rheumatoid_arthritis_crp || '',
      rheumatoid_arthritis_joints: diag.others?.rheumatoid_arthritis_joints,
      rheumatoid_arthritis_rf_score: diag.others?.rheumatoid_arthritis_rf_score,
      ra_lj_1: diag.others?.ra_lj_1,
      ra_lj_2: diag.others?.ra_lj_2,
      ra_lj_3: diag.others?.ra_lj_3,
      ra_lj_4: diag.others?.ra_lj_4,
      ra_lj_5: diag.others?.ra_lj_5,
      ra_lj_6: diag.others?.ra_lj_6,
      ra_lj_7: diag.others?.ra_lj_7,
      ra_lj_8: diag.others?.ra_lj_8,
      ra_lj_9: diag.others?.ra_lj_9,
      ra_lj_10: diag.others?.ra_lj_10,
      ra_lj_11: diag.others?.ra_lj_11,
      ra_lj_12: diag.others?.ra_lj_12,
      ra_lj_13: diag.others?.ra_lj_13,
      ra_lj_14: diag.others?.ra_lj_14,
      ra_lj_15: diag.others?.ra_lj_15,
      ra_lj_16: diag.others?.ra_lj_16,
      ra_lj_total: diag.others?.ra_lj_total,
      ra_sj_1: diag.others?.ra_sj_1,
      ra_sj_2: diag.others?.ra_sj_2,
      ra_sj_3: diag.others?.ra_sj_3,
      ra_sj_4: diag.others?.ra_sj_4,
      ra_sj_5: diag.others?.ra_sj_5,
      ra_sj_6: diag.others?.ra_sj_6,
      ra_sj_7: diag.others?.ra_sj_7,
      ra_sj_8: diag.others?.ra_sj_8,
      ra_sj_9: diag.others?.ra_sj_9,
      ra_sj_10: diag.others?.ra_sj_10,
      ra_sj_11: diag.others?.ra_sj_11,
      ra_sj_12: diag.others?.ra_sj_12,
      ra_sj_13: diag.others?.ra_sj_13,
      ra_sj_14: diag.others?.ra_sj_14,
      ra_sj_15: diag.others?.ra_sj_15,
      ra_sj_16: diag.others?.ra_sj_16,
      ra_sj_17: diag.others?.ra_sj_17,
      ra_sj_18: diag.others?.ra_sj_18,
      ra_sj_19: diag.others?.ra_sj_19,
      ra_sj_20: diag.others?.ra_sj_20,
      ra_sj_21: diag.others?.ra_sj_21,
      ra_sj_22: diag.others?.ra_sj_22,
      ra_sj_23: diag.others?.ra_sj_23,
      ra_sj_24: diag.others?.ra_sj_24,
      ra_sj_25: diag.others?.ra_sj_25,
      ra_sj_26: diag.others?.ra_sj_26,
      ra_sj_27: diag.others?.ra_sj_27,
      ra_sj_28: diag.others?.ra_sj_28,
      ra_sj_29: diag.others?.ra_sj_29,
      ra_sj_30: diag.others?.ra_sj_30,
      ra_sj_31: diag.others?.ra_sj_31,
      ra_sj_32: diag.others?.ra_sj_32,
      ra_sj_33: diag.others?.ra_sj_33,
      ra_sj_34: diag.others?.ra_sj_34,
      ra_sj_35: diag.others?.ra_sj_35,
      ra_sj_36: diag.others?.ra_sj_36,
      ra_sj_37: diag.others?.ra_sj_37,
      ra_sj_38: diag.others?.ra_sj_38,
      ra_sj_39: diag.others?.ra_sj_39,
      ra_sj_40: diag.others?.ra_sj_40,
      ra_sj_41: diag.others?.ra_sj_41,
      ra_sj_42: diag.others?.ra_sj_42,
      ra_sj_43: diag.others?.ra_sj_43,
      ra_sj_44: diag.others?.ra_sj_44,
      ra_sj_45: diag.others?.ra_sj_45,
      ra_sj_46: diag.others?.ra_sj_46,
      ra_sj_47: diag.others?.ra_sj_47,
      ra_sj_48: diag.others?.ra_sj_48,
      ra_sj_49: diag.others?.ra_sj_49,
      ra_sj_50: diag.others?.ra_sj_50,
      ra_sj_51: diag.others?.ra_sj_51,
      ra_sj_52: diag.others?.ra_sj_52,
      ra_sj_53: diag.others?.ra_sj_53,
      ra_sj_54: diag.others?.ra_sj_54,
      ra_sj_55: diag.others?.ra_sj_55,
      ra_sj_56: diag.others?.ra_sj_56,
      ra_sj_57: diag.others?.ra_sj_57,
      ra_sj_58: diag.others?.ra_sj_58,
      ra_sj_59: diag.others?.ra_sj_59,
      ra_sj_60: diag.others?.ra_sj_60,
      ra_sj_total: diag.others?.ra_sj_total
    },
    diseaseActivity: {
      disease_activity_esr: diag.others?.disease_activity_esr,
      disease_activity_crp: diag.others?.disease_activity_crp,
      disease_activity_pga: diag.others?.disease_activity_pga,
      disease_activity_ega: diag.others?.disease_activity_ega,
      disease_activity_notes: diag.others?.disease_activity_notes,
      swollen_lj_1: diag.others?.swollen_lj_1,
      swollen_lj_2: diag.others?.swollen_lj_2,
      swollen_lj_3: diag.others?.swollen_lj_3,
      swollen_lj_4: diag.others?.swollen_lj_4,
      swollen_lj_5: diag.others?.swollen_lj_5,
      swollen_lj_6: diag.others?.swollen_lj_6,
      swollen_lj_7: diag.others?.swollen_lj_7,
      swollen_lj_8: diag.others?.swollen_lj_8,
      swollen_lj_9: diag.others?.swollen_lj_9,
      swollen_lj_10: diag.others?.swollen_lj_10,
      swollen_lj_11: diag.others?.swollen_lj_11,
      swollen_lj_12: diag.others?.swollen_lj_12,
      swollen_lj_13: diag.others?.swollen_lj_13,
      swollen_lj_14: diag.others?.swollen_lj_14,
      swollen_lj_15: diag.others?.swollen_lj_15,
      swollen_lj_16: diag.others?.swollen_lj_16,
      swollen_lj_total: diag.others?.swollen_lj_total,
      swollen_sj_1: diag.others?.swollen_sj_1,
      swollen_sj_2: diag.others?.swollen_sj_2,
      swollen_sj_3: diag.others?.swollen_sj_3,
      swollen_sj_4: diag.others?.swollen_sj_4,
      swollen_sj_5: diag.others?.swollen_sj_5,
      swollen_sj_6: diag.others?.swollen_sj_6,
      swollen_sj_7: diag.others?.swollen_sj_7,
      swollen_sj_8: diag.others?.swollen_sj_8,
      swollen_sj_9: diag.others?.swollen_sj_9,
      swollen_sj_10: diag.others?.swollen_sj_10,
      swollen_sj_11: diag.others?.swollen_sj_11,
      swollen_sj_12: diag.others?.swollen_sj_12,
      swollen_sj_13: diag.others?.swollen_sj_13,
      swollen_sj_14: diag.others?.swollen_sj_14,
      swollen_sj_15: diag.others?.swollen_sj_15,
      swollen_sj_16: diag.others?.swollen_sj_16,
      swollen_sj_17: diag.others?.swollen_sj_17,
      swollen_sj_18: diag.others?.swollen_sj_18,
      swollen_sj_19: diag.others?.swollen_sj_19,
      swollen_sj_20: diag.others?.swollen_sj_20,
      swollen_sj_21: diag.others?.swollen_sj_21,
      swollen_sj_22: diag.others?.swollen_sj_22,
      swollen_sj_23: diag.others?.swollen_sj_23,
      swollen_sj_24: diag.others?.swollen_sj_24,
      swollen_sj_25: diag.others?.swollen_sj_25,
      swollen_sj_26: diag.others?.swollen_sj_26,
      swollen_sj_27: diag.others?.swollen_sj_27,
      swollen_sj_28: diag.others?.swollen_sj_28,
      swollen_sj_29: diag.others?.swollen_sj_29,
      swollen_sj_30: diag.others?.swollen_sj_30,
      swollen_sj_31: diag.others?.swollen_sj_31,
      swollen_sj_32: diag.others?.swollen_sj_32,
      swollen_sj_33: diag.others?.swollen_sj_33,
      swollen_sj_34: diag.others?.swollen_sj_34,
      swollen_sj_35: diag.others?.swollen_sj_35,
      swollen_sj_36: diag.others?.swollen_sj_36,
      swollen_sj_37: diag.others?.swollen_sj_37,
      swollen_sj_38: diag.others?.swollen_sj_38,
      swollen_sj_39: diag.others?.swollen_sj_39,
      swollen_sj_40: diag.others?.swollen_sj_40,
      swollen_sj_41: diag.others?.swollen_sj_41,
      swollen_sj_42: diag.others?.swollen_sj_42,
      swollen_sj_43: diag.others?.swollen_sj_43,
      swollen_sj_44: diag.others?.swollen_sj_44,
      swollen_sj_45: diag.others?.swollen_sj_45,
      swollen_sj_46: diag.others?.swollen_sj_46,
      swollen_sj_47: diag.others?.swollen_sj_47,
      swollen_sj_48: diag.others?.swollen_sj_48,
      swollen_sj_49: diag.others?.swollen_sj_49,
      swollen_sj_50: diag.others?.swollen_sj_50,
      swollen_sj_51: diag.others?.swollen_sj_51,
      swollen_sj_52: diag.others?.swollen_sj_52,
      swollen_sj_53: diag.others?.swollen_sj_53,
      swollen_sj_54: diag.others?.swollen_sj_54,
      swollen_sj_55: diag.others?.swollen_sj_55,
      swollen_sj_56: diag.others?.swollen_sj_56,
      swollen_sj_57: diag.others?.swollen_sj_57,
      swollen_sj_58: diag.others?.swollen_sj_58,
      swollen_sj_59: diag.others?.swollen_sj_59,
      swollen_sj_60: diag.others?.swollen_sj_60,
      swollen_sj_total: diag.others?.swollen_sj_total,
      tender_lj_1: diag.others?.tender_lj_1,
      tender_lj_2: diag.others?.tender_lj_2,
      tender_lj_3: diag.others?.tender_lj_3,
      tender_lj_4: diag.others?.tender_lj_4,
      tender_lj_5: diag.others?.tender_lj_5,
      tender_lj_6: diag.others?.tender_lj_6,
      tender_lj_7: diag.others?.tender_lj_7,
      tender_lj_8: diag.others?.tender_lj_8,
      tender_lj_9: diag.others?.tender_lj_9,
      tender_lj_10: diag.others?.tender_lj_10,
      tender_lj_11: diag.others?.tender_lj_11,
      tender_lj_12: diag.others?.tender_lj_12,
      tender_lj_13: diag.others?.tender_lj_13,
      tender_lj_14: diag.others?.tender_lj_14,
      tender_lj_15: diag.others?.tender_lj_15,
      tender_lj_16: diag.others?.tender_lj_16,
      tender_lj_total: diag.others?.tender_lj_total,
      tender_sj_1: diag.others?.tender_sj_1,
      tender_sj_2: diag.others?.tender_sj_2,
      tender_sj_3: diag.others?.tender_sj_3,
      tender_sj_4: diag.others?.tender_sj_4,
      tender_sj_5: diag.others?.tender_sj_5,
      tender_sj_6: diag.others?.tender_sj_6,
      tender_sj_7: diag.others?.tender_sj_7,
      tender_sj_8: diag.others?.tender_sj_8,
      tender_sj_9: diag.others?.tender_sj_9,
      tender_sj_10: diag.others?.tender_sj_10,
      tender_sj_11: diag.others?.tender_sj_11,
      tender_sj_12: diag.others?.tender_sj_12,
      tender_sj_13: diag.others?.tender_sj_13,
      tender_sj_14: diag.others?.tender_sj_14,
      tender_sj_15: diag.others?.tender_sj_15,
      tender_sj_16: diag.others?.tender_sj_16,
      tender_sj_17: diag.others?.tender_sj_17,
      tender_sj_18: diag.others?.tender_sj_18,
      tender_sj_19: diag.others?.tender_sj_19,
      tender_sj_20: diag.others?.tender_sj_20,
      tender_sj_21: diag.others?.tender_sj_21,
      tender_sj_22: diag.others?.tender_sj_22,
      tender_sj_23: diag.others?.tender_sj_23,
      tender_sj_24: diag.others?.tender_sj_24,
      tender_sj_25: diag.others?.tender_sj_25,
      tender_sj_26: diag.others?.tender_sj_26,
      tender_sj_27: diag.others?.tender_sj_27,
      tender_sj_28: diag.others?.tender_sj_28,
      tender_sj_29: diag.others?.tender_sj_29,
      tender_sj_30: diag.others?.tender_sj_30,
      tender_sj_31: diag.others?.tender_sj_31,
      tender_sj_32: diag.others?.tender_sj_32,
      tender_sj_33: diag.others?.tender_sj_33,
      tender_sj_34: diag.others?.tender_sj_34,
      tender_sj_35: diag.others?.tender_sj_35,
      tender_sj_36: diag.others?.tender_sj_36,
      tender_sj_37: diag.others?.tender_sj_37,
      tender_sj_38: diag.others?.tender_sj_38,
      tender_sj_39: diag.others?.tender_sj_39,
      tender_sj_40: diag.others?.tender_sj_40,
      tender_sj_41: diag.others?.tender_sj_41,
      tender_sj_42: diag.others?.tender_sj_42,
      tender_sj_43: diag.others?.tender_sj_43,
      tender_sj_44: diag.others?.tender_sj_44,
      tender_sj_45: diag.others?.tender_sj_45,
      tender_sj_46: diag.others?.tender_sj_46,
      tender_sj_47: diag.others?.tender_sj_47,
      tender_sj_48: diag.others?.tender_sj_48,
      tender_sj_49: diag.others?.tender_sj_49,
      tender_sj_50: diag.others?.tender_sj_50,
      tender_sj_51: diag.others?.tender_sj_51,
      tender_sj_52: diag.others?.tender_sj_52,
      tender_sj_53: diag.others?.tender_sj_53,
      tender_sj_54: diag.others?.tender_sj_54,
      tender_sj_55: diag.others?.tender_sj_55,
      tender_sj_56: diag.others?.tender_sj_56,
      tender_sj_57: diag.others?.tender_sj_57,
      tender_sj_58: diag.others?.tender_sj_58,
      tender_sj_59: diag.others?.tender_sj_59,
      tender_sj_60: diag.others?.tender_sj_60,
      tender_sj_total: diag.others?.tender_sj_total
    }
  }),
  pulmonologyDiagnosis: (diag: _PulmonologyDiagnosis): PulmoDiagnosis => {
    return {
      id: diag.id,
      code: diag.code || '',
      name: diag.name,
      additional: diag.additional || '',
      ...transformInRecord.pulmonologyOthers(diag.type || '', diag.others)
    };
  },
  pulmonologyOthers: (type: string, diagnosisOthers?: _AllPulmonologyDiagnosisOtherType) => {
    if (type === 'Diagnosis::BronchialAsthma') {
      const others = diagnosisOthers as _BronchialAsthmaDiagnosisOthers | undefined;
      return {
        spirometricConfirmation: others?.spirometric_confirmation,
        empiricTreatment: others?.empiric_treatment,
        spirometry: others?.spirometry,
        spirometryDate: others?.date_taken ? new Date(others.date_taken) : null,
        preFevFec: others?.pre__fev_fvc,
        preFev1: others?.pre__fev1,
        preFevc: others?.pre__fevc,
        postFevFec: others?.post__fev_fvc,
        postFev1: others?.post__fev1,
        postFevc: others?.post__fevc,
        reversibility: others?.reversibility,
        peakFlow: others?.peak_flow
      };
    } else if (type === 'Diagnosis::Tuberculosis') {
      const others = diagnosisOthers as _TuberculosisDiagnosisOthers;
      return {
        startCat: others.start_cat ? others.start_cat : '',
        prophylaxis: others.prophylaxis ? others.prophylaxis : '',
        referToPmdt: others.refer_to_pmdt || false,
        referToTbdc: others.refer_to_tbdc || false
      };
    } else return {};
  },
  diagnosisWithTreatment: (diagnosis: _AllDiagnosisType): DiagnosisWithTreatment => ({
    id: diagnosis.id,
    phrId: diagnosis.patient_health_record_id,
    code: diagnosis.code || '',
    name: diagnosis.name || '',
    additional: diagnosis.additional || '',
    stage: diagnosis.others?.stage || '',
    treatment: transformInTreatment(diagnosis)
  })
};

export const transformInTreatment = (diag: _AllDiagnosisType): TreatmentPlan => {
  return diag.treatment
    ? {
        therapyType: transformInTherapyType(diag.treatment.therapy_type),
        radiations: transformInRadiations(diag.treatment.radiations),
        radiationsBCI: (diag.treatment.radiation_bcis || []).map(item =>
          transformInBciRadTreatment.transformMain(item)
        ),
        surgeries: transformInSurgery(diag.treatment.surgeries),
        therapies: transformInTherapies(diag.treatment.therapies),
        agents: transformInAgents(diag.treatment.agents),
        procedures: transformInProcedures(diag.treatment.procedures),
        historyTrail: transformInHistoryTrail(diag.treatment.history_trail),
        remarks: diag.treatment.remarks || '',
        approved: diag.treatment.approved,
        id: diag.treatment.id,
        lineOfTreatment: diag.treatment.line_of_treatment || '',
        antiCancer: diag.treatment.anti_cancers?.length ? transformInAntiCancer(diag.treatment.anti_cancers) : {}
      }
    : TREATMENT_DEFAULT;
};

const transformInAntiCancer = (anti_cancers: _TreatmentAntiCancer[]): any => {
  const { id, details } = anti_cancers[0];
  return {
    id,
    ...details,
    end_date: details.end_date ? parseDate(new Date(details.end_date)) : '',
    start_date: details.start_date ? parseDate(new Date(details.start_date)) : ''
  };
};

export const transformOutTreatment = (treatment: TreatmentPlan, phrID: number, diagnosisID: number): _TreatmentFlat => {
  return {
    id: treatment.id,
    ...transformOutTherapyType(treatment.therapyType),
    patient_health_record_id: phrID,
    diagnosis_id: diagnosisID,
    radiations: transformOutRadiations(treatment, phrID, diagnosisID),
    radiation_bcis:
      treatment.radiationsBCI.length > 0
        ? [transformOutBciRadTreatment(treatment.radiationsBCI[0], diagnosisID, phrID, treatment.id)]
        : [],
    surgeries: transformOutSurgery(treatment, phrID, diagnosisID),
    therapies: transformOutTherapies(treatment, phrID, diagnosisID),
    procedures: transformOutProcedures(treatment, phrID, diagnosisID),
    agents: transformOutAgents(treatment, phrID, diagnosisID),
    approved: treatment.approved,
    remarks: treatment.remarks,
    line_of_treatment: treatment.lineOfTreatment,
    anti_cancers: treatment.antiCancer
  };
};

const transformInTherapyType = (treatment: _TherapyType) => {
  if (treatment?.adjuvant) return 'adjuvant';
  else if (treatment?.neoadjuvant) return 'neoadjuvant';
  else if (treatment?.palliative) return 'palliative';
  return '';
};

const transformOutTherapyType = (therapyType: string): _TherapyType => {
  let adjuvant: boolean = false;
  let neoadjuvant: boolean = false;
  let palliative: boolean = false;
  switch (therapyType) {
    case 'adjuvant':
      adjuvant = true;
      break;
    case 'neoadjuvant':
      neoadjuvant = true;
      break;
    case 'palliative':
      palliative = true;
      break;
    default:
      break;
  }
  return {
    adjuvant: adjuvant,
    neoadjuvant: neoadjuvant,
    palliative: palliative
  };
};

const transformInRadiations = (radiations: _RadiationTreatment[]): Radiation[] => {
  return radiations.map(radiation => {
    return {
      id: radiation.id,
      phrTreatmentId: radiation.phr_treatment_id,
      type: radiation.type,
      bodyArea: radiation.details?.body_area,
      frequency: radiation.details?.frequency,
      startDate: radiation.details?.start_date ? parseDate(new Date(radiation.details?.start_date)) ?? '' : '',
      endDate: radiation.details?.end_date ? parseDate(new Date(radiation.details?.end_date)) ?? '' : '',
      radiation: radiation.details?.radiation,
      recordType: radiation.details?.record_type,
      institution: radiation.details?.institution,
      createdAt: radiation.created_at,
      updatedAt: radiation.updated_at
    };
  });
};

const transformOutRadiations = (
  treatment: TreatmentPlan,
  phrID: number,
  diagnosisID: number
): _TreatmentRadiationFlat[] => {
  let radiationMap: _TreatmentRadiationFlat[] = [];
  for (let rad of treatment.radiations) {
    radiationMap = [
      ...radiationMap,
      {
        body_area: rad.bodyArea,
        frequency: rad.frequency,
        start_date: rad.startDate,
        end_date: rad.endDate,
        patient_health_record_id: phrID,
        diagnosis_id: diagnosisID,
        treatment_id: treatment.id,
        institution: rad.institution,
        id: rad.id
      } as _TreatmentRadiationFlat
    ];
  }
  return radiationMap;
};

const transformInSurgery = (surgeries: _TreatmentSurgery[]): Surgery[] => {
  return surgeries.map((surgery: any) => ({
    id: surgery.id,
    surgeryDate: surgery.details?.surgery_date ? parseDate(new Date(surgery.details?.surgery_date)) ?? '' : '',
    surgicalProcedure: surgery.details?.surgical_procedure,
    remarks: surgery.details?.remarks,
    institution: surgery.details?.institution,
    surgicalProcedureSpecify: surgery.details?.surgical_procedure_specify,
    surgeryLocation: surgery.details?.surgery_location
  }));
};

const transformOutSurgery = (treatment: TreatmentPlan, phrID: number, diagnosisID: number): _TreatmentSurgeryFlat[] => {
  let surgeryMap: _TreatmentSurgeryFlat[] = [];
  for (let s of treatment.surgeries) {
    surgeryMap = [
      ...surgeryMap,
      {
        id: s.id,
        remarks: s.remarks,
        surgery_date: s.surgeryDate,
        surgical_procedure: s.surgicalProcedure,
        patient_health_record_id: phrID,
        diagnosis_id: diagnosisID,
        treatment_id: treatment.id,
        institution: s.institution,
        surgical_procedure_specify: s.surgicalProcedureSpecify,
        surgery_location: s.surgeryLocation
      } as _TreatmentSurgeryFlat
    ];
  }
  return surgeryMap;
};

const transformInTherapies = (therapies: _TreatmentTherapy[]): Therapy[] => {
  return therapies.map((therapy: any) => ({
    id: therapy.id,
    name: therapy.details?.name
  }));
};

const transformOutTherapies = (
  treatment: TreatmentPlan,
  phrID: number,
  diagnosisID: number
): _TreatmentTherapyFlat[] => {
  let therapiesMap: _TreatmentTherapyFlat[] = [];
  for (let therapy of treatment.therapies) {
    therapiesMap = [
      ...therapiesMap,
      {
        id: therapy.id,
        name: therapy.name,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatment.id
      } as _TreatmentTherapyFlat
    ];
  }
  return therapiesMap;
};

const transformInAgents = (agents: _TreatmentAgent[]): Agent[] => {
  return agents.map((agent: any) => ({
    id: agent.id,
    genericName: agent.details?.generic_name,
    brandName: agent.details?.brand_name,
    dosage: agent.details?.dosage,
    dosageGiven: agent.details?.dosage_given,
    cycles: agent.details?.cycles,
    startDate: agent.details?.start_date ? parseDate(new Date(agent.details?.start_date)) ?? '' : '',
    endDate: agent.details?.end_date ? parseDate(new Date(agent.details.end_date)) ?? '' : ''
  }));
};

const transformOutAgents = (treatment: TreatmentPlan, phrID: number, diagnosisID: number): _TreatmentAgentFlat[] => {
  let agentsMap: _TreatmentAgentFlat[] = [];
  for (let agent of treatment.agents) {
    agentsMap = [
      ...agentsMap,
      {
        id: agent.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatment.id,
        brand_name: agent.brandName,
        generic_name: agent.genericName,
        dosage: agent.dosage,
        dosage_given: agent.dosageGiven,
        start_date: agent.startDate,
        end_date: agent.endDate,
        cycles: agent.cycles
      } as _TreatmentAgentFlat
    ];
  }
  return agentsMap;
};

const transformInProcedures = (procedures: _TreatmentProcedure[]): Procedure[] => {
  return procedures.map((procedure: any) => ({
    id: procedure.id,
    name: procedure.details?.name,
    frequency: procedure.details?.frequency,
    startDate: procedure.details?.start_date ? parseDate(new Date(procedure.details?.start_date)) ?? '' : '',
    endDate: procedure.details?.end_date ? parseDate(new Date(procedure.details.end_date)) ?? '' : ''
  }));
};

const transformOutProcedures = (
  treatment: TreatmentPlan,
  phrID: number,
  diagnosisID: number
): _TreatmentProcedureFlat[] => {
  let proceduresMap: _TreatmentProcedureFlat[] = [];
  for (let p of treatment.procedures) {
    proceduresMap = [
      ...proceduresMap,
      {
        id: p.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatment.id,
        name: p.name,
        frequency: p.frequency,
        start_date: p.startDate,
        end_date: p.endDate
      } as _TreatmentProcedureFlat
    ];
  }
  return proceduresMap;
};

const transformInHistoryTrail = (historyTrail: any): HistoryTrail => {
  return {
    approvedByName: historyTrail?.approved_by_name || '',
    approvedByDate: historyTrail?.approved_by_date ? new Date(historyTrail?.approved_by_date) : new Date(),
    lastEditedByName: historyTrail?.last_edited_by_name || '',
    lastEditedByDate: historyTrail?.last_edited_by_date ? new Date(historyTrail?.last_edited_by_date) : new Date(),
    currentEditedByName: historyTrail?.current_edited_by_name || '',
    currentEditedByDate: historyTrail?.current_edited_by_date
      ? new Date(historyTrail?.current_edited_by_date)
      : new Date()
  };
};

export const transformOutRecord = {
  standardDiagnosis: (diag: StandardDiagnosis) => ({
    id: diag.id,
    code: diag.code || '',
    name: diag.name,
    additional: diag.additional || ''
  }),
  endoDiagnosis: (diag: EndoDiagnosis) => ({
    id: diag.id,
    code: diag.code || '',
    name: diag.name,
    additional: diag.additional || '',
    date_of_onset: diag.dateOfOnset
  }),
  oncoDiagnosis: (diag: OncoDiagnosis) => ({
    ...transformOutRecord.standardDiagnosis(diag),
    ...transformOutRecord.oncoDiagnosisAdditionalFields(
      diag.receptors,
      diag.segments,
      diag.tumorLocation,
      diag.stage,
      diag.dateOfDiagnosis,
      diag.remarks,
      diag.name
    ),
    diagram_data: diag.diagramData || ''
  }),
  oncoDiagnosisAdditionalFields: (
    receptors: Receptors,
    segments: Segments,
    tumorLocation: string,
    stage: string,
    dateOfDiagnosis: string,
    remarks: string,
    diagnosisName: string
  ): _DiagnosisAdditionalFields => {
    if (isBreastCancer(diagnosisName)) {
      return {
        tested: !receptors.notTested,
        receptors__estrogen_positive: receptors.notTested ? '' : receptors.estrogenPositive,
        receptors__progestorene_positive: receptors.notTested ? '' : receptors.progestorenePositive,
        receptors__her2_positive: receptors.notTested ? '' : receptors.her2Positive,
        receptors__ros1_positive: receptors.notTested ? '' : receptors.ros1Positive,
        receptors__pdl1_positive: receptors.notTested ? '' : receptors.pdl1Positive,
        receptors__ntrk: receptors.notTested ? '' : receptors.ntrk,
        receptors__her2neu_ihc: receptors.notTested ? '' : receptors.her2neuIhc,
        receptors__her2neu_fish: receptors.notTested ? '' : receptors.her2neuFish,
        receptors__others: receptors.notTested ? '' : receptors.others,
        tumor_location: tumorLocation,
        stage: stage,
        dateOfDiagnosis: dateOfDiagnosis,
        remarks: remarks
      };
    } else if (isLungCancer(diagnosisName)) {
      return {
        tested: !receptors.notTested,
        receptors__ntrk: receptors.notTested ? '' : receptors.ntrk,
        receptors__egfr: receptors.notTested ? '' : receptors.egfr,
        receptors__alk: receptors.notTested ? '' : receptors.alk,
        receptors__ros1: receptors.notTested ? '' : receptors.ros1,
        receptors__pdl1: receptors.notTested ? '' : receptors.pdl1,
        receptors__egfr_mutation: receptors.notTested ? '' : receptors.egfrMutation,
        receptors__alk_gene_fusion: receptors.notTested ? '' : receptors.alkGeneFusion,
        receptors__kras_mutation: receptors.notTested ? '' : receptors.krasMutation,
        receptors__others: receptors.notTested ? '' : receptors.others,
        tumor_location: tumorLocation,
        stage: stage,
        dateOfDiagnosis: dateOfDiagnosis,
        remarks: remarks
      };
    } else if (isColonCancer(diagnosisName) || isColorectalCancer(diagnosisName)) {
      return {
        tested: !receptors.notTested,
        receptors__kras_nras: receptors.notTested ? '' : receptors.krasNras,
        receptors__braf: receptors.notTested ? '' : receptors.braf,
        receptors__msi: receptors.notTested ? '' : receptors.msi,
        segment_descending_colon: segments.descendingColon,
        segment_ascending_colon: segments.ascendingColon,
        segment_transverse_colon: segments.transverseColon,
        segment_sigmoid_colon: segments.sigmoidColon,
        tumor_location: tumorLocation,
        stage: stage,
        dateOfDiagnosis: dateOfDiagnosis,
        remarks: remarks
      };
    } else {
      return {
        tumor_location: tumorLocation,
        stage: stage,
        dateOfDiagnosis: dateOfDiagnosis,
        remarks: remarks,
        tested: !receptors.notTested,
        receptors__estrogen_positive: receptors.notTested ? '' : receptors.estrogenPositive,
        receptors__progestorene_positive: receptors.notTested ? '' : receptors.progestorenePositive,
        receptors__her2_positive: receptors.notTested ? '' : receptors.her2Positive,
        receptors__ros1_positive: receptors.notTested ? '' : receptors.ros1Positive,
        receptors__pdl1_positive: receptors.notTested ? '' : receptors.pdl1Positive,
        receptors__ntrk: receptors.notTested ? '' : receptors.ntrk,
        receptors__her2neu_ihc: receptors.notTested ? '' : receptors.her2neuIhc,
        receptors__her2neu_fish: receptors.notTested ? '' : receptors.her2neuFish,
        receptors__others: receptors.notTested ? '' : receptors.others
      };
    }
  },
  pulmonologyDiagnosis: (data: PulmoDiagnosis) => {
    return {
      ...transformOutRecord.standardDiagnosis(data),
      ...transformOutRecord.pulmonologyDiagnosisAdditionalFields(data)
    };
  },
  pulmonologyDiagnosisAdditionalFields: (data: PulmoDiagnosis) => {
    if (isBronchialAsthma(data.name)) {
      return {
        spirometric_confirmation: data.spirometricConfirmation,
        empiric_treatment: data.empiricTreatment,
        spirometry: data.spirometry,
        date_taken: data.spirometryDate ? parseDate(data.spirometryDate, true) : '',
        reversibility: data.reversibility,
        peak_flow: data.peakFlow,
        pre__fev_fvc: data.preFevFec,
        pre__fev1: data.preFev1,
        pre__fevc: data.preFevc,
        post__fev_fvc: data.postFevFec,
        post__fev1: data.postFev1,
        post__fevc: data.postFevc
      };
    } else if (isTuberculosis(data.name)) {
      return {
        start_cat: data.startCat ? data.startCat : '',
        prophylaxis: data.prophylaxis ? data.prophylaxis : '',
        refer_to_pmdt: data.referToPmdt || false,
        refer_to_tbdc: data.referToTbdc || false
      };
    } else return {};
  },
  prescription: (prescription: Prescription) => {
    return {
      id: prescription.id,
      brand_name: prescription.brand || '',
      generic_name: prescription.generic || prescription.biologicsBiologic || '',
      preparation: prescription.preparation || '',
      quantity: prescription.quantity || 0,
      instruction: prescription.instruction || '',
      order: prescription.order,
      response_level: prescription.responseLevel,
      response_remarks: prescription.responseRemarks,
      biologics__biologic: prescription.biologicsBiologic,
      biologics__cancer: prescription.biologicsCancer,
      biologics__hepatitis: prescription.biologicsHepatitis,
      biologics__herpes_zoster: prescription.biologicsHerpesZoster,
      biologics__infusion_number: prescription.biologicsInfusionNumber,
      biologics__malignancy: prescription.biologicsMalignancy,
      biologics__notes: prescription.biologicsNotes,
      biologics__preparation: prescription.biologicsPreparation,
      biologics__tuberculosis: prescription.biologicsTuberculosis
    };
  },
  subjective: (encounter: Pick<Encounter, 'subjective' | 'id'>) => {
    return {
      patient_health_record_id: encounter.id,
      chief_complaint: encounter.subjective.chiefComplaint,
      chief_complaint__cough: encounter.subjective.cough,
      chief_complaint__wheezing: encounter.subjective.wheezing,
      chief_complaint__shortness_of_breath: encounter.subjective.shortnessOfBreath,
      chief_complaint__sputum_production: encounter.subjective.sputumProduction,
      chief_complaint__chest_tightness: encounter.subjective.chestTightness,
      chief_complaint__duration: encounter.subjective.duration,
      chief_complaint__unintentional_weight_loss: encounter.subjective.unintentionalWeightLoss,
      chief_complaint__chest_back_pain: encounter.subjective.chestBackPain,
      chief_complaint__malaise: encounter.subjective.malaise,
      chief_complaint__fever: encounter.subjective.fever,
      chief_complaint__fever_temperature: encounter.subjective.feverTemperature,
      chief_complaint__night_sweats: encounter.subjective.nightSweats
    };
  },
  vitals: (vitals: Vitals | EndoVitals | BCIVitals | OncoVitals | PediaVitals): _Vitals => {
    return {
      bmi: vitals.bmi,
      bp_diastolic: vitals.bpDiastolic,
      bp_systolic: vitals.bpSystolic,
      heart_rate: vitals.heartRate,
      respiratory_rate: vitals.respiratoryRate,
      temperature: vitals.temperature,
      height_unit: vitals.heightUnit,
      height_value: vitals.heightValue,
      weight_unit: vitals.weightUnit,
      weight_value: vitals.weightValue,
      hba1c: vitals.hba1c,
      waist_circumference_value: vitals.waistCircumference,
      waist_circumference_unit: vitals.waistCircumferenceUnit,
      rgt: vitals.rgt,
      oggt: vitals.oggt,
      fgt: vitals.fgt,
      rgt_unit: vitals.rgtUnit,
      oggt_unit: vitals.oggtUnit,
      fgt_unit: vitals.fgtUnit,
      blood_sugar_unit: (vitals as EndoVitals).bloodSugarUnit || '',
      blood_sugar_value: (vitals as EndoVitals).bloodSugarValue || 0,
      oxy_saturation_unit: vitals.oxySaturationUnit,
      oxy_saturation_value: vitals.oxySaturationValue,
      ecog: (vitals as BCIVitals).ecog || '',
      bsam2: (vitals as BCIVitals).bsam2 || '',
      pain: (vitals as OncoVitals).pain || 0,
      gsc: (vitals as OncoVitals).gsc || 0,
      head_circumference_value: (vitals as PediaVitals).headCircumferenceValue,
      head_circumference_unit: (vitals as PediaVitals).headCircumferenceUnit,
      neck_circumference_value: (vitals as PulmoVitals).neckCircumferenceValue,
      neck_circumference_unit: (vitals as PulmoVitals).neckCircumferenceUnit
    };
  },
  reviewOfSystem: (form: ReviewOfSystem): _ReviewOfSystemFlat => {
    return {
      id: form.id,
      patient_health_record_id: form.encounterID,
      skin_others: form.skin,
      heent_others: form.heent,
      respiratory_others: form.respiratory,
      cardiovascular_others: form.cardiovascular,
      gastrointestinal_others: form.gastrointestinal,
      genitourinary_others: form.genitourinary,
      musculoskeletal_others: form.musculoskeletal,
      neurological_others: form.neurological,
      metabolic_endocrine_others: form.metabolicEndocrine
    };
  },
  commonPhysicalExam: (form: CommonPhysicalExam): _CommonPhysicalExam => {
    return {
      id: form.id,
      patient_health_record_id: form.encounterID,
      general_comments: form.general,
      skin_comments: form.skin,
      head_comments: form.head,
      eyes_comments: form.eyes,
      ears_comments: form.ears,
      nose_comments: form.nose,
      mouth_and_throat_comments: form.mouthAndThroat,
      neck_comments: form.neck,
      breast_comments: form.breast,
      pelvic_comments: form.pelvic,
      chest_and_lungs_comments: form.chestAndLungs,
      heart_comments: form.heart,
      abdomen_comments: form.abdomen,
      back_and_spine_comments: form.backAndSpine,
      extremities_comments: form.extremities,
      neurologic_exams_comments: form.neurologicExams,
      all_comments__normal: form.allNormal
    };
  },
  bciPhysicalExam: (form: BCIPhysicalExam): _BCIPhysicalExam => {
    return {
      id: form.id,
      patient_health_record_id: form.encounterID,
      general__normal: form.general.generalNormal,
      general__consciousness: form.general.generalConsciousness,
      general__body_build: form.general.generalBodyBuild,
      general__ambulation: form.general.generalAmbulation,
      general__cardiorespiratory: form.general.generalCardiorespiratory,
      skin__normal: form.skin.skinNormal,
      skin__color: form.skin.skinColor,
      skin__pigmentation: form.skin.skinPigmentation,
      skin__lesions: form.skin.skinLesions,
      skin__pigmentation_areas: form.skin.skinPigmentationAreas,
      skin__lesions_describe: form.skin.skinLesionsDescribe,
      heent__normal: form.heent.heentNormal,
      heent__face: form.heent.heentFace,
      heent__left_eye: form.heent.heentLeftEye,
      heent__right_eye: form.heent.heentRightEye,
      heent__left_ear: form.heent.heentLeftEar,
      heent__right_ear: form.heent.heentRightEar,
      heent__nose__septum: form.heent.heentNoseSeptum,
      heent__nose__turbiinates: form.heent.heentNoseTurbiinates,
      heent__nose__mass: form.heent.heentNoseMass,
      heent__nose__discharge: form.heent.heentNoseDischarge,
      heent__throat__mucosa: form.heent.heentThroatMucosa,
      heent__throat__ulsers: form.heent.heentThroatUlsers,
      heent__throat__tonsils: form.heent.heentThroatTonsils,
      heent__throat__uvula: form.heent.heentThroatUvula,
      heent__throat__tongue: form.heent.heentThroatTongue,
      heent__throat__pharynx: form.heent.heentThroatPharynx,
      heent__face_location_size: form.heent.heentFaceLocationSize,
      heent__left_eye_remarks: form.heent.heentLeftEyeRemarks,
      heent__right_eye_remarks: form.heent.heentRightEyeRemarks,
      heent__left_ear_remarks: form.heent.heentLeftEarRemarks,
      heent__right_ear_remarks: form.heent.heentRightEarRemarks,
      heent__nose__discharge_specify: form.heent.heentNoseDischargeSpecify,
      heent__throat__ulsers_describe: form.heent.heentThroatUlsersDescribe,
      heent__throat__tongue_remarks: form.heent.heentThroatTongueRemarks,
      neck__normal: form.neck.neckNormal,
      neck__pulsations: form.neck.neckPulsations,
      neck__masses: form.neck.neckMasses,
      neck__lymph_nodes: form.neck.neckLymphNodes,
      neck__pulsations_specify: form.neck.neckPulsationsSpecify,
      neck__masses_specify: form.neck.neckMassesSpecify,
      neck__lymph_num_loc_char_size: form.neck.neckLymphNumLocCharSize,
      breast__normal: form.breast.breastNormal,
      breast__right__nipple_excoriation: form.breast.breastRightNippleExcoriation,
      breast__right__nipple_discharge: form.breast.breastRightNippleDischarge,
      breast__right__nipple_retraction: form.breast.breastRightNippleRetraction,
      breast__right__skin: form.breast.breastRightSkin,
      breast__right__mass: form.breast.breastRightMass,
      breast__right__axilla_mass: form.breast.breastRightAxillaMass,
      breast__left__nipple_excoriation: form.breast.breastLeftNippleExcoriation,
      breast__left__nipple_discharge: form.breast.breastLeftNippleDischarge,
      breast__left__nipple_retraction: form.breast.breastLeftNippleRetraction,
      breast__left__skin: form.breast.breastLeftSkin,
      breast__left__mass: form.breast.breastLeftMass,
      breast__left__axilla_mass: form.breast.breastLeftAxillaMass,
      breast__left__nipple_discharge_remarks: form.breast.breastLeftNippleDischargeRemarks,
      breast__left__nipple_retraction_remarks: form.breast.breastLeftNippleRetractionRemarks,
      breast__left__mass_remarks: form.breast.breastLeftMassRemarks,
      breast__left__axilla_mass_remarks: form.breast.breastLeftAxillaMassRemarks,
      breast__right__nipple_discharge_remarks: form.breast.breastRightNippleDischargeRemarks,
      breast__right__nipple_retraction_remarks: form.breast.breastRightNippleRetractionRemarks,
      breast__right__mass_remarks: form.breast.breastRightMassRemarks,
      breast__right__axilla_mass_remarks: form.breast.breastRightAxillaMassRemarks,
      thorax_lungs__normal: form.thoraxLungs.thoraxLungsNormal,
      thorax_lungs__right__mass: form.thoraxLungs.thoraxLungsRightMass,
      thorax_lungs__right__tenderness: form.thoraxLungs.thoraxLungsRightTenderness,
      thorax_lungs__right__abnormality: form.thoraxLungs.thoraxLungsRightAbnormality,
      thorax_lungs__right__breath_sounds: form.thoraxLungs.thoraxLungsRightBreathSounds,
      thorax_lungs__right__adventitious_sounds: form.thoraxLungs.thoraxLungsRightAdventitiousSounds,
      thorax_lungs__left__mass: form.thoraxLungs.thoraxLungsLeftMass,
      thorax_lungs__left__tenderness: form.thoraxLungs.thoraxLungsLeftTenderness,
      thorax_lungs__left__abnormality: form.thoraxLungs.thoraxLungsLeftAbnormality,
      thorax_lungs__left__breath_sounds: form.thoraxLungs.thoraxLungsLeftBreathSounds,
      thorax_lungs__left__adventitious_sounds: form.thoraxLungs.thoraxLungsLeftAdventitiousSounds,
      thorax_lungs__left__mass_location: form.thoraxLungs.thoraxLungsLeftMassLocation,
      thorax_lungs__left__tenderness_location: form.thoraxLungs.thoraxLungsLeftTendernessLocation,
      thorax_lungs__left__abnormality_location: form.thoraxLungs.thoraxLungsLeftAbnormalityLocation,
      thorax_lungs__right__mass_location: form.thoraxLungs.thoraxLungsRightMassLocation,
      thorax_lungs__right__tenderness_location: form.thoraxLungs.thoraxLungsRightTendernessLocation,
      thorax_lungs__right__abnormality_location: form.thoraxLungs.thoraxLungsRightAbnormalityLocation,
      cardiovascular__normal: form.cardiovascular.cardiovascularNormal,
      cardiovascular__precordial_pulsations: form.cardiovascular.cardiovascularPrecordialPulsations,
      cardiovascular__apex_beat: form.cardiovascular.cardiovascularApexBeat,
      cardiovascular__heart_sounds: form.cardiovascular.cardiovascularHeartSounds,
      cardiovascular__murmurs: form.cardiovascular.cardiovascularMurmurs,
      cardiovascular__precordial_pulsations_location: form.cardiovascular.cardiovascularPrecordialPulsationsLocation,
      cardiovascular__apex_beat_location: form.cardiovascular.cardiovascularApexBeatLocation,
      cardiovascular__heart_sounds_specify: form.cardiovascular.cardiovascularHeartSoundsSpecify,
      cardiovascular__murmurs_characterize: form.cardiovascular.cardiovascularMurmursCharacterize,
      pulses__normal: form.pulses.pulsesNormal,
      pulses__right: form.pulses.pulsesRight,
      pulses__left: form.pulses.pulsesLeft,
      abdomen__normal: form.abdomen.abdomenNormal,
      abdomen__pulsations: form.abdomen.abdomenPulsations,
      abdomen__scars: form.abdomen.abdomenScars,
      abdomen__distension: form.abdomen.abdomenDistension,
      abdomen__abdominal_wall_mass: form.abdomen.abdomenAbdominalWallMass,
      abdomen__bowel_sounds: form.abdomen.abdomenBowelSounds,
      abdomen__tenderness: form.abdomen.abdomenTenderness,
      abdomen__hepatomegaly: form.abdomen.abdomenHepatomegaly,
      abdomen__splenomegaly: form.abdomen.abdomenSplenomegaly,
      abdomen__mass: form.abdomen.abdomenMass,
      abdomen__pulsations_location: form.abdomen.abdomenPulsationsLocation,
      abdomen__scars_location: form.abdomen.abdomenScarsLocation,
      abdomen__abdominal_wall_mass_location: form.abdomen.abdomenAbdominalWallMassLocation,
      abdomen__tenderness_location: form.abdomen.abdomenTendernessLocation,
      abdomen__hepatomegaly_liverspan_size_char: form.abdomen.abdomenHepatomegalyLiverspanSizeChar,
      abdomen__splenomegaly_size: form.abdomen.abdomenSplenomegalySize,
      abdomen__mass_characterize: form.abdomen.abdomenMassCharacterize,
      muskuloskeletal__normal: form.muskuloskeletal.muskuloskeletalNormal,
      muskuloskeletal__remarks: form.muskuloskeletal.muskuloskeletalRemarks,
      rectal__normal: form.rectal.rectalNormal,
      rectal__inspection__excoriation: form.rectal.rectalInspectionExcoriation,
      rectal__inspection__erythema: form.rectal.rectalInspectionErythema,
      rectal__inspection__anal_tag: form.rectal.rectalInspectionAnalTag,
      rectal__inspection__hemorrhoids: form.rectal.rectalInspectionHemorrhoids,
      rectal__inspection__mass: form.rectal.rectalInspectionMass,
      rectal__inspection__excoriation_site: form.rectal.rectalInspectionExcoriationSite,
      rectal__inspection__erythema_site: form.rectal.rectalInspectionErythemaSite,
      rectal__inspection__anal_tag_site: form.rectal.rectalInspectionAnalTagSite,
      rectal__inspection__hemorrhoids_site: form.rectal.rectalInspectionHemorrhoidsSite,
      rectal__inspection__mass_site: form.rectal.rectalInspectionMassSite,
      rectal__digital__mass: form.rectal.rectalDigitalMass,
      rectal__digital__prostate: form.rectal.rectalDigitalProstate,
      rectal__digital__tenderness: form.rectal.rectalDigitalTenderness,
      rectal__digital__color_of_stools: form.rectal.rectalDigitalColorOfStools,
      rectal__digital__mass_site: form.rectal.rectalDigitalMassSite,
      rectal__digital__prostate_characterize: form.rectal.rectalDigitalProstateCharacterize,
      rectal__digital__tenderness_site: form.rectal.rectalDigitalTendernessSite,
      rectal__digital__color_of_stools_specify: form.rectal.rectalDigitalColorOfStoolsSpecify,
      gynecologic__normal: form.gynecologic.gynecologicNormal,
      gynecologic__perineum__excoriation: form.gynecologic.gynecologicPerineumExcoriation,
      gynecologic__perineum__erythema: form.gynecologic.gynecologicPerineumErythema,
      gynecologic__perineum__mass: form.gynecologic.gynecologicPerineumMass,
      gynecologic__perineum__excoriation_site: form.gynecologic.gynecologicPerineumExcoriationSite,
      gynecologic__perineum__erythema_site: form.gynecologic.gynecologicPerineumErythemaSite,
      gynecologic__perineum__mass_site: form.gynecologic.gynecologicPerineumMassSite,
      gynecologic__speculum__cervix_discharge: form.gynecologic.gynecologicSpeculumCervixDischarge,
      gynecologic__speculum__cervix_excoriation: form.gynecologic.gynecologicSpeculumCervixExcoriation,
      gynecologic__speculum__cervix_mass: form.gynecologic.gynecologicSpeculumCervixMass,
      gynecologic__speculum__cervix_others: form.gynecologic.gynecologicSpeculumCervixOthers,
      gynecologic__speculum__vagina_excoriation: form.gynecologic.gynecologicSpeculumVaginaExcoriation,
      gynecologic__speculum__vagina_erythema: form.gynecologic.gynecologicSpeculumVaginaErythema,
      gynecologic__speculum__vagina_mass: form.gynecologic.gynecologicSpeculumVaginaMass,
      gynecologic__speculum__vagina_others: form.gynecologic.gynecologicSpeculumVaginaOthers,
      gynecologic__speculum__cervix_discharge_type: form.gynecologic.gynecologicSpeculumCervixDischargeType,
      gynecologic__speculum__cervix_excoriation_site: form.gynecologic.gynecologicSpeculumCervixExcoriationSite,
      gynecologic__speculum__cervix_mass_site: form.gynecologic.gynecologicSpeculumCervixMassSite,
      gynecologic__speculum__cervix_others_site: form.gynecologic.gynecologicSpeculumCervixOthersSite,
      gynecologic__speculum__vagina_excoriation_site: form.gynecologic.gynecologicSpeculumVaginaExcoriationSite,
      gynecologic__speculum__vagina_erythema_site: form.gynecologic.gynecologicSpeculumVaginaErythemaSite,
      gynecologic__speculum__vagina_mass_site: form.gynecologic.gynecologicSpeculumVaginaMassSite,
      gynecologic__speculum__vagina_others_site: form.gynecologic.gynecologicSpeculumVaginaOthersSite,
      gynecologic__internal_examination__cervix_tenderness:
        form.gynecologic.gynecologicInternalExaminationCervixTenderness,
      gynecologic__internal_examination__mass: form.gynecologic.gynecologicInternalExaminationMass,
      gynecologic__internal_examination__uterus_size: form.gynecologic.gynecologicInternalExaminationUterusSize,
      gynecologic__internal_examination__uterus_tenderness:
        form.gynecologic.gynecologicInternalExaminationUterusTenderness,
      gynecologic__internal_examination__adnexae_mass: form.gynecologic.gynecologicInternalExaminationAdnexaeMass,
      gynecologic__internal_examination__adnexae_tenderness:
        form.gynecologic.gynecologicInternalExaminationAdnexaeTenderness,
      gynecologic__internal_examination__cervix_tenderness_site:
        form.gynecologic.gynecologicInternalExaminationCervixTendernessSite,
      gynecologic__internal_examination__mass_site: form.gynecologic.gynecologicInternalExaminationMassSite,
      gynecologic__internal_examination__uterus_size_remarks:
        form.gynecologic.gynecologicInternalExaminationUterusSizeRemarks,
      gynecologic__internal_examination__uterus_tenderness_site:
        form.gynecologic.gynecologicInternalExaminationUterusTendernessSite,
      gynecologic__internal_examination__adnexae_mass_site:
        form.gynecologic.gynecologicInternalExaminationAdnexaeMassSite,
      gynecologic__internal_examination__adnexae_tenderness_site:
        form.gynecologic.gynecologicInternalExaminationAdnexaeTendernessSite,
      neurological__normal: form.neurological.neurologicalNormal,
      neurological__mental_status__consciousness: form.neurological.neurologicalMentalStatusConsciousness,
      neurological__mental_status__orientation: form.neurological.neurologicalMentalStatusOrientation,
      neurological__cranial_nerves: form.neurological.neurologicalCranialNerves,
      neurological__motor__upper_extremities_left: form.neurological.neurologicalMotorUpperExtremitiesLeft,
      neurological__motor__upper_extremities_right: form.neurological.neurologicalMotorUpperExtremitiesRight,
      neurological__motor__lower_extremities_left: form.neurological.neurologicalMotorLowerExtremitiesLeft,
      neurological__motor__lower_extremities_right: form.neurological.neurologicalMotorLowerExtremitiesRight,
      neurological__coordination_involuntary_movements: form.neurological.neurologicalCoordinationInvoluntaryMovements,
      neurological__sensory: form.neurological.neurologicalSensory,
      neurological__reflexes__superficial: form.neurological.neurologicalReflexesSuperficial,
      neurological__reflexes__deep_tendon: form.neurological.neurologicalReflexesDeepTendon,
      neurological__mental_status__orientation_specify: form.neurological.neurologicalMentalStatusOrientationSpecify,
      neurological__motor__upper_extremities_left_specify:
        form.neurological.neurologicalMotorUpperExtremitiesLeftSpecify,
      neurological__motor__upper_extremities_right_specify:
        form.neurological.neurologicalMotorUpperExtremitiesRightSpecify,
      neurological__motor__lower_extremities_left_specify:
        form.neurological.neurologicalMotorLowerExtremitiesLeftSpecify,
      neurological__motor__lower_extremities_right_specify:
        form.neurological.neurologicalMotorLowerExtremitiesRightSpecify,
      neurological__coordination_involuntary_movements_specify:
        form.neurological.neurologicalCoordinationInvoluntaryMovementsSpecify,
      neurological__sensory_specify: form.neurological.neurologicalSensorySpecify,
      neurological__reflexes__superficial_specify: form.neurological.neurologicalReflexesSuperficialSpecify,
      neurological__reflexes__deep_tendon_specify: form.neurological.neurologicalReflexesDeepTendonSpecify,
      general__remarks: form.general.generalRemarks,
      skin__remarks: form.skin.skinRemarks,
      heent__remarks: form.heent.heentRemarks,
      neck__remarks: form.neck.neckRemarks,
      breast__remarks: form.breast.breastRemarks,
      thorax_lungs__remarks: form.thoraxLungs.thoraxLungsRemarks,
      cardiovascular__remarks: form.cardiovascular.cardiovascularRemarks,
      pulse__remarks: form.pulses.pulseRemarks,
      abdomen__remarks: form.abdomen.abdomenRemarks,
      rectal__remarks: form.rectal.rectalRemarks,
      gynecologic__remarks: form.gynecologic.gynecologicRemarks,
      neurological__remarks: form.neurological.neurologicalRemarks
    };
  },
  pulmoFields: (encounter: Encounter) => {
    if (encounter.type !== EncounterType.Pulmonology) return {};
    const e = encounter as PulmonologyEncounter;
    return {
      id: e.typeSpecificId ? e.typeSpecificId : undefined,
      level_of_control: e.levelOfControl,
      check_inhaler_technique: e.citGoodTechnique,
      check_inhaler_technique_specify: e.citErrorsSpecify,
      rf: e.riskFactors?.withRiskFactors,
      rf__host_factors: e.riskFactors?.hostFactors,
      rf__tabacco_smoke: e.riskFactors?.tobaccoSmoke,
      rf__smoke_from_home_cooking: e.riskFactors?.smokeFromHomeCookingAndHeatingFuels,
      rf__others: e.riskFactors?.others,
      ssd__date_taken: e.spirometrySpirometricDiagnosis?.dateTaken
        ? parseDate(e.spirometrySpirometricDiagnosis?.dateTaken, true)
        : undefined,
      ssd__fev1_fvc: e.spirometrySpirometricDiagnosis?.fev1Fevc,
      ssd__pre_bd_fev1: e.spirometrySpirometricDiagnosis?.preBdFev1,
      ssd__post_bd_fev: e.spirometrySpirometricDiagnosis?.postBdFev,
      ssd__frequency: e.spirometrySpirometricDiagnosis?.frequency,
      ssd__daytime_symptoms: e.spirometrySpirometricDiagnosis?.daytimeSymptoms,
      ssd__reliever_needed: e.spirometrySpirometricDiagnosis?.relieverNeeded,
      ssd__night_waking_asthma: e.spirometrySpirometricDiagnosis?.anyNightWalking,
      ssd__activity_limitation_asthma: e.spirometrySpirometricDiagnosis?.anyActivityLimitation,
      t: e.triggers?.withTriggers,
      t__pollens: e.triggers?.pollens,
      t__cold_air: e.triggers?.coldAir,
      t__strong_odors: e.triggers?.strongOdors,
      t__smoke: e.triggers?.smoke,
      t__dust_mites: e.triggers?.dustMites,
      t__humid_air: e.triggers?.humidAir,
      t__occupation: e.triggers?.occupation,
      t__exercise: e.triggers?.exerciseOrPhysicalActivities,
      t__molds: e.triggers?.molds,
      t__emotions: e.triggers?.emotions,
      t__animal_dander: e.triggers?.animalDander,
      t__medications: e.triggers?.medications,
      t__food: e.triggers?.food,
      ss: e.smokingStatus?.smoker,
      ss__smokes_per_day: e.smokingStatus?.smokesPerDay,
      ss__willing_to_quit: e.smokingStatus?.willingToQuit,
      ss__environmental_tabacco_exposure: e.smokingStatus?.environmentalTobaccoExposure,
      pi: e.psychologicalIssues?.withPsychologicalIssues,
      pi__worried: e.psychologicalIssues?.worried,
      pi__limitation: e.psychologicalIssues?.limitation,
      pi__workplace_issue: e.psychologicalIssues?.workplaceIssue,
      aos__cat_coughing: e.assessmentOfSymptoms?.coughing,
      aos__cat_phelgm: e.assessmentOfSymptoms?.phlegm,
      aos__cat_chest_feel: e.assessmentOfSymptoms?.chestTightness,
      aos__cat_walking_up_hill: e.assessmentOfSymptoms?.breathless,
      aos__cat_home_activities: e.assessmentOfSymptoms?.activitiesAtHome,
      aos__cat_leaving_home: e.assessmentOfSymptoms?.lungConditionConfidence,
      aos__cat_sleep: e.assessmentOfSymptoms?.soundlySleep,
      aos__cat_energy_level: e.assessmentOfSymptoms?.energyLevels,
      aos__mmrc_dyspnea_scale: e.assessmentOfSymptoms?.mMRCScore,
      aos__tonsillar_grade: e.assessmentOfSymptoms?.tonsillarGrade,
      aos__mallampati_score: e.assessmentOfSymptoms?.mallampatiScore,
      aos__ess_in_car_stopped: e.assessmentOfSymptoms?.essInCarStopped,
      aos__ess_sitting_quietly: e.assessmentOfSymptoms?.essSittingQuietly,
      aos__ess_sitting_inactive: e.assessmentOfSymptoms?.essSittingInactive,
      aos__ess_lying_down_to_rest: e.assessmentOfSymptoms?.essLyingDownToRest,
      aos__ess_passenger_in_a_car: e.assessmentOfSymptoms?.essPassengerInACar,
      aos__ess_sitting_and_resting: e.assessmentOfSymptoms?.essSittingAndResting,
      aos__ess_sitting_and_talking: e.assessmentOfSymptoms?.essSittingAndTalking,
      aos__ess_sitting_and_watching_tv: e.assessmentOfSymptoms?.essSittingAndWatchingTv,
      nafd__wheezes: e.notesAndFindingsAdditions?.wheezes,
      nafd__rales: e.notesAndFindingsAdditions?.rales,
      nafd__localize: e.notesAndFindingsAdditions?.localize,
      m__medications_compliant: e.medications?.medicationsCompliant,
      m__saba_dose_value: e.medications?.saba,
      m__saba_dose_date_started: e.medications?.sabaDate ? parseDate(e.medications.sabaDate, true) : undefined,
      m__laba_dose_value: e.medications?.laba,
      m__laba_dose_date_started: e.medications?.labaDate ? parseDate(e.medications.labaDate, true) : undefined,
      m__lama_dose_value: e.medications?.lama,
      m__lama_dose_date_started: e.medications?.lamaDate ? parseDate(e.medications.lamaDate, true) : undefined,
      m__lama_laba_dose_value: e.medications?.lamaLaba,
      m__lama_laba_dose_date_started: e.medications?.lamaLabaDate
        ? parseDate(e.medications.lamaLabaDate, true)
        : undefined,
      m__lama_ics_value: e.medications?.lamaIcs,
      m__lama_ics_date_started: e.medications?.lamaIcsDate ? parseDate(e.medications.lamaIcsDate, true) : undefined,
      m__others_value: e.medications?.others,
      m__others_date_started: e.medications?.othersDate ? parseDate(e.medications.othersDate, true) : undefined,
      mi__bad_taste: e.medicationalIssues?.badTaste,
      mi__cough: e.medicationalIssues?.cough,
      mi__fast_heartbeat: e.medicationalIssues?.fastHeartbeat,
      mi__nervousness: e.medicationalIssues?.nervousness,
      mi__shakiness: e.medicationalIssues?.shakiness,
      mi__sore_throat: e.medicationalIssues?.soreThroat,
      mi__upset_stomach: e.medicationalIssues?.upsetStomach,
      mi__other: e.medicationalIssues?.other,
      smsape__asthma_action_plan: e.selfManagementSupport?.asthmaActionPlan,
      smsape__peak_flow: e.selfManagementSupport?.peakFlowAndSymptomDiary,
      smsape__trigger_avoidance: e.selfManagementSupport?.adviceOnTriggerAvoidance,
      smsape__inhaler_device_training: e.selfManagementSupport?.inhalerDeviceTraining,
      smsape__inhaler_device_training_done_by: e.selfManagementSupport?.inhalerDeviceTrainingDoneBy,
      smsape__asthma_education: e.selfManagementSupport?.asthmaEducation,
      smsape__asthma_education_done_by: e.selfManagementSupport?.asthmaEducationDoneBy,
      smsape__asthma_club: e.selfManagementSupport?.referredToAsthmaClub,
      smsape__asthma_club_date: e.selfManagementSupport?.referredToAsthmaClubDate
        ? parseDate(e.selfManagementSupport.referredToAsthmaClubDate)
        : undefined,
      smsape__copd_education: e.selfManagementSupport?.copdEducation,
      smsape__copd_education_done_by: e.selfManagementSupport?.copdEducationDoneBy,
      smsape__pulmonary_rehab: e.selfManagementSupport?.referredToPulmonaryRehab,
      smsape__pulmonary_rehab_date: e.selfManagementSupport?.referredToPulmonaryRehabDate
        ? parseDate(e.selfManagementSupport?.referredToPulmonaryRehabDate)
        : undefined,
      icf__unexplained_cough: e.intensifiedCaseFindings?.unexplainedCough,
      icf__close_contact_known_tb: e.intensifiedCaseFindings?.closeContactKnownTb,
      icf__hrg__hiv_aids: e.intensifiedCaseFindings?.hrgHivAids,
      icf__hrg__diabetes: e.intensifiedCaseFindings?.hrgDiabetes,
      icf__hrg__esrd: e.intensifiedCaseFindings?.hrgEsrd,
      icf__hrg__cancer: e.intensifiedCaseFindings?.hrgCancer,
      icf__hrg__connective_tissue_disorder: e.intensifiedCaseFindings?.hrgConnectiveTissueDisorder,
      icf__hrg__autoimmune_disease: e.intensifiedCaseFindings?.hrgAutoimmuneDisease,
      icf__hrg__silicosis: e.intensifiedCaseFindings?.hrgSilicosis,
      icf__hrg__patient_underwent_gastrectomy: e.intensifiedCaseFindings?.hrgPatientUnderwentGastrectomy,
      icf__hrg__patient_prolonged_steroids: e.intensifiedCaseFindings?.hrgPatientProlongedSteroids,
      icf__hrp__elderly: e.intensifiedCaseFindings?.hrpElderly,
      icf__hrp__urban_poor: e.intensifiedCaseFindings?.hrpUrbanPoor,
      icf__hrp__inmates: e.intensifiedCaseFindings?.hrpInmates,
      icf__hrp__other_settings: e.intensifiedCaseFindings?.hrpOtherSettings
        ? e.intensifiedCaseFindings.hrpOtherSettings
        : undefined,
      bs__afp_sputum: e.bacteriologicalStatus?.afpSputum || undefined,
      bs__afp_sputum_1st_date: e.bacteriologicalStatus?.afpSputum1stDate
        ? parseDate(e.bacteriologicalStatus.afpSputum1stDate)
        : undefined,
      bs__afp_sputum_2nd_date: e.bacteriologicalStatus?.afpSputum2ndDate
        ? parseDate(e.bacteriologicalStatus.afpSputum2ndDate)
        : undefined,
      bs__genexpert: e.bacteriologicalStatus?.genexpert || undefined,
      bs__genexpert_date: e.bacteriologicalStatus?.genexpertDate
        ? parseDate(e.bacteriologicalStatus.genexpertDate)
        : undefined,
      bs__tb_lamp: e.bacteriologicalStatus?.tbLamp || undefined,
      bs__tb_lamp_date: e.bacteriologicalStatus?.tbLampDate ? parseDate(e.bacteriologicalStatus.tbLampDate) : undefined,
      ppd: e.ppdSkinTest?.ppd ? e.ppdSkinTest.ppd : undefined,
      ppd_skin_reaction: e.ppdSkinTest?.ppdSkinReaction ? e.ppdSkinTest.ppdSkinReaction : undefined,
      ppd_date: e.ppdSkinTest?.ppdDate ? parseDate(e.ppdSkinTest.ppdDate) : undefined,
      ptb__upper_lobe_infiltrates: e.ptbDiagnosticXray?.upperLobeInfiltrates || undefined,
      ptb__hilar: e.ptbDiagnosticXray?.hilar || undefined,
      ptb__miliary_pattern: e.ptbDiagnosticXray?.miliaryPattern || undefined,
      ptb__cavitary_lesion: e.ptbDiagnosticXray?.cavitaryLesion || undefined,
      ptb__cavitary_lesion_remarks: e.ptbDiagnosticXray?.cavitaryLesionRemarks
        ? e.ptbDiagnosticXray.cavitaryLesionRemarks
        : undefined,
      ptb__nodule_poorly_defined: e.ptbDiagnosticXray?.nodulePoorlyDefined || undefined,
      ptb__nodule_poorly_defined_remarks: e.ptbDiagnosticXray?.nodulePoorlyDefinedRemarks
        ? e.ptbDiagnosticXray.nodulePoorlyDefinedRemarks
        : undefined,
      ptb__pleural_effusion: e.ptbDiagnosticXray?.pleuralEffusion || undefined,
      ptb__pleural_effusion_remarks: e.ptbDiagnosticXray?.pleuralEffusionRemarks
        ? e.ptbDiagnosticXray.pleuralEffusionRemarks
        : undefined,
      ptb__other_diagnostic_findings: e.ptbDiagnosticXray?.otherDiagnosticFindings
        ? e.ptbDiagnosticXray.otherDiagnosticFindings
        : undefined,
      genexpert_mtb_rif_result: e.genexpertMtbRif ? e.genexpertMtbRif : undefined,
      se__confusion: e.sleepEvaluation?.confusion,
      se__dry_mouth: e.sleepEvaluation?.dryMouth,
      se__irritability: e.sleepEvaluation?.irritability,
      se__legs_jerking: e.sleepEvaluation?.legsJerking,
      se__snoring_rate: e.sleepEvaluation?.snoringRate,
      se__forgetfulness: e.sleepEvaluation?.forgetfulness,
      se__hallucination: e.sleepEvaluation?.hallucination,
      se__how_breathing: e.sleepEvaluation?.howBreathing,
      se__sexual_problems: e.sleepEvaluation?.sexualProblems,
      se__morning_headaches: e.sleepEvaluation?.morningHeadaches,
      se__waking_up_choking: e.sleepEvaluation?.wakingUpChoking,
      se__abnormal_breathing: e.sleepEvaluation?.abnormalBreathing,
      se__anxiety_depression: e.sleepEvaluation?.anxietyDepression,
      se__excessively_sleepy: e.sleepEvaluation?.excessivelySleepy,
      se__unrefreshing_sleep: e.sleepEvaluation?.unrefreshingSleep,
      se__sleep_talking_walking: e.sleepEvaluation?.sleepTalkingWalking,
      se__excessive_loud_snoring: e.sleepEvaluation?.excessiveLoudSnoring,
      se__how_breathing_duration: e.sleepEvaluation?.howBreathingDuration,
      se__waking_up_during_night: e.sleepEvaluation?.wakingUpDuringNight,
      se__decreased_concentration: e.sleepEvaluation?.decreasedConcentration,
      se__sexual_problems_remarks: e.sleepEvaluation?.sexualProblemsRemarks,
      se__difficulty_falling_asleep: e.sleepEvaluation?.difficultyFallingAsleep,
      se__how_breathing_duration_unit: e.sleepEvaluation?.howBreathingDurationUnit,
      as__vivid_dreams: e.associatedSymptoms?.vividDreams,
      as__problem_length: e.associatedSymptoms?.problemLength,
      as__legs_feel_restless: e.associatedSymptoms?.legsFeelRestless,
      as__partner_complained: e.associatedSymptoms?.partnerComplained,
      as__awaken_kicking_legs: e.associatedSymptoms?.awakenKickingLegs,
      as__unable_to_move_body: e.associatedSymptoms?.unableToMoveBody,
      as__vivid_dreams_remarks: e.associatedSymptoms?.vividDreamsRemarks,
      as__sudden_muscle_weakness: e.associatedSymptoms?.suddenMuscleWeakness,
      as__legs_feel_restless_remarks: e.associatedSymptoms?.legsFeelRestlessRemarks,
      as__partner_complained_remarks: e.associatedSymptoms?.partnerComplainedRemarks,
      as__sleeping_problem_diagnosed: e.associatedSymptoms?.sleepingProblemDiagnosed,
      as__awaken_kicking_legs_remarks: e.associatedSymptoms?.awakenKickingLegsRemarks,
      as__unable_to_move_body_remarks: e.associatedSymptoms?.unableToMoveBodyRemarks,
      as__legs_restlessness_delay_sleep: e.associatedSymptoms?.legsRestlessnessDelaySleep,
      as__sudden_muscle_weakness_remarks: e.associatedSymptoms?.suddenMuscleWeaknessRemarks,
      as__sleeping_problem_diagnosed_remarks: e.associatedSymptoms?.sleepingProblemDiagnosedRemarks,
      as__legs_restlessness_delay_sleep_remarks: e.associatedSymptoms?.legsRestlessnessDelaySleepRemarks,
      ss__day_nap: e.sleepSchedule?.dayNap,
      ss__shift_work: e.sleepSchedule?.shiftWork,
      ss__day_nap_remarks: e.sleepSchedule?.dayNapRemarks,
      ss__day_nap_duration: e.sleepSchedule?.dayNapDuration,
      ss__shift_work_time_to: e.sleepSchedule?.shiftWorkTimeTo,
      ss__time_get_up_weekday: e.sleepSchedule?.timeGetUpWeekday
        ? parseDate(e.sleepSchedule.timeGetUpWeekday, true)
        : undefined,
      ss__time_get_up_weekend: e.sleepSchedule?.timeGetUpWeekend
        ? parseDate(e.sleepSchedule.timeGetUpWeekend, true)
        : undefined,
      ss__time_to_fall_asleep: e.sleepSchedule?.timeToFallAsleep,
      ss__shift_work_time_from: e.sleepSchedule?.shiftWorkTimeFrom,
      ss__time_wake_up_weekday: e.sleepSchedule?.timeWakeUpWeekday
        ? parseDate(e.sleepSchedule.timeWakeUpWeekday, true)
        : undefined,
      ss__time_wake_up_weekend: e.sleepSchedule?.timeWakeUpWeekend
        ? parseDate(e.sleepSchedule.timeWakeUpWeekend, true)
        : undefined,
      ss__time_lie_down_weekday: e.sleepSchedule?.timeLieDownWeekday
        ? parseDate(e.sleepSchedule.timeLieDownWeekday, true)
        : undefined,
      ss__time_lie_down_weekend: e.sleepSchedule?.timeLieDownWeekend
        ? parseDate(e.sleepSchedule.timeLieDownWeekend, true)
        : undefined,
      ss__time_fall_asleep_weekday: e.sleepSchedule?.timeFallAsleepWeekday
        ? parseDate(e.sleepSchedule.timeFallAsleepWeekday, true)
        : undefined,
      ss__time_fall_asleep_weekend: e.sleepSchedule?.timeFallAsleepWeekend
        ? parseDate(e.sleepSchedule.timeFallAsleepWeekend, true)
        : undefined,
      ss__medications_to_fall_asleep: e.sleepSchedule?.medicationsToFallAsleep,
      ss__medications_to_fall_asleep_remarks: e.sleepSchedule?.medicationsToFallAsleepRemarks,
      es__sleepy_in_daytime: e.evaluatingSleepiness?.sleepyInDaytime,
      es__refreshed_on_waking_up: e.evaluatingSleepiness?.refreshedOnWakingUp,
      es__sudden_muscle_weakness: e.evaluatingSleepiness?.suddenMuscleWeakness,
      es__sleepy_in_daytime_remarks: e.evaluatingSleepiness?.sleepyInDaytimeRemarks,
      es__involved_in_road_accidents: e.evaluatingSleepiness?.involvedInRoadAccidents,
      es__refreshed_on_waking_up_remarks: e.evaluatingSleepiness?.refreshedOnWakingUpRemarks,
      es__sudden_muscle_weakness_remarks: e.evaluatingSleepiness?.suddenMuscleWeaknessRemarks,
      es__fallen_asleep_at_red_light_driving: e.evaluatingSleepiness?.fallenAsleepAtRedLightDriving,
      es__involved_in_road_accidents_remarks: e.evaluatingSleepiness?.involvedInRoadAccidentsRemarks,
      es__fallen_asleep_at_red_light_driving_remarks: e.evaluatingSleepiness?.fallenAsleepAtRedLightDrivingRemarks,
      goiter: e.goiter
    };
  },
  endoFields: (encounter: EndocrinologyEncounter): _EndocrinologyEncounterFields => {
    return {
      patient_health_record_id: encounter.phrId,
      waist_circumference: encounter.waistCircumference || 0,
      waist_circumference_unit: encounter.waistCircumferenceUnit || 'cm'
    };
  },
  diabFields: (
    encounter: Pick<DiabEncounter, 'typeSpecificId' | 'phrId'> &
      Partial<
        Pick<
          DiabEncounter,
          | 'waistCircumference'
          | 'waistCircumferenceUnit'
          | 'diabetesEducatorsNotes'
          | 'followUpSchedule'
          | 'symptoms'
          | 'medicalNutritionalTherapy'
        >
      >
  ): Omit<_DiabEncounterFields, 'follow_up_schedule'> => {
    return {
      patient_health_record_id: encounter.phrId,
      waist_circumference: encounter.waistCircumference,
      waist_circumference_unit: encounter.waistCircumferenceUnit,
      diabetes_educators_notes: encounter.diabetesEducatorsNotes,
      symptoms: !encounter.symptoms
        ? undefined
        : {
            symptoms__non_healing_wounds: encounter.symptoms.non_healing_wounds,
            symptoms__others: encounter.symptoms.others,
            symptoms__polydipsia: encounter.symptoms.polydipsia,
            symptoms__polyphagia: encounter.symptoms.polyphagia,
            symptoms__polyuria: encounter.symptoms.polyuria,
            symptoms__remarks: encounter.symptoms.remarks,
            symptoms__tingling_sensation: encounter.symptoms.tingling_sensation,
            symptoms__weight_loss: encounter.symptoms.weight_loss
          },
      medical_nutrional_therapy: !encounter.medicalNutritionalTherapy
        ? undefined
        : {
            mnt__formal_consultation: encounter.medicalNutritionalTherapy.formalConsultation,
            mnt__no_formal_consultation: encounter.medicalNutritionalTherapy.noFormalConsultation,
            mnt__compliance: encounter.medicalNutritionalTherapy.compliance,
            mnt__current_regimen_tcr: encounter.medicalNutritionalTherapy.currentRegimenTcr,
            mnt__change_to_tcr: encounter.medicalNutritionalTherapy.changeToTcr,
            mnt__current_regimen_cho: encounter.medicalNutritionalTherapy.currentRegimenCho,
            mnt__change_to_cho: encounter.medicalNutritionalTherapy.changeToCho,
            mnt__current_regimen_chon: encounter.medicalNutritionalTherapy.currentRegimenChon,
            mnt__change_to_chon: encounter.medicalNutritionalTherapy.changeToChon,
            mnt__current_regimen_fats: encounter.medicalNutritionalTherapy.currentRegimenFats,
            mnt__change_to_fats: encounter.medicalNutritionalTherapy.changeToFats,
            mnt__meals: encounter.medicalNutritionalTherapy.meals,
            mnt__snacks: encounter.medicalNutritionalTherapy.snacks,
            mnt__remarks: encounter.medicalNutritionalTherapy.remarks
          }
    } as Omit<_DiabEncounterFields, 'follow_up_schedule'>;
  }
};

export const transformInBciRadTreatment = {
  transformMain: (data: _BciRadiationTreatment): BciRadiationTreatment => {
    return {
      id: data.id,
      phrTreatmentId: data.phr_treatment_id,
      therapyPrescriptionPrimarySite: data.details.therapy_prescription_primary_site,
      goal: data.details.goal,
      courseNumber: data.details.course_number,
      treatmentMachine: data.details.treatment_machine,
      specialInstructions: data.details.special_instructions,
      treatment1: data.details.treatment_1.map(item => transformInBciRadTreatment.transformTreatmentOne(item)),
      treatment2: data.details.treatment_2.map(item => transformInBciRadTreatment.transformTreatmentTwo(item)),
      doseConstraints: data.details.dose_constraints.map(item =>
        transformInBciRadTreatment.transformDoseConstraints(item)
      ),
      previousRadiationTherapy: data.details.previous_radiation_therapy.map(item =>
        transformInBciRadTreatment.transformPrevRadTherapy(item)
      )
    };
  },
  transformTreatmentOne: (data: string): Treatment1 => {
    const newData = JSON.parse(data) as _Treatment1;
    return {
      date: typeof newData.date === 'number' ? parseDate(new Date(newData.date * 1000)) ?? '' : '',
      treatmentVol: newData.treatment_vol,
      prescribedDose: newData.prescribed_dose,
      calcAt: newData.calc_at,
      doseFrac: newData.dose_frac,
      fracWk: newData.frac_wk,
      fracDay: newData.frac_day,
      beamEnergy: newData.beam_energy,
      technique: newData.technique,
      remarks: newData.remarks
    };
  },
  transformTreatmentTwo: (data: string): Treatment2 => {
    const newData = JSON.parse(data) as _Treatment2;
    return {
      date: typeof newData.date === 'number' ? parseDate(new Date(newData.date * 1000)) ?? '' : '',
      fieldNo: newData.field_no,
      mvMev: newData.mv_mev,
      collSetting: newData.coll_setting,
      ssd: newData.ssd,
      ceriroPb: newData.ceriro_pb,
      gantryAngle: newData.gantry_angle,
      collAngle: newData.coll_angle,
      tableAngle: newData.table_angle,
      wedge: newData.wedge,
      bolusComp: newData.bolus_comp,
      description: newData.bolus_comp
    };
  },
  transformDoseConstraints: (data: string): DoseConstraints => {
    const newData = JSON.parse(data) as _DosageConstraints;
    return {
      organsAtRisk: newData.organs_at_risk,
      constraints: newData.constraints
    };
  },
  transformPrevRadTherapy: (data: string): PreviousRadiationTherapy => {
    const newData = JSON.parse(data) as _PreviousRadiationTherapy;
    return {
      date: typeof newData.date === 'number' ? parseDate(new Date(newData.date * 1000)) ?? '' : '',
      volume: newData.volume,
      dose: newData.dose,
      coments: newData.coments,
      hospitalInstitution: newData.hospital_institution
    };
  }
};

export const transformOutBciRadTreatment = (
  form: BciRadiationTreatment,
  diagnosisID: number,
  encounterID: number,
  treatmentID: number
): _SaveBciRadiationTreatment => {
  return {
    id: form.id,
    phr_treatment_id: form.phrTreatmentId,
    diagnosis_id: diagnosisID,
    patient_health_record_id: encounterID,
    treatment_id: treatmentID,
    therapy_prescription_primary_site: form.therapyPrescriptionPrimarySite,
    goal: form.goal,
    course_number: form.courseNumber,
    treatment_machine: form.treatmentMachine,
    special_instructions: form.specialInstructions,
    treatment_1: form.treatment1.map(item =>
      JSON.stringify({
        date: item.date !== '' ? new Date(item.date).getTime() / 1000 : '',
        treatment_vol: item.treatmentVol,
        prescribed_dose: item.prescribedDose,
        calc_at: item.calcAt,
        dose_frac: item.doseFrac,
        frac_wk: item.fracWk,
        frac_day: item.fracDay,
        beam_energy: item.beamEnergy,
        technique: item.technique,
        remarks: item.remarks
      } as _Treatment1)
    ),
    treatment_2: form.treatment2.map(item =>
      JSON.stringify({
        date: item.date !== '' ? new Date(item.date).getTime() / 1000 : '',
        field_no: item.fieldNo,
        mv_mev: item.mvMev,
        coll_setting: item.collSetting,
        ssd: item.ssd,
        ceriro_pb: item.ceriroPb,
        gantry_angle: item.gantryAngle,
        coll_angle: item.collAngle,
        table_angle: item.tableAngle,
        wedge: item.wedge,
        bolus_comp: item.bolusComp,
        description: item.description
      })
    ),
    dose_constraints: form.doseConstraints.map(item =>
      JSON.stringify({
        organs_at_risk: item.organsAtRisk,
        constraints: item.constraints
      })
    ),
    previous_radiation_therapy: form.previousRadiationTherapy.map(item =>
      JSON.stringify({
        date: item.date !== '' ? new Date(item.date).getTime() / 1000 : '',
        volume: item.volume,
        dose: item.dose,
        coments: item.coments,
        hospital_institution: item.hospitalInstitution
      })
    )
  };
};
export const transformInTreatmentRecords = (treatmentRecords: _TreatmentRecords): TreatmentRecords => {
  return {
    surgeries: (treatmentRecords.surgeries || []).map(
      (s: _SurgeryRecord): SurgeryRecord => ({
        id: s.id,
        surgery: s.surgery,
        recordType: s.record_type,
        surgeryDate: s.surgery_date,
        institution: s.institution
      })
    ),
    radiations: (treatmentRecords.radiations || []).map(
      (r: _RadiationRecord): RadiationRecord => ({
        id: r.id,
        radiation: r.radiation,
        recordType: r.record_type,
        startDate: r.start_date,
        endDate: r.end_date,
        institution: r.institution
      })
    ),
    chemotherapies: (treatmentRecords.chemotherapies || []).map(
      (c: _ChemotherapyRecord): ChemotherapyRecord => ({
        id: c.id,
        chemotherapy: c.chemotherapy,
        cycles: c.cycles,
        dateFrom: c.date_from,
        dateTo: c.date_to,
        institution: c.institution
      })
    ),
    hormonalTherapies: (treatmentRecords.hormonal_therapies || []).map(
      (h: _HormonalTherapyRecord): HormonalTherapyRecord => ({
        id: h.id,
        hormonalTherapy: h.hormonal_therapy,
        duration: h.duration,
        dateFrom: h.date_from,
        dateTo: h.date_to,
        institution: h.institution
      })
    ),
    targetedTherapies: (treatmentRecords.targeted_therapies || []).map(
      (t: _TargetedTherapyRecord): TargetedTherapyRecord => ({
        id: t.id,
        targetedTherapy: t.targeted_therapy,
        recordType: t.record_type,
        dateFrom: t.date_from,
        dateTo: t.date_to
      })
    )
  };
};

export const transformOutTreatmentRecords = (form: TreatmentRecords): _TreatmentRecords => {
  return {
    surgeries: (form.surgeries || []).map(
      (s: SurgeryRecord): _SurgeryRecord => ({
        id: s.id,
        surgery: s.surgery,
        record_type: s.recordType,
        surgery_date: s.surgeryDate,
        institution: s.institution
      })
    ),
    radiations: (form.radiations || []).map(
      (r: RadiationRecord): _RadiationRecord => ({
        id: r.id,
        radiation: r.radiation,
        record_type: r.recordType,
        start_date: r.startDate,
        end_date: r.endDate,
        institution: r.institution
      })
    ),
    chemotherapies: (form.chemotherapies || []).map(
      (c: ChemotherapyRecord): _ChemotherapyRecord => ({
        id: c.id,
        chemotherapy: c.chemotherapy,
        cycles: c.cycles,
        date_from: c.dateFrom,
        date_to: c.dateTo,
        institution: c.institution
      })
    ),
    hormonal_therapies: (form.hormonalTherapies || []).map(
      (h: HormonalTherapyRecord): _HormonalTherapyRecord => ({
        id: h.id,
        hormonal_therapy: h.hormonalTherapy,
        duration: h.duration,
        date_from: h.dateFrom,
        date_to: h.dateTo,
        institution: h.institution
      })
    ),
    targeted_therapies: (form.targetedTherapies || []).map(
      (t: TargetedTherapyRecord): _TargetedTherapyRecord => ({
        id: t.id,
        targeted_therapy: t.targetedTherapy,
        record_type: t.recordType,
        date_from: t.dateFrom,
        date_to: t.dateTo
      })
    )
  };
};

export const transformOutPatientReferral = (form: PatientReferralData): _PatientReferralData => {
  return {
    email: form.email,
    record_id: typeof form.phrId === 'string' ? Number(form.phrId) : form.phrId,
    record_type: form.recordType,
    is_medcheck_doctor: form.isMedcheckDoctor,
    note: form.notes,
    active: form.active,
    checklist__profile: form.profile,
    checklist__profile_lifestyle: form.lifestyle,
    checklist__profile_disease_history: form.diseaseHistory,
    checklist__profile_medical_history: form.medicalHistory,
    checklist__profile_vaccination: form.vaccination,
    checklist__profile_treatment_records: form.treatmentRecords,
    checklist__profile_disease_and_treatment_status: form.diseaseAndTreatmentStatus,
    checklist__profile_all_sections: form.allPatientSections,
    checklist__phr_subjective: form.subjective,
    checklist__phr_asssessment: form.assessment,
    checklist__phr_objective: form.objective,
    checklist__phr_patient_care: form.patientCare,
    checklist__phr_full_record: form.fullRecord
  };
};
