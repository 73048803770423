import { Tab as MatTab, Tabs as MatTabs } from '@material-ui/core';
import { styled } from 'components/Theme';
import React, { useMemo, useState } from 'react';
import './Tab.scss';

interface Props {
  selected?: number;
  onSelect?: (idx: number) => void;
  pages: TabPage[];
  variant?: 'scrollable' | 'standard' | 'fullWidth';
}

export interface TabPage {
  pageTitle: string | React.ReactElement;
  pageComponent: React.ReactElement | React.ReactNode;
  hidden?: boolean;
  className?: string;
  disabled?: boolean;
}

const TabConstructor: React.FC<Props> = ({ selected: defaultSelected, onSelect, pages, variant }) => {
  const [selected, seSelected] = useState(defaultSelected || 0);

  const memoizedTabButtons = useMemo(
    () =>
      pages.map((page, index) => (
        <MatTab
          key={index}
          onClick={() => {
            onSelect && onSelect(index);
            seSelected(index);
          }}
          label={page.pageTitle}
          disabled={page.disabled}
        />
      )),
    [pages, onSelect]
  );

  return (
    <div className="tab-wrapper">
      <StyledMatTab value={selected} variant={variant}>
        {memoizedTabButtons}
      </StyledMatTab>
      {pages.map((page, index) => (
        <div
          key={index}
          className={`tab-wrapper__page ${page.className ? page.className : ''}`.trim()}
          hidden={index !== selected}
        >
          {page.pageComponent}
        </div>
      ))}
    </div>
  );
};

export const Tab = React.memo(TabConstructor);

const StyledMatTab = styled(MatTabs)`
  &.MuiTabs-root {
    height: inherit;
    width: inherit;
    .MuiTabs-scrollButtons {
      background-color: ${props => props.theme.color.secondary};
      &:not(.MuiButtonBase-root) {
        display: none;
      }
    }
    button {
      background: #f9f9f9;
      border: 1px solid #eaeaea;
      span.MuiTab-wrapper {
        color: #484848;
        display: block;
        span.spanCountWrapper {
          padding: 2px 4px 2px 4px;
          border-radius: 2px;
          background-color: #eaeaea;
          margin-left: 5px;
        }
      }
      &.Mui-selected {
        background-color: ${props => props.theme.color.secondary};
        border: none;
        span.MuiTab-wrapper {
          color: ${props => props.theme.color.primary};
          span.spanCountWrapper {
            background-color: #e76e70;
            color: #ffffff;
          }
        }
      }
    }
    span.MuiTabs-indicator {
      background-color: ${props => props.theme.color.primary};
    }
  }
`;
