import baseStyled, { ThemedStyledInterface } from 'styled-components';
import { getLocalStorageData } from 'common/services';
import { UserTheme } from 'models';

export interface Theme {
  color: ColorTheme;
  variables: VariableTheme;
}

export interface ColorTheme {
  primary: string;
  primaryLight: string;
  secondary: string;
  secondaryDark: string;
  error: string;
  grey: string;
  white: string;
  addIconColor: string;
}

export interface VariableTheme {
  bpSmall: string;
  bpMedium: string;
  bpLarge: string;
  bpXLarge: string;
  bpFull: string;
}

const defaultColors: ColorTheme = {
  primary: '#50b1af',
  primaryLight: '#419e9c',
  secondary: '#e7f2f2',
  secondaryDark: '#bae0e0',
  error: '#ff5a5f',
  grey: '#484848',
  white: '#fff',
  addIconColor: '#50b1af'
};

const commonVariables: VariableTheme = {
  bpSmall: '480px',
  bpMedium: '768px',
  bpLarge: '1024px',
  bpXLarge: '1280px',
  bpFull: '1600px'
};

export const defaultTheme = (): Theme => {
  return {
    color: defaultColors,
    variables: commonVariables
  };
};

export const bcmapTheme = (): Theme => {
  return {
    ...defaultTheme(),
    color: {
      ...defaultColors,
      primary: '#fc6980',
      primaryLight: '#ffbfc3',
      secondary: '#f7eced',
      secondaryDark: '#ffbfc3',
      addIconColor: '#484848'
    }
  };
};

export const tmcTheme = (): Theme => {
  return {
    ...defaultTheme(),
    color: {
      ...defaultColors,
      primary: '#004987',
      primaryLight: '#0A599B',
      secondary: '#E5ECF3',
      secondaryDark: '#D9E4ED',
      error: '#E33030',
      grey: '#484848',
      white: '#FFFFFF',
      addIconColor: '#054479'
    }
  };
};

export const styled = baseStyled as ThemedStyledInterface<Theme>;

export const theme = () => {
  switch (getLocalStorageData().userTheme) {
    case UserTheme.BCMAP:
      return bcmapTheme();
    case UserTheme.TMC:
      return tmcTheme();
    default:
      return defaultTheme();
  }
};
