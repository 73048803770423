export enum LocalStorage {
  accessToken = 'doctor_access_token',
  isAuthenticated = 'doctor_authenticated',
  assumeToken = 'doctor_assume_token',
  activeAccount = 'doctor_active_account',
  clinicId = 'doctor_clinic_id',
  theme = 'doctor_user_theme',
  notifyLater = 'doctor_reminder_notify_later',
  feedbackClosed = 'doctor_feedback_closed'
}
