import './Card.scss';
import React from 'react';
import { styled } from 'components/Theme';

interface Props {
  className?: string;
  footer?: () => JSX.Element;
}

export const Card: React.FC<Props> = props => {
  const { className = '', footer } = props;
  return (
    <CardWrapper className={`c-card ${className}`.trim()}>
      {props.children}
      {footer ? <div className="c-card__footer">{footer()}</div> : null}
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  &.c-card {
    padding: 24px;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    position: relative;
    .c-card__footer {
      border-top: 2px solid ${props => props.theme.color.primary};
    }
  }
`;
