import { styled } from 'components/Theme';
import React from 'react';
import { useDragAndDrop } from 'shared/repository/historical_file';

interface Props {
  onUpload: (files: File[]) => void;
  className?: string;
  label: string;
}

export const Dropzone: React.FC<Props> = ({ onUpload, className = '', label }) => {
  const { onDrop, onDragOver, onDragIn, onDragOut, dragging } = useDragAndDrop(onUpload);
  return (
    <DropzoneWrapper
      className={className}
      onDrop={onDrop}
      onDrag={onDragOver}
      onDragOver={onDragIn}
      onDragLeave={onDragOut}
      dragging={dragging}
    >
      {label}
    </DropzoneWrapper>
  );
};

const DropzoneWrapper = styled.div<{ dragging: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  text-align: center;
  color: #979797;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  margin-top: 8px;
  background-color: ${props => (props.dragging ? '#f9f9f9' : '#ffffff')};
`;
