import { Tooltip } from '@material-ui/core';
import Copy from 'assets/svg/copy.svg?component';
import Share from 'assets/svg/share.svg?component';
import { Button, Input, Loading, Toast } from 'components';
import { styled } from 'components/Theme';
import { useCurrentDoctor, useDomain } from 'hooks';
import React, { useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  ViberIcon,
  ViberShareButton
} from 'react-share';
import { useCopyToClipboard } from 'react-use';
import useFetchRoomCode from './useFetchRoomCode';

interface Props {
  newRoom: () => void;
  onEnter: (url: string) => void;
}

export const useEnterRoom = (room: string) => {
  const { i18n } = useTranslation();
  const { currentDoctor } = useCurrentDoctor();
  const { parseDomain } = useDomain();
  const lastName = currentDoctor.data?.lastName ?? '';
  let name = '';
  if (lastName) name = `&name=Dr. ${lastName}`;
  const roomLink = parseDomain(`/econsult?room=${encodeURI(room)}`);
  const link = `${roomLink}${name}&type=doctor`;

  return {
    link,
    roomLink
  };
};

export const EConsultModal: React.FC<Props> = ({ onEnter, newRoom }) => {
  const [room, setRoom] = React.useState('');
  const [t] = useTranslation();

  const enter = () => {
    onEnter(room);
    setRoom('');
  };

  return (
    <StyledModal>
      <Input placeholder={t('room_id')} name="room" value={room} onChange={e => setRoom(e.target.value)} fullWidth />
      <Button className="btn-enter" label={t('enter')} color="secondary" fullWidth onClick={enter} disabled={!room} />
      <Button className="btn-create-new" label={t('create_new')} color="primary" fullWidth onClick={newRoom} />
    </StyledModal>
  );
};

export const EConsultMessage: React.FC<{ onStart: (url: any) => void }> = ({ onStart }) => {
  const [state, copyToClipboard] = useCopyToClipboard();
  const [room, setRoom] = useState('');
  const { roomLink } = useEnterRoom(room);
  const { fetching, fetchRoomCode } = useFetchRoomCode(setRoom);
  const [t] = useTranslation();

  const copy = () => {
    copyToClipboard(roomLink);
    if (!state.error) {
      Toast.success(t('msg_success_copied', { ns: 'success_error_messages' }));
    }
  };

  const share = () => {
    const nav: any = navigator;
    nav
      .share({
        title: 'MedCheck: EConsult',
        url: roomLink
      })
      .then(() => console.log('Share was successful.'))
      .catch((error: any) => console.log('Sharing failed', error));
  };

  useEffect(() => {
    if (room) return;
    fetchRoomCode();
  }, [room, fetchRoomCode]);

  if (fetching) {
    return (
      <StyledMessage>
        <Loading loading />
      </StyledMessage>
    );
  }

  return (
    <StyledMessage>
      <div className="label">{t('cop_send_link_to_patient')}</div>
      <div className="label label-link">{roomLink}</div>
      <div className="social_links">
        {isMobile || isTablet ? (
          <button className="custom copy" type="button" onClick={share}>
            <Share />
          </button>
        ) : (
          <>
            <Tooltip title={t('share_to_facebook', { ns: 'doctor_profile' })}>
              <FacebookShareButton type="button" url={roomLink}>
                <FacebookIcon />
              </FacebookShareButton>
            </Tooltip>
            <Tooltip title={t('share_to_viber', { ns: 'doctor_profile' })}>
              <ViberShareButton type="button" url={roomLink}>
                <ViberIcon />
              </ViberShareButton>
            </Tooltip>
            <Tooltip title={t('share_to_messenger', { ns: 'doctor_profile' })}>
              <FacebookMessengerShareButton type="button" appId="945720955872345" url={roomLink}>
                <FacebookMessengerIcon />
              </FacebookMessengerShareButton>
            </Tooltip>
          </>
        )}
        <button className="custom" type="button" onClick={copy}>
          <Tooltip title={t('copy_link')}>
            <Copy />
          </Tooltip>
        </button>
      </div>
      <Button label={t('start_e_consult')} color="primary" onClick={() => onStart(room)} />
    </StyledMessage>
  );
};

const StyledModal = styled.div`
  .btn-create-new {
    margin-top: 16px;
  }
  .c-input {
    margin-top: 0;
  }
`;

const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .c-loader {
    margin-top: 48px;
  }
  div.label {
    width: 100%;
    text-align: center;
    margin-top: 16px;
    word-break: break-all;
    font-weight: bold;
    &.label-link {
      font-weight: normal;
      width: 90%;
      max-width: 500px;
    }
  }
  .social_links {
    margin-top: 32px;
    display: flex;
    align-items: center;
    button {
      padding: 0;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      overflow: hidden;
      border: none;
      margin-right: 16px;
      svg {
        width: 100%;
        height: 100%;
        margin: 0;
      }
      &.custom {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #484848;
        svg {
          width: 60%;
          height: 60%;
          path {
            fill: #fff !important;
            stroke: #fff !important;
          }
        }
        &.copy {
          svg {
            transform: translateX(-1px);
          }
        }
      }
      &:hover {
        &:not(:disabled):not(.disabled) {
          filter: brightness(90%);
        }
      }
    }
  }
  & > .c-button {
    margin: 32px 0;
  }
`;
