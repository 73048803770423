import { useAsyncFn } from 'react-use';
import { ECONSULT } from 'shared/constant/endpoint';
import { http } from 'common/services';
import { Toast } from 'components';

const useFetchRoomCode = (cb?: any) => {
  const [{ loading, value }, fetchRoomCodeAsync] = useAsyncFn(async () => {
    const endpoint = ECONSULT.ROOM.key;
    try {
      const { data } = await http.post(endpoint.name, endpoint.url, null);
      if (!data) {
        Toast.error('Unable to create room, please try again.');
        return null;
      }
      cb && cb(data.code);
      return data.code;
    } catch (err) {
      Toast.error('Unable to create room, please try again.');
      return null;
    }
  });
  return {
    fetching: loading,
    code: value,
    fetchRoomCode: fetchRoomCodeAsync
  };
};

export default useFetchRoomCode;
