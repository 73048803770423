import { LinearProgress, LinearProgressProps } from '@material-ui/core';
import React from 'react';
import { styled } from './Theme';

type Props = {
  value: number;
};

export const ProgressBar: React.FC<Props> = ({ value }) => {
  const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    return (
      <React.Fragment>
        <div className="label">YOUR PROGRESS</div>
        <div className="percentage">{Math.round(props.value)}% complete</div>
        <LinearProgress className="c-progress" variant="determinate" {...props} />
      </React.Fragment>
    );
  };

  return (
    <StyledProgressBar>
      <LinearProgressWithLabel value={value} />
    </StyledProgressBar>
  );
};

const StyledProgressBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 29px;

  .label {
    color: #989898;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 12px;
  }
  .percentage {
    color: #333;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    margin-left: auto;
  }
  .MuiLinearProgress-bar {
    background-color: #333 !important;
  }
  .c-progress {
    margin-top: 24px;
    flex-basis: 100%;
    height: 6px;
    border-radius: 6px;
    background-color: #e7e7e7;
  }
`;
