import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { StoreAction } from 'constants/store';
import { getNavigatorDashboard } from 'services/navigator.service';
import { _NavigatorDashboard } from 'models/endpoint';

const { GET_DASHBOARD } = StoreAction.NAVIGATOR;

function* getNavigatorDashboardSaga() {
  try {
    let { data: navigatorDashboard }: AxiosResponse<_NavigatorDashboard> = yield call(getNavigatorDashboard);
    yield put({ type: GET_DASHBOARD.SUCCESS, payload: navigatorDashboard });
  } catch (err) {
    yield put({ type: GET_DASHBOARD.FAILED, payload: err });
  }
}

export function* rootSaga() {
  yield takeLatest(GET_DASHBOARD.REQUEST, getNavigatorDashboardSaga);
}
