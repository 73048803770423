import { BRONCHIAL_ASTHMA_STRING } from 'constants/form';
import {
  Agent,
  BCIAbdomen,
  BCIBreast,
  BCICardiovascular,
  BCIGeneral,
  BCIGynecologic,
  BCIHeent,
  BCIMuskuloskeletal,
  BCINeck,
  BCINeurological,
  BCIPhysicalExam,
  BCIPulses,
  BCIRectal,
  BCISkin,
  BCIThoraxLungs,
  CommonPhysicalExam,
  DiabetesSymptoms,
  DiseaseActivity,
  EncounterVaccination,
  MedicalNutritionTherapy,
  OncoDiagnosis,
  PrescriptionEvaluation,
  Procedure,
  PulmoDiagnosis,
  Radiation,
  RAEvaluation,
  Receptors,
  ReviewOfSystem,
  RheumaDiagnosis,
  Segments,
  StandardDiagnosis,
  Surgery,
  Therapy,
  TreatmentPlan,
  AntiCancer
} from 'models';

export const RA_EVALUATION_DEFAULT: RAEvaluation = {
  age_of_diagnosis: 0,
  age_of_onset: 0,
  rheumatoid_arthritis_rf: '',
  rheumatoid_arthritis_acpa: '',
  rheumatoid_arthritis_duration: '',
  rheumatoid_arthritis_esr: '',
  rheumatoid_arthritis_crp: '',
  rheumatoid_arthritis_joints: 0,
  rheumatoid_arthritis_rf_score: 0,
  ra_lj_1: 0,
  ra_lj_2: 0,
  ra_lj_3: 0,
  ra_lj_4: 0,
  ra_lj_5: 0,
  ra_lj_6: 0,
  ra_lj_7: 0,
  ra_lj_8: 0,
  ra_lj_9: 0,
  ra_lj_10: 0,
  ra_lj_11: 0,
  ra_lj_12: 0,
  ra_lj_13: 0,
  ra_lj_14: 0,
  ra_lj_15: 0,
  ra_lj_16: 0,
  ra_lj_total: 0,
  ra_sj_1: 0,
  ra_sj_2: 0,
  ra_sj_3: 0,
  ra_sj_4: 0,
  ra_sj_5: 0,
  ra_sj_6: 0,
  ra_sj_7: 0,
  ra_sj_8: 0,
  ra_sj_9: 0,
  ra_sj_10: 0,
  ra_sj_11: 0,
  ra_sj_12: 0,
  ra_sj_13: 0,
  ra_sj_14: 0,
  ra_sj_15: 0,
  ra_sj_16: 0,
  ra_sj_17: 0,
  ra_sj_18: 0,
  ra_sj_19: 0,
  ra_sj_20: 0,
  ra_sj_21: 0,
  ra_sj_22: 0,
  ra_sj_23: 0,
  ra_sj_24: 0,
  ra_sj_25: 0,
  ra_sj_26: 0,
  ra_sj_27: 0,
  ra_sj_28: 0,
  ra_sj_29: 0,
  ra_sj_30: 0,
  ra_sj_31: 0,
  ra_sj_32: 0,
  ra_sj_33: 0,
  ra_sj_34: 0,
  ra_sj_35: 0,
  ra_sj_36: 0,
  ra_sj_37: 0,
  ra_sj_38: 0,
  ra_sj_39: 0,
  ra_sj_40: 0,
  ra_sj_41: 0,
  ra_sj_42: 0,
  ra_sj_43: 0,
  ra_sj_44: 0,
  ra_sj_45: 0,
  ra_sj_46: 0,
  ra_sj_47: 0,
  ra_sj_48: 0,
  ra_sj_49: 0,
  ra_sj_50: 0,
  ra_sj_51: 0,
  ra_sj_52: 0,
  ra_sj_53: 0,
  ra_sj_54: 0,
  ra_sj_55: 0,
  ra_sj_56: 0,
  ra_sj_57: 0,
  ra_sj_58: 0,
  ra_sj_59: 0,
  ra_sj_60: 0,
  ra_sj_total: 0
};

const DISEASE_ACTIVITY_DEFAULT: DiseaseActivity = {
  disease_activity_esr: 0,
  disease_activity_crp: 0,
  disease_activity_pga: 0,
  disease_activity_ega: 0,
  disease_activity_notes: '',
  swollen_lj_1: 0,
  swollen_lj_2: 0,
  swollen_lj_3: 0,
  swollen_lj_4: 0,
  swollen_lj_5: 0,
  swollen_lj_6: 0,
  swollen_lj_7: 0,
  swollen_lj_8: 0,
  swollen_lj_9: 0,
  swollen_lj_10: 0,
  swollen_lj_11: 0,
  swollen_lj_12: 0,
  swollen_lj_13: 0,
  swollen_lj_14: 0,
  swollen_lj_15: 0,
  swollen_lj_16: 0,
  swollen_lj_total: 0,
  swollen_sj_1: 0,
  swollen_sj_2: 0,
  swollen_sj_3: 0,
  swollen_sj_4: 0,
  swollen_sj_5: 0,
  swollen_sj_6: 0,
  swollen_sj_7: 0,
  swollen_sj_8: 0,
  swollen_sj_9: 0,
  swollen_sj_10: 0,
  swollen_sj_11: 0,
  swollen_sj_12: 0,
  swollen_sj_13: 0,
  swollen_sj_14: 0,
  swollen_sj_15: 0,
  swollen_sj_16: 0,
  swollen_sj_17: 0,
  swollen_sj_18: 0,
  swollen_sj_19: 0,
  swollen_sj_20: 0,
  swollen_sj_21: 0,
  swollen_sj_22: 0,
  swollen_sj_23: 0,
  swollen_sj_24: 0,
  swollen_sj_25: 0,
  swollen_sj_26: 0,
  swollen_sj_27: 0,
  swollen_sj_28: 0,
  swollen_sj_29: 0,
  swollen_sj_30: 0,
  swollen_sj_31: 0,
  swollen_sj_32: 0,
  swollen_sj_33: 0,
  swollen_sj_34: 0,
  swollen_sj_35: 0,
  swollen_sj_36: 0,
  swollen_sj_37: 0,
  swollen_sj_38: 0,
  swollen_sj_39: 0,
  swollen_sj_40: 0,
  swollen_sj_41: 0,
  swollen_sj_42: 0,
  swollen_sj_43: 0,
  swollen_sj_44: 0,
  swollen_sj_45: 0,
  swollen_sj_46: 0,
  swollen_sj_47: 0,
  swollen_sj_48: 0,
  swollen_sj_49: 0,
  swollen_sj_50: 0,
  swollen_sj_51: 0,
  swollen_sj_52: 0,
  swollen_sj_53: 0,
  swollen_sj_54: 0,
  swollen_sj_55: 0,
  swollen_sj_56: 0,
  swollen_sj_57: 0,
  swollen_sj_58: 0,
  swollen_sj_59: 0,
  swollen_sj_60: 0,
  swollen_sj_total: 0,
  tender_lj_1: 0,
  tender_lj_2: 0,
  tender_lj_3: 0,
  tender_lj_4: 0,
  tender_lj_5: 0,
  tender_lj_6: 0,
  tender_lj_7: 0,
  tender_lj_8: 0,
  tender_lj_9: 0,
  tender_lj_10: 0,
  tender_lj_11: 0,
  tender_lj_12: 0,
  tender_lj_13: 0,
  tender_lj_14: 0,
  tender_lj_15: 0,
  tender_lj_16: 0,
  tender_lj_total: 0,
  tender_sj_1: 0,
  tender_sj_2: 0,
  tender_sj_3: 0,
  tender_sj_4: 0,
  tender_sj_5: 0,
  tender_sj_6: 0,
  tender_sj_7: 0,
  tender_sj_8: 0,
  tender_sj_9: 0,
  tender_sj_10: 0,
  tender_sj_11: 0,
  tender_sj_12: 0,
  tender_sj_13: 0,
  tender_sj_14: 0,
  tender_sj_15: 0,
  tender_sj_16: 0,
  tender_sj_17: 0,
  tender_sj_18: 0,
  tender_sj_19: 0,
  tender_sj_20: 0,
  tender_sj_21: 0,
  tender_sj_22: 0,
  tender_sj_23: 0,
  tender_sj_24: 0,
  tender_sj_25: 0,
  tender_sj_26: 0,
  tender_sj_27: 0,
  tender_sj_28: 0,
  tender_sj_29: 0,
  tender_sj_30: 0,
  tender_sj_31: 0,
  tender_sj_32: 0,
  tender_sj_33: 0,
  tender_sj_34: 0,
  tender_sj_35: 0,
  tender_sj_36: 0,
  tender_sj_37: 0,
  tender_sj_38: 0,
  tender_sj_39: 0,
  tender_sj_40: 0,
  tender_sj_41: 0,
  tender_sj_42: 0,
  tender_sj_43: 0,
  tender_sj_44: 0,
  tender_sj_45: 0,
  tender_sj_46: 0,
  tender_sj_47: 0,
  tender_sj_48: 0,
  tender_sj_49: 0,
  tender_sj_50: 0,
  tender_sj_51: 0,
  tender_sj_52: 0,
  tender_sj_53: 0,
  tender_sj_54: 0,
  tender_sj_55: 0,
  tender_sj_56: 0,
  tender_sj_57: 0,
  tender_sj_58: 0,
  tender_sj_59: 0,
  tender_sj_60: 0,
  tender_sj_total: 0
};

export const reviewOfSystemDefault: ReviewOfSystem = {
  skin: '',
  heent: '',
  respiratory: '',
  cardiovascular: '',
  gastrointestinal: '',
  genitourinary: '',
  musculoskeletal: '',
  neurological: '',
  metabolicEndocrine: ''
};

export const diabetesSymptomsDefault: DiabetesSymptoms = {
  non_healing_wounds: false,
  others: false,
  polydipsia: false,
  polyphagia: false,
  polyuria: false,
  remarks: '',
  tingling_sensation: false,
  weight_loss: false
};

export const MedicalNutritionDefault: MedicalNutritionTherapy = {
  formalConsultation: false,
  noFormalConsultation: false,
  compliance: false,
  currentRegimenTcr: '',
  changeToTcr: '',
  currentRegimenCho: '',
  currentRegimenChon: '',
  changeToCho: '',
  changeToChon: '',
  currentRegimenFats: '',
  changeToFats: '',
  meals: '',
  snacks: '',
  remarks: ''
};

export const commonPhysicalExamDefault: CommonPhysicalExam = {
  general: '',
  skin: '',
  head: '',
  eyes: '',
  ears: '',
  nose: '',
  mouthAndThroat: '',
  neck: '',
  breast: '',
  pelvic: '',
  chestAndLungs: '',
  heart: '',
  abdomen: '',
  backAndSpine: '',
  extremities: '',
  neurologicExams: ''
};

export const BCIPhysicalExamDefault: BCIPhysicalExam = {
  general: {
    generalNormal: false,
    generalConsciousness: '',
    generalBodyBuild: '',
    generalAmbulation: '',
    generalCardiorespiratory: '',
    generalRemarks: ''
  },
  skin: {
    skinNormal: false,
    skinColor: '',
    skinPigmentation: '',
    skinLesions: '',
    skinPigmentationAreas: '',
    skinLesionsDescribe: '',
    skinRemarks: ''
  },
  heent: {
    heentNormal: false,
    heentFace: '',
    heentLeftEye: '',
    heentRightEye: '',
    heentLeftEar: '',
    heentRightEar: '',
    heentNoseSeptum: '',
    heentNoseTurbiinates: '',
    heentNoseMass: '',
    heentNoseDischarge: '',
    heentThroatMucosa: '',
    heentThroatUlsers: '',
    heentThroatTonsils: '',
    heentThroatUvula: '',
    heentThroatTongue: '',
    heentThroatPharynx: '',
    heentFaceLocationSize: '',
    heentLeftEyeRemarks: '',
    heentRightEyeRemarks: '',
    heentLeftEarRemarks: '',
    heentRightEarRemarks: '',
    heentNoseDischargeSpecify: '',
    heentThroatUlsersDescribe: '',
    heentThroatTongueRemarks: '',
    heentRemarks: ''
  },
  neck: {
    neckNormal: false,
    neckPulsations: '',
    neckMasses: '',
    neckLymphNodes: '',
    neckPulsationsSpecify: '',
    neckMassesSpecify: '',
    neckLymphNumLocCharSize: '',
    neckRemarks: ''
  },
  breast: {
    breastNormal: false,
    breastRightNippleExcoriation: '',
    breastRightNippleDischarge: '',
    breastRightNippleRetraction: '',
    breastRightSkin: '',
    breastRightMass: '',
    breastRightAxillaMass: '',
    breastLeftNippleExcoriation: '',
    breastLeftNippleDischarge: '',
    breastLeftNippleRetraction: '',
    breastLeftSkin: '',
    breastLeftMass: '',
    breastLeftAxillaMass: '',
    breastLeftNippleDischargeRemarks: '',
    breastLeftNippleRetractionRemarks: '',
    breastLeftMassRemarks: '',
    breastLeftAxillaMassRemarks: '',
    breastRightNippleDischargeRemarks: '',
    breastRightNippleRetractionRemarks: '',
    breastRightMassRemarks: '',
    breastRightAxillaMassRemarks: '',
    breastRemarks: ''
  },
  thoraxLungs: {
    thoraxLungsNormal: false,
    thoraxLungsRightMass: '',
    thoraxLungsRightTenderness: '',
    thoraxLungsRightAbnormality: '',
    thoraxLungsRightBreathSounds: '',
    thoraxLungsRightAdventitiousSounds: '',
    thoraxLungsLeftMass: '',
    thoraxLungsLeftTenderness: '',
    thoraxLungsLeftAbnormality: '',
    thoraxLungsLeftBreathSounds: '',
    thoraxLungsLeftAdventitiousSounds: '',
    thoraxLungsLeftMassLocation: '',
    thoraxLungsLeftTendernessLocation: '',
    thoraxLungsLeftAbnormalityLocation: '',
    thoraxLungsRightMassLocation: '',
    thoraxLungsRightTendernessLocation: '',
    thoraxLungsRightAbnormalityLocation: '',
    thoraxLungsRemarks: ''
  },
  cardiovascular: {
    cardiovascularNormal: false,
    cardiovascularPrecordialPulsations: '',
    cardiovascularApexBeat: '',
    cardiovascularHeartSounds: '',
    cardiovascularMurmurs: '',
    cardiovascularPrecordialPulsationsLocation: '',
    cardiovascularApexBeatLocation: '',
    cardiovascularHeartSoundsSpecify: '',
    cardiovascularMurmursCharacterize: '',
    cardiovascularRemarks: ''
  },
  pulses: {
    pulsesNormal: false,
    pulsesRight: '',
    pulsesLeft: '',
    pulseRemarks: ''
  },
  abdomen: {
    abdomenNormal: false,
    abdomenPulsations: '',
    abdomenScars: '',
    abdomenDistension: '',
    abdomenAbdominalWallMass: '',
    abdomenBowelSounds: '',
    abdomenTenderness: '',
    abdomenHepatomegaly: '',
    abdomenSplenomegaly: '',
    abdomenMass: '',
    abdomenPulsationsLocation: '',
    abdomenScarsLocation: '',
    abdomenAbdominalWallMassLocation: '',
    abdomenTendernessLocation: '',
    abdomenHepatomegalyLiverspanSizeChar: '',
    abdomenSplenomegalySize: '',
    abdomenMassCharacterize: '',
    abdomenRemarks: ''
  },
  muskuloskeletal: {
    muskuloskeletalNormal: false,
    muskuloskeletalRemarks: ''
  },
  rectal: {
    rectalNormal: false,
    rectalInspectionExcoriation: '',
    rectalInspectionErythema: '',
    rectalInspectionAnalTag: '',
    rectalInspectionHemorrhoids: '',
    rectalInspectionMass: '',
    rectalInspectionExcoriationSite: '',
    rectalInspectionErythemaSite: '',
    rectalInspectionAnalTagSite: '',
    rectalInspectionHemorrhoidsSite: '',
    rectalInspectionMassSite: '',
    rectalDigitalMass: '',
    rectalDigitalProstate: '',
    rectalDigitalTenderness: '',
    rectalDigitalColorOfStools: '',
    rectalDigitalMassSite: '',
    rectalDigitalProstateCharacterize: '',
    rectalDigitalTendernessSite: '',
    rectalDigitalColorOfStoolsSpecify: '',
    rectalRemarks: ''
  },
  gynecologic: {
    gynecologicNormal: false,
    gynecologicPerineumExcoriation: '',
    gynecologicPerineumErythema: '',
    gynecologicPerineumMass: '',
    gynecologicPerineumExcoriationSite: '',
    gynecologicPerineumErythemaSite: '',
    gynecologicPerineumMassSite: '',
    gynecologicSpeculumCervixDischarge: '',
    gynecologicSpeculumCervixExcoriation: '',
    gynecologicSpeculumCervixMass: '',
    gynecologicSpeculumCervixOthers: '',
    gynecologicSpeculumVaginaExcoriation: '',
    gynecologicSpeculumVaginaErythema: '',
    gynecologicSpeculumVaginaMass: '',
    gynecologicSpeculumVaginaOthers: '',
    gynecologicSpeculumCervixDischargeType: '',
    gynecologicSpeculumCervixExcoriationSite: '',
    gynecologicSpeculumCervixMassSite: '',
    gynecologicSpeculumCervixOthersSite: '',
    gynecologicSpeculumVaginaExcoriationSite: '',
    gynecologicSpeculumVaginaErythemaSite: '',
    gynecologicSpeculumVaginaMassSite: '',
    gynecologicSpeculumVaginaOthersSite: '',
    gynecologicInternalExaminationCervixTenderness: '',
    gynecologicInternalExaminationMass: '',
    gynecologicInternalExaminationUterusSize: '',
    gynecologicInternalExaminationUterusTenderness: '',
    gynecologicInternalExaminationAdnexaeMass: '',
    gynecologicInternalExaminationAdnexaeTenderness: '',
    gynecologicInternalExaminationCervixTendernessSite: '',
    gynecologicInternalExaminationMassSite: '',
    gynecologicInternalExaminationUterusSizeRemarks: '',
    gynecologicInternalExaminationUterusTendernessSite: '',
    gynecologicInternalExaminationAdnexaeMassSite: '',
    gynecologicInternalExaminationAdnexaeTendernessSite: '',
    gynecologicRemarks: ''
  },
  neurological: {
    neurologicalNormal: false,
    neurologicalMentalStatusConsciousness: '',
    neurologicalMentalStatusOrientation: '',
    neurologicalCranialNerves: '',
    neurologicalMotorUpperExtremitiesLeft: '',
    neurologicalMotorUpperExtremitiesRight: '',
    neurologicalMotorLowerExtremitiesLeft: '',
    neurologicalMotorLowerExtremitiesRight: '',
    neurologicalCoordinationInvoluntaryMovements: '',
    neurologicalSensory: '',
    neurologicalReflexesSuperficial: '',
    neurologicalReflexesDeepTendon: '',
    neurologicalMentalStatusOrientationSpecify: '',
    neurologicalMotorUpperExtremitiesLeftSpecify: '',
    neurologicalMotorUpperExtremitiesRightSpecify: '',
    neurologicalMotorLowerExtremitiesLeftSpecify: '',
    neurologicalMotorLowerExtremitiesRightSpecify: '',
    neurologicalCoordinationInvoluntaryMovementsSpecify: '',
    neurologicalSensorySpecify: '',
    neurologicalReflexesSuperficialSpecify: '',
    neurologicalReflexesDeepTendonSpecify: '',
    neurologicalRemarks: ''
  }
};

//Normal Values for BCIPhysicalExam
export const HEENT_NORMAL: BCIHeent = {
  heentNormal: true,
  heentFace: 'normal',
  heentLeftEye: 'normal',
  heentRightEye: 'normal',
  heentLeftEar: 'normal',
  heentRightEar: 'normal',
  heentNoseSeptum: 'midline',
  heentNoseTurbiinates: 'normal',
  heentNoseMass: 'No',
  heentNoseDischarge: 'No',
  heentThroatMucosa: 'pink',
  heentThroatUlsers: 'No',
  heentThroatTonsils: 'normal',
  heentThroatUvula: 'midline',
  heentThroatTongue: 'midline',
  heentThroatPharynx: 'normal',
  heentFaceLocationSize: '',
  heentLeftEyeRemarks: '',
  heentRightEyeRemarks: '',
  heentLeftEarRemarks: '',
  heentRightEarRemarks: '',
  heentNoseDischargeSpecify: '',
  heentThroatUlsersDescribe: '',
  heentThroatTongueRemarks: '',
  heentRemarks: ''
};

export const GENERAL_NORMAL: BCIGeneral = {
  generalNormal: true,
  generalConsciousness: 'awake',
  generalBodyBuild: '',
  generalAmbulation: 'ambulates_without_support',
  generalCardiorespiratory: 'Yes',
  generalRemarks: ''
};

export const SKIN_NORMAL: BCISkin = {
  skinNormal: true,
  skinColor: '',
  skinPigmentation: 'normal',
  skinLesions: 'none',
  skinPigmentationAreas: '',
  skinLesionsDescribe: '',
  skinRemarks: ''
};

export const NECK_NORMAL: BCINeck = {
  neckNormal: true,
  neckPulsations: 'No',
  neckMasses: 'No',
  neckLymphNodes: 'No',
  neckPulsationsSpecify: '',
  neckMassesSpecify: '',
  neckLymphNumLocCharSize: '',
  neckRemarks: ''
};

export const BREAST_NORMAL: BCIBreast = {
  breastNormal: true,
  breastRightNippleExcoriation: 'No',
  breastRightNippleDischarge: 'No',
  breastRightNippleRetraction: 'No',
  breastRightSkin: 'normal',
  breastRightMass: 'No',
  breastRightAxillaMass: 'No',
  breastLeftNippleExcoriation: 'No',
  breastLeftNippleDischarge: 'No',
  breastLeftNippleRetraction: 'No',
  breastLeftSkin: 'normal',
  breastLeftMass: 'No',
  breastLeftAxillaMass: 'No',
  breastLeftNippleDischargeRemarks: '',
  breastLeftNippleRetractionRemarks: '',
  breastLeftMassRemarks: '',
  breastLeftAxillaMassRemarks: '',
  breastRightNippleDischargeRemarks: '',
  breastRightNippleRetractionRemarks: '',
  breastRightMassRemarks: '',
  breastRightAxillaMassRemarks: '',
  breastRemarks: ''
};

export const THORAX_LUNGS_NORMAL: BCIThoraxLungs = {
  thoraxLungsNormal: true,
  thoraxLungsRightMass: 'No',
  thoraxLungsRightTenderness: 'No',
  thoraxLungsRightAbnormality: 'No',
  thoraxLungsRightBreathSounds: 'normal',
  thoraxLungsRightAdventitiousSounds: 'none',
  thoraxLungsLeftMass: 'No',
  thoraxLungsLeftTenderness: 'No',
  thoraxLungsLeftAbnormality: 'No',
  thoraxLungsLeftBreathSounds: 'normal',
  thoraxLungsLeftAdventitiousSounds: 'none',
  thoraxLungsLeftMassLocation: '',
  thoraxLungsLeftTendernessLocation: '',
  thoraxLungsLeftAbnormalityLocation: '',
  thoraxLungsRightMassLocation: '',
  thoraxLungsRightTendernessLocation: '',
  thoraxLungsRightAbnormalityLocation: '',
  thoraxLungsRemarks: ''
};

export const CARDIOVASCULAR_NORMAL: BCICardiovascular = {
  cardiovascularNormal: true,
  cardiovascularPrecordialPulsations: '',
  cardiovascularApexBeat: 'normal',
  cardiovascularHeartSounds: 'normal',
  cardiovascularMurmurs: 'No',
  cardiovascularPrecordialPulsationsLocation: '',
  cardiovascularApexBeatLocation: '',
  cardiovascularHeartSoundsSpecify: '',
  cardiovascularMurmursCharacterize: '',
  cardiovascularRemarks: ''
};

export const PULSES_NORMAL: BCIPulses = {
  pulsesNormal: true,
  pulsesRight: '',
  pulsesLeft: '',
  pulseRemarks: ''
};

export const ABDOMEN_NORMAL: BCIAbdomen = {
  abdomenNormal: true,
  abdomenPulsations: 'No',
  abdomenScars: 'No',
  abdomenDistension: 'No',
  abdomenAbdominalWallMass: 'No',
  abdomenBowelSounds: 'normoactive',
  abdomenTenderness: 'No',
  abdomenHepatomegaly: 'No',
  abdomenSplenomegaly: 'No',
  abdomenMass: 'No',
  abdomenPulsationsLocation: '',
  abdomenScarsLocation: '',
  abdomenAbdominalWallMassLocation: '',
  abdomenTendernessLocation: '',
  abdomenHepatomegalyLiverspanSizeChar: '',
  abdomenSplenomegalySize: '',
  abdomenMassCharacterize: '',
  abdomenRemarks: ''
};

export const MUSKULOSKELETAL_NORMAL: BCIMuskuloskeletal = {
  muskuloskeletalNormal: true,
  muskuloskeletalRemarks: ''
};

export const RECTAL_NORMAL: BCIRectal = {
  rectalNormal: true,
  rectalInspectionExcoriation: 'No',
  rectalInspectionErythema: 'No',
  rectalInspectionAnalTag: 'No',
  rectalInspectionHemorrhoids: 'No',
  rectalInspectionMass: 'No',
  rectalInspectionExcoriationSite: '',
  rectalInspectionErythemaSite: '',
  rectalInspectionAnalTagSite: '',
  rectalInspectionHemorrhoidsSite: '',
  rectalInspectionMassSite: '',
  rectalDigitalMass: 'No',
  rectalDigitalProstate: 'normal',
  rectalDigitalTenderness: 'No',
  rectalDigitalColorOfStools: 'normal',
  rectalDigitalMassSite: '',
  rectalDigitalProstateCharacterize: '',
  rectalDigitalTendernessSite: '',
  rectalDigitalColorOfStoolsSpecify: '',
  rectalRemarks: ''
};

export const GYNECOLOGIC_NORMAL: BCIGynecologic = {
  gynecologicNormal: true,
  gynecologicPerineumExcoriation: 'No',
  gynecologicPerineumErythema: 'No',
  gynecologicPerineumMass: 'No',
  gynecologicPerineumExcoriationSite: '',
  gynecologicPerineumErythemaSite: '',
  gynecologicPerineumMassSite: '',
  gynecologicSpeculumCervixDischarge: 'No',
  gynecologicSpeculumCervixExcoriation: 'No',
  gynecologicSpeculumCervixMass: 'No',
  gynecologicSpeculumCervixOthers: 'No',
  gynecologicSpeculumVaginaExcoriation: 'No',
  gynecologicSpeculumVaginaErythema: 'No',
  gynecologicSpeculumVaginaMass: 'No',
  gynecologicSpeculumVaginaOthers: 'No',
  gynecologicSpeculumCervixDischargeType: '',
  gynecologicSpeculumCervixExcoriationSite: '',
  gynecologicSpeculumCervixMassSite: '',
  gynecologicSpeculumCervixOthersSite: '',
  gynecologicSpeculumVaginaExcoriationSite: '',
  gynecologicSpeculumVaginaErythemaSite: '',
  gynecologicSpeculumVaginaMassSite: '',
  gynecologicSpeculumVaginaOthersSite: '',
  gynecologicInternalExaminationCervixTenderness: 'No',
  gynecologicInternalExaminationMass: 'No',
  gynecologicInternalExaminationUterusSize: 'normal',
  gynecologicInternalExaminationUterusTenderness: 'No',
  gynecologicInternalExaminationAdnexaeMass: 'No',
  gynecologicInternalExaminationAdnexaeTenderness: 'No',
  gynecologicInternalExaminationCervixTendernessSite: '',
  gynecologicInternalExaminationMassSite: '',
  gynecologicInternalExaminationUterusSizeRemarks: '',
  gynecologicInternalExaminationUterusTendernessSite: '',
  gynecologicInternalExaminationAdnexaeMassSite: '',
  gynecologicInternalExaminationAdnexaeTendernessSite: '',
  gynecologicRemarks: ''
};

export const NEUROLOGICAL_NORMAL: BCINeurological = {
  neurologicalNormal: true,
  neurologicalMentalStatusConsciousness: 'conscious',
  neurologicalMentalStatusOrientation: '',
  neurologicalCranialNerves: '',
  neurologicalMotorUpperExtremitiesLeft: 'normal',
  neurologicalMotorUpperExtremitiesRight: 'normal',
  neurologicalMotorLowerExtremitiesLeft: 'normal',
  neurologicalMotorLowerExtremitiesRight: 'normal',
  neurologicalCoordinationInvoluntaryMovements: 'absent',
  neurologicalSensory: 'present',
  neurologicalReflexesSuperficial: 'normal',
  neurologicalReflexesDeepTendon: 'normal',
  neurologicalMentalStatusOrientationSpecify: '',
  neurologicalMotorUpperExtremitiesLeftSpecify: '',
  neurologicalMotorUpperExtremitiesRightSpecify: '',
  neurologicalMotorLowerExtremitiesLeftSpecify: '',
  neurologicalMotorLowerExtremitiesRightSpecify: '',
  neurologicalCoordinationInvoluntaryMovementsSpecify: '',
  neurologicalSensorySpecify: '',
  neurologicalReflexesSuperficialSpecify: '',
  neurologicalReflexesDeepTendonSpecify: '',
  neurologicalRemarks: ''
};

export const RECEPTORS_DEFAULT: Receptors = {
  notTested: true,
  estrogenPositive: '',
  progestorenePositive: '',
  her2Positive: '',
  ros1Positive: '',
  pdl1Positive: '',
  egfrMutation: '',
  alkGeneFusion: '',
  krasMutation: '',
  her2neuIhc: '',
  her2neuFish: '',
  krasNras: '',
  braf: '',
  msi: '',
  egfr: '',
  alk: '',
  ros1: '',
  pdl1: '',
  ntrk: '',
  others: ''
};

export const SEGMENTS_DEFAULT: Segments = {
  sigmoidColon: false,
  ascendingColon: false,
  descendingColon: false,
  transverseColon: false
};

export const SURGERY_DEFAULT: Surgery = {
  id: 0,
  surgeryDate: '',
  surgicalProcedure: '',
  remarks: '',
  institution: '',
  surgicalProcedureSpecify: '',
  surgeryLocation: ''
};

export const THERAPY_DEFAULT: Therapy = {
  id: 0,
  name: ''
};

export const AGENT_DEFAULT: Agent = {
  id: 0,
  genericName: '',
  brandName: '',
  dosage: '',
  dosageGiven: '',
  cycles: '',
  startDate: '',
  endDate: ''
};

export const PROCEDURE_DEFAULT: Procedure = {
  id: 0,
  name: '',
  frequency: '',
  startDate: '',
  endDate: ''
};

export const RADIATION_DEFAULT: Radiation = {
  id: 0,
  bodyArea: '',
  frequency: '',
  startDate: '',
  endDate: '',
  radiation: '',
  recordType: '',
  institution: ''
};

export const ANTICANCER_DEFAULT: AntiCancer = {
  id: null,
  institution: '',
  doh_bcmap_enrolled: '',
  regimen: '',
  start_date: '',
  end_date: '',
  complete_chemotheraphy: '',
  hormonotheraphy: '',
  ecspmap_database: ''
};

export const TREATMENT_1_DEFAULT = {
  date: '',
  treatmentVol: '',
  prescribedDose: '',
  calcAt: '',
  doseFrac: '',
  fracWk: '',
  fracDay: '',
  beamEnergy: '',
  technique: '',
  remarks: ''
};

export const ADVERSE_EVENT_DEFAULT = {
  new_symptom: '',
  severity: '',
  treatment_given: '',
  start_date: null,
  end_date: null,
  resolved: null,
  comment: ''
};

export const TREATMENT_2_DEFAULT = {
  date: '',
  fieldNo: '',
  mvMev: '',
  collSetting: '',
  ssd: '',
  ceriroPb: '',
  gantryAngle: '',
  collAngle: '',
  tableAngle: '',
  wedge: '',
  bolusComp: '',
  description: ''
};

export const DOSE_CONSTRAINTS_DEFAULT = {
  organsAtRisk: '',
  constraints: ''
};

export const PREVIOUS_RADIATION_THERAPY_DEFAULT = {
  date: '',
  volume: '',
  dose: '',
  coments: '',
  hospitalInstitution: ''
};

export const RADIATION_TREATMENT_DEFAULT = {
  id: undefined,
  phrTreatmentId: undefined,
  therapyPrescriptionPrimarySite: '',
  goal: '',
  courseNumber: '',
  treatmentMachine: '',
  specialInstructions: '',
  treatment1: new Array(3).fill(TREATMENT_1_DEFAULT),
  treatment2: new Array(3).fill(TREATMENT_2_DEFAULT),
  doseConstraints: new Array(6).fill(DOSE_CONSTRAINTS_DEFAULT),
  previousRadiationTherapy: new Array(3).fill(PREVIOUS_RADIATION_THERAPY_DEFAULT)
};

export const TREATMENT_DEFAULT: TreatmentPlan = {
  id: -1,
  therapyType: '',
  radiations: [RADIATION_DEFAULT],
  radiationsBCI: [RADIATION_TREATMENT_DEFAULT],
  surgeries: [SURGERY_DEFAULT],
  therapies: [THERAPY_DEFAULT],
  agents: [AGENT_DEFAULT],
  procedures: [PROCEDURE_DEFAULT],
  remarks: '',
  approved: false,
  historyTrail: null,
  lineOfTreatment: '',
  antiCancer: {}
};

export const PRESCRIPTION_EVALUATION_DEFAULT: PrescriptionEvaluation = {
  complianceLevel: '',
  complianceRemarks: '',
  responseLevel: 0,
  responseRemarks: ''
};

const DEFAULT_FOR_STANDARD_DIAGNOSIS = {
  code: '',
  name: '',
  additional: ''
} as StandardDiagnosis;

const DEFAULT_FOR_ONCOLOGY_DIAGNOSIS = {
  ...DEFAULT_FOR_STANDARD_DIAGNOSIS,
  dateOfDiagnosis: '',
  stage: '',
  tumorLocation: '',
  remarks: '',
  segments: SEGMENTS_DEFAULT,
  receptors: RECEPTORS_DEFAULT,
  diagramData: '',
  treatment: TREATMENT_DEFAULT,
  stagingFormIds: []
};
export const STANDARD_DIAGNOSIS_DEFAULT: StandardDiagnosis = DEFAULT_FOR_STANDARD_DIAGNOSIS;
export const BCI_DIAGNOSIS_DEFAULT: OncoDiagnosis = DEFAULT_FOR_ONCOLOGY_DIAGNOSIS;
export const ONCO_DIAGNOSIS_DEFAULT: OncoDiagnosis = DEFAULT_FOR_ONCOLOGY_DIAGNOSIS;
export const RHEUMA_DIAGNOSIS_DEFAULT: RheumaDiagnosis = {
  ...DEFAULT_FOR_STANDARD_DIAGNOSIS,
  raEvaluation: RA_EVALUATION_DEFAULT,
  diseaseActivity: DISEASE_ACTIVITY_DEFAULT
};
export const ACTNOW_DIAGNOSIS_DEFAULT: StandardDiagnosis = DEFAULT_FOR_STANDARD_DIAGNOSIS;
export const OPHTHALMOLOGY_DIAGNOSIS_DEFAULT: StandardDiagnosis = DEFAULT_FOR_STANDARD_DIAGNOSIS;
export const OB_DIAGNOSIS_DEFAULT: StandardDiagnosis = DEFAULT_FOR_STANDARD_DIAGNOSIS;
export const GYNE_DIAGNOSIS_DEFAULT: StandardDiagnosis = DEFAULT_FOR_STANDARD_DIAGNOSIS;
export const ENT_DIAGNOSIS_DEFAULT: StandardDiagnosis = DEFAULT_FOR_STANDARD_DIAGNOSIS;
export const PULMO_DIAGNOSIS_DEFAULT: PulmoDiagnosis = {
  ...DEFAULT_FOR_STANDARD_DIAGNOSIS,
  spirometricConfirmation: '',
  empiricTreatment: '',
  spirometry: '',
  spirometryDate: null,
  preFevFec: '',
  preFev1: '',
  preFevc: '',
  postFevFec: '',
  postFev1: '',
  postFevc: '',
  reversibility: '',
  peakFlow: ''
};

const BREAST_ARR = [
  'breast ca',
  'infiltrating ductal carcinoma',
  'invasive ductal ca',
  'mucinous ductal carcinoma',
  'papillary ductal carcinoma',
  'idca',
  'carcinoma of the breast',
  'cancer of the breast',
  'cancer of the female breast',
  'brca',
  'ductal carcinoma in situ',
  'mucinous adeno',
  'dcis',
  'invasive ductal breast',
  'microinvasive ductal',
  'bc invasive ductal',
  'cancer, breast',
  'carcinoma of breast',
  'invasive ductal',
  'infiltrating ductal',
  'mucinous ca',
  'invasive mammary carcinoma',
  'intraductal papilloma',
  'breast',
  'invasive mammary',
  'medullary carcinoma',
  'tubular carcinoma',
  'mucinous carcinoma',
  'ductal carcinoma'
];

const LUNG_ARR = [
  'lung',
  'bronchial cancer',
  'bronchioloalveolar carcinoma',
  'lung ca',
  'nsclc',
  'nsclca',
  'non-squamous lung ca',
  'squamous lung ca',
  'adenocarcinoma of the lung',
  'carcinoma of the lung',
  'metastatic lung ca',
  'Pulmonary Ca',
  'non-small cell lung ca',
  'lung adeno ca',
  'small cell lung ca',
  'adenocancer of the lung',
  'cancer of the lung',
  'non-small cell lung ca',
  'non-small lung ca',
  'non small lung cA',
  'non-small cell lung cancer',
  'non-small cell carcinoma Of lung',
  'on-small cell lung ca',
  'non small cell lung',
  'cancer, lung, non small cell',
  'non small cell ca%'
];

const COLON_ARR = [
  'colon',
  'cancer of the rectum',
  'cancer rectum',
  'adenocarcinoma of rectum',
  'malignant melanoma of rectum',
  'malignant neoplasm of rectum',
  'colon cancer',
  'cancer of the colon',
  'colon carcinoma',
  'colon adenocarcinoma',
  'colon ca',
  'well differentiated colon ca',
  'adenocarcinoma, ascending colon',
  'adenocarcinoma, sigmoid colon'
];

const COLORECTAL_ARR = ['colorectal', 'colorectal cancer', 'colorectal adenocarcinoma', 'colorectal carcinoma'];

const LIVER_ARR = ['cancer of the liver, hepatocellular'];

const GIST_ARR = ['cancer of the stomach', 'stomach cancer', 'cancer of the gastrointestinal tract'];

const OVARY_ARR = ['ovarian cancer', 'cervical cancer', 'endometrial cancer', 'endometrial carcinosarcoma'];

const NET_ARR = ['neuroendocrine tumor', 'neuroendocrine cancer'];

export const isBreastCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  const results = BREAST_ARR.find(b => name.includes(b));
  return results !== undefined;
};

export const isLungCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  const results = LUNG_ARR.find(l => name.includes(l));
  return results !== undefined;
};

export const isGastricCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('gastric');
};

export const isLymphomaCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('lymphoma');
};

export const isGISTCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('gist') || !!GIST_ARR.find(l => name.includes(l));
};

export const isHeadOrNeckCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('head') || name.includes('neck');
};

export const isColonCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  const results = COLON_ARR.find(c => name.includes(c));
  return results !== undefined;
};

export const isColorectalCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  const results = COLORECTAL_ARR.find(c => name.includes(c));
  return results !== undefined;
};

export const isPancreasCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('pancreas') || name.includes('pancreatic cancer');
};

export const isProstateCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('prostate');
};

export const isTestisCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('testis') || name.includes('testicular cancer');
};

export const isNETCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('net') || !!NET_ARR.find(l => name.includes(l));
};

export const isThyroidCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('thyroid');
};

export const isLiverCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  const results = name.includes('liver') || LIVER_ARR.find(l => name.includes(l));
  return results !== undefined;
};

export const isBloodCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('blood') || name.includes('leukemia');
};

export const isOvaryCancer = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('ovary') || !!OVARY_ARR.find(l => name.includes(l));
};

export const isBronchialAsthma = (diagnosisName: string): boolean => {
  return diagnosisName === BRONCHIAL_ASTHMA_STRING;
};

export const isTuberculosis = (diagnosisName: string): boolean => {
  const name = diagnosisName.toLowerCase();
  return name.includes('tuberculosis');
};

// @ts-ignore
export const ENCOUNTER_VACCINATION_DEFAULTS: EncounterVaccination = {
  patientId: -1,
  phrId: -1,
  vaccine: '',
  vaccineType: '',
  dosage: '',
  givenAt: null,
  dueAt: null,
  administeredBy: '',
  administered: false
};

export const actNowDefault: any = {
  cbe__date_of_cbe: '',
  cbe__armpit_mass: '',
  cbe__more_than_1_armpit_mass: '',
  cbe__mass_size: '',
  cbe__mass_size_unit: '',
  cbe__mass_mobility: '',
  cbe__mass_consistency: '',
  cbe__mass_location: '',
  receptors__estrogen: '',
  receptors__progesterone: '',
  receptors__her2: '',
  receptors__ros1: '',
  receptors__pdl1: '',
  receptors__er: '',
  receptors__pr: '',
  receptors__her2neu: '',
  receptors__ntrk: '',
  id: null
};

export const gyneDefault: any = {
  id: null,
  genitourinary__urine_character: '',
  genitourinary__bladder: '',
  genitourinary__voiding: '',
  genitourinary__catheter: '',
  genitourinary__catheter_specify: '',
  genitourinary__tubes: '',
  genitourinary__tubes_specify: '',
  pelvic_exam__pe: '',
  pelvic_exam__speculum: '',
  pelvic_exam__cervical_dilation: '',
  pelvic_exam__uterus: '',
  pelvic_exam__adnexa: '',
  pelvic_exam__rectovaginal_exam: '',
  pelvic_exam__digital_rectal_exam: ''
};

export const obDefault: any = {
  id: null,
  genitourinary__urine_character: '',
  genitourinary__bladder: '',
  genitourinary__voiding: '',
  genitourinary__catheter: '',
  genitourinary__catheter_specify: '',
  genitourinary__tubes: '',
  genitourinary__tubes_specify: '',
  abdominal__fh: '',
  abdominal__fh_unit: 'cm',
  abdominal__efw: '',
  abdominal__efw_unit: 'g',
  abdominal__fht: '',
  abdominal__fht_unit: 'bpm',
  abdominal__leopalds_manuever: [],
  pelvic_exam__pe: '',
  pelvic_exam__speculum: '',
  pelvic_exam__cervical_dilation: '',
  pelvic_exam__effacement: '',
  pelvic_exam__bow: '',
  pelvic_exam__station: '',
  pelvic_exam__consistency: '',
  pelvic_exam__position: '',
  pelvic_exam__bishops_score: '',
  pelvic_exam__cp_performed_by: '',
  pelvic_exam__cp_diagonal_conjugate: '',
  pelvic_exam__cp_sciatic_notch: '',
  pelvic_exam__cp_ischial_spines: '',
  pelvic_exam__cp_public_angle: '',
  pelvic_exam__cp_pelvic_sidewalls: '',
  pelvic_exam__cp_bituberous_diameter: '',
  pelvic_exam__cp_bispinous_diameter: '',
  pelvic_exam__cp_sacral_curvature: '',
  admitting_ctg__ac: '',
  admitting_ctg__noted_by: '',
  admitting_ctg__interpretation: ''
};

export const ophthalmologyDefault: any = {
  id: null,
  pupils__od_rapd: '',
  pupils__od_rtl: '',
  pupils__od_size: '',
  pupils__os_rapd: '',
  pupils__os_rtl: '',
  pupils__os_size: '',
  applanation: '',
  applanation__od: '',
  applanation__os: '',
  digital: '',
  shiotz: '',
  shiotz__remarks: '',
  dva2__cc_ph: '',
  dva2__cc_sc: '',
  dva2__od_ph: '',
  dva2__od_sc: '',
  dva2__os_ph: '',
  dva2__os_sc: '',
  dva__cc_ph: '',
  dva__cc_sc: '',
  dva__od_ph: '',
  dva__od_sc: '',
  dva__os_ph: '',
  dva__os_sc: '',
  nva__od_ph: '',
  nva__od_sc: '',
  nva__os_ph: '',
  nva__os_sc: '',
  other_tests: ''
};

export const STAGE_OPTIONS = [
  { id: 'Stage 0', value: 'Stage 0' },
  { id: 'Stage IA', value: 'Stage IA' },
  { id: 'Stage IIA', value: 'Stage IB' },
  { id: 'Stage IIA', value: 'Stage IIA' },
  { id: 'Stage IIB', value: 'Stage IIB' },
  { id: 'Stage IIIA', value: 'Stage IIIA' },
  { id: 'Stage IIIB', value: 'Stage IIIB' },
  { id: 'Stage IIIC', value: 'Stage IIIC' },
  { id: 'Stage IV', value: 'Stage IV' }
];
