import sortBy from 'lodash/sortBy';
import {
  Account,
  AccountHMO,
  AccountRole,
  AccountType,
  Address,
  AddressType,
  ContactNumber,
  ContactType,
  Gender,
  Identification,
  MedicalRepresentative,
  NavigatorDashboard,
  OwnerType,
  PatientNameAndAvatar,
  PatientSummary,
  Profile,
  SecretaryDashboard,
  SignupForm,
  Specialization,
  UpdateCurrentUserInput,
  User,
  UserDetails,
  UserRole,
  UserType,
  Wallet
} from 'models';
import {
  SUBSCRIPTION_STATUS,
  _Account,
  _AccountHMO,
  _Address,
  _AppointmentMedicalRepresentative,
  _ContactNumber,
  _Identification,
  _MedicalRepresentative,
  _MedRepProfile,
  _NavigatorDashboard,
  _PatientListItem,
  _PatientSummary,
  _Profile,
  _ProfileSpecialization,
  _SecretaryDashboard,
  _User,
  _Wallet
} from 'models/endpoint';
import { getProject } from 'services/utils.service';

export const transformOut = {
  registerDoctor: (form: SignupForm) => {
    return {
      first_name: form.firstName,
      middle_name: form.middleName,
      last_name: form.lastName,
      suffix: form.suffix,
      email: form.email,
      password: form.password,
      password_confirmation: form.passwordConfirmation,
      gender: form.gender,
      birth_date: form.dateOfBirth,
      terms_and_conditions: form.termsAndCondition,
      specialization_id: form.specialization,
      prc_license: form.prcNumber,
      contact_number: form.contactNumber,
      access: {
        psmo: form.psmoDoctor,
        psmo__referral_data_analytics: form.psmoSharedData,
        submission_form__bcmap: form.submissionFormBcmap
      },
      disclaimer: form.psmoSharedData
    };
  },
  updateCurrentUser: (form: UpdateCurrentUserInput) => {
    return {
      first_name: form.firstName,
      middle_name: form.middleName,
      last_name: form.lastName,
      suffix: form.suffix,
      email: form.email,
      gender: form.gender,
      birth_date: form.dateOfBirth,
      onboard: form.onboard,
      terms_and_conditions: form.termsAndConditions,
      disclaimer: form.disclaimer,
      enabled_2fa: form.enabled2fa
    };
  },
  addContactNumber: (contactNumber: Partial<ContactNumber>): Partial<_ContactNumber> => {
    return {
      number: contactNumber.number,
      owner_id: parseInt(`${contactNumber.ownerId}`),
      owner_type: contactNumber.ownerType,
      contact_type: contactNumber.contactType
    };
  },
  updateContactNumber: (contactNumber: Partial<ContactNumber>): Partial<_ContactNumber> => {
    return {
      id: parseInt(`${contactNumber.id}`),
      owner_id: parseInt(`${contactNumber.ownerId}`),
      owner_type: contactNumber.ownerType,
      number: contactNumber.number,
      contact_type: contactNumber.contactType
    };
  },
  updateContactNumbers: (contactNumbers: Partial<ContactNumber>[]): Partial<_ContactNumber>[] => {
    return contactNumbers.map(contactNumber => {
      return {
        id: parseInt(`${contactNumber.id}`),
        owner_id: parseInt(`${contactNumber.ownerId}`),
        owner_type: contactNumber.ownerType,
        number: contactNumber.number,
        contact_type: contactNumber.contactType
      };
    });
  },
  addContactAddress: (address: Partial<Address>): Partial<_Address> => {
    return {
      owner_id: address.ownerId,
      owner_type: address.ownerType,
      address_type: address.addressType,
      address1: address.address1,
      barangay: address.barangay,
      region: address.region,
      city: address.city,
      state: address.state,
      municipality: address.municipality,
      province: address.province,
      country: address.country,
      postcode: address.postcode
    };
  },
  updateContactAddress: (address: Partial<Address>): Partial<_Address> => {
    return {
      id: address.id,
      owner_id: address.ownerId,
      owner_type: address.ownerType,
      address_type: address.addressType,
      address1: address.address1,
      barangay: address.barangay,
      region: address.region,
      city: address.city,
      state: address.state,
      municipality: address.municipality,
      province: address.province,
      country: address.country,
      postcode: address.postcode
    };
  },
  updateContactAddresses: (addresses: Partial<Address>[]): Partial<_Address>[] => {
    return addresses.map(address => {
      return {
        id: address.id,
        owner_id: address.ownerId,
        owner_type: address.ownerType,
        address_type: address.addressType,
        address1: address.address1,
        barangay: address.barangay,
        region: address.region,
        city: address.city,
        state: address.state,
        municipality: address.municipality,
        province: address.province,
        country: address.country,
        postcode: address.postcode
      };
    });
  },
  addIdentificationNumber: (identification: Partial<Identification>): Partial<_Identification> => {
    return {
      id_type: identification.idType,
      identifier: identification.identifier
    };
  },
  updateIdentificationNumber: (identification: Identification): Partial<_Identification> => {
    return {
      id: identification.id,
      id_type: identification.idType,
      identifier: identification.identifier
    };
  }
};

export const transformIn = {
  secretaryDashboard: (form: _SecretaryDashboard): SecretaryDashboard => {
    return {
      accounts: form.accounts.map(acc => ({
        id: acc.id,
        name: acc.name,
        type: acc.type as AccountType,
        doctors: acc.doctors,
      })),
      doctors: form.doctors.map(doc => ({
        id: doc.id,
        email: doc.email,
        firstName: doc.first_name,
        lastName: doc.last_name,
        middleName: doc.middle_name ?? '',
        fileUrl: doc.file_url,
        specializations: doc.specializations.map(spec => ({
          id: spec.id,
          name: spec.name
        }))
      }))
    };
  },
  navigatorDashboard: (data: _NavigatorDashboard): NavigatorDashboard => {
    return {
      accounts: data.accounts.map(acc => ({
        id: acc.id,
        name: acc.name,
        type: acc.type as AccountType,
        doctors: acc.doctors,
      })),
      doctors: data.doctors.map(doc => ({
        id: doc.id,
        email: doc.email,
        firstName: doc.first_name,
        lastName: doc.last_name,
        middleName: doc.middle_name ?? '',
        fileUrl: doc.file_url,
        specializations: doc.specializations.map(spec => ({
          id: spec.id,
          name: spec.name
        }))
      }))
    };
  },
  user: (form: _User): User => {
    const { isTMC, isKeralty } = getProject();
    const {
      doctor_created,
      next_reminder_date,
      project__core__first_sign_in,
      project__tmc__first_sign_in,
      project__keralty__first_sign_in,
      project__tmc_subscription_trial,
      project__tmc_subscription_status,
      project__core_subscription_trial,
      project__core_subscription_status,
      project__keralty_subscription_trial,
      project__keralty_subscription_status,
      prescription__watermark
    } = form.details ?? {};
    const details: UserDetails = {
      doctorCreated: doctor_created,
      nextReminderDate: next_reminder_date,
      prescriptionWatermark: prescription__watermark,
      firstSignIn: isTMC
        ? project__tmc__first_sign_in
        : isKeralty
        ? project__keralty__first_sign_in
        : project__core__first_sign_in,
      subscriptionTrial:
        (isTMC
          ? project__tmc_subscription_trial
          : isKeralty
          ? project__keralty_subscription_trial
          : project__core_subscription_trial) ?? null,
      subscriptionStatus:
        (isTMC
          ? project__tmc_subscription_status
          : isKeralty
          ? project__keralty_subscription_status
          : project__core_subscription_status) ?? SUBSCRIPTION_STATUS.EMPTY
    };
    return {
      id: form.id,
      email: form.email,
      firstName: form.first_name,
      middleName: form.middle_name ?? '',
      lastName: form.last_name,
      nickname: form.nickname,
      gender: form.gender as Gender,
      suffix: form.suffix,
      dateOfBirth: new Date(form.birth_date),
      onboard: form.onboard,
      deceased: form.deceased,
      deceasedCause: form.deceased_cause,
      termsAndConditions: form.terms_and_conditions,
      active: form.active,
      createdAt: new Date(form.created_at),
      updatedAt: new Date(form.updated_at),
      type: form.type as UserType,
      role: form.role as UserRole,
      fileUrl: form.file_url,
      fileName: form.file_name,
      contactNumbers: sortBy(
        (form.contact_numbers || []).map(c => transformIn.contactNumber(c)),
        [item => item.id]
      ),
      addresses: (form.addresses || []).map(a => transformIn.address(a)),
      claimed: form.claimed,
      testAccount: form.test_account,
      access: {
        submissionForm: {
          bcmap: form.access ? form.access.submission_form__bcmap : false,
          nalagen: form.access ? form.access.nalagen : false,
          aace: form.access ? form.access.aace : false,
          tavr: form.access ? form.access.tavr : false,
          registryLiver: form.access ? form.access.registry_liver : false,
          registryPgh: form.access ? form.access.registry_pgh : false,
          registryJrrmmc: form.access ? form.access.registry_jrmmc : false,
          registryEvrmc: form.access ? form.access.registry_evrmc : false,
          ncd: form.access ? form.access.ncd : false,
        },
        encounterForm: {
          bci: form.access ? form.access.encounter_form__bci : false,
          hpb: form.access ? form.access.encounter_form__hpb : false,
          oncology: form.access ? form.access.encounter_form__oncology : false,
          standard: form.access ? form.access.encounter_form__standard : false,
          cardiology: form.access ? form.access.encounter_form__cardiology : false,
          diabetology: form.access ? form.access.encounter_form__diabetology : false,
          rheumatology: form.access ? form.access.encounter_form__rheumatology : false,
          endocrinology: form.access ? form.access.encounter_form__endocrinology : false,
          psmoDoctor: form.access ? form.access.psmo : false,
          psmoSharedData: form.access ? form.access.psmo__referral_data_analytics : false
        }
      },
      isBcmapDoctorOnly:
        form.access &&
        !form.access.encounter_form__bci &&
        !form.access.encounter_form__hpb &&
        !form.access.encounter_form__oncology &&
        !form.access.encounter_form__standard &&
        !form.access.encounter_form__cardiology &&
        !form.access.encounter_form__diabetology &&
        !form.access.encounter_form__rheumatology &&
        !form.access.encounter_form__endocrinology &&
        form.access.submission_form__bcmap,
      lastSignInAt: form.last_sign_in_at,
      details: details,
      wallets: (form.wallets || []).map(w => transformIn.wallet(w)),
      disclaimer: form.disclaimer,
      enabled2fa: form.enabled_2fa,
      nextFeedbackDate: form.next_feedback_date
    };
  },
  contactNumber: (form: _ContactNumber): ContactNumber => {
    return {
      id: form.id,
      contactType: form.contact_type as ContactType,
      number: form.number,
      createdAt: new Date(form.created_at),
      updatedAt: new Date(form.updated_at),
      ownerId: form.owner_id,
      ownerType: form.owner_type as OwnerType
    };
  },
  address: (form: _Address): Address => {
    return {
      id: form.id,
      ownerId: form.owner_id,
      ownerType: form.owner_type as OwnerType,
      address1: form.address1,
      address2: form.address2,
      barangay: form.barangay || '',
      region: form.region || '',
      city: form.city || '',
      state: form.state || '',
      municipality: form.municipality || '',
      province: form.province || '',
      country: form.country || '',
      postcode: form.postcode || '',
      active: form.active,
      createdAt: new Date(form.created_at),
      updatedAt: new Date(form.updated_at),
      addressType: form.address_type as AddressType
    };
  },
  wallet: (form: _Wallet): Wallet => {
    return {
      id: form.id,
      userId: form.user_id,
      institution: form.institution,
      balance: form.balance,
      enabled: form.enabled,
      createdAt: new Date(form.created_at),
      updatedAt: new Date(form.updated_at)
    };
  },
  profile: (profile: _Profile & { hmos: AccountHMO[] }): Profile => {
    return {
      accounts: (profile.accounts || []).map(acc => ({
        id: acc.id,
        type: acc.type as AccountType,
        role: acc.role as AccountRole,
        code: acc.code,
        classification: acc.classification,
        fileName: acc.file_name,
        fileUrl: acc.file_url,
        publishSchedule: acc.publish_schedule,
        name: acc.name,
        limit: acc.limit,
        clinic_ids: acc.clinic_ids,
        affiliation_id: acc.affiliation_id,
        affiliation_type: acc.affiliation_type,
        auto_share: acc.auto_share,
        member_join_dates: acc.member_join_dates,
        active: acc.active,
        created_at: new Date(acc.created_at),
        updated_at: new Date(acc.updated_at)
      })),
      identifications: transformIncomingIdentificationsFromProfile(profile.identifications),
      specializations: transformIncomingSpecializationFromProfile(profile.specializations),
      hmos: profile.hmos
    };
  },
  summary: (summary: _PatientSummary): PatientSummary => {
    return {
      id: summary.id,
      email: summary.email,
      gender: summary.gender,
      currentAddress: summary.current_address || { address1: '', address_type: 'current', owner_type: 'User' },
      permanentAddress: summary.current_address || { address1: '', address_type: 'permanent', owner_type: 'User' },
      contactNumber: summary.contact_number,
      bloodType: summary.blood_type,
      philhealth: summary.philhealth,
      hmo: summary.hmo,
      religion: summary.religion,
      maritalStatus: summary.marital_status,
      birthDate: summary.birth_date,
      allergies: summary.allergies,
      medications: summary.medications,
      presentMedicalHistory: summary.present_medical_history,
      pastMedicalHistory: summary.past_medical_history,
      coMorbidConditions: summary.co_morbid_conditions,
      complications: summary.complications,
      latestConsultationDate: summary.latest_consultation_date,
      pastMedicalHistoryDetails: {
        pmhEcog: summary.past_medical_history_details.pmh_ecog,
        pmhDmBool: summary.past_medical_history_details.pmh_dm_bool,
        pmhDmValue: summary.past_medical_history_details.pmh_dm_value,
        pmhHivBool: summary.past_medical_history_details.pmh_hiv_bool,
        pmhHivValue: summary.past_medical_history_details.pmh_hiv_value,
        pmhHpnBool: summary.past_medical_history_details.pmh_hpn_bool,
        pmhHpnValue: summary.past_medical_history_details.pmh_hpn_value,
        pmhHepatitisBool: summary.past_medical_history_details.pmh_hepatitis_bool,
        pmhHepatitisValue: summary.past_medical_history_details.pmh_hepatitis_value,
        pmhStrokeCvaBool: summary.past_medical_history_details.pmh_stroke_cva_bool,
        pmhStrokeCvaValue: summary.past_medical_history_details.pmh_stroke_cva_value,
        pmhThyroidDseBool: summary.past_medical_history_details.pmh_thyroid_dse_bool,
        pmhThyroidDseValue: summary.past_medical_history_details.pmh_thyroid_dse_value,
        pmhPreviousSurgeries: summary.past_medical_history_details.pmh_previous_surgeries,
        pmhBronchialAsthmaBool: summary.past_medical_history_details.pmh_bronchial_asthma_bool,
        pmhBronchialAsthmaValue: summary.past_medical_history_details.pmh_bronchial_asthma_value,
        pmhPreviousTransfusionBool: summary.past_medical_history_details.pmh_previous_transfusion_bool,
        pmhPreviousTransfusionValue: summary.past_medical_history_details.pmh_previous_transfusion_value
      },
      familyHistory: summary.family_history,
      familyHistoryDetails: summary.family_history_details,
      socialHistory: summary.social_history,
      drugs: summary.drug_lifestyle,
      suicidality: summary.suicidality,
      sexualHistory: summary.sex,
      environmentalExposure: summary.environmental_exposure,
      development: summary.development,
      additionalInfo: summary.additional_info,
      occupation: summary.occupation,
      ethnicity: summary.ethnicity,
      nationality: summary.nationality,
      paymentPreference: summary.payment_preference,
      landlineNumber: summary.landline_number,
      guardianName: summary.guardian_name,
      guardianRelation: summary.guardian_name,
      guardianContactInformation: summary.guardian_contact_information,
      emergencyContactName: summary.emergency_contact_name,
      emergencyContactRelation: summary.emergency_contact_relation,
      emergencyContactInformation: summary.emergency_contact_information,
      emergencyContactAddress: summary.emergency_contact_address,
      cigaretteLifestyle: summary.cigarette_lifestyle,
      cigaretteLifestyleOnset: summary.cigarette_lifestyle_onset,
      cigaretteLifestyleRemark: summary.cigarette_lifestyle_remark,
      cigaretteLifestyleQuitDate: summary?.cigarette_lifestyle_quit_date,
      cigaretteLifestyleMonths: summary?.cigarette_lifestyle_months,
      alcoholLifestyle: summary.alcohol_lifestyle,
      alcoholLifestyleOnset: summary.alcohol_lifestyle_onset,
      alcoholLifestyleRemark: summary.alcohol_lifestyle_remark,
      alcoholLifestyleQuitDate: summary.alcohol_lifestyle_quit_date,
      alcoholLifestyleMonths: summary.alcohol_lifestyle_months,
      eyeRecords: summary.optha_details.eye_records,
      eyeRecordsLastRefracted: summary.optha_details.eye_records_last_refracted,
      dietAndNutrition: summary.diet_and_nutrition.join(', '),
      dietAndNutritionOnset: summary.diet_and_nutrition_onset,
      dietAndNutritionRemark: summary.diet_and_nutrition_remark,
      dietAndNutritionMonths: summary.diet_and_nutrition_months,
      dietAndNutritionQuitDate: summary.diet_and_nutrition_quit_date,
      exerciseHabits: summary.exercise_habits,
      exerciseHabitsOnset: summary.exercise_habits_onset,
      exerciseHabitsRemark: summary.exercise_habits_remark,
      exerciseHabitsMonths: summary.exercise_habits_months,
      exerciseHabitsQuitDate: summary.exercise_habits_quit_date,
      menarche: summary.menarche,
      menopause: summary.menopause,
      obgynRemarks: summary.obgyn_history?.obgyn_remarks ?? null,
      lastMenstrualPeriod: summary.last_menstrual_period,
      estimateDateOfDelivery: summary.estimate_date_of_delivery,
      ageOfGestation: summary.age_of_gestation,
      menstrualCycle: summary.menstrual_cycle,
      lengthOfBleeding: summary.length_of_bleeding,
      padsOrTampons: summary.pads_or_tampons,
      frequency: summary.frequency,
      bleedingBetweenPeriods: summary.bleeding_between_periods,
      bleedingAfterIntercourse: summary.bleeding_after_intercourse,
      postMenopausalBleeding: summary.post_menopausal_bleeding,
      gravida: summary.gravida,
      termBirth: summary.term_birth,
      preterm: summary.preterm,
      abortion: summary.abortion,
      livingChildren: summary.living_children,
      modeOfDelivery: summary.mode_of_delivery,
      breastfeeding: summary.breastfeeding,
      contraception: summary.contraception,
      complicationDuringPregnancy: summary.complication_during_pregnancy,
      historyOfSti: summary.history_of_sti,
      papSmearHistory: summary.pap_smear_history,
      gynecologicalSurgery: summary.gynecological_surgery,
      diseaseHistory: summary.disease_history,
      breastDiseaseHistoryDetails: {
        bdhG: summary.breast_disease_history_details.bdh_g,
        bdhP: summary.breast_disease_history_details.bdh_p,
        bdhPmp: summary.breast_disease_history_details.bdh_pmp,
        bdhData: summary.breast_disease_history_details.bdh_data,
        bdhLnmp: summary.breast_disease_history_details.bdh_lnmp,
        bdhMenarcheAge: summary.breast_disease_history_details.bdh_menarche_age,
        bdhOcpUseBool: summary.breast_disease_history_details.bdh_ocp_use_bool,
        bdhMenopauseAge: summary.breast_disease_history_details.bdh_menopause_age,
        bdhOcpUseValue: summary.breast_disease_history_details.bdh_ocp_use_value,
        bdhBreastfeedingBool: summary.breast_disease_history_details.bdh_breastfeeding_bool,
        bdhBreastfeedingValue: summary.breast_disease_history_details.bdh_breastfeeding_value,
        bdhMenstrualIntervals: summary.breast_disease_history_details.bdh_menstrual_intervals,
        bdhFamilyHxOtherCaBool: summary.breast_disease_history_details.bdh_family_hx_other_ca_bool,
        bdhFamilyHxOtherCaValue: summary.breast_disease_history_details.bdh_family_hx_other_ca_value,
        bdhFamilyHxBreastCaBool: summary.breast_disease_history_details.bdh_family_hx_breast_ca_bool,
        bdhFamilyHxBreastCaValue: summary.breast_disease_history_details.bdh_family_hx_breast_ca_value,
        bdhPreviousBreastSurgeryBool: summary.breast_disease_history_details.bdh_previous_breast_surgery_bool,
        bdhAgeFirstCompletedPregnancy: summary.breast_disease_history_details.bdh_age_first_completed_pregnancy,
        bdhPreviousBreastSurgeryValue: summary.breast_disease_history_details.bdh_previous_breast_surgery_value
      },
      forExtraction: summary.for_extraction,
      deceased: summary.deceased,
      deceasedCause: summary.deceased_cause,
      createdAt: summary.created_at,
      updatedAt: summary.updated_at,
      status: {
        diseaseNoEvidence: summary.status.disease__no_evidence,
        diseaseRecurrenceGeneral: summary.status.disease__recurrence_general,
        diseaseRecurrenceLocal: summary.status.disease__recurrence_local,
        diseaseRecurrenceRegional: summary.status.disease__recurrence_regional,
        diseaseRecurrenceDistant: summary.status.disease__recurrence_distant,
        diseaseDeceasedFromDisease: summary.status.disease__deceased_from_disease,
        diseaseDeceasedFromOther: summary.status.disease__deceased_from_other,
        diseaseDeceasedFromOtherUnrelated: summary.status.disease__deceased_from_other_unrelated,
        diseaseStatusUnknown: summary.status.disease__status_unknown,
        treatmentUndergoing: summary.status.treatment__undergoing,
        treatmentCompleted: summary.status.treatment__completed,
        treatmentIncomplete: summary.status.treatment__incomplete,
        treatmentNone: summary.status.treatment__none,
        treatmentStatusUnknown: summary.status.treatment__status_unknown,
        treatmentLostToFollowup: summary.status.treatment__lost_to_followup,
        turnOffNotifications: summary.status.turn_off_notifications,
        snooze: summary.status.snooze,
        lastUpdatedAt: summary.status.last_updated_at
      },
      // to be defined
      summaryAllergies: summary.summary_allergies
    };
  }
};

const transformIncomingIdentificationsFromProfile = (identifications: _Identification[]): Identification[] => {
  const items = identifications.map((identification: _Identification) => {
    return {
      id: identification.id,
      idType: identification.id_type,
      identifier: identification.identifier,
      expiration: identification.expiration,
      createdAt: new Date(identification.created_at),
      updatedAt: new Date(identification.updated_at)
    };
  });

  return sortBy(items, [identification => identification.id]);
};

export const transformIncomingSpecializationFromProfile = (
  specializations: _ProfileSpecialization[]
): Specialization[] => {
  const items = specializations.map((specialization: _ProfileSpecialization) => {
    return {
      id: specialization.id,
      specializationId: specialization.specialization_id,
      active: specialization.active,
      name: specialization.specialization.name,
      createdAt: new Date(specialization.created_at),
      updatedAt: new Date(specialization.updated_at)
    };
  });

  return sortBy(items, [specialization => specialization.id]);
};

export const transformIncomingHMO = (hmos: _AccountHMO[]): AccountHMO[] => {
  return (hmos || [])
    .map((item: _AccountHMO) => ({
      id: item.id,
      hmoId: item.hmo_id,
      hmoCode: item.hmo_code,
      clinicId: item.clinic_id,
      clinicName: item.clinic_name,
      number: item.number
    }))
    .sort((a, b) => a.id - b.id);
};

export const extractNameAndAvatarFromPatient = (patients: _PatientListItem[]): PatientNameAndAvatar[] => {
  return patients.map((patient: _PatientListItem): PatientNameAndAvatar => {
    return {
      id: patient.id,
      name: patient.name,
      fileUrl: patient.avatar,
      onWatchList: patient.on_watch_list
    };
  });
};

export const sortByKey = (list: Array<any>, key: string) => sortBy(list, key);

export const transformInMedicalRepresentative = (data: _MedicalRepresentative): MedicalRepresentative => {
  return {
    id: data.id ? data.id : undefined,
    profileId: data.profile_id,
    firstName: data.first_name,
    lastName: data.last_name,
    contactNumber: data.contact_number,
    email: data.email,
    company: data.company,
    brand: data.brand,
    notes: data.notes,
    lastVisitDate: data.last_visit_date ? new Date(data.last_visit_date) : undefined
  };
};

export const transformOutMedRepInfo = (form: MedicalRepresentative): Partial<_MedicalRepresentative> => {
  return {
    id: form.id ? form.id : undefined,
    first_name: form.firstName,
    last_name: form.lastName,
    contact_number: form.id ? undefined : form.contactNumber,
    email: form.id ? undefined : form.email,
    company: form.id ? undefined : form.company,
    brand: form.id ? undefined : form.brand,
    notes: form.id ? undefined : form.notes
  };
};

export const transformOutSaveMedRepProfile = (form: MedicalRepresentative): Partial<_MedRepProfile> => {
  return {
    id: form.profileId ? form.profileId : undefined,
    medical_representative_id: form.profileId ? undefined : form.id,
    contact_number: form.contactNumber,
    email: form.email,
    company: form.company,
    brand: form.brand,
    notes: form.notes
  };
};

export const transformMedRepReference = (data: _AppointmentMedicalRepresentative[]): MedicalRepresentative[] => {
  return data.map((item: _AppointmentMedicalRepresentative) =>
    transformInMedicalRepresentative({
      id: item.id,
      first_name: item.first_name,
      last_name: item.last_name,
      contact_number: item.contact_numbers && item.contact_numbers.length > 0 ? item.contact_numbers[0].number : '',
      email: item.email,
      company: item.company,
      brand: item.brand,
      notes: '',
      last_visit_date: item.last_visit_date
    })
  );
};

export const transformInAccountInfo = (data: _Account): Account => {
  return {
    id: data.id,
    type: data.type as AccountType,
    role: data.role as AccountRole,
    code: data.code,
    fileName: data.file_name,
    fileUrl: data.file_url,
    classification: data.classification,
    publishSchedule: data.publish_schedule,
    name: data.name,
    limit: data.limit,
    clinic_ids: data.clinic_ids,
    affiliation_id: data.affiliation_id,
    affiliation_type: data.affiliation_type,
    auto_share: data.auto_share,
    active: data.active,
    created_at: new Date(data.created_at),
    updated_at: new Date(data.updated_at)
  };
};
