import { AxiosResponse } from 'axios';
import { Toast } from 'components';
import { StoreAction } from 'constants/store';
import { _LabRecord as Lab, _TestGroup as Labs } from 'models/endpoint';
import { ActionType } from 'models/store';
import { call, put, takeLatest } from 'redux-saga/effects';
import { IMAGING_TYPES } from 'services/imaging.service';
import {
  addEncounterLab,
  addLabAttachments,
  getAllLabsByPatientID,
  getEncounterLabs,
  removeEncounterLab,
  updateEncounterLab
} from 'services/labs.service';

const { GET_ALL: GET_LABS, ADD: ADD_LAB, UPDATE: UPDATE_LAB, REMOVE: REMOVE_LAB, GET_ALL_LABS } = StoreAction.PHR.LABS;

function* getEncounterLabsSaga(action: ActionType) {
  try {
    const phrId = action.payload;
    const { data }: AxiosResponse<Labs> = yield call(getEncounterLabs, phrId);
    const filteredLabs = data.filter(lab => IMAGING_TYPES.indexOf(lab.type) === -1);
    yield put({ type: GET_LABS.SUCCESS, payload: filteredLabs });
  } catch (err) {
    yield put({ type: GET_LABS.FAILED, payload: err });
  }
}

function* addEncounterLabSaga(action: ActionType) {
  try {
    const { data }: AxiosResponse<Lab> = yield call(addEncounterLab, action.payload.type, action.payload.values);
    let attachmentsData = [];
    if (action.payload.attachments.length > 0) {
      const response = yield call(addLabAttachments, data.id, action.payload.attachments);
      attachmentsData = (response || []).map((d: any) => ({ ...d.data }));
    }
    yield put({ type: ADD_LAB.SUCCESS, payload: { ...data, phr_test_group_files: [...attachmentsData] } });
  } catch (err) {
    yield put({ type: ADD_LAB.FAILED, payload: err });
    Toast.error(err.message);
  }
}

function* updateEncounterLabSaga(action: ActionType) {
  try {
    const { data }: AxiosResponse<Lab> = yield call(updateEncounterLab, action.payload);
    yield put({ type: UPDATE_LAB.SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: UPDATE_LAB.FAILED, payload: err });
  }
}

function* removeEncounterLabSaga(action: ActionType) {
  try {
    const { data } = yield call(removeEncounterLab, action.payload.labId, action.payload.phrId);
    yield put({ type: REMOVE_LAB.SUCCESS, payload: action.payload.labId });
    yield put({ type: GET_ALL_LABS.SUCCESS, payload: { ...data, isDelete: true } });
  } catch (err) {
    yield put({ type: REMOVE_LAB.FAILED, payload: err });
    Toast.error(err.message);
  }
}

function* getAllLabsByPatientIDSaga(action: ActionType) {
  try {
    const { data } = yield call(getAllLabsByPatientID, action.payload);
    yield put({ type: GET_ALL_LABS.SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: GET_ALL_LABS.FAILED, payload: err });
  }
}

export function* rootSaga() {
  yield takeLatest(ADD_LAB.REQUEST, addEncounterLabSaga);
  yield takeLatest(UPDATE_LAB.REQUEST, updateEncounterLabSaga);
  yield takeLatest(GET_LABS.REQUEST, getEncounterLabsSaga);
  yield takeLatest(REMOVE_LAB.REQUEST, removeEncounterLabSaga);
  yield takeLatest(GET_ALL_LABS.REQUEST, getAllLabsByPatientIDSaga);
}
