import * as Sentry from '@sentry/react';
import { ExtraErrorData } from '@sentry/integrations';

const DSN_URL = import.meta.env.VITE_DSN;
const ENVIRONMENT = import.meta.env.VITE_NODE_ENV || 'development';
const PROJECT = import.meta.env.VITE_NAME || '';
const VERSION = import.meta.env.VITE_VERSION || '';
const RELEASE = import.meta.env.VITE_APP_VERSION || '';

Sentry.init({
  enabled: ['production', 'staging'].includes(ENVIRONMENT),
  dsn: DSN_URL,
  release: RELEASE,
  environment: ENVIRONMENT,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay(), new ExtraErrorData()],
  tracePropagationTargets: ['localhost', /^\//],
  tracesSampleRate: 0.3,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0
});

console.log(`%c ${PROJECT.toUpperCase()}v${VERSION} build ${RELEASE} `, 'background: #222; color: #bada55');

// High volume changes at the moment -- disabled for the meantime
// if (localStorage.getItem('doctor_release_version') !== RELEASE) {
//   localStorage.setItem('doctor_release_version', RELEASE);
//   handleLogout();
// }
