import { AxiosResponse } from 'axios';
import { setLocalStorageData } from 'common/services';
import { StoreAction } from 'constants/store';
import { ActionType } from 'models/store';
import { call, put, takeLatest } from 'redux-saga/effects';
import { assumeMember } from 'services/session.service';

const { ASSUME_MEMBER, ACTIVE_ACCOUNT } = StoreAction.SESSION;
const { GET_PROFILE, GET_CLINIC, GET_CLINIC_HEADER } = StoreAction.DOCTOR;

function* assumeMemberSaga(actions: ActionType) {
  try {
    let { data }: AxiosResponse = yield call(assumeMember, actions.payload);
    const assumeToken = data.access_token;
    yield call(setLocalStorageData, { assumeToken });
    yield put({ type: GET_PROFILE.REQUEST, payload: null });
    yield put({ type: ASSUME_MEMBER.SUCCESS, payload: assumeToken });
  } catch (err) {
    yield put({ type: ASSUME_MEMBER.FAILED, payload: err });
  }
}

function* setActiveAccountSaga(actions: ActionType) {
  try {
    const accountId = actions.payload.accountId;
    const noProfile = actions.payload.noProfile || false;
    yield call(setLocalStorageData, { activeAccount: accountId });
    yield put({ type: ACTIVE_ACCOUNT.SUCCESS, payload: accountId });
    if (!noProfile) {
      yield put({ type: GET_PROFILE.REQUEST, payload: null });
      yield put({ type: GET_CLINIC.REQUEST, payload: null });
      yield put({ type: GET_CLINIC_HEADER.REQUEST, payload: null });
    }
  } catch (err) {
    yield put({ type: ASSUME_MEMBER.FAILED, payload: err });
  }
}

export function* rootSaga() {
  yield takeLatest(ASSUME_MEMBER.REQUEST, assumeMemberSaga);
  yield takeLatest(ACTIVE_ACCOUNT.REQUEST, setActiveAccountSaga);
}
