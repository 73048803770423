import IconButton from '@material-ui/core/IconButton';
import { AddCircle } from '@material-ui/icons';
import React from 'react';
import { styled } from 'components/Theme';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
  label?: string;
}

const AddItem: React.FC<Props> = props => {
  const [t] = useTranslation();
  const { onClick, label } = props;
  return (
    <IconButton
      disableRipple={true}
      disableFocusRipple={true}
      onClick={onClick}
      style={{ backgroundColor: 'transparent', padding: 0, borderRadius: 0 }}
    >
      <StyledAddCircle />
      <LabelWrapper>{label ?? t('add_another_record')}</LabelWrapper>
    </IconButton>
  );
};

export default React.memo(AddItem);

const StyledAddCircle = styled(AddCircle)`
  fill: ${props => props.theme.color.addIconColor} !important;
`;

const LabelWrapper = styled.span`
  font-size: 14px;
  color: ${props => props.theme.color.addIconColor};
  margin-left: 3px;
`;
