import { StoreAction } from 'constants/store';
import { ActionType } from 'models/store';
import { createAction } from 'services/utils.service';
import { PeekProps } from 'components';
import { PeekType } from 'constants/form';
const { PEEK } = StoreAction;

export type NewPeekAction = (options: PeekProps, type: PeekType) => ActionType;
export type HidePeekAction = () => ActionType;

export const newPeek: NewPeekAction = (options: PeekProps, type: PeekType) => {
  return createAction(PEEK.REQUEST, { options, type });
};

export const hidePeek: HidePeekAction = () => createAction(PEEK.REQUEST, { type: PeekType.Hide });

export type PeekAction = {
  newPeek: NewPeekAction;
  hidePeek: HidePeekAction;
};

export const peek = {
  newPeek,
  hidePeek
};
