import { SvgIconProps } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import classNames from 'classnames';
import { styled } from 'components/Theme';
import React from 'react';

interface Props {
  loading?: boolean;
  src?: string;
  alt?: string;
  className?: string;
  noImagePadding?: boolean;
  noBorder?: boolean;
  defaultIcon?: (props: SvgIconProps) => JSX.Element;
}

export const Avatar: React.FC<Props> = ({ src, loading, className, alt, defaultIcon, noImagePadding, noBorder }) => {
  const classes = classNames('avatar', className, {
    'has-padding': noImagePadding && !src,
    'no-border': noBorder
  });
  const DefaultIcon = defaultIcon ?? Person;
  if (loading) {
    return <p>loading...</p>;
  }
  return (
    <AvatarWrapper className={classes}>{src ? <img src={src} alt={alt ?? 'avatar'} /> : <DefaultIcon />}</AvatarWrapper>
  );
};

const AvatarWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid ${props => props.theme.color.secondary};
  overflow: hidden;
  position: relative;
  img,
  svg {
    width: 100%;
    height: 100%;
    fill: ${props => props.theme.color.primary} !important;
  }
  img {
    object-fit: cover;
  }
  &.no-border {
    border: none;
  }
  &.has-padding {
    padding: 10px;
  }
`;
