import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Field, FieldSubAttributes } from 'components/form';
import { styled } from 'components/Theme';
import { FieldInputProps, FieldMetaProps, FormikHelpers } from 'formik';
import React from 'react';

type DatePickerProps = {
  label?: string;
  className?: string;
  name: string;
  valueChange?: (value: any) => void;
} & Partial<KeyboardDatePickerProps>;

type PickerProps = DatePickerProps & {
  form: FormikHelpers<any>;
  field: FieldInputProps<FieldSubAttributes>;
  meta: FieldMetaProps<FieldSubAttributes>;
  valueChange?: (value: any) => void;
};

export const Datepicker: React.FC<DatePickerProps> = React.memo(({ name, valueChange, ...props }) => {
  return <Field name={name}>{(nProps: any) => <Picker name={name} {...nProps} {...props} valueChange={valueChange}/>}</Field>;
});

const Picker: React.FC<PickerProps> = ({
  name,
  form: { setFieldValue },
  field: { value },
  meta,
  label,
  className,
  valueChange,
  ...rest
}) => {
  const errorText = meta.error && meta.touched ? meta.error : '';
  const classes = `c-datepicker ${className || ''}`.trim();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePickerWrapper
        {...rest}
        className={classes}
        inputVariant="outlined"
        name={name}
        margin="normal"
        label={label}
        format="MM/dd/yyyy"
        value={value}
        error={!!errorText}
        helperText={errorText}
        onChange={value => {
          setFieldValue(name, value);
          valueChange && valueChange(value);
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

const KeyboardDatePickerWrapper = styled(KeyboardDatePicker)`
  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
`;
