import {
  Formik as FFormik,
  Form as FForm,
  Field as FField,
  FieldArray as FFieldArray,
  FieldAttributes as FFieldAttributes,
  FieldInputProps as FFieldInputProps,
  FieldArrayRenderProps as FFieldArrayRenderProps,
  useField as FuseField
} from 'formik';

export const useField = FuseField;
export type FieldInputProps<T> = FFieldInputProps<T>;
export type FieldSubAttributes = { label?: string; [key: string]: any };
export type FieldAttributes = FFieldAttributes<FieldSubAttributes>;
export type FieldArrayRenderProps = FFieldArrayRenderProps;

export const FormContainer = FFormik;
export const Form = FForm;
export const Field = FField;
export const FieldArray = FFieldArray;

export * from './input/Input';
export * from './checkbox/Checkbox';
export * from './datepicker/Datepicker';
export * from './select/Select';
export * from './autocomplete/Autocomplete';
export * from './scrollToError';
export * from './toggle/Toggle';
