import i18n from 'i18n';
import { ImmunizationVaccinationRecord, PatientImmunizationTemplate } from 'models';

export const DISEASE_STATUS_OPTIONS = [
  { id: 'disease__no_evidence', value: i18n.t('disease__no_evidence', { ns: 'patient_profile' }) },
  { id: 'disease__stable', value: i18n.t('disease__stable', { ns: 'patient_profile' }) },
  { id: 'disease__progressive', value: i18n.t('disease__progressive', { ns: 'patient_profile' }) },
  { id: 'recurrence_of_disease', value: i18n.t('recurrence_of_disease', { ns: 'patient_profile' }) },
  { id: 'disease__deceased_from_disease', value: i18n.t('disease__deceased_from_disease', { ns: 'patient_profile' }) },
  { id: 'disease__deceased_from_other', value: i18n.t('disease__deceased_from_other', { ns: 'patient_profile' }) },
  {
    id: 'disease__deceased_from_other_unrelated',
    value: i18n.t('disease__deceased_from_other_unrelated', { ns: 'patient_profile' })
  },
  { id: 'disease__status_unknown', value: i18n.t('disease__status_unknown', { ns: 'patient_profile' }) }
];

export const DISEASE_RECURRENCE_OPTIONS = [
  { id: '', value: '---' },
  { id: 'disease__recurrence_general', value: i18n.t('general', { ns: 'patient_profile' }) },
  { id: 'disease__recurrence_regional', value: i18n.t('regional', { ns: 'patient_profile' }) },
  { id: 'disease__recurrence_local', value: i18n.t('local', { ns: 'patient_profile' }) },
  { id: 'disease__recurrence_distant', value: i18n.t('distant', { ns: 'patient_profile' }) },
  { id: 'disease__recurrence_progressive', value: i18n.t('progressive', { ns: 'patient_profile' }) }
];

export const RESPONSE_OPTIONS = [
  { id: '', value: '---' },
  { id: 'response__adverse', value: i18n.t('response__adverse', { ns: 'patient_profile' }) },
  { id: 'response__partial', value: i18n.t('response__partial', { ns: 'patient_profile' }) },
  { id: 'response__complete', value: i18n.t('response__complete', { ns: 'patient_profile' }) },
  {
    id: 'response__no_objective_clinical_benefit',
    value: i18n.t('response__no_objective_clinical_benefit', { ns: 'patient_profile' })
  }
];

export const TREATMENT_STATUS_OPTIONS = [
  { id: '', value: '---' },
  { id: 'treatment__undergoing', value: i18n.t('undergoing_treatment', { ns: 'patient_profile' }) },
  { id: 'treatment__completed', value: i18n.t('treatment__completed', { ns: 'patient_profile' }) },
  { id: 'treatment__incomplete', value: i18n.t('treatment__incomplete', { ns: 'patient_profile' }) },
  { id: 'treatment__none', value: i18n.t('treatment__none', { ns: 'patient_profile' }) },
  { id: 'treatment__status_unknown', value: i18n.t('treatment__status_unknown', { ns: 'patient_profile' }) },
  { id: 'treatment__lost_to_followup', value: 'Lost to follow up' }
];

export const IMMUNIZATION_VACCINATION_RECORD: ImmunizationVaccinationRecord = {
  manufacturer: '',
  site: '',
  vaccine: '',
  vaccineType: '',
  route: '',
  date: null
};

export const PATIENT_IMMUNIZATION_TEMPLATE: PatientImmunizationTemplate = {
  id: undefined,
  patientId: -1,
  templateId: -1,
  patientName: '',
  dateOfBirth: null,
  gender: '',
  nationality: '',
  condition: '',
  signature: '',
  vaccinationRecords: [IMMUNIZATION_VACCINATION_RECORD]
};
