import classNames from 'classnames';
import { styled } from 'components/Theme';
import React, { useEffect, useMemo, useState } from 'react';
import './Accordion.scss';

interface Props {
  isOpen?: boolean;
  title: string;
  headerComponent?: any;
  trailingComponent?: any;
  disabled?: boolean;
  onClick?: () => void;
  initialOpenValue?: boolean;
  headerColor?: string;
  headerTextColor?: string;
}

export const Accordion: React.FC<Props> = React.memo(
  ({ isOpen, onClick, disabled, initialOpenValue, headerColor, headerTextColor, ...props }) => {
    const [open, setOpen] = useState(initialOpenValue || false);
    const [isInitialized, setIsInitialized] = useState(false);

    const classes = useMemo(
      () =>
        classNames('accordion', {
          hide: !open,
          clickable: onClick !== undefined
        }),
      [open, onClick]
    );
    const bodyClass = useMemo(
      () =>
        classNames('accordion__body', {
          'accordion__body-disabled': disabled
        }),
      [disabled]
    );

    const children = useMemo(() => props.children, [props.children]);

    useEffect(() => {
      if (initialOpenValue && !isInitialized) {
        setOpen(initialOpenValue);
        setIsInitialized(true);
      }
      if (isOpen !== open && isOpen !== undefined) {
        setOpen(isOpen);
      }
    }, [isOpen, open, setOpen, initialOpenValue, isInitialized]);

    return (
      <AccordionWrapper className={classes} headerColor={headerColor} headerTextColor={headerTextColor}>
        <div className="accordion__header">
          <div className="accordion__header_content" onClick={() => (onClick ? onClick() : setOpen(!open))}>
            <p className="accordion__title">{props.title}</p>
            {props.headerComponent && <div className="header-component">{props.headerComponent}</div>}
          </div>
          {props.trailingComponent && <div className="trailing-component">{props.trailingComponent}</div>}
          <i className="arrow" onClick={() => (onClick ? onClick() : setOpen(!open))}></i>
        </div>
        <fieldset disabled={disabled} className={bodyClass} hidden={!open}>
          {children}
        </fieldset>
      </AccordionWrapper>
    );
  }
);

const AccordionWrapper = styled.div<{ headerColor?: string; headerTextColor?: string }>`
  &.accordion {
    .accordion__header {
      user-select: none;
      background: ${props => props.headerColor || props.theme.color.secondary};
      padding: 0 0 3px 0;
      border-radius: 3px;
      margin-top: 8px;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .accordion__header_content {
        width: 100%;
        cursor: pointer;
        .accordion__title {
          font-weight: bold;
          user-select: none;
          color: ${props => props.headerTextColor || props.theme.color.primary};
          margin: 0 16px 0 0;
          font-size: 14px;
          padding: 12px 12px 9px 12px;
        }
        .header-component {
          white-space: pre-line;
          padding: 0 12px;
        }
      }
      .trailing-component {
        display: flex;
        align-items: center;
        margin-right: 48px;
      }
      i.arrow {
        border: solid ${props => props.headerTextColor || props.theme.color.primary};
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        position: absolute;
        right: 18px;
        top: 15px;
        transition: transform 100ms ease-in-out;
        cursor: pointer;
      }
    }
    .accordion__body {
      margin-top: 8px;
      padding: 16px;
      background-color: #f9f9f9;
      &.accordion__body-disabled {
        background-color: #dcdcdc;
        pointer-events: none;
      }
      &[hidden] {
        padding: 0;
        margin: 0;
      }
    }
    &.hide {
      .accordion__header i.arrow {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 18px;
      }
    }
    &.clickable .accordion__header i.arrow {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
`;
