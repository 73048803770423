import { StoreAction } from 'constants/store';
import { ActionType, StoreModal } from 'models/store';
import { ModalType } from 'constants/form';
import { ModalProps } from 'components';

const { MODAL } = StoreAction;

const INITIAL_STATE: StoreModal = {
  data: []
};

function modalReducer(state = INITIAL_STATE, action: ActionType): StoreModal {
  switch (action.type) {
    case MODAL.REQUEST:
      const { options, type, index = -1 } = action.payload;
      let data: ModalProps[] = [];
      if (type === ModalType.Push) {
        data = [
          ...state.data,
          {
            ...options,
            visible: true
          }
        ];
      } else if (type === ModalType.Replace) {
        data = [
          {
            ...options,
            visible: true
          }
        ];
      } else if (index >= 0) {
        data = state.data.filter((_, i) => i !== index);
      }
      return {
        ...state,
        data
      };
    default:
      return state;
  }
}

export default modalReducer;
