import { CircularProgress } from '@material-ui/core';
import { Pagination as MatPagination } from '@material-ui/lab';
import { styled } from 'components/Theme';
import { PaginationHook } from 'hooks';
import React from 'react';

interface Props {
  pagination: PaginationHook;
  loading?: boolean;
  fetching?: boolean;
}

export const Pagination: React.FC<Props> = props => {
  const { pagination, setPage } = props.pagination;
  if ((pagination.totalPage ?? 0) < 1) return null;
  return (
    <PaginationWrapper>
      {props.fetching && <CircularProgress size="20px" style={{ marginRight: '16px' }} />}
      <MatPagination
        page={pagination.page}
        disabled={props.loading}
        count={pagination.totalPage}
        variant="outlined"
        shape="rounded"
        onChange={(_: any, page: number) => setPage(page)}
      />
    </PaginationWrapper>
  );
};

const PaginationWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
