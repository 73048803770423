interface RequestFilterParams {
  id?: number | string;
  patient_health_record_id?: number;
  patient_id?: number;
  clinic_id?: number;
  type?: string;
  patient_limit?: number;
  active?: boolean;
  owner_id?: number;
  owner_type?: string;
  data_type?: boolean;
  followup_id?: number;
  submission_form_id?: number;
}

function parseParams(options: any) {
  if (!options) {
    return '';
  }
  const tmp = Object.entries(options)
    .filter(opt => opt[1] !== undefined && opt[1] !== null)
    .map(opt => `${opt[0]}=${opt[1]}`)
    .reduce((acc, cur) => `${acc}&${cur}`, '');
  return `?${tmp.startsWith('&') ? tmp.substr(1) : tmp}`;
}

function createEndpoint(name: string, url: string) {
  return {
    name,
    url
  };
}

export const ACCOUNT = {
  PATIENT_PERMISSION: createEndpoint('patientPermission', '/api/doctor/v3/account/patient_permission')
};

export const PATIENT_RESPONSE = {
  BASE: createEndpoint('patientResponse', '/api/doctor/v3/patient_response')
};

export const PHR = {
  ADVERSE_EVENT: createEndpoint('adverseEvent', '/api/doctor/v3/phr/adverse_event'),
  ADVERSE_EVENT_MULTIPLE: createEndpoint('adverseEvent', '/api/doctor/v3/phr/adverse_event/multiple')
};

export const BILLING = {
  PAYMENT_OPTION: {
    key: createEndpoint('paymentOption', '/api/doctor/v2/billing/payment_option'),
    parse: (options: RequestFilterParams) =>
      createEndpoint('paymentOption', `/api/doctor/v2/billing/payment_option${parseParams(options)}`)
  },
  INVOICE: {
    SEND_EMAIL: createEndpoint('sendEmailInvoice', '/api/doctor/v2/billing/invoice/send_email')
  }
};

export const ECONSULT = {
  ROOM: {
    key: createEndpoint('econsultRoom', '/api/doctor/v3/econsult_room')
  }
};
