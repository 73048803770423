import { http } from 'common/services';
import { ENDPOINTS } from 'defaults';

const { MEDICATION, MEDICATION_CONTROLLED } = ENDPOINTS.REFERENCE.SEARCH;

export const searchMedication = (term: string, type: 'generic' | 'brand', controlled?: boolean) => {
  const payload = {
    query: {
      multi_match: {
        query: term,
        fields: [`${type}_name.autocomplete`]
      }
    }
  };
  const endpoint = controlled ? MEDICATION_CONTROLLED : MEDICATION;
  return http.post(endpoint.name, endpoint.url, payload);
};
