import { http } from 'common/services';
import { ENDPOINTS } from 'defaults';
import { formatDate } from 'common/services';
import {
  _ChemotherapyTreatment,
  _HormonalTherapyTreatment,
  _RadiationTreatment,
  _TargetedTherapyTreatment,
  _Treatment,
  _TreatmentSurgery
} from 'models/endpoint';
import { Chemotherapy, DEFAULT_CHEMOTHERAPY } from 'pages/Patient/Treatment/Chemotherapies';
import { HormonalTherapy, DEFAULT_HORMONAL_THERAPY } from 'pages/Patient/Treatment/HormonalTherapies';
import { Radiation, DEFAULT_RADIATION } from 'pages/Patient/Treatment/Radiations';
import { Surgery, DEFAULT_SURGERY } from 'pages/Patient/Treatment/Surgeries';
import { TargetedTherapy, DEFAULT_TARGETED_THERAPY } from 'pages/Patient/Treatment/TargetedTherapies';
import { Summary } from 'pages/Patient/Treatment/TreatmentSummary';
import { sortByKey } from 'common/transformer';

const {
  SURGERY,
  RADIATION,
  CHEMOTHERAPY,
  HORMONAL_THERAPY,
  TARGETED_THERAPY
} = ENDPOINTS.DOCTOR.PATIENT.TREATMENT_RECORDS;

const isFieldsNotEmpty = (data: any, fields: string[]) => {
  if (data) {
    for (let field of fields) {
      if (data[field]) {
        return true;
      }
    }
  }
  return false;
};

const saveSurgeriesForPatient = (patientId: number, surgeries: Surgery[]) => {
  const promises = surgeries
    .filter(item =>
      isFieldsNotEmpty(item, ['remarks', 'surgery_date', 'surgical_procedure', 'record_type', 'institution'])
    )
    .map(surgery => {
      const isUpdate = typeof surgery.id !== 'undefined';
      const endpoint = isUpdate ? SURGERY.PUT : SURGERY.POST;
      const method = isUpdate ? http.put : http.post;
      return method(endpoint.name, endpoint.url, Object.assign(surgery, { patient_id: patientId }));
    });
  return Promise.all(promises);
};

const deleteSurgeriesFromPatientTreatmentSummary = (patientId: number, surgeries: Surgery[]) => {
  const promises = surgeries.map(surgery => {
    const endpoint = SURGERY.DELETE;
    return http.delete(endpoint.name, endpoint.url, { id: surgery.id, patient_id: patientId });
  });
  return Promise.all(promises);
};

const saveRadiationsForPatient = (patientId: number, radiations: Radiation[]) => {
  const promises = radiations
    .filter(item =>
      isFieldsNotEmpty(item, ['body_area', 'frequency', 'start_date', 'end_date', 'record_type', 'institution'])
    )
    .map(radiation => {
      const isUpdate = typeof radiation.id !== 'undefined';
      const endpoint = isUpdate ? RADIATION.PUT : RADIATION.POST;
      const method = isUpdate ? http.put : http.post;
      return method(endpoint.name, endpoint.url, Object.assign(radiation, { patient_id: patientId }));
    });
  return Promise.all(promises);
};

const deleteRadiationsFromPatientTreatmentSummary = (patientId: number, radiations: Radiation[]) => {
  const promises = radiations.map(radiation => {
    const endpoint = RADIATION.DELETE;
    return http.delete(endpoint.name, endpoint.url, { id: radiation.id, patient_id: patientId });
  });
  return Promise.all(promises);
};

const saveChemotherapiesForPatient = (patientId: number, chemotherapies: Chemotherapy[]) => {
  const promises = chemotherapies
    .filter(item => isFieldsNotEmpty(item, ['date_from', 'date_to', 'regimen', 'cycles', 'institution']))
    .map(chemotherapy => {
      const isUpdate = typeof chemotherapy.id !== 'undefined';
      const endpoint = isUpdate ? CHEMOTHERAPY.PUT : CHEMOTHERAPY.POST;
      const method = isUpdate ? http.put : http.post;
      return method(endpoint.name, endpoint.url, Object.assign(chemotherapy, { patient_id: patientId }));
    });
  return Promise.all(promises);
};

const deleteChemotherapiesFromPatientTreatmentSummary = (patientId: number, chemotherapies: Chemotherapy[]) => {
  const promises = chemotherapies.map(chemotherapy => {
    const endpoint = CHEMOTHERAPY.DELETE;
    return http.delete(endpoint.name, endpoint.url, { id: chemotherapy.id, patient_id: patientId });
  });
  return Promise.all(promises);
};

const saveHormonalTherapiesForPatient = (patientId: number, therapies: HormonalTherapy[]) => {
  const promises = therapies
    .filter(item => isFieldsNotEmpty(item, ['date_from', 'date_to', 'regimen', 'duration', 'institution']))
    .map(therapy => {
      const isUpdate = typeof therapy.id !== 'undefined';
      const endpoint = isUpdate ? HORMONAL_THERAPY.PUT : HORMONAL_THERAPY.POST;
      const method = isUpdate ? http.put : http.post;
      return method(endpoint.name, endpoint.url, Object.assign(therapy, { patient_id: patientId }));
    });
  return Promise.all(promises);
};

const deleteHormonalTherapiesFromPatientTreatmentSummary = (patientId: number, therapies: HormonalTherapy[]) => {
  const promises = therapies.map(therapy => {
    const endpoint = HORMONAL_THERAPY.DELETE;
    return http.delete(endpoint.name, endpoint.url, { id: therapy.id, patient_id: patientId });
  });
  return Promise.all(promises);
};

const saveTargetedTherapiesForPatient = (patientId: number, therapies: TargetedTherapy[]) => {
  const promises = therapies
    .filter(item => isFieldsNotEmpty(item, ['date_from', 'date_to', 'record_type']))
    .map(therapy => {
      const isUpdate = typeof therapy.id !== 'undefined';
      const endpoint = isUpdate ? TARGETED_THERAPY.PUT : TARGETED_THERAPY.POST;
      const method = isUpdate ? http.put : http.post;
      return method(endpoint.name, endpoint.url, Object.assign(therapy, { patient_id: patientId }));
    });
  return Promise.all(promises);
};

const deleteTargetedTherapiesFromPatientTreatmentSummary = (patientId: number, therapies: TargetedTherapy[]) => {
  const promises = therapies.map(therapy => {
    const endpoint = TARGETED_THERAPY.DELETE;
    return http.delete(endpoint.name, endpoint.url, { id: therapy.id, patient_id: patientId });
  });
  return Promise.all(promises);
};

export const savePatientTreatmentSummary = async (patientId: number, summary: Summary) => {
  await saveSurgeriesForPatient(patientId, summary.surgeries);
  await saveRadiationsForPatient(patientId, summary.radiations);
  await saveChemotherapiesForPatient(patientId, summary.chemotherapies);
  await saveHormonalTherapiesForPatient(patientId, summary.hormonal_therapies);
  await saveTargetedTherapiesForPatient(patientId, summary.targeted_therapies);
};

export const deleteTreatmentsFromSummary = async (patientId: number, summary: Summary) => {
  await deleteSurgeriesFromPatientTreatmentSummary(patientId, summary.surgeries);
  await deleteRadiationsFromPatientTreatmentSummary(patientId, summary.radiations);
  await deleteChemotherapiesFromPatientTreatmentSummary(patientId, summary.chemotherapies);
  await deleteHormonalTherapiesFromPatientTreatmentSummary(patientId, summary.hormonal_therapies);
  await deleteTargetedTherapiesFromPatientTreatmentSummary(patientId, summary.targeted_therapies);
};

const getSurgeryInfo = (surgery: _TreatmentSurgery): Surgery => {
  return {
    id: surgery.id,
    surgery: surgery.details.surgery || '',
    surgery_date: surgery.details.surgery_date ? formatDate(new Date(surgery.details.surgery_date), 'yyyy-MM-dd') : '',
    record_type: surgery.details.record_type || '',
    institution: surgery.details.institution || ''
  };
};

const getRadiationInfo = (radiation: _RadiationTreatment): Radiation => {
  return {
    id: radiation.id,
    radiation: radiation.details.radiation || '',
    start_date: radiation.details.start_date ? formatDate(new Date(radiation.details.start_date), 'yyyy-MM-dd') : '',
    end_date: radiation.details.end_date ? formatDate(new Date(radiation.details.end_date), 'yyyy-MM-dd') : '',
    record_type: radiation.details.record_type || '',
    institution: radiation.details.institution || ''
  };
};

const getChemotherapyInfo = (chemotherapy: _ChemotherapyTreatment): Chemotherapy => {
  return {
    id: chemotherapy.id,
    chemotherapy: chemotherapy.details.chemotherapy || '',
    regimen: chemotherapy.details.regimen || '',
    cycles: chemotherapy.details.cycles || '',
    date_from: chemotherapy.details.date_from ? formatDate(new Date(chemotherapy.details.date_from), 'yyyy-MM-dd') : '',
    date_to: chemotherapy.details.date_to ? formatDate(new Date(chemotherapy.details.date_to), 'yyyy-MM-dd') : '',
    institution: chemotherapy.details.institution || ''
  };
};

const getHormonalTherapyInfo = (therapy: _HormonalTherapyTreatment): HormonalTherapy => {
  return {
    id: therapy.id,
    hormonal_therapy: therapy.details.hormonal_therapy || '',
    regimen: therapy.details.regimen || '',
    duration: therapy.details.duration || '',
    date_from: therapy.details.date_from ? formatDate(new Date(therapy.details.date_from), 'yyyy-MM-dd') : '',
    date_to: therapy.details.date_to ? formatDate(new Date(therapy.details.date_to), 'yyyy-MM-dd') : '',
    institution: therapy.details.institution || ''
  };
};

const getTargetedTherapyInfo = (therapy: _TargetedTherapyTreatment): TargetedTherapy => {
  return {
    id: therapy.id,
    targeted_therapy: therapy.details.targeted_therapy || '',
    date_from: therapy.details.date_from ? formatDate(new Date(therapy.details.date_from), 'yyyy-MM-dd') : '',
    date_to: therapy.details.date_to ? formatDate(new Date(therapy.details.date_to), 'yyyy-MM-dd') : '',
    record_type: therapy.details.record_type || ''
  };
};

export const getSummaryFromTreatment = (summary: _Treatment): Summary => {
  return {
    surgeries:
      summary.surgeries.length > 0 ? sortByKey(summary.surgeries.map(getSurgeryInfo), 'id') : [DEFAULT_SURGERY],
    radiations:
      summary.radiations.length > 0 ? sortByKey(summary.radiations.map(getRadiationInfo), 'id') : [DEFAULT_RADIATION],
    chemotherapies:
      summary.chemotherapies.length > 0
        ? sortByKey(summary.chemotherapies.map(getChemotherapyInfo), 'id')
        : [DEFAULT_CHEMOTHERAPY],
    hormonal_therapies:
      summary.hormonal_therapies.length > 0
        ? sortByKey(summary.hormonal_therapies.map(getHormonalTherapyInfo), 'id')
        : [DEFAULT_HORMONAL_THERAPY],
    targeted_therapies:
      summary.targeted_therapies.length > 0
        ? sortByKey(summary.targeted_therapies.map(getTargetedTherapyInfo), 'id')
        : [DEFAULT_TARGETED_THERAPY]
  };
};

export const flattenTreatmentDetails = (treatmentRecord: _Treatment) => {
  return {
    surgeries: (treatmentRecord.surgeries || []).map((item: any) => ({
      id: item.id,
      ...item.details
    })),
    radiations: (treatmentRecord.radiations || []).map((item: any) => ({
      id: item.id,
      ...item.details
    })),
    chemotherapies: (treatmentRecord.chemotherapies || []).map((item: any) => ({
      id: item.id,
      ...item.details
    })),
    hormonal_therapies: (treatmentRecord.hormonal_therapies || []).map((item: any) => ({
      id: item.id,
      ...item.details
    })),
    targeted_therapies: (treatmentRecord.targeted_therapies || []).map((item: any) => ({
      id: item.id,
      ...item.details
    }))
  };
};
