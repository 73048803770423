import io from 'socket.io-client';
import { SOCKET_URL } from 'constants/session';

export let socket: SocketIOClient.Socket | null = null;

export const initSocket = (payload?: any, onLocationChange?: any) => {
  if (socket && socket.connected) socket.close();
  socket = io.connect(SOCKET_URL);
  socket.on('connect', () => {
    console.log('WebSocket: Connected');
    socket && payload && socket.emit('authentication', payload);
  });
  socket.on('disconnect', (reason: any) => {
    console.log(`WebSocket: Disconnected, ${reason}`);
  });
  socket.on('locationChange', (data: any[]) => {
    onLocationChange && onLocationChange(data);
  });
};
