import i18n from 'i18n';

const MESSAGES = {
  appointment: {
    create: {
      success: i18n.t('msg_success_appointment_created', { ns: 'success_error_messages' })
    },
    cancel: {
      success: 'Appointment is cancelled.'
    }
  },
  patient: {
    create: {
      inProgress: 'Creating new patient...',
      success: 'Patient created successfully.'
    },
    update: {
      inProgress: 'Updating profile. Please wait.',
      success: 'Patient updated successfully.'
    }
  },
  encounter: {
    readOnly: 'This page is currently on read-only mode. Changes made will not be saved'
  }
};

export default MESSAGES;
