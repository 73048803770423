import { DROPDOWN, FieldDefinitionType, SWITCH } from './constants/mappings';

const TEXT_AREA_FIELDS = ['diagnosis', 'gross_description', 'microscopic_description', 'remarks'];

export function shouldBeTextArea(field: FieldDefinitionType) {
  return TEXT_AREA_FIELDS.indexOf(field.name) !== -1;
}

export function shouldBeToggle(field: FieldDefinitionType) {
  return !!field.type && field.type === SWITCH;
}

export function shouldBeDropdown(field: FieldDefinitionType) {
  return !!field.type && field.type === DROPDOWN;
}

export const WITH_REFERENCE_VALUE_AND_UNIT = {
  ABG: true,
  'Bleeding Parameter': true,
  Chemistry: true,
  'Lipid Profile': true,
  Culture: false,
  Fecalysis: false,
  Hematology: true,
  Imaging: false,
  Urinalysis: true,
  Thyroid: true,
  'Tumor Markers': true,
  Histopathology: false,
  'Hormone Test': true,
  'Lupus Panel': true,
  Hepatitis: false,
  'Arthritis Panel': true,
  'Coagulation Disease': false,
  Lymphoma: false,
  'AA PNH MDS': false,
  Leukemia: false,
  'Myeloproliferative Neoplasm': false,
  'Platelet Disorder': false,
  'Plasma Cell Disorder': false,
  'Liver Function Test': true,
  'Molecular and Immunopathology': false,
  'CKD Panel': true,
  Microbiology: true,
  Microscopy: true,
  Serology: true,
  'Urine Chemistry': true,
  'Cancer Marker': true,
  'Continuous Glucose Monitoring Panel': true
} as { [key: string]: boolean };

interface StringKeyValuePair {
  [key: string]: string;
}

export const MODEL_MAP = {
  ABG: 'PhrTestGroups::Abg',
  'Bleeding Parameter': 'PhrTestGroups::BleedingParameter',
  Chemistry: 'PhrTestGroups::Chemistry',
  Culture: 'PhrTestGroups::Culture',
  Fecalysis: 'PhrTestGroups::Fecalysis',
  Hematology: 'PhrTestGroups::Hematology',
  Histopathology: 'PhrTestGroups::Histopathology',
  'Hormone Test': 'PhrTestGroups::HormoneTest',
  Imaging: 'PhrTestGroups::Imaging',
  'Lipid Profile': 'PhrTestGroups::LipidProfile',
  Thyroid: 'PhrTestGroups::Thyroid',
  'Tumor Markers': 'PhrTestGroups::TumorMarkerGeneral',
  Urinalysis: 'PhrTestGroups::Urinalysis',
  'Lupus Panel': 'PhrTestGroups::LupusPanel',
  Hepatitis: 'PhrTestGroups::Hepatitis',
  'Arthritis Panel': 'PhrTestGroups::ArthritisPanel',
  'AA PNH MDS': 'PhrTestGroups::AaPnhMds',
  'Coagulation Disease': 'PhrTestGroups::Coagulation',
  Leukemia: 'PhrTestGroups::Leukemia',
  Lymphoma: 'PhrTestGroups::Lymphoma',
  'Plasma Cell Disorder': 'PhrTestGroups::PlasmaCellDisorder',
  'Platelet Disorder': 'PhrTestGroups::PlateletDisorder',
  'Myeloproliferative Neoplasm': 'PhrTestGroups::MyeloproliferativeNeoplasm',
  'Liver Function Test': 'PhrTestGroups::LiverFunctionTest',
  'Molecular and Immunopathology': 'PhrTestGroups::Immunology',
  'CKD Panel': 'PhrTestGroups::CkdPanel',
  'Breast Biopsy': 'PhrTestGroups::BreastBiopsy',
  Microscopy: 'PhrTestGroups::Microscopy',
  Microbiology: 'PhrTestGroups::Microbiology',
  Serology: 'PhrTestGroups::Serology',
  Endocrine: 'PhrTestGroups::Endocrine',
  'Urine Chemistry': 'PhrTestGroups::UrineChemistry',
  'Cancer Marker': 'PhrTestGroups::CancerMarker',
  'Continuous Glucose Monitoring Panel': 'PhrTestGroups::CgmPanel'
} as StringKeyValuePair;

export const ModelMapInverse = Object.keys(MODEL_MAP).reduce((IndexById: StringKeyValuePair, type: string) => {
  return {
    ...IndexById,
    [MODEL_MAP[type]]: type
  };
}, {} as StringKeyValuePair);

export enum LabType {
  ABG = 'PhrTestGroups::Abg',
  BleedingParameter = 'PhrTestGroups::BleedingParameter',
  Chemistry = 'PhrTestGroups::Chemistry',
  CKDPanel = 'PhrTestGroups::CkdPanel',
  Culture = 'PhrTestGroups::Culture',
  Fecalysis = 'PhrTestGroups::Fecalysis',
  Hematology = 'PhrTestGroups::Hematology',
  Histopathology = 'PhrTestGroups::Histopathology',
  HormoneTest = 'PhrTestGroups::HormoneTest',
  Imaging = 'PhrTestGroups::Imaging',
  LipidProfile = 'PhrTestGroups::LipidProfile',
  Thyroid = 'PhrTestGroups::Thyroid',
  TumorMarkers = 'PhrTestGroups::TumorMarkerGeneral',
  Urinalysis = 'PhrTestGroups::Urinalysis',
  LupusPanel = 'PhrTestGroups::LupusPanel',
  Hepatitis = 'PhrTestGroups::Hepatitis',
  ArthritisPanel = 'PhrTestGroups::ArthritisPanel',
  AAPNHMDS = 'PhrTestGroups::AaPnhMds',
  CoagulationDisease = 'PhrTestGroups::Coagulation',
  Leukemia = 'PhrTestGroups::Leukemia',
  Lymphoma = 'PhrTestGroups::Lymphoma',
  PlasmaCellDisorder = 'PhrTestGroups::PlasmaCellDisorder',
  PlateletDisorder = 'PhrTestGroups::PlateletDisorder',
  MyeloproliferativeNeoplasm = 'PhrTestGroups::MyeloproliferativeNeoplasm',
  LiverFunctionTest = 'PhrTestGroups::LiverFunctionTest',
  MolecularAndImmunopathology = 'PhrTestGroups::Immunology',
  BreastBiopsy = 'PhrTestGroups::BreastBiopsy',
  Microscopy = 'PhrTestGroups::Microscopy',
  UrineChemistry = 'PhrTestGroups::UrineChemistry',
  Serology = 'PhrTestGroups::Serology',
  Endocrine = 'PhrTestGroups::Endocrine',
  Microbiology = 'PhrTestGroups::Microbiology',
  CancerMarker = 'PhrTestGroups::CancerMarker',
  CGM = 'PhrTestGroups::CgmPanel'
}
