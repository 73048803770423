import React from 'react';
import { styled } from 'components/Theme';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

export default function PageNotFound() {
  return (
    <Wrapper>
      <h1>Page Not Found</h1>
    </Wrapper>
  );
}
