import React from 'react';
import { FlagProvider } from '@unleash/proxy-client-react';

const config = {
  url: '/proxy',
  clientKey: import.meta.env.VITE_CLIENT_KEY ?? '',
  refreshInterval: 15,
  environment: import.meta.env.VITE_NODE_ENV ?? 'local'
};

export const FeatureFlag: React.FC<{ appName: string; children?: any }> = ({ appName, children }) => {
  return (
    <FlagProvider
      config={{ ...config, url: `${window.location.protocol}//${window.location.host}${config.url}`, appName }}
    >
      {children}
    </FlagProvider>
  );
};
