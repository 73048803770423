import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import classNames from 'classnames';
import { styled } from 'components/Theme';
import { FormHelperProps } from 'hooks/form';
import React from 'react';
import { Controller } from 'react-hook-form';

interface DatePickerProps extends Partial<KeyboardDatePickerProps> {
  name: string;
  helpers?: FormHelperProps;
}

export const Datepicker: React.FC<DatePickerProps> = React.memo(
  ({ className, helpers, onChange, onFocus, hidden, format = 'MM/dd/yyyy', ...props }) => {
    const classes = classNames(`c-datepicker${hidden ? ' hidden' : ''}`, className);
    if (!helpers) {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePickerWrapper
            {...props}
            onChange={date => onChange && onChange(date)}
            value={props.value || null}
            className={classes}
            inputVariant="outlined"
            margin="normal"
            format={format}
            hidden={hidden}
          />
        </MuiPickersUtilsProvider>
      );
    }
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Controller
          {...props}
          onBlur={args => args[0]}
          value={props.value}
          as={KeyboardDatePickerWrapper}
          name={props.name}
          control={helpers?.control}
          className={classes}
          inputVariant="outlined"
          margin="normal"
          format={format}
          hidden={hidden}
        />
      </MuiPickersUtilsProvider>
    );
  }
);

const KeyboardDatePickerWrapper = styled(KeyboardDatePicker)`
  &.hidden {
    margin: 0 !important;
    padding: 0 !important;
    height: 0 !important;
    width: 0 !important;
    .MuiInputBase-root,
    input {
      margin: 0 !important;
      padding: 0 !important;
      height: 0 !important;
      width: 0 !important;
    }
    fieldset,
    button {
      display: none;
    }
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
    .MuiInputAdornment-positionEnd {
      margin-left: 0;
    }
  }
  .MuiFormHelperText-root {
    position: absolute;
    top: calc(100% + 2px);
    left: 5px;
    margin: 0;
  }
  &.MuiFormControl-marginNormal {
    margin-top: 16px;
    margin-bottom: 12px;
  }
`;
