import { createActionTypes } from 'services/utils.service';

export const StoreAction = {
  LOGIN: createActionTypes(1, 'login'),
  LOGOUT: createActionTypes(2, 'logout'),
  SIGNUP: createActionTypes(3, 'signup'),
  SESSION: {
    ASSUME_MEMBER: createActionTypes(4.1, 'assume member'),
    ACTIVE_ACCOUNT: createActionTypes(4.2, 'active account'),
    BLOCK_REDIRECT: createActionTypes(4.3, 'block redirect')
  },
  USER: {
    GET_CURRENT: createActionTypes(11.1, 'get current user'),
    UPDATE_CURRENT: createActionTypes(11.2, 'update current user'),
    ONBOARD: createActionTypes(12, 'onboarding current user'),
    UPDATE_AVATAR: createActionTypes(13, 'update current user avatar'),
    CHANGE_PASSWORD: createActionTypes(14, 'change user password'),
    UPDATE_FEEDBACK_DATE: createActionTypes(15, 'update current user feedback date'),
    UPDATE_REMINDER_DATE: createActionTypes(16, 'update current reminder date')
  },
  SPECIALIZATION: {
    SEARCH: createActionTypes(21, 'search specialization')
  },
  DOCTOR: {
    GET_PROFILE: createActionTypes(31.1, 'get doctor profile'),
    UPDATE_PROFILE: createActionTypes(31.2, 'update doctor profile'),
    GET_CLINIC: createActionTypes(32, 'get doctor clinic'),
    GET_CLINIC_HEADER: createActionTypes(33, 'get doctor clinic header'),
    UPDATE_HMO: createActionTypes(34, 'update hmo list')
  },
  SECRETARY: {
    GET_DASHBOARD: createActionTypes(41.1, 'get secretary dashboard'),
    UPDATE_PROFILE: createActionTypes(41.2, 'update secretary profile'),
    GET_PERMISSIONS: createActionTypes(42.1, 'get secretary permissions')
  },
  NAVIGATOR: {
    GET_DASHBOARD: createActionTypes(51.1, 'get navigator dashboard'),
    UPDATE_PROFILE: createActionTypes(51.2, 'update navigator profile')
  },
  CLINIC: {
    SEARCH: createActionTypes(61, 'search clinic'),
    SET_ACTIVE: createActionTypes(62, 'set active clinic')
  },
  APPOINTMENT: {
    SET_SELECTED_DATE: createActionTypes(71, 'set selected date'),
    GET: {
      VIA: {
        DATE_AND_CLINIC: createActionTypes(72, 'get appointments via date and clinic')
      }
    },
    PATIENT: {
      START: createActionTypes(73.1, 'start patient appointment'),
      ADD_REFERENCE: createActionTypes(73.2, 'add reference for patient')
    },
    MED_REP: {
      ADD_MEDREP_REFERENCE: createActionTypes(73.2, 'add reference for medrep')
    },
    MODAL: {
      HISTORICAL_FILES: {
        TOGGLE: createActionTypes(75, 'toggle historical files modal')
      }
    },
    CREATE: createActionTypes(76.1, 'create new appointment'),
    CREATE_WALKIN: createActionTypes(76.2, 'create new walkin appointment'),
    CANCEL: createActionTypes(77, 'cancel appointment')
  },
  PATIENT: {
    SEARCH_HMO: createActionTypes(81, 'search hmo'),
    RECORDS: createActionTypes(81, 'get patient records'),
    TREATMENT_PLANS: createActionTypes(81.2, 'get treatment plans'),
    CREATE: {
      NEW: createActionTypes(82.1, 'create new patient'),
      SUMMARY: createActionTypes(82.2, 'create patient summary'),
      VACCINATIONS: createActionTypes(82.3, 'create patient vaccinations')
    },
    PROFILE: {
      GET: createActionTypes(83.1, 'get patient profile'),
      UPDATE: createActionTypes(83.2, 'update patient profile'),
      RESET: createActionTypes(83.3, 'reset patient profile')
    },
    AVATAR: {
      UPDATE: createActionTypes(84, 'update patient avatar')
    },
    GET: {
      INFO: createActionTypes(85.1, 'get patient information'),
      LATEST_HEALTH_RECORD: createActionTypes(85.2, 'get patient latest health record'),
      TREATMENT_PLANS: createActionTypes(85.3, 'get patient treatment plans')
    },
    INFO: {
      AVATAR: {
        UPDATE: createActionTypes(86.6, 'update patient information avatar')
      }
    },
    SHARE_PATIENT: {
      POST: createActionTypes(87.1, 'share patient records')
    },
    SHARE_PATIENT_SOLO: {
      POST: createActionTypes(88.1, 'share patient records solo')
    }
  },
  PHR: {
    NEW_ENCOUNTER: createActionTypes(90, 'create new encounter'),
    GET_ENCOUNTER: createActionTypes(91, 'get encounter'),
    SAVE_ENCOUNTER: createActionTypes(92, 'save encounter'),
    GET_PROCEDURE: createActionTypes(91.2, 'get encounter procedure'),
    SAVE_PROCEDURE: createActionTypes(91.3, 'save encounter procedures'),
    LABS: {
      ADD: createActionTypes(93.1, 'add encounter lab'),
      REMOVE: createActionTypes(93.2, 'remove encounter lab'),
      UPDATE: createActionTypes(93.3, 'update encounter lab'),
      GET_ALL: createActionTypes(93.4, 'get encounter labs'),
      GET_ALL_LABS: createActionTypes(93.5, 'get all labs by patient id'),
      UPDATE_ATTACHMENTS: createActionTypes(94, 'update lab attachments')
    },
    GET_STAGING_FORM: {
      BY_ENCOUNTER: createActionTypes(95.1, 'get encounter staging forms'),
      UPDATE_LIST: createActionTypes(95.2, 'update encounter staging forms list locally')
    },
    MEDICAL_CERTIFICATE: createActionTypes(104, 'medical_certificate')
  },
  PEEK: createActionTypes(100, 'peek'),
  MODAL: createActionTypes(101, 'modal'),
  DIAGNOSIS: createActionTypes(102, 'search diagnosis'),
  PRESCRIPTION: {
    SEARCH_GENERIC: createActionTypes(103.1, 'search generic'),
    SEARCH_BRAND: createActionTypes(103.2, 'search brand')
  }
};
