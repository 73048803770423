import { StoreAction } from 'constants/store';
import { ActionType, StoreClinic, ClinicIndexById } from 'models/store';
import { transformIncomingClinicsData, transformIncomingCliniHeaderData } from 'common/transformer';
import { Clinic } from 'models';

const { GET_CLINIC, GET_CLINIC_HEADER } = StoreAction.DOCTOR;
const { SEARCH: SEARCH_CLINIC, SET_ACTIVE } = StoreAction.CLINIC;

const INITIAL_STATE: StoreClinic = {
  list: [],
  search: [],
  indexById: null,
  header: undefined,
  activeClinicId: undefined,
  transaction: {
    list: {
      inProgress: false
    },
    header: {
      inProgress: false
    },
    search: {
      inProgress: false
    }
  }
};

function clinicReducer(state = INITIAL_STATE, action: ActionType): StoreClinic {
  switch (action.type) {
    case GET_CLINIC.REQUEST:
      return {
        ...state,
        list: [],
        transaction: {
          ...state.transaction,
          list: {
            inProgress: true
          }
        }
      };
    case GET_CLINIC.FAILED:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          list: {
            inProgress: false,
            error: action.payload
          }
        }
      };
    case GET_CLINIC.SUCCESS: {
      const list = transformIncomingClinicsData(action.payload);
      const activeClinicId = list.length > 0 ? list[0]['id'] : undefined;
      const indexById = list.reduce((clinicIndexById: ClinicIndexById, clinic: Clinic) => {
        return {
          ...clinicIndexById,
          [clinic.id]: clinic
        };
      }, {});
      return {
        ...state,
        list,
        indexById,
        activeClinicId,
        transaction: {
          ...state.transaction,
          list: {
            inProgress: false
          }
        }
      };
    }
    case GET_CLINIC_HEADER.REQUEST:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          header: {
            inProgress: true
          }
        }
      };
    case GET_CLINIC_HEADER.FAILED:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          header: {
            inProgress: false,
            error: action.payload
          }
        }
      };
    case GET_CLINIC_HEADER.SUCCESS:
      return {
        ...state,
        header: transformIncomingCliniHeaderData(action.payload),
        transaction: {
          ...state.transaction,
          header: {
            inProgress: false
          }
        }
      };
    case SEARCH_CLINIC.REQUEST:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          search: {
            inProgress: true
          }
        }
      };
    case SEARCH_CLINIC.FAILED:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          search: {
            inProgress: false,
            error: action.payload
          }
        }
      };
    case SEARCH_CLINIC.SUCCESS:
      return {
        ...state,
        search: transformIncomingClinicsData(action.payload),
        transaction: {
          ...state.transaction,
          search: {
            inProgress: false
          }
        }
      };
    case SET_ACTIVE.SUCCESS:
      return {
        ...state,
        activeClinicId: action.payload
      };
    default:
      return state;
  }
}

export default clinicReducer;
