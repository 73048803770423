import { http } from 'common/services';
import { ENDPOINTS } from 'defaults';
import { _SecretaryPermission } from 'models/endpoint';
import { SecretaryPermission } from 'models/secretary';

const { DASHBOARD, GET, POST, DELETE, PERMISSION, MEMBERSHIP } = ENDPOINTS.SECRETARY;

export const getSecretaryDashboard = async () => {
  const endpoint = DASHBOARD;
  return http.get(endpoint.name, endpoint.url);
};

export const getSecretaries = async (activeAccountId?: number) => {
  const endpoint = GET;
  const queryParam = activeAccountId ? `account_id=${activeAccountId}` : '';
  return http.get(endpoint.name, `${endpoint.url}?${queryParam}`);
};

export const addSecretary = async (memberEmail: string) => {
  const endpoint = POST;
  return http.post(endpoint.name, endpoint.url, { member_email: memberEmail });
};

export const removeSecretary = async (memberId: string) => {
  const endpoint = DELETE;
  return http.delete(endpoint.name, endpoint.url, { member_id: memberId });
};

export const getSecretaryPermissions = async (userId?: number) => {
  const endpoint = PERMISSION.GET;
  const params = userId ? `user_id=${userId}` : '';
  return http.get(endpoint.name, `${endpoint.url}?${params}`);
};

export const addSecretaryPermission = async (data: SecretaryPermission) => {
  const endpoint = PERMISSION.POST;
  const payload: _SecretaryPermission = {
    user_id: data.userId,
    secretary__clinic_ids: data.clinicIds,
    secretary__manage_clinics: data.accessManageClinics,
    secretary__create_patient: data.accessCreatePatient,
    secretary__edit_patient: data.accessEditPatient,
    secretary__crud_appointment: data.accessCrudAppointment,
    secretary__crud_encounter: data.accessCrudEncounter,
    secretary__crud_billing: data.accessCrudBilling
  };
  return http.post(endpoint.name, endpoint.url, payload);
};

export const updateSecretaryPermission = async (data: SecretaryPermission) => {
  const endpoint = PERMISSION.PUT;
  const payload: _SecretaryPermission = {
    id: data.id,
    user_id: data.userId,
    secretary__clinic_ids: data.clinicIds,
    secretary__manage_clinics: data.accessManageClinics,
    secretary__create_patient: data.accessCreatePatient,
    secretary__edit_patient: data.accessEditPatient,
    secretary__crud_appointment: data.accessCrudAppointment,
    secretary__crud_encounter: data.accessCrudEncounter,
    secretary__crud_billing: data.accessCrudBilling
  };
  return http.put(endpoint.name, endpoint.url, payload);
};

export const getMembership = async (userId?: number) => {
  const endpoint = MEMBERSHIP.GET;
  const params = userId ? `?user_id=${userId}` : '';
  return http.get(endpoint.name, `${endpoint.url}${params}`);
};

export const updateMembership = async (id: number, active: boolean) => {
  const endpoint = MEMBERSHIP.PUT;
  return http.put(endpoint.name, endpoint.url, { id, active });
};
