import { transformIn } from 'common/transformer';
import { StoreAction } from 'constants/store';
import { User } from 'models';
import {
  ActionType,
  StoreChangePassword,
  StoreCurrentDoctor,
  StoreCurrentUser,
  StoreNavigatorDashboard,
  StoreSecretaryDashboard,
  StoreSecretaryPermission,
  StoreUpdateAvatar,
  StoreUpdateNavigatorProfile,
  StoreUser
} from 'models/store';

const {
  GET_CURRENT,
  UPDATE_CURRENT,
  UPDATE_AVATAR,
  CHANGE_PASSWORD,
  UPDATE_FEEDBACK_DATE,
  UPDATE_REMINDER_DATE
} = StoreAction.USER;
const { GET_PROFILE, UPDATE_PROFILE, UPDATE_HMO } = StoreAction.DOCTOR;
const { SECRETARY, NAVIGATOR } = StoreAction;

const INITIAL_STATE: StoreUser = {
  currentUser: {
    inProgress: false
  },
  currentDoctor: {
    inProgress: false,
    accounts: [],
    identifications: [],
    specializations: [],
    hmos: []
  },
  updateAvatar: {
    inProgress: false
  },
  updateProfile: {
    inProgress: false
  },
  secretaryDashboard: {
    inProgress: false
  },
  navigatorDashboard: {
    inProgress: false
  },
  updateNavigatorProfile: {
    inProgress: false
  },
  changePassword: {
    inProgress: false,
    success: false
  },
  permissions: {
    inProgress: false
  }
};

function getCurrentUser(state = INITIAL_STATE.currentUser, action: ActionType): StoreCurrentUser {
  switch (action.type) {
    case GET_CURRENT.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case GET_CURRENT.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload
      };

    case GET_CURRENT.SUCCESS:
    case UPDATE_AVATAR.SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: transformIn.user(action.payload)
      };
    case UPDATE_FEEDBACK_DATE.REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          nextFeedbackDate: action.payload
        } as User
      };
    case UPDATE_REMINDER_DATE.REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          nextReminderDate: action.payload
        } as User
      };
    default:
      return state;
  }
}

function getCurrentDoctor(state = INITIAL_STATE.currentDoctor, action: ActionType): StoreCurrentDoctor {
  switch (action.type) {
    case SECRETARY.UPDATE_PROFILE.FAILED:
    case UPDATE_CURRENT.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload
      };
    case SECRETARY.UPDATE_PROFILE.SUCCESS:
    case UPDATE_CURRENT.SUCCESS:
    case UPDATE_AVATAR.SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: transformIn.user(action.payload)
      };
    case SECRETARY.UPDATE_PROFILE.REQUEST:
    case GET_PROFILE.REQUEST:
      return {
        ...state,
        data: undefined,
        inProgress: true
      };
    case GET_PROFILE.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload
      };
    case GET_PROFILE.SUCCESS:
    case UPDATE_PROFILE.SUCCESS: {
      const { accounts, identifications, specializations, hmos } = transformIn.profile(action.payload);
      return {
        ...state,
        inProgress: false,
        data: transformIn.user(action.payload.user),
        accounts,
        identifications,
        specializations,
        hmos
      };
    }
    case UPDATE_HMO.SUCCESS: {
      return {
        ...state,
        hmos: action.payload
      };
    }
    default:
      return state;
  }
}

function secretaryDashboardReducers(
  state = INITIAL_STATE.secretaryDashboard,
  action: ActionType
): StoreSecretaryDashboard {
  switch (action.type) {
    case SECRETARY.GET_DASHBOARD.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case SECRETARY.GET_DASHBOARD.SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: transformIn.secretaryDashboard(action.payload)
      };
    case SECRETARY.GET_DASHBOARD.FAILED:
      return {
        ...state,
        inProgress: false
      };
    default:
      return state;
  }
}

function navigatorDashboardReducers(
  state = INITIAL_STATE.navigatorDashboard,
  action: ActionType
): StoreNavigatorDashboard {
  switch (action.type) {
    case NAVIGATOR.GET_DASHBOARD.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case NAVIGATOR.GET_DASHBOARD.SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: transformIn.navigatorDashboard(action.payload)
      };
    case NAVIGATOR.GET_DASHBOARD.FAILED:
      return {
        ...state,
        inProgress: false
      };
    default:
      return state;
  }
}

function updateAvatar(state = INITIAL_STATE.updateAvatar, action: ActionType): StoreUpdateAvatar {
  switch (action.type) {
    case UPDATE_AVATAR.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case UPDATE_AVATAR.SUCCESS:
      return {
        ...state,
        inProgress: false
      };
    case UPDATE_AVATAR.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload
      };
    default:
      return state;
  }
}

function updateProfile(state = INITIAL_STATE.updateProfile, action: ActionType): StoreUpdateAvatar {
  switch (action.type) {
    case UPDATE_PROFILE.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case UPDATE_PROFILE.SUCCESS:
      return {
        ...state,
        inProgress: false
      };
    case UPDATE_PROFILE.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload
      };
    default:
      return state;
  }
}

function updateNavigatorProfileReducer(
  state = INITIAL_STATE.updateProfile,
  action: ActionType
): StoreUpdateNavigatorProfile {
  switch (action.type) {
    case NAVIGATOR.UPDATE_PROFILE.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case NAVIGATOR.UPDATE_PROFILE.SUCCESS:
      return {
        ...state,
        inProgress: false
      };
    case NAVIGATOR.UPDATE_PROFILE.FAILED:
      return {
        ...state,
        inProgress: false
      };
    default:
      return state;
  }
}

function changePasswordReducer(state = INITIAL_STATE.changePassword, action: ActionType): StoreChangePassword {
  switch (action.type) {
    case CHANGE_PASSWORD.REQUEST:
      return {
        ...state,
        inProgress: true,
        success: false
      };
    case CHANGE_PASSWORD.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload,
        success: false
      };
    case CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        inProgress: false,
        success: true
      };
    default:
      return state;
  }
}

function secretaryPermissionReducers(state = INITIAL_STATE.permissions, action: ActionType): StoreSecretaryPermission {
  switch (action.type) {
    case SECRETARY.GET_PERMISSIONS.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case SECRETARY.GET_PERMISSIONS.SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: action.payload
      };
    case SECRETARY.GET_PERMISSIONS.FAILED:
      return {
        ...state,
        inProgress: false
      };
    default:
      return state;
  }
}

export default function reducer(state: StoreUser = INITIAL_STATE, action: ActionType): StoreUser {
  return {
    currentUser: getCurrentUser(state.currentUser, action),
    currentDoctor: getCurrentDoctor(state.currentDoctor, action),
    updateAvatar: updateAvatar(state.updateAvatar, action),
    updateProfile: updateProfile(state.updateProfile, action),
    secretaryDashboard: secretaryDashboardReducers(state.secretaryDashboard, action),
    navigatorDashboard: navigatorDashboardReducers(state.navigatorDashboard, action),
    updateNavigatorProfile: updateNavigatorProfileReducer(state.updateNavigatorProfile, action),
    changePassword: changePasswordReducer(state.changePassword, action),
    permissions: secretaryPermissionReducers(state.permissions, action)
  };
}
