import { FieldArray, FieldArrayRenderProps, Input, Toggle } from 'components/form';
import { YES_NO_OPTIONS } from 'constants/form';
import { useFormSubField } from 'hooks/form';
import isEqual from 'lodash/isEqual';
import { _RadiationInput } from 'models/endpoint';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddItem from '../AddItem';
import RemoveItem from '../RemoveItem';

export type Radiation = _RadiationInput;
export const DEFAULT_RADIATION: Radiation = {
  id: undefined,
  radiation: '',
  record_type: '',
  start_date: '',
  end_date: '',
  institution: ''
};

interface RadiationItemProps {
  index: number;
  helpers: FieldArrayRenderProps;
  showAdd: boolean;
  showRemove: boolean;
  onChangeCb: (data?: any) => void;
}

const RadiationItem: React.FC<RadiationItemProps> = React.memo(
  ({ index, helpers, showAdd, showRemove, onChangeCb }) => {
    const [t] = useTranslation('patient_profile');
    const { getSubName } = useFormSubField('radiations');
    const insert = () => helpers.push(DEFAULT_RADIATION);
    const remove = () => {
      helpers.remove(index);
      onChangeCb();
    };
    return (
      <React.Fragment>
        <div className="flex-row">
          {index === 0 ? (
            <div className="flex-child">
              <Toggle name={getSubName('radiation', index)} items={YES_NO_OPTIONS} onChange={onChangeCb} fullWidth />
            </div>
          ) : null}
          <div className="flex-child" style={{ flexGrow: index === 0 ? 3 : 1 }}>
            <div className="flex-row">
              <div className="flex-child">
                <Input type="date" label={t('from')} name={getSubName('start_date', index)} fullWidth />
              </div>
              <div className="flex-child">
                <Input type="date" label={t('to')} name={getSubName('end_date', index)} fullWidth />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row">
          <Input label={t('type')} name={getSubName('record_type', index)} fullWidth />
        </div>
        <div className="flex-row">
          <Input label={t('institution')} name={getSubName('institution', index)} fullWidth />
        </div>
        <div className="add-multiple">
          {showAdd ? <AddItem onClick={insert} /> : null}
          {showRemove ? <RemoveItem onClick={remove} /> : null}
        </div>
      </React.Fragment>
    );
  }
);

interface Props {
  list: Radiation[];
  onChangeCb: (data?: any) => void;
}

const Radiations: React.FC<Props> = props => {
  const [t] = useTranslation('appointment');
  const { getName } = useFormSubField('radiations');
  const { list, onChangeCb } = props;
  const totalItems = list.length;
  const lastItemIndex = totalItems - 1;
  return (
    <div className="section">
      <label className="sub-header grey">{t('radiation')}</label>
      <FieldArray
        name={getName()}
        render={helpers =>
          list.map((_, index: number) => (
            <RadiationItem
              key={index}
              index={index}
              helpers={helpers}
              showAdd={totalItems === 1 || (totalItems > 1 && lastItemIndex === index)}
              showRemove={index > 0 && lastItemIndex === index}
              onChangeCb={onChangeCb}
            />
          ))
        }
      />
    </div>
  );
};

export default React.memo(Radiations, (prev, next) => isEqual(prev.list, next.list));
