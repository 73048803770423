import { AxiosPromise } from 'axios';
import { http } from 'common/services';
import { sortByKey } from 'common/transformer';
import { ENDPOINTS } from 'defaults';
import { _LabInput, _LabRecord, _TestGroup as _LabRecords } from 'models/endpoint';
import { LabType } from 'partials/labs/helpers';

export function getEncounterLabs(phrId: number): AxiosPromise<_LabRecords> {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.ALL;
  return http.getAll(endpoint.name, `${endpoint.url}?patient_health_record_id=${phrId}`);
}

export function getLabAttachments(labId: number): AxiosPromise {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.ATTACHMENT.GET;
  return http.getAll(endpoint.name, `${endpoint.url}?phr_test_group_id=${labId}`);
}

export function addLabAttachment(labId: number, file: File): AxiosPromise {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.ATTACHMENT.POST;

  let form = new FormData();
  form.append('phr_test_group_id', `${labId}`);
  form.append('file', file);

  return http.post(endpoint.name, endpoint.url, form);
}

export function removeLabAttachment(attachmentId: number, labId: number): AxiosPromise {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.ATTACHMENT.DELETE;
  return http.delete(endpoint.name, endpoint.url, { id: attachmentId, phr_test_group_id: labId });
}

export function addLabAttachments(labId: number, files: Array<File>) {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.ATTACHMENT.POST;
  const promises = files.map((file: File) => {
    let form = new FormData();
    form.append('phr_test_group_id', `${labId}`);
    form.append('file', file);
    return http.post(endpoint.name, endpoint.url, form);
  });

  return Promise.all(promises);
}

export const updateList = (labs: _LabRecords, record: _LabRecord): _LabRecords => {
  const hasUpdate = labs.filter(lab => lab.id === record.id).length > 0;

  if (hasUpdate) return labs.map(lab => (lab.id === record.id ? record : lab));
  return sortByKey([...labs, record], 'id');
};

export function getEncounterLabForPatient(type: string, patientId: number): AxiosPromise {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.GET_ALL_BY_PATIENT_ID.parse({ type, patient_id: patientId });
  return http.get(endpoint.name, endpoint.url);
}

export function addEncounterLab(type: string, form: _LabInput): AxiosPromise | undefined {
  const endpoint = getEndpointForType(type, 'POST');
  if (endpoint) return http.post(endpoint.name, endpoint.url, form);
  return undefined;
}

export function removeEncounterLab(labId: number, phrId: number): AxiosPromise | undefined {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.DELETE_RECORD;
  return http.delete(endpoint.name, endpoint.url, { id: labId, patient_health_record_id: phrId });
}

export function updateEncounterLab(form: _LabInput & { type: string }): AxiosPromise | undefined {
  const endpoint = getEndpointForType(form.type, 'PUT');

  if (endpoint) return http.put(endpoint.name, endpoint.url, form);
  return undefined;
}

export function getAllLabsByPatientID(patientID: number) {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.GET_ALL_BY_PATIENT_ID.parse({ patient_id: patientID });
  if (endpoint) return http.getAll(endpoint.name, endpoint.url);
  return undefined;
}

const getEndpointForType = (type: string, method: 'POST' | 'PUT' | 'DELETE') => {
  console.log('TYPE', type);
  switch (type) {
    case LabType.ABG:
      return ENDPOINTS.PHR.DOCTOR.LABS.ABG[method];
    case LabType.BleedingParameter:
      return ENDPOINTS.PHR.DOCTOR.LABS.BLEEDING_PARAMETER[method];
    case LabType.Chemistry:
      return ENDPOINTS.PHR.DOCTOR.LABS.CHEMISTRY[method];
    case LabType.CKDPanel:
      return ENDPOINTS.PHR.DOCTOR.LABS.CKD_PANEL[method];
    case LabType.Culture:
      return ENDPOINTS.PHR.DOCTOR.LABS.CULTURE[method];
    case LabType.Fecalysis:
      return ENDPOINTS.PHR.DOCTOR.LABS.FECALYSIS[method];
    case LabType.Hematology:
      return ENDPOINTS.PHR.DOCTOR.LABS.HEMATOLOGY[method];
    case LabType.Endocrine:
      return ENDPOINTS.PHR.DOCTOR.LABS.ENDOCRINE[method];
    case LabType.CancerMarker:
      return ENDPOINTS.PHR.DOCTOR.LABS.CANCER_MARKER[method];
    case LabType.Serology:
      return ENDPOINTS.PHR.DOCTOR.LABS.SEROLOGY[method];
    case LabType.UrineChemistry:
      return ENDPOINTS.PHR.DOCTOR.LABS.URINE_CHEMISTRY[method];
    case LabType.Microbiology:
      return ENDPOINTS.PHR.DOCTOR.LABS.MICROBIOLOGY[method];
    case LabType.Microscopy:
      return ENDPOINTS.PHR.DOCTOR.LABS.MICROSCOPY[method];
    case LabType.Histopathology:
      return ENDPOINTS.PHR.DOCTOR.LABS.HISTOPATHOLOGY[method];
    case LabType.HormoneTest:
      return ENDPOINTS.PHR.DOCTOR.LABS.HORMONE_TEST[method];
    case LabType.Imaging:
      return ENDPOINTS.PHR.DOCTOR.LABS.IMAGING[method];
    case LabType.LipidProfile:
      return ENDPOINTS.PHR.DOCTOR.LABS.LIPID_PROFILE[method];
    case LabType.Thyroid:
      return ENDPOINTS.PHR.DOCTOR.LABS.THYROID[method];
    case LabType.TumorMarkers:
      return ENDPOINTS.PHR.DOCTOR.LABS.TUMOR_MARKERS[method];
    case LabType.Urinalysis:
      return ENDPOINTS.PHR.DOCTOR.LABS.URINALYSIS[method];
    case LabType.LupusPanel:
      return ENDPOINTS.PHR.DOCTOR.LABS.LUPUS_PANEL[method];
    case LabType.Hepatitis:
      return ENDPOINTS.PHR.DOCTOR.LABS.HEPATITIS[method];
    case LabType.ArthritisPanel:
      return ENDPOINTS.PHR.DOCTOR.LABS.ARTHRITIS_PANEL[method];
    case LabType.AAPNHMDS:
      return ENDPOINTS.PHR.DOCTOR.LABS.AA_PNH_MDS[method];
    case LabType.CoagulationDisease:
      return ENDPOINTS.PHR.DOCTOR.LABS.COAGULATION_DISEASE[method];
    case LabType.Leukemia:
      return ENDPOINTS.PHR.DOCTOR.LABS.LEUKEMIA[method];
    case LabType.Lymphoma:
      return ENDPOINTS.PHR.DOCTOR.LABS.LYMPHOMA[method];
    case LabType.PlasmaCellDisorder:
      return ENDPOINTS.PHR.DOCTOR.LABS.PLASMA_CELL_DISORDER[method];
    case LabType.PlateletDisorder:
      return ENDPOINTS.PHR.DOCTOR.LABS.PLATELET_DISORDER[method];
    case LabType.MyeloproliferativeNeoplasm:
      return ENDPOINTS.PHR.DOCTOR.LABS.MYELOPROLIFERATIVE_NEOPLASM[method];
    case LabType.LiverFunctionTest:
      return ENDPOINTS.PHR.DOCTOR.LABS.LIVER_FUNCTION_TEST[method];
    case LabType.MolecularAndImmunopathology:
      return ENDPOINTS.PHR.DOCTOR.LABS.IMMUNOLOGY[method];
    case LabType.BreastBiopsy:
      return ENDPOINTS.PHR.DOCTOR.LABS.BREAST_BIOPSY[method];
    case LabType.CGM:
      return ENDPOINTS.PHR.DOCTOR.LABS.CGM[method];
  }
};
