import { ENDPOINTS } from 'defaults';
import { Identification } from 'models';
import { UpdateCurrentUserInput } from 'models/forms/user';
import { transformOut } from '../transformer/user';
import { http } from './http';
import { getLocalStorageData } from './utils';
import * as Sentry from '@sentry/browser';

const { DEFAULT, AVATAR, CHANGE_PASSWORD } = ENDPOINTS.USER;

export async function getCurrentUser(token?: string): Promise<{ data: any }> {
  const { GET } = DEFAULT;
  // TODO: Cache local storage
  const { accessToken, assumeToken } = getLocalStorageData();
  const cacheKey = token || assumeToken || accessToken;
  // attempt to query the session storage cache for the user type.
  // This is low hanging optimization for the most called api endpoint ever.
  const cachedValue = sessionStorage.getItem(accessToken);
  if (cachedValue) {
    const jsonValue = JSON.parse(cachedValue);
    Sentry.setUser({
      email: jsonValue.email
    });
    return jsonValue;
  }
  const response = await http.get(GET.name, GET.url, token);

  sessionStorage.setItem(cacheKey, JSON.stringify({ data: response.data }));

  Sentry.setUser({
    email: response.data.email
  });

  return { data: response.data };
}

export async function updateCurrentUser(payload: UpdateCurrentUserInput) {
  const { PUT } = DEFAULT;

  const response = await http.put(PUT.name, PUT.url, transformOut.updateCurrentUser(payload));

  const { accessToken, assumeToken } = getLocalStorageData();
  sessionStorage.setItem(assumeToken || accessToken, JSON.stringify({ data: response.data }));

  return response;
}

export function updateCurrentUserAvatar(file: File) {
  const endpoint = AVATAR.PUT;
  const payload = transformFileToFormData(file);

  return http.put(endpoint.name, endpoint.url, payload);
}

const transformFileToFormData = (file: File): FormData => {
  let formData = new FormData();
  formData.append('file', file);

  return formData;
};

export function getIdentification(
  idType: 'prc' | 's2' | 'ptr',
  identifications: Identification[] | null | undefined,
  returnString: boolean = true
): any {
  const identifiers = {
    prc: 'PRC No.',
    s2: 'S2 No.',
    ptr: 'PTR No.',
    'PRC No.': 'PRC No.',
    'S2 No.': 'S2 No.',
    'PTR No.': 'PTR No.'
  };

  if (!identifications) return returnString ? '' : null;

  const identificationsByIdType = identifications.filter(i => i.idType === identifiers[idType]);

  if (identificationsByIdType.length === 0) return returnString ? '' : null;

  const identification = identificationsByIdType.slice(-1).find(i => i.idType === identifiers[idType]);

  if (identification) {
    if (returnString) {
      return identification.identifier;
    } else {
      return identification;
    }
  }

  return returnString ? '' : null;
}

export function changePassword(currentPassword: string, newPassword: string) {
  const { PUT: endpoint } = CHANGE_PASSWORD;
  return http.put(
    endpoint.name,
    endpoint.url,
    { current_password: currentPassword, new_password: newPassword },
    getLocalStorageData().accessToken
  );
}

export function update2FA(enable: boolean) {
  const endpoint = DEFAULT.PUT;
  return http.put(endpoint.name, endpoint.url, { enabled_2fa: enable });
}
