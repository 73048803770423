import { StoreAction } from 'constants/store';
import { ActionType, StorePatient, StorePatientProfile } from 'models/store';
import { PatientWithSummary, Gender } from 'models';
import { _PatientProfile } from 'models/endpoint';
import { transformIn, transformInPatient } from 'common/transformer';

const { AVATAR, PROFILE, GET, INFO, SHARE_PATIENT } = StoreAction.PATIENT;
const { GET_ALL_LABS } = StoreAction.PHR.LABS;

const INITIAL_STATE: StorePatient = {
  currentPatient: {
    inProgress: false
  },
  profile: {
    data: undefined,
    inProgress: false
  },
  updateAvatar: {
    inProgress: false
  },
  currentPatientInformation: {
    inProgress: false
  },
  currentPatientTreatmentPlans: {
    data: [],
    inProgress: false
  },
  currentPatientLatestRecord: {
    inProgress: false
  },
  labs: {
    inProgress: false,
    data: []
  },
  sharePatient: {
    inProgress: false,
    selectedAccount: undefined
  }
};

function patientReducer(state = INITIAL_STATE, action: ActionType): StorePatient {
  switch (action.type) {
    case PROFILE.GET.REQUEST:
      return {
        ...state,
        currentPatient: {
          inProgress: true
        },
        profile: {
          ...state.profile,
          inProgress: true
        }
      };
    case PROFILE.GET.FAILED:
      return {
        ...state,
        currentPatient: {
          inProgress: false
        },
        profile: {
          ...state.profile,
          inProgress: false,
          error: action.payload
        }
      };
    case PROFILE.GET.SUCCESS:
      return {
        ...state,
        currentPatient: {
          data: transformInPatient(action.payload),
          inProgress: false
        },
        profile: {
          data: action.payload,
          inProgress: false
        }
      };
    case PROFILE.GET.RESET:
      return {
        ...state,
        currentPatient: {
          inProgress: false
        },
        profile: {
          data: undefined,
          inProgress: false
        }
      };
    case AVATAR.UPDATE.REQUEST:
    case INFO.AVATAR.UPDATE.REQUEST:
      return {
        ...state,
        updateAvatar: {
          inProgress: true
        }
      };
    case AVATAR.UPDATE.FAILED:
    case INFO.AVATAR.UPDATE.FAILED:
      return {
        ...state,
        updateAvatar: {
          inProgress: false,
          error: action.payload
        }
      };
    case AVATAR.UPDATE.SUCCESS: {
      const { file_name, file_url } = action.payload;
      return {
        ...state,
        profile: {
          data: {
            ...state.profile.data,
            file_name,
            file_url
          } as _PatientProfile
        } as StorePatientProfile,
        updateAvatar: {
          inProgress: false
        }
      };
    }
    case INFO.AVATAR.UPDATE.SUCCESS: {
      const { file_name, file_url } = action.payload;
      return {
        ...state,
        currentPatientInformation: {
          ...state.currentPatientInformation,
          data: {
            ...state.currentPatientInformation.data,
            fileName: file_name,
            fileUrl: file_url
          } as PatientWithSummary
        },
        updateAvatar: {
          inProgress: false
        }
      };
    }
    case GET.INFO.REQUEST:
      return {
        ...state,
        currentPatientInformation: {
          inProgress: true
        }
      };
    case GET.INFO.FAILED:
      return {
        ...state,
        currentPatientInformation: {
          inProgress: false
        }
      };
    case GET.INFO.SUCCESS:
      const patient = action.payload;
      const patientSummary = transformIn.summary(patient.summary);
      return {
        ...state,
        currentPatientInformation: {
          inProgress: false,
          data: {
            ...transformInPatient(patient),
            gender: patientSummary.gender as Gender,
            dateOfBirth: new Date(patientSummary.birthDate),
            summary: patientSummary
          }
        }
      };
    case GET.INFO.RESET:
      return {
        ...state,
        currentPatientInformation: {
          inProgress: false
        }
      };
    case GET.LATEST_HEALTH_RECORD.REQUEST:
      return {
        ...state,
        currentPatientLatestRecord: {
          inProgress: true
        }
      };
    case GET.LATEST_HEALTH_RECORD.FAILED:
      return {
        ...state,
        currentPatientLatestRecord: {
          inProgress: false
        }
      };
    case GET.LATEST_HEALTH_RECORD.SUCCESS:
      return {
        ...state,
        currentPatientLatestRecord: {
          inProgress: false,
          data: action.payload
        }
      };
    case GET.LATEST_HEALTH_RECORD.RESET:
      return {
        ...state,
        currentPatientLatestRecord: {
          inProgress: false
        }
      };
    case GET_ALL_LABS.REQUEST:
      return {
        ...state,
        labs: {
          inProgress: true,
          data: []
        }
      };
    case GET_ALL_LABS.SUCCESS:
      return {
        ...state,
        labs: {
          inProgress: false,
          data: !!action.payload['isDelete']
            ? state.labs.data.filter(lab => lab.id !== action.payload.id)
            : action.payload
        }
      };
    case GET_ALL_LABS.FAILED:
      return {
        ...state,
        labs: {
          inProgress: false,
          data: [],
          error: action.payload
        }
      };
    case GET.TREATMENT_PLANS.REQUEST: {
      return {
        ...state,
        currentPatientTreatmentPlans: {
          ...state.currentPatientTreatmentPlans,
          inProgress: true
        }
      };
    }
    case GET.TREATMENT_PLANS.FAILED: {
      return {
        ...state,
        currentPatientTreatmentPlans: {
          ...state.currentPatientTreatmentPlans,
          inProgress: false,
          error: action.payload
        }
      };
    }
    case GET.TREATMENT_PLANS.SUCCESS: {
      return {
        ...state,
        currentPatientTreatmentPlans: {
          data: action.payload,
          inProgress: false
        }
      };
    }
    case SHARE_PATIENT.POST.REQUEST: {
      return {
        ...state,
        sharePatient: {
          inProgress: true,
          selectedAccount: action.payload.accountId
        }
      };
    }
    case SHARE_PATIENT.POST.SUCCESS: {
      return {
        ...state,
        sharePatient: {
          inProgress: false,
          selectedAccount: undefined
        }
      };
    }
    case SHARE_PATIENT.POST.FAILED: {
      return {
        ...state,
        sharePatient: {
          inProgress: false,
          selectedAccount: undefined
        }
      };
    }
    default:
      return state;
  }
}

export default patientReducer;
