import { IconButton, InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { Search, Visibility, VisibilityOff } from '@material-ui/icons';
import classNames from 'classnames';
import { styled } from 'components/Theme';
import { FormHelperProps } from 'hooks/form';
import React, { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Select, SelectProps } from '../select/Select';
import { useAutosaveContext } from '../../context/AutosaveContext';

export type InputProps = Partial<TextFieldProps> & {
  name: string;
  unit?: any;
  onSearch?: () => void;
  submitOnEnter?: boolean;
  unitProps?: SelectProps;
  helpers?: FormHelperProps;
  onEnterKeyPress?: (e: any) => void;
  preventCopy?: boolean;
  preventCut?: boolean;
  preventPaste?: boolean;
  unitPosition?: string;
};

export const Input: React.FC<InputProps> = ({
  type,
  unit,
  unitPosition = 'end',
  className,
  onSearch,
  submitOnEnter = false,
  unitProps,
  helpers,
  onEnterKeyPress,
  onFocus,
  preventCopy,
  preventCut,
  preventPaste,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputClass = classNames('c-input', className, {
    'has-unit-props': !!unitProps || type === 'password' || type === 'search'
  });

  const PasswordIcon = useMemo(
    () => (
      <IconButton aria-label="Toggle password visibility" onClick={e => setShowPassword(!showPassword)}>
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    ),
    [showPassword, setShowPassword]
  );

  const SearchIcon = useMemo(
    () => (
      <IconButton onClick={() => onSearch && onSearch()}>
        <Search />
      </IconButton>
    ),
    [onSearch]
  );

  const InputProps = useMemo(() => {
    if (!unitProps && !unit && type !== 'password' && type !== 'search') return;
    if (unitPosition === 'end') {
      return {
        endAdornment: !!unitProps ? (
          <React.Fragment>
            {type === 'search' && <InputAdornment position="end">{SearchIcon}</InputAdornment>}
            <Select {...unitProps} className={unitProps.className} margin="none" />
          </React.Fragment>
        ) : (
          <InputAdornment position="end">
            {type === 'password' && PasswordIcon}
            {type === 'search' && SearchIcon}
            {unit}
          </InputAdornment>
        )
      };
    } else {
      return {
        startAdornment: <InputAdornment position="start">{unit}</InputAdornment>
      };
    }
  }, [unitProps, unit, unitPosition, PasswordIcon, SearchIcon, type]);

  const onKeyDown = (ev: any) => {
    // On Enter
    if (ev.keyCode === 13) {
      if (onEnterKeyPress) {
        onEnterKeyPress(ev);
      }
      if (type === 'search' && onSearch) {
        onSearch();
      } else if (!props.multiline && !submitOnEnter) {
        ev.preventDefault();
      }
    }
  };

  const preventEvent = (e: any) => e.preventDefault();

  if (helpers) {
    return (
      <Controller
        {...props}
        name={props.name}
        control={helpers?.control}
        className={inputClass}
        InputProps={InputProps}
        onChange={([e]) => {
          props.onChange && props.onChange(e);
          return e;
        }}
        onBlur={args => args[0]}
        inputProps={{
          step: type === 'number' ? '0.01' : undefined,
          onKeyDown: (e: any) => {
            if (type === 'number') {
              return ['e', 'E', '-', '+'].includes(e.key) && e.preventDefault();
            }
          },
          ...props.inputProps
        }}
        InputLabelProps={type === 'date' || type === 'time' ? { shrink: true } : undefined}
        type={showPassword || type === 'search' ? 'text' : type}
        variant={'outlined'}
        size={'small'}
        margin={props.margin || 'normal'}
        onKeyDown={onKeyDown}
        defaultValue={props.defaultValue}
        onCopy={preventCopy ? preventEvent : props.onCopy}
        onCut={preventCut ? preventEvent : props.onCut}
        onPaste={preventPaste ? preventEvent : props.onPaste}
        as={<StyledTextField />}
      />
    );
  }

  return (
    <StyledTextField
      {...props}
      className={inputClass}
      InputProps={InputProps}
      inputProps={{
        step: type === 'number' ? '0.01' : undefined,
        ...props.inputProps
      }}
      InputLabelProps={type === 'date' || type === 'time' ? { shrink: true } : undefined}
      type={showPassword || type === 'search' ? 'text' : type}
      variant={'outlined'}
      size={'small'}
      margin={props.margin || 'normal'}
      onKeyDown={onKeyDown}
      onCopy={preventCopy ? preventEvent : props.onCopy}
      onCut={preventCut ? preventEvent : props.onCut}
      onPaste={preventPaste ? preventEvent : props.onPaste}
    />
  );
};

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    padding-right: 0;
  }
  .MuiInputAdornment-root.MuiInputAdornment-positionStart {
    height: auto;
    display: inline-flex;
    border-right: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0 12px 0 0;
    margin: 0;
  }

  .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
    height: auto;
    display: inline-flex;
    border-left: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0 12px;
    margin: 0;
  }

  .MuiFormHelperText-root {
    position: absolute;
    top: calc(100% + 2px);
    left: 5px;
    margin: 0;
  }
  &.MuiFormControl-marginNormal {
    margin-top: 16px;
    margin-bottom: 12px;
  }
  &.has-unit-props {
    .MuiInputAdornment-root {
      border: none;
      padding: 0;
    }
  }
  .c-select {
    border-left: 1px solid rgba(0, 0, 0, 0.23);
    min-width: 70px;
    margin: 0 !important;
    &.max {
      width: 100%;
    }
    fieldset {
      border: none;
    }
  }
  textarea {
    padding: 0 !important;
    border: 0 !important;
  }
`;
