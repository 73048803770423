import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MatSelect, Tooltip } from '@material-ui/core';
import { FieldAttributes, FieldSubAttributes, useField } from 'components/form';
import { KeyValue } from 'models';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import './Select.scss';

export type SelectProps = FieldAttributes & {
  fullWidth?: boolean;
  items: KeyValue[];
  multiple?: boolean;
  disabled?: boolean;
  defaultSelect?: boolean;
};

export const Select: React.FC<SelectProps> = ({
  label,
  items,
  fullWidth,
  className,
  disabled,
  multiple,
  defaultSelect = false,
  ...props
}) => {
  const [field, meta] = useField<FieldSubAttributes>(props);
  const [value, setValue] = useState((field as any).value);
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabel = useRef<HTMLLabelElement | null>(null);
  const errorText = meta.error && meta.touched ? meta.error : '';

  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    if (field.value !== props.value) {
      setValue(field.value);
    }
  }, [field.value, props.value]);

  const valueChanged = useMemo(() => {
    const prevValue: string = (field as any).value;
    return value !== prevValue;
  }, [value, field]);

  const onBlur = (e: any) => {
    if (defaultSelect) {
      field.onBlur(e);
    } else {
      if (valueChanged) {
        field.onChange(e);
        field.onBlur(e);
      }
    }
  };

  const onChange = (e: any) => {
    if (defaultSelect) {
      field.onChange(e);
    } else {
      setValue(e.target.value);
    }
  };

  return (
    <Tooltip title={multiple ? 'You can select multiple items here.' : ''} placement="top">
      <FormControl
        fullWidth={fullWidth}
        margin="dense"
        variant="outlined"
        className={`c-select ${className || ''}`.trim()}
      >
        {label && (
          <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
            {label}
          </InputLabel>
        )}
        <MatSelect
          name={field.name}
          value={value}
          multiple={multiple}
          error={!!errorText}
          onBlur={onBlur}
          onChange={onChange}
          labelWidth={labelWidth}
          fullWidth={fullWidth}
          disabled={disabled}
          label={label}
        >
          {items.map((item, i) => (
            <MenuItem key={`${item.id}-${i}`} value={item.id}>
              {item.value}
            </MenuItem>
          ))}
        </MatSelect>
        {errorText && <FormHelperText error={!!errorText}>{errorText}</FormHelperText>}
      </FormControl>
    </Tooltip>
  );
};
