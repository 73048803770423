import { transformInEncounter, transformInStagingForm } from 'common/transformer';
import { StoreAction } from 'constants/store';
import {
  ActionType,
  StoreEncounter,
  StoreEncounterLabs,
  StorePHR,
  StorePHRTransaction,
  StoreEncounterStagingForms,
  StoreMedicalCertificate
} from 'models/store';
import { updateList } from 'services/labs.service';
import { _CombinedStagingForm } from 'models/endpoint';

const {
  NEW_ENCOUNTER,
  GET_ENCOUNTER,
  SAVE_ENCOUNTER,
  LABS,
  GET_PROCEDURE,
  SAVE_PROCEDURE,
  GET_STAGING_FORM,
  MEDICAL_CERTIFICATE
} = StoreAction.PHR;
const { GET_ALL: GET_LABS, ADD: ADD_LAB, UPDATE: UPDATE_LAB, REMOVE: REMOVE_LAB, UPDATE_ATTACHMENTS } = LABS;

const INITIAL_STATE: StorePHR = {
  currentEncounter: {
    inProgress: false
  },
  currentEncounterLabs: {
    data: [],
    inProgress: false
  },
  currentEncounterStagingForms: {
    data: undefined
  },
  transaction: {
    newEncounter: {
      inProgress: false
    },
    updateEncounter: {
      inProgress: false
    }
  },
  currentCertificate: undefined
};

function currentEncounterReducer(state = INITIAL_STATE.currentEncounter, action: ActionType): StoreEncounter {
  switch (action.type) {
    case GET_ENCOUNTER.REQUEST:
      return {
        ...state,
        // Get encounter can either be called with a number (id) or an object { id: number, silent: boolean }
        inProgress: typeof action.payload === 'number' ? true : !action.payload.silent
      };
    case GET_ENCOUNTER.SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: action.payload ? transformInEncounter(action.payload) : undefined
      };
    case GET_ENCOUNTER.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload
      };
    case GET_PROCEDURE.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case GET_PROCEDURE.SUCCESS:
      return {
        ...state,
        inProgress: false,
        procedure: action.payload
      };
    case GET_PROCEDURE.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload,
        procedure: undefined
      };
    case SAVE_PROCEDURE.SUCCESS:
      return {
        ...state,
        inProgress: false,
        procedure: action.payload
      };
    default:
      return state;
  }
}

function transactionReducer(state = INITIAL_STATE.transaction, action: ActionType): StorePHRTransaction {
  switch (action.type) {
    case NEW_ENCOUNTER.REQUEST:
      return {
        ...state,
        newEncounter: {
          inProgress: true,
          phrId: undefined
        }
      };
    case NEW_ENCOUNTER.SUCCESS:
      return {
        ...state,
        newEncounter: {
          inProgress: false,
          phrId: action.payload
        }
      };
    case NEW_ENCOUNTER.FAILED:
      return {
        ...state,
        newEncounter: {
          inProgress: false,
          error: action.payload
        }
      };
    case SAVE_ENCOUNTER.REQUEST:
      return {
        ...state,
        updateEncounter: {
          inProgress: true
        }
      };
    case SAVE_ENCOUNTER.SUCCESS:
      return {
        ...state,
        updateEncounter: {
          inProgress: false
        }
      };
    case SAVE_ENCOUNTER.FAILED:
      return {
        ...state,
        updateEncounter: {
          inProgress: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
}

function currentEncounterLabsReducer(
  state = INITIAL_STATE.currentEncounterLabs,
  action: ActionType
): StoreEncounterLabs {
  switch (action.type) {
    case GET_LABS.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case GET_LABS.SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: action.payload
      };
    case GET_LABS.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload
      };
    case ADD_LAB.SUCCESS:
    case UPDATE_LAB.SUCCESS:
      return {
        ...state,
        data: updateList(state.data, action.payload),
        inProgress: false
      };
    case REMOVE_LAB.SUCCESS:
      const updatedList = state.data.filter(item => item.id !== action.payload);
      return {
        ...state,
        data: updatedList
      };
    case UPDATE_ATTACHMENTS.SUCCESS:
      const hasUpdatedAttachments = state.data.find(lab => lab.id === action.payload.id);
      if (hasUpdatedAttachments) {
        return {
          ...state,
          data: updateList(
            state.data,
            Object.assign(hasUpdatedAttachments, { phr_test_group_files: action.payload.attachments })
          )
        };
      }
      return state;
    default:
      return state;
  }
}

function currentEncounterStagingFormsReducer(
  state = INITIAL_STATE.currentEncounterStagingForms,
  action: ActionType
): StoreEncounterStagingForms {
  const { BY_ENCOUNTER, UPDATE_LIST } = GET_STAGING_FORM;
  switch (action.type) {
    case BY_ENCOUNTER.REQUEST:
      return state;
    case BY_ENCOUNTER.SUCCESS:
      return {
        data: action.payload
          ? (action.payload || []).map((item: _CombinedStagingForm) => transformInStagingForm.anyStagingForm(item))
          : []
      };
    case UPDATE_LIST.SUCCESS:
      return {
        data: action.payload
      };
    case BY_ENCOUNTER.FAILED:
      return state;
    default:
      return state;
  }
}

function currentCertificateReducer(
  state = INITIAL_STATE.currentCertificate,
  action: ActionType
): StoreMedicalCertificate | undefined {
  switch (action.type) {
    case MEDICAL_CERTIFICATE.SUCCESS:
      return action.payload;
    case MEDICAL_CERTIFICATE.RESET:
      return INITIAL_STATE.currentCertificate;
    default:
      return state;
  }
}

export default function reducer(state: StorePHR = INITIAL_STATE, action: ActionType): StorePHR {
  return {
    currentEncounter: currentEncounterReducer(state.currentEncounter, action),
    currentEncounterLabs: currentEncounterLabsReducer(state.currentEncounterLabs, action),
    currentEncounterStagingForms: currentEncounterStagingFormsReducer(state.currentEncounterStagingForms, action),
    transaction: transactionReducer(state.transaction, action),
    currentCertificate: currentCertificateReducer(state.currentCertificate, action)
  };
}
