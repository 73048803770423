import { AxiosResponse } from 'axios';
import { StoreAction } from 'constants/store';
import { _SecretaryDashboard } from 'models/endpoint';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getSecretaryDashboard } from 'services/secretary.service';

const { GET_DASHBOARD } = StoreAction.SECRETARY;

function* getSecretaryDashboardSaga() {
  try {
    let { data: secretaryDashboard }: AxiosResponse<_SecretaryDashboard> = yield call(getSecretaryDashboard);
    yield put({ type: GET_DASHBOARD.SUCCESS, payload: secretaryDashboard });
  } catch (err) {
    yield put({ type: GET_DASHBOARD.FAILED, payload: err });
  }
}

export function* rootSaga() {
  yield takeLatest(GET_DASHBOARD.REQUEST, getSecretaryDashboardSaga);
}
