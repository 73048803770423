import { http } from 'common/services';
import { transformOut } from 'common/transformer';
import { ENDPOINTS, LocalStorage } from 'defaults';
import { ConfirmationEmail, ForgotPasswordForm, ResetPasswordForm, SignupForm } from 'models';

export const handleLogout = () => {
  clearLocalStorage();
  window.location.reload();
};

export const clearLocalStorage = () => {
  localStorage.removeItem(LocalStorage.isAuthenticated);
  localStorage.removeItem(LocalStorage.accessToken);
  localStorage.removeItem(LocalStorage.assumeToken);
  localStorage.removeItem(LocalStorage.activeAccount);
  localStorage.removeItem(LocalStorage.notifyLater);
  localStorage.removeItem(LocalStorage.feedbackClosed);
};

export const confirmOTP = (otp: string, token: string) => {
  const { CONFIRM_OTP: endpoint } = ENDPOINTS.SESSION;
  return http.put(endpoint.name, endpoint.url, { token: otp }, token);
};

export const resendOTP = (token: string) => {
  const { RESEND_OTP: endpoint } = ENDPOINTS.SESSION;
  return http.get(endpoint.name, endpoint.url, token);
};

export const signUp = (form: SignupForm) => {
  const { REGISTER_DOCTOR: endpoint } = ENDPOINTS.SESSION;
  return http.post(endpoint.name, endpoint.url, transformOut.registerDoctor(form), '', true);
};

export const forgotPassword = (form: ForgotPasswordForm) => {
  const { FORGOT_PASSWORD: endpoint } = ENDPOINTS.SESSION;
  return http.post(endpoint.name, endpoint.url, form, '', true);
};

export const resetPassword = ({ password }: ResetPasswordForm, token: string) => {
  const { RESET_PASSWORD: endpoint } = ENDPOINTS.SESSION;
  return http.post(endpoint.name, endpoint.url, { password, token }, '', true);
};

export const confirmEmail = ({ confirmationToken }: ConfirmationEmail) => {
  const { CONFIRM_EMAIL: endpoint } = ENDPOINTS.SESSION;
  return http.post(endpoint.name, endpoint.url, { confirmation_token: confirmationToken }, '', true);
};

export default {
  handleLogout,
  clearLocalStorage,
  confirmOTP,
  resendOTP,
  signUp,
  forgotPassword,
  resetPassword,
  confirmEmail
};
