import { AxiosResponse } from 'axios';
import { Toast } from 'components';
import MESSAGE from 'constants/message';
import { StoreAction } from 'constants/store';
import isSameDay from 'date-fns/isSameDay';
import omit from 'lodash/omit';
import { _HealthRecord, _LabRecord as Lab } from 'models/endpoint';
import { ActionType } from 'models/store';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  addHealthRecordChiefComplaint,
  cancelAppointment,
  createAddHealthRecordChiefComplaintParams,
  createHealthRecordParamsFromNewAppointment,
  createHealthRecordParamsFromNewWalkinAppointment,
  createNewAppointment,
  createNewHealthRecordFromNewAppointment,
  filterByDay,
  getAppointmentsViaDateAndClinicRange,
  transformOutCreateNewAppointmentForm,
  transformOutCreateNewWalkinAppointmentForm
} from 'services/appointment.service';
import {
  createTreatmentResultPhr,
  saveImagingLab,
  saveImagingResult,
  saveImagingResultDetails,
  saveMammogramDetails
} from 'services/imaging.service';
import { addEncounterLab, addLabAttachments } from 'services/labs.service';
import { getPatientLatestHealthRecord } from 'services/patient.service';
import { addAppointmentAttachment } from 'services/uploader.service';

const { GET, CREATE, CREATE_WALKIN, CANCEL } = StoreAction.APPOINTMENT;
const { DATE_AND_CLINIC } = GET.VIA;

function* getAppointmentsViaDateAndClinicSaga(action: ActionType) {
  try {
    const { data } = yield call(
      getAppointmentsViaDateAndClinicRange,
      new Date(action.payload.date),
      action.payload.clinicId
    );
    const appointments = filterByDay(new Date(action.payload.date), data);
    yield put({ type: DATE_AND_CLINIC.SUCCESS, payload: appointments });
  } catch (err) {
    yield put({ type: DATE_AND_CLINIC.FAILED, payload: err });
    Toast.error(err.message);
  }
}

function* createNewAppointmentSaga(action: ActionType) {
  try {
    let followup_id = null;
    const { attachedLabs } = action.payload;

    Toast.info('Creating Appointment. Please wait.');

    const { data: healthRecordList } = yield call(getPatientLatestHealthRecord, action.payload.patientId);
    if (action.payload.consultationOrFollowup === 'followup' && healthRecordList.length > 0)
      followup_id = healthRecordList[0]['id'];

    const { data } = yield call(createNewAppointment, transformOutCreateNewAppointmentForm(action.payload));
    const { data: newHealthRecord } = yield call(
      createNewHealthRecordFromNewAppointment,
      createHealthRecordParamsFromNewAppointment(
        Object.assign(data, { followup_id, type: action.payload.encounterType, appointment_id: data.id })
      )
    );

    yield call(
      addHealthRecordChiefComplaint,
      createAddHealthRecordChiefComplaintParams(newHealthRecord.id, data.chief_complaint)
    );

    if (attachedLabs.length > 0) {
      const limit = attachedLabs.length;
      for (let index = 0; index < limit; index++) {
        const payload = attachedLabs[index];
        const values = Object.assign(payload.values, { patient_health_record_id: newHealthRecord.id });
        const { data }: AxiosResponse<Lab> = yield call(addEncounterLab, payload.type, values);
        if (payload.attachments.length > 0) yield call(addLabAttachments, data.id, payload.attachments);
      }
    }

    const { attachedImagings } = action.payload;
    if (attachedImagings.length > 0) {
      const limit = attachedImagings.length;
      for (let i = 0; i < limit; i++) {
        const attachedImagingPayload = attachedImagings[i];
        const values = Object.assign(attachedImagingPayload, { patient_health_record_id: newHealthRecord.id });
        const saveUnderImagingResuts =
          [
            'PhrTestGroups::XRay',
            'PhrTestGroups::CtScan',
            'PhrTestGroups::Mammogram',
            'PhrTestGroups::Ultrasound',
            'PhrTestGroups::MagneticResonanceImaging'
          ].indexOf(values.type) >= 0;

        if (saveUnderImagingResuts) {
          const imagingResultData = omit(values, ['imaging_type', 'laboratory_name']) as any;
          const { data: treatmentResultPHR } = yield call(
            createTreatmentResultPhr,
            action.payload.patientId,
            action.payload.clinicId,
            newHealthRecord.id
          );
          const imagingResultPayload = Object.assign(imagingResultData, {
            patient_health_record_id: (treatmentResultPHR as _HealthRecord).id
          });
          const { data: imagingResult } = yield call(saveImagingResult, imagingResultPayload);

          if (values.type !== 'PhrTestGroups::Mammogram') {
            yield call(saveImagingResultDetails, imagingResult.id, values.phr_test_details);
          } else {
            const { name, assessment, clinicalHistory } = values.mammogram_details;
            yield call(
              saveMammogramDetails,
              imagingResult.id,
              name,
              assessment,
              clinicalHistory,
              values.phr_test_details
            );
          }

          if (values.attachments.length > 0) yield call(addLabAttachments, imagingResult.id, values.attachments);
        } else {
          const imagingLabPayload = {
            patient_health_record_id: newHealthRecord.id,
            taken_at: values.taken_at,
            imaging_type: values.type,
            laboratory_name: values.laboratory_name,
            result: values.remarks
          } as any;
          const { data: imagingLab } = yield call(saveImagingLab, imagingLabPayload);
          if (values.attachments.length > 0) yield call(addLabAttachments, imagingLab.id, values.attachments);
        }
      }
    }

    const bookingScheduleIsCurrentDay = isSameDay(new Date(), new Date(data.booking_schedule));
    if (bookingScheduleIsCurrentDay) {
      yield put({
        type: DATE_AND_CLINIC.REQUEST,
        payload: { date: new Date(data.booking_schedule), clinicId: data.clinic_id }
      });
    }

    yield put({ type: CREATE.SUCCESS, payload: null });
    Toast.success(MESSAGE.appointment.create.success);
  } catch (err) {
    yield put({ type: CREATE.FAILED, payload: err });
    Toast.error(err.message);
  }
}

function* createNewWalkinAppointmentSaga(action: ActionType) {
  try {
    const { encounterType, attachedLabs, formsFiles } = action.payload;
    let followupId = null;

    Toast.info('Creating Appointment. Please wait.');

    const { data: healthRecordList } = yield call(getPatientLatestHealthRecord, action.payload.patientId);
    if (action.payload.consultationOrFollowup === 'followup' && healthRecordList.length > 0)
      followupId = healthRecordList[0]['id'];

    const { data } = yield call(createNewAppointment, transformOutCreateNewWalkinAppointmentForm(action.payload));
    const createNewRecordParams = createHealthRecordParamsFromNewWalkinAppointment(
      Object.assign(action.payload, {
        followupId,
        type: encounterType,
        appointmentId: data.id,
        visitDate: data.booking_schedule
      })
    );

    const { data: newHealthRecord } = yield call(createNewHealthRecordFromNewAppointment, createNewRecordParams);

    if (attachedLabs.length > 0) {
      const limit = attachedLabs.length;
      for (let index = 0; index < limit; index++) {
        const payload = attachedLabs[index];
        const values = Object.assign(payload.values, { patient_health_record_id: newHealthRecord.id });
        const { data }: AxiosResponse<Lab> = yield call(addEncounterLab, payload.type, values);
        if (payload.attachments.length > 0) yield call(addLabAttachments, data.id, payload.attachments);
      }
    }

    const { attachedImagings } = action.payload;
    if (attachedImagings.length > 0) {
      const limit = attachedImagings.length;
      for (let i = 0; i < limit; i++) {
        const attachedImagingPayload = attachedImagings[i];
        const values = Object.assign(attachedImagingPayload, { patient_health_record_id: newHealthRecord.id });
        const saveUnderImagingResuts =
          [
            'PhrTestGroups::XRay',
            'PhrTestGroups::CtScan',
            'PhrTestGroups::Mammogram',
            'PhrTestGroups::Ultrasound',
            'PhrTestGroups::MagneticResonanceImaging'
          ].indexOf(values.type) >= 0;

        if (saveUnderImagingResuts) {
          const imagingResultData = omit(values, ['imaging_type', 'laboratory_name']) as any;
          const { data: treatmentResultPHR } = yield call(
            createTreatmentResultPhr,
            action.payload.patientId,
            action.payload.clinicId,
            newHealthRecord.id
          );
          const imagingResultPayload = Object.assign(imagingResultData, {
            patient_health_record_id: (treatmentResultPHR as _HealthRecord).id
          });
          const { data: imagingResult } = yield call(saveImagingResult, imagingResultPayload);

          if (values.type !== 'PhrTestGroups::Mammogram') {
            yield call(saveImagingResultDetails, imagingResult.id, values.phr_test_details);
          } else {
            const { name, assessment, clinicalHistory } = values.mammogram_details;
            yield call(
              saveMammogramDetails,
              imagingResult.id,
              name,
              assessment,
              clinicalHistory,
              values.phr_test_details
            );
          }

          if (values.attachments.length > 0) yield call(addLabAttachments, imagingResult.id, values.attachments);
        } else {
          const imagingLabPayload = {
            patient_health_record_id: newHealthRecord.id,
            taken_at: values.taken_at,
            imaging_type: values.type,
            laboratory_name: values.laboratory_name,
            result: values.remarks
          } as any;
          const { data: imagingLab } = yield call(saveImagingLab, imagingLabPayload);
          if (values.attachments.length > 0) yield call(addLabAttachments, imagingLab.id, values.attachments);
        }
      }
    }

    yield call(
      addHealthRecordChiefComplaint,
      createAddHealthRecordChiefComplaintParams(newHealthRecord.id, data.chief_complaint)
    );

    if (formsFiles && formsFiles.length > 0) {
      for (let file of formsFiles) {
        yield call(addAppointmentAttachment, data.id, file, 'clinic', 'confirmed');
      }
    }

    yield put({
      type: DATE_AND_CLINIC.REQUEST,
      payload: { date: new Date(data.booking_schedule), clinicId: data.clinic_id }
    });

    yield put({ type: CREATE.SUCCESS, payload: null });
    Toast.success(MESSAGE.appointment.create.success);
  } catch (err) {
    yield put({ type: CREATE.FAILED, payload: err });
    Toast.error(err.message);
  }
}

function* cancelAppointmentSaga(action: ActionType) {
  try {
    yield call(cancelAppointment, action.payload.id, action.payload.clinicId);
    yield put({ type: CANCEL.SUCCESS, payload: action.payload.id });
    Toast.success(MESSAGE.appointment.cancel.success);
  } catch (err) {
    yield put({ type: CANCEL.FAILED, payload: err });
    Toast.error(err.message);
  }
}

export function* rootSaga() {
  yield takeLatest(DATE_AND_CLINIC.REQUEST, getAppointmentsViaDateAndClinicSaga);
  yield takeLatest(CREATE.REQUEST, createNewAppointmentSaga);
  yield takeLatest(CREATE_WALKIN.REQUEST, createNewWalkinAppointmentSaga);
  yield takeLatest(CANCEL.REQUEST, cancelAppointmentSaga);
}
