import { IconButton, Tooltip } from '@material-ui/core';
import { AttachFile as AttachFileIcon, ChevronLeft } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import CallIcon from '@material-ui/icons/Call';
import CloseIcon from '@material-ui/icons/Close';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';
import { styled } from 'components/Theme';
import { useCurrentUser } from 'hooks';
import { Resizable } from 're-resizable';
import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useModal } from 'shared/components/Modal';
import { EConsultMessage, EConsultModal, useEnterRoom } from 'shared/econsult/EConsultModal';
import QuickAttachFile from './QuickAttachFile';

const SpeedDialButton = () => {
  const { location } = useHistory();
  const { isNavigator } = useCurrentUser();
  const [room, setRoom] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const { link } = useEnterRoom(room);
  const [t] = useTranslation();
  const { modalRef, open: openEConsultModal, close, shouldReload } = useModal({
    title: t('e_consult'),
    component: () =>
      isNew ? (
        <EConsultMessage onStart={onStart} />
      ) : (
        <EConsultModal onEnter={onStart} newRoom={() => setIsNew(true)} />
      ),
    options: {
      enableBackButton: isNew,
      handleBackButton: () => setIsNew(false)
    }
  });
  const {
    modalRef: attachModalRef,
    open: openAttachModal,
    close: closeAttachModal,
    isOpen: isAttachModalOpen
  } = useModal({
    title: t('attach_files', { ns: 'encounter' }),
    component: () => <QuickAttachFile close={closeAttachModal} />,
    size: 'lg',
    options: {
      disableCloseButton: true
    }
  });
  const onStart = (r: string) => {
    setRoom(r);
    setVisible(true);
    setEConsultActive(true);
    close();
  };
  const [eConsultActive, setEConsultActive] = useState(false);
  const [size, setSize] = useState({ width: 500, height: 350 });
  const [visible, setVisible] = useState(false);
  const [posX] = useState(0);
  const [posY, setPosY] = useState(65);

  useEffect(() => {
    if (!shouldReload) return;
    setIsNew(false);
  }, [shouldReload]);

  const whiteList = ['/patients', '/phr', '/profile', '/appointment'];
  if (whiteList.filter(w => location.pathname.startsWith(w)).length === 0) {
    return null;
  }

  const actions = [
    isNavigator
      ? null
      : {
          icon: <AttachFileIcon />,
          name: t('attach_file', { ns: 'encounter' }),
          onClick: openAttachModal
        },
    {
      icon: <CallIcon />,
      name: t('start_e_consult'),
      onClick: openEConsultModal
    }
  ].filter(d => !!d);

  return (
    <React.Fragment>
      {modalRef}
      {isAttachModalOpen && attachModalRef}
      <Draggable
        handle=".handle"
        position={{ x: visible ? posX : posX - size.width, y: posY }}
        axis="y"
        onStop={(_, data) => setPosY(data.y < 65 ? 65 : data.y)}
        defaultClassName="e-consult-draggable"
      >
        <RndWrapper
          minWidth={500}
          minHeight={680}
          size={size}
          onResizeStop={(e: any, direction: any, ref: any, d: any) => {
            setSize({
              width: size.width + d.width,
              height: size.height + d.height
            });
          }}
        >
          {!visible && eConsultActive && (
            <button className="resume-control" onClick={() => setVisible(true)}>
              <CallIcon />
            </button>
          )}
          <div className="handle"></div>
          <Tooltip title="Hide Window">
            <IconButton className="btn-hide-window" onClick={() => setVisible(false)}>
              <ChevronLeft fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close Window">
            <IconButton
              className="btn-close-window"
              onClick={() => {
                setVisible(false);
                setEConsultActive(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {eConsultActive && (
            <iframe
              allow="camera;microphone"
              title="EConsult"
              src={encodeURI(link)}
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </RndWrapper>
      </Draggable>
      <Dial
        open={isOpen}
        ariaLabel="E-Consult"
        icon={<SpeedDialIcon icon={<AddIcon />} openIcon={<CloseIcon />} />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action?.name}
            icon={action?.icon}
            tooltipTitle={action?.name}
            onClick={action?.onClick}
          />
        ))}
      </Dial>
    </React.Fragment>
  );
};

export default SpeedDialButton;

const RndWrapper = styled(Resizable)`
  .resume-control {
    position: absolute;
    left: 100%;
    top: 50px;
    padding: 12px 12px 12px 6px;
    background: #fff;
    border-radius: 0 50% 50% 0;
    width: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    border: none;
    user-select: none;
  }
  .btn-hide-window {
    position: absolute;
    right: 53px;
    top: 8px;
  }
  .btn-close-window {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .handle {
    width: calc(100% - 120px);
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 16px;
    z-index: 1103;
    cursor: move;
  }
`;

const Dial = styled(SpeedDial)`
  z-index: 1100 !important;
  position: fixed;
  left: 32px;
  bottom: 32px;
  .MuiSpeedDial-fab {
    svg {
      fill: #fff;
    }
    background-color: #4990e2;
    :hover {
      background-color: #4990e2;
      filter: brightness(90%);
    }
  }
  .MuiSpeedDialAction-fab {
    background-color: #fff;
  }
`;
