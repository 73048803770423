import { CircularProgress } from '@material-ui/core';
import { Autocomplete as MatAutocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import { FieldAttributes, FieldSubAttributes, useField } from 'components/form';
import { StyledTextField } from 'components/input/Input';
import { styled } from 'components/Theme';
import debounce from 'lodash/debounce';
import { KeyValue } from 'models';
import React from 'react';
import './Autocomplete.scss';

export type AutocompleteProps = {
  loading?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  suggestions: KeyValue[];
  unit?: string;
  onSearch?: (term: string) => void;
  onItemSelected?: (value: KeyValue) => void;
  submitOnEnter?: boolean;
  helperText?: string;
  multiple?: boolean;
  clearable?: boolean;
  enableBuiltInFilter?: boolean;
  renderOption?: any;
} & FieldAttributes;

export const Autocomplete: React.FC<AutocompleteProps> = ({
  label,
  fullWidth,
  className,
  loading,
  suggestions,
  disabled,
  onSearch,
  onItemSelected,
  unit,
  required,
  submitOnEnter,
  helperText,
  multiple,
  clearable,
  freeSolo,
  enableBuiltInFilter = false,
  renderOption,
  ...props
}) => {
  const [field, meta] = useField<FieldSubAttributes>(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const classes = classNames('c-autocomplete', className, {
    'w-100': fullWidth
  });

  const onKeyDown = (ev: any) => {
    // On Enter
    if (ev.keyCode === 13) {
      if (!submitOnEnter) {
        ev.preventDefault();
      }
    }
  };

  const syncChanges = debounce(e => {
    onSearch && onSearch(e.target.value);
  }, 400);

  const renderInput = (params: any) => (
    <StyledTextField
      {...params}
      variant="outlined"
      size="small"
      margin="normal"
      label={label}
      error={!!errorText}
      helperText={errorText || helperText}
      onKeyDown={onKeyDown}
      name={props.name}
      onChange={e => {
        e.persist && e.persist();
        syncChanges(e);
      }}
      onBlur={e => {
        if (freeSolo) {
          e.persist && e.persist();
          field.onBlur(e);
          field.onChange(e);
        }
      }}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading && <CircularProgress color="inherit" size={20} />}
            {unit && <span className="unit">{unit}</span>}
            {params.InputProps.endAdornment}
          </React.Fragment>
        )
      }}
      disabled={disabled}
    />
  );

  if (multiple) {
    return (
      <React.Fragment>
        <AutocompleteWrapper
          {...field}
          {...props}
          multiple
          disableClearable={!clearable || disabled}
          className={classes}
          options={suggestions}
          onChange={(_: any, item: KeyValue) => {
            onItemSelected && onItemSelected(item);
          }}
          filterOptions={!enableBuiltInFilter ? (option: any) => option : undefined}
          getOptionLabel={
            props.getOptionLabel
              ? props.getOptionLabel
              : (option: any) => (typeof option === 'object' ? option.value : option)
          }
          renderInput={renderInput}
          renderOption={renderOption}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <AutocompleteWrapper
        {...field}
        {...props}
        disableClearable={!clearable || disabled}
        className={classes}
        options={suggestions}
        onChange={(_: any, item: KeyValue) => {
          onItemSelected && onItemSelected(item);
        }}
        filterOptions={!enableBuiltInFilter ? (option: any) => option : undefined}
        getOptionLabel={
          props.getOptionLabel
            ? props.getOptionLabel
            : (option: any) => (typeof option === 'object' ? option.value : option)
        }
        renderInput={renderInput}
        renderOption={renderOption}
      />
    </React.Fragment>
  );
};

export const AutocompleteWrapper = styled(MatAutocomplete)<any>`
  .MuiFormControl-root {
    width: 100%;
  }
  .unit {
    margin-left: 8px;
  }
`;
