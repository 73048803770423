import { http } from 'common/services';
import { ENDPOINTS } from 'defaults';
import omit from 'lodash/omit';
import {
  _HealthRecord,
  _ImagingFlat,
  _LabCommon as _ImagingResult,
  _LabRecord,
  _PhrTestDetails as _ImagingResultDetails,
  _PhrTestDetails
} from 'models/endpoint';
import { useAsyncFn, useEffectOnce } from 'react-use';

export const IMAGING_TYPES = [
  'PhrTestGroups::XRay',
  'PhrTestGroups::CtScan',
  'PhrTestGroups::Mammogram',
  'PhrTestGroups::Ultrasound',
  'PhrTestGroups::MagneticResonanceImaging',
  'PhrTestGroups::Imaging'
];

enum ImagingResultType {
  XRay = 'PhrTestGroups::XRay',
  CtScan = 'PhrTestGroups::CtScan',
  Mammogram = 'PhrTestGroups::Mammogram',
  Ultrasound = 'PhrTestGroups::Ultrasound',
  MagneticResonanceImaging = 'PhrTestGroups::MagneticResonanceImaging',
  Imaging = 'PhrTestGroups::Imaging'
}

const getTreatmentResultPhrType = (phrId: number) => {
  const endpoint = ENDPOINTS.PHR.DOCTOR.RECORD.FILTER.parse({
    followup_id: phrId,
    type: 'Phrs::TypeTreatmentResult'
  });
  return http.get(endpoint.name, endpoint.url);
};

const filterByImagingResultType = (labs: _LabRecord[]): _LabRecord[] =>
  labs.filter(lab => IMAGING_TYPES.indexOf(lab.type) >= 0);
const getAllTestGroupsFromHealthRecords = (records: _HealthRecord[]): _LabRecord[] =>
  records.map(record => record.phr_test_groups).flat();

export async function getImagingResults(phrId: number) {
  const { data } = await getTreatmentResultPhrType(phrId);
  let referenceId = undefined;
  let list: _LabRecord[] = [];
  if (Array.isArray(data) && data.length > 0) {
    const index = data.length - 1;
    referenceId = (data[index] as _HealthRecord).id;
    list = filterByImagingResultType(getAllTestGroupsFromHealthRecords(data));
  }

  return { referenceId, list };
}

export const getImagingResultByPatientId = (patientId: number) => {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.IMAGING.BY_PATIENT;
  return http.get(endpoint.name, `${endpoint.url}?patient_id=${patientId}`);
};

export const deleteImagingResult = (id: number, phrId: number) => {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.DELETE_RECORD;
  return http.delete(endpoint.name, endpoint.url, { id, patient_health_record_id: phrId });
};

export const createTreatmentResultPhr = (patientId: number, clinicId: number, followUpId: number) => {
  const endpoint = ENDPOINTS.PHR.DOCTOR.RECORD.key;
  const payload = {
    patient_id: patientId,
    clinic_id: clinicId,
    followup_id: followUpId,
    type: 'Phrs::TypeTreatmentResult',
    active: true
  };

  return http.post(endpoint.name, endpoint.url, payload);
};

export const saveMammogramDetails = (
  testGroupId: number,
  name: string,
  assessment: string,
  clinicalHistory: string,
  testDetails: _PhrTestDetails[]
) => {
  const ID_MAP = getTestDetailIdMap(testDetails);
  const details = [
    {
      id: ID_MAP.name,
      phr_test_group_id: testGroupId,
      name: 'Name',
      value: name,
      reference: '',
      unit: ''
    },
    {
      id: ID_MAP.clinicalHistory,
      phr_test_group_id: testGroupId,
      name: 'Clinical History',
      value: clinicalHistory,
      reference: '',
      unit: ''
    },
    {
      id: ID_MAP.assessment,
      phr_test_group_id: testGroupId,
      name: 'Assessment',
      value: assessment,
      reference: '',
      unit: ''
    }
  ] as _ImagingResultDetails[];

  return saveImagingResultDetails(testGroupId, details);
};

interface TEST_DETAIL_ID_MAP {
  name: number | undefined;
  assessment: number | undefined;
  clinicalHistory: number | undefined;
}

export const getTestDetailIdMap = (phr_test_details: _PhrTestDetails[]): TEST_DETAIL_ID_MAP => {
  const TEST_DETAIL_ID_MAP = {
    name: undefined,
    assessment: undefined,
    clinicalHistory: undefined
  } as TEST_DETAIL_ID_MAP;

  const limit = phr_test_details.length;
  for (let i = 0; i < limit; i++) {
    const testDetail = phr_test_details[i];
    if (testDetail.name === 'Name') TEST_DETAIL_ID_MAP.name = testDetail.id;
    if (testDetail.name === 'Assessment') TEST_DETAIL_ID_MAP.assessment = testDetail.id;
    if (testDetail.name === 'Clinical History') TEST_DETAIL_ID_MAP.clinicalHistory = testDetail.id;
  }

  return TEST_DETAIL_ID_MAP;
};

export const getMammogramDefaultValueFromTestDetails = (
  testGroupId: number,
  testDetails: Partial<_PhrTestDetails>[]
) => {
  let name, assessment, clinicalHistory;
  const limit = testDetails.length;
  for (let i = 0; i < limit; i++) {
    const testDetail = testDetails[i];
    if (testDetail.name === 'Name') name = testDetail.value || '';
    if (testDetail.name === 'Assessment') assessment = testDetail.value || '';
    if (testDetail.name === 'Clinical History') clinicalHistory = testDetail.value || '';
  }

  return {
    name,
    assessment,
    clinicalHistory,
    phr_test_group_id: testGroupId
  };
};

export const saveImagingResultDetails = (testGroupId: number, imagingResultDetails: _ImagingResultDetails[]) => {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.TEST_DETAILS.key;
  const promises = imagingResultDetails.map((details: _ImagingResultDetails) => {
    const sanitized = omit(details, ['created_at', 'updated_at']);
    const payload = Object.assign(sanitized, { phr_test_group_id: testGroupId });
    return details.id
      ? http.put(endpoint.name, endpoint.url, payload)
      : http.post(endpoint.name, endpoint.url, payload);
  });
  return Promise.all(promises);
};

export const deleteImagingResultDetails = (details: _PhrTestDetails[]) => {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.TEST_DETAILS.key;
  const promises = details.map((detail: _PhrTestDetails) =>
    http.delete(endpoint.name, endpoint.url, { id: detail.id, phr_test_group_id: detail.phr_test_group_id })
  );
  return Promise.all(promises);
};

export const saveImagingResult = (imagingResult: _ImagingResult) => {
  let payload = omit(imagingResult, [
    'type',
    'details',
    'created_at',
    'updated_at',
    'attachments',
    'defined_order',
    'phr_test_details',
    'mammogram_details',
    'phr_test_group_files',
    'phr_test_group_template',
    'phr_test_group_template_id',
    'breast_ultrasound_detail'
  ]);
  const endpoint = getEndpointForType(imagingResult.type);
  if (imagingResult.type === 'Breast Ultrasound') {
    payload = {
      ...payload,
      ...imagingResult?.breast_ultrasound_detail
    };
  }
  if (endpoint)
    return imagingResult.id
      ? http.put(endpoint.name, endpoint.url, payload)
      : http.post(endpoint.name, endpoint.url, payload);
  return undefined;
};

const getEndpointForType = (type: string) => {
  switch (type) {
    case ImagingResultType.XRay:
      return ENDPOINTS.PHR.DOCTOR.LABS.XRAY.key;
    case ImagingResultType.CtScan:
      return ENDPOINTS.PHR.DOCTOR.LABS.CTSCAN.key;
    case ImagingResultType.Mammogram:
      return ENDPOINTS.PHR.DOCTOR.LABS.MAMMOGRAM.key;
    case ImagingResultType.Ultrasound:
      return ENDPOINTS.PHR.DOCTOR.LABS.ULTRASOUND.key;
    case ImagingResultType.MagneticResonanceImaging:
      return ENDPOINTS.PHR.DOCTOR.LABS.MRI.key;
    case 'Breast Ultrasound':
      return ENDPOINTS.PHR.DOCTOR.LABS.BREAST_ULTRASOUND.key;
  }
};

export const getImagingTestResults = (phrId: number) => {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.IMAGING.GET;
  return http.get(endpoint.name, `${endpoint.url}?patient_health_record_id=${phrId}`);
};

export const saveImagingLab = (imaging: _ImagingFlat) => {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.IMAGING;
  return imaging.id
    ? http.put(endpoint.PUT.name, endpoint.PUT.url, imaging)
    : http.post(endpoint.POST.name, endpoint.POST.url, imaging);
};

export const getBreastUltrasound = (phrId: number) => {
  const endpoint = ENDPOINTS.PHR.DOCTOR.LABS.IMAGING.BREAST_ULTRASOUND.GET;
  return http.get(endpoint.name, `${endpoint.url}?patient_health_record_id=${phrId}`);
};

export const useBreastUltrasound = (phrId: number) => {
  const [{ value, loading }, fetchBreastUltrasound] = useAsyncFn(async (...args: any[]) => {
    const { data } = await getBreastUltrasound(phrId);
    return data ?? [];
  });

  useEffectOnce(() => {
    fetchBreastUltrasound();
  });

  return {
    value,
    fetching: loading,
    fetchBreastUltrasound
  };
};

export const saveBreastUltrasound = (payload: any) => {
  const { POST, PUT } = ENDPOINTS.PHR.DOCTOR.LABS.IMAGING.BREAST_ULTRASOUND;
  return payload.id ? http.put(PUT.name, PUT.url, payload) : http.post(POST.name, POST.url, payload);
};
