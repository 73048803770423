import { StoreAction } from 'constants/store';
import { StoreSpecialization, ActionType } from 'models/store';
const { SEARCH } = StoreAction.SPECIALIZATION;

const INITIAL_STATE: StoreSpecialization = {
  search: {
    data: [],
    inProgress: false
  }
};

function specializationReducer(state = INITIAL_STATE, action: ActionType): StoreSpecialization {
  switch (action.type) {
    // SEARCH
    case SEARCH.REQUEST:
      return {
        ...state,
        search: {
          ...state.search,
          inProgress: true
        }
      };
    case SEARCH.FAILED:
      return {
        ...state,
        search: {
          ...state.search,
          inProgress: false,
          error: action.payload
        }
      };
    case SEARCH.SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          inProgress: false,
          data: action.payload
        }
      };
    // END SEARCH
    default:
      return state;
  }
}

export default specializationReducer;
