import './Modal.scss';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from './Modal';
import { StoreModal, StoreState } from 'models/store';
import { modalAction, ModalAction } from 'state/actions/modal';
import { bindActionCreators } from 'redux';

interface StateMap {
  modals: StoreModal;
}

type DispatchMap = ModalAction;

type Props = StateMap & DispatchMap;

const MSP = (store: StoreState): StateMap => ({ modals: store.modal });
const MDP = (dispatch: any) => bindActionCreators({ ...modalAction }, dispatch);

export const ModalContainer: React.FC<Props> = props => {
  const { hideModal } = props;
  const { data: modals } = props.modals;
  return (
    <React.Fragment>
      {modals.map((modal, index) => {
        modal = {
          ...modal,
          toggle: () => hideModal(index)
        };
        return <Modal key={index} index={index} {...modal} />;
      })}
    </React.Fragment>
  );
};

export default connect(MSP, MDP)(ModalContainer);
