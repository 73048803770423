import { ENDPOINTS } from 'defaults';
import { http } from 'common/services';
import { AxiosPromise } from 'axios';
import { _AppointmentAttachment } from 'models/endpoint';

const transformFileToFormData = (file: File): FormData => {
  let formData = new FormData();
  formData.append('file', file);

  return formData;
};

export const updateDoctorAvatar = (file: File): AxiosPromise => {
  const endpoint = ENDPOINTS.USER.AVATAR.PUT;
  const payload = transformFileToFormData(file);
  return http.put(endpoint.name, endpoint.url, payload);
};

export const getRecordAttachments = (id: number, fileType: 'general' | 'notes_and_findings' | 'forms') => {
  const endpoint = ENDPOINTS.DOCTOR.RECORD.ATTACHMENT.GET.parse({
    patient_health_record_id: id,
    file_type: fileType || 'general'
  });
  return http.get(endpoint.name, endpoint.url);
};

export const addRecordAttachment = (
  id: number,
  file: File,
  fileType: 'general' | 'notes_and_findings' | 'forms'
): AxiosPromise => {
  let payload = new FormData();
  payload.append('file', file);
  payload.append('patient_health_record_id', `${id}`);
  fileType && payload.append('file_type', fileType);

  const endpoint = ENDPOINTS.DOCTOR.RECORD.ATTACHMENT.POST;

  return http.post(endpoint.name, endpoint.url, payload);
};

export const removeRecordAttachment = (id: number, historicalRecordId: number) => {
  const endpoint = ENDPOINTS.DOCTOR.RECORD.ATTACHMENT.DELETE;
  return http.delete(endpoint.name, endpoint.url, { id, patient_health_record_id: historicalRecordId });
};

export const addAppointmentAttachment = (
  appointmentId: number,
  file: File,
  fileType: 'clinic' | 'service',
  status?: 'new' | 'confirmed' | 'rejected',
  rejectReason?: string
): AxiosPromise => {
  let payload = new FormData();
  payload.append('file', file);
  payload.append('appointment_id', `${appointmentId}`);
  fileType && payload.append('file_type', fileType);
  status !== undefined && payload.append('status', status);
  rejectReason !== undefined && payload.append('reject_reason', rejectReason);

  const endpoint = ENDPOINTS.DOCTOR.APPOINTMENT.FILE.key;

  return http.post(endpoint.name, endpoint.url, payload);
};

export const getAppointmentAttachments = (id: number, fileType: 'clinic' | 'service') => {
  const endpoint = ENDPOINTS.DOCTOR.APPOINTMENT.FILE.parse({ appointment_id: id, file_type: fileType || 'clinic' });
  return http.get(endpoint.name, endpoint.url);
};

export const removeAppointmentAttachment = (id: number, historicalRecordId: number) => {
  const endpoint = ENDPOINTS.DOCTOR.APPOINTMENT.FILE.key;
  return http.delete(endpoint.name, endpoint.url, { id, appointment_id: historicalRecordId });
};

export const updateAppointmentAttachment = (
  id: number,
  appointmentId: number,
  status: string,
  rejectReason: string
) => {
  const endpoint = ENDPOINTS.DOCTOR.APPOINTMENT.FILE.key;
  const payload: Partial<_AppointmentAttachment> = {
    id,
    appointment_id: appointmentId,
    status,
    reject_reason: rejectReason
  };
  return http.put(endpoint.name, endpoint.url, payload);
};
