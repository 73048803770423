import HeartJson from 'assets/json/heart-loader.json';
import TMCGif from 'assets/images/tmc-loader.gif';
import classnames from 'classnames';
import { styled } from 'components/Theme';
import lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';
import './Loading.scss';

interface Props {
  loading: boolean;
  className?: string;
  type?: 'heart' | 'tmc' | '';
  project?: 'core' | 'tmc' | 'keralty' | '';
}
export const Loading: React.FC<Props> = props => {
  let { loading, className = '', type = '', children, project } = props;
  const loaderClass = classnames('c-loader', className);

  const RenderLoader = () => {
    if (project === 'core' || project === '') return <HeartLoader className={loaderClass} />;
    else if (project === 'tmc') return <DefaultLoader isTMC className={loaderClass} />;
    return <DefaultLoader isTMC={false} className={loaderClass} />;
  };
  return loading ? RenderLoader() : <>{children}</>;
};

const DefaultLoader: React.FC<{ className: string; isTMC?: boolean }> = React.memo(({ className, isTMC }) => {
  return (
    <LoadingWrapper className={className.trim()}>
      {isTMC ? (
        <img src={TMCGif} className="gif" alt="loader" />
      ) : (
        <div className="loading">
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
        </div>
      )}
    </LoadingWrapper>
  );
});

const HeartLoader: React.FC<{ className: string }> = React.memo(({ className }) => {
  const heartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (heartRef.current) {
      lottie.loadAnimation({
        container: heartRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: HeartJson
      });
    }

    return () => {
      heartRef.current = null;
    };
  }, []);

  return (
    <HeartLoaderWrapper className={className.trim()}>
      <div className="c-heart-loader" ref={heartRef}></div>
    </HeartLoaderWrapper>
  );
});

const HeartLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .c-heart-loader {
    height: 100px;
    width: auto;
  }
`;

const LoadingWrapper = styled.div`
  &.c-loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;
    &.full {
      height: 100%;
      margin: 0;
    }
  }

  @keyframes loading {
    0% {
      left: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    25% {
      left: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      left: 30px;
    }
    75% {
      left: 60px;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    100% {
      left: 72px;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
  }
  .gif {
    width: 140px;
    height: 140px;
  }
  .loading {
    position: relative;
    width: 100px !important;
    height: 26px !important;
    div {
      & > div {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #dae8e5;
        position: absolute;
        top: 0;
        left: 0;
        animation: loading 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
      }
      &:nth-child(2) div {
        -webkit-animation-delay: -0.75s;
        animation-delay: -0.75s;
        background: ${props => props.theme.color.primaryLight};
      }
      &:nth-child(3) div {
        -webkit-animation-delay: -0.375s;
        animation-delay: -0.375s;
        background: ${props => props.theme.color.primary};
      }
      &:nth-child(4) div {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        background: #d2d2cb;
      }
    }
  }
`;
