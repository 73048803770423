import * as Sentry from '@sentry/browser';
import Axios, { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { Toast } from 'components';
import { ERROR_MAP } from 'defaults';
import { handleLogout } from 'services/auth.service';
import { getProject } from 'services/utils.service';
import { errorHandler } from '../services';
import { getLocalStorageData } from './utils';

const createHttpConfig = async (
  url: string,
  accessToken = '',
  isPublic = false,
  cancelToken?: CancelToken
): Promise<AxiosRequestConfig> => {
  const { project } = getProject();
  const { activeAccount, ...localStorage } = getLocalStorageData();
  if (!isPublic && !accessToken) {
    accessToken = localStorage.assumeToken || localStorage.accessToken || '';
  }
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: accessToken,
      'Content-Type': 'application/json',
      'X-PATH': url,
      'X-ENV': import.meta.env.VITE_NODE_ENV || '',
      // As we iterate on autosaving, we have to periodiclly remove logic from the server.
      // Server checks this value to see if it should autosave certain fields or not
      'X-CLIENT-AUTOSV-VERSION': 1,
      Project: project,
      AccountId: activeAccount
    },
    cancelToken
  };
  return {
    ...config
  };
};

const basePath = (url: string) => {
  if (url.startsWith('/api')) return '/v1/api';
  return '/api';
};

async function getImage(name: string, url: string): Promise<any> {
  const config = await createHttpConfig(url);
  return new Promise((res, rej) => {
    Axios.get(`${basePath(url)}?${name}`, { responseType: 'blob', ...config })
      .then(data => res(data))
      .catch(err => {
        if (Axios.isCancel(err)) {
          rej(err);
          return;
        }

        const tmp = errorHandler(err);
        const error = ERROR_MAP[tmp.error];
        if (tmp.error === 'the_access_token_expired') {
          return handleLogout();
        }
        Sentry.captureException(err);
        error && Toast.error(error || 'An error occurred.');
        rej(tmp);
      });
  });
}

async function get<T = any>(
  name: string,
  url: string,
  accessToken?: string,
  isPublic = false
): Promise<AxiosResponse<T>> {
  const config = await createHttpConfig(url, accessToken, isPublic);
  return new Promise((res, rej) => {
    return Sentry.startSpan(
      {
        name: `GET ${name}`,
        op: 'http.client'
      },
      () =>
        Axios.get<T>(`${basePath(url)}?${name}_get`, config)
          .then(data => res(data))
          .catch(err => {
            if (Axios.isCancel(err)) {
              rej(err);
              return;
            }
            const tmp = errorHandler(err);
            const error = ERROR_MAP[tmp.error];
            if (tmp.error === 'the_access_token_expired' || tmp.status === 401) {
              return handleLogout();
            }
            Sentry.captureException(err);
            error && Toast.error(error || 'An error occurred.');
            rej(tmp);
          })
    );
  });
}

async function cancellablePost<T = any>(
  name: string,
  url: string,
  payload: any,
  cancelToken?: CancelToken,
  accessToken?: string,
  isPublic = false
): Promise<AxiosResponse<T>> {
  const config = await createHttpConfig(url, accessToken, isPublic, cancelToken);
  return Sentry.startSpan(
    {
      name: `POST ${name}`,
      op: 'http.client'
    },
    () =>
      new Promise((res, rej) => {
        Axios.post<T>(`${basePath(url)}?${name}_post`, payload, config)
          .then(data => res(data))
          .catch(err => {
            if (Axios.isCancel(err)) {
              rej(err);
              return;
            }
            const tmp = errorHandler(err);
            if (tmp.error === 'the_access_token_expired' || tmp.status === 401) {
              return handleLogout();
            }
            Sentry.captureException(err);
            const error = ERROR_MAP[tmp.error];
            error && Toast.error(error || 'An error occurred.');
            rej(tmp);
          });
      })
  );
}

async function post<T = any>(
  name: string,
  url: string,
  payload: any,
  accessToken?: string,
  isPublic = false
): Promise<AxiosResponse<T>> {
  const config = await createHttpConfig(url, accessToken, isPublic);
  return Sentry.startSpan(
    {
      name: `POST ${name}`,
      op: 'http.client'
    },
    () =>
      new Promise((res, rej) => {
        Axios.post<T>(`${basePath(url)}?${name}_post`, payload, config)
          .then(data => res(data))
          .catch(err => {
            if (Axios.isCancel(err)) {
              rej(err);
              return;
            }
            const tmp = errorHandler(err);
            if (tmp.error === 'the_access_token_expired' || tmp.status === 401) {
              return handleLogout();
            }
            Sentry.captureException(err);
            const error = ERROR_MAP[tmp.error];
            error && Toast.error(error || 'An error occurred.');
            rej(tmp);
          });
      })
  );
}

async function cancellablePut<T = any>(
  name: string,
  url: string,
  payload: any,
  cancelToken?: CancelToken,
  accessToken?: string,
  isPublic = false
): Promise<AxiosResponse<T>> {
  const config = await createHttpConfig(url, accessToken, isPublic, cancelToken);
  return Sentry.startSpan(
    {
      name: `PUT ${name}`,
      op: 'http.client'
    },
    () =>
      new Promise((res, rej) => {
        Axios.put<T>(`${basePath(url)}?${name}_put`, payload, config)
          .then(data => res(data))
          .catch(err => {
            if (Axios.isCancel(err)) {
              rej(err);
              return;
            }
            const tmp = errorHandler(err);
            if (tmp.error === 'the_access_token_expired' || tmp.status === 401) {
              return handleLogout();
            }
            Sentry.captureException(err);
            const error = ERROR_MAP[tmp.error];
            error && Toast.error(error || 'An error occurred.');
            rej(tmp);
          });
      })
  );
}

async function put<T = any>(
  name: string,
  url: string,
  payload: any,
  accessToken?: string,
  isPublic = false
): Promise<AxiosResponse<T>> {
  const config = await createHttpConfig(url, accessToken, isPublic);
  return Sentry.startSpan(
    {
      name: `PUT ${name}`,
      op: 'http.client'
    },
    () =>
      new Promise((res, rej) => {
        Axios.put<T>(`${basePath(url)}?${name}_put`, payload, config)
          .then(data => res(data))
          .catch(err => {
            if (Axios.isCancel(err)) {
              rej(err);
              return;
            }
            const tmp = errorHandler(err);
            if (tmp.error === 'the_access_token_expired' || tmp.status === 401) {
              return handleLogout();
            }
            Sentry.captureException(err);
            const error = ERROR_MAP[tmp.error];
            error && Toast.error(error || 'An error occurred.');
            rej(tmp);
          });
      })
  );
}

async function cancellablePatch<T = any>(
  name: string,
  url: string,
  payload: any,
  cancelToken?: CancelToken,
  accessToken?: string,
  isPublic = false
): Promise<AxiosResponse<T>> {
  const config = await createHttpConfig(url, accessToken, isPublic, cancelToken);
  return Sentry.startSpan(
    {
      name: `PATCH ${name}`,
      op: 'http.client'
    },
    () =>
      new Promise((res, rej) => {
        Axios.patch<T>(`${basePath(url)}?${name}_patch`, payload, config)
          .then(data => res(data))
          .catch(err => {
            if (Axios.isCancel(err)) {
              rej(err);
              return;
            }
            const tmp = errorHandler(err);
            if (tmp.error === 'the_access_token_expired' || tmp.status === 401) {
              return handleLogout();
            }
            Sentry.captureException(err);
            const error = ERROR_MAP[tmp.error];
            error && Toast.error(error || 'An error occurred.');
            rej(tmp);
          });
      })
  );
}

async function patch<T = any>(
  name: string,
  url: string,
  payload: any,
  accessToken?: string,
  isPublic = false
): Promise<AxiosResponse<T>> {
  const config = await createHttpConfig(url, accessToken, isPublic);
  return Sentry.startSpan(
    {
      name: `PATCH ${name}`,
      op: 'http.client'
    },
    () =>
      new Promise((res, rej) => {
        Axios.patch<T>(`${basePath(url)}?${name}_patch`, payload, config)
          .then(data => res(data))
          .catch(err => {
            if (Axios.isCancel(err)) {
              rej(err);
              return;
            }
            const tmp = errorHandler(err);
            if (tmp.error === 'the_access_token_expired' || tmp.status === 401) {
              return handleLogout();
            }
            Sentry.captureException(err);
            const error = ERROR_MAP[tmp.error];
            error && Toast.error(error || 'An error occurred.');
            rej(tmp);
          });
      })
  );
}

async function remove(name: string, url: string, payload: any, accessToken?: string, isPublic = false): Promise<any> {
  const config = await createHttpConfig(url, accessToken, isPublic);
  return Sentry.startSpan(
    {
      name: `DELETE ${name}`,
      op: 'http.client'
    },
    () =>
      new Promise((res, rej) => {
        Axios.delete(`${basePath(url)}?${name}_delete`, Object.assign(config, { data: payload }))
          .then(data => res(data))
          .catch(err => {
            if (Axios.isCancel(err)) {
              rej(err);
              return;
            }
            const tmp = errorHandler(err);
            if (tmp.error === 'the_access_token_expired' || tmp.status === 401) {
              return handleLogout();
            }
            Sentry.captureException(err);
            const error = ERROR_MAP[tmp.error];
            error && Toast.error(error || 'An error occurred.');
            rej(tmp);
          });
      })
  );
}

async function getAll<T = any>(
  name: string,
  url: string,
  accessToken?: string,
  isPublic = false
): Promise<AxiosResponse<T>> {
  const config = await createHttpConfig(url, accessToken, isPublic);
  return Sentry.startSpan(
    {
      name: `GET ${name}`,
      op: 'http.client'
    },
    () =>
      new Promise((res, rej) => {
        Axios.get(`${basePath(url)}?${name}_get`, config)
          .then(async resp => {
            const config2 = await createHttpConfig(
              `${url}${url.includes('?') ? '&' : '?'}page=1&per_page=${
                resp.headers['x-total'] > 0 ? resp.headers['x-total'] : 1
              }`,
              accessToken,
              isPublic
            );
            return Axios.get<T>(`${basePath(url)}?${name}`, config2);
          })
          .then(data => res(data))
          .catch(err => {
            if (Axios.isCancel(err)) {
              rej(err);
              return;
            }
            Sentry.captureException(err);
            const tmp = errorHandler(err);
            if (tmp.error === 'the_access_token_expired' || tmp.status === 401) {
              handleLogout();
            }
            const error = ERROR_MAP[tmp.error];
            error && Toast.error(error || 'An error occurred.');
            rej(tmp);
          });
      })
  );
}

async function newGet<T = any>(url: string, accessToken?: string, isPublic = false): Promise<AxiosResponse<T>> {
  const config = await createHttpConfig(url, accessToken, isPublic);
  return Sentry.startSpan(
    {
      name: `GET ${url}`,
      op: 'http.client'
    },
    () =>
      new Promise((res, rej) => {
        Axios.get<T>(url, config)
          .then(data => res(data))
          .catch(err => {
            if (Axios.isCancel(err)) {
              rej(err);
              return;
            }
            const tmp = errorHandler(err);
            if (tmp.error === 'the_access_token_expired' || tmp.status === 401) {
              return handleLogout();
            }
            Sentry.captureException(err);
            const error = ERROR_MAP[tmp.error];
            error && Toast.error(error || 'An error occurred.');
            rej(tmp);
          });
      })
  );
}

async function newPost<T = any>(
  url: string,
  payload: any,
  accessToken?: string,
  isPublic = false
): Promise<AxiosResponse<T>> {
  const config = await createHttpConfig(url, accessToken, isPublic);
  return Sentry.startSpan(
    {
      name: `POST ${url}`,
      op: 'http.client'
    },
    () =>
      new Promise((res, rej) => {
        Axios.post<T>(url, payload, config)
          .then(data => res(data))
          .catch(err => {
            if (Axios.isCancel(err)) {
              rej(err);
              return;
            }
            const tmp = errorHandler(err);
            if (tmp.error === 'the_access_token_expired' || tmp.status === 401) {
              return handleLogout();
            }
            Sentry.captureException(err);
            const error = ERROR_MAP[tmp.error];
            error && Toast.error(error || 'An error occurred.');
            rej(tmp);
          });
      })
  );
}

function getPDF(data: any) {
  return Axios.post(`/v1/printing`, data, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf'
    }
  });
}

export const http = {
  get,
  getImage,
  getAll,
  post,
  newGet,
  newPost,
  put,
  patch,
  delete: remove,
  getPDF,
  cancellablePut,
  cancellablePost,
  cancellablePatch,
  isCancel: Axios.isCancel
};
