import { getLocalStorageData } from 'common/services';
import { ROUTES } from 'constants/routes';
import { User, UserTheme, UserType } from 'models';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isInStringBlackList } from 'services/utils.service';

interface Props {
  component: any;
  isAuthenticated: boolean;
  currentUser?: User;
  socket: SocketIOClient.Socket | null;
  [key: string]: any;
}

export const AuthenticatedRoute = ({ component: C, isAuthenticated, currentUser, socket, ...props }: Props) => {
  const path = props['path'];
  const localStorage = getLocalStorageData();
  const location = window.location.pathname + window.location.search;
  const BCMAPExclusiveBlackList = [ROUTES.APPOINTMENT.HOME];
  if (socket && socket.connected) {
    socket.emit('location', location);
  }

  let redirectUrl = '';

  if (!isAuthenticated) {
    if (localStorage.userTheme === UserTheme.BCMAP) {
      redirectUrl = ROUTES.BCMAP.LOGIN;
    } else {
      redirectUrl = ROUTES.LOGIN;
    }
  } else if (currentUser) {
    const { onboard } = currentUser;
    if (
      (currentUser.type === UserType.Navigator || props.isBcmapDoctorOnly) &&
      isInStringBlackList(path, BCMAPExclusiveBlackList)
    ) {
      redirectUrl = ROUTES.PATIENTS.LIST;
    } else if (!onboard) {
      if (path !== ROUTES.ONBOARDING) {
        redirectUrl = ROUTES.ONBOARDING;
      }
    } else {
      if (path === ROUTES.ONBOARDING) {
        redirectUrl = ROUTES.DASHBOARD;
      } else {
        if (localStorage.activeAccount === 0 && path !== ROUTES.DASHBOARD) {
          redirectUrl = ROUTES.DASHBOARD;
        } else if (currentUser.type === UserType.Secretary || currentUser.type === UserType.ProserAdmin) {
          if (!localStorage.assumeToken && path !== ROUTES.DASHBOARD) {
            redirectUrl = ROUTES.DASHBOARD;
          }
        }
      }
    }
  }

  return <Route {...props} render={props => (redirectUrl ? <Redirect to={redirectUrl} /> : <C {...props} />)} />;
};
