import { Chip as MatChip, ChipProps, Tooltip } from '@material-ui/core';
import { styled } from 'components/Theme';
import React from 'react';

interface Props extends ChipProps {
  toolTip?: string;
}

export const Chip: React.FC<Props> = ({ toolTip, ...props }) => {
  if (toolTip) {
    return (
      <Tooltip title={toolTip}>
        <ChipWrapper {...props} />
      </Tooltip>
    );
  }
  return <ChipWrapper {...props} />;
};

const ChipWrapper = styled(MatChip)`
  height: 35px;
  .MuiChip-label {
    padding: 0 16px;
  }
  &.MuiChip-colorPrimary {
    background-color: ${props => props.theme.color.primary};
    .MuiChip-label {
      color: #fff;
    }
  }
  &.MuiChip-colorSecondary {
    background-color: ${props => props.theme.color.secondary};
    .MuiChip-label {
      color: ${props => props.theme.color.primary};
    }
    .MuiChip-deleteIconColorSecondary {
      color: rgba(00, 0, 0, 0.7);
    }
  }
`;
