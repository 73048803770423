import pick from 'lodash/pick';
import { RheumaDiagnosis } from 'models';

const fields = {
  common: ['id', 'patient_health_record_id', 'code', 'name', 'additional', 'date_of_diagnosis'],
  hpb: [
    'stage',
    'meld_score',
    'child_pugh_score',
    'pathology_solitary',
    'pathology_bilateral',
    'pathology_multiple',
    'pathology_diffuse',
    'pathology_infiltrative',
    'segment_i',
    'segment_ii',
    'segment_iii',
    'segment_iv',
    'segment_v',
    'segment_vi',
    'segment_vii',
    'segment_viii'
  ],
  rheumatoid_arthritis: [
    'age_of_diagnosis',
    'age_of_onset',
    'rheumatoid_arthritis_rf',
    'rheumatoid_arthritis_acpa',
    'rheumatoid_arthritis_duration',
    'rheumatoid_arthritis_esr',
    'rheumatoid_arthritis_crp',
    'rheumatoid_arthritis_joints',
    'rheumatoid_arthritis_rf_score',
    'ra_lj_1',
    'ra_lj_2',
    'ra_lj_3',
    'ra_lj_4',
    'ra_lj_5',
    'ra_lj_6',
    'ra_lj_7',
    'ra_lj_8',
    'ra_lj_9',
    'ra_lj_10',
    'ra_lj_11',
    'ra_lj_12',
    'ra_lj_13',
    'ra_lj_14',
    'ra_lj_15',
    'ra_lj_16',
    'ra_lj_total',
    'ra_sj_1',
    'ra_sj_2',
    'ra_sj_3',
    'ra_sj_4',
    'ra_sj_5',
    'ra_sj_6',
    'ra_sj_7',
    'ra_sj_8',
    'ra_sj_9',
    'ra_sj_10',
    'ra_sj_11',
    'ra_sj_12',
    'ra_sj_13',
    'ra_sj_14',
    'ra_sj_15',
    'ra_sj_16',
    'ra_sj_17',
    'ra_sj_18',
    'ra_sj_19',
    'ra_sj_20',
    'ra_sj_21',
    'ra_sj_22',
    'ra_sj_23',
    'ra_sj_24',
    'ra_sj_25',
    'ra_sj_26',
    'ra_sj_27',
    'ra_sj_28',
    'ra_sj_29',
    'ra_sj_30',
    'ra_sj_31',
    'ra_sj_32',
    'ra_sj_33',
    'ra_sj_34',
    'ra_sj_35',
    'ra_sj_36',
    'ra_sj_37',
    'ra_sj_38',
    'ra_sj_39',
    'ra_sj_40',
    'ra_sj_41',
    'ra_sj_42',
    'ra_sj_43',
    'ra_sj_44',
    'ra_sj_45',
    'ra_sj_46',
    'ra_sj_47',
    'ra_sj_48',
    'ra_sj_49',
    'ra_sj_50',
    'ra_sj_51',
    'ra_sj_52',
    'ra_sj_53',
    'ra_sj_54',
    'ra_sj_55',
    'ra_sj_56',
    'ra_sj_57',
    'ra_sj_58',
    'ra_sj_59',
    'ra_sj_60',
    'ra_sj_total',
    'disease_activity_esr',
    'disease_activity_crp',
    'disease_activity_pga',
    'disease_activity_ega',
    'disease_activity_notes',
    'swollen_lj_1',
    'swollen_lj_2',
    'swollen_lj_3',
    'swollen_lj_4',
    'swollen_lj_5',
    'swollen_lj_6',
    'swollen_lj_7',
    'swollen_lj_8',
    'swollen_lj_9',
    'swollen_lj_10',
    'swollen_lj_11',
    'swollen_lj_12',
    'swollen_lj_13',
    'swollen_lj_14',
    'swollen_lj_15',
    'swollen_lj_16',
    'swollen_lj_total',
    'swollen_sj_1',
    'swollen_sj_2',
    'swollen_sj_3',
    'swollen_sj_4',
    'swollen_sj_5',
    'swollen_sj_6',
    'swollen_sj_7',
    'swollen_sj_8',
    'swollen_sj_9',
    'swollen_sj_10',
    'swollen_sj_11',
    'swollen_sj_12',
    'swollen_sj_13',
    'swollen_sj_14',
    'swollen_sj_15',
    'swollen_sj_16',
    'swollen_sj_17',
    'swollen_sj_18',
    'swollen_sj_19',
    'swollen_sj_20',
    'swollen_sj_21',
    'swollen_sj_22',
    'swollen_sj_23',
    'swollen_sj_24',
    'swollen_sj_25',
    'swollen_sj_26',
    'swollen_sj_27',
    'swollen_sj_28',
    'swollen_sj_29',
    'swollen_sj_30',
    'swollen_sj_31',
    'swollen_sj_32',
    'swollen_sj_33',
    'swollen_sj_34',
    'swollen_sj_35',
    'swollen_sj_36',
    'swollen_sj_37',
    'swollen_sj_38',
    'swollen_sj_39',
    'swollen_sj_40',
    'swollen_sj_41',
    'swollen_sj_42',
    'swollen_sj_43',
    'swollen_sj_44',
    'swollen_sj_45',
    'swollen_sj_46',
    'swollen_sj_47',
    'swollen_sj_48',
    'swollen_sj_49',
    'swollen_sj_50',
    'swollen_sj_51',
    'swollen_sj_52',
    'swollen_sj_53',
    'swollen_sj_54',
    'swollen_sj_55',
    'swollen_sj_56',
    'swollen_sj_57',
    'swollen_sj_58',
    'swollen_sj_59',
    'swollen_sj_60',
    'swollen_sj_total',
    'tender_lj_1',
    'tender_lj_2',
    'tender_lj_3',
    'tender_lj_4',
    'tender_lj_5',
    'tender_lj_6',
    'tender_lj_7',
    'tender_lj_8',
    'tender_lj_9',
    'tender_lj_10',
    'tender_lj_11',
    'tender_lj_12',
    'tender_lj_13',
    'tender_lj_14',
    'tender_lj_15',
    'tender_lj_16',
    'tender_lj_total',
    'tender_sj_1',
    'tender_sj_2',
    'tender_sj_3',
    'tender_sj_4',
    'tender_sj_5',
    'tender_sj_6',
    'tender_sj_7',
    'tender_sj_8',
    'tender_sj_9',
    'tender_sj_10',
    'tender_sj_11',
    'tender_sj_12',
    'tender_sj_13',
    'tender_sj_14',
    'tender_sj_15',
    'tender_sj_16',
    'tender_sj_17',
    'tender_sj_18',
    'tender_sj_19',
    'tender_sj_20',
    'tender_sj_21',
    'tender_sj_22',
    'tender_sj_23',
    'tender_sj_24',
    'tender_sj_25',
    'tender_sj_26',
    'tender_sj_27',
    'tender_sj_28',
    'tender_sj_29',
    'tender_sj_30',
    'tender_sj_31',
    'tender_sj_32',
    'tender_sj_33',
    'tender_sj_34',
    'tender_sj_35',
    'tender_sj_36',
    'tender_sj_37',
    'tender_sj_38',
    'tender_sj_39',
    'tender_sj_40',
    'tender_sj_41',
    'tender_sj_42',
    'tender_sj_43',
    'tender_sj_44',
    'tender_sj_45',
    'tender_sj_46',
    'tender_sj_47',
    'tender_sj_48',
    'tender_sj_49',
    'tender_sj_50',
    'tender_sj_51',
    'tender_sj_52',
    'tender_sj_53',
    'tender_sj_54',
    'tender_sj_55',
    'tender_sj_56',
    'tender_sj_57',
    'tender_sj_58',
    'tender_sj_59',
    'tender_sj_60',
    'tender_sj_total'
  ]
};

export function assemblePayloadForRheumatoidArthritis(diagnosis: RheumaDiagnosis, encounterId: number) {
  const common = {
    ...pick(diagnosis, ...fields.common),
    patient_health_record_id: encounterId
  };
  return {
    ...common,
    ...pick(
      {
        ...diagnosis.raEvaluation,
        ...diagnosis.diseaseActivity
      },
      ...fields.rheumatoid_arthritis
    )
  };
}
