import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Search, Visibility, VisibilityOff } from '@material-ui/icons';
import classNames from 'classnames';
import { FieldAttributes, FieldSubAttributes, useField } from 'components/form';
import React, { useEffect, useMemo, useState } from 'react';
import { Select, SelectProps } from '../select/Select';
import './Input.scss';

export type InputProps = {
  fullWidth?: boolean;
  uncontrolled?: boolean;
  type?: string;
  unit?: any;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
  unitProps?: SelectProps;
  prefixProps?: SelectProps;
  onSearch?: () => void;
  submitOnEnter?: boolean;
  helperText?: string;
  disabled?: boolean;
  onEnterKeyPress?: (value: any) => void;
} & FieldAttributes;

export const Input: React.FC<InputProps> = ({
  label,
  fullWidth,
  type,
  unit,
  className,
  onSearch,
  rows,
  rowsMax,
  multiline = false,
  submitOnEnter = false,
  disabled,
  unitProps,
  uncontrolled = false,
  min,
  max,
  maxLength,
  minLength,
  required,
  helperText,
  onEnterKeyPress,
  prefixProps,
  ...props
}) => {
  const [field, meta] = useField<FieldSubAttributes>(props);
  const [value, setValue] = useState((field as any).value);
  const [showPassword, setShowPassword] = useState(false);
  const errorText = meta.error && meta.touched ? meta.error : '';

  let inputClass = classNames(`c-input ${className || ''}`.trim(), { full: fullWidth });
  let inputProps = {};

  useEffect(() => {
    if (field.value !== props.value) {
      setValue(field.value);
    }
  }, [field.value, props.value]);

  const valueChanged = useMemo(() => {
    const prevValue: string = (field as any).value;
    return value !== prevValue;
  }, [value, field]);

  switch (type) {
    case 'password':
      // Add password icon
      inputClass += ' c-input--password';
      inputProps = {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="Toggle password visibility" onClick={e => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      };
      break;
    case 'search':
      // Add clickable search icon
      inputClass += ' c-input--search';
      inputProps = {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => onSearch && onSearch()}>
              <Search />
            </IconButton>
          </InputAdornment>
        )
      };
      break;
  }

  if (unitProps) {
    inputClass += ' has-input-unit';
    if (prefixProps) inputClass += ' has-prepend-unit';
    inputProps = {
      startAdornment: prefixProps ? (
        <Select {...prefixProps} className={`c-input--left-unit ${prefixProps.className || ''}`.trim()} />
      ) : (
        undefined
      ),
      endAdornment: <Select {...unitProps} className={`c-input--unit ${unitProps.className || ''}`.trim()} />
    };
  } else if (unit) {
    // Add unit field as adornment
    inputProps = {
      endAdornment: <InputAdornment position="start">{unit}</InputAdornment>
    };
  }

  const onKeyDown = (ev: any) => {
    // On Enter
    if (ev.keyCode === 13) {
      if (onEnterKeyPress && !submitOnEnter) {
        ev.preventDefault();
        onEnterKeyPress(ev);
        setValue('');
      } else {
        field.onChange(ev);
        if (type === 'search' && onSearch) {
          onSearch();
        } else if (!multiline && !submitOnEnter) {
          ev.preventDefault();
        }
      }
    }
  };

  if (uncontrolled) {
    return (
      <TextField
        name={props.name}
        onChange={e => props.onChange && props.onChange(e as any)}
        onBlur={e => props.onBlur && props.onBlur(e as any)}
        value={props.value}
        className={inputClass}
        inputProps={{
          min,
          max,
          minLength,
          maxLength,
          step: type === 'number' ? '0.01' : undefined
        }}
        InputProps={inputProps}
        type={showPassword || type === 'search' ? 'text' : type}
        variant="outlined"
        margin={'dense'}
        label={`${label || ''}${!!required ? ' *' : ''}`}
        multiline={multiline}
        onKeyDown={onKeyDown}
        fullWidth={fullWidth}
        helperText={errorText}
        disabled={disabled}
        error={!!errorText}
      />
    );
  }

  return (
    <TextField
      name={field.name}
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={(e: any) => {
        if (valueChanged && !onEnterKeyPress) {
          field.onBlur(e);
          field.onChange(e);
        }
        if (onEnterKeyPress) {
          e.target.value = '';
          field.onBlur(e);
          field.onChange(e);
          setValue('');
        }
      }}
      required={required}
      className={inputClass}
      inputProps={{
        min,
        max,
        minLength,
        maxLength,
        step: type === 'number' ? '0.01' : undefined
      }}
      InputProps={inputProps}
      InputLabelProps={type === 'date' ? { shrink: true } : undefined}
      type={showPassword || type === 'search' ? 'text' : type}
      variant="outlined"
      margin={'dense'}
      label={label}
      rows={rows}
      rowsMax={rowsMax}
      multiline={multiline}
      onKeyDown={onKeyDown}
      fullWidth={fullWidth}
      helperText={errorText || helperText}
      disabled={disabled}
      error={!!errorText}
    />
  );
};
