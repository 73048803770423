import { formatDate, getLocalStorageData } from 'common/services';

export function numberWithCommas(num: number) {
  let x = num.toFixed(2);
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const convertDateToUtc = (date: string | Date) => {
  return formatDate(new Date(date), 'yyyy-LL-dd HH:mm:ss') + ' PHT';
};

export const getDateTimezone = () => {
  const timezone = new Date().toTimeString();
  const indexSymbol = timezone.includes('+') ? '+' : timezone.includes('-') ? '-' : null;
  if (indexSymbol) {
    const index = timezone.indexOf(indexSymbol);
    return timezone.substr(index, 5);
  }
  return '';
};
