import { _PatientListItem, _PatientsOnWatchlist } from 'models/endpoint';
import { Patient, PatientImmunizationTemplate, PatientOnWatchlist } from 'models';
import { transformIn } from './user';
import { getFullName } from 'common/services';
import { _PatientImmunizationTemplate, _PatientImmunizationTemplateInput } from 'models/endpoint/patient';
import { IMMUNIZATION_VACCINATION_RECORD } from 'constants/patient';

const getWatchlistFlag = (patient: _PatientListItem) => {
  if (patient.on_watch_list && typeof patient.on_watch_list === 'boolean') return patient.on_watch_list;
  if (patient.summary && typeof patient.summary.on_watch_list === 'boolean') return patient.summary.on_watch_list;
  return false;
};

export const transformInPatient = (patient: _PatientListItem): Patient => {
  const user = transformIn.user(patient);
  return {
    ...user,
    id: patient.id,
    name: patient.name || getFullName(user),
    avatar: patient.avatar,
    visitDate: patient?.visit_date ? new Date(patient.visit_date) : null,
    diagnosis: patient.diagnosis,
    birthDate: patient.birth_date ? new Date(patient.birth_date) : null,
    gender: patient.gender,
    hmo: patient.hmo,
    philhealth: patient.philhealth,
    forExtraction: patient.for_extraction,
    active: patient.active,
    createdAt: new Date(patient.created_at),
    phrId: patient.phr_id || undefined,
    eligibleTrialCount: patient.eligible_trial_count,
    eligiblePatientSupportProgramCount: patient.eligible_support_program_count,
    defaultWalletId: patient.default_wallet_id,
    defaultWalletBalance: patient.default_wallet_balance,
    onWatchlist: getWatchlistFlag(patient),
    summary: patient.summary ? transformIn.summary(patient.summary) : undefined,
    submissionFormTypes: patient.submission_form_types,
    code: patient.code
  };
};

export const transformInPatientList = (patients: _PatientListItem[]): Patient[] => {
  return patients.map(patient => transformInPatient(patient));
};

export const transformInPatientsOnWatchlist = (patients: _PatientsOnWatchlist[]): PatientOnWatchlist[] => {
  return patients.map(patient => {
    const patientDetails = transformInPatient(patient);
    return {
      ...patientDetails,
      snooze: patient?.status?.snooze,
      turnOffNotifications: patient?.status?.turn_off_notifications,
      lastUpdatedAt: patient?.status?.last_updated_at ? new Date(patient?.status?.last_updated_at) : null
    } as PatientOnWatchlist;
  });
};

export const transformInImmunizationTemplate = (data: _PatientImmunizationTemplate): PatientImmunizationTemplate => {
  return {
    id: data.id,
    patientId: data.patient_id,
    templateId: data.medical_certificate_template_id,
    patientName: '',
    dateOfBirth: null,
    gender: '',
    nationality: data.details.nationality,
    condition: data.details.condition,
    signature: data.details.signature,
    vaccinationRecords: data.details.vaccination_records
      ? JSON.parse(data.details.vaccination_records)
      : [IMMUNIZATION_VACCINATION_RECORD]
  };
};

export const transformOutImmunizationTemplate = (
  form: PatientImmunizationTemplate
): _PatientImmunizationTemplateInput => {
  return {
    id: form.id ? form.id : undefined,
    patient_id: form.patientId,
    medical_certificate_template_id: form.templateId,
    nationality: form.nationality,
    condition: form.condition,
    signature: form.signature,
    vaccination_records: JSON.stringify(form.vaccinationRecords)
  };
};
