import { getLocalStorageData } from 'common/services';
import { ROUTES } from 'constants/routes';
import { UserTheme } from 'models';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAsync, useFavicon } from 'react-use';
import { getProject } from 'services/utils.service';
import { Toast } from '../components';
import axios, { CancelToken } from 'axios';
import * as Sentry from '@sentry/browser';
import { useAutosaveContext } from '../context/AutosaveContext';
import riskFactors from 'forms/_presentation/pulmonology/riskFactors';

export const useEnv = () => {
  const { location } = useHistory();
  const env = import.meta.env.VITE_NODE_ENV || 'local';
  const userTheme = getLocalStorageData().userTheme;
  const { isTMC, isKeralty, isCore, project } = getProject();
  const inMedCheckAuth = useMemo(() => {
    if (!isCore) return false;
    const path = location.pathname;
    return path === ROUTES.LOGIN || path === ROUTES.SIGNUP;
  }, [isCore, location.pathname]);

  const inBCMAPAuth = useMemo(() => {
    if (!isCore) return false;
    const path = location.pathname;
    return (
      path === ROUTES.BCMAP.BASE ||
      path === ROUTES.BCMAP.LOGIN ||
      path === ROUTES.BCMAP.SIGNUP ||
      path === ROUTES.BCMAP.CONFIRMATION
    );
  }, [isCore, location.pathname]);

  return {
    project,
    isProduction: env === 'production',
    isStaging: env === 'staging',
    isDevelopment: env === 'development',
    isLocal: env === 'local',
    isTMC,
    isKeralty,
    isMedCheck: isCore,
    isBCMAP: isCore && userTheme === UserTheme.BCMAP,
    inMedCheckAuth,
    inBCMAPAuth,
    inTMCAuth: isTMC,
    inKeraltyAuth: isKeralty,
    theme: userTheme,
    isBCMAPTheme: userTheme === UserTheme.BCMAP,
    isTMCTheme: userTheme === UserTheme.TMC,
    isMedCheckTheme: userTheme === UserTheme.MedCheck
  };
};

export const useWatchEnrollmentForm = (watch: any) => {
  const all: any = watch([
    'info.hospitalOfTreatment',
    'info.heightValue',
    'info.heightUnit',
    'info.weightValue',
    'info.weightUnit',
    'cancer.metastasis',
    'cancer.pain',
    'cancer.biopsy',
    'cancer.definitiveSurgeryDone',
    'cancer.tTumor',
    'cancer.nLymphNodes',
    'cancer.mMetastasis',
    'cancer.clinicalStage',
    'cancer.historicalDiagnosis',
    'cancer.icd',
    'cancer.definitiveSurgeryDoneProcedure',
    'cancer.skinNippleInfiltration',
    'coMorbidities.hypertension',
    'coMorbidities.diabetes',
    'coMorbidities.heartDisease',
    'coMorbidities.allergy',
    'coMorbidities.others',
    'treatmentPlan.drug',
    'treatmentPlan.drugType',
    'treatmentPlan.drugFirstLine',
    'treatmentPlan.drugSecondLine',
    'treatmentPlan.drugThirdLine',
    'demographics.longestJobHeld',
    'demographics.meansOfHealthExpOthers',
    'riskFactors.visualTactileOralInspection',
    'riskFactors.riskBreastExam',
    'riskFactors.cytoPapSmear',
    'riskFactors.riskFOBT',
    'riskFactors.upperEndoscopy',
    'riskFactors.riskLiverUltrasound',
    'riskFactors.lowDoseCTScan',
    'riskFactors.digitalRectalExam',
    'riskFactors.riskMammography',
    'riskFactors.hpvTesting',
    'riskFactors.stoolFit',
    'riskFactors.upperGISeries',
    'riskFactors.alphaFetoproteinTesting',
    'riskFactors.riskVIA',
    'riskFactors.riskColonoscopy',
    'patientStatus.screeningSupport',
  ]);
  return useMemo(() => {
    return {
      info: {
        hospitalOfTreatment: all['info.hospitalOfTreatment'],
        heightValue: all['info.heightValue'],
        heightUnit: all['info.heightUnit'],
        weightValue: all['info.weightValue'],
        weightUnit: all['info.weightUnit']
      },
      riskFactors: {
        visualTactileOralInspection: all['riskFactors.visualTactileOralInspection'],
        riskBreastExam: all['riskFactors.riskBreastExam'],
        cytoPapSmear: all['riskFactors.cytoPapSmear'],
        riskFOBT: all['riskFactors.riskFOBT'],
        upperEndoscopy: all['riskFactors.upperEndoscopy'],
        riskLiverUltrasound: all['riskFactors.riskLiverUltrasound'],
        lowDoseCTScan: all['riskFactors.lowDoseCTScan'],
        digitalRectalExam: all['riskFactors.digitalRectalExam'],
        riskMammography: all['riskFactors.riskMammography'],
        hpvTesting: all['riskFactors.hpvTesting'],
        stoolFit: all['riskFactors.stoolFit'],
        upperGISeries: all['riskFactors.upperGISeries'],
        alphaFetoproteinTesting: all['riskFactors.alphaFetoproteinTesting'],
        riskVIA: all['riskFactors.riskVIA'],
        riskColonoscopy: all['riskFactors.riskColonoscopy']
      },
      cancer: {
        metastasis: all['cancer.metastasis'],
        pain: all['cancer.pain'],
        biopsy: all['cancer.biopsy'],
        definitiveSurgeryDone: all['cancer.definitiveSurgeryDone'],
        tTumor: all['cancer.tTumor'],
        nLymphNodes: all['cancer.nLymphNodes'],
        mMetastasis: all['cancer.mMetastasis'],
        clinicalStage: all['cancer.clinicalStage'],
        historicalDiagnosis: all['cancer.historicalDiagnosis'],
        icd: all['cancer.icd'],
        definitiveSurgeryDoneProcedure: all['cancer.definitiveSurgeryDoneProcedure'],
        skinNippleInfiltration: all['cancer.skinNippleInfiltration']
      },
      coMorbidities: {
        hypertension: all['coMorbidities.hypertension'],
        diabetes: all['coMorbidities.diabetes'],
        heartDisease: all['coMorbidities.heartDisease'],
        allergy: all['coMorbidities.allergy'],
        others: all['coMorbidities.others']
      },
      treatmentPlan: {
        drug: all['treatmentPlan.drug'],
        drugType: all['treatmentPlan.drugType'],
        drugFirstLine: all['treatmentPlan.drugFirstLine'],
        drugSecondLine: all['treatmentPlan.drugSecondLine'],
        drugThirdLine: all['treatmentPlan.drugThirdLine']
      },
      demographics: {
        longestJobHeld: all['demographics.longestJobHeld'],
        meansOfHealthExpOthers: all['demographics.meansOfHealthExpOthers']
      },
      patientStatus: {
        screeningSupport: all['patientStatus.screeningSupport']
      }
    };
  }, [all]);
};

export const useWatchDrugRequestForm = (watch: any) => {
  const all: any = watch(['id']);
  return useMemo(() => {
    return {
      id: all['id']
    };
  }, [all]);
};

export const useWatchOngoingTreatmentForm = (watch: any) => {
  const all: any = watch([
    'id',
    'info.tumorMarkers',
    'medicalEvaluation.coMorbidity',
    'medicalEvaluation.newSymptom',
    'medicalEvaluation.treatmentResponse',
    'medicalEvaluation.scheduledChemoCycle',
    'medicalEvaluation.changeRegimen',
    'medicalEvaluation.sameRegimen',
    'medicalEvaluation.supportDrugs'
  ]);
  return useMemo(() => {
    return {
      id: all['id'],
      info: {
        tumorMarkers: all['info.tumorMarkers']
      },
      medicalEvaluation: {
        coMorbidity: all['medicalEvaluation.coMorbidity'],
        newSymptom: all['medicalEvaluation.newSymptom'],
        treatmentResponse: all['medicalEvaluation.treatmentResponse'],
        scheduledChemoCycle: all['medicalEvaluation.scheduledChemoCycle'],
        changeRegimen: all['medicalEvaluation.changeRegimen'],
        sameRegimen: all['medicalEvaluation.sameRegimen'],
        supportDrugs: all['medicalEvaluation.supportDrugs']
      }
    };
  }, [all]);
};

export const useWatchAfterTreatmentForm = (watch: any) => {
  const all: any = watch([
    'id',
    'info.onHormonalDrugs',
    'info.firstDefinitiveSurgery',
    'info.firstLineChemotherapy',
    'info.firstLineBiologicalAgent',
    'info.firstDefinitiveRadiotherapy',
    'medicalEvaluation.newSymptom',
    'medicalEvaluation.coMorbidity',
    'medicalEvaluation.biopsy',
    'medicalEvaluation.lineDrug2nd',
    'medicalEvaluation.lineDrugRegimen2nd',
    'medicalEvaluation.lineDrugResponse2nd',
    'medicalEvaluation.lineRadiotherapy2nd',
    'medicalEvaluation.lineSurgery2nd',
    'medicalEvaluation.lineDrug3rd',
    'medicalEvaluation.lineDrugRegimen3rd',
    'medicalEvaluation.lineDrugResponse3rd',
    'medicalEvaluation.lineRadiotherapy3rd',
    'medicalEvaluation.lineSurgery3rd',
    'medicalEvaluation.lineDrug4th',
    'medicalEvaluation.lineDrugRegimen4th',
    'medicalEvaluation.lineDrugResponse4th',
    'medicalEvaluation.lineRadiotherapy4th',
    'medicalEvaluation.lineSurgery4th',
    'survivalData.aliveDate',
    'survivalData.deathDate'
  ]);
  return useMemo(() => {
    return {
      id: all['id'],
      info: {
        onHormonalDrugs: all['info.onHormonalDrugs'],
        firstDefinitiveSurgery: all['info.firstDefinitiveSurgery'],
        firstLineChemotherapy: all['info.firstLineChemotherapy'],
        firstLineBiologicalAgent: all['info.firstLineBiologicalAgent'],
        firstDefinitiveRadiotherapy: all['info.firstDefinitiveRadiotherapy']
      },
      medicalEvaluation: {
        newSymptom: all['medicalEvaluation.newSymptom'],
        coMorbidity: all['medicalEvaluation.coMorbidity'],
        biopsy: all['medicalEvaluation.biopsy'],
        lineDrug2nd: all['medicalEvaluation.lineDrug2nd'],
        lineDrugRegimen2nd: all['medicalEvaluation.lineDrugRegimen2nd'],
        lineDrugResponse2nd: all['medicalEvaluation.lineDrugResponse2nd'],
        lineRadiotherapy2nd: all['medicalEvaluation.lineRadiotherapy2nd'],
        lineSurgery2nd: all['medicalEvaluation.lineSurgery2nd'],
        lineDrug3rd: all['medicalEvaluation.lineDrug3rd'],
        lineDrugRegimen3rd: all['medicalEvaluation.lineDrugRegimen3rd'],
        lineDrugResponse3rd: all['medicalEvaluation.lineDrugResponse3rd'],
        lineRadiotherapy3rd: all['medicalEvaluation.lineRadiotherapy3rd'],
        lineSurgery3rd: all['medicalEvaluation.lineSurgery3rd'],
        lineDrug4th: all['medicalEvaluation.lineDrug4th'],
        lineDrugRegimen4th: all['medicalEvaluation.lineDrugRegimen4th'],
        lineDrugResponse4th: all['medicalEvaluation.lineDrugResponse4th'],
        lineRadiotherapy4th: all['medicalEvaluation.lineRadiotherapy4th'],
        lineSurgery4th: all['medicalEvaluation.lineSurgery4th']
      },
      survivalData: {
        aliveDate: all['survivalData.aliveDate'],
        deathDate: all['survivalData.deathDate']
      }
    };
  }, [all]);
};

export const useWatchSupportivePalliativeCare = (watch: any) => {
  const all: any = watch([
    'patientInfo.hospitalOfTreatment',
    'patientInfo.heightValue',
    'patientInfo.heightUnit',
    'patientInfo.weightValue',
    'patientInfo.weightUnit',
    'indicateEsasScore.diagram',
    'palliativeCareNeeds.symptomaticCareOthers',
    'palliativeCareNeeds.nutritional',
    'palliativeCareNeeds.nursing',
    'palliativeCareNeeds.rehabilitation',
    'palliativeCareNeeds.psychosocial',
    'palliativeCareNeeds.spiritual',
    'palliativeCareNeeds.cultural',
    'palliativeCareNeeds.other',
    'painManagement.diagram',
    'painManagement.isUnderPainManagement',
    'painManagement.pain',
    'painManagement.adParacetamol',
    'painManagement.adNsaid',
    'painManagement.adTramadol',
    'painManagement.adStrongOpioid',
    'painManagement.adOther'
  ]);
  return useMemo(() => {
    return {
      patientInfo: {
        hospitalOfTreatment: all['patientInfo.hospitalOfTreatment'],
        heightValue: all['patientInfo.heightValue'],
        heightUnit: all['patientInfo.heightUnit'],
        weightValue: all['patientInfo.weightValue'],
        weightUnit: all['patientInfo.weightUnit']
      },
      indicateEsasScore: {
        diagram: all['indicateEsasScore.diagram']
      },
      palliativeCareNeeds: {
        symptomaticCareOthers: all['palliativeCareNeeds.symptomaticCareOthers'],
        nutritional: all['palliativeCareNeeds.nutritional'],
        nursing: all['palliativeCareNeeds.nursing'],
        rehabilitation: all['palliativeCareNeeds.rehabilitation'],
        psychosocial: all['palliativeCareNeeds.psychosocial'],
        spiritual: all['palliativeCareNeeds.spiritual'],
        cultural: all['palliativeCareNeeds.cultural'],
        other: all['palliativeCareNeeds.other']
      },
      painManagement: {
        diagram: all['painManagement.diagram'],
        isUnderPainManagement: all['painManagement.isUnderPainManagement'],
        pain: all['painManagement.pain'],
        adParacetamol: all['painManagement.adParacetamol'],
        adNsaid: all['painManagement.adNsaid'],
        adTramadol: all['painManagement.adTramadol'],
        adStrongOpioid: all['painManagement.adStrongOpioid'],
        adOther: all['painManagement.adOther']
      }
    };
  }, [all]);
};

export const useWatchCF4 = (watch: any) => {
  const all: any = watch([
    'id',
    'reasonForAdmission.rfaObgynHistoryNa',
    'reasonForAdmission.rfaPssaPain',
    'reasonForAdmission.rfaPssaOthers',
    'reasonForAdmission.rfaRfahReferred',
    'reasonForAdmission.rfaPeaGeneralAlteredSensorium',
    'drugsOutcomeAndCertification.outcomeOfTreatmentResult'
  ]);
  return useMemo(() => {
    return {
      id: all['id'],
      reasonForAdmission: {
        rfaObgynHistoryNa: all['reasonForAdmission.rfaObgynHistoryNa'],
        rfaPssaPain: all['reasonForAdmission.rfaPssaPain'],
        rfaPssaOthers: all['reasonForAdmission.rfaPssaOthers'],
        rfaRfahReferred: all['reasonForAdmission.rfaRfahReferred'],
        rfaPeaGeneralAlteredSensorium: all['reasonForAdmission.rfaPeaGeneralAlteredSensorium']
      },
      drugsOutcomeAndCertification: {
        outcomeOfTreatmentResult: all['drugsOutcomeAndCertification.outcomeOfTreatmentResult']
      }
    };
  }, [all]);
};

export const useDomain = () => {
  const { isTMC, isKeralty, isLocal, isProduction, isStaging } = useEnv();
  const hostName = window.location.hostname;
  const clinical = isProduction ? 'clinical' : isStaging ? 'clinical' : 'dev-clinical';
  const mainDomain = isLocal
    ? hostName
    : hostName
        .split('.')
        .filter((_, i) => i > 0)
        .join('.');

  let domain = isLocal
    ? `https://${isTMC ? 'tmc' : isKeralty ? 'keralty' : 'develop'}.medcheck.com.ph`
    : `https://${hostName}`;

  const parseDomain = useCallback(
    (path: string, isClinical?: boolean) => {
      return encodeURI(`${isClinical ? `https://${clinical}.${mainDomain}` : domain}${path}`);
    },
    [domain]
  );
  return useMemo(
    () => ({
      isTMC,
      isKeralty,
      parseDomain,
      domain
    }),
    [parseDomain, domain, isTMC, isKeralty]
  );
};

export const useAppFavicon = (path?: string, name?: string) => {
  const env = import.meta.env.VITE_NODE_ENV || 'development';
  useFavicon(`${env === 'development' ? '' : path ?? ''}/${name ?? 'favicon.ico'}`);
};

export const useAutosave = (
  cb: (token: CancelToken) => Promise<{ toastMsg: string } | void>,
  deps: any[] = [],
  opts?: { debounce?: number; trigger: boolean } = { trigger: true }
) => {
  const autosave = useAutosaveContext();
  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    let timeout;

    if (opts?.debounce)
      autosave.enqueue(
        new Promise(res => {
          if (opts?.debounce) setTimeout(res, opts.debounce + 150);
          else res();
        })
      );

    if (!opts.trigger || autosave.disable) return;
    let cleanup;

    const token = axios.CancelToken.source();

    const run = async () => {
      const p = cb(token.token).then(v => Toast.success(v ? v['toastMsg'] : 'Saved'));
      cleanup = autosave.enqueue(p);
      await p;
    };

    if (opts.debounce) {
      timeout = setTimeout(run, opts.debounce);
      cleanup = () => clearTimeout(timeout);
    } else {
      run();
    }

    return () => {
      if (cleanup) cleanup();
      token.cancel();
    };
  }, [...deps, autosave.disable, opts.trigger, opts.debounce]);
};

export function useSave<T, X>(
  cb: (payload: T, token: CancelToken) => Promise<{ toastMsg: string; retVal: X } | void>,
  deps?: any[],
  opts?: { debounce?: number }
): (payload: T) => Promise<X | void>;
export function useSave<T, X>(
  cb: (payload: T, token: CancelToken) => Promise<{ toastMsg: string; retVal: X }>,
  deps?: any[],
  opts?: { debounce?: number }
): (payload: T) => Promise<X>;
export function useSave<T, X>(
  cb: (payload: T, token: CancelToken) => Promise<{ toastMsg: string }>,
  deps?: any[],
  opts?: { debounce?: number }
): (payload: T) => Promise<void>;
export function useSave<T, X>(
  cb: (payload: T, token: CancelToken) => Promise<{ toastMsg: string } | void>,
  deps?: any[],
  opts?: { debounce?: number }
): (payload: T) => Promise<void>;
export function useSave<T, X>(
  cb: (payload: T, token: CancelToken) => Promise<{ toastMsg: string; retVal: X } | { toastMsg: string } | void>,
  deps?: any[],
  opts?: { debounce?: number }
): (payload: T) => Promise<X | void> {
  // self cancelling triggered autosave.
  const token = useRef(axios.CancelToken.source());
  const cleanup = useRef<null | (() => void)>(null);
  const timeout = useRef<number | null>(null);
  const autosave = useAutosaveContext();

  return useCallback(
    async payload => {
      token.current.cancel();
      token.current = axios.CancelToken.source();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      if (cleanup.current) cleanup.current();
      if (autosave.disable) return;

      if (opts?.debounce)
        autosave.enqueue(
          new Promise(res => {
            if (opts?.debounce) setTimeout(res, opts.debounce + 150);
            else res();
          })
        );

      const run = async () => {
        const p = cb(payload, token.current.token);

        // @ts-ignore
        cleanup.current = autosave.enqueue(p);
        const v = await p;

        Toast.success(v ? v['toastMsg'] : 'Saved');

        // @ts-ignore
        if (v && v.retVal) return v.retVal;
        // @ts-ignore undefined deps is totally valid
      };

      if (opts?.debounce) {
        return new Promise((res, rej) => {
          timeout.current = setTimeout(() => run().then(res).catch(rej), opts.debounce);
        });
      } else {
        return run();
      }
    },
    [deps, autosave.disable]
  );
}

export function useAutosavedState<T>(
  initial: T,
  saveHook: (payload: T, token: CancelToken) => Promise<{ toastMsg: string; retVal: T } | void>,
  deps: any[] = [],
  opts: { optimistic: boolean; debounce?: number } = { optimistic: false }
) {
  const autoSave = useAutosaveContext();
  const [state, _setState] = useState<T>(initial);
  const [saving, setSaving] = useState(false);
  const timeout = useRef<number | null>(null);
  const save = useSave(saveHook, deps);
  const setState = useCallback(
    async (payload: T | ((old: T) => T)) => {
      setSaving(true);
      let o;
      if (typeof payload === 'function') {
        o = payload(state);
      } else {
        o = payload;
      }
      let previous = state;

      if (opts.optimistic || opts.debounce != null) _setState(o);

      const run = async () => {
        const val = await save(o)
          .finally(() => setSaving(false))
          .catch(e => {
            if (axios.isCancel(e)) return 'cancel'; // ugly af
            if (opts.optimistic || opts.debounce != null) _setState(previous);
            else throw e;
          });

        if (val && val != 'cancel') _setState(val);
        else if ((opts.optimistic || opts.debounce != null) && val !== 'cancel') {
          // The promise must always return a value otherwise we bail
          _setState(previous);
        }
      };
      if (timeout.current != null) clearTimeout(timeout.current);
      if (opts.debounce) {
        autoSave.enqueue(
          new Promise(res => {
            setTimeout(res, opts.debounce + 150);
          })
        );
        timeout.current = setTimeout(run, opts.debounce);
      } else {
        await run();
      }
    },
    [save, state, opts.optimistic]
  );
  return [state, setState, saving] as const;
}

export function useAsyncSeededState<T>(
  fn: () => Promise<T>,
  deps: any[] = []
): {
  value: T | null;
  loading: boolean;
  error: Error | undefined;
  setState: React.Dispatch<React.SetStateAction<T | null>>;
  refresh: () => void;
} {
  const [resetFlag, setResetFlag] = useState(false);
  const asyncSeed = useAsync(fn, [...deps, resetFlag]);
  const [state, setState] = useState<T | null>(null);
  const dirty = useRef(false);
  useEffect(() => {
    if (!asyncSeed.loading && !dirty.current) {
      setState(asyncSeed.value || null);
    }
  }, [asyncSeed.value, asyncSeed.loading]);

  const setStateDirty = useCallback((...args) => {
    dirty.current = true;
    setState(...args);
  }, []) as React.Dispatch<React.SetStateAction<T | null>>;

  return {
    value: state,
    loading: asyncSeed.loading,
    error: asyncSeed.error,
    setState: setStateDirty,
    refresh: useCallback(() => {
      dirty.current = false;
      setResetFlag(prev => !prev);
    }, [])
  };
}
