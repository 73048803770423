import { StoreAction } from 'constants/store';
import { ActionType } from 'models/store';
import { createAction } from 'services/utils.service';
import { ModalProps } from 'components';
import { ModalType } from 'constants/form';
const { MODAL } = StoreAction;

export type NewModalAction = (options: ModalProps, type: ModalType) => ActionType;
export type HideModalAction = (index?: number) => ActionType;

export const newModal: NewModalAction = (options: ModalProps, type: ModalType) => {
  return createAction(MODAL.REQUEST, { options, type });
};

export const hideModal: HideModalAction = (index?: number) =>
  createAction(MODAL.REQUEST, { type: ModalType.Hide, index: index === undefined ? -1 : index });

export type ModalAction = {
  newModal: NewModalAction;
  hideModal: HideModalAction;
};

export const modalAction = {
  newModal,
  hideModal
};
