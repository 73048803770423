import { AxiosResponse } from 'axios';
import {
  addClinics,
  addIdentificationNumbers,
  changePassword,
  deleteAccountHMOs,
  deleteContactNumbers,
  getAccountHMOs,
  getAccountSpecializations,
  getCurrentUser,
  getDoctorClinic,
  getDoctorClinicHeader,
  getDoctorProfile,
  getLocalStorageData,
  parseDate,
  saveAccountHMOs,
  updateContactAddresses,
  updateContactNumbers,
  updateCurrentUser,
  updateCurrentUserAvatar,
  updateIdentificationNumbers,
  deleteIdentificationNumbers
} from 'common/services';
import { transformIncomingHMO } from 'common/transformer';
import { getIdentification } from 'common/services/user';
import { Toast } from 'components';
import { StoreAction } from 'constants/store';
import {
  AccountHMO,
  AccountType,
  Address,
  AddressType,
  Clinic,
  DoctorProfileForm,
  Identification,
  NavigatorProfileForm,
  OwnerType,
  SecretaryProfileForm,
  User,
  UserType
} from 'models';
import { _Account } from 'models/endpoint';
import { ActionType } from 'models/store';
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleLogout } from 'services/auth.service';
import { getSecretaryPermissions } from 'services/secretary.service';
import { transformSecretaryPermissions } from 'transformer/secretary';

const { SECRETARY, NAVIGATOR } = StoreAction;
const { GET_CURRENT, UPDATE_AVATAR, CHANGE_PASSWORD } = StoreAction.USER;
const { GET_PROFILE, UPDATE_PROFILE, GET_CLINIC, GET_CLINIC_HEADER } = StoreAction.DOCTOR;

function* getCurrentUserSaga(action: ActionType) {
  try {
    const { data } = yield call(getCurrentUser);
    const isDoctor = data && data.type === UserType.Doctor;
    const isNavigator = data && data.type === UserType.Navigator;
    yield put({ type: GET_CURRENT.SUCCESS, payload: data });
    if ((!!getLocalStorageData().assumeToken || isDoctor) && !isNavigator) {
      yield put({ type: GET_PROFILE.REQUEST, payload: null });
      yield put({ type: GET_CLINIC.REQUEST, payload: null });
      yield put({ type: GET_CLINIC_HEADER.REQUEST, payload: null });
    }
    if (isNavigator) {
      yield put({ type: NAVIGATOR.GET_DASHBOARD.REQUEST, payload: null });
    }
  } catch (err) {
    yield put({ type: GET_CURRENT.FAILED, payload: err });
  }
}

function* updateCurrentUserAvatarSaga(action: ActionType) {
  try {
    const { data } = yield call(updateCurrentUserAvatar, action.payload.file);
    yield put({ type: UPDATE_AVATAR.SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: UPDATE_AVATAR.FAILED, payload: err });
  }
}

function* getDoctorProfileSaga() {
  try {
    const activeAccount = getLocalStorageData().activeAccount;
    const { data } = yield call(getDoctorProfile);
    let specializations: any[] = [];
    let hmos: AccountHMO[] = [];
    const privateAccount = (data.accounts || []).find((acc: _Account) => acc.type === AccountType.Doctor);
    if (privateAccount) {
      const accountID = privateAccount.id;
    }
    if (activeAccount) {
      const { data: hmosRes } = yield call(getAccountHMOs, activeAccount);
      hmos = transformIncomingHMO(hmosRes);
    }
    yield put({ type: GET_PROFILE.SUCCESS, payload: { ...data, specializations, hmos } });
  } catch (err) {
    yield put({ type: GET_PROFILE.FAILED, payload: err });
  }
}

function* getDoctorClinicSaga() {
  try {
    const accountId = getLocalStorageData().activeAccount;
    if (!accountId) {
      yield put({ type: GET_CLINIC.SUCCESS, payload: [] });
    } else {
      const { data } = yield call(getDoctorClinic);
      const clinics =
        data && data.length > 0 && data.filter((item: any) => !!item.clinic).map((item: any) => item.clinic);
      yield put({ type: GET_CLINIC.SUCCESS, payload: clinics || [] });
    }
  } catch (err) {
    yield put({ type: GET_CLINIC.FAILED, payload: err });
  }
}

function* getDoctorClinicHeaderSaga() {
  try {
    const accountId = getLocalStorageData().activeAccount;
    if (!accountId) yield put({ type: GET_CLINIC_HEADER.SUCCESS, payload: undefined });
    else {
      const { data } = yield call(getDoctorClinicHeader);
      yield put({ type: GET_CLINIC_HEADER.SUCCESS, payload: data });
    }
  } catch (err) {
    yield put({ type: GET_CLINIC_HEADER.FAILED, payload: err });
  }
}

function* updateDoctorProfileSaga(action: ActionType) {
  const activeAccount = getLocalStorageData().activeAccount;
  const cUser: User = action.payload.current.user;
  const cIdentifications: Identification[] = action.payload.current.identifications;
  const currentContactNumbers = cUser.contactNumbers;
  const cHmos: AccountHMO[] = action.payload.current.hmos || [];
  try {
    const { address, contactNumbers, prc, s2, ptr, hmos, ...profile }: DoctorProfileForm = action.payload.payload;

    // Delete Contact Numbers
    const newContactIds = (contactNumbers || []).filter(c => !!c.id).map(c => parseInt(`${c.id}`));
    const deleteContactIds = ((currentContactNumbers as any[]) || [])
      .filter(c => newContactIds.indexOf(c.id) < 0)
      .map(c => c.id);
    if (deleteContactIds.length > 0) yield call(deleteContactNumbers, deleteContactIds, cUser.id);

    // Add Contact Numbers
    yield call(updateContactNumbers, contactNumbers);

    if (cUser.addresses.length === 0) {
      const addresses: Partial<Address>[] = [
        {
          ownerId: cUser.id,
          ownerType: OwnerType.User,
          addressType: AddressType.Work,
          ...address
        }
      ];
      // Add Address
      yield call(updateContactAddresses, addresses);
    } else {
      //Temporary since the address is currently just a single line control
      const addresses: Partial<Address>[] = [
        {
          id: cUser.addresses[0].id,
          ownerId: cUser.id,
          ownerType: OwnerType.User,
          addressType: AddressType.Work,
          ...address
        }
      ];
      // Update Address
      yield call(updateContactAddresses, addresses);
    }

    let identifications: Partial<Identification>[] = [];
    if (s2 !== '') {
      const s2Identification = getIdentification('s2', cIdentifications, false);
      identifications = [
        ...identifications,
        {
          id: s2Identification?.id || undefined,
          idType: 's2',
          identifier: s2
        }
      ];
    }

    if (cIdentifications.length > 0 && s2 === '') {
      const s2Identification = getIdentification('s2', cIdentifications, false);
      if (s2Identification) {
        yield call(deleteIdentificationNumbers, s2Identification.id);
      }
    }

    if (ptr !== '') {
      const ptrIdentification = getIdentification('ptr', cIdentifications, false);
      identifications = [
        ...identifications,
        {
          id: ptrIdentification?.id || undefined,
          idType: 'ptr',
          identifier: ptr
        }
      ];
    }

    if (cIdentifications.length > 0 && ptr === '') {
      const ptrIdentification = getIdentification('ptr', cIdentifications, false);
      if (ptrIdentification) {
        yield call(deleteIdentificationNumbers, ptrIdentification.id);
      }
    }

    // Add identification numbers
    if (identifications.length > 0) {
      const identificationWithoutId = identifications.filter(val => val.id === undefined);
      if (identificationWithoutId.length > 0) {
        yield call(addIdentificationNumbers, identificationWithoutId);
      }
      // Update identification numbers if exist
      const identificationWithId = identifications.filter(val => val.id !== undefined);
      if (identificationWithId.length > 0) {
        yield call(updateIdentificationNumbers, identificationWithId as Identification[]);
      }
    }

    //Account HMOS DELETE
    const newHMOIds = (hmos || [])
      .filter(c => !!c.id)
      .filter(c => c.hmoCode !== '')
      .map(c => parseInt(`${c.id}`));
    const deleteHMOIds = ((cHmos as any[]) || []).filter(c => newHMOIds.indexOf(c.id) < 0).map(c => c.id);
    if (deleteHMOIds.length > 0) yield call(deleteAccountHMOs, deleteHMOIds);

    const hmosForSave = (hmos || []).filter(item => {
      if (!item.id) return true;
      const exist = cHmos.find(c => c.id === item.id);
      if (!exist) return true;
      else {
        if (item.hmoId === exist.hmoId && item.number === exist.number) return false;
      }
      return true;
    });

    //Account HMOS ADD/UPDATE
    yield call(saveAccountHMOs, hmosForSave);

    // Update profile
    const { data: profileData }: AxiosResponse = yield call(updateCurrentUser, {
      ...profile,
      dateOfBirth: profile.dateOfBirth ? parseDate(profile.dateOfBirth) : ''
    });
    yield put({ type: GET_CURRENT.SUCCESS, payload: profileData });
    yield put({ type: GET_CLINIC.REQUEST, payload: null });
    const { data: updatedprofile } = yield call(getDoctorProfile);
    let specializations = [];
    const privateAccount = (updatedprofile.accounts || []).find((acc: _Account) => acc.type === AccountType.Doctor);
    if (privateAccount) {
      const accountID = privateAccount.id;
      const { data: specs } = yield call(getAccountSpecializations, accountID);
      specializations = specs;
    }
    const { data: hmosRes } = yield call(getAccountHMOs, activeAccount);
    const updatedHMO = hmosRes ? transformIncomingHMO(hmosRes) : [];
    yield put({ type: UPDATE_PROFILE.SUCCESS, payload: { ...updatedprofile, specializations, hmos: updatedHMO } });
    Toast.success('Profile successfully updated');
  } catch (err) {
    yield put({ type: UPDATE_PROFILE.FAILED, payload: err });
  }
}

function* updateSecretaryProfileSaga(action: ActionType) {
  try {
    const cClinics: Clinic[] = action.payload.current.clinics;
    const { clinics, ...profile }: SecretaryProfileForm = action.payload.payload;
    const clinicIds: number[] = clinics
      .map(clinic => clinic.id)
      .filter(cId => cClinics.map(c => c.id).indexOf(cId) < 0);
    // Add clinics
    yield call(addClinics, clinicIds);
    // Update profile
    const { data: profileData }: AxiosResponse = yield call(updateCurrentUser, {
      ...profile,
      dateOfBirth: profile.dateOfBirth ? parseDate(profile.dateOfBirth) : ''
    });
    yield put({ type: SECRETARY.UPDATE_PROFILE.SUCCESS, payload: profileData });
    yield put({ type: GET_CURRENT.SUCCESS, payload: profileData });
    yield put({ type: GET_CLINIC.REQUEST, payload: null });
    Toast.success('Profile successfully updated');
  } catch (err) {
    yield put({ type: SECRETARY.UPDATE_PROFILE.FAILED, payload: err });
  }
}

function* updateNavigatorProfileSaga(action: ActionType) {
  const cUser: User = action.payload.currentUser;
  const currentContactNumbers = cUser.contactNumbers;
  try {
    const { address, contactNumbers, ...profile }: NavigatorProfileForm = action.payload.payload;

    // Delete Contact Numbers
    const newContactIds = (contactNumbers || []).filter(c => !!c.id).map(c => parseInt(`${c.id}`));
    const deleteContactIds = ((currentContactNumbers as any[]) || [])
      .filter(c => newContactIds.indexOf(c.id) < 0)
      .map(c => c.id);
    if (deleteContactIds.length > 0) yield call(deleteContactNumbers, deleteContactIds, cUser.id);

    // Add Contact Numbers
    yield call(updateContactNumbers, contactNumbers);

    if (cUser.addresses.length === 0) {
      const addresses: Partial<Address>[] = [
        {
          ownerId: cUser.id,
          ownerType: OwnerType.User,
          addressType: AddressType.Work,
          address1: address
        }
      ];
      // Add Address
      yield call(updateContactAddresses, addresses);
    } else {
      //Temporary since the address is currently just a single line control
      const addresses: Partial<Address>[] = [
        {
          id: cUser.addresses[0].id,
          ownerId: cUser.id,
          ownerType: OwnerType.User,
          addressType: AddressType.Work,
          address1: address
        }
      ];
      // Update Address
      yield call(updateContactAddresses, addresses);

      const { data: profileData }: AxiosResponse = yield call(updateCurrentUser, {
        ...profile,
        dateOfBirth: profile.dateOfBirth ? parseDate(profile.dateOfBirth) : ''
      });
      yield put({ type: GET_CURRENT.SUCCESS, payload: profileData });
      yield put({ type: NAVIGATOR.UPDATE_PROFILE.SUCCESS, payload: null });
      Toast.success('Profile successfully updated!');
    }
  } catch (err) {
    yield put({ type: NAVIGATOR.UPDATE_PROFILE.FAILED, payload: err });
  }
}

function* changePasswordSaga(action: ActionType) {
  try {
    const { currentPassword, newPassword } = action.payload;
    yield call(changePassword, currentPassword, newPassword);
    yield put({ type: CHANGE_PASSWORD.SUCCESS, payload: null });
    Toast.success('You have sucessfully changed your \npassword', () => handleLogout());
  } catch (err) {
    Toast.error(err.message);
    yield put({ type: CHANGE_PASSWORD.FAILED, payload: err });
  }
}

function* getSecretaryPermissionsSaga(action: ActionType) {
  try {
    const { userId } = action.payload;
    let { data: secretaryPermissions }: AxiosResponse = yield call(getSecretaryPermissions, userId);
    yield put({
      type: SECRETARY.GET_PERMISSIONS.SUCCESS,
      payload: transformSecretaryPermissions(secretaryPermissions)
    });
  } catch (err) {
    yield put({ type: SECRETARY.GET_PERMISSIONS.FAILED, payload: err });
  }
}

export function* rootSaga() {
  yield takeLatest(SECRETARY.UPDATE_PROFILE.REQUEST, updateSecretaryProfileSaga);
  yield takeLatest(GET_CURRENT.REQUEST, getCurrentUserSaga);
  yield takeLatest(UPDATE_AVATAR.REQUEST, updateCurrentUserAvatarSaga);
  yield takeLatest(GET_PROFILE.REQUEST, getDoctorProfileSaga);
  yield takeLatest(UPDATE_PROFILE.REQUEST, updateDoctorProfileSaga);
  yield takeLatest(NAVIGATOR.UPDATE_PROFILE.REQUEST, updateNavigatorProfileSaga);
  yield takeLatest(GET_CLINIC.REQUEST, getDoctorClinicSaga);
  // yield takeLatest(GET_CLINIC_HEADER.REQUEST, getDoctorClinicHeaderSaga);
  yield takeLatest(CHANGE_PASSWORD.REQUEST, changePasswordSaga);
  yield takeLatest(SECRETARY.GET_PERMISSIONS.REQUEST, getSecretaryPermissionsSaga);
}
