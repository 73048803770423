import { MenuItem, TextField } from '@material-ui/core';
import { SelectProps as MatSelectProps } from '@material-ui/core/Select';
import classNames from 'classnames';
import { styled } from 'components/Theme';
import { FormHelperProps } from 'hooks/form';
import { KeyValue } from 'models';
import React from 'react';
import { Controller } from 'react-hook-form';

export type SelectProps = MatSelectProps & {
  label?: string;
  name: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  items: KeyValue[];
  onChange?: (e: any) => void;
  helpers?: FormHelperProps;
};

export const Select: React.FC<SelectProps> = ({ label, items, fullWidth, className, helpers, ...props }) => {
  const classes = classNames('c-select', className, {
    'no-label': !label,
    full: fullWidth
  });

  const SelectComponent: React.FC<{ [key: string]: any }> = fProps => {
    return (
      <StyledSelect select disabled={props.disabled} {...fProps}>
        {items.map((item, i) => (
          <MenuItem style={{ fontStyle: item.id ? 'normal' : 'italic' }} key={`${item.id}-${i}`} value={item.id}>
            {item.value}
          </MenuItem>
        ))}
      </StyledSelect>
    );
  };

  const commonProps = {
    fullWidth,
    size: 'small',
    margin: props.margin || 'normal',
    variant: 'outlined',
    className: classes,
    label,
    hidden: props.hidden,
    helperText: props.helperText,
    error: props.error
  };

  if (helpers) {
    return (
      <Controller
        {...commonProps}
        name={props.name}
        control={helpers?.control}
        defaultValue={props.defaultValue}
        onChange={([e]) => {
          if (props.onChange) {
            return props.onChange(e);
          } else {
            return e.target.value;
          }
        }}
        as={<SelectComponent onBlur={props.onBlur} />}
      />
    );
  }
  return <SelectComponent {...props} {...commonProps} />;
};
const StyledSelect = styled(TextField)`
  .MuiOutlinedInput-root {
    padding-right: 0;
    .Mui-disabled {
      background-color: transparent;
      border-color: transparent;
    }
  }
  .MuiInputAdornment-root {
    height: auto;
    display: inline-flex;
    border-left: 1px solid rgba(0, 0, 0, 0.23);
    padding: 0 12px;
    margin: 0;
  }
  .MuiFormHelperText-root {
    position: absolute;
    top: calc(100% + 2px);
    left: 5px;
    margin: 0;
  }
  &.MuiFormControl-marginNormal {
    margin-top: 16px;
    margin-bottom: 12px;
  }
`;
