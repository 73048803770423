import React from 'react';
import { styled } from 'components/Theme';
import { Button } from 'components';
import i18n from 'i18n';

interface Props {
  label?: string;
  className?: string;
  color?: 'primary' | 'secondary' | 'default';
  loading?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  onUpload?: (files: File[]) => void;
}

export const AttachFile: React.FC<Props> = ({
  label = i18n.t('attach_file'),
  className,
  color = 'default',
  loading = false,
  disabled = false,
  multiple = false,
  onUpload
}) => {
  const fileInput = React.createRef<HTMLInputElement>();
  const uploadFile = () => {
    if (fileInput && fileInput.current) fileInput.current.click();
  };

  const handleChange = (ev: any) => {
    const files = Array.from(ev.target.files) as Array<File>;
    onUpload && onUpload(files);
  };

  return (
    <AttachFileWrapper className={className}>
      <Button
        className="c-button-attach"
        label={label}
        color={color}
        disabled={disabled}
        loading={loading}
        onClick={uploadFile}
      />
      <input
        className="file-holder"
        multiple={multiple}
        type="file"
        name="avatar"
        ref={fileInput}
        accept="image/png, image/jpeg, application/pdf"
        onChange={handleChange}
      />
    </AttachFileWrapper>
  );
};

const AttachFileWrapper = styled.div`
  .file-holder {
    display: none;
  }
`;
