import { getLocalStorageData } from 'common/services';
import { StoreAction } from 'constants/store';
import { LocalStorage } from 'defaults';
import { ActionType, StoreSession } from 'models/store';

const { ASSUME_MEMBER, ACTIVE_ACCOUNT, BLOCK_REDIRECT } = StoreAction.SESSION;

const INITIAL_STATE: StoreSession = {
  shouldBlockRedirect: false,
  assumeToken: {
    data: getLocalStorageData().assumeToken,
    inProgress: false
  },
  activeAccount: getLocalStorageData().activeAccount
};

function sessionReducer(state = INITIAL_STATE, action: ActionType): StoreSession {
  switch (action.type) {
    case BLOCK_REDIRECT.REQUEST:
      return {
        ...state,
        shouldBlockRedirect: action.payload
      };
    case ACTIVE_ACCOUNT.RESET:
      localStorage.removeItem(LocalStorage.activeAccount);
      return {
        ...state,
        activeAccount: 0
      };
    case ACTIVE_ACCOUNT.SUCCESS:
      return {
        ...state,
        activeAccount: action.payload
      };
    case ASSUME_MEMBER.REQUEST:
      return {
        ...state,
        assumeToken: {
          ...state.assumeToken,
          inProgress: true
        }
      };
    case ASSUME_MEMBER.FAILED:
      return {
        ...state,
        assumeToken: {
          ...state.assumeToken,
          inProgress: false
        }
      };
    case ASSUME_MEMBER.SUCCESS:
      return {
        ...state,
        assumeToken: {
          data: action.payload,
          inProgress: false
        }
      };
    case ASSUME_MEMBER.RESET:
      localStorage.removeItem(LocalStorage.assumeToken);
      return {
        ...state,
        assumeToken: {
          ...state.assumeToken,
          data: ''
        }
      };
    default:
      return state;
  }
}

export default sessionReducer;
