import { Checkbox as MatCheckbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import { useField } from 'formik';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { FieldAttributes, FieldSubAttributes } from '..';
import './Checkbox.scss';

type Props = {
  fullWidth?: boolean;
  onClick?: () => void;
} & FieldAttributes;

export const Checkbox: React.FC<Props> = React.memo(
  ({ label, fullWidth, className, children, checked, onClick, ...props }) => {
    const [field, meta] = useField<FieldSubAttributes>(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const [value, setValue] = useState((field as any).value);

    const syncChanges = debounce(e => {
      setValue(!value);
      field.onChange(e);
    }, 200);

    useEffect(() => {
      if (field.value !== props.value) {
        setValue(field.value);
      }
    }, [field.value, props.value]);

    return (
      <FormControl error={!!errorText} className={`c-checkbox${fullWidth ? ' full' : ''} ${className || ''}`.trim()}>
        <FormControlLabel
          control={
            <MatCheckbox
              {...field}
              onClick={onClick}
              onChange={e => {
                e.persist && e.persist();
                syncChanges(e);
              }}
            />
          }
          label={label}
          checked={value}
        />
        {children}
        {errorText && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    );
  }
);
