import DateFnsUtils from '@date-io/date-fns';
import { KeyboardTimePicker, KeyboardTimePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import classNames from 'classnames';
import { styled } from 'components/Theme';
import { FormHelperProps } from 'hooks/form';
import React from 'react';
import { Controller } from 'react-hook-form';

interface TimePickerProps extends Partial<KeyboardTimePickerProps> {
  name: string;
  helpers?: FormHelperProps;
}

export const Timepicker: React.FC<TimePickerProps> = React.memo(
  ({ className, helpers, onChange, onFocus, ...props }) => {
    const classes = classNames('c-timepicker', className);
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Controller
          {...props}
          onBlur={args => args[0]}
          value={props.value}
          as={KeyboardTimePickerWrapper}
          name={props.name}
          control={helpers?.control}
          className={classes}
          inputVariant="outlined"
          margin="normal"
          format="hh:mm a"
        />
      </MuiPickersUtilsProvider>
    );
  }
);

const KeyboardTimePickerWrapper = styled(KeyboardTimePicker)`
  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
    .MuiInputAdornment-positionEnd {
      margin-left: 0;
    }
  }
  .MuiFormHelperText-root {
    position: absolute;
    top: calc(100% + 2px);
    left: 5px;
    margin: 0;
  }
  &.MuiFormControl-marginNormal {
    margin-top: 16px;
    margin-bottom: 12px;
  }
`;
