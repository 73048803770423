import { http, parseDate } from 'common/services';
import {
  transformInEncounter,
  transformInTreatment,
  transformOutNewEncounter,
  transformOutRecord,
  transformOutStagingForm,
  transformOutTreatment,
  transformOutPatientReferral,
  transformInRecord,
  isGranular
} from 'common/transformer';
import { EncounterType, PulmonologyFormSubType, StagingFormType } from 'constants/form';
import {
  ENDPOINTS,
  isBreastCancer,
  isBronchialAsthma,
  isColonCancer,
  isColorectalCancer,
  isLungCancer,
  isTuberculosis,
  TREATMENT_DEFAULT
} from 'defaults';
import isEqual from 'lodash/isEqual';
import {
  BCIPhysicalExam,
  CombinedDiagnosis,
  CombinedStagingForm,
  CommonPhysicalExam,
  Encounter,
  NewEncounterInput,
  OncoDiagnosis,
  PatientInstruction,
  Prescription,
  PrescriptionEvaluation,
  PulmoDiagnosis,
  ReviewOfSystem,
  RheumaDiagnosis,
  TreatmentPlan,
  PatientReferralData,
  PulmonologyEncounter,
  EncounterVaccination,
  EndoDiagnosis,
  EndocrinologyEncounter,
  DiabEncounter,
  Subjective,
  StandardDiagnosis,
  CombinedEncounter
} from 'models';
import {
  _HealthRecord,
  _ReviewOfSystemFlat,
  _TreatmentAgentFlat,
  _TreatmentFlat,
  _TreatmentProcedureFlat,
  _TreatmentRadiationBCIFlat,
  _TreatmentRadiationFlat,
  _TreatmentSurgeryFlat,
  _TreatmentTherapyFlat,
  _PatientReferralData,
  _EncounterVaccination
} from 'models/endpoint';
import { assemblePayloadForRheumatoidArthritis } from 'shared/constants/encounter/payload';
import { compareObject } from './utils.service';
import { CancelToken } from 'axios';

export function createEncounter(data: NewEncounterInput) {
  const { RECORD } = ENDPOINTS.PHR.DOCTOR;
  const endpoint = RECORD.key;
  const payload = transformOutNewEncounter(data);
  return http.post(endpoint.name, endpoint.url, payload);
}

export function getEncounter(phrId: number) {
  const { RECORD } = ENDPOINTS.PHR.DOCTOR;
  const endpoint = RECORD.parse({ id: phrId });
  return http.get(endpoint.name, endpoint.url);
}

export function getEncounterStagingForm(phrId: number) {
  const { parse } = ENDPOINTS.PHR.DOCTOR.STAGING_FORM.GET_BY_ENCOUNTER;
  const endpoint = parse({ patient_health_record_id: phrId });
  return http.get(endpoint.name, endpoint.url);
}

export async function getTransformedDataTypeEncounter(phrId: number) {
  const { RECORD } = ENDPOINTS.PHR.DOCTOR;
  const endpoint = RECORD.parse({ id: phrId, data_type: true });
  const { data } = await http.get(endpoint.name, endpoint.url);
  return Array.isArray(data) && data.length > 0 ? transformInEncounter(data[0]) : null;
}

export async function savePrescriptionEvaluation(phrId: number, evaluation: PrescriptionEvaluation) {
  const { data } = await getEncounter(phrId);
  if (Array.isArray(data) && data.length > 0) {
    const encounter: _HealthRecord = data[0];
    const updateRecordEndpoint = ENDPOINTS.PHR.DOCTOR.RECORD.key;
    const updateRecord = http.put(updateRecordEndpoint.name, updateRecordEndpoint.url, {
      id: encounter.id,
      patient_id: encounter.patient_id,
      clinic_id: encounter.clinic_id,
      compliance_level: evaluation.complianceLevel,
      compliance_remarks: evaluation.complianceRemarks
    });
    const promises = encounter.phr_prescriptions.map(prescription => {
      const endpoint = ENDPOINTS.PHR.DOCTOR.PRESCRIPTION.PUT;
      return http.put(endpoint.name, endpoint.url, {
        id: prescription.id,
        patient_health_record_id: encounter.id,
        response_level: evaluation.responseLevel,
        response_remarks: evaluation.responseRemarks
      });
    });

    return Promise.all(promises.concat(updateRecord));
  }
}

export function getEncounters(phrIds: Array<number>) {
  const endpoint = ENDPOINTS.PHR.DOCTOR.RECORD.parse({
    id: phrIds.join(',')
  });
  return http.get(endpoint.name, endpoint.url);
}

export function getPhysicalExam(encounterID: number) {
  const { GET: endpoint } = ENDPOINTS.PHR.DOCTOR.PHYSICAL;
  return http.get(endpoint.name, `${endpoint.url}?patient_health_record_id=${encounterID}`);
}

export function getActNow(encounterID: number) {
  const { GET: endpoint } = ENDPOINTS.PHR.DOCTOR.ACT_NOW;
  return http.get(endpoint.name, `${endpoint.url}?patient_health_record_id=${encounterID}`);
}

export function getOphthalmology(encounterID: number) {
  const { GET: endpoint } = ENDPOINTS.PHR.DOCTOR.OPHTHALMOLOGY;
  return http.get(endpoint.name, `${endpoint.url}?patient_health_record_id=${encounterID}`);
}

export function getOb(encounterID: number) {
  const { GET: endpoint } = ENDPOINTS.PHR.DOCTOR.OB;
  return http.get(endpoint.name, `${endpoint.url}?patient_health_record_id=${encounterID}`);
}

export function getGyne(encounterID: number) {
  const { GET: endpoint } = ENDPOINTS.PHR.DOCTOR.GYNE;
  return http.get(endpoint.name, `${endpoint.url}?patient_health_record_id=${encounterID}`);
}

export const getReviewOfSystem = (encounterID: number) => {
  const { GET: endpoint } = ENDPOINTS.PHR.DOCTOR.ROS;
  return http.get(endpoint.name, `${endpoint.url}?patient_health_record_id=${encounterID}`);
};

export const getDiagnosisByPatient = (patientId: number) => {
  const { GET: endpoint } = ENDPOINTS.PHR.DOCTOR.DIAGNOSIS.PER_PATIENT;
  return http.get(endpoint.name, `${endpoint.url}?patient_id=${patientId}&per_page=1000`);
};

export async function savePhysicalExam(form: CommonPhysicalExam, encounterType: EncounterType, token: CancelToken) {
  const { POST: endpoint } = ENDPOINTS.PHR.DOCTOR.PHYSICAL;
  const payload = transformOutRecord.commonPhysicalExam(form);
  const method = payload.id ? http.cancellablePut : http.cancellablePost;
  if (!payload.id) {
    delete payload.id;
  }
  const value = await method(endpoint.name, endpoint.url, payload, token);

  return transformInRecord.physical(value.data, isGranular(encounterType));
}

export function saveACTNOW(payload: any, token: CancelToken) {
  const { POST: endpoint } = ENDPOINTS.PHR.DOCTOR.ACT_NOW;
  const method = payload.id ? http.cancellablePut : http.cancellablePost;
  if (!payload.id) {
    delete payload.id;
  }
  return method(endpoint.name, endpoint.url, payload, token);
}

export function saveOphthalmology(payload: any, token: CancelToken) {
  const { POST: endpoint } = ENDPOINTS.PHR.DOCTOR.OPHTHALMOLOGY;
  const method = payload.id ? http.cancellablePut : http.cancellablePost;
  if (!payload.id) {
    delete payload.id;
  }
  return method(endpoint.name, endpoint.url, payload, token);
}

export function saveOb(payload: any, token: CancelToken) {
  const { POST: endpoint } = ENDPOINTS.PHR.DOCTOR.OB;
  const method = payload.id ? http.cancellablePut : http.cancellablePost;
  if (!payload.id) {
    delete payload.id;
  }
  return method(endpoint.name, endpoint.url, payload, token);
}

export function saveGyne(payload: any, token: CancelToken) {
  const { POST: endpoint } = ENDPOINTS.PHR.DOCTOR.GYNE;
  const method = payload.id ? http.cancellablePut : http.cancellablePost;
  if (!payload.id) {
    delete payload.id;
  }
  return method(endpoint.name, endpoint.url, payload, token);
}

export async function saveGranularPhysicalExam(form: BCIPhysicalExam, token: CancelToken) {
  const { POST: endpoint } = ENDPOINTS.PHR.DOCTOR.PHYSICAL;
  const payload = transformOutRecord.bciPhysicalExam(form);
  const method = payload.id ? http.cancellablePut : http.cancellablePost;
  if (!payload.id) {
    delete payload.id;
  }
  const value = await method(endpoint.name, endpoint.url, payload, token);
  return transformInRecord.physical(value.data, true);
}

export const saveReviewOfSystem = (form: ReviewOfSystem, token: CancelToken) => {
  const { POST: endpoint } = ENDPOINTS.PHR.DOCTOR.ROS;
  const payload: _ReviewOfSystemFlat = transformOutRecord.reviewOfSystem(form);
  const method = payload.id ? http.cancellablePut : http.cancellablePost;
  if (!payload.id) {
    delete payload.id;
  }
  return method(endpoint.name, endpoint.url, payload, token);
};

export const saveSubjective = async (encounter: Encounter, cancelToken?: CancelToken) => {
  const { SUBJECTIVE: endpoint } = ENDPOINTS.PHR.DOCTOR;
  const subjective = transformOutRecord.subjective(encounter);
  await http.cancellablePut(endpoint.name, endpoint.url, subjective, cancelToken);
};

export const saveSubjectiveIndependent = async (
  encounterId: number,
  subjective: Subjective,
  cancelToken?: CancelToken
) => {
  const { SUBJECTIVE: endpoint } = ENDPOINTS.PHR.DOCTOR;
  const transformed = transformOutRecord.subjective({ subjective, id: encounterId });
  await http.cancellablePut(endpoint.name, endpoint.url, transformed, cancelToken);
};

export const saveVitals = async (encounter: Pick<Encounter, 'vitals' | 'id'>, cancelToken: CancelToken) => {
  const { VITALS: endpoint } = ENDPOINTS.PHR.DOCTOR;
  const vitals = {
    patient_health_record_id: encounter.id,
    ...transformOutRecord.vitals(encounter.vitals)
  };
  return http.cancellablePut(endpoint.name, endpoint.url, vitals, cancelToken);
};

export const changeEncounterType = (payload: any) => {
  const { key: endpoint } = ENDPOINTS.PHR.DOCTOR.RECORD;
  return http.put(endpoint.name, endpoint.url, payload);
};

export const saveRecord = async (
  encounter: Pick<Encounter, 'id' | 'patientId' | 'clinicId'> &
    Partial<Omit<Encounter, 'id' | 'patientId' | 'clinicId'>>,
  currentDoctorId: number,
  bypassActive?: boolean,
  cancelToken?: CancelToken
): Promise<CombinedEncounter> => {
  const { key: endpoint } = ENDPOINTS.PHR.DOCTOR.RECORD;
  const formSubTypeDefault = encounter.type === EncounterType.Pulmonology ? PulmonologyFormSubType.Basic : undefined;
  const vitals = {
    id: encounter.id,
    type: encounter.type,
    patient_id: encounter.patientId,
    clinic_id: encounter.clinicId,
    laboratory_and_diagnostic_data: encounter.laboratoryAndDiagnosticData,
    active: bypassActive ? encounter.active : true,
    is_telemed_encounter: encounter.isTelemedEncounter,
    visit_date: encounter.visitDate,
    visit_start_time: encounter.visitStartTime,
    visit_end_time: encounter.visitEndTime,
    prescription_validity_date: encounter.prescriptionValidityDate,
    pr__patient_feedback: encounter.patientFeedback,
    diagram_data: encounter.diagramData ? JSON.stringify(encounter.diagramData) : undefined,
    form_sub_type:
      encounter.formSubType === undefined ? encounter.formSubType : encounter.formSubType ?? formSubTypeDefault,
    last_updated_by_id: encounter.active ? currentDoctorId || undefined : undefined,
    history_of_present_illness: encounter.subjective?.historyOfPresentIllness ?? undefined
  };
  const response = await http.cancellablePut(endpoint.name, endpoint.url, vitals, cancelToken);
  return transformInEncounter(response.data);
};

export const saveHPI = async (
  enc: Pick<Encounter, 'id' | 'clinicId' | 'patientId'>,
  hpi: Encounter['subjective']['historyOfPresentIllness'],

  cancelToken?: CancelToken
) => {
  const { key: endpoint } = ENDPOINTS.PHR.DOCTOR.RECORD;

  const vitals = {
    id: enc.id,
    patient_id: enc.patientId,
    clinic_id: enc.clinicId,
    history_of_present_illness: hpi
  };
  return http.cancellablePut(endpoint.name, endpoint.url, vitals, cancelToken);
};

type PulmoRequiredFields = 'id' | 'typeSpecificId' | 'type';

export const savePulmonologySpecificFields = (
  encounter: Pick<PulmonologyEncounter, PulmoRequiredFields> & Partial<Omit<PulmonologyEncounter, PulmoRequiredFields>>,
  cancelToken: CancelToken
) => {
  const { key } = ENDPOINTS.PHR.DOCTOR.RECORD.TYPE_SPECIFIC.PULMONOLOGY;
  const typeSpecificId = encounter.typeSpecificId ? Number(encounter.typeSpecificId) : undefined;
  const method = typeSpecificId ? http.cancellablePut : http.cancellablePost;
  const payload = {
    patient_health_record_id: encounter.id,
    ...transformOutRecord.pulmoFields(encounter as any)
  };
  ``;
  return method(key.name, key.url, payload, cancelToken);
};

export const getPulmonologySpecificFields = (phrId: number) => {
  const { parse } = ENDPOINTS.PHR.DOCTOR.RECORD.TYPE_SPECIFIC.PULMONOLOGY;
  const endpoint = parse({ patient_health_record_id: phrId });
  return http.get(endpoint.name, endpoint.url);
};

export const saveEndoSpecificFields = (
  encounter: EndocrinologyEncounter | DiabEncounter,
  encounterType: EncounterType
) => {
  const { ENDOCRINOLOGY, DIABETOLOGY } = ENDPOINTS.PHR.DOCTOR.RECORD.TYPE_SPECIFIC;
  const { key } = encounterType === EncounterType.Endocrinology ? ENDOCRINOLOGY : DIABETOLOGY;
  const typeSpecificId = encounter.typeSpecificId ? Number(encounter.typeSpecificId) : undefined;
  const method = typeSpecificId ? http.put : http.post;
  let payload = null;

  if (encounterType === EncounterType.Endocrinology) {
    payload = transformOutRecord.endoFields(encounter as EndocrinologyEncounter);
  } else {
    payload = transformOutRecord.diabFields(encounter as DiabEncounter);
  }

  return method(key.name, key.url, payload);
};

export const saveDiabSpecificFields = async (
  encounter: Pick<DiabEncounter, 'typeSpecificId' | 'phrId'> &
    Partial<
      Pick<
        DiabEncounter,
        | 'waistCircumference'
        | 'waistCircumferenceUnit'
        | 'diabetesEducatorsNotes'
        | 'followUpSchedule'
        | 'symptoms'
        | 'medicalNutritionalTherapy'
      >
    >,
  token: CancelToken
) => {
  const endpoint = ENDPOINTS.PHR.DOCTOR.RECORD.TYPE_SPECIFIC.DIABETOLOGY;
  const typeSpecificId = encounter.typeSpecificId ? Number(encounter.typeSpecificId) : undefined;
  const method = typeSpecificId ? http.cancellablePut : http.cancellablePost;
  const payload = transformOutRecord.diabFields(encounter);

  return await method(endpoint.key.name, endpoint.key.url, payload, token);
};

export const deleteEndoSpecificFields = (phrId: number, encounterType: EncounterType) => {
  const { ENDOCRINOLOGY, DIABETOLOGY } = ENDPOINTS.PHR.DOCTOR.RECORD.TYPE_SPECIFIC;
  const { key: endpoint } = encounterType === EncounterType.Endocrinology ? ENDOCRINOLOGY : DIABETOLOGY;
  return http.delete(endpoint.name, endpoint.url, { patient_health_record_id: phrId });
};

export const getEndoSpecificFields = (phrId: number, encounterType: EncounterType) => {
  const { ENDOCRINOLOGY, DIABETOLOGY } = ENDPOINTS.PHR.DOCTOR.RECORD.TYPE_SPECIFIC;
  const { parse } = encounterType === EncounterType.Endocrinology ? ENDOCRINOLOGY : DIABETOLOGY;
  const endpoint = parse({ patient_health_record_id: phrId });
  return http.get(endpoint.name, endpoint.url);
};

export const updateTypeAsPulmo = (encounter: Encounter, currentDoctorId: number, bypassActive?: boolean) => {
  const { key: endpoint } = ENDPOINTS.PHR.DOCTOR.RECORD;
  const vitals = {
    id: encounter.id,
    type: encounter.type,
    patient_id: encounter.patientId,
    clinic_id: encounter.clinicId,
    laboratory_and_diagnostic_data: encounter.laboratoryAndDiagnosticData,
    active: bypassActive ? encounter.active : true,
    is_telemed_encounter: encounter.isTelemedEncounter,
    visit_date: encounter.visitDate,
    last_updated_by_id: encounter.active ? currentDoctorId || undefined : undefined
  };
  return http.put(endpoint.name, endpoint.url, vitals);
};

// Diagnosis

export const getDiagnoses = (phrId: number) => {
  const { GET } = ENDPOINTS.PHR.DOCTOR.DIAGNOSIS;
  const endpoint = GET.parse({ patient_health_record_id: phrId });
  return http.get(endpoint.name, endpoint.url);
};

const getDiagnosisEndpoint = (name: string, encounterType: string) => {
  const { DIAGNOSIS } = ENDPOINTS.PHR.DOCTOR;
  if (
    encounterType === EncounterType.BCI ||
    encounterType === EncounterType.Oncology ||
    encounterType === EncounterType.ActNow
  ) {
    const isBreast = isBreastCancer(name);
    const isLung = isLungCancer(name);
    const isColon = isColonCancer(name);
    const isColorectal = isColorectalCancer(name);
    if (isBreast) {
      return DIAGNOSIS.CANCER_TYPE.BREAST;
    } else if (isLung) {
      return DIAGNOSIS.CANCER_TYPE.LUNG;
    } else if (isColon || isColorectal) {
      return DIAGNOSIS.CANCER_TYPE.COLON;
    } else {
      return DIAGNOSIS.CANCER_TYPE.BREAST;
    }
  } else if (encounterType === EncounterType.Rheumatology) {
    return {
      GET: DIAGNOSIS.RHEUMA,
      POST: DIAGNOSIS.RHEUMA.key,
      PUT: DIAGNOSIS.RHEUMA.key,
      DELETE: DIAGNOSIS.RHEUMA
    };
  } else if (encounterType === EncounterType.Pulmonology) {
    if (isBronchialAsthma(name)) {
      return {
        GET: DIAGNOSIS.PULMO_DIAGNOSIS.BRONCHIAL_ASTHMA,
        POST: DIAGNOSIS.PULMO_DIAGNOSIS.BRONCHIAL_ASTHMA.key,
        PUT: DIAGNOSIS.PULMO_DIAGNOSIS.BRONCHIAL_ASTHMA.key,
        DELETE: DIAGNOSIS.PULMO_DIAGNOSIS.BRONCHIAL_ASTHMA
      };
    } else if (isTuberculosis(name)) {
      return {
        GET: DIAGNOSIS.PULMO_DIAGNOSIS.TUBERCULOSIS,
        POST: DIAGNOSIS.PULMO_DIAGNOSIS.TUBERCULOSIS.key,
        PUT: DIAGNOSIS.PULMO_DIAGNOSIS.TUBERCULOSIS.key,
        DELETE: DIAGNOSIS.PULMO_DIAGNOSIS.TUBERCULOSIS
      };
    } else return DIAGNOSIS;
  }
  return DIAGNOSIS;
};

export const insertDiagnoses = async (
  diagnoses: CombinedDiagnosis[],
  phrId: number,
  encounterType: string,
  cancelToken: CancelToken
) => {
  const mapped: any = {};
  for (const diag of diagnoses) {
    const { POST } = getDiagnosisEndpoint(diag.name, encounterType);
    let dx: any = {};
    if (
      encounterType === EncounterType.BCI ||
      encounterType === EncounterType.Oncology ||
      encounterType === EncounterType.ActNow
    ) {
      dx = transformOutRecord.oncoDiagnosis(diag as OncoDiagnosis);
    } else if (encounterType === EncounterType.Rheumatology) {
      dx = assemblePayloadForRheumatoidArthritis(diag as RheumaDiagnosis, phrId);
    } else if (encounterType === EncounterType.Pulmonology) {
      dx = transformOutRecord.pulmonologyDiagnosis(diag as PulmoDiagnosis);
    } else if (encounterType === EncounterType.Endocrinology || encounterType === EncounterType.Diabetology) {
      dx = transformOutRecord.endoDiagnosis(diag as EndoDiagnosis);
    } else {
      dx = transformOutRecord.standardDiagnosis(diag);
    }
    let payload = {
      ...dx,
      patient_health_record_id: phrId
    };
    delete payload.id;
    const { data } = await http.cancellablePost(POST.name, POST.url, payload, cancelToken);
    if (
      encounterType === EncounterType.BCI ||
      encounterType === EncounterType.Oncology ||
      encounterType === EncounterType.ActNow
    ) {
      mapped[diag.id] = { id: data.id, treatment: (diag as OncoDiagnosis).treatment, pastId: diag.id };
    } else {
      mapped[diag.id] = { id: data.id, pastId: diag.id };
    }
  }
  return mapped;
};

export const updateDiagnoses = async (
  diagnoses: CombinedDiagnosis[],
  phrId: number,
  encounterType: string,
  token: CancelToken
) => {
  const mapped: any = {};
  for (const diag of diagnoses) {
    const { PUT } = getDiagnosisEndpoint(diag.name, encounterType);
    let payload = {};
    if (
      encounterType === EncounterType.BCI ||
      encounterType === EncounterType.Oncology ||
      encounterType === EncounterType.ActNow
    ) {
      payload = transformOutRecord.oncoDiagnosis(diag as OncoDiagnosis);
    } else if (encounterType === EncounterType.Rheumatology) {
      payload = assemblePayloadForRheumatoidArthritis(diag as RheumaDiagnosis, phrId);
    } else if (encounterType === EncounterType.Pulmonology) {
      payload = transformOutRecord.pulmonologyDiagnosis(diag as PulmoDiagnosis);
    } else if (encounterType === EncounterType.Endocrinology || encounterType === EncounterType.Diabetology) {
      payload = transformOutRecord.endoDiagnosis(diag as EndoDiagnosis);
    } else {
      payload = transformOutRecord.standardDiagnosis(diag);
    }
    const { data } = await http.cancellablePut(
      PUT.name,
      PUT.url,
      {
        ...payload,
        patient_health_record_id: phrId
      },
      token
    );
    if (
      encounterType === EncounterType.BCI ||
      encounterType === EncounterType.Oncology ||
      encounterType === EncounterType.ActNow
    ) {
      mapped[diag.id] = {
        id: data.id,
        treatment: (diag as OncoDiagnosis).treatment,
        oldTreatment: transformInTreatment(data as any)
      };
    } else {
      mapped[diag.id] = { id: data.id };
    }
  }
  return mapped;
};

export const removeDiagnoses = (diagnoses: CombinedDiagnosis[], phrId: number, encounterType: string) => {
  const promises = diagnoses.map(diag => {
    const { DELETE } = getDiagnosisEndpoint(diag.name, encounterType);
    const endpoint = DELETE.parse({ id: diag.id, patient_health_record_id: phrId });
    return http.delete(endpoint.name, endpoint.url, null);
  });
  return Promise.all(promises);
};

// Treatment Plan

export const saveTreatmentPlan = async (
  treatment: TreatmentPlan,
  oldTreatment: TreatmentPlan | null,
  phrID: number,
  diagnosisID: number,
  encounterType: string
) => {
  if (isEqual(treatment, TREATMENT_DEFAULT)) return null;
  if (isEqual(treatment, oldTreatment)) return null;
  const transformedTreatmentPlan = transformOutTreatment(treatment, phrID, diagnosisID);
  const transformedOldTreatmentPlan = oldTreatment ? transformOutTreatment(oldTreatment, phrID, diagnosisID) : null;
  const { data }: any = await saveTreatment(transformedTreatmentPlan, phrID, diagnosisID);

  const treatmentID = data ? data.id : undefined;
  if (!treatmentID) return null;

  await saveTherapy(
    transformedTreatmentPlan.therapies,
    transformedOldTreatmentPlan ? transformedOldTreatmentPlan.therapies : [],
    phrID,
    diagnosisID,
    treatmentID
  );
  await saveAgents(
    transformedTreatmentPlan.agents,
    transformedOldTreatmentPlan ? transformedOldTreatmentPlan.agents : [],
    phrID,
    diagnosisID,
    treatmentID
  );
  await saveSurgeries(
    transformedTreatmentPlan.surgeries,
    transformedOldTreatmentPlan ? transformedOldTreatmentPlan.surgeries : [],
    phrID,
    diagnosisID,
    treatmentID
  );
  await saveProcedures(
    transformedTreatmentPlan.procedures,
    transformedOldTreatmentPlan ? transformedOldTreatmentPlan.procedures : [],
    phrID,
    diagnosisID,
    treatmentID
  );

  if (encounterType === EncounterType.ActNow) {
    await saveAntiCancer(transformedTreatmentPlan.anti_cancers, phrID, treatmentID, diagnosisID);
  }

  if (encounterType === EncounterType.BCI) {
    await saveBCIRadiations(
      transformedTreatmentPlan.radiation_bcis,
      transformedOldTreatmentPlan ? transformedOldTreatmentPlan.radiation_bcis : [],
      phrID,
      diagnosisID,
      treatmentID
    );
  } else {
    await saveRadiations(
      transformedTreatmentPlan.radiations,
      transformedOldTreatmentPlan ? transformedOldTreatmentPlan.radiations : [],
      phrID,
      diagnosisID,
      treatmentID
    );
  }

  return { ...treatment, id: treatmentID };
};

const saveRadiations = async (
  radiations: _TreatmentRadiationFlat[],
  oldRadiations: _TreatmentRadiationFlat[],
  phrID: number,
  diagnosisID: number,
  treatmentID: number
) => {
  const { POST, PUT, DELETE } = ENDPOINTS.PHR.DOCTOR.DIAGNOSIS.TREATMENT_PLAN.RADIATION;
  const rData = compareObject(
    radiations,
    oldRadiations.filter(d => d.id && d.id > 0)
  );
  if (rData.insert.length > 0) {
    for (let r of rData.insert) {
      let payload = {
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID,
        body_area: r.body_area,
        frequency: r.frequency,
        start_date: r.start_date,
        end_date: r.end_date,
        institution: r.institution
      };
      await http.post(POST.name, POST.url, payload);
    }
  }
  if (rData.update.length > 0) {
    for (let r of rData.update) {
      const existing = oldRadiations.find(rad => r.id === rad.id);
      if (existing && isEqual(r, existing)) {
        continue;
      }
      let payload = {
        id: r.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID,
        body_area: r.body_area,
        frequency: r.frequency,
        start_date: r.start_date,
        end_date: r.end_date,
        institution: r.institution
      };
      await http.put(PUT.name, PUT.url, payload);
    }
  }
  if (rData.remove.length > 0) {
    for (let r of rData.remove) {
      let payload = {
        id: r.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID
      };
      const endpoint = DELETE.parse(payload);
      await http.delete(endpoint.name, endpoint.url, null);
    }
  }
};

const saveAntiCancer = async (anti_cancer: any, phrID: number, treatmentID: number, diagnosisID: number) => {
  const { POST, PUT } = ENDPOINTS.PHR.DOCTOR.DIAGNOSIS.TREATMENT_PLAN.ANTI_CANCER;
  const payload = {
    ...anti_cancer,
    patient_health_record_id: phrID,
    treatment_id: treatmentID,
    diagnosis_id: diagnosisID
  };
  if (payload.id) {
    await http.put(PUT.name, PUT.url, payload);
  } else {
    delete payload.id;
    await http.post(POST.name, POST.url, payload);
  }
};

const saveBCIRadiations = async (
  bciRadiations: _TreatmentRadiationBCIFlat[],
  oldBciRadiations: _TreatmentRadiationBCIFlat[],
  phrID: number,
  diagnosisID: number,
  treatmentID: number
) => {
  const { POST, PUT } = ENDPOINTS.PHR.DOCTOR.DIAGNOSIS.TREATMENT_PLAN.RADIATION_BCI;
  const newData = bciRadiations[0];
  const oldData = oldBciRadiations.length > 0 ? oldBciRadiations[0] : null;
  if (newData.id === undefined) {
    let payload = {
      diagnosis_id: diagnosisID,
      patient_health_record_id: phrID,
      treatment_id: treatmentID,
      therapy_prescription_primary_site: newData.therapy_prescription_primary_site,
      goal: newData.goal,
      course_number: newData.course_number,
      treatment_machine: newData.treatment_machine,
      special_instructions: newData.special_instructions,
      treatment_1: newData.treatment_1,
      treatment_2: newData.treatment_2,
      dose_constraints: newData.dose_constraints,
      previous_radiation_therapy: newData.previous_radiation_therapy
    };
    await http.post(POST.name, POST.url, payload);
  } else {
    if (oldData && !isEqual(newData, oldData)) {
      let payload = {
        id: newData.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID,
        therapy_prescription_primary_site: newData.therapy_prescription_primary_site,
        goal: newData.goal,
        course_number: newData.course_number,
        treatment_machine: newData.treatment_machine,
        special_instructions: newData.special_instructions,
        treatment_1: newData.treatment_1,
        treatment_2: newData.treatment_2,
        dose_constraints: newData.dose_constraints,
        previous_radiation_therapy: newData.previous_radiation_therapy
      };
      await http.put(PUT.name, PUT.url, payload);
    }
  }
};

const saveProcedures = async (
  procedures: _TreatmentProcedureFlat[],
  oldProcedures: _TreatmentProcedureFlat[],
  phrID: number,
  diagnosisID: number,
  treatmentID: number
) => {
  const { POST, PUT, DELETE } = ENDPOINTS.PHR.DOCTOR.DIAGNOSIS.TREATMENT_PLAN.PROCEDURE;
  const pData = compareObject(
    procedures,
    oldProcedures.filter(d => d.id && d.id > 0)
  );
  if (pData.insert.length > 0) {
    for (let p of pData.insert) {
      let payload = {
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID,
        name: p.name,
        frequency: p.frequency,
        start_date: p.start_date,
        end_date: p.end_date
      };
      await http.post(POST.name, POST.url, payload);
    }
  }
  if (pData.update.length > 0) {
    for (let p of pData.update) {
      const existing = oldProcedures.find(proc => p.id === proc.id);
      if (existing && isEqual(p, existing)) {
        continue;
      }
      let payload = {
        id: p.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID,
        name: p.name,
        frequency: p.frequency,
        start_date: p.start_date,
        end_date: p.end_date
      };
      await http.put(PUT.name, PUT.url, payload);
    }
  }
  if (pData.remove.length > 0) {
    for (let p of pData.remove) {
      let payload = {
        id: p.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID
      };
      const endpoint = DELETE.parse(payload);
      await http.delete(endpoint.name, endpoint.url, null);
    }
  }
};

const saveSurgeries = async (
  surgeries: _TreatmentSurgeryFlat[],
  oldSurgeries: _TreatmentSurgeryFlat[],
  phrID: number,
  diagnosisID: number,
  treatmentID: number
) => {
  const { POST, PUT, DELETE } = ENDPOINTS.PHR.DOCTOR.DIAGNOSIS.TREATMENT_PLAN.SURGERY;
  const sData = compareObject(
    surgeries,
    oldSurgeries.filter(d => d.id && d.id > 0)
  );
  if (sData.insert.length > 0) {
    for (let s of sData.insert) {
      let payload = {
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID,
        remarks: s.remarks,
        surgery_date: s.surgery_date,
        surgical_procedure: s.surgical_procedure,
        institution: s.institution,
        surgical_procedure_specify: s.surgical_procedure_specify,
        surgery_location: s.surgery_location
      };
      await http.post(POST.name, POST.url, payload);
    }
  }
  if (sData.update.length > 0) {
    for (let s of sData.update) {
      const existing = oldSurgeries.find(sur => s.id === sur.id);
      if (existing && isEqual(s, existing)) {
        continue;
      }
      let payload = {
        id: s.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID,
        remarks: s.remarks,
        surgery_date: s.surgery_date,
        surgical_procedure: s.surgical_procedure,
        institution: s.institution,
        surgical_procedure_specify: s.surgical_procedure_specify,
        surgery_location: s.surgery_location
      };
      await http.put(PUT.name, PUT.url, payload);
    }
  }
  if (sData.remove.length > 0) {
    for (let s of sData.remove) {
      let payload = {
        id: s.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID
      };
      const endpoint = DELETE.parse(payload);
      await http.delete(endpoint.name, endpoint.url, null);
    }
  }
};

const saveAgents = async (
  agents: _TreatmentAgentFlat[],
  oldAgents: _TreatmentAgentFlat[],
  phrID: number,
  diagnosisID: number,
  treatmentID: number
) => {
  const { POST, PUT, DELETE } = ENDPOINTS.PHR.DOCTOR.DIAGNOSIS.TREATMENT_PLAN.AGENT;
  const agentsData = compareObject(
    agents,
    oldAgents.filter(d => d.id && d.id > 0)
  );
  if (agentsData.insert.length > 0) {
    for (let agent of agentsData.insert) {
      let payload = {
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID,
        brand_name: agent.brand_name,
        generic_name: agent.generic_name,
        dosage: agent.dosage,
        dosage_given: agent.dosage_given,
        start_date: agent.start_date,
        end_date: agent.end_date,
        cycles: agent.cycles
      };
      await http.post(POST.name, POST.url, payload);
    }
  }
  if (agentsData.update.length > 0) {
    for (let agent of agentsData.update) {
      const existing = oldAgents.find(a => agent.id === a.id);
      if (existing && isEqual(agent, existing)) {
        continue;
      }
      let payload = {
        id: agent.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID,
        brand_name: agent.brand_name,
        generic_name: agent.generic_name,
        dosage: agent.dosage,
        dosage_given: agent.dosage_given,
        start_date: agent.start_date,
        end_date: agent.end_date,
        cycles: agent.cycles
      };
      await http.put(PUT.name, PUT.url, payload);
    }
  }
  if (agentsData.remove.length > 0) {
    for (let agent of agentsData.remove) {
      let payload = {
        id: agent.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID
      };
      const endpoint = DELETE.parse(payload);
      await http.delete(endpoint.name, endpoint.url, null);
    }
  }
};

const saveTherapy = async (
  therapies: _TreatmentTherapyFlat[],
  oldTherapies: _TreatmentTherapyFlat[],
  phrID: number,
  diagnosisID: number,
  treatmentID: number
) => {
  const { POST, PUT, DELETE } = ENDPOINTS.PHR.DOCTOR.DIAGNOSIS.TREATMENT_PLAN.THERAPY;
  const therapiesData = compareObject(
    therapies,
    oldTherapies.filter(d => d.id && d.id > 0)
  );
  if (therapiesData.insert.length > 0) {
    for (let therapy of therapiesData.insert) {
      let payload = {
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID,
        name: therapy.name
      };
      await http.post(POST.name, POST.url, payload);
    }
  }
  if (therapiesData.update.length > 0) {
    for (let therapy of therapiesData.update) {
      const existing = oldTherapies.find(th => therapy.id === th.id);
      if (existing && isEqual(therapy, existing)) {
        continue;
      }
      let payload = {
        id: therapy.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID,
        name: therapy.name
      };
      await http.put(PUT.name, PUT.url, payload);
    }
  }
  if (therapiesData.remove.length > 0) {
    for (let therapy of therapiesData.remove) {
      let payload = {
        id: therapy.id,
        diagnosis_id: diagnosisID,
        patient_health_record_id: phrID,
        treatment_id: treatmentID
      };
      const endpoint = DELETE.parse(payload);
      await http.delete(endpoint.name, endpoint.url, null);
    }
  }
};

const saveTreatment = async (treatment: _TreatmentFlat, phrID: number, diagnosisID: number) => {
  const { POST, PUT } = ENDPOINTS.PHR.DOCTOR.DIAGNOSIS.TREATMENT_PLAN.TREATMENT;
  let payload = {
    id: treatment.id === -1 ? undefined : treatment.id,
    diagnosis_id: diagnosisID,
    patient_health_record_id: phrID,
    approved: treatment.approved,
    remarks: treatment.remarks,
    adjuvant: treatment.adjuvant,
    neoadjuvant: treatment.neoadjuvant,
    palliative: treatment.palliative,
    line_of_treatment: treatment.line_of_treatment
  };
  if (!payload.id) {
    delete payload.id;
    return await http.post(POST.name, POST.url, payload);
  } else {
    return await http.put(PUT.name, PUT.url, payload);
  }
};

// Prescription

export const savePatientInstruction = (instruction: PatientInstruction, phrId: number, cancelToken: CancelToken) => {
  const { POST } = ENDPOINTS.PHR.DOCTOR.INSTRUCTION;
  const payload: any = { ...instruction, patient_health_record_id: phrId };
  let method = http.cancellablePut;
  if (instruction.id <= 0) {
    method = http.cancellablePost;
    delete payload.id;
  }
  return method(POST.name, POST.url, payload, cancelToken);
};

export const deletePatientInstruction = (instruction: PatientInstruction, phrId: number) => {
  const { DELETE } = ENDPOINTS.PHR.DOCTOR.INSTRUCTION;
  const payload = { patient_health_record_id: phrId, id: instruction.id };
  return http.delete(DELETE.name, DELETE.url, payload);
};

export const getPrescriptions = (phrId: number) => {
  const { GET } = ENDPOINTS.PHR.DOCTOR.PRESCRIPTION;
  const endpoint = GET.parse({ patient_health_record_id: phrId });
  return http.get(endpoint.name, endpoint.url);
};

export const insertPrescription = async (pres: Prescription, phrId: number) => {
  const { POST } = ENDPOINTS.PHR.DOCTOR.PRESCRIPTION;
  const mapped: any = {};
  let payload = {
    ...transformOutRecord.prescription(pres),
    patient_health_record_id: phrId
  };

  const response = await http.post(POST.name, POST.url, payload);
  if (response.status < 300) {
    return transformInRecord.prescriptions([response.data])[0];
  } else {
    return null;
  }
};

export const insertPrescriptions = async (prescriptions: Prescription[], phrId: number, diagnosisMap: any) => {
  const { POST } = ENDPOINTS.PHR.DOCTOR.PRESCRIPTION;
  const mapped: any = {};
  for (const pres of prescriptions) {
    let payload = {
      ...transformOutRecord.prescription(pres),
      patient_health_record_id: phrId
    };
    delete (payload as any).id;
    const { data } = await http.post(POST.name, POST.url, payload);
    if (pres.diagnosis && pres.diagnosis.id <= 0) {
      const dx = diagnosisMap[pres.diagnosis.id];
      const dxId = dx ? dx.id : 0;
      mapped[data.id] = dxId;
    } else mapped[data.id] = pres.diagnosis ? pres.diagnosis.id : 0;
  }
  return mapped;
};

export const updatePrescription = async (prescription: Prescription, phrId: number): Promise<Prescription | null> => {
  const { PUT } = ENDPOINTS.PHR.DOCTOR.PRESCRIPTION;
  let payload = transformOutRecord.prescription(prescription);

  const response = await http.put(PUT.name, PUT.url, {
    ...payload,
    patient_health_record_id: phrId
  });

  if (response.status < 300) {
    return transformInRecord.prescriptions([response.data])[0];
  } else {
    return null;
  }
};

export const updatePrescriptions = async (prescriptions: Prescription[], phrId: number, diagnosisMap: any) => {
  const { PUT } = ENDPOINTS.PHR.DOCTOR.PRESCRIPTION;
  const mapped: any = {};
  for (const pres of prescriptions) {
    let payload = transformOutRecord.prescription(pres);
    const { data } = await http.put(PUT.name, PUT.url, {
      ...payload,
      patient_health_record_id: phrId
    });
    if (pres.diagnosis && pres.diagnosis.id <= 0) {
      const dx = diagnosisMap[pres.diagnosis.id];
      const dxId = dx ? dx.id : 0;
      mapped[data.id] = dxId;
    } else mapped[data.id] = pres.diagnosis ? pres.diagnosis.id : 0;
  }
  return mapped;
};

export const removePrescriptions = (prescriptions: Prescription[], phrId: number) => {
  const { DELETE } = ENDPOINTS.PHR.DOCTOR.PRESCRIPTION;
  const promises = prescriptions.map(pres => {
    const endpoint = DELETE.parse({ id: pres.id, patient_health_record_id: phrId });
    return http.delete(endpoint.name, endpoint.url, null);
  });
  return Promise.all(promises);
};

export const linkDiagnosisPrescription = async (
  phrId: number,
  prescriptions: Prescription,
  diagnosis: StandardDiagnosis
) => {
  const { POST } = ENDPOINTS.PHR.DOCTOR.DIAGNOSIS_PRESCRIPTION;

  const method = prescriptions.diagnosisPrescriptionId ? http.put : http.post;
  const payload = {
    id: prescriptions.diagnosisPrescriptionId || undefined,
    patient_health_record_id: phrId,
    phr_clinical_diagnosis_id: diagnosis.id,
    phr_prescription_id: prescriptions.id
  };
  const response = await method(POST.name, POST.url, payload);
  if (response.status < 300) {
    return response.data.id;
  } else {
    return null;
  }
};

// Clinical Diagnostic Procedure
export const searchProcedureReference = (term: string) => {
  const endpoint = ENDPOINTS.REFERENCE.SEARCH_DIAGNOSTIC;
  return http.get(endpoint.name, `${endpoint.url}?term=${term}&sort_by=is_proser&sort_direction=desc`);
};

export const searchDiagProcedureReference = (term: string) => {
  const endpoint = ENDPOINTS.REFERENCE.SEARCH_PROCEDURE;
  return http.get(endpoint.name, `${endpoint.url}?term=${term}&sort_by=is_proser&sort_direction=desc`);
};

export const getProcedure = (phrId: number) => {
  const { GET: endpoint } = ENDPOINTS.PHR.DOCTOR.PROCEDURE;
  return http.get(endpoint.name, `${endpoint.url}?patient_health_record_id=${phrId}`);
};

export const addProcedure = (phrId: number, procedures: Array<string>, additional: string, remarks: string) => {
  const { POST: endpoint } = ENDPOINTS.PHR.DOCTOR.PROCEDURE;
  return http.post(endpoint.name, endpoint.url, {
    patient_health_record_id: phrId,
    name: procedures,
    additional,
    remarks
  });
};

export const updateProcedure = (
  id: number,
  phrId: number,
  procedures: Array<string>,
  additional: string,
  remarks: string
) => {
  const { PUT: endpoint } = ENDPOINTS.PHR.DOCTOR.PROCEDURE;
  return http.put(endpoint.name, endpoint.url, {
    id,
    patient_health_record_id: phrId,
    name: procedures,
    additional,
    remarks
  });
};
//Request Diagnostic Procedure
export const getProcedures = (phrId: number) => {
  const { PROCEDURES } = ENDPOINTS.PHR.DOCTOR;
  const endpoint = PROCEDURES.parse({ patient_health_record_id: phrId });
  return http.get(endpoint.name, endpoint.url);
};

export const getTreatmentRecords = (patientID: number) => {
  const { GET } = ENDPOINTS.DOCTOR.PATIENT.TREATMENT_RECORDS;
  const endpoint = GET.parse({ patient_id: patientID });
  return http.get(endpoint.name, endpoint.url);
};

export const getSharedTreatmentRecords = (patientID: number) => {
  const { SHARED } = ENDPOINTS.DOCTOR.PATIENT.TREATMENT_RECORDS;
  const endpoint = SHARED.parse({ patient_id: patientID });
  return http.get(endpoint.name, endpoint.url);
};

const getStagingFormEndpoint = (type: StagingFormType) => {
  const {
    LIVER,
    COLORECTAL,
    PANCREATIC,
    GALLBLADDER,
    BREAST,
    CERVIX_UTERI,
    LUNG,
    PROSTATE,
    CERVICAL_LYMPH_NODES,
    ENDOMETRIAL,
    OROPHARYNGEAL,
    NASOPHARYNGEAL,
    ORAL_CAVITY,
    BRAIN_AND_SPINAL_CORD
  } = ENDPOINTS.PHR.DOCTOR.STAGING_FORM;
  switch (type) {
    case StagingFormType.Liver:
      return LIVER;
    case StagingFormType.Pancreatic:
      return PANCREATIC;
    case StagingFormType.Colorectal:
      return COLORECTAL;
    case StagingFormType.Gallbladder:
      return GALLBLADDER;
    case StagingFormType.Breast:
      return BREAST;
    case StagingFormType.CervixUteri:
      return CERVIX_UTERI;
    case StagingFormType.Lung:
      return LUNG;
    case StagingFormType.Prostate:
      return PROSTATE;
    case StagingFormType.CervicalLymphNodes:
      return CERVICAL_LYMPH_NODES;
    case StagingFormType.Endometrial:
      return ENDOMETRIAL;
    case StagingFormType.Oropharyngeal:
      return OROPHARYNGEAL;
    case StagingFormType.Nasopharyngeal:
      return NASOPHARYNGEAL;
    case StagingFormType.OralCavity:
      return ORAL_CAVITY;
    case StagingFormType.BrainAndSpinalCord:
      return BRAIN_AND_SPINAL_CORD;
    default:
      return LIVER;
  }
};

export const saveStagingForm = (form: CombinedStagingForm, phrId: number, diagnosisId: number, token: CancelToken) => {
  const { parse } = getStagingFormEndpoint(form.type);
  const label = form.id > 0 ? 'update' : 'create';
  const method = form.id > 0 ? http.cancellablePut : http.cancellablePost;
  const payload = {
    ...transformOutStagingForm.anyStagingForm(form),
    patient_health_record_id: phrId,
    phr_clinical_diagnosis_id: diagnosisId
  };
  if (payload.id === -1) {
    delete payload.id;
  }
  const endpoint = parse(label);
  return method(endpoint.name, endpoint.url, payload, token);
};

export const deleteStagingForm = (phrId: number, id: number, type: StagingFormType) => {
  const { parse } = getStagingFormEndpoint(type);
  const endpoint = parse('delete', { patient_health_record_id: phrId, id });
  return http.delete(endpoint.name, endpoint.url, null);
};

export const getStagingFormByPatientId = (patientId: number, fromDate?: string, toDate?: string) => {
  const { parse } = ENDPOINTS.PHR.DOCTOR.STAGING_FORM.GET_BY_PATIENT_ID;
  const endpoint = parse({
    patient_id: patientId,
    from_date: fromDate ? fromDate : undefined,
    to_date: toDate ? toDate : undefined
  });
  return http.get(endpoint.name, endpoint.url);
};

export const getPrescriptionTemplates = () => {
  const { GET: endpoint } = ENDPOINTS.DOCTOR.PRESCRIPTION.TEMPLATE.GROUP;
  return http.getAll(endpoint.name, `${endpoint.url}?`);
};

export const createPrescriptionTemplateGroup = (name: string) => {
  const { POST: endpoint } = ENDPOINTS.DOCTOR.PRESCRIPTION.TEMPLATE.GROUP;
  return http.post(endpoint.name, endpoint.url, { name });
};

export const updatePrescriptionsTemplateGroup = (groupId: number, groupName: string, cancelToken?: CancelToken) => {
  const { PUT: endpoint } = ENDPOINTS.DOCTOR.PRESCRIPTION.TEMPLATE.GROUP;
  return http.cancellablePut(endpoint.name, endpoint.url, { id: groupId, name: groupName }, cancelToken);
};

export const deletePrescriptionsTemplateGroup = (groupId: number) => {
  const { DELETE: endpoint } = ENDPOINTS.DOCTOR.PRESCRIPTION.TEMPLATE.GROUP;
  return http.delete(endpoint.name, endpoint.url, { id: groupId });
};

export const addPrescriptionsToTemplateGroup = (groupId: number, prescriptionIds: number[]) => {
  const { POST: endpoint } = ENDPOINTS.DOCTOR.PRESCRIPTION.TEMPLATE.GROUP.ITEM;
  return http.post(endpoint.name, endpoint.url, { id: groupId, phr_prescription_ids: prescriptionIds });
};

export const removePrescriptionsFromTemplateGroup = (groupId: number, prescriptionIds: number[]) => {
  const { DELETE: endpoint } = ENDPOINTS.DOCTOR.PRESCRIPTION.TEMPLATE.GROUP.ITEM;
  return http.delete(endpoint.name, endpoint.url, { id: groupId, phr_prescription_ids: prescriptionIds });
};

export const getPhrCount = (status: boolean) => {
  const { COUNT: endpoint } = ENDPOINTS.V3;
  return http.get(endpoint.name, `${endpoint.url}?active=${status}`);
};

export const sendPatientReferral = (form: PatientReferralData) => {
  const { POST: endpoint } = ENDPOINTS.PHR.PATIENT_REFERRAL;
  const payload: _PatientReferralData = transformOutPatientReferral(form);
  return http.post(endpoint.name, endpoint.url, payload);
};

export const getReferralFavorites = () => {
  const { FAVORITES: endpoint } = ENDPOINTS.PHR.PATIENT_REFERRAL;
  return http.get(endpoint.name, endpoint.url);
};

export const getAllDiagramsByPatientId = (patientId: number, fromDate?: string, toDate?: string) => {
  const endpoint = ENDPOINTS.DIAGRAMS.GET.parse({
    patient_id: patientId,
    from_date: fromDate ? fromDate : undefined,
    to_date: toDate ? toDate : undefined
  });
  return http.get(endpoint.name, endpoint.url);
};

export const getEncounterVaccination = (phrId: number, patientId: number) => {
  const { parse } = ENDPOINTS.PHR.DOCTOR.VACCINATION;
  const endpoint = parse({ patient_health_record_id: phrId, patient_id: patientId }, 'Encounter');
  return http.get(endpoint.name, endpoint.url);
};

export const saveEncounterVaccination = (v: any, token: CancelToken) => {
  const { key: endpoint } = ENDPOINTS.PHR.DOCTOR.VACCINATION;
  const id = v.id ? Number(v.id) : undefined;
  const method = id ? http.cancellablePut : http.cancellablePost;
  const payload: _EncounterVaccination = {
    id,
    patient_id: v.patientId,
    patient_health_record_id: v.phrId,
    vaccine: v.vaccine,
    vaccine_type: v.vaccineType,
    dosage: v.dosage,
    given_at: v.givenAt ? parseDate(v.givenAt) : '',
    due_at: v.dueAt ? parseDate(v.dueAt) : '',
    administered_by: v.administeredBy,
    notify_email: v.notifyEmail,
    notify_sms: v.notifySms,
  };
  return method(endpoint.name, endpoint.url, payload, token);
};

export const deleteEncounterVaccination = (vaccination: EncounterVaccination) => {
  const { key: endpoint } = ENDPOINTS.PHR.DOCTOR.VACCINATION;
  const payload = {
    id: vaccination.id,
    patient_id: vaccination.patientId
  };
  return http.delete(endpoint.name, endpoint.url, payload);
};

export const getNotesAndFindingsFiles = (phrId: number) => {
  const endpoint = ENDPOINTS.PHR.NOTES_AND_FINDINGS_FILES.parse({ patient_health_record_id: phrId });
  return http.get(endpoint.name, endpoint.url);
};

export const saveNotesAndFindingsFiles = (phrId: number, file: File) => {
  const endpoint = ENDPOINTS.PHR.NOTES_AND_FINDINGS_FILES.key;
  let payload = new FormData();
  payload.append('file', file);
  payload.append('patient_health_record_id', `${phrId}`);
  return http.post(endpoint.url, endpoint.url, payload);
};

export const deleteNotesAndFindingsFile = (phrId: number, id: number) => {
  const endpoint = ENDPOINTS.PHR.NOTES_AND_FINDINGS_FILES.key;
  return http.delete(endpoint.name, endpoint.url, { patient_health_record_id: phrId, id });
};

export const getVitals = (patient_id: number) => {
  const endpoint = ENDPOINTS.PHR.VITALS.parse({ patient_id });
  return http.get(endpoint.name, endpoint.url);
};
