import { Button as MatButton, CircularProgress } from '@material-ui/core';
import { ButtonProps as MatButtonProps } from '@material-ui/core/Button';
import { styled } from 'components/Theme';
import React from 'react';

export interface ButtonProps extends MatButtonProps {
  label?: string;
  className?: string;
  fullWidth?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  startIconSrc?: string;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  className = '',
  fullWidth,
  loading,
  onClick,
  disabled,
  type,
  color,
  style,
  children,
  startIconSrc,
  ...props
}) => {
  let buttonClasses = `c-button ${className}`.trim();
  if (fullWidth) {
    buttonClasses += ' c-button--full';
  }
  if (loading) {
    buttonClasses += ' c-button--loading';
  }
  const startIcon =
    props.startIcon ?? (startIconSrc ? <img className="start-icon" alt="start-icon" src={startIconSrc} /> : undefined);

  return (
    <ButtonWrapper className={buttonClasses} style={style}>
      <StyledMatButton
        {...props}
        startIcon={startIcon}
        fullWidth={true}
        onClick={onClick}
        type={type ?? 'button'}
        color={color ?? 'default'}
        variant={props.variant || 'contained'}
        disabled={disabled || loading}
        className="mat-button"
        size={props.size ?? 'large'}
      >
        {label}
        {children}
      </StyledMatButton>
      {loading && <CircularProgress color="primary" size={24} className="loader" />}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: inline-flex;
  position: relative;
  &.c-button--full {
    width: 100%;
  }
  .loader {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
    transform: translate(0, 0);
  }
`;

export const StyledMatButton = styled(MatButton)`
  max-height: 38px;
  &.MuiButtonBase-root {
    box-shadow: none;
    border-radius: 3px;
    font-weight: normal;
    padding-left: 24px;
    padding-right: 24px;
    .MuiButton-label {
      color: ${props => props.theme.color.grey};
    }
    &.MuiButton-contained:hover {
      box-shadow: none;
    }
  }
  &.MuiButton-textPrimary {
    .MuiButton-label {
      color: ${props => props.theme.color.primary};
    }
  }
  &.MuiButton-textSecondary {
    .MuiButton-label {
      color: ${props => props.theme.color.secondary};
    }
  }
  &.MuiButton-containedPrimary {
    background-color: ${props => props.theme.color.primary};
    transition: all 0.2s ease-in-out;
    .MuiButton-label {
      color: #fff;
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      background-color: ${props => props.theme.color.primaryLight};
      .MuiButton-label {
        color: ${props => '#fff'};
      }
    }
  }
  &.MuiButton-containedSecondary {
    background-color: ${props => props.theme.color.secondary};
    transition: all 0.2s ease-in-out;
    .MuiButton-label {
      color: ${props => props.theme.color.primary};
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      background-color: ${props => props.theme.color.secondaryDark};
      .MuiButton-label {
        color: ${props => props.theme.color.primary};
      }
    }
  }
`;
