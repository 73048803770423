import axios, { AxiosPromise } from 'axios';
import { Toast } from 'components';
import { ERROR_MAP } from 'defaults';
import { Credentials } from 'models';
import { getProject } from 'services/utils.service';
import { errorHandler } from './utils';

export function login<T>(credentials: Credentials): AxiosPromise<T> {
  const { email, password } = credentials;
  const { project } = getProject();
  return new Promise((res, rej) => {
    axios
      .post<T>(
        `/v1/auth/login`,
        {
          email,
          password
        },
        {
          headers: {
            'X-ENV': import.meta.env.VITE_NODE_ENV || '',
            Project: project
          }
        }
      )
      .then(data => res(data))
      .catch(err => {
        const tmp = errorHandler(err);
        const error = ERROR_MAP[tmp.error];
        error && Toast.error(error || 'An error occurred.');
        rej(tmp);
      });
  });
}
