import { ENDPOINTS } from 'defaults';
import { http } from './http';
import { ContactNumber, Address, OwnerType } from 'models/user';
import { transformOut } from '../transformer/user';
import { _Address } from 'models/endpoint';
const { NUMBER, ADDRESS } = ENDPOINTS.CONTACT;

export async function updateContactNumber(payload: Partial<ContactNumber>) {
  const { UPDATE } = NUMBER;
  return http.put(UPDATE.name, UPDATE.url, transformOut.updateContactNumber(payload));
}

export async function updateContactNumbers(payload: Partial<ContactNumber>[]) {
  const { ADD, UPDATE } = NUMBER;
  const promises = payload.map(contactNumber => {
    return !!contactNumber.id
      ? http.put(UPDATE.name, UPDATE.url, transformOut.updateContactNumber(contactNumber))
      : http.post(ADD.name, ADD.url, transformOut.addContactNumber(contactNumber));
  });
  return Promise.all(promises);
}

export async function getContactNumbers(userID: number) {
  const { GET } = NUMBER;
  const endpoint = GET.parse({ owner_id: userID, owner_type: OwnerType.User });
  return http.get(endpoint.name, endpoint.url);
}

export async function deleteContactNumbers(ids: number[], userID: number) {
  const { DELETE } = NUMBER;
  const promises = ids.map(id =>
    http.delete(DELETE.name, DELETE.url, { id, owner_id: userID, owner_type: OwnerType.User })
  );
  return Promise.all(promises);
}

export async function updateContactAddress(payload: Partial<Address>) {
  const { UPDATE } = ADDRESS;
  return http.put(UPDATE.name, UPDATE.url, transformOut.updateContactAddress(payload));
}

export async function updateContactAddresses(payload: Partial<Address>[]) {
  const { ADD, UPDATE } = ADDRESS;
  const promises = payload.map(address => {
    return typeof address.id !== 'undefined'
      ? http.put(UPDATE.name, UPDATE.url, transformOut.updateContactAddress(address))
      : http.post(ADD.name, ADD.url, transformOut.addContactAddress(address));
  });
  return Promise.all(promises);
}

export async function savePatientAddress(address: Partial<_Address> | null) {
  const { ADD } = ADDRESS;
  if (!address) return { data: null };
  if (address.id !== undefined) return { data: address };
  if (address.id === undefined && !address.address1) return { data: null };
  return http.post(ADD.name, ADD.url, address);
}
