// Components
import { useUnleashContext } from '@unleash/proxy-client-react';
import { getFullName, setLocalStorageData } from 'common/services';
import {
  // AsyncAnalytics,
  AsyncAppointmentPreConsultation,
  AsyncAppointments,
  AsyncAuth,
  AsyncAuthBCMAP,
  AsyncAuthTMC,
  AsyncDashboard,
  AsyncEditPatientProfile,
  AsyncHelp,
  AsyncNewPatientFromAppointment,
  AsyncNewPatientFromPatientList,
  AsyncOnboarding,
  AsyncPatientBCMAP,
  AsyncPatientList,
  AsyncPatientNalagen,
  AsyncPatientRecords,
  AsyncPatientScreening,
  AsyncPatientTAVR,
  AsyncPHRCF4,
  AsyncPHRForm,
  AsyncProfile,
  AsyncReschedule,
  AsyncResetPassword,
  AuthenticatedRoute,
  Loading
} from 'components';
import { ROUTES } from 'constants/routes';
import { useSessionLocationDispatch } from 'context/SessionContext';
import { useActiveAccount, useAppFavicon, useCurrentDoctor, useCurrentUser, useEnv } from 'hooks';
import { UserTheme } from 'models';
import { StoreAuth, StorePeek, StoreState } from 'models/store';
import PageNotFound from 'pages/PageNotFound';
import SpeedDial from 'partials/SpeedDial';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { usePrevious, useTitle } from 'react-use';
import { bindActionCreators } from 'redux';
import { Prompt } from 'shared/utils/Prompt';
import { getSecretaryPermissions } from 'state/actions/secretary';
import { getCurrentUser, GetCurrentUserAction } from 'state/actions/user';
import './i18n';
import { initSocket, socket } from './socket';

interface StateMap {
  auth: StoreAuth;
  peek: StorePeek;
}
interface DispatchMap {
  getCurrentUser: GetCurrentUserAction;
}
const MSP = (state: StoreState): StateMap => ({
  auth: state.auth,
  peek: state.peek
});
const MDP = (dispatch: any) => bindActionCreators({ getCurrentUser }, dispatch);

const App: React.FC<StateMap & DispatchMap> = props => {
  const updateContext = useUnleashContext();
  const {
    inMedCheckAuth,
    inBCMAPAuth,
    inTMCAuth,
    isBCMAPTheme,
    isTMCTheme,
    isKeralty,
    inKeraltyAuth,
    isMedCheckTheme,
    isTMC,
    project
  } = useEnv();
  // useAppFavicon('/app', isTMC ? 'tmc-favicon.ico' : isKeralty ? 'keralty-favicon.ico' : undefined);
  useAppFavicon('/app', isTMC ? 'tmc-favicon.ico' : undefined);

  const isMount = useRef<boolean>(false);
  const dispatch = useDispatch();
  useTitle(isBCMAPTheme ? 'CSPMAP Dashboard' : isTMCTheme ? 'Dashboard' : 'MedCheck Dashboard');

  const { getCurrentUser } = props;
  const { data: peeks } = props.peek;
  const activePeeks = peeks.filter(p => p.visible);
  const prevPeeks = usePrevious(activePeeks);
  const { isAuthenticated } = props.auth.data;
  const [loaded, setLoaded] = useState(false);
  const { isBcmapDoctorOnly, inProgress: currentDoctorInProgress } = useCurrentDoctor();
  const {
    currentUser: { data: currentUser, inProgress: currentUserInProgress },
    isDoctor,
    isSecretary
  } = useCurrentUser();
  const { activeAccountId: accountId, activeAccount } = useActiveAccount();
  const setLocations = useSessionLocationDispatch();

  const path = window.location.pathname + window.location.search;

  const payload = useMemo(() => {
    if (!currentUser || accountId === 0) return null;
    const { id } = currentUser;
    const name = `${isDoctor ? 'Dr. ' : ''}${getFullName(currentUser)}`;
    return {
      id,
      name,
      accountId,
      location: path
    };
  }, [currentUser, isDoctor, accountId, path]);

  useEffect(() => {
    if (!prevPeeks || activePeeks.length !== prevPeeks.length) {
      const body = document.getElementsByTagName('body').item(0);
      body && body.setAttribute('style', activePeeks.length > 0 ? 'overflow: hidden;' : '');
    }
  }, [activePeeks, prevPeeks]);

  useEffect(() => {
    if (isAuthenticated && !loaded) {
      getCurrentUser();
      setLoaded(true);
    }
  }, [getCurrentUser, isAuthenticated, loaded]);

  useEffect(() => {
    if (isSecretary && accountId) {
      isMount.current = true;
      if (isMount) {
        const userId = currentUser?.id;
        userId && dispatch(getSecretaryPermissions(userId));
      }
    }

    return () => {
      isMount.current = false;
    };
  }, [currentUser, isSecretary, isMount, dispatch, accountId]);

  useEffect(() => {
    if (inMedCheckAuth && !isMedCheckTheme) {
      setLocalStorageData({ userTheme: UserTheme.MedCheck });
      window.location.reload();
    } else if (inBCMAPAuth && !isBCMAPTheme) {
      setLocalStorageData({ userTheme: UserTheme.BCMAP });
      window.location.reload();
    } else if (inTMCAuth && !isTMCTheme) {
      setLocalStorageData({ userTheme: UserTheme.TMC });
      window.location.reload();
    }
  }, [inBCMAPAuth, inMedCheckAuth, inTMCAuth, isMedCheckTheme, isBCMAPTheme, isTMCTheme, inKeraltyAuth]);

  // This is being removed due to very high failure rates.
  // useEffect(() => {
  //   if (!payload) return;
  //   initSocket(payload, setLocations);
  // }, [payload, setLocations]);

  useEffect(() => {
    if (currentUser?.id) {
      updateContext({ userId: String(currentUser.id) });
    }
  }, [currentUser?.id]);

  const cProps = {
    isAuthenticated: isAuthenticated,
    currentUser,
    isBcmapDoctorOnly,
    socket
  };

  return currentUserInProgress || currentDoctorInProgress ? (
    <Loading
      loading={currentUserInProgress || currentDoctorInProgress}
      type={isTMC ? 'tmc' : 'heart'}
      project={project}
      className="full"
    />
  ) : (
    <>
      <Prompt />
      <Switch>
        <AuthenticatedRoute exact path={ROUTES.DASHBOARD} component={AsyncDashboard} {...cProps} />
        <AuthenticatedRoute exact path={ROUTES.ONBOARDING} component={AsyncOnboarding} {...cProps} />
        <AuthenticatedRoute exact path={ROUTES.PATIENTS.RECORDS.key} component={AsyncPatientRecords} {...cProps} />
        <AuthenticatedRoute exact path={ROUTES.PATIENTS.BCMAP.key} component={AsyncPatientBCMAP} {...cProps} />
        <AuthenticatedRoute exact path={ROUTES.PATIENTS.SCREENING.key} component={AsyncPatientScreening} {...cProps} />
        <AuthenticatedRoute exact path={ROUTES.PATIENTS.TAVR.key} component={AsyncPatientTAVR} {...cProps} />
        {currentUser?.access?.submissionForm?.nalagen && (
          <AuthenticatedRoute exact path={ROUTES.PATIENTS.NALAGEN.key} component={AsyncPatientNalagen} {...cProps} />
        )}
        <AuthenticatedRoute exact path={ROUTES.PATIENTS.LIST} component={AsyncPatientList} {...cProps} />
        <AuthenticatedRoute exact path={ROUTES.PHR.CF4.key} component={AsyncPHRCF4} {...cProps} />
        <AuthenticatedRoute path={ROUTES.PHR.key} component={AsyncPHRForm} {...cProps} />
        <AuthenticatedRoute exact path={ROUTES.PROFILE} component={AsyncProfile} {...cProps} />
        {activeAccount?.classification !== 'opd' && (
          <AuthenticatedRoute exact path={ROUTES.BILLING} component={AsyncProfile} {...cProps} />
        )}
        <AuthenticatedRoute exact path={ROUTES.APPOINTMENT.HOME} component={AsyncAppointments} {...cProps} />
        <AuthenticatedRoute exact path={ROUTES.PATIENT.NEW} component={AsyncNewPatientFromPatientList} {...cProps} />
        <AuthenticatedRoute exact path={ROUTES.PATIENT.EDIT} component={AsyncEditPatientProfile} {...cProps} />
        <AuthenticatedRoute
          exact
          path={ROUTES.APPOINTMENT.PATIENT.NEW}
          component={AsyncNewPatientFromAppointment}
          {...cProps}
        />
        <AuthenticatedRoute
          exact
          path={ROUTES.APPOINTMENT.PRE_CONSULTATION}
          component={AsyncAppointmentPreConsultation}
          {...cProps}
        />
        {/* <AuthenticatedRoute exact path={ROUTES.ANALYTICS} component={AsyncAnalytics} {...cProps} /> */}
        <AuthenticatedRoute exact path={ROUTES.HELP} component={AsyncHelp} {...cProps} />
        <Route path={ROUTES.RESETPASSWORD} component={AsyncResetPassword} />
        {inBCMAPAuth && (
          <>
            <Route exact path={ROUTES.BCMAP.BASE} render={() => <Redirect to={ROUTES.BCMAP.LOGIN} />} />
            <Route exact path={ROUTES.BCMAP.LOGIN} component={AsyncAuthBCMAP} />
            <Route exact path={ROUTES.BCMAP.SIGNUP} component={AsyncAuthBCMAP} />
            <Route path={ROUTES.BCMAP.CONFIRMATION} component={AsyncAuthBCMAP} />
          </>
        )}
        {inTMCAuth && (
          <>
            <Route exact path={ROUTES.LOGIN} component={AsyncAuthTMC} />
            <Route exact path={ROUTES.SIGNUP} component={AsyncAuthTMC} />
            <Route path={ROUTES.CONFIRMATION} component={AsyncAuthTMC} />
          </>
        )}

        <Route path={ROUTES.LOGIN} component={AsyncAuth} />
        <Route path={ROUTES.SIGNUP} component={AsyncAuth} />
        <Route path={ROUTES.CONFIRMATION} component={AsyncAuth} />

        <Route path={ROUTES.RESCHEDULE} component={AsyncReschedule} />
        <Route component={PageNotFound} />
      </Switch>
      <SpeedDial />
    </>
  );
};

export default connect(MSP, MDP)(App);
