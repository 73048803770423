import React, { useContext, useState, useEffect } from 'react';
import logo from 'assets/images/logo.png';
import rx from 'assets/images/prescription-logo.png';
import tmc from 'assets/images/tmc_logo_blue.png';
import { useSelector, shallowEqual } from 'react-redux';
import { StoreState } from 'models/store';
import { useEnv } from 'hooks';
import { toBase64 } from 'services/utils-data.service';
import { getAsset } from 'common/services';

type PreloadedAssets = {
  loading: boolean;
  clinicHeaderLink: string;
  clinicHeader: string;
  rxLogo: string;
  medLogo: string;
  tmcLogo: string;
};

const AssetLoaderContext = React.createContext<PreloadedAssets | null>(null);

export const AssetLoaderProvider: React.FC = ({ children }) => {
  const { isTMC } = useEnv();
  const [loading] = useState(false);
  const clinicId = useSelector<StoreState, number | undefined>(store => store.clinic.activeClinicId, shallowEqual);
  const [clinicHeaderLink, setClinicHeaderLink] = useState('');
  const [clinicHeader, setClinicHeader] = useState('');
  const [rxLogo, setRx] = useState('');
  const [medLogo, setMedLogo] = useState('');
  const [tmcLogo, setTmcLogo] = useState('');

  useEffect(() => {
    if (!clinicId) return;
    (async () => {
      const { data } = await getAsset('clinic_header', clinicId);
      const headerUrl = Object.keys(data.assets).length > 0 && data.assets[clinicId] ? data.assets[clinicId][0] : '';
      setClinicHeaderLink(headerUrl);
      setClinicHeader(await toBase64(headerUrl));
    })();
  }, [clinicId]);

  useEffect(() => {
    (async () => {
      setRx(await toBase64(rx));
      !isTMC && setMedLogo(await toBase64(logo));
      isTMC && setTmcLogo(await toBase64(tmc));
    })();
  }, [isTMC]);

  const state: PreloadedAssets = {
    loading,
    clinicHeaderLink,
    clinicHeader,
    rxLogo,
    medLogo,
    tmcLogo
  };

  return <AssetLoaderContext.Provider value={state}>{children}</AssetLoaderContext.Provider>;
};

export const useAssetLoader = () => {
  const context = useContext(AssetLoaderContext);
  if (!context) {
    throw new Error('useAssetLoader must be used within a AssetLoaderProvider');
  }
  return context;
};
