import { StoreAction } from 'constants/store';
import { StoreOnboarding, ActionType } from 'models/store';
const { ONBOARD } = StoreAction.USER;

const INITIAL_STATE: StoreOnboarding = {
  status: false,
  inProgress: false
};

function onboardingReducer(state = INITIAL_STATE, action: ActionType): StoreOnboarding {
  switch (action.type) {
    case ONBOARD.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case ONBOARD.FAILED:
      return {
        ...state,
        status: false,
        inProgress: false
      };
    case ONBOARD.SUCCESS:
      return {
        ...state,
        status: true,
        inProgress: false
      };
    default:
      return state;
  }
}

export default onboardingReducer;
