import { StagingFormType } from 'constants/form';
import {
  LiverStagingForm,
  GallbladderStagingForm,
  CombinedStagingForm,
  BreastStagingForm,
  CervixUteriStagingForm,
  LungStagingForm,
  ProstateStagingForm,
  CervicalLymphNodesStagingForm,
  EndometrialStagingForm,
  OropharyngealStagingForm,
  NasopharyngealStagingForm,
  PancreaticStagingForm,
  OralCavityStagingForm,
  BrainAndSpinalCordStagingForm,
  ColorectalCancerStagingForm
} from 'models';
import {
  _CombinedStagingForm,
  _LiverStagingForm,
  _LiverStagingFormInput,
  _GallbladderStagingForm,
  _GallbladderStagingFormInput,
  _BreastStagingForm,
  _CervixUteriStagingForm,
  _LungStagingForm,
  _ProstateStagingForm,
  _CervicalLymphNodesStagingForm,
  _EndometrialStagingForm,
  _OropharyngealStagingForm,
  _NasopharyngealStagingForm,
  _PancreaticStagingForm,
  _OralCavityStagingForm,
  _BrainAndSpinalCordStagingForm,
  _ColorectalCancerStagingForm
} from 'models/endpoint';

export const transformInStagingForm = {
  anyStagingForm: (data: _CombinedStagingForm) => {
    switch (data.type) {
      case StagingFormType.Liver:
        return transformInStagingForm.liver(data as _LiverStagingForm);
      case StagingFormType.Gallbladder:
        return transformInStagingForm.gallbladder(data as _GallbladderStagingForm);
      case StagingFormType.Breast:
        return transformInStagingForm.breast(data as _BreastStagingForm);
      case StagingFormType.CervixUteri:
        return transformInStagingForm.cervixUteri(data as _CervixUteriStagingForm);
      case StagingFormType.Lung:
        return transformInStagingForm.lung(data as _LungStagingForm);
      case StagingFormType.Prostate:
        return transformInStagingForm.prostate(data as _ProstateStagingForm);
      case StagingFormType.CervicalLymphNodes:
        return transformInStagingForm.cervicalLymphNodes(data as _CervicalLymphNodesStagingForm);
      case StagingFormType.Endometrial:
        return transformInStagingForm.endometrialCancer(data as _EndometrialStagingForm);
      case StagingFormType.Oropharyngeal:
        return transformInStagingForm.oropharyngealCancer(data as _OropharyngealStagingForm);
      case StagingFormType.Nasopharyngeal:
        return transformInStagingForm.nasopharyngealCancer(data as _NasopharyngealStagingForm);
      case StagingFormType.Pancreatic:
        return transformInStagingForm.pancreatic(data as _PancreaticStagingForm);
      case StagingFormType.OralCavity:
        return transformInStagingForm.oralCavity(data as _OralCavityStagingForm);
      case StagingFormType.BrainAndSpinalCord:
        return transformInStagingForm.brainAndSpinalCord(data as _BrainAndSpinalCordStagingForm);
      case StagingFormType.Colorectal:
        return transformInStagingForm.colorectalCancer(data as _ColorectalCancerStagingForm);
      default:
        return data;
    }
  },
  liver: (data: _LiverStagingForm): LiverStagingForm => {
    return {
      id: data.id,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name ? data.phr_clinical_diagnosis_name : '',
      stageCategoryDefinition: {
        tumorSize: data.stage_category_definition.tumor_size,
        yClinical: data.stage_category_definition.y_clinical,
        yPathologic: data.stage_category_definition.y_pathologic,
        lateralityLeft: data.stage_category_definition.laterality_left,
        lateralityRight: data.stage_category_definition.laterality_right,
        lateralityBilateral: data.stage_category_definition.laterality_bilateral
      },
      primaryTumor: {
        clinicalT0: data.primary_tumor.clinical_t0,
        clinicalT1: data.primary_tumor.clinical_t1,
        clinicalT2: data.primary_tumor.clinical_t2,
        clinicalT3: data.primary_tumor.clinical_t3,
        clinicalT4: data.primary_tumor.clinical_t4,
        clinicalTx: data.primary_tumor.clinical_tx,
        clinicalT1a: data.primary_tumor.clinical_t1a,
        clinicalT1b: data.primary_tumor.clinical_t1b,
        pathologicT0: data.primary_tumor.pathologic_t0,
        pathologicT1: data.primary_tumor.pathologic_t1,
        pathologicT2: data.primary_tumor.pathologic_t2,
        pathologicT3: data.primary_tumor.pathologic_t3,
        pathologicT4: data.primary_tumor.pathologic_t4,
        pathologicTx: data.primary_tumor.pathologic_tx,
        pathologicT1a: data.primary_tumor.pathologic_t1a,
        pathologicT1b: data.primary_tumor.pathologic_t1b
      },
      regionalLymphNodes: {
        clinicalN0: data.regional_lymph_nodes.clinical_n0,
        clinicalN1: data.regional_lymph_nodes.clinical_n1,
        clinicalNx: data.regional_lymph_nodes.clinical_nx,
        pathologicN0: data.regional_lymph_nodes.pathologic_n0,
        pathologicN1: data.regional_lymph_nodes.pathologic_n1,
        pathologicNx: data.regional_lymph_nodes.pathologic_nx
      },
      distantMetastasis: {
        clinicalM0: data.distant_metastasis.clinical_m0,
        clinicalM1: data.distant_metastasis.clinical_m1,
        pathologicM1: data.distant_metastasis.pathologic_m1
      },
      anatomicStage: {
        clinicalGroup2: data.anatomic_stage.clinical_group_2,
        clinicalGroup1a: data.anatomic_stage.clinical_group_1a,
        clinicalGroup1b: data.anatomic_stage.clinical_group_1b,
        clinicalGroup3a: data.anatomic_stage.clinical_group_3a,
        clinicalGroup3b: data.anatomic_stage.clinical_group_3b,
        clinicalGroup4a: data.anatomic_stage.clinical_group_4a,
        clinicalGroup4b: data.anatomic_stage.clinical_group_4b,
        pathologicGroup2: data.anatomic_stage.pathologic_group_2,
        pathologicGroup1a: data.anatomic_stage.pathologic_group_1a,
        pathologicGroup1b: data.anatomic_stage.pathologic_group_1b,
        pathologicGroup3a: data.anatomic_stage.pathologic_group_3a,
        pathologicGroup3b: data.anatomic_stage.pathologic_group_3b,
        pathologicGroup4a: data.anatomic_stage.pathologic_group_4a,
        pathologicGroup4b: data.anatomic_stage.pathologic_group_4b,
        clinicalStageUnknown: data.anatomic_stage.clinical_stage_unknown,
        pathologicStageUnknown: data.anatomic_stage.pathologic_stage_unknown
      },
      prognosticFactors: {
        nccn: data.prognostic_factors.nccn,
        others: data.prognostic_factors.others,
        bilirubin: data.prognostic_factors.bilirubin,
        creatinine: data.prognostic_factors.creatinine,
        hgGrade1: data.prognostic_factors.hg_grade_1,
        hgGrade2: data.prognostic_factors.hg_grade_2,
        hgGrade3: data.prognostic_factors.hg_grade_3,
        hgGrade4: data.prognostic_factors.hg_grade_4,
        lviAbsent: data.prognostic_factors.lvi_absent,
        lviPresent: data.prognostic_factors.lvi_present,
        lviUnknown: data.prognostic_factors.lvi_unknown,
        othersRemark: data.prognostic_factors.others_remark,
        fibrosisScore: data.prognostic_factors.fibrosis_score,
        alphaFetoprotein: data.prognostic_factors.alpha_fetoprotein,
        hg2GradeSystem: data.prognostic_factors.hg_2_grade_system,
        hg3GradeSystem: data.prognostic_factors.hg_3_grade_system,
        hg4GradeSystem: data.prognostic_factors.hg_4_grade_system,
        residualTumorR0: data.prognostic_factors.residual_tumor_r0,
        residualTumorR1: data.prognostic_factors.residual_tumor_r1,
        residualTumorR2: data.prognostic_factors.residual_tumor_r2,
        residualTumorRx: data.prognostic_factors.residual_tumor_rx,
        hepatitisSerology: data.prognostic_factors.hepatitis_serology,
        hgNoGradeSystem: data.prognostic_factors.hg_no_grade_system,
        lviNotApplicable: data.prognostic_factors.lvi_not_applicable,
        prothrombinNormalizedRatio: data.prognostic_factors.prothrombin_normalized_ratio,
        clinicalStageTreatmentPlanning: data.prognostic_factors.clinical_stage_treatment_planning,
        nationalGuidelinesTreatmentPlanning: data.prognostic_factors.national_guidelines_treatment_planning,
        clinicalStageTreatmentPlanningRemark: data.prognostic_factors.clinical_stage_treatment_planning_remark
      },
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : { splines: [], texts: [] },
      stagingSpecificData: data.staging_specific_data,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  gallbladder: (data: _GallbladderStagingForm): GallbladderStagingForm => {
    return {
      id: data.id,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name ? data.phr_clinical_diagnosis_name : '',
      stageCategoryDefinition: {
        tumorSize: data.stage_category_definition.tumor_size,
        lateralityLeft: data.stage_category_definition.laterality_left,
        lateralityRight: data.stage_category_definition.laterality_right,
        lateralityBilateral: data.stage_category_definition.laterality_bilateral,
        yClinical: data.stage_category_definition.y_clinical,
        yPathologic: data.stage_category_definition.y_pathologic
      },
      primaryTumor: {
        clinicalTx: data.primary_tumor.clinical_tx,
        clinicalT0: data.primary_tumor.clinical_t0,
        clinicalT1: data.primary_tumor.clinical_t1,
        clinicalT2: data.primary_tumor.clinical_t2,
        pathologicTx: data.primary_tumor.pathologic_tx,
        pathologicT0: data.primary_tumor.pathologic_t0,
        pathologicT1: data.primary_tumor.pathologic_t1,
        pathologicT2: data.primary_tumor.pathologic_t2,
        clinicalTis: data.primary_tumor.clinical_tis,
        clinicalT1a: data.primary_tumor.clinical_t1a,
        clinicalT1b: data.primary_tumor.clinical_t1b,
        clinicalT2a: data.primary_tumor.clinical_t2a,
        clinicalT2b: data.primary_tumor.clinical_t2b,
        clinicalT3: data.primary_tumor.clinical_t3,
        clinicalT4: data.primary_tumor.clinical_t4,
        pathologicTis: data.primary_tumor.pathologic_tis,
        pathologicT1a: data.primary_tumor.pathologic_t1a,
        pathologicT1b: data.primary_tumor.pathologic_t1b,
        pathologicT2a: data.primary_tumor.pathologic_t2a,
        pathologicT2b: data.primary_tumor.pathologic_t2b,
        pathologicT3: data.primary_tumor.pathologic_t3,
        pathologicT4: data.primary_tumor.pathologic_t4
      },
      regionalLymphNodes: {
        clinicalN0: data.regional_lymph_nodes.clinical_n0,
        clinicalN1: data.regional_lymph_nodes.clinical_n1,
        clinicalNx: data.regional_lymph_nodes.clinical_nx,
        pathologicN0: data.regional_lymph_nodes.pathologic_n0,
        pathologicN1: data.regional_lymph_nodes.pathologic_n1,
        pathologicNx: data.regional_lymph_nodes.pathologic_nx,
        clinicalN2: data.regional_lymph_nodes.clinical_n2,
        pathologicN2: data.regional_lymph_nodes.pathologic_n2
      },
      distantMetastasis: {
        clinicalM0: data.distant_metastasis.clinical_m0,
        clinicalM1: data.distant_metastasis.clinical_m1,
        pathologicM1: data.distant_metastasis.pathologic_m1
      },
      anatomicStage: {
        clinicalGroup0: data.anatomic_stage.clinical_group_0,
        clinicalGroup1: data.anatomic_stage.clinical_group_1,
        clinicalGroup2a: data.anatomic_stage.clinical_group_2a,
        clinicalGroup2b: data.anatomic_stage.clinical_group_2b,
        clinicalGroup4b2: data.anatomic_stage.clinical_group_4b_2,
        pathologicGroup0: data.anatomic_stage.pathologic_group_0,
        pathologicGroup1: data.anatomic_stage.pathologic_group_1,
        pathologicGroup2a: data.anatomic_stage.pathologic_group_2a,
        pathologicGroup2b: data.anatomic_stage.pathologic_group_2b,
        pathologicGroup4b2: data.anatomic_stage.pathologic_group_4b_2,
        clinicalGroup3a: data.anatomic_stage.clinical_group_3a,
        clinicalGroup3b: data.anatomic_stage.clinical_group_3b,
        clinicalGroup4a: data.anatomic_stage.clinical_group_4a,
        clinicalGroup4b: data.anatomic_stage.clinical_group_4b,
        clinicalStageUnknown: data.anatomic_stage.clinical_stage_unknown,
        pathologicGroup3a: data.anatomic_stage.pathologic_group_3a,
        pathologicGroup3b: data.anatomic_stage.pathologic_group_3b,
        pathologicGroup4a: data.anatomic_stage.pathologic_group_4a,
        pathologicGroup4b: data.anatomic_stage.pathologic_group_4b,
        pathologicStageUnknown: data.anatomic_stage.pathologic_stage_unknown
      },
      prognosticFactors: {
        hg2GradeSystem: data.prognostic_factors.hg_2_grade_system,
        hg3GradeSystem: data.prognostic_factors.hg_3_grade_system,
        hg4GradeSystem: data.prognostic_factors.hg_4_grade_system,
        hgNoGradeSystem: data.prognostic_factors.hg_no_grade_system,
        hgGrade1: data.prognostic_factors.hg_grade_1,
        hgGrade2: data.prognostic_factors.hg_grade_2,
        hgGrade3: data.prognostic_factors.hg_grade_3,
        hgGrade4: data.prognostic_factors.hg_grade_4,
        lviAbsent: data.prognostic_factors.lvi_absent,
        lviPresent: data.prognostic_factors.lvi_present,
        lviNotApplicable: data.prognostic_factors.lvi_not_applicable,
        lviUnknown: data.prognostic_factors.lvi_unknown,
        residualTumorRx: data.prognostic_factors.residual_tumor_rx,
        residualTumorR0: data.prognostic_factors.residual_tumor_r0,
        residualTumorR1: data.prognostic_factors.residual_tumor_r1,
        residualTumorR2: data.prognostic_factors.residual_tumor_r2,
        clinicalStageTreatmentPlanning: data.prognostic_factors.clinical_stage_treatment_planning,
        clinicalStageTreatmentPlanningRemark: data.prognostic_factors.clinical_stage_treatment_planning_remark,
        nationalGuidelinesTreatmentPlanning: data.prognostic_factors.national_guidelines_treatment_planning,
        nccn: data.prognostic_factors.nccn,
        others: data.prognostic_factors.others,
        othersRemark: data.prognostic_factors.others_remark,
        tumorLocation: data.prognostic_factors.tumor_location,
        specimenType: data.prognostic_factors.specimen_type,
        extentLiverResection: data.prognostic_factors.extent_liver_resection,
        peritonealVsHepatic: data.prognostic_factors.peritoneal_vs_hepatic
      },
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : { splines: [], texts: [] },
      stagingSpecificData: data.staging_specific_data,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  breast: (data: _BreastStagingForm): BreastStagingForm => {
    return {
      id: data.id || -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name ? data.phr_clinical_diagnosis_name : '',
      diagramData: data.diagram_data
        ? JSON.parse(data.diagram_data)
        : [
            { splines: [], texts: [] },
            { splines: [], texts: [] }
          ],
      stagingCategoryDefinition: data.stage_category_definition.staging_category_definition,
      stagingCategoryTumorSize: data.stage_category_definition.staging_category_tumor_size,
      stagingCategoryLaterality: data.stage_category_definition.staging_category_laterality,
      primaryTumor: data.primary_tumor.primary_tumor_value,
      clinicalRegionalLymphNodes: data.regional_lymph_nodes.clinical_regional_lymph_nodes_value,
      pathologicalRegionalLymphNodes: data.regional_lymph_nodes.pathological_regional_lymph_nodes_value,
      distantMetastasis: data.distant_metastasis.distant_metastasis_value,
      prognosticHgInvasive: data.prognostic_factors.prognostic_hg_invasive,
      prognosticHgSitu: data.prognostic_factors.prognostic_hg_situ,
      prognosticHer2: data.prognostic_factors.prognostic_her2,
      prognosticEr: data.prognostic_factors.prognostic_er,
      prognosticPr: data.prognostic_factors.prognostic_pr,
      prognosticOncotypeDx: data.prognostic_factors.prognostic_oncotype_dx,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  cervixUteri: (data: _CervixUteriStagingForm): CervixUteriStagingForm => {
    return {
      id: data.id || -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name ? data.phr_clinical_diagnosis_name : '',
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : { splines: [], text: [] },

      stagingCategoryDefinition: data.stage_category_definition.staging_category_definition,
      stagingCategoryTumorSize: data.stage_category_definition.staging_category_tumor_size,
      stagingCategoryLaterality: data.stage_category_definition.staging_category_laterality,

      primaryTumor: data.primary_tumor.primary_tumor_value,

      regionalLymphNodes: data.regional_lymph_nodes.regional_lymph_nodes_value,

      distantMetastasis: data.distant_metastasis.distant_metastasis_value,

      prognosticHistologicalGrade: data.prognostic_factors.prognostic_histological_grade,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  lung: (data: _LungStagingForm): LungStagingForm => {
    return {
      id: data.id || -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name ? data.phr_clinical_diagnosis_name : '',
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : { splines: [], text: [] },

      stagingCategoryDefinition: data.stage_category_definition.staging_category_definition,
      stagingCategoryTumorSize: data.stage_category_definition.staging_category_tumor_size,
      stagingCategoryLaterality: data.stage_category_definition.staging_category_laterality,

      primaryTumor: data.primary_tumor.primary_tumor_value,

      regionalLymphNodes: data.regional_lymph_nodes.regional_lymph_nodes_value,

      distantMetastasis: data.distant_metastasis.distant_metastasis_value,

      prognosticHistologicalGrade: data.prognostic_factors.prognostic_histological_grade,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  prostate: (data: _ProstateStagingForm): ProstateStagingForm => {
    return {
      id: data.id ? data.id : -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name ? data.phr_clinical_diagnosis_name : '',
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : new Array(2).fill({ splines: [], text: [] }),
      stagingCategoryDefinition: data.stage_category_definition.staging_category_definition,
      stagingCategoryTumorSize: data.stage_category_definition.staging_category_tumor_size,
      stagingCategoryLaterality: data.stage_category_definition.staging_category_laterality,
      clinicalPrimaryTumor: data.primary_tumor.clinical_primary_tumor_value,
      pathologicalPrimaryTumor: data.primary_tumor.pathological_primary_tumor_value,
      regionalLymphNodes: data.regional_lymph_nodes.regional_lymph_nodes_value,
      distantMetastasis: data.distant_metastasis.distant_metastasis_value,
      prognosticProstateSpecificAntigen: data.prognostic_factors.prognostic_prostate_specific_antigen,
      prognosticHistologicalGrade: data.prognostic_factors.prognostic_histological_grade,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  cervicalLymphNodes: (data: _CervicalLymphNodesStagingForm): CervicalLymphNodesStagingForm => {
    return {
      id: data.id ? data.id : -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name ? data.phr_clinical_diagnosis_name : '',
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : { splines: [], text: [] },
      stagingCategoryDefinition: data.stage_category_definition.staging_category_definition,
      stagingCategoryTumorSize: data.stage_category_definition.staging_category_tumor_size,
      stagingCategoryLaterality: data.stage_category_definition.staging_category_laterality,
      primaryTumor: data.primary_tumor.primary_tumor_value,
      clinicalRegionalLymphNodes: data.regional_lymph_nodes.clinical_regional_lymph_nodes_value,
      pathologicalRegionalLymphNodes: data.regional_lymph_nodes.pathological_regional_lymph_nodes_value,
      distantMetastasis: data.distant_metastasis.distant_metastasis_value,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  endometrialCancer: (data: _EndometrialStagingForm): EndometrialStagingForm => {
    return {
      id: data.id ? data.id : -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name ? data.phr_clinical_diagnosis_name : '',
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : new Array(2).fill({ splines: [], texts: [] }),
      stagingCategoryDefinition: data.stage_category_definition.staging_category_definition,
      stagingCategoryTumorSize: data.stage_category_definition.staging_category_tumor_size,
      stagingCategoryLaterality: data.stage_category_definition.staging_category_laterality,
      primaryTumor: data.primary_tumor.primary_tumor_value,
      regionalLymphNodes: data.regional_lymph_nodes.regional_lymph_nodes_value,
      distantMetastasis: data.distant_metastasis.distant_metastasis_value,
      prognosticHistologicalGrade: data.prognostic_factors.prognostic_histological_grade,
      prognosticHistopathology: data.prognostic_factors.prognostic_histopathology,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  oropharyngealCancer: (data: _OropharyngealStagingForm): OropharyngealStagingForm => {
    return {
      id: data.id ? data.id : -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name || '',
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : new Array(2).fill({ splines: [], texts: [] }),
      stagingCategoryDefinition: data.stage_category_definition.staging_category_definition,
      stagingCategoryTumorSize: data.stage_category_definition.staging_category_tumor_size,
      stagingCategoryLaterality: data.stage_category_definition.staging_category_laterality,
      primaryTumor: data.primary_tumor.primary_tumor_value,
      clinicalRegionalLymphNodes: data.regional_lymph_nodes.clinical_regional_lymph_nodes_value,
      pathologicalRegionalLymphNodes: data.regional_lymph_nodes.pathological_regional_lymph_nodes_value,
      distantMetastasis: data.distant_metastasis.distant_metastasis_value,
      prognosticP16HpvStatus: data.prognostic_factors.prognostic_p16_hpv_status,
      prognosticHistologicalGrade: data.prognostic_factors.prognostic_histological_grade,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  nasopharyngealCancer: (data: _NasopharyngealStagingForm): NasopharyngealStagingForm => {
    return {
      id: data.id ? data.id : -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name || '',
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : new Array(2).fill({ splines: [], texts: [] }),
      stagingCategoryDefinition: data.stage_category_definition.staging_category_definition,
      stagingCategoryTumorSize: data.stage_category_definition.staging_category_tumor_size,
      stagingCategoryLaterality: data.stage_category_definition.staging_category_laterality,
      primaryTumor: data.primary_tumor.primary_tumor_value,
      regionalLymphNodes: data.regional_lymph_nodes.regional_lymph_nodes_value,
      distantMetastasis: data.distant_metastasis.distant_metastasis_value,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  pancreatic: (data: _PancreaticStagingForm): PancreaticStagingForm => {
    return {
      id: data.id ? data.id : -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name || '',
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : { splines: [], texts: [] },
      stagingCategoryDefinition: data.stage_category_definition.staging_category_definition,
      stagingCategoryTumorSize: data.stage_category_definition.staging_category_tumor_size,
      stagingCategoryLaterality: data.stage_category_definition.staging_category_laterality,
      primaryTumor: data.primary_tumor.primary_tumor_value,
      regionalLymphNodes: data.regional_lymph_nodes.regional_lymph_nodes_value,
      distantMetastasis: data.distant_metastasis.distant_metastasis_value,
      prognosticHistologicalGrade: data.prognostic_factors.prognostic_histological_grade,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      prognosticResidualTumor: data.prognostic_factors.prognostic_residual_tumor,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  oralCavity: (data: _OralCavityStagingForm): OralCavityStagingForm => {
    return {
      id: data.id ? data.id : -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name || '',
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : new Array(4).fill({ splines: [], texts: [] }),
      stagingCategoryDefinition: data.stage_category_definition.staging_category_definition,
      stagingCategoryTumorSize: data.stage_category_definition.staging_category_tumor_size,
      stagingCategoryLaterality: data.stage_category_definition.staging_category_laterality,
      primaryTumor: data.primary_tumor.primary_tumor_value,
      clinicalRegionalLymphNodes: data.regional_lymph_nodes.clinical_regional_lymph_nodes_value,
      pathologicalRegionalLymphNodes: data.regional_lymph_nodes.pathological_regional_lymph_nodes_value,
      distantMetastasis: data.distant_metastasis.distant_metastasis_value,
      prognosticHistologicalGrade: data.prognostic_factors.prognostic_histological_grade,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  brainAndSpinalCord: (data: _BrainAndSpinalCordStagingForm): BrainAndSpinalCordStagingForm => {
    return {
      id: data.id ? data.id : -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name || '',
      prognosticHistologicalGrade: data.prognostic_factors.prognostic_histological_grade,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  },
  colorectalCancer: (data: _ColorectalCancerStagingForm): ColorectalCancerStagingForm => {
    return {
      id: data.id || -1,
      type: data.type,
      phrId: data.patient_health_record_id,
      diagnosisId: data.phr_clinical_diagnosis_id,
      diagnosisName: data.phr_clinical_diagnosis_name ? data.phr_clinical_diagnosis_name : '',
      diagramData: data.diagram_data ? JSON.parse(data.diagram_data) : new Array(3).fill({ splines: [], texts: [] }),
      stagingCategoryDefinition: data.stage_category_definition.staging_category_definition,
      stagingCategoryTumorSize: data.stage_category_definition.staging_category_tumor_size,
      stagingCategoryLaterality: data.stage_category_definition.staging_category_laterality,
      primaryTumor: data.primary_tumor.primary_tumor_value,
      regionalLymphNodes: data.regional_lymph_nodes.regional_lymph_nodes_value,
      distantMetastasis: data.distant_metastasis.distant_metastasis_value,
      prognosticHistologicalGrade: data.prognostic_factors.prognostic_histological_grade,
      prognosticAdditionalDescriptors: data.prognostic_factors.prognostic_additional_descriptors,
      createdAt: data.created_at ? new Date(data.created_at) : new Date(),
      updatedAt: data.updated_at ? new Date(data.updated_at) : new Date()
    };
  }
};

export const transformOutStagingForm = {
  anyStagingForm: (form: CombinedStagingForm) => {
    switch (form.type) {
      case StagingFormType.Liver:
        return transformOutStagingForm.liver(form as LiverStagingForm);
      case StagingFormType.Gallbladder:
        return transformOutStagingForm.gallbladder(form as GallbladderStagingForm);
      case StagingFormType.Breast:
        return transformOutStagingForm.breast(form as BreastStagingForm);
      case StagingFormType.CervixUteri:
        return transformOutStagingForm.cervixUteri(form as CervixUteriStagingForm);
      case StagingFormType.Lung:
        return transformOutStagingForm.lung(form as LungStagingForm);
      case StagingFormType.Prostate:
        return transformOutStagingForm.prostate(form as ProstateStagingForm);
      case StagingFormType.CervicalLymphNodes:
        return transformOutStagingForm.cervicalLymphNodes(form as CervicalLymphNodesStagingForm);
      case StagingFormType.Endometrial:
        return transformOutStagingForm.endometrialCancer(form as EndometrialStagingForm);
      case StagingFormType.Oropharyngeal:
        return transformOutStagingForm.oropharyngealCancer(form as OropharyngealStagingForm);
      case StagingFormType.Nasopharyngeal:
        return transformOutStagingForm.nasopharyngealCancer(form as NasopharyngealStagingForm);
      case StagingFormType.Pancreatic:
        return transformOutStagingForm.pancreatic(form as PancreaticStagingForm);
      case StagingFormType.OralCavity:
        return transformOutStagingForm.oralCavity(form as OralCavityStagingForm);
      case StagingFormType.BrainAndSpinalCord:
        return transformOutStagingForm.brainAndSpinalCord(form as BrainAndSpinalCordStagingForm);
      case StagingFormType.Colorectal:
        return transformOutStagingForm.colorectalCancer(form as ColorectalCancerStagingForm);
      default:
        return form;
    }
  },
  liver: (form: LiverStagingForm): _LiverStagingFormInput => {
    return {
      id: form.id,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,

      tumor_size: form.stageCategoryDefinition.tumorSize,
      y_clinical: form.stageCategoryDefinition.yClinical,
      y_pathologic: form.stageCategoryDefinition.yPathologic,
      laterality_left: form.stageCategoryDefinition.lateralityLeft,
      laterality_right: form.stageCategoryDefinition.lateralityRight,
      laterality_bilateral: form.stageCategoryDefinition.lateralityBilateral,

      clinical_t0: form.primaryTumor.clinicalT0,
      clinical_t1: form.primaryTumor.clinicalT1,
      clinical_t2: form.primaryTumor.clinicalT2,
      clinical_t3: form.primaryTumor.clinicalT3,
      clinical_t4: form.primaryTumor.clinicalT4,
      clinical_tx: form.primaryTumor.clinicalTx,
      clinical_t1a: form.primaryTumor.clinicalT1a,
      clinical_t1b: form.primaryTumor.clinicalT1b,
      pathologic_t0: form.primaryTumor.pathologicT0,
      pathologic_t1: form.primaryTumor.pathologicT1,
      pathologic_t2: form.primaryTumor.pathologicT2,
      pathologic_t3: form.primaryTumor.pathologicT3,
      pathologic_t4: form.primaryTumor.pathologicT4,
      pathologic_tx: form.primaryTumor.pathologicTx,
      pathologic_t1a: form.primaryTumor.pathologicT1a,
      pathologic_t1b: form.primaryTumor.pathologicT1b,

      clinical_n0: form.regionalLymphNodes.clinicalN0,
      clinical_n1: form.regionalLymphNodes.clinicalN1,
      clinical_nx: form.regionalLymphNodes.clinicalNx,
      pathologic_n0: form.regionalLymphNodes.pathologicN0,
      pathologic_n1: form.regionalLymphNodes.pathologicN1,
      pathologic_nx: form.regionalLymphNodes.pathologicNx,

      clinical_m0: form.distantMetastasis.clinicalM0,
      clinical_m1: form.distantMetastasis.clinicalM1,
      pathologic_m1: form.distantMetastasis.pathologicM1,

      clinical_group_2: form.anatomicStage.clinicalGroup2,
      clinical_group_1a: form.anatomicStage.clinicalGroup1a,
      clinical_group_1b: form.anatomicStage.clinicalGroup1b,
      clinical_group_3a: form.anatomicStage.clinicalGroup3a,
      clinical_group_3b: form.anatomicStage.clinicalGroup3b,
      clinical_group_4a: form.anatomicStage.clinicalGroup4a,
      clinical_group_4b: form.anatomicStage.clinicalGroup4b,
      pathologic_group_2: form.anatomicStage.pathologicGroup2,
      pathologic_group_1a: form.anatomicStage.pathologicGroup1a,
      pathologic_group_1b: form.anatomicStage.pathologicGroup1b,
      pathologic_group_3a: form.anatomicStage.pathologicGroup3a,
      pathologic_group_3b: form.anatomicStage.pathologicGroup3b,
      pathologic_group_4a: form.anatomicStage.pathologicGroup4a,
      pathologic_group_4b: form.anatomicStage.pathologicGroup4b,
      clinical_stage_unknown: form.anatomicStage.clinicalStageUnknown,
      pathologic_stage_unknown: form.anatomicStage.pathologicStageUnknown,

      nccn: form.prognosticFactors.nccn,
      others: form.prognosticFactors.others,
      bilirubin: form.prognosticFactors.bilirubin,
      creatinine: form.prognosticFactors.creatinine,
      hg_grade_1: form.prognosticFactors.hgGrade1,
      hg_grade_2: form.prognosticFactors.hgGrade2,
      hg_grade_3: form.prognosticFactors.hgGrade3,
      hg_grade_4: form.prognosticFactors.hgGrade4,
      lvi_absent: form.prognosticFactors.lviAbsent,
      lvi_present: form.prognosticFactors.lviPresent,
      lvi_unknown: form.prognosticFactors.lviUnknown,
      others_remark: form.prognosticFactors.othersRemark,
      fibrosis_score: form.prognosticFactors.fibrosisScore,
      alpha_fetoprotein: form.prognosticFactors.alphaFetoprotein,
      hg_2_grade_system: form.prognosticFactors.hg2GradeSystem,
      hg_3_grade_system: form.prognosticFactors.hg3GradeSystem,
      hg_4_grade_system: form.prognosticFactors.hg4GradeSystem,
      residual_tumor_r0: form.prognosticFactors.residualTumorR0,
      residual_tumor_r1: form.prognosticFactors.residualTumorR1,
      residual_tumor_r2: form.prognosticFactors.residualTumorR2,
      residual_tumor_rx: form.prognosticFactors.residualTumorRx,
      hepatitis_serology: form.prognosticFactors.hepatitisSerology,
      hg_no_grade_system: form.prognosticFactors.hgNoGradeSystem,
      lvi_not_applicable: form.prognosticFactors.lviNotApplicable,
      prothrombin_normalized_ratio: form.prognosticFactors.prothrombinNormalizedRatio,
      clinical_stage_treatment_planning: form.prognosticFactors.clinicalStageTreatmentPlanning,
      national_guidelines_treatment_planning: form.prognosticFactors.nationalGuidelinesTreatmentPlanning,
      clinical_stage_treatment_planning_remark: form.prognosticFactors.clinicalStageTreatmentPlanningRemark,

      diagram_data: JSON.stringify(form.diagramData),
      staging_specific_data: form.stagingSpecificData
    };
  },
  gallbladder: (form: GallbladderStagingForm): _GallbladderStagingFormInput => {
    return {
      id: form.id,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,
      /* stage_category_definition */
      tumor_size: form.stageCategoryDefinition.tumorSize,
      laterality_left: form.stageCategoryDefinition.lateralityLeft,
      laterality_right: form.stageCategoryDefinition.lateralityRight,
      laterality_bilateral: form.stageCategoryDefinition.lateralityBilateral,
      y_clinical: form.stageCategoryDefinition.yClinical,
      y_pathologic: form.stageCategoryDefinition.yPathologic,
      /* primary_tumor */
      clinical_tx: form.primaryTumor.clinicalTx,
      clinical_t0: form.primaryTumor.clinicalT0,
      clinical_t1: form.primaryTumor.clinicalT1,
      clinical_t2: form.primaryTumor.clinicalT2,
      pathologic_tx: form.primaryTumor.pathologicTx,
      pathologic_t0: form.primaryTumor.pathologicT0,
      pathologic_t1: form.primaryTumor.pathologicT1,
      pathologic_t2: form.primaryTumor.pathologicT2,
      clinical_tis: form.primaryTumor.clinicalTis,
      clinical_t1a: form.primaryTumor.clinicalT1a,
      clinical_t1b: form.primaryTumor.clinicalT1b,
      clinical_t2a: form.primaryTumor.clinicalT2a,
      clinical_t2b: form.primaryTumor.clinicalT2b,
      clinical_t3: form.primaryTumor.clinicalT3,
      clinical_t4: form.primaryTumor.clinicalT4,
      pathologic_tis: form.primaryTumor.pathologicTis,
      pathologic_t1a: form.primaryTumor.pathologicT1a,
      pathologic_t1b: form.primaryTumor.pathologicT1b,
      pathologic_t2a: form.primaryTumor.pathologicT2a,
      pathologic_t2b: form.primaryTumor.pathologicT2b,
      pathologic_t3: form.primaryTumor.pathologicT3,
      pathologic_t4: form.primaryTumor.pathologicT4,
      /* regional_lymph_nodes */
      clinical_nx: form.regionalLymphNodes.clinicalNx,
      clinical_n0: form.regionalLymphNodes.clinicalN0,
      clinical_n1: form.regionalLymphNodes.clinicalN1,
      pathologic_nx: form.regionalLymphNodes.pathologicNx,
      pathologic_n0: form.regionalLymphNodes.pathologicN0,
      pathologic_n1: form.regionalLymphNodes.pathologicN1,
      clinical_n2: form.regionalLymphNodes.clinicalN2,
      pathologic_n2: form.regionalLymphNodes.pathologicN2,
      /* distant_metastasis */
      clinical_m0: form.distantMetastasis.clinicalM0,
      clinical_m1: form.distantMetastasis.clinicalM1,
      pathologic_m1: form.distantMetastasis.pathologicM1,
      /* anatomic_stage */
      clinical_group_0: form.anatomicStage.clinicalGroup0,
      clinical_group_1: form.anatomicStage.clinicalGroup1,
      clinical_group_2a: form.anatomicStage.clinicalGroup2a,
      clinical_group_2b: form.anatomicStage.clinicalGroup2b,
      clinical_group_4b_2: form.anatomicStage.clinicalGroup4b2,
      pathologic_group_0: form.anatomicStage.pathologicGroup0,
      pathologic_group_1: form.anatomicStage.clinicalGroup1,
      pathologic_group_2a: form.anatomicStage.pathologicGroup2a,
      pathologic_group_2b: form.anatomicStage.pathologicGroup2b,
      pathologic_group_4b_2: form.anatomicStage.pathologicGroup4b2,
      clinical_group_3a: form.anatomicStage.clinicalGroup3a,
      clinical_group_3b: form.anatomicStage.clinicalGroup3b,
      clinical_group_4a: form.anatomicStage.clinicalGroup4a,
      clinical_group_4b: form.anatomicStage.clinicalGroup4b,
      clinical_stage_unknown: form.anatomicStage.clinicalStageUnknown,
      pathologic_group_3a: form.anatomicStage.pathologicGroup3a,
      pathologic_group_3b: form.anatomicStage.pathologicGroup3b,
      pathologic_group_4a: form.anatomicStage.pathologicGroup4a,
      pathologic_group_4b: form.anatomicStage.pathologicGroup4b,
      pathologic_stage_unknown: form.anatomicStage.pathologicStageUnknown,
      /* prognostic_factors */
      hg_2_grade_system: form.prognosticFactors.hg2GradeSystem,
      hg_3_grade_system: form.prognosticFactors.hg3GradeSystem,
      hg_4_grade_system: form.prognosticFactors.hg4GradeSystem,
      hg_no_grade_system: form.prognosticFactors.hgNoGradeSystem,
      hg_grade_1: form.prognosticFactors.hgGrade1,
      hg_grade_2: form.prognosticFactors.hgGrade2,
      hg_grade_3: form.prognosticFactors.hgGrade3,
      hg_grade_4: form.prognosticFactors.hgGrade4,
      lvi_absent: form.prognosticFactors.lviAbsent,
      lvi_present: form.prognosticFactors.lviPresent,
      lvi_not_applicable: form.prognosticFactors.lviNotApplicable,
      lvi_unknown: form.prognosticFactors.lviUnknown,
      residual_tumor_rx: form.prognosticFactors.residualTumorRx,
      residual_tumor_r0: form.prognosticFactors.residualTumorR0,
      residual_tumor_r1: form.prognosticFactors.residualTumorR1,
      residual_tumor_r2: form.prognosticFactors.residualTumorR2,
      clinical_stage_treatment_planning: form.prognosticFactors.clinicalStageTreatmentPlanning,
      clinical_stage_treatment_planning_remark: form.prognosticFactors.clinicalStageTreatmentPlanningRemark,
      national_guidelines_treatment_planning: form.prognosticFactors.nationalGuidelinesTreatmentPlanning,
      nccn: form.prognosticFactors.nccn,
      others: form.prognosticFactors.others,
      others_remark: form.prognosticFactors.othersRemark,
      tumor_location: form.prognosticFactors.tumorLocation,
      specimen_type: form.prognosticFactors.specimenType,
      extent_liver_resection: form.prognosticFactors.extentLiverResection,
      peritoneal_vs_hepatic: form.prognosticFactors.peritonealVsHepatic,

      diagram_data: JSON.stringify(form.diagramData),
      staging_specific_data: form.stagingSpecificData
    };
  },
  breast: (form: BreastStagingForm): _BreastStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,
      stage_category_definition: {
        staging_category_definition: form.stagingCategoryDefinition,
        staging_category_tumor_size: form.stagingCategoryTumorSize,
        staging_category_laterality: form.stagingCategoryLaterality
      },
      primary_tumor: {
        primary_tumor_value: form.primaryTumor
      },
      regional_lymph_nodes: {
        clinical_regional_lymph_nodes_value: form.clinicalRegionalLymphNodes,
        pathological_regional_lymph_nodes_value: form.pathologicalRegionalLymphNodes
      },
      distant_metastasis: {
        distant_metastasis_value: form.distantMetastasis
      },
      prognostic_factors: {
        prognostic_hg_invasive: form.prognosticHgInvasive,
        prognostic_hg_situ: form.prognosticHgSitu,
        prognostic_her2: form.prognosticHer2,
        prognostic_er: form.prognosticEr,
        prognostic_pr: form.prognosticPr,
        prognostic_oncotype_dx: form.prognosticOncotypeDx,
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors
      },
      diagram_data: form.diagramData ? JSON.stringify(form.diagramData) : ''
    };
  },
  cervixUteri: (form: CervixUteriStagingForm): _CervixUteriStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,

      stage_category_definition: {
        staging_category_definition: form.stagingCategoryDefinition,
        staging_category_tumor_size: form.stagingCategoryTumorSize,
        staging_category_laterality: form.stagingCategoryLaterality
      },
      primary_tumor: {
        primary_tumor_value: form.primaryTumor
      },
      regional_lymph_nodes: {
        regional_lymph_nodes_value: form.regionalLymphNodes
      },
      distant_metastasis: {
        distant_metastasis_value: form.distantMetastasis
      },
      prognostic_factors: {
        prognostic_histological_grade: form.prognosticHistologicalGrade,
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors
      },

      diagram_data: form.diagramData ? JSON.stringify(form.diagramData) : ''
    };
  },
  lung: (form: LungStagingForm): _LungStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,

      stage_category_definition: {
        staging_category_definition: form.stagingCategoryDefinition,
        staging_category_tumor_size: form.stagingCategoryTumorSize,
        staging_category_laterality: form.stagingCategoryLaterality
      },
      primary_tumor: {
        primary_tumor_value: form.primaryTumor
      },
      regional_lymph_nodes: {
        regional_lymph_nodes_value: form.regionalLymphNodes
      },
      distant_metastasis: {
        distant_metastasis_value: form.distantMetastasis
      },
      prognostic_factors: {
        prognostic_histological_grade: form.prognosticHistologicalGrade,
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors
      },

      diagram_data: form.diagramData ? JSON.stringify(form.diagramData) : ''
    };
  },
  prostate: (form: ProstateStagingForm): _ProstateStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,
      stage_category_definition: {
        staging_category_definition: form.stagingCategoryDefinition,
        staging_category_tumor_size: form.stagingCategoryTumorSize,
        staging_category_laterality: form.stagingCategoryLaterality
      },
      primary_tumor: {
        clinical_primary_tumor_value: form.clinicalPrimaryTumor,
        pathological_primary_tumor_value: form.pathologicalPrimaryTumor
      },
      regional_lymph_nodes: {
        regional_lymph_nodes_value: form.regionalLymphNodes
      },
      distant_metastasis: {
        distant_metastasis_value: form.distantMetastasis
      },
      prognostic_factors: {
        prognostic_histological_grade: form.prognosticHistologicalGrade,
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors,
        prognostic_prostate_specific_antigen: form.prognosticProstateSpecificAntigen
      },
      diagram_data: form.diagramData ? JSON.stringify(form.diagramData) : ''
    };
  },
  cervicalLymphNodes: (form: CervicalLymphNodesStagingForm): _CervicalLymphNodesStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,
      stage_category_definition: {
        staging_category_definition: form.stagingCategoryDefinition,
        staging_category_laterality: form.stagingCategoryLaterality,
        staging_category_tumor_size: form.stagingCategoryTumorSize
      },
      primary_tumor: {
        primary_tumor_value: form.primaryTumor
      },
      regional_lymph_nodes: {
        clinical_regional_lymph_nodes_value: form.clinicalRegionalLymphNodes,
        pathological_regional_lymph_nodes_value: form.pathologicalRegionalLymphNodes
      },
      distant_metastasis: {
        distant_metastasis_value: form.distantMetastasis
      },
      prognostic_factors: {
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors
      },
      diagram_data: form.diagramData ? JSON.stringify(form.diagramData) : ''
    };
  },
  endometrialCancer: (form: EndometrialStagingForm): _EndometrialStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,
      stage_category_definition: {
        staging_category_definition: form.stagingCategoryDefinition,
        staging_category_laterality: form.stagingCategoryLaterality,
        staging_category_tumor_size: form.stagingCategoryTumorSize
      },
      primary_tumor: {
        primary_tumor_value: form.primaryTumor
      },
      regional_lymph_nodes: {
        regional_lymph_nodes_value: form.regionalLymphNodes
      },
      distant_metastasis: {
        distant_metastasis_value: form.distantMetastasis
      },
      prognostic_factors: {
        prognostic_histological_grade: form.prognosticHistologicalGrade,
        prognostic_histopathology: form.prognosticHistopathology,
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors
      },
      diagram_data: form.diagramData ? JSON.stringify(form.diagramData) : ''
    };
  },
  oropharyngealCancer: (form: OropharyngealStagingForm): _OropharyngealStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,
      stage_category_definition: {
        staging_category_definition: form.stagingCategoryDefinition,
        staging_category_tumor_size: form.stagingCategoryTumorSize,
        staging_category_laterality: form.stagingCategoryLaterality
      },
      primary_tumor: {
        primary_tumor_value: form.primaryTumor
      },
      regional_lymph_nodes: {
        clinical_regional_lymph_nodes_value: form.clinicalRegionalLymphNodes,
        pathological_regional_lymph_nodes_value: form.pathologicalRegionalLymphNodes
      },
      distant_metastasis: {
        distant_metastasis_value: form.distantMetastasis
      },
      prognostic_factors: {
        prognostic_p16_hpv_status: form.prognosticP16HpvStatus,
        prognostic_histological_grade: form.prognosticHistologicalGrade,
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors
      },
      diagram_data: form.diagramData ? JSON.stringify(form.diagramData) : ''
    };
  },
  nasopharyngealCancer: (form: NasopharyngealStagingForm): _NasopharyngealStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,
      stage_category_definition: {
        staging_category_definition: form.stagingCategoryDefinition,
        staging_category_tumor_size: form.stagingCategoryTumorSize,
        staging_category_laterality: form.stagingCategoryLaterality
      },
      primary_tumor: {
        primary_tumor_value: form.primaryTumor
      },
      regional_lymph_nodes: {
        regional_lymph_nodes_value: form.regionalLymphNodes
      },
      distant_metastasis: {
        distant_metastasis_value: form.distantMetastasis
      },
      prognostic_factors: {
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors
      },
      diagram_data: form.diagramData ? JSON.stringify(form.diagramData) : ''
    };
  },
  pancreatic: (form: PancreaticStagingForm): _PancreaticStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,
      stage_category_definition: {
        staging_category_definition: form.stagingCategoryDefinition,
        staging_category_tumor_size: form.stagingCategoryTumorSize,
        staging_category_laterality: form.stagingCategoryLaterality
      },
      primary_tumor: {
        primary_tumor_value: form.primaryTumor
      },
      regional_lymph_nodes: {
        regional_lymph_nodes_value: form.regionalLymphNodes
      },
      distant_metastasis: {
        distant_metastasis_value: form.distantMetastasis
      },
      prognostic_factors: {
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors,
        prognostic_histological_grade: form.prognosticHistologicalGrade,
        prognostic_residual_tumor: form.prognosticResidualTumor
      },
      diagram_data: form.diagramData ? JSON.stringify(form.diagramData) : ''
    };
  },
  oralCavity: (form: OralCavityStagingForm): _OralCavityStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,
      stage_category_definition: {
        staging_category_definition: form.stagingCategoryDefinition,
        staging_category_tumor_size: form.stagingCategoryTumorSize,
        staging_category_laterality: form.stagingCategoryLaterality
      },
      primary_tumor: {
        primary_tumor_value: form.primaryTumor
      },
      regional_lymph_nodes: {
        clinical_regional_lymph_nodes_value: form.clinicalRegionalLymphNodes,
        pathological_regional_lymph_nodes_value: form.pathologicalRegionalLymphNodes
      },
      distant_metastasis: {
        distant_metastasis_value: form.distantMetastasis
      },
      prognostic_factors: {
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors,
        prognostic_histological_grade: form.prognosticHistologicalGrade
      },
      diagram_data: form.diagramData ? JSON.stringify(form.diagramData) : ''
    };
  },
  brainAndSpinalCord: (form: BrainAndSpinalCordStagingForm): _BrainAndSpinalCordStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,
      prognostic_factors: {
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors,
        prognostic_histological_grade: form.prognosticHistologicalGrade
      }
    };
  },
  colorectalCancer: (form: ColorectalCancerStagingForm): _ColorectalCancerStagingForm => {
    return {
      id: form.id !== -1 ? form.id : undefined,
      type: form.type,
      patient_health_record_id: form.phrId,
      phr_clinical_diagnosis_id: form.diagnosisId,
      stage_category_definition: {
        staging_category_definition: form.stagingCategoryDefinition,
        staging_category_tumor_size: form.stagingCategoryTumorSize,
        staging_category_laterality: form.stagingCategoryLaterality
      },
      primary_tumor: {
        primary_tumor_value: form.primaryTumor
      },
      regional_lymph_nodes: {
        regional_lymph_nodes_value: form.regionalLymphNodes
      },
      distant_metastasis: {
        distant_metastasis_value: form.distantMetastasis
      },
      prognostic_factors: {
        prognostic_histological_grade: form.prognosticHistologicalGrade,
        prognostic_additional_descriptors: form.prognosticAdditionalDescriptors
      },
      diagram_data: form.diagramData ? JSON.stringify(form.diagramData) : ''
    };
  }
};
