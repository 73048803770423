export const SWITCH = 'switch';
export const DROPDOWN = 'dropdown';

const mapping = {
  ABG: {
    ph: {
      english: 'Acidity(pH)',
      hasReference: true,
      hasUnit: true,
      name: 'ph'
    },
    pco2: {
      english: 'Partial Pressure of Carbon Dioxide(pCO2)',
      hasReference: true,
      hasUnit: true,
      name: 'pco2'
    },
    po2: {
      english: 'Partial Pressure of Oxygen(pO2)',
      hasReference: true,
      hasUnit: true,
      name: 'po2'
    },
    hco3: {
      english: 'Bicarbonate(HCO3)',
      hasReference: true,
      hasUnit: true,
      name: 'hco3'
    },
    o2sat: {
      english: 'Oxygen Saturation(O2sat)',
      hasReference: true,
      hasUnit: true,
      name: 'o2sat'
    },
    pf_ratio: {
      english: 'Partial Fractional Ratio(PF Ratio)',
      hasReference: true,
      hasUnit: true,
      name: 'pf_ratio'
    }
  },
  'Bleeding Parameter': {
    pt: {
      english: 'Prothrombin Time(PT)',
      hasReference: true,
      hasUnit: true,
      name: 'pt'
    },
    ptpx: {
      english: 'Prothrombin Time px(PTpx)',
      hasReference: true,
      hasUnit: true,
      name: 'ptpx'
    },
    appt: {
      english: 'Activated Partial Thromboplastin Time(APTT)',
      hasReference: true,
      hasUnit: true,
      name: 'appt'
    },
    apptpx: {
      english: 'Activated Partial Thromboplastin Time px(APTTpx)',
      hasReference: true,
      hasUnit: true,
      name: 'apptpx'
    },
    ct: {
      english: 'Clotting Time(CT)',
      hasReference: true,
      hasUnit: true,
      name: 'ct'
    },
    bt: {
      english: 'Bleeding Time(BT)',
      hasReference: true,
      hasUnit: true,
      name: 'bt'
    },
    fibrinogen: {
      english: 'Fibrinogen(Fibrino)',
      hasReference: true,
      hasUnit: true,
      name: 'fibrinogen'
    },
    retic: {
      english: 'Reticulocyte Count(Retic)',
      hasReference: true,
      hasUnit: true,
      name: 'retic'
    },
    inr: {
      english: 'International Normalized Ratio(inr)',
      hasReference: true,
      hasUnit: true,
      name: 'inr'
    },
    pt_control: {
      english: 'PT Control',
      hasReference: true,
      hasUnit: true,
      name: 'pt_control'
    },
    pt_activity: {
      english: 'PT Activity',
      hasReference: true,
      hasUnit: true,
      name: 'pt_activity'
    }
  },
  Chemistry: {
    hba1c: {
      english: 'Glycated Haemoglobin (HbA1c)',
      hasReference: true,
      hasUnit: true,
      name: 'hba1c'
    },
    fbs: {
      english: 'Fasting Blood Sugar (FBS)',
      hasReference: true,
      hasUnit: true,
      name: 'fbs'
    },
    bun: {
      english: 'Blood Urea Nitrogen (BUN)',
      hasReference: true,
      hasUnit: true,
      name: 'bun'
    },
    crea: {
      english: 'Creatinine (Crea)',
      hasReference: true,
      hasUnit: true,
      name: 'crea'
    },
    crea_clearance: {
      english: 'Creatinine Clearance',
      hasReference: true,
      hasUnit: true,
      name: 'crea_clearance'
    },
    mp: {
      english: 'Metabolic Profile (MP)',
      hasReference: true,
      hasUnit: true,
      name: 'mp'
    },
    ua: {
      english: 'Uric Acid (UA)',
      hasReference: true,
      hasUnit: true,
      name: 'ua'
    },
    tchol: {
      english: 'Total Cholesterol (TChol)',
      hasReference: true,
      hasUnit: true,
      name: 'tchol'
    },
    trig: {
      english: 'Triglyceride (Trig)',
      hasReference: true,
      hasUnit: true,
      name: 'trig'
    },
    hdl: {
      english: 'High Density Level (HDL)',
      hasReference: true,
      hasUnit: true,
      name: 'hdl'
    },
    ldl: {
      english: 'Low Density Level (LDL)',
      hasReference: true,
      hasUnit: true,
      name: 'ldl'
    },
    vldl: {
      english: 'Very Low Density Level (VLDL)',
      hasReference: true,
      hasUnit: true,
      name: 'vldl'
    },
    ratio: {
      english: 'Chol/HDL Ratio',
      hasReference: true,
      hasUnit: true,
      name: 'ratio'
    },
    sgot: {
      english: 'Serum Glutamic Oxaloacetic Transaminase (SGOT)',
      hasReference: true,
      hasUnit: true,
      name: 'sgot'
    },
    sgpt: {
      english: 'Serum Glutamic-pyruvic Transaminase (SGPT)',
      hasReference: true,
      hasUnit: true,
      name: 'sgpt'
    },
    k: {
      english: 'Potassium (K)',
      hasReference: true,
      hasUnit: true,
      name: 'k'
    },
    cl: {
      english: 'Chloride (Cl)',
      hasReference: true,
      hasUnit: true,
      name: 'cl'
    },
    na: {
      english: 'Sodium (Na)',
      hasReference: true,
      hasUnit: true,
      name: 'na'
    },
    ca: {
      english: 'Calcium (Ca)',
      hasReference: true,
      hasUnit: true,
      name: 'ca'
    },
    twenty_four_hour_urine_protein: {
      english: '24 Hour Urine Protein',
      hasReference: true,
      hasUnit: true,
      name: 'twenty_four_hour_urine_protein'
    },
    two_hour_pgc: {
      english: '2 Hour PGC (OGTT)',
      hasReference: true,
      hasUnit: true,
      name: 'two_hour_pgc'
    },
    one_hour_pgc: {
      english: '1 Hour PGC (OGTT)',
      hasReference: true,
      hasUnit: true,
      name: 'one_hour_pgc'
    },
    egfr: {
      english: 'eGFR',
      hasReference: true,
      hasUnit: true,
      name: 'egfr'
    },
    aldl: {
      english: 'ALDL',
      hasReference: true,
      hasUnit: true,
      name: 'aldl'
    },
    ag_ratio: {
      english: 'A/G Ratio',
      hasReference: true,
      hasUnit: true,
      name: 'ag_ratio'
    },
    alkaline_phosphatase: {
      english: 'Alkaline phosphatase',
      hasReference: true,
      hasUnit: true,
      name: 'alkaline_phosphatase'
    },
    total_bilirubin: {
      english: 'Total bilirubin',
      hasReference: true,
      hasUnit: true,
      name: 'total_bilirubin'
    },
    free_psa: {
      english: 'free PSA',
      hasReference: true,
      hasUnit: true,
      name: 'free_psa'
    },
    total_psa: {
      english: 'total PSA',
      hasReference: true,
      hasUnit: true,
      name: 'total_psa'
    },
    tibc: {
      english: 'Total Iron Binding Capacity',
      hasReference: true,
      hasUnit: true,
      name: 'tibc'
    },
    total_protein: {
      english: 'Total Protein',
      hasReference: true,
      hasUnit: true,
      name: 'total_protein'
    },
    vitamin_d: {
      english: 'Vitamin D',
      hasReference: true,
      hasUnit: true,
      name: 'vitamin_d'
    },
    albumin: {
      english: 'Albumin',
      hasReference: true,
      hasUnit: true,
      name: 'albumin'
    },
    blood_glucose: {
      english: 'Blood Glucose',
      hasReference: true,
      hasUnit: true,
      name: 'blood_glucose'
    },
    hscrp: {
      english: 'High Sensitive C-reactive Protein',
      hasReference: true,
      hasUnit: true,
      name: 'hscrp'
    },
    magnesium: {
      english: 'Magnesium',
      hasReference: true,
      hasUnit: true,
      name: 'magnesium'
    },
    micral_test: {
      english: 'Micral Test',
      hasReference: true,
      hasUnit: true,
      name: 'micral_test'
    },
    phosphorous: {
      english: 'Phosphorus',
      hasReference: true,
      hasUnit: true,
      name: 'phosphorous'
    },
    glycomark: {
      english: 'Glycomark',
      hasUnit: true,
      hasReference: true,
      name: 'glycomark'
    },
    ionized_calcium: {
      english: 'Ionized Calcium',
      hasUnit: true,
      hasReference: true,
      name: 'ionized_calcium'
    },
    pre_bun: {
      english: 'Pre Dialysis Blood Urea Nitrogen',
      hasUnit: true,
      hasReference: true,
      name: 'pre_bun'
    },
    post_bun: {
      english: 'Post Dialysis Blood Urea Nitrogen',
      hasUnit: true,
      hasReference: true,
      name: 'post_bun'
    },
    rbs: {
      english: 'Random Blood Sugar (RBS)',
      hasUnit: true,
      hasReference: true,
      name: 'rbs'
    },
    ogtt__3_sample: {
      english: 'Oral Glucose Tolerance Test (OGTT) - 3 Sample',
      hasUnit: true,
      hasReference: true,
      name: 'ogtt__3_sample'
    },
    ogtt__4_sample: {
      english: 'Oral Glucose Tolerance Test (OGTT) - 4 Sample',
      hasUnit: true,
      hasReference: true,
      name: 'ogtt__4_sample'
    },
    ogtt__5_sample: {
      english: 'Oral Glucose Tolerance Test (OGTT) - 5 Sample',
      hasUnit: true,
      hasReference: true,
      name: 'ogtt__5_sample'
    },
    ppbs__after_meal: {
      english: 'Post Prandial Blood Sugar (PPBS) - After Meal',
      hasUnit: true,
      hasReference: true,
      name: 'ppbs__after_meal'
    },
    ppbs__after_sgl: {
      english: 'Post Prandial Blood Sugar (PPBS) - After Standard Glucose Load',
      hasUnit: true,
      hasReference: true,
      name: 'ppbs__after_sgl'
    },
    ogct: {
      english: 'Oral Glucose Challenge Test (OGCT)',
      hasUnit: true,
      hasReference: true,
      name: 'ogct'
    },
    cystatin_c: {
      english: 'Cystatin C',
      hasUnit: true,
      hasReference: true,
      name: 'cystatin_c'
    },
    urea_clearance: {
      english: 'Urea Clearance (Pre & Post BUN)',
      hasUnit: true,
      hasReference: true,
      name: 'urea_clearance'
    },
    amylase: {
      english: 'Amylase',
      hasUnit: true,
      hasReference: true,
      name: 'amylase'
    },
    lipase: {
      english: 'Lipase',
      hasUnit: true,
      hasReference: true,
      name: 'lipase'
    },
    cpk: {
      english: 'CPK',
      hasUnit: true,
      hasReference: true,
      name: 'cpk'
    },
    ckmb: {
      english: 'CKMB',
      hasUnit: true,
      hasReference: true,
      name: 'ckmb'
    },
    ckmm: {
      english: 'CKMM',
      hasUnit: true,
      hasReference: true,
      name: 'ckmm'
    },
    iron: {
      english: 'Iron',
      hasUnit: true,
      hasReference: true,
      name: 'iron'
    },
    total_ige: {
      english: 'Total IgE',
      hasUnit: true,
      hasReference: true,
      name: 'total_ige'
    },
    ggtp: {
      english: 'GGTP',
      hasUnit: true,
      hasReference: true,
      name: 'ggtp'
    }
  },
  'CKD Panel': {
    egfr: {
      english: 'eGFR (CKD EPI)',
      hasReference: true,
      hasUnit: true,
      name: 'egfr'
      // unit: 'ml/min',
      // isUnitReadOnly: true
    },
    urine_albumin_to_creatinine_ratio: {
      english: 'Urine albumin-to-creatinine ratio',
      hasReference: true,
      hasUnit: true,
      name: 'urine_albumin_to_creatinine_ratio'
    },
    micralbumin: {
      english: 'Microalbumin',
      hasReference: true,
      hasUnit: true,
      name: 'micralbumin'
    },
    albumin: {
      english: 'Albumin in Blood',
      hasReference: true,
      hasUnit: true,
      name: 'albumin'
    },
    gfr: {
      english: 'GFR',
      hasReference: true,
      hasUnit: true,
      name: 'gfr'
    },
    vldl: {
      english: 'VLDL',
      hasReference: true,
      hasUnit: true,
      name: 'vldl'
    },
    chol_hdl_ratio: {
      english: 'Chol/HDL Ratio',
      hasReference: true,
      hasUnit: true,
      name: 'chol_hdl_ratio'
    },
    aldl: {
      english: 'Aldl',
      hasReference: true,
      hasUnit: true,
      name: 'aldl'
    },
    egfr_score: {
      english: 'eGFR Score',
      name: 'egfr_score',
      isReadOnly: true,
      hasUnit: false,
      hasReference: false
      // unit: 'ml/min'
    },
    age: {
      english: 'Age',
      hasUnit: false,
      hasReference: false,
      name: 'age'
    },
    gender: {
      english: 'Gender',
      hasUnit: false,
      hasReference: false,
      name: 'gender',
      type: SWITCH,
      values: ['Male', 'Female']
    },
    creatinine: {
      english: 'Creatinine',
      hasReference: true,
      hasUnit: true,
      // isUnitReadOnly: true,
      // unit: 'mg/dl',
      name: 'creatinine'
    }
  },
  'Lipid Profile': {
    tchol: {
      english: 'Total Cholesterol (TChol)',
      hasReference: true,
      hasUnit: true,
      name: 'tchol'
    },
    trig: {
      english: 'Triglyceride (Trig)',
      hasReference: true,
      hasUnit: true,
      name: 'trig'
    },
    hdl: {
      english: 'High Density Level (HDL)',
      hasReference: true,
      hasUnit: true,
      name: 'hdl'
    },
    ldl: {
      english: 'Low Density Level (LDL)',
      hasReference: true,
      hasUnit: true,
      name: 'ldl'
    },
    vldl: {
      english: 'Very Low Density Level (VLDL)',
      hasReference: true,
      hasUnit: true,
      name: 'vldl'
    },
    ratio: {
      english: 'Chol/HDL Ratio',
      hasReference: true,
      hasUnit: true,
      name: 'ratio'
    }
  },
  Culture: {
    specimen: {
      english: 'Specimen',
      hasReference: false,
      hasUnit: false,
      name: 'specimen'
    },
    specimen_number: {
      english: 'Specimen Number',
      hasReference: false,
      hasUnit: false,
      name: 'specimen_number'
    },
    gram_stain: {
      english: 'Gram Stain',
      hasReference: false,
      hasUnit: false,
      name: 'gram_stain'
    },
    culture: {
      english: 'Culture',
      hasReference: false,
      hasUnit: false,
      name: 'culture'
    }
  },
  Fecalysis: {
    color: {
      english: 'Color',
      hasReference: false,
      hasUnit: false,
      name: 'color'
    },
    consistency: {
      english: 'Consistency',
      hasReference: false,
      hasUnit: false,
      name: 'consistency'
    },
    mucus: {
      english: 'Mucus',
      hasReference: false,
      hasUnit: false,
      name: 'mucus'
    },
    blood_gross: {
      english: 'Blood Gross',
      hasReference: false,
      hasUnit: false,
      name: 'blood_gross'
    },
    blood_occult: {
      english: 'Blood Occult',
      hasReference: false,
      hasUnit: false,
      name: 'blood_occult'
    }
  },
  Hematology: {
    rbc: {
      english: 'Red Blood Cell Count (RBC)',
      hasReference: true,
      hasUnit: true,
      name: 'rbc'
    },
    hgb: {
      english: 'Hemoglobin (Hgb)',
      hasReference: true,
      hasUnit: true,
      name: 'hgb'
    },
    hct: {
      english: 'Hematocrit (Hct)',
      hasReference: true,
      hasUnit: true,
      name: 'hct'
    },
    mcv: {
      english: 'Mean Corpuscular Volume (MCV)',
      hasReference: true,
      hasUnit: true,
      name: 'mcv'
    },
    mch: {
      english: 'Mean Corpuscular Hemoglobin (MCH)',
      hasReference: true,
      hasUnit: true,
      name: 'mch'
    },
    mchc: {
      english: 'Mean Corpuscular Hemoglobin Concentration (MCHC)',
      hasReference: true,
      hasUnit: true,
      name: 'mchc'
    },
    mpv: {
      english: 'Mean Platelet Volume (MPV)',
      hasReference: true,
      hasUnit: true,
      name: 'mpv'
    },
    rdw: {
      english: 'Red Cell Distribution Width (RDW)',
      hasReference: true,
      hasUnit: true,
      name: 'rdw'
    },
    wbc: {
      english: 'White Blood Cell (WBC)',
      hasReference: true,
      hasUnit: true,
      name: 'wbc'
    },
    cbc: {
      english: 'Complete Blood Count (CBC)',
      hasReference: true,
      hasUnit: true,
      name: 'cbc'
    },
    pc: {
      english: 'Platelet Count (PC)',
      hasReference: true,
      hasUnit: true,
      name: 'pc'
    },
    seg: {
      english: 'Segmenters (Seg)',
      hasReference: true,
      hasUnit: true,
      name: 'seg'
    },
    neut: {
      english: 'Neutrophils (Neut)',
      hasReference: true,
      hasUnit: true,
      name: 'neut'
    },
    lymph: {
      english: 'Lymphocytes (Lymph)',
      hasReference: true,
      hasUnit: true,
      name: 'lymph'
    },
    mono: {
      english: 'Monocytes (Mono)',
      hasReference: true,
      hasUnit: true,
      name: 'mono'
    },
    eosino: {
      english: 'Eosinophils (Eos)',
      hasReference: true,
      hasUnit: true,
      name: 'eosino'
    },
    baso: {
      english: 'Basophils (Baso)',
      hasReference: true,
      hasUnit: true,
      name: 'baso'
    },
    esr: {
      english: 'Erythrocyte Sedimentation Rate (ESR)',
      hasReference: true,
      hasUnit: true,
      name: 'esr'
    },
    blood_typing: {
      english: 'Blood Typing (ABO, Rh)',
      hasReference: true,
      hasUnit: true,
      name: 'blood_typing'
    },
    pbs: {
      english: 'Peripheral Blood Smear (PBS)',
      hasReference: true,
      hasUnit: true,
      name: 'pbs'
    },
    aptt: {
      english: 'Activated Partial Thromboplastin Time (APTT)',
      hasReference: true,
      hasUnit: true,
      name: 'aptt'
    },
    malarial_test: {
      english: 'Malarial Test',
      hasReference: true,
      hasUnit: true,
      name: 'malarial_test'
    }
  },
  Urinalysis: {
    color: {
      english: 'Color',
      hasReference: true,
      hasUnit: true,
      name: 'color'
    },
    transparency: {
      english: 'Transparency',
      hasReference: true,
      hasUnit: true,
      name: 'transparency'
    },
    sg: {
      english: 'Specific Gravity',
      hasReference: true,
      hasUnit: true,
      name: 'sg'
    },
    ph: {
      english: 'Acidity(pH)',
      hasReference: true,
      hasUnit: true,
      name: 'ph'
    },
    chon: {
      english: 'Protein(CHON)',
      hasReference: true,
      hasUnit: true,
      name: 'chon'
    },
    glucose: {
      english: 'Sugar(Glucose)',
      hasReference: true,
      hasUnit: true,
      name: 'glucose'
    },
    bilirubin: {
      english: 'Bilirubin',
      hasReference: true,
      hasUnit: true,
      name: 'bilirubin'
    },
    urobilinogen: {
      english: 'Urobilinogen',
      hasReference: true,
      hasUnit: true,
      name: 'urobilinogen'
    },
    blood: {
      english: 'Blood',
      hasReference: true,
      hasUnit: true,
      name: 'blood'
    },
    nitrites: {
      english: 'Nitrites',
      hasReference: true,
      hasUnit: true,
      name: 'nitrites'
    },
    leukocytes: {
      english: 'Leukocytes',
      hasReference: true,
      hasUnit: true,
      name: 'leukocytes'
    },
    keto: {
      english: 'Acetone/Ketone(Keto)',
      hasReference: true,
      hasUnit: true,
      name: 'keto'
    },
    ascorbic_acid: {
      english: 'Ascorbic acid',
      hasReference: true,
      hasUnit: true,
      name: 'ascorbic_acid'
    },
    pus_cells: {
      english: 'Pus Cells',
      hasReference: true,
      hasUnit: true,
      name: 'pus_cells'
    },
    epethil: {
      english: 'Epithelial Cells(Epithelial)',
      hasReference: true,
      hasUnit: true,
      name: 'epethil'
    },
    micral: {
      english: 'Microalbumin(Micral)',
      hasReference: true,
      hasUnit: true,
      name: 'micral'
    },
    urine_creatinine: {
      english: 'Urine Creatinine',
      hasReference: true,
      hasUnit: true,
      name: 'urine_creatinine'
    },
    rbc: {
      english: 'Red Blood Cells(RBC)',
      hasReference: true,
      hasUnit: true,
      name: 'rbc'
    },
    wbc: {
      english: 'White Blood Cells(WBC)',
      hasReference: true,
      hasUnit: true,
      name: 'wbc'
    },
    bac: {
      english: 'Bacteria(Bac)',
      hasReference: true,
      hasUnit: true,
      name: 'bac'
    },
    mucus_threads: {
      english: 'Mucus Threads',
      hasReference: true,
      hasUnit: true,
      name: 'mucus_threads'
    },
    amorphous_urates: {
      english: 'Amorphous Urates',
      hasReference: true,
      hasUnit: true,
      name: 'amorphous_urates'
    },
    amorphous_phosphates: {
      english: 'Amorphous Phosphates',
      hasReference: true,
      hasUnit: true,
      name: 'amorphous_phosphates'
    },
    cast: {
      english: 'Cast',
      hasReference: true,
      hasUnit: true,
      name: 'cast'
    },
    calcium_oxalate_crystals: {
      english: 'Calcium Oxalate Crystals',
      hasReference: true,
      hasUnit: true,
      name: 'calcium_oxalate_crystals'
    },
    mcr: {
      english: 'Microalbumin Creatinine Ratio',
      hasReference: true,
      hasUnit: true,
      name: 'mcr'
    },
    yeast_cells: {
      english: 'Yeast Cells',
      hasReference: true,
      hasUnit: true,
      name: 'yeast_cells'
    },
    uacr: {
      english: 'Urine Albumin to Creatinine Ratio',
      hasUnit: true,
      hasReference: true,
      name: 'uacr'
    },
    urr: {
      english: 'Urea Reduction Ratio',
      hasUnit: true,
      hasReference: true,
      name: 'urr'
    }
  },
  Thyroid: {
    tsh: {
      english: 'TSH',
      hasReference: true,
      hasUnit: true,
      name: 'tsh'
    },
    ft4: {
      english: 'FT4',
      hasReference: true,
      hasUnit: true,
      name: 'ft4'
    },
    ft3: {
      english: 'FT3',
      hasReference: true,
      hasUnit: true,
      name: 'ft3'
    },
    t3: {
      english: 'T3',
      hasReference: true,
      hasUnit: true,
      name: 't3'
    },
    t4: {
      english: 'T4',
      hasReference: true,
      hasUnit: true,
      name: 't4'
    },
    anti_antithyroglobulin: {
      english: 'Anti-TG (Antithyroglobulin)',
      hasReference: true,
      hasUnit: true,
      name: 'anti_antithyroglobulin'
    },
    anti_thyroperoxidase: {
      english: 'Anti-TPO (Thyroperoxidase)',
      hasReference: true,
      hasUnit: true,
      name: 'anti_thyroperoxidase'
    },
    thyroglobulin: {
      english: 'Thyroglobulin',
      hasReference: true,
      hasUnit: true,
      name: 'thyroglobulin'
    }
  },
  'Tumor Markers': {
    afp: {
      english: 'Alpha-fetoprotein (AFP)',
      hasReference: true,
      hasUnit: true,
      name: 'afp'
    },
    ca_125: {
      english: 'Cancer antigen 125 (CA125)',
      hasReference: true,
      hasUnit: true,
      name: 'ca_125'
    },
    ca_15_3: {
      english: 'Cancer antigen 15-3 (CA15-3)',
      hasReference: true,
      hasUnit: true,
      name: 'ca_15_3'
    },
    ca_19_9: {
      english: 'Carbohydrate antigen 19-9 (CA19-9)',
      hasReference: true,
      hasUnit: true,
      name: 'ca_19_9'
    },
    cea: {
      english: 'Carcinoembryonic antigen (CEA)',
      hasReference: true,
      hasUnit: true,
      name: 'cea'
    },
    hcg: {
      english: 'Human chorionic gonadotropin (hCG or beta-hCG)',
      hasReference: true,
      hasUnit: true,
      name: 'hcg'
    },
    kras: {
      english: 'KRAS Gene Mutation Analysis',
      hasReference: true,
      hasUnit: true,
      name: 'kras'
    },
    msi: {
      english: 'MSI (Microsatellite Instability)',
      hasReference: true,
      hasUnit: true,
      name: 'msi'
    },
    nse: {
      english: 'Neuron-Specific Enolase (NSE)',
      hasReference: true,
      hasUnit: true,
      name: 'nse'
    },
    pro_grp: {
      english: 'Pro-gastrin-releasing peptide (proGRP)',
      hasReference: true,
      hasUnit: true,
      name: 'pro_grp'
    },
    psa: {
      english: 'Prostate-specific antigen (PSA)',
      hasReference: true,
      hasUnit: true,
      name: 'psa'
    },
    scc: {
      english: 'Squamous Cell Carcinoma Antigen (SCC)',
      hasReference: true,
      hasUnit: true,
      name: 'scc'
    },
    urine_vma: {
      english: 'Vanillylmandelic Acid',
      hasReference: true,
      hasUnit: true,
      name: 'urine_vma'
    },
    pivka_ii: {
      english: 'Protein Induced by Vitamin K Absence or Antagonist-II (PIVKA-II)',
      hasReference: true,
      hasUnit: true,
      name: 'pivka_ii'
    }
  },
  Histopathology: {
    pathologist: {
      english: 'Pathologist',
      hasUnit: false,
      hasReference: false,
      name: 'pathologist'
    },
    clinical_history: {
      english: 'Clinical History',
      hasUnit: false,
      hasReference: false,
      name: 'clinical_history'
    },
    specimen: {
      english: 'Specimen',
      hasUnit: false,
      hasReference: false,
      name: 'specimen'
    },
    diagnosis: {
      english: 'Diagnosis',
      hasUnit: false,
      hasReference: false,
      name: 'diagnosis'
    },
    gross_description: {
      english: 'Gross Description',
      hasUnit: false,
      hasReference: false,
      name: 'gross_description'
    },
    microscopic_description: {
      english: 'Microscopic Description',
      hasUnit: false,
      hasReference: false,
      name: 'microscopic_description'
    },
    remarks: {
      english: 'Comment',
      hasUnit: false,
      hasReference: false,
      name: 'remarks'
    }
  },
  'Hormone Test': {
    fsh: {
      english: 'FSH',
      hasUnit: true,
      hasReference: true,
      name: 'fsh'
    },
    lh: {
      english: 'LH',
      hasUnit: true,
      hasReference: true,
      name: 'lh'
    },
    prolactin: {
      english: 'Prolactin',
      hasUnit: true,
      hasReference: true,
      name: 'prolactin'
    },
    e2: {
      english: 'E2',
      hasUnit: true,
      hasReference: true,
      name: 'e2'
    },
    progesterone: {
      english: 'Progesterone',
      hasUnit: true,
      hasReference: true,
      name: 'progesterone'
    },
    testosterone: {
      english: 'Testosterone',
      hasUnit: true,
      hasReference: true,
      name: 'testosterone'
    },
    cortisol: {
      english: 'Cortisol',
      hasUnit: true,
      hasReference: true,
      name: 'cortisol'
    },
    sex_hormone_binding: {
      english: 'Sex hormone Binding Globulin',
      hasUnit: true,
      hasReference: true,
      name: 'sex_hormone_binding'
    },
    renin: {
      english: 'Renin',
      hasUnit: true,
      hasReference: true,
      name: 'renin'
    },
    aldosterone: {
      english: 'Aldosterone',
      hasUnit: true,
      hasReference: true,
      name: 'aldosterone'
    },
    igf1: {
      english: 'IGF1 insuling growth factor (Somatomedin-C)',
      hasUnit: true,
      hasReference: true,
      name: 'igf1'
    },
    acth: {
      english: 'ACTH',
      hasUnit: true,
      hasReference: true,
      name: 'acth'
    },
    growth_hormone: {
      english: 'Growth Hormone',
      hasUnit: true,
      hasReference: true,
      name: 'growth_hormone'
    },
    insulin_level: {
      english: 'Insulin Level',
      hasUnit: true,
      hasReference: true,
      name: 'insulin_level'
    },
    metanephrine_urine_plasma: {
      english: 'Metanephrine urine Plasma',
      hasUnit: true,
      hasReference: true,
      name: 'metanephrine_urine_plasma'
    },
    cortisol_urine: {
      english: 'Cortisol Urine',
      hasUnit: true,
      hasReference: true,
      name: 'cortisol_urine'
    },
    free_testosterone: {
      english: 'Free Testosterone',
      hasUnit: true,
      hasReference: true,
      name: 'free_testosterone'
    },
    total_testosterone: {
      english: 'Total Testosterone',
      hasUnit: true,
      hasReference: true,
      name: 'total_testosterone'
    },
    thyroid_trb_tsi: {
      english: 'Thyroid TRB TSI',
      hasUnit: true,
      hasReference: true,
      name: 'thyroid_trb_tsi'
    },
    c_peptide: {
      english: 'C-peptide',
      hasUnit: true,
      hasReference: false,
      name: 'c_peptide'
    }
  },
  Hepatitis: {
    hbsag: {
      english: 'Hepatitis B surface antigen',
      hasUnit: false,
      hasReference: false,
      name: 'hbsag'
    },
    hbcab_igm: {
      english: 'Hepatitis - Anti-HBc IgM',
      hasUnit: false,
      hasReference: false,
      name: 'hbcab_igm'
    },
    hbcab_total: {
      english: 'Hepatitis - Anti-HBc Total',
      hasUnit: false,
      hasReference: false,
      name: 'hbcab_total'
    },
    hbsab: {
      english: 'Hepatitis - Anti-HBs',
      hasUnit: false,
      hasReference: false,
      name: 'hbsab'
    },
    anti_hcv: {
      english: 'Hepatitis - Anti-HCV',
      hasUnit: false,
      hasReference: false,
      name: 'anti_hcv'
    },
    anti_hbe: {
      english: 'Hepatits - Anti-HBe',
      hasUnit: false,
      hasReference: false,
      name: 'anti_hbe'
    },
    hbv_dna: {
      english: 'Hepatitis B virus DNA',
      hasUnit: false,
      hasReference: false,
      name: 'hbv_dna'
    },
    aso: {
      english: 'Antistreptolysin O Serum',
      hasUnit: false,
      hasReference: false,
      name: 'aso'
    },
    anti_hbc_igg: {
      english: 'Anti-HBc (lgG)',
      hasUnit: false,
      hasReference: false,
      name: 'anti_hbc_igg'
    },
    anti_hav_igg: {
      english: 'Anti-HAV (lgG)',
      hasUnit: false,
      hasReference: false,
      name: 'anti_hav_igg'
    },
    anti_hav_igm: {
      english: 'Anti-HAV (lgM)',
      hasUnit: false,
      hasReference: false,
      name: 'anti_hav_igm'
    }
  },
  'Lupus Panel': {
    ana: {
      english: 'ANA',
      hasUnit: true,
      hasReference: true,
      name: 'ana'
    },
    c3: {
      english: 'C3',
      hasUnit: true,
      hasReference: true,
      name: 'c3'
    },
    c4: {
      english: 'C4',
      hasUnit: true,
      hasReference: true,
      name: 'c4'
    },
    anti_dsdna: {
      english: 'Anti DSDNA',
      hasUnit: true,
      hasReference: true,
      name: 'anti_dsdna'
    },
    anti_smith: {
      english: 'Anti Smith',
      hasUnit: true,
      hasReference: true,
      name: 'anti_smith'
    },
    anti_rnp: {
      english: 'Anti RNP',
      hasUnit: true,
      hasReference: true,
      name: 'anti_rnp'
    },
    anti_ssro: {
      english: 'Anti SSRO',
      hasUnit: true,
      hasReference: true,
      name: 'anti_ssro'
    },
    anti_ssla: {
      english: 'Anti SSLA',
      hasUnit: true,
      hasReference: true,
      name: 'anti_ssla'
    },
    anti_lkm: {
      english: 'Antibody Liver/Kidney Microsome',
      hasUnit: true,
      hasReference: true,
      name: 'anti_lkm'
    },
    anti_sma: {
      english: 'Anti-Smooth Antibody',
      hasUnit: true,
      hasReference: true,
      name: 'anti_sma'
    },
    anti_cardio: {
      english: 'Anti-cardiolipin Antibodies',
      hasUnit: true,
      hasReference: true,
      name: 'anti_cardio'
    }
  },
  'Arthritis Panel': {
    crp: {
      english: 'CRP',
      hasUnit: true,
      hasReference: true,
      name: 'crp'
    },
    rf: {
      english: 'RF',
      hasUnit: true,
      hasReference: true,
      name: 'rf'
    },
    anti_ccp: {
      english: 'Anti CCP',
      hasUnit: true,
      hasReference: true,
      name: 'anti_ccp'
    },
    ana: {
      english: 'ANA',
      hasUnit: true,
      hasReference: true,
      name: 'ana'
    },
    c3: {
      english: 'C3',
      hasUnit: true,
      hasReference: true,
      name: 'c3'
    },
    vitamin_d: {
      english: 'Vitamin D',
      hasUnit: true,
      hasReference: true,
      name: 'vitamin_d'
    }
  },
  'Coagulation Disease': {
    label_coagulation_testing: 'Coagulation Testing',
    factor_vii_level: {
      english: 'Factor VII level',
      hasUnit: false,
      hasReference: false,
      name: 'factor_vii_level'
    },
    factor_ix_level: {
      english: 'Factor IX level',
      hasUnit: false,
      hasReference: false,
      name: 'factor_ix_level'
    },
    inhibitor_assay: {
      english: 'Inhibitor assay',
      hasUnit: false,
      hasReference: false,
      name: 'inhibitor_assay'
    },
    bethesda_units: {
      english: 'Bethesda Units',
      hasUnit: false,
      hasReference: false,
      name: 'bethesda_units'
    },
    label_blood_chemistry: 'Blood Chemistry',
    creatinine: {
      english: 'Creatinine (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'creatinine'
    },
    uric_acid: {
      english: 'Uric acid (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'uric_acid'
    },
    na: {
      english: 'Na (mEq/L)',
      hasUnit: false,
      hasReference: false,
      name: 'na'
    },
    k: {
      english: 'K (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'k'
    },
    sgot: {
      english: 'SGOT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgot'
    },
    sgpt: {
      english: 'SGPT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgpt'
    },
    ldh: {
      english: 'LDH (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'ldh'
    },
    imaging_studies: {
      english: 'Imaging Studies',
      hasUnit: false,
      hasReference: false,
      name: 'imaging_studies',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    }
  },
  Lymphoma: {
    label_blood_chemistry: 'Blood Chemistry',
    creatinine: {
      english: 'Creatinine (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'creatinine'
    },
    uric_acid: {
      english: 'Uric acid (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'uric_acid'
    },
    na: {
      english: 'Na (mEq/L)',
      hasUnit: false,
      hasReference: false,
      name: 'na'
    },
    k: {
      english: 'K (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'k'
    },
    sgot: {
      english: 'SGOT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgot'
    },
    sgpt: {
      english: 'SGPT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgpt'
    },
    ldh: {
      english: 'LDH (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'ldh'
    },
    esr: {
      english: 'ESR',
      hasUnit: false,
      hasReference: false,
      name: 'esr'
    },
    hematopathology_review_slide: {
      english: 'Hematopathology review slide',
      hasUnit: false,
      hasReference: false,
      name: 'hematopathology_review_slide',
      type: SWITCH,
      values: ['Yes', 'No']
    },
    immunohistochemistry_of_tissue: {
      english: 'Immunohistochemistry of tissue',
      hasUnit: false,
      hasReference: false,
      name: 'immunohistochemistry_of_tissue',
      type: SWITCH,
      values: ['Yes', 'No']
    },
    imaging_studies: {
      english: 'Imaging studies',
      hasUnit: false,
      hasReference: false,
      name: 'imaging_studies',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    },
    bone_marrow_aspirate_and_biopsy_result: {
      english: 'Bone marrow aspirate and biopsy result',
      hasUnit: false,
      hasReference: false,
      name: 'bone_marrow_aspirate_and_biopsy_result',
      type: SWITCH,
      values: ['Yes', 'No']
    },
    flow_cytometry: {
      english: 'Flow cytometry',
      hasUnit: false,
      hasReference: false,
      name: 'flow_cytometry',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    }
  },
  'AA PNH MDS': {
    creatinine: {
      english: 'Creatinine (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'creatinine'
    },
    uric_acid: {
      english: 'Uric acid (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'uric_acid'
    },
    reticulocyte_count: {
      english: 'Reticulocyte count',
      hasUnit: false,
      hasReference: false,
      name: 'reticulocyte_count'
    },
    serum_iron: {
      english: 'Serum iron',
      hasUnit: false,
      hasReference: false,
      name: 'serum_iron'
    },
    iron_binding_capacity: {
      english: 'Iron-binding capacity',
      hasUnit: false,
      hasReference: false,
      name: 'iron_binding_capacity'
    },
    serum_ferritin: {
      english: 'Serum ferritin',
      hasUnit: false,
      hasReference: false,
      name: 'serum_ferritin'
    },
    direct_antiglobulin: {
      english: 'Direct antiglobulin (Coombs) test(+/++/+++/-)',
      hasUnit: false,
      hasReference: false,
      name: 'direct_antiglobulin'
    },
    indirect_antiglobulin: {
      english: 'Indirect antiglobulin (Coombs) test(+/++/+++/-)',
      hasUnit: false,
      hasReference: false,
      name: 'indirect_antiglobulin'
    },
    sgot: {
      english: 'SGOT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgot'
    },
    sgpt: {
      english: 'SGPT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgpt'
    },
    ldh: {
      english: 'LDH (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'ldh'
    },
    screening_tests_for_hepatitis_virus: {
      english: 'Screening tests for hepatitis viruses A, B, and C (+/-)',
      hasUnit: false,
      hasReference: false,
      name: 'screening_tests_for_hepatitis_virus'
    },
    screening_tests_for_ebv_cmv_hiv: {
      english: 'Screening tests for EBV cytomegalovirus (CMV) and HIV (+/-)',
      hasUnit: false,
      hasReference: false,
      name: 'screening_tests_for_ebv_cmv_hiv'
    },
    erythropoeitin_level: {
      english: 'Erythropoietin level',
      hasUnit: false,
      hasReference: false,
      name: 'erythropoeitin_level'
    },
    serum_folic_acid: {
      english: 'Serum folic acid',
      hasUnit: false,
      hasReference: false,
      name: 'serum_folic_acid'
    },
    serum_b12: {
      english: 'Serum B12',
      hasUnit: false,
      hasReference: false,
      name: 'serum_b12'
    },
    tsh: {
      english: 'TSH',
      hasUnit: false,
      hasReference: false,
      name: 'tsh'
    },
    bone_marrow_aspirate_and_biopsy_result: {
      english: 'Bone marrow aspirate and biopsy result',
      hasUnit: false,
      hasReference: false,
      name: 'bone_marrow_aspirate_and_biopsy_result',
      type: SWITCH,
      values: ['Yes', 'No']
    },
    flow_cytometry: {
      english: 'Flow cytometry',
      hasUnit: false,
      hasReference: false,
      name: 'flow_cytometry',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    },
    cytogenetic_and_molecular_analysis_aplastic_anemia_pnh: {
      english: 'Cytogenetic and molecular analysis if for aplastic anemia and PNH',
      hasUnit: false,
      hasReference: false,
      name: 'cytogenetic_and_molecular_analysis_aplastic_anemia_pnh',
      type: SWITCH,
      values: ['Yes', 'Not done']
    },
    cytogenetic_and_molecular_analysis_mds: {
      english: 'Cytogenetic and molecular analysis if for MDS',
      hasUnit: false,
      hasReference: false,
      name: 'cytogenetic_and_molecular_analysis_mds',
      type: SWITCH,
      values: ['Yes', 'Not done']
    }
  },
  Leukemia: {
    label_blood_chemistry: 'Blood Chemistry',
    creatinine: {
      english: 'Creatinine (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'creatinine'
    },
    uric_acid: {
      english: 'Uric acid (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'uric_acid'
    },
    na: {
      english: 'Na (mEq/L)',
      hasUnit: false,
      hasReference: false,
      name: 'na'
    },
    k: {
      english: 'K (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'k'
    },
    sgot: {
      english: 'SGOT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgot'
    },
    sgpt: {
      english: 'SGPT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgpt'
    },
    ldh: {
      english: 'LDH (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'ldh'
    },
    imaging_studies: {
      english: 'Imaging studies',
      hasUnit: false,
      hasReference: false,
      name: 'imaging_studies',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    },
    bone_marrow_aspirate_and_biopsy_result: {
      english: 'Bone marrow aspirate and biopsy result',
      hasUnit: false,
      hasReference: false,
      name: 'bone_marrow_aspirate_and_biopsy_result',
      type: SWITCH,
      values: ['Yes', 'No']
    },
    flow_cytometry: {
      english: 'Flow cytometry',
      hasUnit: false,
      hasReference: false,
      name: 'flow_cytometry',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    },
    cytogenetic_and_molecular_analysis: {
      english: 'Cytogenetic and molecular analysis',
      hasUnit: false,
      hasReference: false,
      name: 'cytogenetic_and_molecular_analysis',
      type: SWITCH,
      values: ['Yes', 'Not done']
    }
  },
  'Myeloproliferative Neoplasm': {
    label_blood_chemistry: 'Blood Chemistry',
    creatinine: {
      english: 'Creatinine (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'creatinine'
    },
    uric_acid: {
      english: 'Uric acid (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'uric_acid'
    },
    na: {
      english: 'Na (mEq/L)',
      hasUnit: false,
      hasReference: false,
      name: 'na'
    },
    k: {
      english: 'K (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'k'
    },
    sgot: {
      english: 'SGOT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgot'
    },
    sgpt: {
      english: 'SGPT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgpt'
    },
    ldh: {
      english: 'LDH (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'ldh'
    },
    bone_marrow_aspirate_and_biopsy_result: {
      english: 'Bone marrow aspirate and biopsy result',
      hasUnit: false,
      hasReference: false,
      name: 'bone_marrow_aspirate_and_biopsy_result',
      type: SWITCH,
      values: ['Yes', 'No']
    },
    molecular_analysis: {
      english: 'Molecular analysis (jak 2 assay)',
      hasUnit: false,
      hasReference: false,
      name: 'molecular_analysis',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    }
  },
  'Platelet Disorder': {
    label_other_laboratories: 'Other Laboratories',
    creatinine: {
      english: 'Creatinine (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'creatinine'
    },
    uric_acid: {
      english: 'Uric acid (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'uric_acid'
    },
    na: {
      english: 'Na (mEq/L)',
      hasUnit: false,
      hasReference: false,
      name: 'na'
    },
    k: {
      english: 'K (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'k'
    },
    sgot: {
      english: 'SGOT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgot'
    },
    sgpt: {
      english: 'SGPT (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'sgpt'
    },
    ana_titer: {
      english: 'ANA titer',
      hasUnit: false,
      hasReference: false,
      name: 'ana_titer'
    },
    hepatitis_crna: {
      english: 'Hepatitis CRNA',
      hasUnit: false,
      hasReference: false,
      name: 'hepatitis_crna'
    },
    imaging_studies: {
      english: 'Imaging studies',
      hasUnit: false,
      hasReference: false,
      name: 'imaging_studies',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    },
    bone_marrow_aspirate_and_biopsy_result: {
      english: 'Bone marrow aspirate and biopsy result',
      hasUnit: false,
      hasReference: false,
      name: 'bone_marrow_aspirate_and_biopsy_result',
      type: SWITCH,
      values: ['Yes', 'No']
    },
    upper_gi_endoscopy: {
      english: 'Upper GI endoscopy',
      hasUnit: false,
      hasReference: false,
      name: 'upper_gi_endoscopy',
      type: SWITCH,
      values: ['Yes', 'No']
    },
    form_of_itp: {
      english: 'Form of ITP',
      hasUnit: false,
      hasReference: false,
      name: 'form_of_itp',
      type: DROPDOWN,
      values: ['Acute', 'Chronic']
    }
  },
  'Plasma Cell Disorder': {
    bun: {
      english: 'BUN (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'bun'
    },
    creatinine: {
      english: 'Creatinine (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'creatinine'
    },
    uric_acid: {
      english: 'Uric acid (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'uric_acid'
    },
    ionized_calcium: {
      english: 'Ionized calcium (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'ionized_calcium'
    },
    na: {
      english: 'Na (mEq/L)',
      hasUnit: false,
      hasReference: false,
      name: 'na'
    },
    k: {
      english: 'K (mg/dl)',
      hasUnit: false,
      hasReference: false,
      name: 'k'
    },
    total_protein: {
      english: 'Total protein',
      hasUnit: false,
      hasReference: false,
      name: 'total_protein'
    },
    albumin: {
      english: 'Albumin',
      hasUnit: false,
      hasReference: false,
      name: 'albumin'
    },
    globulin: {
      english: 'Globulin',
      hasUnit: false,
      hasReference: false,
      name: 'globulin'
    },
    beta_microglobulin: {
      english: 'Beta Microglobulin',
      hasUnit: false,
      hasReference: false,
      name: 'beta_microglobulin'
    },
    ldh: {
      english: 'LDH (U/L)',
      hasUnit: false,
      hasReference: false,
      name: 'ldh'
    },
    imaging_studies: {
      english: 'Imaging studies',
      hasUnit: false,
      hasReference: false,
      name: 'imaging_studies',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    },
    bone_marrow_aspirate_and_biopsy_result: {
      english: 'Bone marrow aspirate and biopsy result',
      hasUnit: false,
      hasReference: false,
      name: 'bone_marrow_aspirate_and_biopsy_result',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    },
    serum_free_light_chain_assay: {
      english: 'Serum free light chain assay',
      hasUnit: false,
      hasReference: false,
      name: 'serum_free_light_chain_assay',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    },
    serum_protein_electrophoresis: {
      english: 'Serum protein electrophoresis',
      hasUnit: false,
      hasReference: false,
      name: 'serum_protein_electrophoresis',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    },
    serum_immunofixation: {
      english: 'Serum immunofixation',
      hasUnit: false,
      hasReference: false,
      name: 'serum_immunofixation',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    },
    urine_protein_electrophoresis: {
      english: 'Urine protein electrophoresis/immunofixation',
      hasUnit: false,
      hasReference: false,
      name: 'urine_protein_electrophoresis',
      type: SWITCH,
      values: ['Yes', 'No/Not applicable']
    },
    cytogenetic_and_molecular_analysis: {
      english: 'Cytogenetic and molecular analysis',
      hasUnit: false,
      hasReference: false,
      name: 'cytogenetic_and_molecular_analysis',
      type: SWITCH,
      values: ['Yes', 'Not done']
    }
  },
  'Liver Function Test': {
    ag_ratio: {
      english: 'Albumin-Globulin (A/G) Ratio',
      hasUnit: false,
      hasReference: true,
      name: 'ag_ratio'
    },
    albumin: {
      english: 'Albumin in Blood',
      hasUnit: true,
      hasReference: true,
      name: 'albumin'
    },
    alkaline_phosphatase: {
      english: 'Alkaline Phosphatase (ALP)',
      hasUnit: true,
      hasReference: true,
      name: 'alkaline_phosphatase'
    },
    ggt: {
      english: 'Gamma-glutamyl transpeptidase',
      hasUnit: true,
      hasReference: true,
      name: 'ggt'
    },
    globulin: {
      english: 'Globulin',
      hasUnit: true,
      hasReference: true,
      name: 'globulin'
    },
    ldh: {
      english: 'Lactate Dehydrogenase',
      hasUnit: true,
      hasReference: true,
      name: 'ldh'
    },
    pag: {
      english: 'PAG',
      hasUnit: false,
      hasReference: false,
      name: 'pag'
    },
    prothrombin_time: {
      english: 'PT',
      hasUnit: true,
      hasReference: true,
      name: 'prothrombin_time'
    },
    sgot: {
      english: 'SGOT/AST',
      hasUnit: true,
      hasReference: true,
      name: 'sgot'
    },
    sgpt: {
      english: 'SGPT/ALT',
      hasUnit: true,
      hasReference: true,
      name: 'sgpt'
    },
    total_bilirubin: {
      english: 'Total Bilirubin',
      hasUnit: true,
      hasReference: true,
      name: 'total_bilirubin'
    },
    direct_bilirubin: {
      english: 'Direct (Conjugated) Bilirubin',
      hasUnit: true,
      hasReference: true,
      name: 'direct_bilirubin'
    },
    indirect_bilirubin: {
      english: 'Indirect (Unconjugated) Bilirubin',
      hasUnit: true,
      hasReference: true,
      name: 'indirect_bilirubin'
    }
  },
  'Molecular and Immunopathology': {
    rna_rt_pcr_test: {
      english: 'SARS COV2 RNA RT-PCR Test',
      hasUnit: false,
      hasReference: false,
      name: 'rna_rt_pcr_test',
      type: SWITCH,
      values: ['Positive', 'Negative']
    },
    rat_lgg_lgm: {
      english: 'SARS COV2 Rapid Antibody Test IgG and IgM',
      hasUnit: false,
      hasReference: false,
      name: 'rat_lgg_lgm',
      type: SWITCH,
      values: ['Positive', 'Negative']
    },
    at_elisa: {
      english: 'SARS COV2 Antibody test ELSICA',
      hasUnit: false,
      hasReference: false,
      name: 'at_elisa',
      type: SWITCH,
      values: ['Positive', 'Negative']
    },
    at_eclia: {
      english: 'SARS COV2 Antibody test ECLIA',
      hasUnit: false,
      hasReference: false,
      name: 'at_eclia',
      type: SWITCH,
      values: ['Positive', 'Negative']
    }
  },
  Microscopy: {
    routine_analysis: {
      english: 'Routine Urinalysis with 10 parameters',
      hasUnit: true,
      hasReference: true,
      name: 'routine_analysis'
    },
    micral_test: {
      english: 'Micral Test',
      hasUnit: true,
      hasReference: true,
      name: 'micral_test'
    },
    urine_rbc: {
      english: 'Urine RBC Morphology',
      hasUnit: true,
      hasReference: true,
      name: 'urine_rbc'
    },
    semen_analysis: {
      english: 'Semen Analysis',
      hasUnit: true,
      hasReference: true,
      name: 'semen_analysis'
    },
    pregnancy_test: {
      english: 'Pregnancy Test (Urine/Serum)',
      hasUnit: true,
      hasReference: true,
      name: 'pregnancy_test'
    },
    routine_fecalysis: {
      english: 'Routine Fecalysis',
      hasUnit: true,
      hasReference: true,
      name: 'routine_fecalysis'
    },
    occult_blood: {
      english: 'Occult Blood',
      hasUnit: true,
      hasReference: true,
      name: 'occult_blood'
    },
    cellulose_tape_slide: {
      english: 'Cellulose Tape Slide',
      hasUnit: true,
      hasReference: true,
      name: 'cellulose_tape_slide'
    },
    quantiferon_tb: {
      english: 'QuantiFERON-TB',
      hasUnit: true,
      hasReference: true,
      name: 'quantiferon_tb'
    },
    igra: {
      english: 'IGRA',
      hasUnit: true,
      hasReference: true,
      name: 'igra'
    }
  },
  'Urine Chemistry': {
    creatinine_clearance: {
      english: 'Creatinine Clearance',
      hasUnit: true,
      hasReference: true,
      name: 'creatinine_clearance'
    },
    microalbumin: {
      english: 'Microalbumin with Value',
      hasUnit: true,
      hasReference: true,
      name: 'microalbumin'
    },
    microalbumin_creatine: {
      english: 'Microalbumin-Creatinine Ratio',
      hasUnit: true,
      hasReference: true,
      name: 'microalbumin_creatine'
    },
    urine_creatinine: {
      english: 'Urine Creatinine',
      hasUnit: true,
      hasReference: true,
      name: 'urine_creatinine'
    },
    urine_protein: {
      english: 'Urine Protein',
      hasUnit: true,
      hasReference: true,
      name: 'urine_protein'
    },
    upcr: {
      english: 'Urine Protein-Creatinine Ratio (UPCR)',
      hasUnit: true,
      hasReference: true,
      name: 'upcr'
    },
    drug_test: {
      english: 'Drug Test (Methamphetamine, Tetrahydrocannabinol)',
      hasUnit: true,
      hasReference: true,
      name: 'drug_test'
    }
  },
  Serology: {
    dengue__igg_igm_blot: {
      english: 'Dengue IgG / IgM / Dengue Blot',
      hasUnit: true,
      hasReference: true,
      name: 'dengue__igg_igm_blot'
    },
    dengue__ns1: {
      english: 'Dengue NS1',
      hasUnit: true,
      hasReference: true,
      name: 'dengue__ns1'
    },
    typhidot: {
      english: 'Typhidot',
      hasUnit: true,
      hasReference: true,
      name: 'typhidot'
    },
    crp: {
      english: 'C-Reactive Protein (CRP)',
      hasUnit: true,
      hasReference: true,
      name: 'crp'
    },
    aso: {
      english: 'Antistreptolysin O (ASO)',
      hasUnit: true,
      hasReference: true,
      name: 'aso'
    },
    rf: {
      english: 'Rheumatoid Factor (RF)',
      hasUnit: true,
      hasReference: true,
      name: 'rf'
    },
    syphilis: {
      english: 'Syphilis',
      hasUnit: true,
      hasReference: true,
      name: 'syphilis'
    },
    leptospiral: {
      english: 'Leptospiral (IgG & IgM)',
      hasUnit: true,
      hasReference: true,
      name: 'leptospiral'
    },
    rpr_quantitative: {
      english: 'RPR Quantitative',
      hasUnit: true,
      hasReference: true,
      name: 'rpr_quantitative'
    },
    rpr_qualitative: {
      english: 'RPR Qualitative',
      hasUnit: true,
      hasReference: true,
      name: 'rpr_qualitative'
    },
    hiv: {
      english: 'HIV',
      hasUnit: true,
      hasReference: true,
      name: 'hiv'
    }
  },
  'Cancer Marker': {
    psa: {
      english: 'Prostate Specific Antigen (PSA)',
      hasUnit: true,
      hasReference: true,
      name: 'psa'
    },
    free_psa: {
      english: 'Free PSA',
      hasUnit: true,
      hasReference: true,
      name: 'free_psa'
    },
    psa_profile: {
      english: 'PSA Profile',
      hasUnit: true,
      hasReference: true,
      name: 'psa_profile'
    },
    cea: {
      english: 'Carcinoembryonic Antigen (CEA)',
      hasUnit: true,
      hasReference: true,
      name: 'cea'
    },
    afp: {
      english: 'Alpha Feto Protein (AFP)',
      hasUnit: true,
      hasReference: true,
      name: 'afp'
    },
    b_hcg: {
      english: 'B-HCG',
      hasUnit: true,
      hasReference: true,
      name: 'b_hcg'
    },
    ca_125: {
      english: 'CA-125',
      hasUnit: true,
      hasReference: true,
      name: 'ca_125'
    },
    ca_153: {
      english: 'CA 15-3',
      hasUnit: true,
      hasReference: true,
      name: 'ca_153'
    }
  },
  Endocrine: {
    t3: {
      english: 'Triiodothyronine (T3)',
      hasUnit: true,
      hasReference: true,
      name: 't3'
    },
    t4: {
      english: 'Thyroxine (T4)',
      hasUnit: true,
      hasReference: true,
      name: 't4'
    },
    tsh: {
      english: 'Thyroid Stimulating Hormone (TSH)',
      hasUnit: true,
      hasReference: true,
      name: 'tsh'
    },
    ft3: {
      english: 'FT3',
      hasUnit: true,
      hasReference: true,
      name: 'ft3'
    },
    ft4: {
      english: 'FT4',
      hasUnit: true,
      hasReference: true,
      name: 'ft4'
    },
    anti_tpo: {
      english: 'Anti-Thyroxine Peroxidase (Anti-TPO)',
      hasUnit: true,
      hasReference: true,
      name: 'anti_tpo'
    },
    insulin: {
      english: 'Insulin',
      hasUnit: true,
      hasReference: true,
      name: 'insulin'
    },
    ipth: {
      english: 'IPTH',
      hasUnit: true,
      hasReference: true,
      name: 'ipth'
    },
    lh: {
      english: 'Luteinizing Hormone (LH)',
      hasUnit: true,
      hasReference: true,
      name: 'lh'
    },
    prolactin: {
      english: 'Prolactin',
      hasUnit: true,
      hasReference: true,
      name: 'prolactin'
    }
  },
  Microbiology: {
    gram_stain: {
      english: 'Gram Stain',
      hasUnit: true,
      hasReference: true,
      name: 'gram_stain'
    },
    india_ink: {
      english: 'India Ink',
      hasUnit: true,
      hasReference: true,
      name: 'india_ink'
    },
    koh_preparation: {
      english: 'KOH Preparation',
      hasUnit: true,
      hasReference: true,
      name: 'koh_preparation'
    },
    wet_mount: {
      english: 'Wet Mount (Fresh Smear)',
      hasUnit: true,
      hasReference: true,
      name: 'wet_mount'
    },
    afb: {
      english: 'AFB (DSSM)',
      hasUnit: true,
      hasReference: true,
      name: 'afb'
    },
    routine_cs: {
      english: 'Routine CS',
      hasUnit: true,
      hasReference: true,
      name: 'routine_cs'
    },
    routine_cs_gs: {
      english: 'Routine CS / GS',
      hasUnit: true,
      hasReference: true,
      name: 'routine_cs_gs'
    }
  },
  // Vitals: {
  //   height_value: { name: 'height_value', hasUnit: true },
  //   weight_value: { name: 'weight_value', hasUnit: true },
  //   blood_sugar_value: { name: 'blood_sugar_value', hasUnit: true },
  //   bmi: { name: 'bmi' },
  //   bp_systolic: { name: 'bp_systolic' },
  //   bp_diastolic: { name: 'bp_diastolic' },
  //   heart_rate: { name: 'heart_rate' },
  //   respiratory_rate: { name: 'respiratory_rate' },
  //   temperature: { name: 'temperature' },
  //   pain: { name: 'pain' },
  //   gsc: { name: 'gsc' },
  //   hba1c: { name: 'hba1c' },
  //   rgt: { name: 'rgt' },
  //   fgt: { name: 'fgt' },
  //   oggt: { name: 'oggt' },
  //   body_surface_area: { name: 'body_surface_area' },
  //   oxy_saturation_value: { name: 'oxy_saturation_value', hasUnit: true },
  //   head_circumference_value: { name: 'head_circumference_value', hasUnit: true },
  //   waist_circumference_value: { name: 'waist_circumference_value', hasUnit: true },
  //   neck_circumference_value: { name: 'neck_circumference_value', hasUnit: true }
  // }
  'Breast Biopsy': {},
  'Continuous Glucose Monitoring Panel': {
    time_sensor_active: {
      english: 'Time Sensor Active',
      hasUnit: true,
      hasReference: true,
      name: 'time_sensor_active',
      unit: '%',
      isUnitReadOnly: true
    },
    target_range: {
      english: 'Target Range',
      hasUnit: true,
      hasReference: true,
      name: 'target_range',
      isUnitDropdown: true,
      values: ['%', 'mg/dL', 'mmol/mol']
    },
    low: {
      english: 'LOW',
      hasUnit: true,
      hasReference: true,
      name: 'low',
      textHelper: 'Below 70 mg/dL',
      isUnitDropdown: true,
      values: ['%', 'mg/dL', 'mmol/mol']
    },
    very_low: {
      english: 'VERY LOW',
      hasUnit: true,
      hasReference: true,
      name: 'very_low',
      textHelper: 'Below 54 mg/dL',
      isUnitDropdown: true,
      values: ['%', 'mg/dL', 'mmol/mol']
    },
    high: {
      english: 'HIGH',
      hasUnit: true,
      hasReference: true,
      name: 'high',
      textHelper: 'Above 180 mg/dL',
      isUnitDropdown: true,
      values: ['%', 'mg/dL', 'mmol/mol']
    },
    very_high: {
      english: 'VERY HIGH',
      hasUnit: true,
      hasReference: true,
      name: 'very_high',
      textHelper: 'Above 250 mg/dL',
      isUnitDropdown: true,
      values: ['%', 'mg/dL', 'mmol/mol']
    },
    average_glucose: {
      english: 'Average Glucose',
      hasUnit: true,
      hasReference: true,
      name: 'average_glucose',
      unit: '%',
      isUnitReadOnly: true
    },
    glucose_management_indicator: {
      english: 'Glucose Management Indicator',
      hasUnit: true,
      hasReference: true,
      name: 'glucose_management_indicator',
      isUnitDropdown: true,
      values: ['%', 'mg/dL', 'mmol/mol']
    },
    glucose_variability: {
      english: 'Glucose Variability',
      hasUnit: true,
      hasReference: true,
      name: 'glucose_variability',
      unit: '%',
      isUnitReadOnly: true
    }
  }
} as { [index: string]: any };

const testTypeMap = {
  ABG: 'PhrTestGroups::Abg',
  'Bleeding Parameter': 'PhrTestGroups::BleedingParameter',
  Chemistry: 'PhrTestGroups::Chemistry',
  'CKD Panel': 'PhrTestGroups::CkdPanel',
  'Lipid Profile': 'PhrTestGroups::LipidProfile',
  Culture: 'PhrTestGroups::Culture',
  Fecalysis: 'PhrTestGroups::Fecalysis',
  Hematology: 'PhrTestGroups::Hematology',
  Urinalysis: 'PhrTestGroups::Urinalysis',
  Thyroid: 'PhrTestGroups::Thyroid',
  'Tumor Markers': 'PhrTestGroups::TumorMarkerGeneral',
  Histopathology: 'PhrTestGroups::Histopathology',
  'Hormone Test': 'PhrTestGroups::HormoneTest',
  'Lupus Panel': 'PhrTestGroups::LupusPanel',
  Hepatitis: 'PhrTestGroups::Hepatitis',
  'Arthritis Panel': 'PhrTestGroups::ArthritisPanel',
  'Coagulation Disease': 'PhrTestGroups::Coagulation',
  Lymphoma: 'PhrTestGroups::Lymphoma',
  'AA PNH MDS': 'PhrTestGroups::AaPnhMds',
  Leukemia: 'PhrTestGroups::Leukemia',
  'Myeloproliferative Neoplasm': 'PhrTestGroups::MyeloproliferativeNeoplasm',
  'Platelet Disorder': 'PhrTestGroups::PlateletDisorder',
  'Plasma Cell Disorder': 'PhrTestGroups::PlasmaCellDisorder',
  'Liver Function Test': 'PhrTestGroups::LiverFunctionTest',
  'Molecular and Immunopathology': 'PhrTestGroups::Immunology',
  Microscopy: 'PhrTestGroups::Microscopy',
  Microbiology: 'PhrTestGroups::Microbiology',
  Serology: 'PhrTestGroups::Serology',
  Endocrine: 'PhrTestGroups::Endocrine',
  'Urine Chemistry': 'PhrTestGroups::UrineChemistry',
  'Cancer Marker': 'PhrTestGroups::CancerMarker',
  'Continuous Glucose Monitoring Panel': 'PhrTestGroups::CgmPanel'
} as { [index: string]: any };

export const findDefinitionForField = (type: string, name: string) => {
  const set = mapping[type];
  if (!set) return null;

  const field = set[name];
  if (!field) return null;

  return field;
};

export const findLabelForField = (type: string, name: string) => {
  const field = findDefinitionForField(type, name);
  if (field) return field.english;

  return '';
};

export const enumerateFieldsForType = (type: string) => {
  return Object.keys(mapping[type]);
};

export const enumerateSections = () => {
  return Object.keys(mapping);
};

export const getSectionType = (key: string) => {
  return testTypeMap[key] ? testTypeMap[key] : null;
};

export type FieldDefinitionType = {
  english: string;
  hasReference: boolean;
  hasUnit: boolean;
  name: string;
  type?: 'switch' | 'dropdown';
  values?: Array<string>;
  isUnitReadOnly?: boolean;
  isUnitDropdown?: boolean;
  unit?: string;
  isReadOnly?: boolean;
  textHelper?: string;
};

export default mapping;
