import { ENDPOINTS } from 'defaults';
import { http } from './http';
import { AccountHMOInput, Identification, Specialization } from 'models/user';
import { transformOut } from '../transformer/user';
const { PROFILE, CLINIC, IDENTIFICATION, SPECIALIZATION } = ENDPOINTS.DOCTOR;
const { PUT: UPDATE_IDENTIFICATION } = IDENTIFICATION;
const { DELETE: DELETE_IDENTIFICATION } = IDENTIFICATION;
const { DELETE: DELETE_SPECIALIZATION, POST: ADD_SPECIALIZATION, GET: GET_SPECIALIZATIONS } = SPECIALIZATION;
const { POST: ADD_CLINIC } = CLINIC;

export async function getAccountClinics(accountId?: number) {
  const { parse } = CLINIC.GET;
  const endpoint = parse({ account_id: accountId });
  return http.get(endpoint.name, endpoint.url);
}

export async function addAccountClinic(clinicId: number, accountId?: number) {
  return http.post(ADD_CLINIC.name, ADD_CLINIC.url, { clinic_id: clinicId, account_id: accountId });
}

export async function getDoctorProfile() {
  const { GET } = PROFILE;
  return http.get(GET.name, GET.url);
}

export async function getDoctorClinic() {
  const { LIST } = CLINIC.GET;
  return http.get(LIST.name, LIST.url);
}

export async function getDoctorClinicHeader() {
  const { HEADER } = CLINIC.GET;
  return http.get(HEADER.name, HEADER.url);
}

export async function addIdentificationNumbers(payload: Partial<Identification>[]) {
  const promises = payload.map(identificationNumber =>
    http.post(
      UPDATE_IDENTIFICATION.name,
      UPDATE_IDENTIFICATION.url,
      transformOut.addIdentificationNumber(identificationNumber)
    )
  );
  return Promise.all(promises);
}

export async function updateIdentificationNumbers(payload: Identification[]) {
  const promises = payload.map(identificationNumber =>
    http.put(
      UPDATE_IDENTIFICATION.name,
      UPDATE_IDENTIFICATION.url,
      transformOut.updateIdentificationNumber(identificationNumber)
    )
  );
  return Promise.all(promises);
}

export async function deleteIdentificationNumbers(id: number) {
  return http.delete(DELETE_IDENTIFICATION.name, DELETE_IDENTIFICATION.url, { id });
}

export async function getAccountSpecializations(accountId?: number) {
  const { parse } = GET_SPECIALIZATIONS;
  const endpoint = parse({ account_id: accountId });
  return http.get(endpoint.name, endpoint.url);
}

export async function deleteAccountSpecialization(id: number, accountId?: number) {
  return http.delete(DELETE_SPECIALIZATION.name, DELETE_SPECIALIZATION.url, { id, account_id: accountId });
}

export async function addAccountSpecialization(specializationId: number, accountId?: number) {
  return http.post(ADD_SPECIALIZATION.name, ADD_SPECIALIZATION.url, {
    specialization_id: specializationId,
    active: true,
    account_id: accountId
  });
}

export async function deleteSpecializations(specializationIds: number[], accountId?: number) {
  const promises = specializationIds.map(specializationId =>
    http.delete(DELETE_SPECIALIZATION.name, DELETE_SPECIALIZATION.url, { id: specializationId, account_id: accountId })
  );
  return Promise.all(promises);
}

export async function addSpecialization(payload: Partial<Specialization>, accountId?: number) {
  return http.post(ADD_SPECIALIZATION.name, ADD_SPECIALIZATION.url, {
    specialization_id: payload.id,
    active: true,
    account_id: accountId
  });
}

export async function addSpecializations(specializationIds: number[], accountId?: number) {
  const promises = specializationIds.map(specializationId =>
    http.post(ADD_SPECIALIZATION.name, ADD_SPECIALIZATION.url, {
      specialization_id: specializationId,
      active: true,
      account_id: accountId
    })
  );
  return Promise.all(promises);
}

export async function addClinic(clinicId: number) {
  return http.post(ADD_CLINIC.name, ADD_CLINIC.url, { clinic_id: clinicId });
}

export async function addClinics(clinicIds: number[]) {
  const { data } = await getDoctorClinic();
  const existIds = data && data.length > 0 ? data.map((item: any) => item?.clinic?.id) : [];
  const clinicsToSave = clinicIds.filter(clinicId => !existIds.includes(clinicId));
  const promises = clinicsToSave.map(clinicId => http.post(ADD_CLINIC.name, ADD_CLINIC.url, { clinic_id: clinicId }));
  return Promise.all(promises);
}

export async function saveAccountHMOs(hmos: AccountHMOInput[]) {
  const endpoint = ENDPOINTS.DOCTOR.HMO.key;
  for (let i = 0; i < hmos.length; i++) {
    const hmo = hmos[i];
    const method = !hmo.id ? http.post : http.put;
    const payload = {
      id: !hmo.id ? undefined : hmo.id,
      hmo_id: hmo.hmoId,
      number: hmo.number
    };
    if (payload.hmo_id === -1) continue;
    await method(endpoint.name, endpoint.url, payload);
  }
}

export async function deleteAccountHMOs(ids: number[]) {
  const endpoint = ENDPOINTS.DOCTOR.HMO.key;
  const promises = ids.map(id => http.delete(endpoint.name, endpoint.url, { id }));
  return Promise.all(promises);
}

export async function getAccountHMOs(accountId: number) {
  const endpoint = ENDPOINTS.DOCTOR.HMO.parse({ account_id: accountId });
  return http.get(endpoint.name, endpoint.url);
}
