import { combineReducers, Reducer } from 'redux';
import { StoreState, ActionType } from 'models/store';

import auth from './auth';
import specialization from './specialization';
import user from './user';
import clinic from './clinic';
import hmo from './hmo';
import appointment from './appointment';
import onboarding from './onboard';
import session from './session';
import search from './search';
import patient from './patient';
import phr from './phr';
import peek from './peek';
import modal from './modal';

const rootReducer: Reducer<StoreState, ActionType> = combineReducers({
  ...auth,
  specialization,
  user,
  clinic,
  appointment,
  onboarding,
  session,
  search,
  hmo,
  patient,
  phr,
  peek,
  modal
});

export default rootReducer;
