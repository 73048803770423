import { FieldArray, FieldArrayRenderProps, Input, Toggle } from 'components/form';
import { YES_NO_OPTIONS } from 'constants/form';
import { useFormSubField } from 'hooks/form';
import isEqual from 'lodash/isEqual';
import { _ChemotherapyInput } from 'models/endpoint';
import React from 'react';
import AddItem from '../AddItem';
import RemoveItem from '../RemoveItem';
import { useTranslation } from 'react-i18next';

export type Chemotherapy = _ChemotherapyInput;
export const DEFAULT_CHEMOTHERAPY: Chemotherapy = {
  id: undefined,
  chemotherapy: '',
  regimen: '',
  cycles: '',
  date_to: '',
  date_from: '',
  institution: ''
};

interface ChemotherapyItemProps {
  index: number;
  helpers: FieldArrayRenderProps;
  showAdd: boolean;
  showRemove: boolean;
  onChangeCb: (data?: any) => void;
}

const ChemotherapyItem: React.FC<ChemotherapyItemProps> = React.memo(
  ({ index, helpers, showAdd, showRemove, onChangeCb }) => {
    const [t] = useTranslation('patient_profile');
    const { getSubName } = useFormSubField('chemotherapies');
    const insert = () => helpers.push(DEFAULT_CHEMOTHERAPY);
    const remove = () => {
      helpers.remove(index);
      onChangeCb();
    };
    return (
      <React.Fragment>
        <div className="flex-row">
          {index === 0 ? (
            <div className="flex-child">
              <Toggle name={getSubName('chemotherapy', index)} items={YES_NO_OPTIONS} onChange={onChangeCb} fullWidth />
            </div>
          ) : null}
          <div className="flex-child" style={{ flexGrow: index === 0 ? 3 : 1 }}>
            <div className="flex-row">
              <div className="flex-child">
                <Input type="date" label={t('from')} name={getSubName('date_from', index)} fullWidth />
              </div>
              <div className="flex-child">
                <Input type="date" label={t('to')} name={getSubName('date_to', index)} fullWidth />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row">
          <div className="flex-child">
            <Input label={t('regimen')} name={getSubName('regimen', index)} fullWidth />
          </div>
          <div className="flex-child">
            <Input label={t('number_of_cycles')} name={getSubName('cycles', index)} fullWidth />
          </div>
        </div>
        <div className="flex-row">
          <Input label={t('institution')} name={getSubName('institution', index)} fullWidth />
        </div>
        <div className="add-multiple">
          {showAdd ? <AddItem onClick={insert} /> : null}
          {showRemove ? <RemoveItem onClick={remove} /> : null}
        </div>
      </React.Fragment>
    );
  }
);

interface Props {
  list: Chemotherapy[];
  onChangeCb: (data?: any) => void;
}

const Chemotherapies: React.FC<Props> = props => {
  const [t] = useTranslation('patient_profile');
  const { getName } = useFormSubField('chemotherapies');
  const { list, onChangeCb } = props;
  const totalItems = list.length;
  const lastItemIndex = totalItems - 1;
  return (
    <div className="section">
      <label className="sub-header grey">{t('chemotherapy', { count: 1 })}</label>
      <FieldArray
        name={getName()}
        render={helpers =>
          list.map((_, index: number) => (
            <ChemotherapyItem
              key={index}
              index={index}
              helpers={helpers}
              showAdd={totalItems === 1 || (totalItems > 1 && lastItemIndex === index)}
              showRemove={index > 0 && lastItemIndex === index}
              onChangeCb={onChangeCb}
            />
          ))
        }
      />
    </div>
  );
};

export default React.memo(Chemotherapies, (prev, next) => isEqual(prev.list, next.list));
