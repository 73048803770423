import './Peek.scss';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Peek } from './Peek';
import { StorePeek, StoreState } from 'models/store';
import { peek, PeekAction } from 'state/actions/peek';
import { bindActionCreators } from 'redux';

interface StateMap {
  peeks: StorePeek;
}

type DispatchMap = PeekAction;

type Props = StateMap & DispatchMap;

const MSP = (store: StoreState): StateMap => ({ peeks: store.peek });
const MDP = (dispatch: any) => bindActionCreators({ ...peek }, dispatch);

const PeekContainer: React.FC<Props> = props => {
  const { hidePeek } = props;
  const { data: peeks } = props.peeks;

  const renderPeeks = useMemo(() => {
    return peeks.map((peek, idx) => {
      peek = {
        ...peek,
        toggle: () => hidePeek()
      };
      return <Peek key={idx} {...peek} />;
    });
  }, [peeks, hidePeek]);
  return <React.Fragment>{renderPeeks}</React.Fragment>;
};

export default connect(MSP, MDP)(PeekContainer);
