import { all, fork } from 'redux-saga/effects';
import * as loggerSaga from './logger';
import * as authSaga from './auth';
import * as userSaga from './user';
import * as specializationSaga from './specialization';
import * as searchSaga from './search';
import * as appointmentSaga from './appointment';
import * as onboardingSaga from './onboard';
import * as secretarySaga from './secretary';
import * as sessionSaga from './session';
import * as patientSaga from './patient';
import * as phrSaga from './phr';
import * as labsSaga from './labs';
import * as navigatorSaga from './navigator';

export default function* root() {
  yield all(
    [
      ...Object.values(authSaga),
      ...Object.values(loggerSaga),
      ...Object.values(specializationSaga),
      ...Object.values(userSaga),
      ...Object.values(searchSaga),
      ...Object.values(appointmentSaga),
      ...Object.values(onboardingSaga),
      ...Object.values(secretarySaga),
      ...Object.values(sessionSaga),
      ...Object.values(patientSaga),
      ...Object.values(phrSaga),
      ...Object.values(labsSaga),
      ...Object.values(navigatorSaga)
    ].map(fork)
  );
}
