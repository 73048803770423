import { XIcon } from '@heroicons/react/solid';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Button } from 'components/button/Button';
import { styled } from 'components/Theme';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';

const modalRoot = document.getElementById('modal-root');

const getSize = (size?: string) => {
  switch (size ?? 'sm') {
    case 'sm':
      return 'bpSmall';
    case 'md':
      return 'bpMedium';
    case 'lg':
      return 'bpLarge';
    case 'xl':
      return 'bpXLarge';
    case 'xxl':
      return 'bpFull';
    default:
      return 'bpFull';
  }
};

export interface ModalProps {
  open?: boolean;
  index?: number;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  customSize?: string;
  title?: any;
  subheader?: any;
  component?: (props: ModalProps) => any;
  footer?: any;
  options?: ModalOptions;
  handleClose?: () => void;
}

export interface ModalOptions {
  disableHeader?: boolean;
  disableCloseButton?: boolean;
  enableBackButton?: boolean;
  handleBackButton?: () => void;
  closeText?: string;
  useCloseIcon?: boolean;
  closeClassName?: string;
}

const ModalWrapper = styled.div<{ index?: number; open?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${props => (props.open ? 1 : 0)};
  z-index: ${props => (props.open ? 1202 + (props.index || 0) : -999)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContainer = styled.div<ModalProps>`
  width: 95%;
  max-width: ${props => (props.customSize ? props.customSize : props.theme.variables[getSize(props.size)])};
  max-height: 80vh;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(${props => (props.open ? '-50%' : '50%')}) translateX(-50%);
  transition: transform 0.2s ease-in-out;
`;

const ModalHeader = styled.div<{ hasBackButton: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 16px;
  padding-left: ${props => (props.hasBackButton ? '48px' : '24px')};
  position: relative;
  flex-wrap: wrap;
  p.title {
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
  p.subheader {
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: #989898;
    grid-column: span 2 / span 2;

  }
  .line-break {
    width: 100%;
  }
  span.spacer {
    flex: 1;
  }
  .btn-back {
    width: 48px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    height: 100%;
    button {
      min-width: 100%;
      border-radius: 0;
      padding: 0;
      font-size: 2rem;
      align-self: center;
    }
  }
`;

const ModalBody = styled.div`
  width: 100%;
  max-height: 90vh;
  padding: 24px;
  overflow-y: auto;
`;

export const ModalFooter = styled.div`
  width: 100%;
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    width: calc(100% + 48px);
    height: 1px;
    background-color: #eee;
  }
  .c-button {
    margin-left: 12px;
  }
`;

const Modal: React.FC<ModalProps> = ({ title, subheader, ...props }) => {
  const [t] = useTranslation();
  const [ref, setRef] = React.useState<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!!ref) return;
    const div = document.createElement('div');
    modalRoot?.appendChild(div);
    setRef(div);
    return () => {
      if (ref && modalRoot) {
        modalRoot.removeChild(ref);
      }
    };
  }, [ref]);

  const header = React.useMemo(() => {
    if (props.options) {
      const { disableHeader } = props.options;
      if (disableHeader) return null;
    }
    const canClose = !props.options || !props.options.disableCloseButton;
    const hasBackBtn = (props.options && props.options.enableBackButton) ?? false;
    const closeText = props.options?.closeText || '';
    return (
      <ModalHeader hasBackButton={hasBackBtn}>
        {hasBackBtn && (
          <Button
            className="btn-back"
            variant="text"
            onClick={() => props.options?.handleBackButton && props.options?.handleBackButton()}
          >
            <ChevronLeft fontSize="inherit" />
          </Button>
        )}
        {title && typeof title === 'string' ? <p className="title">{title}</p> : title}
        <span className="spacer"></span>
        {canClose && (
          <Button
            variant="text"
            className={props?.options?.closeClassName || ''}
            onClick={props.handleClose}
          >
            {props?.options?.useCloseIcon ? (
              <XIcon fontSize="inherit" />
            ) : (
              closeText ||  t('close')
            )}
          </Button>
        )}
        <div className="line-break"></div>
        {subheader && typeof title === 'string' ? <p className="subheader">{subheader}</p> : subheader}
      </ModalHeader>
    );
  }, [props.options, props.handleClose, title]);

  if (!ref) return null;

  return ReactDOM.createPortal(
    <ModalWrapper index={props.index} open={props.open} className="modal-wrapper">
      <ModalContainer {...props}>
        {header}
        <ModalBody>
          {props.component && props.component(props)}
          {props.footer && <ModalFooter>{props.footer}</ModalFooter>}
        </ModalBody>
      </ModalContainer>
    </ModalWrapper>,
    ref
  );
};

export const useModal = (modalProps: ModalProps) => {
  const [open, setOpen] = React.useState(modalProps.open ?? false);
  const prevOpen = usePrevious(open);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  return React.useMemo(
    () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
      toggle: () => setOpen(!open),
      shouldReload: !prevOpen && open,
      isOpen: open,
      modalRef: open ? <Modal {...modalProps} open={open} handleClose={modalProps.handleClose ?? handleClose} /> : null
    }),
    [open, modalProps, handleClose, prevOpen]
  );
};
