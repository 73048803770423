import { styled } from 'components/Theme';
import React from 'react';

export interface PeekProps {
  id: string;
  title: string;
  visible?: boolean;
  component?: any;
  toggle?: () => void;
  width?: string;
}

export const Peek: React.FC<PeekProps> = props => {
  const { visible = true, title, toggle, component = null, width = '500px' } = props;
  let style = {
    maxWidth: width,
    left: `calc(100% - ${width})`,
    zIndex: 1203
  };
  if (!visible) {
    style = {
      ...style,
      left: '100%'
    };
  }
  return (
    <StyledDiv className={`peek-window ${!visible ? 'peek-window--close' : ''}`.trim()} style={style}>
      <span className="toggle" onClick={toggle}>
        <i className="arrow"></i>
      </span>
      <div className="header">
        <p className="title">{title}</p>
      </div>
      <div className="peek__body">{component}</div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  border-left: 5px solid ${props => props.theme.color.primary};
  span.toggle {
    background-color: ${props => props.theme.color.primary};
  }
  .peek__divider {
    background-color: ${props => props.theme.color.primary};
  }
  .divider {
    border: 2px solid ${props => props.theme.color.primary} !important;
    height: 2.5px;
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;
