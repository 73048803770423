import { AxiosResponse } from 'axios';
// Services
import {
  addIdentificationNumbers,
  getIdentification,
  updateContactAddresses,
  updateContactNumbers,
  updateCurrentUser,
  parseDate,
  deleteAccountHMOs,
  saveAccountHMOs
} from 'common/services';
import { Toast } from 'components';
import { StoreAction } from 'constants/store';
import { AccountHMO, Address, AddressType, Identification, OnboardingForm, OwnerType, User } from 'models';
import { ActionType } from 'models/store';
import { call, put, takeLatest } from 'redux-saga/effects';

const { ONBOARD, GET_CURRENT } = StoreAction.USER;

function* onboardSaga(actions: ActionType) {
  const cUser: User = actions.payload.current.user;
  const cIdentifications: Identification[] = actions.payload.current.identifications;
  const cHmos: AccountHMO[] = actions.payload.current.hmos || [];
  try {
    const { address, contactNumbers, prc, s2, ptr, hmos, ...profile }: OnboardingForm = actions.payload.payload;
    const identifications: Partial<Identification>[] = [
      {
        idType: 's2',
        identifier: s2
      },
      {
        idType: 'ptr',
        identifier: ptr
      }
    ].filter(ident => !getIdentification(ident.idType as any, cIdentifications) && !!ident.identifier);

    // Add Contact Numbers
    yield call(updateContactNumbers, contactNumbers);
    if (cUser.addresses.length === 0) {
      const addresses: Partial<Address>[] = [
        {
          ownerId: cUser.id,
          ownerType: OwnerType.User,
          addressType: AddressType.Work,
          ...address
        }
      ];
      // Add Address
      yield call(updateContactAddresses, addresses);
    }
    // Add identification numbers
    yield call(addIdentificationNumbers, identifications);

    //Account HMOS DELETE
    const newHMOIds = (hmos || []).filter(c => !!c.id).map(c => parseInt(`${c.id}`));
    const deleteHMOIds = ((cHmos as any[]) || []).filter(c => newHMOIds.indexOf(c.id) < 0).map(c => c.id);
    if (deleteHMOIds.length > 0) yield call(deleteAccountHMOs, deleteHMOIds);

    //Account HMOS ADD/UPDATE
    yield call(saveAccountHMOs, hmos);

    // Update profile
    const { data: profileData }: AxiosResponse = yield call(updateCurrentUser, {
      ...profile,
      dateOfBirth: profile.dateOfBirth ? parseDate(profile.dateOfBirth) : '',
      onboard: true
    });
    Toast.success('Profile updated successfully.');
    yield put({ type: GET_CURRENT.SUCCESS, payload: profileData });
    yield put({ type: ONBOARD.SUCCESS, payload: profileData });
  } catch (err) {
    yield put({ type: ONBOARD.FAILED, payload: err });
  }
}

export function* rootSaga() {
  yield takeLatest(ONBOARD.REQUEST, onboardSaga);
}
