import { toast } from 'sonner';

type VariantType = 'default' | 'success' | 'error' | 'warning' | 'info';

export const Toast = {
  success(msg: string, cb?: any) {
    this.toast(msg, 'success', cb);
  },
  warning(msg: string, cb?: any) {
    this.toast(msg, 'warning', cb);
  },
  info(msg: string, cb?: any) {
    this.toast(msg, 'info', cb);
  },
  error(msg: string, cb?: any) {
    this.toast(msg, 'error', cb);
  },
  toast(msg: string, variant: VariantType = 'default', cb?: any) {
    switch (variant) {
      case 'default':
        return toast(msg, { onDismiss: cb, onAutoClose: cb });
      case 'success':
        return toast.success(msg, { onDismiss: cb, onAutoClose: cb });
      case 'warning':
        return toast.warning(msg, { onDismiss: cb, onAutoClose: cb });
      case 'info':
        return toast.info(msg, { onDismiss: cb, onAutoClose: cb });
      case 'error':
        return toast.error(msg, { onDismiss: cb, onAutoClose: cb });
    }
  }
};
