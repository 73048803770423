import { http } from 'common/services';
import { AxiosResponse } from 'axios';

function isValidBase64Image(str: String) {
  const mime = str.substring(str.indexOf('/') + 1, str.indexOf(';base64'));
  const invalid = ['jpg', 'jpeg', 'png'];
  return invalid.indexOf(mime) >= 0;
}

export const toDataURL = async (url: string) => {
  return new Promise(async (res, rej) => {
    if (url && isValidBase64Image(url)) {
      res(url);
    }
    try {
      const { data }: AxiosResponse = await http.getImage(`getAsset&d=${Date.now()}`, url);
      var reader = new FileReader();
      reader.onloadend = function () {
        res(reader.result);
      };
      reader.readAsDataURL(data);
    } catch (err) {
      rej(err);
    }
  });
};

export const toBase64 = (url: string) =>
  new Promise<string>((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = url;
    img.onload = () => {
      const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');
      canvas.height = img.height;
      canvas.width = img.width;
      ctx && ctx.drawImage(img, 0, 0);
      resolve(canvas.toDataURL('image/png'));
    };
    img.onerror = error => reject(error);
  });
