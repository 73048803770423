import IconButton from '@material-ui/core/IconButton';
import { RemoveCircle } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
  label?: string;
}

const RemoveItem: React.FC<Props> = props => {
  const [t] = useTranslation();
  const { onClick, label } = props;
  return (
    <IconButton
      disableRipple={true}
      disableFocusRipple={true}
      onClick={onClick}
      style={{ backgroundColor: 'transparent', padding: 0, borderRadius: 0 }}
    >
      <RemoveCircle style={{ fill: '#FF305B' }} />
      <span style={{ fontSize: '14px', color: '#FF305B', marginLeft: '3px' }}>{label ?? t('remove_record')}</span>
    </IconButton>
  );
};

export default React.memo(RemoveItem);
