import { transformInRecord } from 'common/transformer';
import { StoreAction } from 'constants/store';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import { ActionType } from 'models/store';
import { call, put, takeLatest } from 'redux-saga/effects';
import { searchICD10 } from 'services/diagnosis.service';
import { searchMedication } from 'services/medication.service';
import { searchClinic, searchHmo } from 'services/search.service';
import { mapElasticHits } from 'shared/repository/utils';

const { CLINIC, PATIENT, DIAGNOSIS, PRESCRIPTION } = StoreAction;

const { SEARCH_HMO } = PATIENT;
const { SEARCH_BRAND, SEARCH_GENERIC } = PRESCRIPTION;

function* searchClinicSaga(action: ActionType) {
  try {
    const { data } = yield call(searchClinic, action.payload);
    yield put({ type: CLINIC.SEARCH.SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: CLINIC.SEARCH.FAILED, payload: err });
  }
}

function* searchHmoSaga(action: ActionType) {
  try {
    const { data } = yield call(searchHmo, action.payload);
    yield put({ type: SEARCH_HMO.SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: SEARCH_HMO.FAILED, payload: err.message });
  }
}

function* searchGenericSaga(action: ActionType) {
  try {
    const { data } = yield call(searchMedication, action.payload.term, 'generic', action.payload.controlled);
    yield put({ type: SEARCH_GENERIC.SUCCESS, payload: transformInRecord.ref_prescriptions(data || []) });
  } catch (err) {
    yield put({ type: SEARCH_GENERIC.FAILED, payload: err.message });
  }
}

function* searchBrandSaga(action: ActionType) {
  try {
    const { data } = yield call(searchMedication, action.payload.term, 'brand', action.payload.controlled);
    yield put({ type: SEARCH_BRAND.SUCCESS, payload: transformInRecord.ref_prescriptions(data || []) });
  } catch (err) {
    yield put({ type: SEARCH_BRAND.FAILED, payload: err.message });
  }
}

function* searchDiagnosisSaga(action: ActionType) {
  try {
    const term = action.payload || '';
    const { data: diagnoses1 } = yield call(searchICD10, term, true);
    const { data: diagnoses2 } = yield call(searchICD10, term);
    const total = diagnoses1.hits.hits.length + diagnoses2.hits.hits.length;
    let payload1 = yield call(mapElasticHits, diagnoses1, term, total); //mapElasticHits(diagnoses1, term, total);
    let payload2 = yield call(mapElasticHits, diagnoses2, term, total); //mapElasticHits(diagnoses2, term, total);
    const merged = merge(keyBy(payload1, 'id'), keyBy(payload2, 'id'));
    let payload = values(merged);
    yield put({ type: DIAGNOSIS.SUCCESS, payload: orderBy(payload, ['score2'], ['asc']) });
  } catch (err) {
    yield put({ type: DIAGNOSIS.FAILED, payload: err.message });
  }
}

export function* rootSaga() {
  yield takeLatest(CLINIC.SEARCH.REQUEST, searchClinicSaga);

  yield takeLatest(SEARCH_HMO.REQUEST, searchHmoSaga);
  yield takeLatest(DIAGNOSIS.REQUEST, searchDiagnosisSaga);

  yield takeLatest(SEARCH_GENERIC.REQUEST, searchGenericSaga);
  yield takeLatest(SEARCH_BRAND.REQUEST, searchBrandSaga);
}
