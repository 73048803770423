import { http } from 'common/services';
import { format, getDay } from 'date-fns';
import { ENDPOINTS } from 'defaults';
import { Appointment } from 'models';
import {
  _Appointment,
  _AppointmentFilterParameters,
  _AppointmentUpdateParameters,
  _CreateAppointmentParams,
  _CreateHealthRecordChiefComplainFromAppointmentParams,
  _CreateHealthRecordFromAppointmentParams,
  _CreateHealthRecordFromWalkinAppointmentParams,
  _CreateWalkinAppointmentParams
} from 'models/endpoint';
import { getDateTimezone } from 'shared/services/utils';
import { CancelToken } from 'axios';

const { APPOINTMENT, PATIENT, RECORD, STAGED_APPOINTMENT } = ENDPOINTS.DOCTOR;
const { LIST } = PATIENT;
const {
  SCHEDULE_SLOTS,
  SCHEDULE_COUNT,
  CONFIRM_RESCHEDULE,
  CANCEL_RESCHEDULE,
  GET_APPOINTMENT,
  APPOINTMENT: APPOINTMENTV3,
  FOLLOW_UP_SCHEDULE
} = ENDPOINTS.V3;

export const getAppointments = (params: _AppointmentFilterParameters) => {
  const endpoint = APPOINTMENTV3.parse(params);
  return http.get(endpoint.name, endpoint.url);
};

export const updateAppointment = (params: _AppointmentUpdateParameters) => {
  const endpoint = APPOINTMENTV3.key;
  return http.put(endpoint.name, endpoint.url, params);
};

export const getAppointmentsViaDateAndClinic = (date: Date, clinicId: number) => {
  const endpoint = APPOINTMENT.GET;
  const formattedDate = format(date, 'yyyy-MM-dd');
  return http.get(
    endpoint.name,
    `${endpoint.url}?from_date=${formattedDate + ' 00:00:00 PHT'}&to_date=${
      formattedDate + ' 23:59:59 PHT'
    }&clinic_id=${clinicId}`
  );
};

export const getAppointmentsViaDateAndClinicRange = (date: Date, clinicId: number) => {
  const endpoint = APPOINTMENT.GET;
  const baseDate = format(date, 'yyyy-MM-dd');
  // const fromDate = format(subDays(date, 1), 'yyyy-MM-dd');
  // const toDate = format(addDays(date, 1), 'yyyy-MM-dd');
  const timezone = getDateTimezone();
  const fromDate = encodeURIComponent(baseDate.concat(` 00:00:00 ${timezone}`));
  const toDate = encodeURIComponent(baseDate.concat(` 23:59:59 ${timezone}`));

  return http.getAll(endpoint.name, `${endpoint.url}?from_date=${fromDate}&to_date=${toDate}`); //&clinic_id=${clinicId} --removed
};

export const completeAppointment = (appointmentId: number, clinicId: number) => {
  const endpoint = APPOINTMENT.PUT;
  return http.put(endpoint.name, endpoint.url, { id: appointmentId, clinic_id: clinicId, status: 'completed' });
};

export const cancelAppointment = (id: number, clinicId: number) => {
  const endpoint = APPOINTMENT.PUT;
  return http.put(endpoint.name, endpoint.url, { id: id, clinic_id: clinicId, status: 'cancelled' });
};

export const filterByDay = (date: Date, list: _Appointment[]) => {
  return list.filter((appointment: _Appointment) => getDay(date) === getDay(new Date(appointment.booking_schedule)));
};

export const getAppointmentsPatientReference = async (patientIds: number[]) => {
  const endpoint = LIST.GET;
  return http.getAll(endpoint.name, `${endpoint.url}?id=${patientIds.join(',')}`);
};

export const createNewAppointment = async (appointment: _CreateAppointmentParams | _CreateWalkinAppointmentParams) => {
  const endpoint = APPOINTMENT.POST;
  return http.post(endpoint.name, endpoint.url, appointment);
};

export const assignAppointment = (appointment: Appointment, doctorId: number) => {
  const endpoint = APPOINTMENT.PUT;
  const payload = {
    id: appointment.id,
    doctor_id: doctorId
    // clinic_id: appointment.clinicId,
    // type: appointment.type,
    // user_id: appointment.patientId,
    // booking_schedule: appointment.bookingSchedule,
    // booking_schedule_end: appointment.bookingScheduleEnd,
    // chief_complaint: appointment.chiefComplaint,
    // status: appointment.status
  };
  return http.put(endpoint.name, endpoint.url, payload);
};

export const createNewHealthRecordFromNewAppointment = async (
  payload: _CreateHealthRecordFromAppointmentParams | _CreateHealthRecordFromWalkinAppointmentParams
) => {
  const endpoint = RECORD.POST;
  return http.post(endpoint.name, endpoint.url, payload);
};

export const createHealthRecordParamsFromNewAppointment = (form: any): _CreateHealthRecordFromAppointmentParams => {
  return {
    type: form.type,
    patient_id: form.user_id,
    appointment_id: form.appointment_id,
    clinic_id: form.clinic_id,
    followup_id: form.followup_id,
    visit_date: form.booking_schedule,
    visit_start_time: form.booking_schedule,
    visit_end_time: form.booking_schedule_end,
    active: false
  };
};

export const addHealthRecordChiefComplaint = async (payload: _CreateHealthRecordChiefComplainFromAppointmentParams) => {
  const endpoint = RECORD.SUBJECTIVE.POST;
  return http.post(endpoint.name, endpoint.url, payload);
};

export const createAddHealthRecordChiefComplaintParams = (
  id: number,
  chiefComplaint: string
): _CreateHealthRecordChiefComplainFromAppointmentParams => {
  return {
    patient_health_record_id: id,
    chief_complaint: chiefComplaint
  };
};

export const transformOutCreateNewAppointmentForm = (form: any): _CreateAppointmentParams => {
  return {
    user_id: form.patientId,
    clinic_id: form.clinicId,
    status: form.status,
    chief_complaint: form.chiefComplaint,
    type: form.walkinOrScheduled === 'walkin' ? 'Appointments::Walkin' : 'Appointments::Consultation',
    booking_schedule: form.appoinmentStartTime,
    booking_schedule_end: form.appoinmentEndTime
  };
};

export const transformOutCreateNewWalkinAppointmentForm = (form: any): _CreateWalkinAppointmentParams => {
  return {
    user_id: form.patientId,
    clinic_id: form.clinicId,
    status: form.status,
    chief_complaint: form.chiefComplaint,
    booking_schedule: form.appoinmentStartTime,
    type: 'Appointments::Walkin'
  };
};

export const createHealthRecordParamsFromNewWalkinAppointment = (
  form: any
): _CreateHealthRecordFromWalkinAppointmentParams => {
  return {
    patient_id: form.patientId,
    clinic_id: form.clinicId,
    appointment_id: form.appointmentId,
    followup_id: form.followupId,
    type: form.type,
    visit_date: form.visitDate,
    bmi: Number(form.vitals.bmi),
    bp_diastolic: Number(form.vitals.bpDiastolic),
    bp_systolic: Number(form.vitals.bpSystolic),
    heart_rate: Number(form.vitals.heartRate),
    respiratory_rate: Number(form.vitals.respiratoryRate),
    temperature: Number(form.vitals.temperature),
    height_unit: form.vitals.heightUnit,
    height_value: form.vitals.heightValue,
    head_circumference_unit: form.vitals.headCircumferenceUnit,
    head_circumference_value: form.vitals.headCircumferenceValue,
    ecog: form.vitals.ecog,
    weight_unit: form.vitals.weightUnit,
    weight_value: Number(form.vitals.weightValue),
    hba1c: Number(form.vitals.hba1c),
    waist_circumference_value: Number(form.vitals.waistCircumference),
    waist_circumference_unit: form.vitals.waistCircumferenceUnit,
    rgt: Number(form.vitals.rgt),
    oggt: Number(form.vitals.oggt),
    fgt: Number(form.vitals.fgt),
    rgt_unit: form.vitals.rgtUnit,
    oggt_unit: form.vitals.ogttUnit,
    fgt_unit: form.vitals.fgtUnit,
    oxy_saturation_unit: form.vitals.oxySaturationUnit,
    oxy_saturation_value: Number(form.vitals.oxySaturationValue),
    blood_sugar_unit: form.vitals.bloodSugarUnit,
    blood_sugar_value: Number(form.vitals.bloodSugarValue),
    pain: Number(form.vitals.pain),
    gsc: Number(form.vitals.gsc),
    laboratory_and_diagnostic_data: form.laboratoryAndDiagnosticData,
    active: false
  };
};

export const addAttachmentstoHealthRecord = (id: number, files: Array<File>) => {
  const transformOut = (file: File): FormData => {
    let formData = new FormData();
    formData.append('patient_health_record_id', `${id}`);
    formData.append('file', file);
    return formData;
  };
  const promises = files.map((file: File) =>
    http.post(RECORD.ATTACHMENT.POST.name, RECORD.ATTACHMENT.POST.url, transformOut(file))
  );
  return Promise.all(promises);
};

export const getPatientIdsFromAppointments = (appointments: _Appointment[]): Array<number> => {
  return appointments.map((appointment: _Appointment) => appointment.user.id);
};

export const getStagedAppointments = (status?: string) => {
  const endpoint = STAGED_APPOINTMENT.GET.parse({ status: status ? status : 'new' });
  return http.get(endpoint.name, endpoint.url);
};

export const getFollowUpSchedule = (id: number) => {
  const endpoint = FOLLOW_UP_SCHEDULE.parse({ patient_health_record_id: id });
  return http.get(endpoint.name, endpoint.url);
};

export const deleteFollowUpSchedule = (id: number) => {
  const endpoint = FOLLOW_UP_SCHEDULE.key;
  return http.delete(endpoint.name, endpoint.url, { id });
};

export const setFollowUpSchedule = (params: any, token: CancelToken) => {
  const endpoint = FOLLOW_UP_SCHEDULE.key;
  return http.cancellablePost(endpoint.name, endpoint.url, params, token);
};

export const confirmStageAppointment = (
  patientId: number,
  stageAppointmentId: number,
  phrType: string,
  hasEconsult: boolean
) => {
  const endpoint = STAGED_APPOINTMENT.CONFIRM;
  return http.post(endpoint.name, endpoint.url, {
    id: stageAppointmentId,
    user_id: patientId,
    phr_type: phrType,
    has_econsult: hasEconsult
  });
};

export const rejectStageAppointment = (stageAppointmentId: number) => {
  const endpoint = STAGED_APPOINTMENT.REJECT;
  return http.post(endpoint.name, endpoint.url, { id: stageAppointmentId });
};

export const cancelStageAppointment = (stageAppointmentId: number) => {
  const endpoint = CANCEL_RESCHEDULE;
  return http.put(endpoint.name, endpoint.url, { id: stageAppointmentId });
};

export const getStageAppointment = (stageAppointmentId: number) => {
  const endpoint = GET_APPOINTMENT;
  return http.get(endpoint.name, `${endpoint.url}?id=${stageAppointmentId}`);
};

export const rescheduleStageAppointment = <T>(stageAppointmentId: number, schedules: T[]) => {
  const endpoint = STAGED_APPOINTMENT.RESCHEDULE;
  return http.post(endpoint.name, endpoint.url, { id: stageAppointmentId, reschedule_dates: schedules });
};

export const confirmRescheduleStageAppointment = (stageAppointmentId: number, schedule: string) => {
  const endpoint = CONFIRM_RESCHEDULE;
  return http.put(endpoint.name, endpoint.url, { id: stageAppointmentId, booking_schedule: schedule });
};

export const getScheduleSlots = (code: string, clinicId: number, schedule: Date) => {
  const endpoint = SCHEDULE_SLOTS;
  return http.get(endpoint.name, `${endpoint.url}?code=${code}&clinic_id=${clinicId}&schedule_day=${schedule}`);
};

export const getScheduleSlotsCount = (code: string, clinicId: number, yearMonth: string) => {
  const endpoint = SCHEDULE_COUNT;
  return http.get(endpoint.name, `${endpoint.url}?code=${code}&clinic_id=${clinicId}&year_month=${yearMonth}`);
};
