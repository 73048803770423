import { StoreAction } from 'constants/store';
import { StoreAuth, ActionType, StoreSignup } from 'models/store';
import { getLocalStorageData } from 'common/services';
const { LOGIN, LOGOUT, SIGNUP } = StoreAction;

const INITIAL_STATE: StoreAuth = {
  data: {
    isAuthenticated: getLocalStorageData().isAuthenticated
  },
  inProgress: false,
  isForgotEmailValid: false,
  loginAttempts: 0
};

function authReducer(state = INITIAL_STATE, action: ActionType): StoreAuth {
  switch (action.type) {
    case LOGIN.REQUEST:
    case LOGOUT.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case LOGIN.FAILED:
    case LOGOUT.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload,
        loginAttempts: state.loginAttempts + 1
      };
    case LOGIN.SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: {
          isAuthenticated: true
        },
        loginAttempts: 0
      };
    case LOGOUT.SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: {
          isAuthenticated: false
        }
      };
    case LOGIN.RESET:
      return {
        ...state,
        loginAttempts: 0
      };
    default:
      return state;
  }
}

const INITIAL_SIGNUP_STATE: StoreSignup = {
  data: undefined,
  inProgress: false,
  signupAttempts: 0
};
function signupReducer(state = INITIAL_SIGNUP_STATE, action: ActionType): StoreSignup {
  switch (action.type) {
    case SIGNUP.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case SIGNUP.FAILED:
      return {
        ...state,
        inProgress: false,
        error: action.payload,
        signupAttempts: state.signupAttempts + 1
      };
    case SIGNUP.SUCCESS:
      return {
        ...state,
        inProgress: false,
        data: action.payload,
        error: null,
        signupAttempts: 0
      };
    case SIGNUP.RESET:
      return {
        ...state,
        signupAttempts: 0
      };
    default:
      return state;
  }
}

export default {
  auth: authReducer,
  signup: signupReducer
};
