import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup as MatRadioGroup
} from '@material-ui/core';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldAttributes, FieldSubAttributes, useField } from '..';
import './Toggle.scss';
import { styled } from 'components/Theme';

export interface RadioItems {
  id: number | string;
  value: string;
  disabled?: boolean;
}
type RadioGroupProps = {
  fullWidth?: boolean;
  items: RadioItems[];
  allowDeselect?: boolean;
  onChange?: (e: any) => void;
} & FieldAttributes;

export const Toggle: React.FC<RadioGroupProps> = React.memo(
  ({
    inline = false,
    items,
    className,
    label,
    fullWidth = false,
    disabled = false,
    allowDeselect = false,
    onChange,
    style,
    ...props
  }) => {
    const [field, meta] = useField<FieldSubAttributes>(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const [value, setValue] = useState((field as any).value);
    const selectedIndex = useMemo(() => items.findIndex(item => item.id.toString() === (value || '').toString()), [
      items,
      value
    ]);
    const wrapperClass = useMemo(() => `c-toggle ${className || ''} ${errorText ? 'error' : ''}`.trim(), [
      className,
      errorText
    ]);
    const generateItemClass = useCallback(
      (i: number) => {
        return classNames('c-toggle__item', `items-${items.length}`, {
          full: fullWidth,
          disabled: disabled,
          selected: i === selectedIndex,
          'selected-prev': selectedIndex >= 0 && i === selectedIndex - 1,
          'selected-next': selectedIndex >= 0 && i === selectedIndex + 1
        });
      },
      [items.length, selectedIndex, disabled, fullWidth]
    );

    const syncChanges = debounce((e, nVal) => {
      e.target.value = nVal;
      onChange && onChange(e); // Moved up before field.onChange since it resets the value to original state
      field.onChange(e);
    }, 200);

    useEffect(() => {
      if (field.value !== props.value) {
        setValue(field.value);
      }
    }, [field.value, props.value]);

    const toggleItems = useMemo(
      () =>
        items.map((itm, idx) => (
          <FormControlLabel
            className={generateItemClass(idx)}
            key={itm.id}
            label={itm.value}
            control={
              <Radio
                onClick={(e: any) => {
                  if (idx === selectedIndex && !allowDeselect) return;
                  const nVal = idx === selectedIndex ? '' : itm.id;
                  e.persist && e.persist();
                  setValue(nVal);
                  syncChanges(e, nVal);
                }}
                className="radio"
                size="small"
                value={itm.id}
              />
            }
          />
        )),
      [items, generateItemClass, selectedIndex, syncChanges, allowDeselect]
    );

    return (
      <StyledFormControl style={style}>
        <FormControl component="fieldset" className={wrapperClass}>
          {label && (
            <FormLabel component="legend" className="c-toggle__label">
              {label}
            </FormLabel>
          )}
          <MatRadioGroup row {...field} className="c-toggle__items">
            {toggleItems}
          </MatRadioGroup>
          {errorText && <FormHelperText className="helper-text">{errorText}</FormHelperText>}
        </FormControl>
      </StyledFormControl>
    );
  }
);

const StyledFormControl = styled.div`
  width: 100%;
  margin-top: 8px;
  .c-toggle {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 4px;
    .MuiFormGroup-root {
      flex-direction: row !important;
      flex-wrap: nowrap !important;
    }
    .c-toggle__label {
      margin-bottom: 8px;
    }
    .c-toggle__items {
      width: 100%;
    }
    .c-toggle__item {
      user-select: none;
      margin: 0;
      border: 1px solid #c3c3c3;
      border-left: none;
      padding: 12px;
      transition: background 0.1s ease-in-out;
      padding-top: 8px;
      padding-bottom: 7px;
      justify-content: center;
      align-content: center;
      &.full {
        width: 100%;
      }
      &:last-of-type {
        border-radius: 0 4px 4px 0;
      }
      &:first-of-type {
        border: 1px solid #c3c3c3;
        border-radius: 4px 0 0 4px;
        &.items-1 {
          border-radius: 4px;
        }
      }
      &.disabled {
        span {
          color: rgba(0, 0, 0, 0.54);
        }
        background-color: #dcdcdc;
        &.selected {
          background-color: #a5c9c9;
        }
        pointer-events: none;
        transition: background 0.1s ease-in-out;
      }
      &:hover {
        span {
          color: #484848;
        }
        background: #c3c3c3;
        transition: background 0.1s ease-in-out;
      }
      &.selected {
        span {
          color: #fff;
        }
        background: ${props => props.theme.color.primary} !important;
        transition: background 0.1s ease-in-out;
        border: 1px solid transparent;
        &:hover {
          color: #484848;
          background: ${props => props.theme.color.primaryLight} !important;
        }
      }
      &.selected-prev {
        border-right: 0;
      }
      &.selected-next {
        border-left: 0;
      }
      .radio {
        position: absolute;
        margin: 0;
        padding: 0;
        visibility: hidden;
        height: 0;
        width: 0;
      }
    }
    .helper-text {
      display: block;
      color: rgba(0, 0, 0, 0.54);
      margin: 0;
      font-size: 0.75rem;
      margin-top: 8px;
      min-height: 1em;
      text-align: left;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      line-height: 1em;
      letter-spacing: 0.03333em;
      margin: 8px 14px 0;
    }
    &.error {
      .c-toggle__item {
        border-color: #f44336;
      }
      .helper-text {
        color: #f44336;
      }
    }
  }
`;
