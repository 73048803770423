import { Breadcrumbs as MatBreadcrumbs } from '@material-ui/core';
import Logo from 'assets/images/medcheck-icon.svg';
import TMCLogo from 'assets/svg/tmc_breadcrumbs.svg';
import { Card } from 'components';
import { useEnv } from 'hooks';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Spacing = styled.div`
  margin-bottom: 15px;
`;

const StyledBreadCrumbs = styled(MatBreadcrumbs)`
  padding: 0;
  .MuiBreadcrumbs-separator:nth-of-type(2) {
    margin-left: 4px;
  }
  li:last-child {
    a {
      cursor: default;
    }
  }
`;

const LastSpan = styled.span`
  cursor: default;
`;

const StyledCard = styled(Card)`
  &.c-card {
    padding: 0 12px;
  }
`;

const LogoImage = styled.img`
  height: 30px;
  margin-top: 5px;
`;

interface BreadcrumbLink {
  label: string;
  href: string;
}
interface Props {
  links: Array<BreadcrumbLink>;
}

export const Breadcrumbs: React.FC<Props> = ({ links }) => {
  const { isTMC } = useEnv();

  return (
    <Spacing>
      <StyledCard>
        <StyledBreadCrumbs separator=">" aria-label="breadcrumb">
          <LogoImage src={isTMC ? TMCLogo : Logo} alt="Logo" />
          {links.map((link, index) => {
            if (links.length - 1 === index) {
              // Last text
              return <LastSpan key={link.href}>{link.label}</LastSpan>;
            } else {
              return (
                <NavLink to={link.href} key={link.href}>
                  {link.label}{' '}
                </NavLink>
              );
            }
          })}
        </StyledBreadCrumbs>
      </StyledCard>
    </Spacing>
  );
};
