import { Appointment, HistoricalFileReference, Attachment, StagedAppointments, AppointmentAttachment } from 'models';
import { _Appointment, _HealthRecord, _Attachment, _StagedAppointments, _AppointmentAttachment } from 'models/endpoint';
import { BookedByType } from 'constants/form';

export const transformIncomingAppointmentsData = (appointments: _Appointment[]): Appointment[] => {
  const getPhrId = (appointment: _Appointment): number | null => {
    if (appointment.patient_health_records.length > 0) return appointment.patient_health_records[0]['id'];
    return null;
  };
  return appointments.map(
    (appointment: _Appointment): Appointment => {
      return {
        id: appointment.id,
        code: appointment.code,
        type: appointment.type,
        phrId: getPhrId(appointment),
        patientId: appointment.user.id,
        patientName: appointment.user.name,
        appointmentFiles: (appointment ?? []).appointment_files
          .filter(af => af.file_type === 'loa')
          .map(
            (attachment: _AppointmentAttachment): AppointmentAttachment => {
              return {
                appointmentId: attachment.appointment_id,
                fileName: attachment.file_name,
                fileType: attachment.file_type,
                fileUrl: attachment.file_url,
                status: attachment.status,
                rejectReason: attachment.reject_reason || '',
                id: attachment.id
              };
            }
          ),
        patientAvatar: appointment.user.file_url,
        clinicId: appointment.clinic_id,
        doctorId: appointment.doctor_id,
        doctorName: appointment.doctor_name,
        chiefComplaint: appointment.chief_complaint,
        bookingSchedule: appointment.booking_schedule ? new Date(appointment.booking_schedule) : new Date(),
        bookingScheduleEnd: appointment.booking_schedule_end ? new Date(appointment.booking_schedule_end) : new Date(),
        hasEconsult: appointment.has_econsult,
        econsultRoom: !appointment.econsult_room
          ? null
          : {
              id: appointment.econsult_room.id,
              appointmentId: appointment.econsult_room.appointment_id,
              code: appointment.econsult_room.code,
              createdAt: appointment.econsult_room.created_at
                ? new Date(appointment.econsult_room.created_at)
                : new Date(),
              updatedAt: appointment.econsult_room.updated_at
                ? new Date(appointment.econsult_room.updated_at)
                : new Date(),
              validUntil: appointment.econsult_room.valid_until
                ? new Date(appointment.econsult_room.valid_until)
                : new Date()
            },
        eligibleTrialCount: appointment.eligible_trial_count,
        eligiblePatientSupportProgramCount: appointment.eligible_support_program_count,
        status: appointment.status,
        active: appointment.active,
        bookedByType: appointment.booked_by_type ? (appointment.booked_by_type as BookedByType) : BookedByType.Patient,
        company: appointment.company || '',
        brand: appointment.brand || '',
        creatorName: appointment.creator_name || '',
        isOnlineBooking: appointment.is_online_booking || false,
        service: appointment.ref_service,
        user: {
          id: appointment.user.id ?? '',
          fileUrl: appointment.user.file_url ?? '',
          name: appointment.user.name ?? '',
          email: appointment.user.email ?? '',
          contactNumber: appointment.user.contact_number ?? '',
          onWatchList: appointment.user.on_watch_list,
          company: appointment.user.company,
          brand: appointment.user.brand,
          code: appointment.user.code
        },
        createdAt: new Date(appointment.created_at),
        updatedAt: new Date(appointment.updated_at)
      };
    }
  );
};

export const transformIncomingHistoricalFileReferenceData = (reference: _HealthRecord): HistoricalFileReference => {
  return {
    id: reference.id,
    type: reference.type,
    patientId: reference.patient_id,
    clinicId: reference.clinic_id,
    createdAt: reference.created_at,
    updatedAt: reference.updated_at
  };
};

export const transformIncomingAttachmentsData = (attachments: _Attachment[]): Attachment[] => {
  return attachments.map(
    (attachment: _Attachment): Attachment => {
      return {
        id: attachment.id,
        fileName: attachment.file_name,
        fileUrl: attachment.file_url,
        createdAt: attachment.created_at,
        updatedAt: attachment.updated_at
      };
    }
  );
};

export const transformIncomingAppointmentAttachmentsData = (
  attachments: _AppointmentAttachment[]
): AppointmentAttachment[] => {
  return attachments.map(
    (attachment: _AppointmentAttachment): AppointmentAttachment => {
      return {
        appointmentId: attachment.appointment_id,
        fileName: attachment.file_name,
        fileType: attachment.file_type,
        fileUrl: attachment.file_url,
        status: attachment.status,
        rejectReason: attachment.reject_reason || '',
        id: attachment.id
      };
    }
  );
};

export const transformInStagedAppointments = (data: _StagedAppointments[]): StagedAppointments[] => {
  return (data || []).map((item: _StagedAppointments) => {
    return {
      id: item.id,
      accountId: item.account_id,
      accountCode: item.account_code,
      doctorId: item.doctor_id,
      clinicId: item.clinic.id,
      clinicName: item.clinic.name,
      firstName: item.first_name,
      appointmentFiles: item.appointment_files,
      middleName: item.middle_name ?? '',
      lastName: item.last_name,
      gender: item.gender || '',
      birthDate: item.birth_date ? new Date(item.birth_date) : null,
      email: item.email,
      contactNumber: item.contact_number ? item.contact_number : '',
      bookingSchedule: item.booking_schedule ? new Date(item.booking_schedule) : new Date(),
      rescheduleExpiration: item.reschedule_expiration ? new Date(item.reschedule_expiration) : null,
      rescheduleDates: item.reschedule_dates,
      status: item.status,
      hasEconsult: item.has_econsult,
      bookedByType: item.booked_by_type ? (item.booked_by_type as BookedByType) : BookedByType.Patient,
      company: item.company ? item.company : '',
      brand: item.brand ? item.brand : '',
      createdAt: item.created_at ? new Date(item.created_at) : new Date(),
      updatedAt: item.updated_at ? new Date(item.updated_at) : new Date()
    };
  });
};
