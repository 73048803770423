import { format } from 'date-fns';
import sortBy from 'lodash/sortBy';
import { BlockedSchedule, Clinic, ClinicHeader } from 'models';
import {
  _BlockedSchedule,
  _Clinic as ClinicFromEndpoint,
  _ClinicForms,
  _ClinicHeader as ClinicHeaderFromEndpoint
} from 'models/endpoint';
import { transformIn as transformInFromUser } from './user';

export const transformIncomingClinicsData = (clinics: ClinicFromEndpoint[]): Clinic[] => {
  const items = clinics
    .filter(c => c)
    .map(clinic => {
      return {
        id: clinic.id,
        name: clinic.name,
        hospitalName: clinic.hospital_name,
        active: clinic.active,
        fileUrl: clinic.file_url,
        fileName: clinic.file_name,
        contactNumbers: (clinic.contact_numbers ?? []).filter(c => c).map(c => transformInFromUser.contactNumber(c)),
        addresses: (clinic.addresses ?? []).filter(c => c).map(a => transformInFromUser.address(a)),
        createdAt: new Date(clinic.created_at),
        updatedAt: new Date(clinic.updated_at)
      };
    });

  return sortBy(items, [clinic => clinic.name]);
};

export const transformIncomingCliniHeaderData = (clinic: ClinicHeaderFromEndpoint): ClinicHeader => {
  return {
    id: clinic.id,
    viewable: clinic.viewable,
    fileUrl: clinic.file_url,
    fileName: clinic.file_name,
    createdAt: new Date(clinic.created_at),
    updatedAt: new Date(clinic.updated_at)
  };
};

export const transformIncomingBlockedSchedule = (data: _BlockedSchedule[]): BlockedSchedule[] => {
  return (data || []).map((item: _BlockedSchedule) => {
    return {
      id: item.id || undefined,
      blockedDayStart: item.blocked_day_start ? new Date(item.blocked_day_start) : null,
      blockedDayEnd: item.blocked_day_end ? new Date(item.blocked_day_end) : null,
      clinicId: item.clinic_id,
      accountId: item.account_id,
      doctorId: item.doctor_id,
      createdAt: item.created_at ? new Date(item.created_at) : null,
      updatedAt: item.updated_at ? new Date(item.updated_at) : null
    };
  });
};

export const transformOutBlockedSchedule = (form: BlockedSchedule): _BlockedSchedule => {
  return {
    id: form.id || undefined,
    blocked_day_start: format(form.blockedDayStart || new Date(), 'yyyy-MM-dd HH:mm:ss'),
    blocked_day_end: format(form.blockedDayEnd || new Date(), 'yyyy-MM-dd HH:mm:ss'),
    clinic_id: form.clinicId,
    account_id: form.accountId,
    doctor_id: form.doctorId
  };
};

export const transformInUploadedClinicForms = (data: _ClinicForms[]) => {
  return (data || []).map((item: _ClinicForms) => ({
    id: item.id,
    accountId: item.account_id,
    clinicId: item.clinic_id,
    doctorId: item.doctor_id,
    fileName: item.file_name,
    fileUrl: item.file_url
  }));
};
