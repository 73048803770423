import React, { useContext, useState } from 'react';
import { useCurrentUser } from 'hooks';
import { Alert } from '@material-ui/lab';
import { usePrevious } from 'react-use';

interface Location {
  path: string;
  value: number[];
}

const SessionContext = {
  LocationState: React.createContext<Location[]>([]),
  LocationDispatch: React.createContext((locs: Location[]) => {})
};

export const SessionProvider: React.FC = ({ children }) => {
  const [activeLocations, setLocations] = useState<Location[]>([]);
  return (
    <SessionContext.LocationState.Provider value={activeLocations}>
      <SessionContext.LocationDispatch.Provider value={setLocations}>
        {children}
      </SessionContext.LocationDispatch.Provider>
    </SessionContext.LocationState.Provider>
  );
};

export const useSessionLocation = () => {
  const context = useContext(SessionContext.LocationState);
  if (context === undefined) {
    throw new Error('useSessionLocation must be used within a SessionProvider');
  }
  return context;
};

export const useSessionLocationDispatch = () => {
  const context = useContext(SessionContext.LocationDispatch);
  if (context === undefined) {
    throw new Error('useSessionLocationDispatch must be used within a SessionProvider');
  }
  return context;
};

export const useLocationLock = () => {
  const { currentUser } = useCurrentUser();
  const { data: user } = currentUser;
  const activeLocations = useSessionLocation();
  const location = window.location.pathname + window.location.search;
  const currentActiveLocation = activeLocations.filter(loc => loc.path === location);
  const ownedLocs = currentActiveLocation.filter(loc => loc.value.length === 0 || loc.value[0] === user?.id);
  const isLock = !!user && currentActiveLocation.length > 0 && ownedLocs.length === 0;
  const prevIsLock = usePrevious(isLock);
  return {
    isLock,
    lockChanged: isLock !== prevIsLock && prevIsLock !== undefined,
    LockBanner: () => {
      if (!isLock) return null;
      return <Alert severity="error">This page is locked, another user is currently using it.</Alert>;
    }
  };
};
