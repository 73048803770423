import { take, select } from 'redux-saga/effects';

export function* watchAndLog() {
  while (true) {
    const action = yield take('*');
    const state = yield select();

    console.groupCollapsed(`action: ${action.type}`);
    console.info('payload', action.payload);
    console.log('state', state);
    console.groupEnd();
  }
}
