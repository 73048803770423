import { StoreAction } from 'constants/store';
import { ActionType, StorePeek } from 'models/store';
import { PeekType } from 'constants/form';
import { PeekProps } from 'components';

const { PEEK } = StoreAction;

const INITIAL_STATE: StorePeek = {
  data: []
};

function peekReducer(state = INITIAL_STATE, action: ActionType): StorePeek {
  switch (action.type) {
    case PEEK.REQUEST:
      const { options, type } = action.payload;
      let data: PeekProps[] = [];
      if (type !== PeekType.Hide && type !== PeekType.Remove) {
        const { id } = options;
        const search = state.data.filter(d => d.id === id);
        if (search.length > 0) {
          data = state.data.map(d => {
            return { ...d, visible: d.id === id };
          });
        } else {
          data = [
            ...state.data.map(d => ({ ...d, visible: false })),
            {
              ...options,
              visible: true
            }
          ];
        }
      }
      return {
        ...state,
        data
      };
    default:
      return state;
  }
}

export default peekReducer;
