import { Checkbox as MatCheckbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import { styled } from 'components/Theme';
import { FormHelperProps } from 'hooks/form';
import React from 'react';
import { Controller } from 'react-hook-form';

interface Props extends CheckboxProps {
  label: string;
  error?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  checkmarkClassName?: string;
  helpers?: FormHelperProps;
}

export const Checkbox: React.FC<Props> = ({
  label,
  name,
  helperText,
  error,
  className,
  required,
  fullWidth,
  children,
  helpers,
  disabled,
  checkmarkClassName,
  ...props
}) => {
  const classes = classNames('c-checkbox', className, {
    full: !!fullWidth,
    disabled: !!disabled
  });

  return (
    <CheckboxControl required={required} error={error} className={classes}>
      <FormControlLabel
        control={
          name ? (
            <Controller
              defaultValue={props.defaultValue}
              name={name}
              control={helpers?.control}
              onChange={
                props.onChange
                  ? ([e, v]) => {
                      props.onChange && props.onChange(e, v);
                      return v;
                    }
                  : undefined
              }
              as={<MatCheckbox {...props} className={checkmarkClassName} />}
            />
          ) : (
            <MatCheckbox {...props} innerRef={helpers?.register} />
          )
        }
        label={label}
      />
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </CheckboxControl>
  );
};

const CheckboxControl = styled(FormControl)`
  &.full {
    width: 100%;
  }
  &.disabled {
    pointer-events: none;
    .MuiTypography-root {
      color: #a8a8a8;
    }
    .MuiSvgIcon-root {
      color: #a8a8a8;
    }
  }
  .MuiFormControlLabel-root {
    user-select: none;
  }
`;
