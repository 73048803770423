import { Button } from 'components';
import { theme, styled } from 'components/Theme';
import { t } from 'i18next';
import { StoreState } from 'models/store';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Prompt as RouterPrompt, useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

export const Prompt: React.FC = () => {
  const { location } = useHistory();
  const shouldBlockRedirect = useSelector<StoreState, boolean>(
    props => props.session.shouldBlockRedirect,
    shallowEqual
  );

  const message = {
    title: 'confirm_page_leave',
    message: 'confirm_page_leave_message'
  };

  return (
    <>
      <RouterPrompt
        message={JSON.stringify(message)}
        when={location.pathname.startsWith('/phr') && shouldBlockRedirect}
      />
    </>
  );
};

export const Confirm = (message: any, callback: any) => {
  const container = document.createElement('div');
  container.setAttribute('custom-confirmation-navigation', '');
  document.body.appendChild(container);

  const closeModal = (callbackState: any) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
  };

  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <ConfirmWrapper>
        <div className="prompt">
          <p className="title">{t(message.title)}</p>
          <p className="message">{t(message.message)}</p>
          <div className="prompt__footer">
            <Button variant="text" onClick={() => closeModal(false)} label={t('cancel')} />
            <Button variant="contained" color="primary" onClick={() => closeModal(true)} label={t('confirm')} />
          </div>
        </div>
      </ConfirmWrapper>
    </ThemeProvider>,
    container
  );
};

const ConfirmWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  background: rgba(0, 0, 0, 0.1);
  .prompt {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 90%;
    max-width: 400px;
    min-height: 100px;
    max-height: 90%;
    z-index: 3001;
    padding: 24px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    p {
      width: 100%;
      &.title {
        font-weight: bold;
      }
    }
    .prompt__footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
      margin-bottom: 0;
      .c-button {
        margin: 0;
        margin-left: 16px;
      }
    }
  }
`;
