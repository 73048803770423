import { Datepicker } from 'components';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import React, { useEffect } from 'react';
import {
  ArrayField,
  FormContext as FFormContext,
  FormContextValues,
  useFieldArray as useReactFieldArray,
  useForm as useReactForm,
  UseFormOptions
} from 'react-hook-form';
import { usePrevious } from 'react-use';
// import { flattenObject } from 'services/utils.service';
export const FormContext = FFormContext;

export interface FormHelperProps {
  register?: any;
  unregister?: any;
  setValue?: any;
  control?: any;
}

export const useFormSubField = <P, S = any>(parentName: keyof P) => {
  return {
    getName: () => parentName,
    getSubName: (name: keyof S, index?: number) => {
      return index === undefined ? `${parentName}.${name}` : `${parentName}.[${index}].${name}`;
    },
    get<T>(parent: string) {
      return {
        getSubName: (name: keyof T, index?: number) => {
          return index === undefined ? `${parent}.${name}` : `${parent}.[${index}].${name}`;
        }
      };
    }
  };
};

interface FormFieldProps<P> {
  parent?: keyof P;
  getFieldProps?: any;
}

interface FormProps<T> extends UseFormOptions<T> {
  enableReinitialize?: boolean;
}

export type FieldArrayProps = Partial<ArrayField<Record<string, any>, 'keyId'>>[];

export const useForm = <T,>(props?: FormProps<T>) => {
  const { enableReinitialize, ...fProps } = props || {};
  const methods = useReactForm<T>(fProps);
  const prevDefaults = usePrevious(props?.defaultValues);

  useEffect(() => {
    if (enableReinitialize && !!prevDefaults && !isEqual(props?.defaultValues, prevDefaults)) {
      methods.reset(props?.defaultValues);
    }
  }, [methods, prevDefaults, props, enableReinitialize]);

  return {
    ...methods,
    // setValue: (name: any, value: any, shouldValidate?: boolean) => {
    //   if (typeof value === 'object') {
    //     const newObj = flattenObject({ [name]: value });
    //     const sorted: any = Object.keys(newObj)
    //       .sort()
    //       .reduce((acc, cur) => ({ ...acc, [cur]: newObj[cur] }), {});
    //     for (let nKey in sorted) {
    //       methods.setValue(nKey, sorted[nKey], shouldValidate);
    //     }
    //   } else {
    //     methods.setValue(name, value, shouldValidate);
    //   }
    // },
    useFieldArray: ({ name, control }: { name: string; control: any }) =>
      useReactFieldArray({ name, control, keyName: 'keyId' }),
    getFieldProps: (name: keyof T) => getFieldProps(name, methods)
  };
};

export const useFormField = <P, S = any>(options?: FormFieldProps<P>) => {
  return {
    getFieldProps(name: keyof P) {
      return options?.getFieldProps(name);
    },
    get(name: keyof P) {
      return name;
    },
    getSubName(name: keyof S, index?: number) {
      return index === undefined ? `${options?.parent ?? ''}.${name}` : `${options?.parent ?? ''}.[${index}].${name}`;
    },
    getSubFieldProps(name: keyof S, index?: number) {
      const fName: any =
        index === undefined ? `${options?.parent ?? ''}.${name}` : `${options?.parent ?? ''}[${index}].${name}`;
      return options?.getFieldProps(fName);
    }
  };
};

export const getFieldProps = <T,>(name: any, methods: FormContextValues<T>) => {
  const errorText = get(methods.errors, `${name}.message`, '');
  return {
    name,
    error: !!errorText,
    helperText: errorText,
    helpers: {
      setValue: methods.setValue,
      control: methods.control,
      register: methods.register,
      unregister: methods.unregister
    }
  };
};

export const bindHiddenFields = (ids: string[], values: any, fieldGetter: any, idx?: number): any[] => {
  const component = [];
  for (let index = 0; index < ids.length; index++) {
    const id = ids[index];
    const value = get(values, id, undefined);
    if (value && typeof value === 'object' && Object.keys(value).length > 0) {
      const newIds = Object.keys(value).map(newId => `${id}.${newId}`);
      component.push(...bindHiddenFields(newIds, values, fieldGetter));
    } else {
      if (value === null || value instanceof Date) {
        component.push(
          <Datepicker key={id} hidden {...(idx !== undefined ? fieldGetter(id) : fieldGetter(id, idx))} />
        );
      } else {
        const inputType = typeof value === 'boolean' ? 'checkbox' : 'text';
        component.push(
          <input
            key={id}
            hidden
            defaultValue={inputType === 'checkbox' ? undefined : value}
            defaultChecked={inputType === 'checkbox' ? value : undefined}
            name={(idx !== undefined ? fieldGetter(id, idx) : fieldGetter(id)).name}
            ref={idx !== undefined ? fieldGetter(id, idx).helpers.register() : fieldGetter(id).helpers.register}
            type={inputType}
          />
        );
      }
    }
  }
  return component;
};
