import { SUBSCRIPTION_STATUS, _CoMorbidConditions, _Complications, _FamilyHistoryDetails } from './endpoint';

export interface User {
  id: number;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  nickname: string;
  gender: Gender;
  suffix: string | null;
  dateOfBirth: Date;
  onboard: boolean;
  deceased: boolean;
  deceasedCause: string | null;
  termsAndConditions: boolean;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  type: UserType;
  role: UserRole;
  fileUrl: string;
  fileName: string;
  contactNumbers: ContactNumber[];
  addresses: Address[];
  claimed: boolean;
  testAccount: boolean;
  access: Access;
  isBcmapDoctorOnly: boolean;
  lastSignInAt: Date;
  wallets: Wallet[];
  disclaimer: boolean | null;
  enabled2fa?: boolean;
  nextFeedbackDate?: string | Date;
  details: UserDetails;
  code?: string;
}

export interface UserDetails extends UserSubscriptionDetails {
  doctorCreated?: boolean;
  nextReminderDate?: string | Date;
  firstSignIn?: string | null;
  prescriptionWatermark?: boolean;
}

export interface UserSubscriptionDetails {
  subscriptionTrial: boolean | null;
  subscriptionStatus: SUBSCRIPTION_STATUS;
}

export interface Access {
  submissionForm: SubmissionFormAccess;
  encounterForm: EncounterFormAccess;
}

export interface SubmissionFormAccess {
  bcmap: boolean;
  aace: boolean;
  tavr: boolean;
  nalagen: boolean;
  registryLiver: boolean;
  registryPgh: boolean;
  registryJrrmmc: boolean;
  registryEvrmc: boolean;
  ncd: boolean;
}

export interface EncounterFormAccess {
  bci: boolean;
  hpb: boolean;
  oncology: boolean;
  standard: boolean;
  cardiology: boolean;
  diabetology: boolean;
  rheumatology: boolean;
  endocrinology: boolean;
  psmoDoctor: boolean;
  psmoSharedData: boolean;
}

export interface SecretaryDoctor {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  fileUrl: string;
  specializations: {
    id: number;
    name: string;
  }[];
}

export interface SecretaryDashboard {
  accounts: {
    id: number;
    name: string;
    type: AccountType;
    doctors: number[];
  }[];
  doctors: SecretaryDoctor[];
}

export interface NavigatorAccount {
  id: number;
  type: string;
  name: string;
  doctors: number[];
}

export interface NavigatorDashboard {
  accounts: NavigatorAccount[];
  doctors: SecretaryDoctor[];
}

export interface ContactNumber {
  id?: number;
  contactType: ContactType;
  number: string;
  createdAt?: Date;
  updatedAt?: Date;
  ownerId: number;
  ownerType: OwnerType;
}

export interface AccountHMO {
  id: number;
  hmoId: number;
  hmoCode: string;
  clinicId: number | null;
  clinicName: string | null;
  number: string;
}

export interface AccountHMOInput {
  id?: number;
  hmoId: number;
  hmoCode: string;
  number: string;
}

export interface Wallet {
  id: number;
  userId: number;
  institution: string;
  balance: number;
  enabled: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface Address {
  id: number;
  ownerId: number;
  ownerType: OwnerType;
  address1: string;
  address2: string;
  barangay: string;
  region: string;
  city: string;
  state: string;
  municipality: string;
  province: string;
  country: string;
  postcode: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
  addressType: AddressType;
}

export interface AddressFlags {
  address1: boolean;
  country: boolean;
  city: boolean;
  province: boolean;
}

export interface Profile {
  accounts: Account[];
  identifications: Identification[];
  specializations: Specialization[];
  hmos: AccountHMO[];
}

export interface JoinDates {
  id: number;
  date: Date;
}

export interface Account {
  id: number;
  type: AccountType;
  role: AccountRole;
  code: string;
  fileName: string | null;
  fileUrl: string | null;
  publishSchedule: boolean;
  classification: 'clinic' | 'opd';
  name: string;
  limit: number;
  clinic_ids: number[];
  affiliation_id: number;
  affiliation_type: string;
  auto_share: boolean;
  active: boolean;
  member_join_dates: JoinDates[];
  created_at: Date;
  updated_at: Date;
}

export interface AccountMembership {
  id: number;
  membership: {
    id: number;
    role: AccountRole;
  };
  name: string;
  email: string;
  specializations: { id: number; name: string }[];
}

export interface Identification {
  id: number;
  idType: string;
  identifier: string;
  expiration: Date | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface Specialization {
  id: number;
  specializationId: number;
  active: Boolean;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface PatientNameAndAvatar {
  id: number;
  name: string;
  fileUrl: string;
  onWatchList: boolean;
}

export type PatientWithSummary = User & {
  summary: PatientSummary;
};

export interface PatientSummary {
  id: number;
  email: string;
  gender: string;
  currentAddress: Partial<Address> | null;
  permanentAddress: Partial<Address> | null;
  contactNumber: string | null;
  bloodType: string | null;
  philhealth: string | null;
  hmo: string | null;
  religion: string | null;
  maritalStatus: string | null;
  birthDate: Date;
  allergies: string | null;
  medications: string | null;
  presentMedicalHistory: string | null;
  pastMedicalHistory: string | null;
  pastMedicalHistoryDetails: PastMedicalHistoryDetails;
  familyHistory: string | null;
  familyHistoryDetails: _FamilyHistoryDetails[];
  socialHistory: string | null;
  additionalInfo: string | null;
  complications: _Complications[];
  latestConsultationDate: Date | null;
  coMorbidConditions: _CoMorbidConditions[];
  occupation: string | null;
  ethnicity: string | null;
  nationality: string | null;
  paymentPreference: string | null;
  landlineNumber: string | null;
  guardianName: string | null;
  guardianRelation: string | null;
  guardianContactInformation: string | null;
  emergencyContactName: string | null;
  emergencyContactRelation: string | null;
  emergencyContactInformation: string | null;
  emergencyContactAddress: string | null;
  cigaretteLifestyle: string | null;
  cigaretteLifestyleOnset: Date | null;
  cigaretteLifestyleRemark: string | null;
  cigaretteLifestyleQuitDate: Date | null;
  cigaretteLifestyleMonths: string | null;
  alcoholLifestyle: string | null;
  alcoholLifestyleOnset: Date | null;
  alcoholLifestyleRemark: string | null;
  alcoholLifestyleQuitDate: Date | null;
  alcoholLifestyleMonths: string | null;
  dietAndNutrition: string;
  dietAndNutritionOnset: Date | null;
  dietAndNutritionRemark: string | null;
  dietAndNutritionQuitDate: Date | null;
  dietAndNutritionMonths: string | null;
  exerciseHabits: string | null;
  exerciseHabitsOnset: Date | null;
  exerciseHabitsRemark: string | null;
  exerciseHabitsQuitDate: Date | null;
  exerciseHabitsMonths: string | null;
  menarche: string | null;
  menopause: string | null;
  obgynRemarks: string | null;
  lastMenstrualPeriod: Date | null;
  estimateDateOfDelivery: Date | null;
  ageOfGestation: string | null;
  menstrualCycle: string | null;
  lengthOfBleeding: string | null;
  padsOrTampons: string | null;
  frequency: string | null;
  bleedingBetweenPeriods: boolean;
  bleedingAfterIntercourse: boolean;
  postMenopausalBleeding: boolean;
  gravida: string | null;
  termBirth: string | null;
  preterm: string | null;
  abortion: string | null;
  livingChildren: string | null;
  modeOfDelivery: string | null;
  breastfeeding: string | null;
  contraception: string | null;
  complicationDuringPregnancy: string | null;
  historyOfSti: string | null;
  papSmearHistory: string | null;
  gynecologicalSurgery: string | null;
  diseaseHistory: string | null;
  breastDiseaseHistoryDetails: BreastDiseaseHistoryDetails;
  forExtraction: boolean;
  deceased: boolean;
  deceasedCause: string | null;
  status: PatientStatus;
  createdAt: Date;
  updatedAt: Date;
  drugs: string | null;
  suicidality: string | null;
  sexualHistory: string | null;
  environmentalExposure: string | null;
  development: string | null;
  // to be defined
  summaryAllergies: Array<any>;
  eyeRecords: Array<any>;
  eyeRecordsLastRefracted: Date;
}

export interface PatientStatus {
  diseaseNoEvidence: boolean;
  diseaseRecurrenceGeneral: boolean;
  diseaseRecurrenceLocal: boolean;
  diseaseRecurrenceRegional: boolean;
  diseaseRecurrenceDistant: boolean;
  diseaseDeceasedFromDisease: boolean;
  diseaseDeceasedFromOther: boolean;
  diseaseDeceasedFromOtherUnrelated: boolean;
  diseaseStatusUnknown: boolean;
  treatmentUndergoing: boolean;
  treatmentCompleted: boolean;
  treatmentIncomplete: boolean;
  treatmentNone: boolean;
  treatmentStatusUnknown: boolean;
  treatmentLostToFollowup: boolean;
  turnOffNotifications: boolean;
  snooze: string;
  lastUpdatedAt: string;
}

export interface OBGYN {
  menarche: string | null;
  menopause: string | null;
  lastMenstrualPeriod: Date | null;
  estimateDateOfDelivery: Date | null;
  ageOfGestation: string | null;
  menstrualCycle: string | null;
  lengthOfBleeding: string | null;
  padsOrTampons: string | null;
  frequency: string | null;
  bleedingBetweenPeriods: boolean;
  bleedingAfterIntercourse: boolean;
  postMenopausalBleeding: boolean;
  gravida: string | null;
  termBirth: string | null;
  preterm: string | null;
  abortion: string | null;
  livingChildren: string | null;
  modeOfDelivery: string | null;
  breastfeeding: string | null;
  contraception: string | null;
  complicationDuringPregnancy: string | null;
  historyOfSti: string | null;
  papSmearHistory: string | null;
  gynecologicalSurgery: string | null;
}

export type PastMedicalHistoryDetails = {
  pmhEcog: string;
  pmhDmBool: boolean;
  pmhDmValue: string;
  pmhHivBool: boolean;
  pmhHivValue: string;
  pmhHpnBool: boolean;
  pmhHpnValue: string;
  pmhHepatitisBool: boolean;
  pmhHepatitisValue: string;
  pmhStrokeCvaBool: boolean;
  pmhStrokeCvaValue: string;
  pmhThyroidDseBool: boolean;
  pmhThyroidDseValue: string;
  pmhPreviousSurgeries: string;
  pmhBronchialAsthmaBool: boolean;
  pmhBronchialAsthmaValue: string;
  pmhPreviousTransfusionBool: boolean;
  pmhPreviousTransfusionValue: string;
};

export type BreastDiseaseHistoryDetails = {
  bdhG: string;
  bdhP: string;
  bdhPmp: string;
  bdhData: string;
  bdhLnmp: string;
  bdhMenarcheAge: string;
  bdhOcpUseBool: boolean | string;
  bdhMenopauseAge: string;
  bdhOcpUseValue: string;
  bdhBreastfeedingBool: boolean | string;
  bdhBreastfeedingValue: string;
  bdhMenstrualIntervals: string;
  bdhFamilyHxOtherCaBool: boolean | string;
  bdhFamilyHxBreastCaBool: boolean | string;
  bdhFamilyHxOtherCaValue: string;
  bdhFamilyHxBreastCaValue: string;
  bdhPreviousBreastSurgeryBool: boolean | string;
  bdhAgeFirstCompletedPregnancy: string;
  bdhPreviousBreastSurgeryValue: string;
};

// Enums

export enum Gender {
  Male = 'male',
  Female = 'female'
}

export enum UserRole {
  Doctor = 'doctor',
  Secretary = 'secretary',
  ProserAdmin = 'proser_admin'
}

export enum UserType {
  Doctor = 'Users::Doctor',
  Secretary = 'Users::Secretary',
  Navigator = 'Users::Navigator',
  ProserAdmin = 'Users::ProserAdmin'
}

export enum AccountType {
  Doctor = 'Accounts::Doctor',
  Clinic = 'Accounts::Clinic'
}

export enum AccountRole {
  Admin = 'admin',
  Member = 'member'
}

export enum OwnerType {
  User = 'User'
}

export enum ContactType {
  Mobile = 'mobile',
  Landline = 'landline'
}

export enum AddressType {
  Home = 'home',
  Work = 'work'
}

export enum UserTheme {
  MedCheck = 'Theme:MedCheck',
  BCMAP = 'Theme:BCMAP',
  TMC = 'Theme:TMC'
}

export interface OnboardingForm {
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  dateOfBirth: Date | null;
  contactNumbers: ContactNumber[];
  hmos: AccountHMOInput[];
  gender: string | null;
  email: string;
  prc: string;
  s2: string;
  ptr: string;
  address: Partial<Address>;
}

export interface DoctorProfileForm {
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  dateOfBirth: Date | null;
  gender: string;
  contactNumbers: ContactNumber[];
  hmos: AccountHMOInput[];
  prc: string;
  s2: string;
  ptr: string;
  address: Partial<Address>;
}

export interface NavigatorProfileForm {
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  dateOfBirth: Date | null;
  gender: string;
  contactNumbers: ContactNumber[];
  address: string;
}

export interface SecretaryProfileForm {
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  dateOfBirth: Date | null;
  gender: string;
  clinics: any[];
}

export interface MedicalRepresentative {
  id?: number;
  profileId?: number;
  firstName: string;
  lastName: string;
  contactNumber: string;
  email: string;
  company: string;
  brand: string;
  notes: string;
  lastVisitDate?: Date;
}
