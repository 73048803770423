import { StoreAction } from 'constants/store';
import { ActionType, StoreHMO } from 'models/store';
import { HMO } from 'models';

const { SEARCH_HMO } = StoreAction.PATIENT;

const INITIAL_STATE: StoreHMO = {
  list: [] as HMO[],
  inProgress: false
};

function hmoReducer(state = INITIAL_STATE, action: ActionType): StoreHMO {
  switch (action.type) {
    case SEARCH_HMO.REQUEST:
      return {
        ...state,
        inProgress: true
      };
    case SEARCH_HMO.SUCCESS:
      return {
        ...state,
        list: action.payload,
        inProgress: false
      };
    case SEARCH_HMO.FAILED:
      return {
        ...state,
        list: [],
        inProgress: false
      };
    default:
      return state;
  }
}

export default hmoReducer;
