import { _AccountPermission } from 'models/endpoint';
import { Permission, SecretaryPermission } from 'models/secretary';

export const transformPermissions = (data: _AccountPermission): Permission & { id?: number } => {
  return {
    id: data.id,
    accessManageClinics: data.details.secretary__manage_clinics,
    accessCreatePatient: data.details.secretary__create_patient,
    accessEditPatient: data.details.secretary__edit_patient,
    accessCrudAppointment: data.details.secretary__crud_appointment,
    accessCrudEncounter: data.details.secretary__crud_encounter,
    accessCrudBilling: data.details.secretary__crud_billing
  };
};

export const transformSecretaryPermissions = (data: _AccountPermission[]): SecretaryPermission => {
  return {
    userId: data[0].user_id,
    clinicIds: data[0].details.secretary__clinic_ids,
    accessManageClinics: data[0].details.secretary__manage_clinics,
    accessCreatePatient: data[0].details.secretary__create_patient,
    accessEditPatient: data[0].details.secretary__edit_patient,
    accessCrudAppointment: data[0].details.secretary__crud_appointment,
    accessCrudEncounter: data[0].details.secretary__crud_encounter,
    accessCrudBilling: data[0].details.secretary__crud_billing
  };
};
